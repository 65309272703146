import React, { useState } from 'react';
import { Card, ChoiceList, Button, Stack, TextField, Text, Modal, Banner } from '@shopify/polaris';
import * as StoryConsts from '../../../../consts';
import { STORY_FIELD, STORY_DISPLAY_TYPE } from '../../../../consts';
import { useStoriesProvider } from '../../../../components/StoryiesProvider';
import { useShopProvider } from '../../../../../../components/ShopProvider';

const StorySettingsCard = () => {
  const {
    currentStory,
    validationError,
    onChangeCurrentStory,
    shoppablePreviewVisible,
    setShoppablePreviewVisible,
    isStoriesChangeLoading,
    publishLoading,
    uploadingStates,
    videoStoryLoading
  } = useStoriesProvider();

  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const onChangeConditionUrl = (value) => {
    onChangeCurrentStory(STORY_FIELD.conditionUrl, value)
  }

  const onClearConditionUrl = () => {
    onChangeCurrentStory(STORY_FIELD.conditionUrl, '')
  }

  const onChangeCurrentStoryTitle = (value) => {
    onChangeCurrentStory(STORY_FIELD.title, value)
  }

  const onChangeWidgetSize = (val) => {
    onChangeCurrentStory(STORY_FIELD.widget.size, val[0])
  }

  const onChangeWidgetShape = (val) => {
    onChangeCurrentStory(STORY_FIELD.widget.shape, val[0])
  }

  const onChangeWidgetPosition = (val) => {
    onChangeCurrentStory(STORY_FIELD.widget.position, val[0])
  }

  const onChangeShoppableStoryType = (val) => {
    onChangeCurrentStory(STORY_FIELD.storyDisplayType, val[0])
  }

  const shoppableVideoWidgetSettings = (isSelected) => (
    <Card.Subsection>
      <Stack vertical>
        <Text color='subdued'>Display videos using a floating widget over your online store theme page</Text>
        <Stack distribution='fillEvenly'>
          <ChoiceList
            disabled={!isSelected || videoStoryLoading}
            title='Size'
            choices={[
              { label: StoryConsts.StoryWidgetSize.standard, value: StoryConsts.StoryWidgetSize.standard },
              { label: StoryConsts.StoryWidgetSize.large, value: StoryConsts.StoryWidgetSize.large }
            ]}
            selected={[currentStory.widget?.size]}
            onChange={onChangeWidgetSize}
          />
          <ChoiceList
            disabled={!isSelected || videoStoryLoading}
            title='Shape'
            choices={[
              { label: StoryConsts.StoryWidgetShape.circle, value: StoryConsts.StoryWidgetShape.circle },
              { label: StoryConsts.StoryWidgetShape.rectangle, value: StoryConsts.StoryWidgetShape.rectangle }
            ]}
            selected={[currentStory.widget?.shape]}
            onChange={onChangeWidgetShape}
          />
          <ChoiceList
            disabled={!isSelected || videoStoryLoading}
            title='Position'
            choices={[
              { label: StoryConsts.StoryWidgetPositionLabel.bottom_right, value: StoryConsts.StoryWidgetPosition.bottom_right },
              { label: StoryConsts.StoryWidgetPositionLabel.bottom_left, value: StoryConsts.StoryWidgetPosition.bottom_left }
            ]}
            selected={[currentStory.widget?.position ?? StoryConsts.StoryWidgetPosition.bottom_right]}
            onChange={onChangeWidgetPosition}
          />
        </Stack>
        <Button
          plain
          onClick={() => setShoppablePreviewVisible(prev => !prev)}
          disabled={isStoriesChangeLoading || publishLoading || currentStory.videos.length === 0 || uploadingStates.length !== 0 || !isSelected || videoStoryLoading}
        >
          {shoppablePreviewVisible ? 'Hide widget' : 'Preview widget'}
        </Button>
      </Stack>
    </Card.Subsection>
  )

  const themeVideos = (isSelected) => (
    <Card.Subsection>
      <Stack spacing='tight'>
        <Text color='subdued'>Display videos carousel as a section or a block in your online store theme page</Text>
        <Button plain onClick={() => setShowLearnMoreModal(true)}>Learn More</Button>
      </Stack>

    </Card.Subsection>
  )

  return (
    <Card sectioned title='Settings'>
      <Stack vertical>
        <TextField
          label={'Title*'}
          value={currentStory.title}
          placeholder={'Please update a title'}
          onChange={onChangeCurrentStoryTitle}
          error={validationError.titleError}
          autoComplete={'off'}
        />
        <TextField
          label={'Publish to url*'}
          value={currentStory.conditionUrl}
          placeholder={'url path such as: /products/my-product'}
          onChange={onChangeConditionUrl}
          error={validationError.conditionUrlError}
          onClearButtonClick={onClearConditionUrl}
          autoComplete={'off'}
          helpText='Set the url path to publish this shoppable video to'
        />
        <ChoiceList
          disabled={videoStoryLoading}
          title='Shoppable video type'
          selected={[currentStory.storyDisplayType]}
          choices={[
            { label: 'Shoppable video widget floating over store', value: STORY_DISPLAY_TYPE.widget, renderChildren: shoppableVideoWidgetSettings },
            { label: 'Shoppable videos carousel within your store theme', value: STORY_DISPLAY_TYPE.section, renderChildren: themeVideos }
          ]}
          onChange={onChangeShoppableStoryType}
        />
      </Stack>
      <InlineDetailsModal
        open={showLearnMoreModal}
        onClose={() => setShowLearnMoreModal(false)}
        publishUrl={currentStory.conditionUrl}
      />
    </Card>
  )
}

function InlineDetailsModal({ open, onClose, publishUrl }) {
  const { shopOrigin, headlessConfig, headlessConfigLoading } = useShopProvider();
  const { shopifyTheme, verifyingSections, supportsThemeSections } = useStoriesProvider();

  function getThemeEditorLink() {
    if (!shopifyTheme?.theme.id) {
      return null;
    }
    let editorLink = `https://admin.shopify.com/store/${shopOrigin.split('.')[0]}/themes/${shopifyTheme.theme.id}/editor`
    if (publishUrl) {
      editorLink += `?previewPath=${encodeURIComponent(publishUrl)}`;
    }
    return editorLink;

  }
  function renderContent() {
    const editorLink = getThemeEditorLink();
    if (headlessConfig?.isHeadless) {
      return (
        <Stack vertical spacing='tight'>
          <Banner status='warning'>You've set your store as using a headless storefront</Banner>
          <Text>Adding shoppable videos inside headless storefront pages requires storefront code changes by your headless admin.</Text>
          <Text>Please <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact Boutiq support</a> for further instructions.</Text>
        </Stack>
      )
    } else {
      if (supportsThemeSections) {
        return (
          <Stack vertical spacing='tight'>
            <Text>You can easily add shoppable videos to your theme by using the Shopify Theme Editor.</Text>
            <Text>You can add Boutiq shoppable videos as a section to any of your online store templates or as an app block to templates that support blocks (e.g product pages)</Text>
            {editorLink &&
              <Text><a href={editorLink} target='_blank' rel="noopener noreferrer">Open your Shopify Theme Editor</a> to add the shoppable video section or app block to the desired theme template (Shopfiy admin permissions are required)</Text>
            }
          </Stack>
        )
      } else {
        return (
          <Stack vertical spacing='tight'>
            <Banner status='warning'>Your online store is using the theme "{shopifyTheme?.theme?.name ?? 'unknown'}" that does not support JSON templates and app blocks</Banner>
            <Text>Adding shoppable videos inside your theme requries theme code changes by your theme admin.</Text>
            <Text>Please <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact Boutiq support</a> for further instructions.</Text>
          </Stack>
        )
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      loading={headlessConfigLoading || verifyingSections}
      title='Shoppable Videos Carousel'
    >
      <Modal.Section>
        <Stack vertical spacing='tight'>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
            <img
              alt=""
              width="70%"
              style={{
                objectFit: 'contain',
                border: '1px solid #f6f6f6',
              }}
              src="/settings/shoppable_theme_preview.jpg"
            />
          </div>
          {renderContent()}
        </Stack>
      </Modal.Section>

    </Modal>
  )

}

export default StorySettingsCard;
