import React from "react";
import { Button, Stack, TextStyle, Icon } from '@shopify/polaris';
import { CustomThumbnailImage } from "./ThumbnailImage";
import { MobileCancelMajor } from '@shopify/polaris-icons';

export const UploadingVideoItem = ({ file, isDisabled, thumbnailSource, isPreviewLoading, cancelVideo }) => {

  return (
    <div style={{ padding: '1rem' }}>
      <Stack vertical>
        <Stack>
          <CustomThumbnailImage
            disabled={isDisabled}
            isLoading={isPreviewLoading}
            alt={file.name}
            source={thumbnailSource} />
          <Stack.Item fill >
            <div style={{ opacity: isDisabled ? .2 : 1 }}>
              <Stack vertical spacing='tight'>
                <TextStyle variation="strong">
                  {file.name}
                </TextStyle>
                <TextStyle variation="subdued">{(file.size / (1024 * 1024)).toFixed(2)}&nbsp;MB</TextStyle>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button plain destructive onClick={(event) => cancelVideo(file, event)}>
              <Icon
                source={MobileCancelMajor}
                color="base" />
            </Button>
          </Stack.Item>
        </Stack>
      </Stack>
    </div>
  )
}
