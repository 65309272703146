import React, { useEffect, useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Card, Stack, Checkbox, SkeletonBodyText } from '@shopify/polaris';
import { useShopProvider } from './ShopProvider'

import useClientAppFeatures from '../hooks/useClientAppFeatures';

export default function ClientProductSearchSettings() {

    const { shopOrigin } = useShopProvider();
    const { clientAppFeatures, clientAppFeaturesLoading } = useClientAppFeatures(shopOrigin)
    const [disableClientProductSearch, setDisableClientProductSearch] = useState(false);

    const dbRef = Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('clientAppFeatures');

    useEffect(() => {
        if (clientAppFeatures) {
            setDisableClientProductSearch(clientAppFeatures?.disableClientProductSearch ?? false);
        }

    }, [clientAppFeatures])

    const onDisabledChange = (newChecked) => {
        setDisableClientProductSearch(newChecked);
        dbRef.set({ disableClientProductSearch: newChecked }, { merge: true })
            .catch(error => console.error('ClientProductSearchSettings onDisabledChange', error));
    }

    return (

        <Card sectioned title='Client product search'>
            <Stack vertical spacing='loose'>
                <p>{`Disable product search for the client app - only the host will be able to search and add products to the Boutiq call showroom`}</p>
                {clientAppFeaturesLoading && <SkeletonBodyText lines='1' />}
                {!clientAppFeaturesLoading &&
                    <Checkbox
                        label='Disable client product search'
                        checked={disableClientProductSearch}
                        onChange={onDisabledChange}
                    />}
            </Stack>

        </Card>
    );
}
