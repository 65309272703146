import React, {useEffect, useMemo, useState} from 'react';
import {
  Modal,
  Text
} from '@shopify/polaris';
import {DEFAULT_EVENT_TYPE_FILTER, DEFAULT_HOST_FILTER, NO_TYPE} from "../../../../constants/analytics";
import CombinedSelector from "../../../../components/Selectors/CombinedSelector";

const RecordingRuleModal = ({onSubmitRule, open, onClose, editedRule, rules}) => {
  const [host, setHost] = useState(DEFAULT_HOST_FILTER);
  const [eventType, setEventType] = useState(DEFAULT_EVENT_TYPE_FILTER);
  const [errorMessage, setErrorMessage] = useState('')
  const [hostId, setHostId] = useState(DEFAULT_HOST_FILTER.id)
  const [eventTypeId, setEventTypeId] = useState(DEFAULT_EVENT_TYPE_FILTER.id)
  
  const isPrimaryActionDisabled = useMemo(() => {
    if (errorMessage) {
      return true
    }
    if (editedRule) {
      return hostId === editedRule.hostId && eventTypeId === editedRule.eventTypeId
    }
  }, [hostId, eventTypeId, errorMessage, editedRule])
  
  useEffect(() => {
    return () => {
      setHost(DEFAULT_HOST_FILTER)
      setEventType(DEFAULT_EVENT_TYPE_FILTER)
      setHostId(DEFAULT_HOST_FILTER.id)
      setEventTypeId(DEFAULT_EVENT_TYPE_FILTER.id)
      setErrorMessage('')
    }
  }, [open])
  
  const onChangeHost = (value) => {
    setHost(value)
    setHostId(value.id)
    setErrorMessage('')
  }
  
  const onChangeEvent = (value) => {
    setEventType(value)
    setEventTypeId(value.id)
    setErrorMessage('')
  }
  
  const onAdd = async () => {
    if (rules.some(rule => rule.hostId === hostId && rule.eventTypeId === eventTypeId)) {
      setErrorMessage('This shop already have this rule')
    } else {
      onSubmitRule({
        hostId,
        eventTypeId,
        type: 'include',
        source: 'user'
      })
      onClose()
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={editedRule ? 'Edit recording rule' : 'Add recording rule'}
      primaryAction={{ content: editedRule ? 'Save' : 'Add', onAction: onAdd, disabled: isPrimaryActionDisabled }}
      secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
    >
      <div style={{height: 400}}>        
        {errorMessage && (
          <Modal.Section>
            <Text as={'p'} color={'critical'} variant={'bodyMd'}>{errorMessage}</Text>
          </Modal.Section>
        )}
        <Modal.Section>
          <CombinedSelector
            vertical
            withNoEventType
            maxMenuHeight={180}
            hostSelectorLabel={<Text as={'p'} variant={'bodyMd'}>{'Select host'}</Text>}
            selectedHost={host}
            onChangeHost={onChangeHost}
            eventSelectorLabel={<Text as={'p'} variant={'bodyMd'}>{'Select event'}</Text>}
            selectedEvent={eventType}
            onChangeEvent={onChangeEvent}
            initValue={editedRule}
          />
        </Modal.Section>
      </div>
    </Modal>
  )
}

export default RecordingRuleModal;