import React, {useEffect, useState} from 'react';
import {useShopProvider} from "../../ShopProvider";
import {
  Button,
  Stack,
} from '@shopify/polaris';
import {
  RefreshMajor
} from '@shopify/polaris-icons';
import {DEFAULT_EVENT_TYPE_FILTER, DEFAULT_HOST_FILTER, NO_TYPE} from "../../../constants/analytics";
import DateRangeSelector from "../DateRangeSelector";
import {HostSelector} from "../HostSelector";
import {EventTypesSelector} from "../EventTypeSelector";

const CombinedSelector = ({
    selectedHost,
    selectedEvent,
    selectedRange,
    onChangeHost,
    onChangeEvent,
    onChangeRange,
    vertical,
    maxMenuHeight,
    initValue,
    showTeam,
    withNoEventType,
    onRefresh,
    hostSelectorLabel,
    eventSelectorLabel,
    distribution = 'trailing'
}) => {
  const { hosts, shopUserTeams, eventTypes } = useShopProvider();
  const [hostList, setHostList] = useState([])
  const [eventTypesList, setEventTypesList] = useState([])
  
  useEffect(() => {
    if (initValue && hostList.length && eventTypesList.length) {
      const host = hostList.find(({id}) => id === initValue.hostId);
      const event = eventTypesList.find(({id}) => id === initValue.eventTypeId);
      onChangeHost(host)
      onChangeEvent(event)
    }
  }, [initValue, JSON.stringify(hostList), JSON.stringify(eventTypesList)])
  
  useEffect(() => {
    if (hosts.length > 0 && hostList.length !== hosts.length + 1) {
      setHostList([DEFAULT_HOST_FILTER]
        .concat(hosts.map(host => ({
          label: `${host.firstName} ${host.lastName}`,
          value: host.id, id: host.id,
          teamId: showTeam ? host.teamId : null
        }))));
    }
  }, [hosts])
  
  useEffect(() => {
    if (eventTypes && !eventTypes?.empty) {
      const eventTypesOptions = eventTypes.docs.map(eventType => {
        const data = eventType.data();
        return {
          label: data.name,
          value: eventType.id,
          id: eventType.id,
          color: data.color
        }
      })
      
      setEventTypesList(
        (withNoEventType ? [DEFAULT_EVENT_TYPE_FILTER, NO_TYPE] : [DEFAULT_EVENT_TYPE_FILTER])
          .concat(eventTypesOptions)
      );
    }
  }, [eventTypes])
  
  return (
    <Stack vertical={vertical} spacing={'loose'} distribution={distribution}>
      {onChangeHost && (
        <Stack vertical spacing={'extraTight'}>
          {hostSelectorLabel}
          <HostSelector
            maxMenuHeight={maxMenuHeight}
            hostsOptions={hostList}
            value={selectedHost}
            shopUserTeams={shopUserTeams}
            onChange={onChangeHost}
          />
        </Stack>
      )}
      {onChangeEvent && (
        <Stack vertical spacing={'extraTight'}>
          {eventSelectorLabel}
          <EventTypesSelector
            maxMenuHeight={maxMenuHeight}
            eventTypesOptions={eventTypesList}
            value={selectedEvent}
            onChange={onChangeEvent}
          />
        </Stack>
      )}
      {onChangeRange && (
        <DateRangeSelector
          range={selectedRange}
          onSelectRange={onChangeRange}
        />
      )}
      {onRefresh && (
        <Button onClick={onRefresh} icon={RefreshMajor}/>
      )}
    </Stack>
  )
}

export default CombinedSelector;