import React, { useMemo } from 'react'
import {Text, Stack} from '@shopify/polaris';
import { currencyFormatter } from '../../utils/formatters';

const ProductPrice = ({product, variant = 'headingSm', as = 'h4'}) => {

  const formatter = useMemo(() => (
    currencyFormatter(product.currencyCode, 2, 'standard')
  ), [product.currencyCode])

  if (product.maxVariantPrice !== product.minVariantPrice) {
    return (
      <Text as={as} variant={variant}>{`${formatter.format(product.minVariantPrice)} — ${formatter.format(product.maxVariantPrice)}`}</Text>
    )
  }
  return (
    <Stack spacing={'extraTight'}>
      <Text as={as} variant={variant}>{`${formatter.format(product.price)}`}</Text>
      {product.compareAtPrice && product.compareAtPrice !== product.price && (
        <Text as={as} variant={variant} color={'subdued'}>
          <span style={{textDecoration: 'line-through solid gray'}}>
            {`${formatter.format(product.compareAtPrice)}`}
          </span>
        </Text>
      )}
    </Stack>

  )
}

export default ProductPrice;
