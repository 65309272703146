
exports.getUserToken = (tokenUser) => {
    if (tokenUser) {
        return tokenUser.getIdToken(/* forceRefresh */ true).then(token => {
            return token
        }).catch(error => {
            console.error('Failed to retrieve REST API token', error);
            return null;
        })
    } else {
        return null;
    }
}