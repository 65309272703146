import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import { Stack, Badge } from '@shopify/polaris';

export const HostSelector = ({
  hostsOptions,
  value,
  shopUserTeams,
  onChange,
  maxMenuHeight
}) => {
  
  const [options, setOptions] = useState([]);
  
  useEffect(() => {
    if (shopUserTeams && hostsOptions) {
      const availableOptions = hostsOptions.reduce((acc, host) => {
        if (host.teamId) {
          const currentTeam = shopUserTeams.find(team => team.id === host.teamId);
          if (currentTeam && !acc.find(item => item.id === currentTeam.name)) {
            const hostsInTeam = currentTeam.members;
            const teamGroupOption = [{ label: currentTeam.name, value: currentTeam.name, id: currentTeam.name, optionValue: currentTeam.members, isTeamOption: true, teamId: currentTeam.id }]
            const membersInTeam = hostsInTeam.map(member => {
              const hostItem = hostsOptions.find(host => host.id === member);
              return { ...hostItem, optionValue: [hostItem.id] };
            });
            acc = [...acc, ...teamGroupOption, ...membersInTeam]
          }
        } else {
          if (!acc.find(item => item.id === host.id))
            acc = [...acc, ...[{ ...host, ...{ optionValue: [host.id] } }]]
        }
        return acc;
      }, [])
      setOptions(availableOptions)
    }
  }, [shopUserTeams, hostsOptions])
  
  const styles = {
    option: (css, state) => {
      return ({
        ...css,
        position: "relative",
        ...(state.data.teamId && !state.data.isTeamOption && {
          paddingLeft: '30px'
        }),
        ...(state.isSelected && {
          backgroundColor: '#6E76F2'
        }),
        ...(state.selectProps.value.isTeamOption && !state.data.isTeamOption && state.selectProps.value.teamId && state.selectProps.value.teamId === state.data.teamId && {
          background: '#BDC4FF',
          color: 'white'
        })
      })
    }
  };
  
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <Stack>
          <Stack.Item>
            {children}
          </Stack.Item>
          <Stack.Item>
            {props.data.teamName && <Badge>{props.data.teamName}</Badge>}
          </Stack.Item>
        </Stack>
      </components.Option>
    );
  };
  
  return (
    <div style={{ minWidth: 200 }}>
      <Select
        options={options}
        onChange={onChange}
        value={value}
        styles={styles}
        maxMenuHeight={maxMenuHeight}
        components={{ Option }} />
    </div>
  )
}
