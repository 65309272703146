import React, { useState, useEffect } from 'react';
import { Stack, ContextualSaveBar, Collapsible, Button, Subheading, TextStyle, Checkbox, Caption, InlineError, SkeletonBodyText } from '@shopify/polaris';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Moment from 'moment-timezone';

import { useShopProvider } from './ShopProvider';
import { useAppAuthState } from '../authState'
import TimeField from "./TimeField";
import {validateTime, isIncorrectTimePeriod} from "../utils/time";
import {isSafariBrowser} from "../utils/system";
import {isInvalid} from "../utils/strings";
import {TIME_PATTERN} from "../constants/time";
import {
    PlusMinor,
    MinusMinor,
  } from '@shopify/polaris-icons';

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export function isBusinessHoursValid(config) {
    return config && Object.keys(config.activeDays).reduce((agg, day) => agg || config.activeDays[day],false);

}

export function BusinessHoursSummary({config}) {

    const exceptionDays = DAYS.filter(day=> !config.activeDays[day]);
    const isException = exceptionDays.length > 0 && exceptionDays.length < 7;
    const isClosed = exceptionDays.length >= 7;

    return (
        <Stack>
            {!isClosed
                ? <TextStyle variation='strong'> Every day {isException && 'except: '} {exceptionDays.join(', ')}</TextStyle>
                : <TextStyle variation='negative'> No business hours </TextStyle>
            }
            {!config.isCustomConfig && !isClosed && <p>{config.openTime} to {config.closeTime}</p>}
            {config.isCustomConfig && !isClosed && <p>custom business hours</p>}
        </Stack>
    )
}

export function isInBusinessHours(config, testDate) {

    if (!config) return false;

    const testMoment = Moment(testDate).tz(config.timezone);
    const testDay = testMoment.format('ddd');

    if (!config.activeDays[testDay]) return false;
    if (config.isCustomConfig) {
        for (const range of config.customHours[testDay]) {
            const openTime = range.openTime.split(':');
            const closeTime = range.closeTime.split(':');

            const begin = Moment(testMoment).hours(openTime[0]).minutes(openTime[1]);
            const end = Moment(testMoment).hours(closeTime[0]).minutes(closeTime[1]);
            if (testMoment >= begin && testMoment < end) return true;
        }
        return false;
    } else {
        const openTime = config.openTime.split(':');
        const closeTime = config.closeTime.split(':');

        const begin = Moment(testMoment).hours(openTime[0]).minutes(openTime[1]);
        const end = Moment(testMoment).hours(closeTime[0]).minutes(closeTime[1]);
        return testMoment >= begin && testMoment < end;
    }
}

export function isBusinessHoursActiveDay(config, testDate) {

    if (!config) return false;

    const testMoment = Moment(testDate);
    const testDay = testMoment.format('ddd');

    return (config.activeDays[testDay]);
}

function CustomHours({ isCustomConfig, activeDays, setDirtyBit, setErrorBit, customHours, setCustomHours, errorBit }) {

    const [timeError, setTimeError] = useState(DAYS.reduce((agg, day) => {
        agg[day] = false;
        return agg;
    }, {}));

    useEffect(() => {
        if (timeError) {
            setErrorBit(Object.values(timeError).some(item => item))
        }
     }, [timeError])

    const checkForError = (id, { openTime, closeTime }) => {
        const newErrors = { ...timeError }
        const errorRule = isIncorrectTimePeriod(closeTime, openTime);
        newErrors[id] = errorRule;
        setErrorBit(errorRule);
        setTimeError(newErrors);
    }

    const onChangeCustomOpen = (event, day, index) => {
        const time = validateTime(event);
        const newCustomHours = { ...customHours };
        newCustomHours[day][index] = { ...newCustomHours[day][index], openTime: time };
        setCustomHours(newCustomHours);
        checkForError(day, newCustomHours[day][index]);
        setDirtyBit(true);
    }

    const onChangeCustomClose = (event, day, index) => {
        const time = validateTime(event);
        const newCustomHours = { ...customHours };
        newCustomHours[day][index] = { ...newCustomHours[day][index], closeTime: time };
        setCustomHours(newCustomHours);
        checkForError(day, newCustomHours[day][index]);
        setDirtyBit(true);
    }

    const addTimeRange = (day) => {
        const newTimeRange = {'openTime': '', 'closeTime': ''};
        setCustomHours({ ...customHours, ...{[day]: [...customHours[day], newTimeRange]}});
        setDirtyBit(true);
        const newErrors = { ...timeError }
        newErrors[day] = true;
        setTimeError(newErrors);
    }

    const removeTimeRange = (day, index) => {
        const targetDay = [...customHours[day]];
        const removedTimeRange = targetDay.splice(index, 1).find(() => true);
        const errorRule = isIncorrectTimePeriod(removedTimeRange.closeTime, removedTimeRange.openTime);
        if (errorRule) {
            const newErrors = { ...timeError }
            newErrors[day] = false;
            setTimeError(newErrors);
        }
        setCustomHours({...customHours, ...{[day]: targetDay}});
        setDirtyBit(true);
    }

    return (
        <Collapsible
            open={isCustomConfig}
            id="basic-collapsible"
            transition={{ duration: '150ms', timingFunction: 'ease' }}
        >
            <Stack vertical>
               {DAYS.map((day) => {
                    return (
                        customHours[day].length > 0 ?
                            <Stack key={day} alignment='baseline' spacing='tight'>
                                <div style={{ width: '32px' }}>
                                    <Subheading>{day}</Subheading>
                                </div>
                                <Stack vertical>
                                    {customHours[day].map(((range, index) => (
                                        <Stack key={index} alignment='center'>
                                            <TimeField id={day} value={range && range.openTime} onChange={(e) => onChangeCustomOpen(e, day, index)} disabled={!activeDays[day]} />
                                            <TimeField id={day} value={range && range.closeTime} onChange={(e) => onChangeCustomClose(e, day, index)} disabled={!activeDays[day]} />
                                            {index > 0 ?
                                                <Button onClick={() => removeTimeRange(day, index)} icon={MinusMinor}></Button> :
                                                <Button onClick={() => addTimeRange(day)} icon={PlusMinor} disabled={errorBit}></Button>}
                                            {timeError[day] && <InlineError message={getErrorMessage(range.openTime, range.closeTime, day)} />}
                                        </Stack>
                                        )))}
                                </Stack>
                        </Stack> :


                         <Stack key={day} alignment='baseline' spacing='tight'>
                         <div style={{ width: '32px' }}>
                             <Subheading>{day}</Subheading>
                         </div>
                         <Stack vertical>
                                 <Stack alignment='center'>
                                     <TimeField id={day} value={''} disabled />
                                     <TimeField id={day} value={''} disabled />
                                 </Stack>
                         </Stack>
                 </Stack>
                        )
                })}
            </Stack>
        </Collapsible>
    )
}

const getErrorMessage = (openTime, closeTime, day) => { // TODO: move strings to i18n
    const isInvalidOpenTime = isSafariBrowser() ? isInvalid(openTime, TIME_PATTERN) : openTime === '';
    const isInvalidCloseTime = isSafariBrowser() ? isInvalid(closeTime, TIME_PATTERN) : closeTime === '';
    if (closeTime <= openTime || (isInvalidOpenTime && isInvalidCloseTime)) {
        return 'Close time must be after open time';
    } else if (isInvalidOpenTime) {
        return 'Open time is incorrect';
    } else if (isInvalidCloseTime) {
        return 'Close time is incorrect';
    }
}

export default function BusinessHours({ onDiscard }) {

    const { shopData, businessHoursConfig, businessHoursLoading } = useShopProvider();
    const [dirtyBit, setDirtyBit] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [errorBit, setErrorBit] = useState(false);
    const [openTime, setOpenTime] = useState('09:00');
    const [closeTime, setCloseTime] = useState('19:00');
    const [isCustomConfig, setIsCustomConfig] = useState(false);
    const [timeError, setTimeError] = useState(false);
    const [activeDays, setActiveDays] = useState(DAYS.reduce((agg, day) => {
        agg[day] = true;
        return agg;
    }, {}));

    const [customHours, setCustomHours] = useState(DAYS.reduce((agg, day) => {
        agg[day] = activeDays[day] ? [{ openTime, closeTime }] : [];
        return agg;
    }, {}));

    const { shopOrigin } = useAppAuthState();

    useEffect(()=>{
        if( !businessHoursConfig) return;

        setIsCustomConfig(businessHoursConfig.isCustomConfig);
        setOpenTime(businessHoursConfig.openTime);
        setCloseTime(businessHoursConfig.closeTime);

        const newActiveDays = {}
        Object.keys(activeDays).forEach(day=> {
            newActiveDays[day] = businessHoursConfig.activeDays[day];
        })
        setActiveDays(newActiveDays);

        const newCustomHours = {}
        if (businessHoursConfig.isCustomConfig) {
            Object.keys(customHours).forEach(day=>{
                newCustomHours[day] = businessHoursConfig.customHours[day];
            })
        } else {
            Object.keys(customHours).forEach(day=>{
                newCustomHours[day] = newActiveDays[day] ? [{ openTime: businessHoursConfig.openTime, closeTime: businessHoursConfig.closeTime}] : [];
            })
        }
        setCustomHours(newCustomHours);

    },[businessHoursConfig])

    useEffect(() => {
        const errorRule = isIncorrectTimePeriod(closeTime, openTime);
        setTimeError(errorRule);
        setErrorBit(errorRule);
    }, [closeTime, openTime])

    const onOpenTimeChange = (val) => {
        const time = validateTime(val);
        setDirtyBit(true);
        setOpenTime(time);
        if (!isCustomConfig) {
            setCustomHours(DAYS.reduce((agg, day) => {
                agg[day] = activeDays[day] ? [{ openTime: time, closeTime }] : [];
                return agg;
            }, {}));
        }
    }

    const onCloseTimeChange = (val) => {
        const time = validateTime(val);
        setDirtyBit(true);
        setCloseTime(time);
        if (!isCustomConfig) {
            setCustomHours(DAYS.reduce((agg, day) => {
                agg[day] = activeDays[day] ? [{ openTime, closeTime: time }] : [];
                return agg;
            }, {}));
        }
    }

    const onDayChecked = (val, id) => {
        const newActiveDays = { ...activeDays }
        newActiveDays[id] = val;
        setActiveDays(newActiveDays);
        if (isCustomConfig) {
            setCustomHours(DAYS.reduce((agg, day) => {
                agg[day] = day !== id ? customHours[day] : val ? [{ openTime, closeTime }] : [];
                return agg;
            }, {}));
        }
        setDirtyBit(true);
    }

    const onResetCustomConfig = () => {
        setCustomHours(DAYS.reduce((agg, day) => {
            agg[day] = activeDays[day] ? [{ openTime, closeTime }] : [];
            return agg;
        }, {}));
        setDirtyBit(true);
        setErrorBit(false);
    }

    const toggleCustomConfig = () => {
        setIsCustomConfig(isCustomConfig => !isCustomConfig);
        setDirtyBit(true);
    }

    const save = () => {
        setSaving(true);
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('businessHours').set({
            activeDays,
            openTime,
            closeTime,
            isCustomConfig,
            customHours: isCustomConfig ? customHours : null,
            timezone: shopData.ianaTimezone || null,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        }).catch(error => {
            console.error('Failed to save business hours', error);
        }).finally(() => {
            setSaving(false);
            onDiscard();
        });
    }

    if (businessHoursLoading) {
        return <SkeletonBodyText lines={4}/>
    }

    return (
        <>
            <Stack spacing='extraLoose'>
                <Stack vertical spacing='tight'>
                    <Subheading>Active days</Subheading>
                    <Stack spacing='tight'>
                        {DAYS.map(day => (
                            <Stack key={day} vertical alignment='center' spacing='extraTight'>
                                <Stack.Item>{day}</Stack.Item>
                                <Checkbox id={day} checked={activeDays[day]} onChange={onDayChecked} />
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
                <Stack vertical spacing='tight'>
                    <Subheading>Active hours</Subheading>
                    <Stack distribution='fill'>
                        <TimeField id='open_time' label='Open time' value={openTime} onChange={onOpenTimeChange} disabled={isCustomConfig} />
                        <TimeField id='close_time' label='Close time' value={closeTime} onChange={onCloseTimeChange} disabled={isCustomConfig} />
                    </Stack>
                    <Caption>Timezone:{' '}<TextStyle variation='subdued'>{shopData.ianaTimezone}</TextStyle> </Caption>
                    {timeError && <InlineError message={getErrorMessage(openTime, closeTime)} />}
                </Stack>
            </Stack>
            <div style={{ marginTop: '2rem' }}>
                <Stack vertical>
                    <Stack>
                        <Button disabled={errorBit} plain onClick={toggleCustomConfig}>Custom</Button>
                        {isCustomConfig && <Button plain destructive onClick={onResetCustomConfig}>Reset</Button>}
                    </Stack>
                    <CustomHours isCustomConfig={isCustomConfig}
                        activeDays={activeDays}
                        customHours={customHours}
                        setCustomHours={setCustomHours}
                        setDirtyBit={setDirtyBit}
                        errorBit={errorBit}
                        setErrorBit={setErrorBit}
                    />
                </Stack>
            </div>
            {dirtyBit && <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                    onAction: save,
                    disabled: errorBit,
                    loading: isSaving,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: isSaving,
                }}
            />}
        </>
    )

}
