import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { csvBuilder, csvLoader, mapCallToCsvTable, tableColumnNames } from '../utils/csvProcessor';
import { useRef, useState } from 'react';


const EXPORT_STATUS = {
  SUCCEED: 'Succeed',
  PENDING: 'Pending',
  NO_DATA: 'No data to export',
  ERROR: 'Something went wrong - '
}

const useExportCalls = (getHistoryCalls, shopOrigin, filter) => {
  const csvFileRef = useRef('');

  const [exportStatus, setExportStatus] = useState(null);
  const [exportError, setExportError] = useState(null);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const toCsvConverter = (docs) => {
    const dataRows = docs.map(doc => {
      return mapCallToCsvTable(doc);
    })
    return csvBuilder(dataRows);
  }

  const csvProcessor = (docs, next) => {
    const csvBatch = toCsvConverter(docs);
    csvFileRef.current += csvBatch;
    if (!next) {
      if (!csvFileRef.current) {
        setExportStatus(EXPORT_STATUS.NO_DATA);
      } else {
        csvFileRef.current = tableColumnNames() + csvFileRef.current;
        setExportStatus(EXPORT_STATUS.SUCCEED);
        csvLoader(csvFileRef.current,`${shopOrigin}_`);
      }
      setIsExportInProgress(false);
    } else {
      next().then(({ docs, next }) => {
        csvProcessor(docs, next);
      }).catch((error) => {
        setExportStatus(EXPORT_STATUS.ERROR);
        setExportError(error);
        setIsExportInProgress(false);
      });
    }
  }

  const exportHistoryCalls = () => {
    csvFileRef.current = '';
    setExportError(null);
    setExportStatus(EXPORT_STATUS.PENDING);
    setIsExportInProgress(true);

    firebase.analytics().logEvent('admin.export_call_history', { range: filter.range?.id });

    getHistoryCalls(400)
      .then(({ docs, next }) => {
        csvProcessor(docs, next);
      })
      .catch((error) => {
        setExportStatus(EXPORT_STATUS.ERROR);
        setExportError(error);
        setIsExportInProgress(false);
      });
  }

  return ({
    isExportInProgress,
    exportStatus,
    exportError,
    exportHistoryCalls
  })
}

export default useExportCalls;
