import AdminPage from "../../../../components/AdminPage";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppPage from "../../../../components/AppPage";
import {
  ContextualSaveBar,
  Layout,
} from '@shopify/polaris';
import { StoryPreviewModal } from "../../components/StoryPreviewModal";
import './styles.css'
import { VIDEO_STATUS } from '../../../../constants/story';
import DeleteStoryModal from '../../components/VideoDeleteModal';
import { useStoriesProvider } from '../../components/StoryiesProvider';
import StorySettingsCard from './components/StorySettingsCard';
import StoryMediaCard from '../../components/StoryMediaCard';
import { useShopProvider } from '../../../../components/ShopProvider';
import ShoppableWidgetPreview from './components/ShoppableWidgetPreview';

const EditStoryPage = () => {
  const history = useHistory();
  const location = useLocation();
  const {storyId} = location.state ?? {}
  const {
    selectedStory,
    onChangeSelectedStory,
    onSaveStory,
    shopVideoStories,
    onDeleteStory,
    isStoriesChangeLoading,
    isNewStory,
    currentStory,
    isSaveVisible,
    isConfigurationValid,
    onDiscardStoryChanges,
    onViewStoryPage,
    shoppablePreviewVisible,
    setShoppablePreviewVisible,
  } = useStoriesProvider();
  const {shopVideos} = useShopProvider();

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      setShoppablePreviewVisible(false)
    }
  }, [])

  useEffect(() => {
    if (shopVideoStories.length) {
      onChangeSelectedStory(shopVideoStories.find((story) => story.storyId === storyId))
    }
  }, [JSON.stringify(shopVideoStories), storyId])

  const onSave = async () => {
    if (!isConfigurationValid()) {
      return
    }
    const storyId = await onSaveStory(currentStory)
    history.replace({pathname: '/stories/sequence', state: {storyId}})
  }

  const onDiscard = () => {
    onDiscardStoryChanges()
  }

  const onDelete = async () => {
    onDeleteStory(selectedStory.storyId)
    setIsDeleteDialogOpen(false);
    onBack()
  }

  const onBack = () => {
    onChangeSelectedStory(null)
    history.goBack()
  }

  return (
    <AppPage
      fullWidth
      breadcrumbs={[{content: 'Back', onAction: onBack}]}
      title={isNewStory ? 'Create new shoppable video' : `Edit shoppable video: ${selectedStory.title}`}
    >
      <Layout>
        <StoryMediaCard
          onPreview={() => setIsPreviewDialogOpen(true)}
          onViewStoryPage={() => onViewStoryPage(selectedStory)}
          onDelete={() => setIsDeleteDialogOpen(true)}
          settingsCard={<StorySettingsCard />}
        />
      </Layout>
      <StoryPreviewModal
        stories={currentStory.videos.filter(video => video.data.status !== VIDEO_STATUS.DELETED)}
        isOpen={isPreviewDialogOpen}
        setIsOpen={setIsPreviewDialogOpen}
        isBoutiqClientStory={currentStory.useAsBoutiqClientStory}
      />
      <DeleteStoryModal
        open={isDeleteDialogOpen}
        title={'Confirm delete story'}
        subTitle={'Are you sure you want to delete this story? This action cannot be undone'}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onDelete={onDelete}
      />
      {isSaveVisible && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: onSave,
            disabled: shopVideos[0]?.data?.status !== VIDEO_STATUS.READY,
            loading: isStoriesChangeLoading,
          }}
          discardAction={{
            disabled: shopVideos[0]?.data?.status !== VIDEO_STATUS.READY,
            onAction: onDiscard
          }}
        />
      )}
      {shoppablePreviewVisible> 0 && <ShoppableWidgetPreview />}
    </AppPage>
  )
}

export default () => {
  return (
    <AdminPage>
      <EditStoryPage/>
    </AdminPage>
  )
}
