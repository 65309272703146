import React from 'react';
import Handlebars from 'handlebars';
import Moment from 'moment-timezone';

import { Card, Stack, TextStyle} from '@shopify/polaris';

export default function HeaderPreview({ shopData, shopNotifications, templateData, locale }) {
    const replyTo = Object.assign({name:  shopData.name, email: shopData.contactEmail}, shopNotifications.replyTo)
    const formattedTimestamp = Moment().tz(shopData.ianaTimezone).locale(locale).format('LLLL (z)');
    const subject = Handlebars.compile(templateData.subject);

    return (
        <Card.Section>
            <Stack vertical>
                <p>From: {shopData.name} {'<no-reply@getboutiq.com>'} </p>
                <p>Reply-to: {replyTo.name} {`<${replyTo.email}>`}</p>
                <p><TextStyle variation='strong' >Subject: {subject({ shopName: shopData.name, formattedTimestamp  })}</TextStyle></p>
            </Stack>
        </Card.Section>)
}