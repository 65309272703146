import React from 'react'
import { Card, DisplayText, TextStyle, Spinner, Subheading, Stack, Icon, Tooltip } from '@shopify/polaris';
import { InfoMinor, ArrowUpMinor, ArrowDownMinor } from '@shopify/polaris-icons';

const chartPercentFormatter = (value) => `${value.toFixed(2)}%`

function DashboardKPI({ kpi, isLoading }) {

    const formattedKPI = kpi.value ? kpi.render(kpi.value) : '-'
    const customRender = kpi.customRender ? kpi.customRender : null;
    const compareRatio = kpi.value != null && kpi.previousValue && ((kpi.value - kpi.previousValue) / Math.abs(kpi.previousValue)) * 100;

    const compareColor = kpi.comparisonColor(compareRatio);
    const compareIcon = compareRatio > 1.0 ? ArrowUpMinor : ArrowDownMinor;
    const formattedComparison = compareRatio
        ? <Stack alignment='center' wrap={false} spacing='extraTight'>
            <div style={{ color: compareColor !== null ? compareColor === 'success' ? 'green' : 'red' : null }}>
                {' '}{chartPercentFormatter(compareRatio)}
            </div> : null
        <Icon source={compareIcon} color={compareColor} />
        </Stack>
        : null

    const KPITitle = () => (
        <Stack alignment='center'>
            <Subheading>{kpi.name}</Subheading>

            {kpi.description && <Tooltip light content={kpi.description}>
                <Icon source={InfoMinor} color='orange'/>
            </Tooltip>}
        </Stack>)

    return (
        <Card.Section title={<KPITitle />}>
            {!isLoading &&
                <Stack alignment='baseline' spacing='extraTight'>
                   {customRender ? 
                        customRender(kpi.render(kpi.value)) : 
                        <DisplayText>
                            <TextStyle variation='strong'>{formattedKPI}</TextStyle>
                        </DisplayText>}
                    {formattedComparison}
                </Stack>
            }
            {isLoading && <Spinner size='small' />}
        </Card.Section>
    )

}

export default function DashboardHeader({ isLoading, kpis }) {
    return (
        <Card>
            <div className='dashboard-header'>
                {Object.keys(kpis).filter(kpi => kpis[kpi].display).map(kpi => <DashboardKPI key={kpi} kpi={kpis[kpi]} isLoading={isLoading} />)}
            </div>
        </Card>
    )
}
