import React from "react";
import { CollapsibleElement } from "../collapsibleItem";
import { ScheduledTag } from "../scheduled-tag/ScheduledTag";
import './styles.css';

export const ListItem = ({
    countOfNonCollapsibleRows = 4,
    onClick,
    isActive,
    label,
    subline,
    description,
    specialTag,
    children,
    alignment = 'flex-start',
    disabled
}) => {

    return (
        <div
            onClick={onClick}
            className={`list-item-wrapper${isActive ? ' active' : ''}${disabled ? ' disabled' : ''}`}>
            {children}
            <div
                className='list-item'
                style={{ justifySelf: alignment }}>
                <p className='label'>{label}</p>
                {subline && <p className='subline'>{subline}</p>}
                {description && <CollapsibleElement
                    countOfNonCollapsibleRows={countOfNonCollapsibleRows}
                    text={description} />}
            </div>
            {specialTag && <ScheduledTag />}
        </div>
    )
}
