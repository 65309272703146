import React, { useEffect, useState } from 'react';
import './styles.css'
import { DEFAULT_STORIES_WIDGET_SIZE } from '../../pages/StoryProductConfig/consts';
import CollapseProductPreview from './components/CollapseProductPreview';
import ExpandProductPreview from './components/ExpandProductPreview';

const StoryProductsPreview = ({products = [], videoElement}) => {
  const [currentTime, setCurrentTime] = useState(null)
  const [hoverProductIndex, setHoverProductIndex] = useState(null)

  useEffect(() => {
    if (videoElement?.current) {
      videoElement.current.addEventListener('timeupdate', onChangeTime);
    }

    return () => {
      if (videoElement?.current) {
        videoElement.current.removeEventListener('timeupdate', onChangeTime);
      }
    }
  }, [videoElement])

  const onChangeTime = () => {
    setCurrentTime(videoElement?.current?.currentTime)
  }

  const onHover = (index) => {
    setHoverProductIndex(index)
  }

  const onHoverOut = () => {
    setHoverProductIndex(null)
  }

  return (
    products.map((product, index) => (
      product.storiesConfig.size === DEFAULT_STORIES_WIDGET_SIZE ? (
          <CollapseProductPreview
            key={index}
            product={product}
            index={index}
            onHover={onHover}
            onHoverOut={onHoverOut}
            hoverProductIndex={hoverProductIndex}
            products={products}
            currentTime={currentTime}
          />
        ) : (
          <ExpandProductPreview
            key={index}
            product={product}
            index={index}
            products={products}
            currentTime={currentTime}
          />
        )
    ))
  )
}

export default StoryProductsPreview;
