import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HostSettingsPage from './index';
import CustomerProfileSettings from './CustomerProfileSettings';
import DraftOrderSettings from './DraftOrders';

export default function HostSettingsRouter({}) {
    return (
        <Switch>
            <Route path='/hostSettings/clientProfile'>
                <CustomerProfileSettings/>
            </Route>
            <Route path='/hostSettings/draftOrders'>
                <DraftOrderSettings/>
            </Route>                        
            <Route path='/hostSettings/'>
                <HostSettingsPage />
            </Route>
        </Switch>
    )
}
