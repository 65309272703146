import React, { useState, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, Stack, Button, Toast, List, Collapsible, Banner, Icon } from '@shopify/polaris';

import {
    DuplicateMinor,
    ChevronDownMinor,
    ChevronUpMinor,
    TransferMajor,
} from '@shopify/polaris-icons';

import { useShopProvider } from '../../../../components/ShopProvider';

export default function AddButton() {
    const { shopOrigin } = useShopProvider();

    const [showToast, setShowToast] = useState(false);
    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);
    const toastMarkup = showToast ? (
        <Toast content="Code copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;

    const [themeDetails, showThemeDetails] = useState(false);
    const [editorDetails, showEditorDetails] = useState(false);
    const [otherDetails, showOtherDetails] = useState(false);

    const themeOpenExample = `<button onclick="boutiqApp.open()">Click to open</button>`
    const CopyThemeOpenAction = () =>
        <CopyToClipboard text={themeOpenExample} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const themePresetExample = `<button onclick="boutiqApp.openPreset('INSERT_CODE_HERE')">Click to open</button>`
    const CopyThemePresetAction = () =>
        <CopyToClipboard text={themePresetExample} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const editorOpenExmaple = `<a href="javascript:boutiqApp.open()">Click to open</a>`;
    const CopyEditorOpenAction = () =>
        <CopyToClipboard text={editorOpenExmaple} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const editorPresetExmaple = `<a href="javascript:boutiqApp.openPreset('INSERT_CODE_HERE')">Click to open</a>`;
    const CopyEditorPresetAction = () =>
        <CopyToClipboard text={editorPresetExmaple} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const otherOpenCode = `javascript:boutiqApp.open()`;
    const CopyOtherOpenAction = () =>
        <CopyToClipboard text={otherOpenCode} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const otherPresetCode = `javascript:boutiqApp.openPreset('INSERT_CODE_HERE')`;
    const CopyOtherPresetAction = () =>
        <CopyToClipboard text={otherPresetCode} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>


    return (
        <Card.Section title="Activate Boutiq from a button (recommended)">
            <Stack vertical>
                <p> Use a Boutiq action as the "onclick" event handler of an HTML button or other clickable element:</p>
                {/* <Banner>This is the recommended option and will allow you to add Boutiq actions to any page of your store - homepage, product pages, etc.</Banner> */}
                {/* <Button plain icon={themeDetails ? ChevronUpMinor : ChevronDownMinor} onClick={() => showThemeDetails(prev => !prev)}>{themeDetails ? 'Hide' : 'Show'} examples</Button> */}
                <Stack vertical>
                    <Stack vertical>
                        <p> (a) Open Boutiq using the global configuration:</p>
                        <Stack alignment='center'>
                            <span className='code-snippet code-background'>{themeOpenExample}</span>
                            <CopyThemeOpenAction />
                        </Stack>
                        <p> (b) Open Boutiq using a call link configuration:</p>
                        <Stack alignment='center'>
                            <span className='code-snippet code-background'>{themePresetExample}</span>
                            <CopyThemePresetAction />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {toastMarkup}
        </Card.Section>
        /* 
        <Card.Section title="Using the Shopify admin page editor">
            <Stack vertical>
                <Stack alignment='center'>
                    <p>Add a Boutiq action to a page created with the Shopify Admin page editor </p>
                    <Button plain icon={editorDetails ? ChevronUpMinor : ChevronDownMinor} onClick={() => showEditorDetails(prev => !prev)}>{editorDetails ? 'Hide' : 'Show'} steps</Button>
                </Stack>
                <Collapsible open={editorDetails} id='editorSection'>
                    <List type='number'>
                        <List.Item>
                            <Stack spacing='tight'>
                                <p>Open the page in the <a href={`https://${shopOrigin}/admin/pages`} target='_blank' rel="noopener noreferrer">Shopify page editor</a></p>
                                <Icon source={TransferMajor} color='subdued'/>
                            </Stack>
                        </List.Item>
                        <List.Item>In the page content view change to HTML mode by selecting Show HTML.</List.Item>
                        <List.Item>
                            <Stack vertical>
                                <p>Add an HTML link element to the page HTML content and use a Boutiq action as the target URL for an HTML link.</p>                                    
                                <p>(a) Open Boutiq using the global configuration:</p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{editorOpenExmaple}</span>
                                    <CopyEditorOpenAction />
                                </Stack>
                                <p>(b) Open Boutiq using a call link configuration:</p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{editorPresetExmaple}</span>
                                    <CopyEditorPresetAction />
                                </Stack>
                                <Banner>
                                    <p>The "javascript" prefix is important so that the link doesn't result in navigating away from the current page.</p>
                                </Banner>

                            </Stack>
                        </List.Item>
                    </List>
                </Collapsible>
            </Stack>
        </Card.Section>
        <Card.Section title="Using other visual page editors">
            <Stack vertical>
                <Stack alignment='center'>
                    <p>Add a Boutiq action to a page created with other visual page editors (e.g Shogun)</p>
                    <Button plain icon={otherDetails ? ChevronUpMinor : ChevronDownMinor} onClick={() => showOtherDetails(prev => !prev)}>{otherDetails ? 'Hide' : 'Show'} steps</Button>
                </Stack>
                <Collapsible open={otherDetails} id='eotherEditorSection'>
                    <List type='number'>
                        <List.Item>Open the page in the page editor.</List.Item>
                        <List.Item>Add a button element to the page.</List.Item>
                        <List.Item>
                            <Stack vertical>
                                <p>Set the button's URL (target page).</p>
                                <p>(a) Open Boutiq using the global configuration:</p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{otherOpenCode}</span>
                                    <CopyOtherOpenAction />
                                </Stack>
                                <p>(b) Open Boutiq using a call link configuration:</p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{otherPresetCode}</span>
                                    <CopyOtherPresetAction />
                                </Stack>
                                <Banner>
                                    <p>The "javascript" prefix is important so that the link doesn't result in navigating away from the current page.</p>
                                </Banner>
                            </Stack>
                        </List.Item>

                    </List>
                </Collapsible>
            </Stack>
        </Card.Section> */
    )
}