import { Thumbnail, Box } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import React from 'react';
import useStoriesProductStyle from '../../../../../../hooks/useStoriesProductStyle';
import StoryPreviewProductDescription from '../StoryPreviewProductDescription';

const ExpandProductPreview = ({product, index, products, currentTime}) => {
  const {color, size} = product.storiesConfig;
  const {top, bottom, left, right} = useStoriesProductStyle(size, products, product, currentTime);

  return (
    <Box
      key={index}
      className={`product-preview expanded ${top < 0 || bottom < 0 ? 'hidden' : ''}`}
      style={{
        top: Number.isInteger(top) ? `${top}px` : 'auto',
        bottom: 'auto',
        left,
        right,
        backgroundColor: `${color}99`, //60% bg opacity
      }}
    >
      <Thumbnail
        transparent={!!product.image}
        source={product.image ?? ImageMajor}
        size={"large"}
        alt={product.title}
      />
      <StoryPreviewProductDescription product={product} />
    </Box>
  )
}

export default ExpandProductPreview;
