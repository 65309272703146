import React, { useRef, useState, useEffect } from 'react';
import { Avatar } from '@shopify/polaris';
import { useCreateNewAppointmentProvider } from '../../../../../../components/CreateNewAppointmentProvider';
import { ListItem } from '../listItem';
import { smoothScrollBySteps } from '../../../../../../utils/smooth-scroll';

export const HostDataTab = () => {

    const { selectedHostId, setSelectedHostId, availableHosts, setFormError, preselectedData } = useCreateNewAppointmentProvider();
    const contentRef = useRef(null);
    const wrapperRef = useRef(null);
    const [preselected, setPreselected] = useState(false);

    useEffect(() => {
        if (wrapperRef.current &&
            contentRef.current &&
            availableHosts.length > 0 &&
            !preselected) {
            setPreselected(true);
            let index = 0;
            if (selectedHostId) {
                index = availableHosts.findIndex((item => item.id === selectedHostId))
            } else if (preselectedData) {
                index = availableHosts.findIndex((item => item.id === preselectedData.hostId))
            }
            if (index > 0) {
                const targetEl = contentRef.current.childNodes.item(index + 1);
                if (targetEl && targetEl.offsetTop > 0) {
                    smoothScrollBySteps(
                        wrapperRef.current,
                        wrapperRef.current.scrollTop,
                        targetEl.offsetTop - 70
                    ).then(() => { })
                }
            }
        }
    }, [
        preselectedData,
        contentRef.current,
        wrapperRef.current,
        availableHosts,
        preselected])

    return (
        <div ref={wrapperRef} style={{ height: '100%', overflowY: 'auto' }}>
            <div ref={contentRef}>
                {availableHosts.map((host, index) =>
                    <ListItem key={index}
                        onClick={() => { setSelectedHostId(host.id); setFormError(null); }}
                        isActive={host.id === selectedHostId}
                        label={`${host.firstName} ${host.lastName}`}
                        description={!host.haveAvailableSlots && 'no available slots'}
                        disabled={!host.haveAvailableSlots}
                        specialTag={preselectedData && preselectedData.hostId === host.id}
                        countOfNonCollapsibleRows={2}>
                        <Avatar source={host.avatarUrl} name={`${host.firstName} ${host.lastName}`} initials={host.initials} />
                    </ListItem>
                )}
            </div>
        </div>
    )
}
