import ChartTitle from '../../../../../../components/charts/ChartTitle';
import { CHARTS_LABELS } from '../../../../../../constants/analytics';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import CustomizedDateAxisTick from '../../../../../../components/charts/CustomizedDateAxisTick';
import { Card } from '@shopify/polaris';
import React, { useEffect, useMemo, useState } from 'react';
import { numberFormatter } from '../../../../../../utils/formatters';
import Moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import { storiesEventsAnalytics, storiesPlaytimeAnalytics } from '../../../../../../cubeManager/queries/stories';
import { useShopProvider } from '../../../../../../components/ShopProvider';
import { useCubeProvider } from '../../../../../../cubeManager';
import { storyAnalyticsDataAggregation } from '../../../../../../utils/analytics';

const StoriesAnalyticsChart = ({startDate, endDate}) => {
  const {cubeOptions, onCubeError} = useCubeProvider()
  const {shopData} = useShopProvider();

  const {resultSet, error, isLoading} = useCubeQuery([
    storiesEventsAnalytics(shopData.ianaTimezone, startDate, endDate),
    storiesPlaytimeAnalytics(shopData.ianaTimezone, startDate, endDate)
  ], cubeOptions);

  const margin = {top: 32, right: 16, left: 16, bottom: 8}
  const colors = ["#FFBE88", "#6E76F2", '#3dc3737F'];
  const legendWrappedStyle = {top: 0, paddingBottom: 10};

  const [formattedData, setFormattedData] = useState([
    {clicks: 0, views: 0, playtime: 0, date: Moment(startDate).format('YYYY-MM-DD')},
    {clicks: 0, views: 0, playtime: 0, date: Moment(endDate).format('YYYY-MM-DD')}
  ])

  const tickInterval = useMemo(() => parseInt(formattedData.length / 15), [formattedData.length]);

  useEffect(() => {
    if (resultSet?.loadResponse?.results) {
      const data = storyAnalyticsDataAggregation(resultSet, (item) => {
        if (Object.keys(item).includes('StoryPlaytime.playtime')) {
          return ({
            playtime: parseFloat(item['StoryPlaytime.playtime']),
            date: Moment(item['StoryPlaytime.eventTimestamp']).format('YYYY-MM-DD')
          })
        }
        return ({
          clicks: item['StoryEvents.clicks'],
          views: item['StoryEvents.views'],
          date: Moment(item['StoryEvents.eventTimestamp']).format('YYYY-MM-DD')
        })
      })
        .sort((a, b) => Moment(a.date).diff(Moment(b.date)))
      setFormattedData(data)
    }
  }, [resultSet])

  useEffect(() => {
    onCubeError(error)
  }, [error])

  const chartNumberFormatter = (value) => numberFormatter().format(value) || null;

  const chartPlaytimeFormatter = (decimals) => (value) => (value / 60).toFixed(decimals || 0);

  return (
    <Card
      title={(
        <ChartTitle title={CHARTS_LABELS.VIDEO_STATISTICS} isLoading={isLoading}/>
      )}
    >
      <ResponsiveContainer width="100%" height={350}>
        <ComposedChart data={formattedData} margin={margin}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis key='xaxis' dataKey='date' height={80} tick={<CustomizedDateAxisTick/>} interval={tickInterval}/>
          <YAxis yAxisId={'count'} key='yaxis_count' type='number' tickFormatter={chartNumberFormatter}/>
          <YAxis yAxisId={'time'} key='yaxis_amount' unit=' min' interval={'preserveStart'} tickCount={3} tickFormatter={chartPlaytimeFormatter()} orientation='right'/>
          <Tooltip key='story_events_tooltip' formatter={chartNumberFormatter}/>          
          <Line key='views' type="monotone" dataKey="views" name="Views" stroke={colors[0]} connectNulls={true} yAxisId='count'/>
          <Line key='clicks' type="monotone" dataKey="clicks" name="Clicks" stroke={colors[1]} connectNulls={true} yAxisId='count'/>
          <Bar key='playtime' dataKey="playtime" name="Playtime" unit={'s'} fill={colors[2]} connectNulls={true} yAxisId='time'/>
          <Legend align={'right'} verticalAlign="bottom" height={36} wrapperStyle={legendWrappedStyle}/>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default StoriesAnalyticsChart;
