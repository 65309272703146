import React from 'react';

import {  Modal } from '@shopify/polaris';


export default function ViemoVideoModal({ videoUrl, videoTitle, open, onClose }) {
    return (
        <Modal
            titleHidden={true}
            open={open}
            onClose={onClose}
        >
            <Modal.Section>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe 
                        src={videoUrl}
                        frameBorder="0" 
                        allow="autoplay; picture-in-picture" 
                        allowFullScreen 
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} 
                        title={videoTitle}/>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </Modal.Section>

        </Modal>
    )
}