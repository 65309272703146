
export const UserRole = {
    admin: 'admin',
    calendarAdmin: 'calendar_admin',
    host: 'host',
    participant: 'participant',
}

const adminAppPermissions = [UserRole.admin, UserRole.calendarAdmin];

export function shopsWithAdminAppPermissions(permissionsDoc) {

    if (!permissionsDoc?.exists) return [];
    return Object.keys(permissionsDoc.data()).filter(shopId => hasAdminAppPermissions(permissionsDoc.data()[shopId])).sort();
}

export function hasAdminAppPermissions(roles) {
    return roles?.some(role => adminAppPermissions.includes(role));
}

export function getAdminAppPermission(roles) {
    if (roles?.includes(UserRole.admin)) return UserRole.admin;
    else if (roles?.includes(UserRole.calendarAdmin)) return UserRole.calendarAdmin;
    else return null;
}

export function isAdminRole(roles) {
    return roles?.includes(UserRole.admin);
}

export function hasCalendarAdminPermissions(role) {
    return [UserRole.admin, UserRole.calendarAdmin].includes(role);
}

export function isHostRole(roles) {
    return roles?.includes(UserRole.host);
}