export const DEFAULT_HOST_VALUE = {
	label: 'All Hosts',
	value: 'all_hosts'
}

export const HostConfig = {
    Default: 'Default',
    Custom: 'Custom',
    None: 'None',
  }

