import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useShopProvider } from './ShopProvider';
import useCaazamREST from '../hooks/useCaazamREST';
import useShopCalendarEvent from '../hooks/useShopCalendarEvent';
import { isEmailValid, isStringNonEmpty } from '../utils/strings';
import { omit } from '../utils/objects';
import { DEFAULT_SLOT_DURATION } from '../utils/consts';
import moment from 'moment-timezone';
import { Toast } from "@shopify/polaris";

const defaultTabs = {
    client: {
        content: 'Client data',
    },
    event: {
        content: 'Select Event',
    },
    host: {
        content: 'Select Host',
    },
    timeslot: {
        content: 'Select Slot',
    },
    summary: {
        content: 'Summary',
    }
};

export const VIEWS = {
    CLIENT_DATA: 'client',
    EVENT_DATA: 'event',
    HOST_DATA: 'host',
    TIMESLOT_DATA: 'timeslot',
    SUMMARY_DATA: 'summary',
    SCHEDULE_RESULT_DATA: 'result'
};

export const CreateNewAppointmentContext = createContext(null);

export default function CreateNewAppointmentProvider({
    open,
    onClose,
    preselectedData,
    children
}) {

    const { hosts, shopOrigin, schedulingConfig } = useShopProvider();
    const [availableHosts, setAvailableHosts] = useState(hosts);
    const [isScheduling, setIsScheduling] = useState(null);
    const [selectedTab, setSelectedTab] = useState(VIEWS.CLIENT_DATA);

    const { getEventTypes, getSchedulingSlots } = useCaazamREST();
    const [eventTypes, setEventTypes] = useState(null)
    const [tabs, setTabs] = useState(null)

    const { createScheduledCall, rescheduleCall } = useShopCalendarEvent(shopOrigin);

    let minNotice = schedulingConfig?.minNotice ?? schedulingConfig?.slotDuration ?? DEFAULT_SLOT_DURATION;

    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [selectedEventType, setSelectedEventType] = useState(null);
    const [selectedHostId, setSelectedHostId] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [timezone, setTimezone] = useState(null)

    const [nameError, setNameError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [scheduleRequestError, setScheduleRequestError] = useState(null);
    const [formError, setFormError] = useState(null);

    const [slotData, setSlotData] = useState(null);
    const [availableSlots, setAvailableSlots] = useState(null);
    const [slotsErrorMessage, setSlotsErrorMessage] = useState(null)

    const [isBtnsVisible, setIsBtnsVisible] = useState(true);

    const [showToast, setShowToast] = useState(false);

    const getSlotDay = (dateObject) =>
        new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());

    const buildSlots = (slots) => {
        if (!slots || slots.length === 0) {
            return [];
        }
        let slotsByDate = [];
        let currentDate = null;
        let currentDateSlots = [];
        slots.forEach((slot) => {

            let slotDate = getSlotDay(new Date(slot.start));
            if (slotDate.getTime() !== currentDate?.getTime()) {
                if (!!currentDate) {
                    slotsByDate.push({ date: new Date(currentDate), slots: [...currentDateSlots] })
                }
                currentDateSlots = [];
                currentDate = new Date(slotDate);
            }

            currentDateSlots.push({
                isAvailable: slot.isAvailable,
                timeSlot: new Date(slot.start),
                timeSlotEnd: new Date(slot.end),
            });
        });
        // for last date (we've left the loop already)
        slotsByDate.push({ date: new Date(currentDate), slots: [...currentDateSlots] });
        return slotsByDate;
    }

    const tabsDefiner = (eventTypes) => {
        if (preselectedData?.id) {
            let tabs = omit(VIEWS.CLIENT_DATA, defaultTabs)
            tabs = omit(VIEWS.EVENT_DATA, tabs);
            setSelectedTab(VIEWS.HOST_DATA)
            return tabs;
        } else {
            setSelectedTab(VIEWS.CLIENT_DATA);
        }
        if (eventTypes.length === 0) {
            return omit(VIEWS.EVENT_DATA, defaultTabs)
        } else {
            return defaultTabs
        }
    }

    const handleTabChange = (currentPage, forward = true) => {
        if (forward) {
            switch (currentPage) {
                case VIEWS.CLIENT_DATA:
                    if (validateForm()) {
                        setSelectedTab(eventTypes.length > 0 ? VIEWS.EVENT_DATA : VIEWS.HOST_DATA);
                    }
                    break;
                case VIEWS.EVENT_DATA:
                    if (selectedEventType) {
                        setSelectedTab(VIEWS.HOST_DATA);
                    } else {
                        setFormError('Please select event')
                    }
                    break;
                case VIEWS.HOST_DATA:
                    if (selectedHostId) {
                        setSelectedTab(VIEWS.TIMESLOT_DATA);
                    } else {
                        setFormError('Please select host')
                    }
                    break;
                case VIEWS.TIMESLOT_DATA:
                    if (selectedSlot) {
                        setSelectedTab(VIEWS.SUMMARY_DATA);
                    } else {
                        setFormError('Please select timeslot')
                    }
                    break;
            }
        } else {
            const index = Object.keys(tabs).findIndex(tab => tab === currentPage);
            if (index > 0) {
                setSelectedTab(Object.keys(tabs)[index - 1]);
            }
        }
    }

    const handleOnSubmit = async () => {
        await requestScheduleCall();
        setSelectedTab(VIEWS.SCHEDULE_RESULT_DATA)
    }

    useEffect(() => {
        if (open) {
            setClientName(preselectedData?.name ?? '')
            setClientEmail(preselectedData?.email ?? '')
            setSelectedEventType(preselectedData?.eventTypeId ?? null)
            setSelectedHostId(preselectedData?.hostId ?? null)
            if (preselectedData?.timezone) {
                setTimezone({id: preselectedData?.timezone})
            } else {
                setTimezone(null)
            }
            setTabs(null);
            if (preselectedData?.slot && !preselectedData?.id) {
                const timeslot = {
                    timeSlot: new Date(preselectedData.slot?.start),
                    timeSlotEnd: new Date(preselectedData.slot?.end)
                }
                setSelectedSlot(timeslot)
            } else {
                setSelectedSlot(null)
            }
            setIsBtnsVisible(true);            
            const fetchEventTypes = async () => {
                try {
                    const { eventTypes } = await getEventTypes(shopOrigin)
                    setEventTypes(eventTypes);
                    setTabs(tabsDefiner(eventTypes));
                } catch (e) {
                    setEventTypes([])
                }
            }
            fetchEventTypes();
        }
    }, [open, shopOrigin]);

    useEffect(() => {
        if (open) {
            const fetchSlots = async () => {
                try {
                    let allSlots = await getSchedulingSlots(shopOrigin, null, null, selectedEventType);
                    if (preselectedData?.slot && preselectedData?.id) {
                        const scheduleItem = {
                            start: preselectedData?.slot.start,
                            end: preselectedData?.slot.end,
                            isAvailable: true,
                            hostId: preselectedData?.hostId,
                        }

                        setSlotData({
                            ...allSlots, slots: [
                                ...allSlots.slots,
                                scheduleItem
                            ].sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
                        })
                    } else {
                        setSlotData(allSlots)
                    }
                } catch (error) {
                    setAvailableSlots([]);
                    setSlotsErrorMessage('Something went wrong, please try again');
                }
            }
            fetchSlots();
        }
    }, [open, shopOrigin, selectedEventType]);

    useEffect(() => {
        if (selectedHostId && slotData?.slots) {
            const slots = slotData.slots.filter(slot => {
                if (slot.hostId === selectedHostId && moment(slot.start).diff(moment().add(minNotice, 'minutes')) > 0) {
                    return slot
                }
            })

            const formattedSlots = buildSlots(slots);
            setAvailableSlots(formattedSlots);
            setSlotsErrorMessage(formattedSlots.length === 0 ? 'To enable scheduling, please ask your Boutiq admin to configure business hours for your shop' : null);
        }
    }, [selectedHostId, slotData]);

    useEffect(() => {
        if (hosts && slotData?.slots) {
            setAvailableHosts(hosts.map(host =>
                ({ ...host, ...{ haveAvailableSlots: slotData.slots.filter(slot => slot.hostId === host.id).length > 0 } })
            ))
        }
    }, [hosts, slotData]);

    const validateForm = () => {
        let nameError = null;
        let emailError = null;
        if (!isStringNonEmpty(clientName)) {
            nameError = 'Please enter client name';
        }
        if (!isStringNonEmpty(clientEmail)) {
            emailError = 'enter email'
        } else {
            if (!isEmailValid(clientEmail)) {
                emailError = 'email is not valid'
            }
        }
        setNameError(nameError)
        setEmailError(emailError)
        return !nameError && !emailError
    }

    const [linkUrl, setLinkUrl] = useState(null);
    const [isSchedulingSucceed, setIsSchedulingSucceed] = useState(null);

    const requestScheduleCall = async () => {
        setIsScheduling(true);
        let bodyData = {
            slot: {
                start: selectedSlot.timeSlot,
                end: selectedSlot.timeSlotEnd,
            },
            hostId: selectedHostId,
            customerTimezone: timezone?.id ?? null
        };
        if (!preselectedData?.id) {
            bodyData = { ...bodyData, customerName: clientName, customerEmail: clientEmail }
        }
        if (selectedEventType) {
            bodyData.eventTypeId = selectedEventType
        }

        const schedule = preselectedData?.id ? rescheduleCall : createScheduledCall;

        try {
            const res = await schedule(bodyData, preselectedData?.id);
            if (res) setLinkUrl(res.callLink)
            setIsSchedulingSucceed(true);
        } catch (error) {
            console.error(error)
            setIsSchedulingSucceed(false);
            setScheduleRequestError(error)
        } finally {
            setSelectedTab(VIEWS.SCHEDULE_RESULT_DATA);
            setIsScheduling(false);
            setIsBtnsVisible(false);
        }
    };

    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);
    const toastMarkup = showToast ? (
        <Toast content="Code copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;

    return <CreateNewAppointmentContext.Provider value={{
        open, onClose,
        tabs,
        selectedTab, setSelectedTab,
        clientName, setClientName,
        nameError, setNameError,
        clientEmail, setClientEmail,
        emailError, setEmailError,
        formError, setFormError,
        eventTypes, selectedEventType, setSelectedEventType,
        availableHosts, selectedHostId, setSelectedHostId,
        availableSlots, selectedSlot, setSelectedSlot, slotsErrorMessage,
        handleOnSubmit,
        handleTabChange,
        requestScheduleCall,
        isSchedulingSucceed,
        isScheduling,
        scheduleRequestError,
        linkUrl,
        isBtnsVisible,
        toggleToastActive,
        preselectedData,
        isReschedule: !!preselectedData?.id,
        onSetTimezone: setTimezone,
        timezone
    }}>
        {children}
        {toastMarkup}
    </CreateNewAppointmentContext.Provider>;
}

export function useCreateNewAppointmentProvider() {
    const context = useContext(CreateNewAppointmentContext);
    if (!context) {
        throw new Error('useCreateNewAppointmentProvider must be used within the CreateNewAppointmentProvider');
    }
    return context;
}
