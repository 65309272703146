import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {
    Layout,
    Card,
    Stack,
    Spinner,
    Subheading,
    Checkbox,
    ContextualSaveBar
} from '@shopify/polaris';
import AppPage from '../../../../components/AppPage'
import AdminPage from '../../../../components/AdminPage'
import { useShopProvider } from '../../../../components/ShopProvider';
import useShopIntegrationsConfig from '../../../../hooks/useShopIntegrationsConfig';
import { CustomerProfileSectionsMockup } from '../../../../components/mockup/CustomerProfileSectionsMockup';


const DEFAULT_CONFIG_CONTROLS = {
    showLastPurchaseDate: {
        label: 'Last purchase',
        isActive: true,
        isCustom: false,
    },
    showAvgBasket: {
        label: 'Avg Basket',
        isActive: true,
        isCustom: false,
    },
    showMaxBasket: {
        label: 'Max basket',
        isActive: true,
        isCustom: false,
    },
    showTotalSpend: {
        label: 'Total spend',
        isActive: true,
        isCustom: false,
    },
    showTotalCredit: {
        label: 'Credit',
        isActive: true,
        isCustom: false,
    },
    showTotalRefunds: {
        label: 'Total refunds',
        isActive: true,
        isCustom: false,
    },
    showClientNote: {
        label: 'Note',
        isActive: true,
        isCustom: false,
    },
    showRecentPurchases: {
        label: 'Show recent purchases',
        isActive: true,
        isCustom: false,
    },
    showProductRecommendations: {
        label: 'Show product recommendations',
        isActive: true,
        isCustom: false,
    },
    showRecentlyViewedProducts: {
        label: 'Show recently viewed products',
        isActive: true,
        isCustom: false,
    }
}

function CustomerProfileSettings() {
    const [dirtyBit, setDirtyBit] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [currentConfig, setCurrentConfig] = useState(DEFAULT_CONFIG_CONTROLS);
    const customFields = Object.keys(currentConfig).filter(key => currentConfig[key].isCustom).map(key => ({... currentConfig[key], key }));
    const isLoading = false;
    const history = useHistory();
    const {
        shopOrigin,
        shopData,
        customerProfileConfig,
        customerProfileConfigLoading
    } = useShopProvider();

    const { integrations, integrationsLoading } = useShopIntegrationsConfig(shopOrigin);

    useEffect(() => {
        if (customerProfileConfig && !customerProfileConfigLoading) {
            let newState = currentConfig;
            for( const [key,val] of Object.entries(customerProfileConfig)) {
                if (newState[key] != null) newState[key].isActive = val;
            }
            setCurrentConfig(newState);
        }
    }, [customerProfileConfig, customerProfileConfigLoading]);

    useEffect(() => {
        if (!integrationsLoading) {
            let customConfig = {};
            for (const integId in integrations) {
                const integData = integrations[integId];
                if (integData.isCustomerProfile && integData.customFields) {
                    for (const field of integData.customFields) {
                        const key = `${integId}_${field.key}`;
                        customConfig[key] = {
                            label: `${field.label} (${integData.name})`,
                            isActive: customerProfileConfig && customerProfileConfig[key] ? !!customerProfileConfig[key] : false,
                            isCustom: true,
                        }
                    }
                }
            }
            setCurrentConfig({...currentConfig, ...customConfig});
        }
    }, [integrations, integrationsLoading])


    useEffect(() => {
        let change = Object.entries(currentConfig).reduce((agg, [fieldId, fieldData]) => {
            if (customerProfileConfig && customerProfileConfig[fieldId] != null) {
                return agg || customerProfileConfig[fieldId] !== fieldData.isActive;
            } else {
                if (fieldData.isCustom)
                    return agg || fieldData.isActive;
                else
                    return agg || !fieldData.isActive;
            }

        }, false);
        setDirtyBit(change);
    }, [customerProfileConfig, currentConfig]);

    const save = async () => {
        setIsSaving(true);
        try {
            let newConfig;
            Object.entries(currentConfig).map(([key, value]) => {
                newConfig = { ...newConfig, ...{ [key]: value.isActive } }
            })
            await Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('customerProfile').set(newConfig, { merge: true });
        }
        catch (error) {
            console.error('Failed to config app', error);
        }
        finally {
            setIsSaving(false);
            setDirtyBit(false);
        }
    }

    const onDiscard = () => {
        let profileConfig;
        if (customerProfileConfig) {
            Object.entries(customerProfileConfig).map(([key, value]) => {
                const label = currentConfig[key].label;
                profileConfig = {
                    ...profileConfig,
                    ...{ [key]: { isActive: value, label: label } }
                };
            })
        } else {
            Object.entries(currentConfig).map(([key, value]) => {
                profileConfig = {
                    ...profileConfig,
                    ...{ [key]: { isActive: true, label: value.label } }
                };
            })
        }
        if (integrations) {
            let customConfig = {};
            for (const integId in integrations) {
                const integData = integrations[integId];
                if (integData.isCustomerProfile && integData.customFields) {
                    for (const field of integData.customFields) {
                        const key = `${integId}_${field.key}`;
                        customConfig[key] = {
                            label: `${field.label} (${integData.name})`,
                            isActive: customerProfileConfig && customerProfileConfig[key] ? !!customerProfileConfig[key] : false,
                            isCustom: true,
                        }
                    }
                }
            }
            setCurrentConfig({...DEFAULT_CONFIG_CONTROLS, ...profileConfig, ...customConfig});
        } else {
            setCurrentConfig({...DEFAULT_CONFIG_CONTROLS, ...profileConfig});
        }
    }

    const onCheckStatusChange = (key, isActive) => {
        setCurrentConfig({
            ...currentConfig,
            ...{ [key]: { ...currentConfig[key], isActive, } }
        });
    }

    return (
        <>
            <AppPage
                title='Client profile display'
                breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            >
                <Layout>
                    <Layout.Section>
                        <Card title={<Stack><Subheading>Preview</Subheading>{isLoading && <Spinner size='small' />}</Stack>} sectioned subdued>
                            {!customerProfileConfigLoading &&
                                <CustomerProfileSectionsMockup
                                    currencyCode={shopData.currencyCode}
                                    config={currentConfig} />}
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        {!customerProfileConfigLoading &&

                            <Card>
                                <Card.Section title='Client Spend'>
                                    <Stack vertical spacing='tight'>
                                        <Checkbox
                                            label={currentConfig.showAvgBasket.label}
                                            checked={currentConfig.showAvgBasket.isActive}
                                            onChange={status => onCheckStatusChange('showAvgBasket', status)}
                                        />
                                        <Checkbox
                                            label={currentConfig.showMaxBasket.label}
                                            checked={currentConfig.showMaxBasket.isActive}
                                            onChange={status => onCheckStatusChange('showMaxBasket', status)}
                                        />
                                        <Checkbox
                                            label={currentConfig.showTotalSpend.label}
                                            checked={currentConfig.showTotalSpend.isActive}
                                            onChange={status => onCheckStatusChange('showTotalSpend', status)}
                                        />
                                    </Stack>
                                </Card.Section>
                                <Card.Section title='Client data'>
                                    <Stack vertical spacing='extraLoose'>
                                        <Stack vertical spacing='tight'>
                                            <Checkbox
                                                label={currentConfig.showLastPurchaseDate.label}
                                                checked={currentConfig.showLastPurchaseDate.isActive}
                                                onChange={status => onCheckStatusChange('showLastPurchaseDate', status)}
                                            />

                                            <Checkbox
                                                label={currentConfig.showTotalCredit.label}
                                                checked={currentConfig.showTotalCredit.isActive}
                                                onChange={status => onCheckStatusChange('showTotalCredit', status)}
                                            />

                                            <Checkbox
                                                label={currentConfig.showTotalRefunds.label}
                                                checked={currentConfig.showTotalRefunds.isActive}
                                                onChange={status => onCheckStatusChange('showTotalRefunds', status)}
                                            />

                                            <Checkbox
                                                label={currentConfig.showClientNote.label}
                                                checked={currentConfig.showClientNote.isActive}
                                                onChange={status => onCheckStatusChange('showClientNote', status)}
                                            />
                                        </Stack>
                                    </Stack>
                                </Card.Section>
                                <Card.Section title='Client products'>
                                    <Stack vertical spacing='tight'>
                                        <Checkbox
                                            label={currentConfig.showRecentPurchases.label}
                                            checked={currentConfig.showRecentPurchases.isActive}
                                            onChange={status => onCheckStatusChange('showRecentPurchases', status)}
                                        />

                                        <Checkbox
                                            label={currentConfig.showProductRecommendations.label}
                                            checked={currentConfig.showProductRecommendations.isActive}
                                            onChange={status => onCheckStatusChange('showProductRecommendations', status)}
                                        />

                                        <Checkbox
                                            label={currentConfig.showRecentlyViewedProducts.label}
                                            checked={currentConfig.showRecentlyViewedProducts.isActive}
                                            onChange={status => onCheckStatusChange('showRecentlyViewedProducts', status)}
                                        />
                                    </Stack>
                                </Card.Section>
                                {customFields && customFields.length > 0 &&
                                    <Card.Section title='Client custom Fields'>
                                        <Stack vertical spacing='tight'>
                                            {customFields.map(field => (
                                                <Checkbox
                                                    key={field.key}
                                                    label={field.label}
                                                    checked={field.isActive}
                                                    onChange={status => onCheckStatusChange(field.key, status)}
                                                />
                                            ))}
                                        </Stack>
                                    </Card.Section>}
                            </Card>}
                    </Layout.Section>
                </Layout>
            </AppPage>
            {dirtyBit && <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                    onAction: save,
                    loading: isSaving,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: isSaving,
                }}
            />}
        </>
    )
}

export default function () {
    return (
        <AdminPage>
            <CustomerProfileSettings />
        </AdminPage>
    )
}
