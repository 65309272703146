export const CAMPAIGN_TYPES = [{
  label: 'popup',
  value: 'popup',
}, {
  label: 'widget',
  value: 'widget',
}];

export const CTA_ACTION_TYPE = [{
  label: 'Call Now',
  value: 'regular',
}, {
  label: 'Schedule for Later',
  value: 'scheduling',
}];

export const RULES = [{
  label: 'URL path',
  value: 'currentUrl',
}, {
  label: 'Time spent on page',
  value: 'timeOnPage',
}, {
  label: 'Within business hours',
  value: 'shopOpen',
}, {
  label: 'URL query parameter',
  value: 'queryParam',
}
];

export const AUTO_POP_UP_DEFAULTS = {
  open: 10,
  close: 5,
  debounceTime: 2 * 60,
}

export const TIME_PAGE_OPTIONS = [{
  label: 'great than',
  value: 'is_great_than',
}, {
  label: 'less than',
  value: 'is_less_than',
}];

export const CURRENT_URL_OPTIONS = [{
  label: 'contains',
  value: 'contains',
}, {
  label: "doesn't contain",
  value: 'does_not_contain',
}];

export const OPEN_SHOP_OPTIONS = [{
  label: 'true',
  value: 'true',
}, {
  label: 'false',
  value: 'false',
}];

export const FIELD_KEYS = {
  COLOR: 'COLOR',
  RULES: 'RULES',
  NAME: 'NAME',
  LANG: 'LANG',
  ENABLED: 'ENABLED',
  TITLE: 'TITLE',
  BUTTON_ACTION: 'BUTTON_ACTION',
  BUTTON_NAME: 'BUTTON_NAME',
  CURRENT_URL_SELECTED: 'CURRENT_URL_SELECTED',
  CURRENT_URL: 'CURRENT_URL',
  QUERY_PARAM_NAME: 'QUERY_PARAM_NAME',
  QUERY_PARAM_VALUE: 'QUERY_PARAM_VALUE',
  TIME_ON_PAGE: 'TIME_ON_PAGE',
  TIME_ON_PAGE_SELECTED: 'TIME_ON_PAGE_SELECTED',
  SHOP_OPEN_SELECTED: 'SHOP_OPEN_SELECTED',
  SHOP_OPEN: 'SHOP_OPEN',
  TEMP_IMAGE: 'TEMP_IMAGE',
  DESCRIPTION: 'DESCRIPTION',
  TEST: 'TEST',
}
