
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShop(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId) : null;
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(ref);
    return { shopData, shopDataLoading, shopDataError }
}
