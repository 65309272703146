import {useState, useEffect} from 'react';
import Moment from 'moment-timezone';
import {getPreviousPeriod} from "../../utils/time";

export const DateRange = {
    last7: { id: 'last7', name: 'Last 7 days', start: Moment().subtract(1, 'week').startOf('day'), end: Moment().endOf('day'), comparePeriod: 1, compareUnit: 'WEEK' },
    last30: { id: 'last30', name: 'Last 30 days', start: Moment().subtract(1, 'month').startOf('day'), end: Moment().endOf('day'), comparePeriod: 30, compareUnit: 'DAY' },
    last90: { id: 'last90', name: 'Last 90 days', start: Moment().subtract(3, 'month').startOf('day'), end: Moment().endOf('day'),  comparePeriod: 90, compareUnit: 'DAY'  },
    currentYear: { id: 'currentYear', name: 'This Year', start: Moment().startOf('year'), end: Moment().endOf('day') },
}

export default function useDateRange(range) {
    const [start, setStart] = useState(range.start);
    const [previousStart, setPreviousStart] = useState(range.start);
    const [end,setEnd] = useState(range.end);
    const [previousEnd,setPreviousEnd] = useState(range.end);

    useEffect(()=>{
        setStart(range.start);
        setEnd(range.end);

        const previousPeriod = getPreviousPeriod(range.start, range.end);
        setPreviousStart(previousPeriod.start);
        setPreviousEnd(previousPeriod.end);
    },[range])

    return [start, end, previousStart, previousEnd]
}
