import React, {useState, useEffect, useMemo} from 'react';
import { Layout, Card } from '@shopify/polaris';

import { useShopProvider } from '../../../components/ShopProvider';
import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import useShopEventTypes from '../../../hooks/useShopEventTypes';
import useAllShopEvents from '../../../hooks/useAllShopEvents';
import DashboardFilter from "../../Dashboard/DashboardFilter";
import {CHARTS_LABELS, COMPARE_TYPES, DEFAULT_EVENT_TYPE_FILTER, DEFAULT_HOST_FILTER, NO_TYPE} from "../../../constants/analytics";
import useDateRange, {DateRange} from "../../Dashboard/useDateRange";
import {Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend} from "recharts";
import CustomizedDateAxisTick from "../../../components/charts/CustomizedDateAxisTick";
import {scheduledEventsHostsComparisonDataMapper, scheduledEventsDataMapper, scheduledEventsTypesComparisonDataMapper} from "../../Dashboard/useDataset";
import {
    formatHostNameForCharts,
    formatHostNameForTable,
    formatShortEventDate,
    numberFormatter, statusMapper
} from "../../../utils/formatters";
import {
    ExportMinor
  } from '@shopify/polaris-icons';
import CustomizedHostAxisTick from "../../../components/charts/CustomizedHostAxisTick";
import CustomizedEventTypeAxisTick from "../../../components/charts/CustomizedEventTypeAxisTick";
import ChartTitle from "../../../components/charts/ChartTitle";
import CalendarAnalyticsDataTable from "../../../components/charts/CalendarAnalyticsDataTable";
import Papa from "papaparse";
import {csvLoader} from "../../../utils/csvProcessor";

function CalendarAnalytics() {

    const { hosts, shopOrigin, shopUserTeams } = useShopProvider();
    const { eventTypes } = useShopEventTypes(shopOrigin);

    const [range, setRange] = useState(DateRange.last30);
    const [startDate, endDate] = useDateRange(range);
    const [host, setHost] = useState(DEFAULT_HOST_FILTER);
    const [eventType, setEventType] = useState(DEFAULT_EVENT_TYPE_FILTER);

    const hostId = useMemo(() => (host?.id === 'all' || host?.isTeamOption) ? null : host.id, [host]);
    const teamId = useMemo(() => (host?.isTeamOption ? host?.teamId : null), [host]);

    const {shopEvents, shopEventsLoading} = useAllShopEvents(shopOrigin, startDate.toDate(), endDate.toDate(), hostId, teamId);
    const [filterType, changeFilterType] = useState('all');
    const [shopEventTypesList, setEventTypesList] = useState([]);
    const [formattedData, setFormattedData] = useState([]);
    const [formattedTableDataset, setFormattedTableDataset] = useState([]);

    const chartNumberFormatter = (value) => numberFormatter().format(value) || null;

    useEffect(() => {
        let analyticsData = [];
        let tableData = [];
        if (filterType === 'all') {
            const {dataset, datePoints} = scheduledEventsDataMapper(shopEvents, startDate, endDate, eventType);
            analyticsData = datePoints;
            tableData = dataset;
        } else if (filterType === COMPARE_TYPES.HOSTS) {
            const {dataset, hostPoints} = scheduledEventsHostsComparisonDataMapper(shopEvents, startDate, endDate, hosts, eventType);
            analyticsData = hostPoints;
            tableData = dataset;
        } else if (filterType === COMPARE_TYPES.EVENT_TYPES) {
            const typesForComparison = shopEventTypesList.slice(1, shopEventTypesList.length);
            const {dataset, eventTypesPoints} = scheduledEventsTypesComparisonDataMapper(shopEvents, startDate, endDate, typesForComparison);
            analyticsData = eventTypesPoints;
            tableData = dataset;
        }
        setFormattedData(analyticsData);

        const sortedTableDataset = tableData.sort((a, b) => b?.start?.valueOf() - a?.start?.valueOf())
        setFormattedTableDataset(sortedTableDataset);
    },[filterType, range, shopEvents, host, eventType])

    useEffect(() => {
        if (eventTypes && !eventTypes?.empty) {
            const eventTypesOptions = eventTypes.docs.map(eventType => {
                const data = eventType.data();
                return {
                    label: data.name,
                    value: eventType.id,
                    id: eventType.id,
                    color: data.color
                }
            })
            setEventTypesList([DEFAULT_EVENT_TYPE_FILTER].concat([NO_TYPE]).concat(eventTypesOptions));
        }
    }, [eventTypes])

    const updateEventType = (value) => {
        filterType === COMPARE_TYPES.EVENT_TYPES && changeFilterType('all');
        setEventType(value);
    }

    const updateCurrentHost = (value) => {
        filterType === COMPARE_TYPES.HOSTS && changeFilterType('all');
        setHost(value);
    }

    const setFilterType = (filterType) => {
        changeFilterType(filterType);
        if (filterType === COMPARE_TYPES.HOSTS) {
            setHost(DEFAULT_HOST_FILTER);
        } else if (filterType === COMPARE_TYPES.EVENT_TYPES) {
            setEventType(DEFAULT_EVENT_TYPE_FILTER);
        }
    }

    const renderTooltipLabel = (label) => formatHostNameForCharts(filterType, hosts, label);

    const renderLineChart = () => {
        const colors = ["#FFBE88","#6E76F2", "#afa44f", "#FF0000"];
        const margins = {top: 32, right: 16, left: 16, bottom: 8};
        const legendWrappedStyle = {top: 0, paddingBottom: 10};

        const tickInterval = parseInt(formattedData.length / 15);
        if (filterType === 'all') {
            return (
              <LineChart data={formattedData} margin={margins}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis key='xaxis' dataKey='date' height={80} tick={<CustomizedDateAxisTick />} interval={tickInterval} />
                  <YAxis key='yaxis_count' type='number' domain={[0,1]} tickFormatter={chartNumberFormatter}/>
                  <Tooltip key='scheduled_events_tooltip' formatter={chartNumberFormatter}/>
                  <Line key='scheduled' type="monotone" dataKey="total" name="Total" stroke={colors[0]} connectNulls={true} />
                  <Line key='completed' type="monotone" dataKey="completed" name="Completed" stroke={colors[1]} connectNulls={true} />
                  <Line key='canceled' type="monotone" dataKey="canceled" name="Canceled" stroke={colors[2]} connectNulls={true} />
                  <Line key='noShow' type="monotone" dataKey="noShow" name="No Show" stroke={colors[3]} connectNulls={true} />
                  <Legend align={'right'} verticalAlign="bottom" height={36} wrapperStyle={legendWrappedStyle}/>
              </LineChart>
            );
        }

        const stackId = filterType === COMPARE_TYPES.HOSTS ? 'hostId' : 'eventTypeId';

        return (
          <BarChart data={formattedData} margin={margins}>
              {
                  filterType === COMPARE_TYPES.HOSTS
                    ? <XAxis key='xaxis' dataKey={stackId} height={80} tick={<CustomizedHostAxisTick hosts={hosts}/>} interval={tickInterval} />
                    : <XAxis key='xaxis' dataKey='eventTypeName' height={80} tick={<CustomizedEventTypeAxisTick />} interval={tickInterval} />
              }
              <YAxis key='yaxis_count' type='number' domain={[0,1]} tickFormatter={chartNumberFormatter}/>
              <Tooltip labelFormatter={renderTooltipLabel} key='scheduled_events_tooltip' formatter={chartNumberFormatter}/>
              <Bar key='total' dataKey='total' name='total' fill={colors[0]}/>
              <Bar key='completed' dataKey='completed' name='completed' fill={colors[1]}/>
              <Bar key='canceled' dataKey='canceled' name='canceled' fill={colors[2]}/>
              <Bar key='noShow' dataKey='noShow' name='noShow' fill={colors[3]}/>
              <Legend align={'right'} verticalAlign="top" height={36}  wrapperStyle={legendWrappedStyle} />
          </BarChart>
        );
    }

    const getChartTitleLabel = () => {
        if (filterType === COMPARE_TYPES.HOSTS) {
            return CHARTS_LABELS.SCHEDULED_EVENTS_BY_HOST;
        } else if (filterType === COMPARE_TYPES.EVENT_TYPES) {
            return CHARTS_LABELS.SCHEDULED_EVENTS_BY_EVENT_TYPE;
        }
        return CHARTS_LABELS.SCHEDULED_EVENTS_PER_DAY;;
    }

    const exportCalendarAnalytics = () => {
        const csvDataset = formattedTableDataset.map(data => ({
            'Client name': data.title,
            'Client email': data.clientEmail,
            'Event type': data?.eventType?.name,
            'Status': statusMapper(data),
            'Host': formatHostNameForTable(hosts, data?.hostId),
            'Start time': formatShortEventDate(data?.start),
            'End time': formatShortEventDate(data?.end),
        }));
        const csv = Papa.unparse(csvDataset);
        csvLoader(csv, `${shopOrigin}_calendar_`);
    }

    return (
        <AppPage title='Calendar Reports' secondaryActions={[{content: 'Export', icon: ExportMinor, onAction: exportCalendarAnalytics}]}>
            <Layout >
                <Layout.Section>
                    <DashboardFilter
                      onRangeUpdate={setRange}
                      currentRange={range}
                      currentHost={host}
                      currentEventType={eventType}
                      setEventType={updateEventType}
                      shopEventTypesList={shopEventTypesList}
                      setCurrentHost={updateCurrentHost}
                      hosts={hosts}
                      shopUserTeams={shopUserTeams}
                      setFilterType={setFilterType}
                      filterType={filterType}
                    />
                </Layout.Section>
                <Layout.Section>
                    <Card title={<ChartTitle title={getChartTitleLabel()} isLoading={shopEventsLoading}/>}>
                        <ResponsiveContainer width="100%" height={350}>
                            {renderLineChart()}
                        </ResponsiveContainer>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card title='Appointments list'>
                        <CalendarAnalyticsDataTable dataset={formattedTableDataset} hosts={hosts} />
                    </Card>
                </Layout.Section>
            </Layout>

        </AppPage>
    )
}

export default function () {
    return (
        <AdminPage>
            <CalendarAnalytics />
        </AdminPage>
    )
}
