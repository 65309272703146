import useStoryVideos from './useStoryVideos';
import { useEffect, useMemo, useState } from 'react';
import * as StoryConsts from '../../../consts';
import { compareObjects } from '../../../../../utils/objects';
import { VIDEO_STATUS } from '../../../../../constants/story';
import { STORY_DISPLAY_TYPE } from '../../../consts';
import { isValidPath } from '../../../../../utils/paths';

const initStory = {
  storyId: null,
  videos: [],
  title: '',
  useAsBoutiqClientStory: false,
  storyDisplayType: STORY_DISPLAY_TYPE.widget,
  widget: {
    size: StoryConsts.DefaultStoryWidgetSize,
    shape: StoryConsts.DefaultStoryWidgetShape,
    position: StoryConsts.DefaultStoryWidgetPosition,
  },
  conditionUrl: ''
}

const initValidationError = {
  titleError: '',
  conditionUrlError: '',
  videosError: ''
}

const useStoryConfigurations = (story, shopVideoStories) => {
  const {videos, onAddVideo, onRemoveVideo, onChangeVideosOrder, resetVideos} = useStoryVideos(story);

  const [currentStory, setCurrentStory] = useState(initStory)
  const [validationError, setValidationError] = useState(initValidationError)

  const isNewStory = Object.keys(story ?? {}).length === 0

  const isSaveVisible = useMemo(() => {
    if (isNewStory) {
      return !compareObjects(currentStory, initStory)
    } else {
      return !compareObjects(currentStory, story)
    }
  }, [story, currentStory, isNewStory])

  useEffect(() => {
    if (story) {
      setCurrentStory({...story})
    } else {
      setCurrentStory(initStory)
      setValidationError(initValidationError)
    }
  }, [story])

  useEffect(() => {
    if (videos) {
      setCurrentStory(prev => ({
        ...prev,
        videos
      }))
    }
  }, [JSON.stringify(videos)])

  useEffect(() => {
    onClearErrors()
  }, [currentStory])

  const onChangeCurrentStory = (field, value) => {
    if (field.includes('.')) {
      const path = field.split('.')
      setCurrentStory(prev => ({
        ...prev,
        [path[0]]: {
          ...prev[path[0]],
          [path[1]]: value
        }
      }))
    } else {
      setCurrentStory(prev => ({
        ...prev,
        [field]: value
      }))
    }
  }

  const isConfigurationValid = (isBoutiqClientStory = false) => {
    if (!isBoutiqClientStory) {
      if (currentStory.title.length === 0) {
        setValidationError(prev => ({
          ...prev,
          titleError: 'Title cannot be empty'
        }))
        return false;
      }
      if (currentStory.conditionUrl.length === 0) {
        setValidationError(prev => ({
          ...prev,
          conditionUrlError: 'Condition url cannot be empty'
        }))
        return false;
      }
      if (!isValidPath(currentStory.conditionUrl)) {
        setValidationError(prev => ({
          ...prev,
          conditionUrlError: 'Please enter a valid url path starting with \"/\"'
        }))
        return false;
      }
      if (shopVideoStories.some(story => story.conditionUrl === currentStory.conditionUrl) && isNewStory) {
        setValidationError(prev => ({
          ...prev,
          conditionUrlError: 'You already have a story published to this url'
        }))
        return false
      }
    }
    if (currentStory.videos.filter((video) => video.data.status !== VIDEO_STATUS.DELETED).length === 0) {
      setValidationError(prev => ({
        ...prev,
        videosError: 'At least one video must be added'
      }))
      return false
    }
    return true
  }

  const onClearErrors = () => {
    setValidationError(initValidationError)
  }

  const onDiscardStoryChanges = () => {
    if (story) {
      setCurrentStory({...story})
    } else {
      setCurrentStory(initStory)
      setValidationError(initValidationError)
      resetVideos()
    }
  }

  return ({
    isNewStory,
    isSaveVisible,
    currentStory,
    validationError,
    isConfigurationValid,
    onChangeCurrentStory,
    onAddVideo,
    onRemoveVideo,
    onChangeVideosOrder,
    onClearErrors,
    onDiscardStoryChanges
  })
}

export default useStoryConfigurations;
