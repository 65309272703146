import React from 'react';

import { Stack, TextContainer, Heading, List } from '@shopify/polaris';

import HostTable from '../../components/Hosts'

export default function Hosts() {
    return (
        <Stack vertical spacing='loose'>
            <TextContainer>
                <Heading>Invite Hosts:</Heading>
                <p>Let’s make sure your sales associates can answer incoming video calls.</p>
                <List type='bullet'>
                    <List.Item>We’ve already added you as a host so you can skip this step and start right away</List.Item>
                    <List.Item>You can add (up to five total) hosts by clicking “Invite new host” below (you can always add them later from the Boutiq app settings)</List.Item>
                    <List.Item>
                        Hosts can respond on incoming video calls using the <a href={process.env.REACT_APP_CAAZAM_HOST_APP} target='_blank' rel="noopener noreferrer">Boutiq host app</a> (they will need to accept your invitation and make sure to allow push notifications)
                        </List.Item>
                </List>
            </TextContainer>
            <HostTable />
        </Stack>
    )
}