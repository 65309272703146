import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    AdjustMinor,
  } from '@shopify/polaris-icons';

import { Layout, ContextualSaveBar } from '@shopify/polaris';
import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import WidgetSettingsComponent, { OnlineStoreConfigMethod, DefaultWidgetConfig, saveWidgetEnabled, saveWidgetConfig } from "../../../components/Widget";
import { useShopProvider } from '../../../components/ShopProvider';


function WidgetSettingsPage() {
    const history = useHistory();
    const { onlineStoreConfig, onlineStoreConfigLoading, shopOrigin } = useShopProvider();
    const [currentConfig, setCurrentConfig] = useState(onlineStoreConfig?.methodConfig ?? DefaultWidgetConfig);
    const [dirtyBit, setDirtyBit] = useState(false);
    const [saving, setSaving] = useState(false);
    const isWidget = onlineStoreConfig && onlineStoreConfig.method === OnlineStoreConfigMethod.widget.id;

    useEffect(() => {
        if (!onlineStoreConfigLoading) {
            if (onlineStoreConfig?.method == null || onlineStoreConfig?.methodConfig == null)  {
                saveWidgetConfig(shopOrigin, true, DefaultWidgetConfig);
            } else {
                setCurrentConfig(onlineStoreConfig?.methodConfig);
            }

        }
    }, [onlineStoreConfig, onlineStoreConfigLoading]);

    const onWidgetToggle = async (val) => {
        try {
            await saveWidgetEnabled(shopOrigin, val);
        } catch (error) {
            console.error('saveWidgetEnabled', error)
        }
    }

    const onConfigChange = (config) => {
        setCurrentConfig(config);
        setDirtyBit(true);
    }

    const saveConfig = async () => {
        setSaving(true);
        try {
            await saveWidgetConfig(shopOrigin, isWidget, currentConfig);
            setDirtyBit(false);
        } catch (error) {
            console.error('saveWidgetConfig', error)
        } finally {
            setSaving(false);
        }
    }

    const discardConfig = () => {
        setCurrentConfig(onlineStoreConfig?.methodConfig);
        setDirtyBit(false);
    }

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Widget Settings'
            secondaryActions={[{ content: 'Advanced', icon: AdjustMinor, disabled: !isWidget, onAction: () => history.push('/clientSettings/widgetAdvanced') }]}
        >
            <Layout>
                <Layout.Section>
                    <WidgetSettingsComponent
                        enabled={isWidget}
                        config={currentConfig}
                        loading={onlineStoreConfigLoading || !currentConfig}
                        onEnable={onWidgetToggle}
                        onConfigChange={onConfigChange} 
                        showMoreConfig={true}
                        />
                </Layout.Section>
            </Layout>
            {dirtyBit && <ContextualSaveBar
                message='Save changes to widget settings?'
                saveAction={{
                    onAction: saveConfig,
                    loading: saving,
                }}
                discardAction={{
                    onAction: discardConfig,
                    disabled: !onlineStoreConfig?.methodConfig,
                }}
            />}
        </AppPage>
    );
}


export default function () {
    return (
        <AdminPage>
            <WidgetSettingsPage />
        </AdminPage>
    )
}