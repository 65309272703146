import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

import { FormLayout, TextField, Page, Form, Button, TextStyle, InlineError, Icon } from '@shopify/polaris';
import {
    TickMinor
} from '@shopify/polaris-icons';

import useInstallData from '../../hooks/useInstallData';
import { useAppAuthState } from '../../authState';
import PublicPage from '../../components/PublicPage';
import { isEmailValid, isStringNonEmpty } from '../../utils/strings';

import './signup.css';

function SignUp() {
    const { shopOrigin, signUpAdmin } = useAppAuthState();
    const history = useHistory();

    const { installKey, removeInstallData } = useInstallData(shopOrigin);
    const postInstall = useRef(!!installKey);

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState(null);
    const [firstnameError, setFirstnameError] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordHelp, setPasswordHelp] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [confirmPasswordHelp, setConfirmPasswordHelp] = useState(null);

    const [signUpError, setSignUpError] = useState(null);
    const [isAuthLoading, setAuthLoading] = useState(false);

    const validatePassword = () => {
        if (!password || password === '' || !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password)) {
            setPasswordError('Minimum eight characters, at least one letter and one number');
            return false;
        }
        setPasswordError(null);
        return true;
    }

    const validateConfirmPassword = () => {
        if (password !== confirmPassword) {
            setConfirmPasswordError(`Passwords don't match`);
            return false;
        }
        setConfirmPasswordError(null);
        return true;
    }

    const validateFirstName = () => {
        if (!isStringNonEmpty(firstName)) {
            setFirstnameError('first name is required');
            return false;
        }
        setFirstnameError(null);
        return true;
    }

    const validateLastName = () => {
        if (!isStringNonEmpty(lastName)) {
            setLastnameError('last name is required');
            return false;
        }
        setLastnameError(null);
        return true;
    }

    const validateEmail = () => {
        if (email === '' || !email) {
            setEmailError('email is required');
            return false;
        } else if (!isEmailValid(email)) {
            setEmailError('email is not valid');
            return false;
        }
        setEmailError(null);
        return true;
    }

    const handleSubmit = async () => {
        setSignUpError(null);

        if (!validateFirstName() || !validateLastName()) {
            return;
        }

        if (!validateEmail()) {
            return;
        }

        if (!validatePassword() || !validateConfirmPassword()) {
            return;
        }

        setAuthLoading(true);
        try {
            await signUpAdmin(email, password, firstName, lastName);
            removeInstallData();
            history.push(postInstall.current ? '/onboard' : '/');
        } catch (error) {
            console.error('Signup error', error);
            setAuthLoading(false);
            setPassword('');
            setConfirmPassword('');
            switch (error.code) {
                case 'auth/email-already-in-use':
                    setSignUpError(new Error(`Email address ${email} already in use.`));
                    break;
                case 'auth/invalid-email':
                    setSignUpError(new Error(`Email address ${email} is invalid.`));
                    break;
                case 'auth/weak-password':
                    setSignUpError(new Error(`Password is too weak`));
                    break;
                default:
                    console.error(error);
                    setSignUpError(new Error(`An error occured - please try again`));
            }
        }
    }

    useEffect(() => {
        if (!!postInstall.current) {
            firebase.analytics().logEvent('admin.onboard.sign_up');
        }
    }, []);

    useEffect(()=>{
        if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password)) {
            setPasswordHelp(
                <div style={{ color: 'green', display: 'flex'}}>
                    <div>
                    <Icon source={TickMinor} color='green' />
                    </div>
                    <p> Minimum eight characters, at least one letter and one number </p>
                </div>)
        } else {
            setPasswordHelp(<p> Minimum eight characters, at least one letter and one number </p>)
        }
        setPasswordError(null);

    },[password])

    useEffect(()=>{
        if (password === confirmPassword && confirmPassword && confirmPassword !== '') {
            setConfirmPasswordHelp(
                <div style={{ color: 'green', display: 'flex'}}>
                    <div>
                    <Icon source={TickMinor} color='green' />
                    </div>
                    <p> Passwords match </p>
                </div>)
        } else {
            setConfirmPasswordHelp(null);
        }
        setConfirmPasswordError(null);

    },[confirmPassword, password])

    // if its not a post install journey then redirect
    if (!postInstall.current) {
        console.log('SignUp: its not a post install onboard journey then redirect');
        return <Redirect to='/' />
    }

    return (
        <Page>
            <div className='signup-container'>
                <div className='signup-row'>
                    <div className='signup-content'>
                        <img src='/boutiq_dark.svg' className='logo' alt='Boutiq logo' />
                        <div className='trailing'>
                            <TextStyle variation='subdued'>Already have an account? <Link to={'/login'}>Login</Link></TextStyle>
                        </div>
                        <div className='signup-form'>
                            <Form preventDefault noValidate implicitSubmit={false}>
                                <FormLayout>
                                    <FormLayout.Group condensed>
                                        <TextField
                                            value={firstName}
                                            onChange={setFirstName}
                                            label="First Name *"
                                            type="text"
                                            onBlur={validateFirstName}
                                            error={firstnameError && firstnameError}
                                        />
                                        <TextField
                                            value={lastName}
                                            onChange={setLastName}
                                            label="Last Name *"
                                            type="text"
                                            onBlur={validateLastName}
                                            error={lastnameError && lastnameError}
                                        />
                                    </FormLayout.Group>
                                    <TextField
                                        value={email}
                                        onChange={setEmail}
                                        label="Email *"
                                        type="email"
                                        onBlur={validateEmail}
                                        error={emailError && emailError}
                                    />
                                    <TextField
                                        value={password}
                                        onChange={setPassword}
                                        label="Password *"
                                        type="password"
                                        helpText={!passwordError && passwordHelp}
                                        onBlur={validatePassword}
                                        error={passwordError && passwordError}
                                    />
                                    <TextField
                                        value={confirmPassword}
                                        onChange={setConfirmPassword}
                                        label="Confirm Password *"
                                        type="password"
                                        helpText={!confirmPasswordError && confirmPasswordHelp}
                                        onBlur={validateConfirmPassword}
                                        error={confirmPasswordError && confirmPasswordError}
                                    />
                                    <Button primary size='large' onClick={handleSubmit} loading={isAuthLoading}>Create Account</Button>
                                </FormLayout>
                            </Form>
                        </div>
                        <InlineError message={signUpError && signUpError.message} />
                    </div>
                    <div className='landing-media'>
                        <img src='/landing_page.png' alt='Host video app' />
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default function() {
    return (
        <PublicPage>
            <SignUp/>
        </PublicPage>
    )
}
