import React from 'react';
import './styles.css';

export default function EventTypeBadge({ name, color}) {
    return (
        <span className='event-type-badge-wrapper'>
            <span className='event-type-badge-color' style={{borderLeftColor: color}}/>
            {name}
        </span>
    )
}