import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopTeams(shopId) {
    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('shopUserTeams') : null;
    const [query, teamsLoading, teamsError] = useCollection(ref);
    const shopUserTeams = query ? query.docs.map(doc => ({ id: doc.id, ...doc.data()})) : [];
    return { shopUserTeams, teamsLoading, teamsError }
}
