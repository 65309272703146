import React, { useState } from 'react';
import IntroPageProvider from './IntroPageProvider';
import UserActivityButtons from './activityButtons/userActivityButtons';
import IntroVideo from './introVideo/intro-video';
import './styles.css';
import StoryProductsMockup from "./storyProductsMockup/story-products-mockup";

export const PreviewStoriesMockup = ({
    cornerStyle,
    primaryColor,
    playList = [],
    isBoutiqClientStory
}) => {

  const [videoElement, setVideoElement] = useState(null)

  return (
        <div style={{ '--main-color': primaryColor }} className={`stories-mockup-container${cornerStyle ? ` ${cornerStyle}` : ''}`}>
            <div style={{ color: primaryColor }} className={`stories-mockup-border${cornerStyle ? ` ${cornerStyle}` : ''}`}>
            </div>
            <IntroPageProvider
                introVideosConfig={playList}
            >
                <IntroVideo onSetVideoElement={setVideoElement}  />
                <UserActivityButtons
                    cornerStyle={cornerStyle}
                    outgoingCallsAllowed={true}
                    isBoutiqClientStory={isBoutiqClientStory}
                />
                <StoryProductsMockup videoElement={videoElement} />
            </IntroPageProvider>
        </div>
    )
}
