import React from "react";
import { Spinner, Icon } from '@shopify/polaris';
import { CircleDisabledMajor } from '@shopify/polaris-icons';
import './styles.css'

export const CustomThumbnailImage = ({ alt, source, isLoading = false, disabled }) => {

    return (
        <div className={`thumbnail-image-container${disabled ? ' disabled' : ''}`}>
            {disabled && <div className='upload-disabled-container'>
                <Icon
                    source={CircleDisabledMajor}
                    color="critical" /></div>}
            {isLoading && <Spinner accessibilityLabel="Spinner example" size="large" />}
            {source && <img className='thumbnail-image' alt={alt} src={source} />}
        </div>
    )
}
