import React from 'react';
import {TextField, Stack, Button} from '@shopify/polaris';
import {CancelSmallMinor} from '@shopify/polaris-icons';

import './styles.css';

export default function TimeOnPageBlock({ deleteRule, ruleId, changeTime, time }) {

  const deleteCurrentTime = () => deleteRule(ruleId);

  return (
    <Stack alignment='leading' spacing='tight'>
      <div className='time-on-page-block_label'>
        <p>Time on page</p>
      </div>
      <Stack.Item fill>
        <TextField type='number' value={time} onChange={changeTime} prefix='more than' suffix='sec' />
      </Stack.Item>
      <Button icon={CancelSmallMinor} onClick={deleteCurrentTime}></Button>
    </Stack>
  );
}
