import { Box, Card, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail, Stack } from '@shopify/polaris';
import React from 'react';

const StoryProductsSettingsSkeleton = () => {
  return (
    <Card.Section>
      <Box padding={'2'} style={{display: 'grid', gridTemplateColumns: '6rem 1fr', margin: '1rem'}}>
        <SkeletonThumbnail size="large" />
        <Stack vertical spacing={'baseTight'}>
          <Stack vertical>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </Stack>
          <Stack vertical>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </Stack>
          <Stack vertical>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </Stack>
        </Stack>
      </Box>
    </Card.Section>
  )
}

export default StoryProductsSettingsSkeleton;
