import React from 'react';
import moment from 'moment-timezone';
import { Stack, TextStyle, Button, Modal, Subheading } from '@shopify/polaris';
import { formatEventDate, instanceDuration } from './utils';
import {CalendarEventType} from "./index";
import {useHistory} from "react-router-dom";
import Moment from "moment-timezone";

export const CaazamBlockModal = ({ open, onClose, data, viewType, adminShops }) => {
    const history = useHistory();
    if (!data || !open) return null;

    const onViewEvent = () => {
        if (data.type === CalendarEventType.CallAvailability) {
            const hostId = data.host.id
            history.push(`/calendar/hosts/${hostId}`, {
                eventId: data.id,
                start: Moment(data.start).startOf('week').toDate(),
                end: Moment(data.end).startOf('week').toDate()
            })
        } else {
            window.location
              .replace(`${window.location.origin}${window.location.pathname}?shop=${data.shopId}&viewType=${viewType}&date=${moment(data.start)
                .startOf('day')
                .toISOString()}`
              )
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={data.modalTitle ?? (
              <p>Blocked by <TextStyle variation='strong'>{adminShops[data.shopId]?.name || data.shopId}</TextStyle></p>
            )}
        >
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <Subheading>Date and time </Subheading>
                    <Stack alignment='start' vertical spacing='extraTight'>
                        <Stack alignment='center'>
                            <TextStyle variation='strong'>{formatEventDate(data.start)}</TextStyle>
                            {adminShops?.[data.shopId] && (
                              <Button plain onClick={onViewEvent}>{data.type === CalendarEventType.CallAvailability ? 'Edit' : 'View event'}</Button>
                            )}
                            </Stack>
                        <TextStyle>{instanceDuration(moment(data.start), moment(data.end))} minutes</TextStyle>
                    </Stack>
                </Stack>
            </Modal.Section>
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <Subheading>Host details</Subheading>
                    <p>{`${data?.host?.firstName} ${data?.host?.lastName}`}</p>
                    <p>{data?.host?.email}</p>
                </Stack>
            </Modal.Section>
        </Modal>
    )
}
