import React from 'react';
import {Stack, Button, Select} from '@shopify/polaris';
import {CancelSmallMinor} from '@shopify/polaris-icons';

import './styles.css';

export default function ShopOpenBlock({deleteRule, ruleId, isShopOpened, handleSelectOpenShopChange, OPTIONS}) {

  const deleteOpenShopRule = () => deleteRule(ruleId);

  return (
    <Stack alignment='leading' spacing='tight'>
      <div className='shop-open-block_label'>
        <p>Within business hours</p>
      </div>
      <Stack.Item fill>
        <Select
          options={OPTIONS}
          onChange={handleSelectOpenShopChange}
          value={isShopOpened}
        />
      </Stack.Item>
      <Button icon={CancelSmallMinor} onClick={deleteOpenShopRule}></Button>
    </Stack>
  );
}
