import { useEffect, useState } from 'react';
import { getStorage, getBytes, ref } from "firebase/storage";

export default function useCallTranscriptData(transcriptUrl) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);
  
  useEffect(() => {
    setError(null);
    setLoading(true);
    let storageRef = ref(getStorage(), transcriptUrl);
    getBytes(storageRef)
    .then(jsonFile=>{
      setTranscriptData(JSON.parse(new TextDecoder().decode(jsonFile)))
    })
    .catch(error=>{
      console.error('useCallTranscriptData',error);
      setError(error);
      setTranscriptData(null);
    })
    .finally(()=>{
      setLoading(false);
    })

  }, [transcriptUrl])

  return ({
    transcriptLoading: loading,
    transcriptError: error,
    transcriptData,
  })
}
