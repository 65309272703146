import { useEffect, useState, useCallback } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopCallsFeedback(shopId, rowsCount, filter, realtimeUpdates) {
	const [feedback, setFeedback] = useState([]);
	const [loading, setLoading] = useState(false)
	const [getNextFeedback, setNextFeedback] = useState(null);
	const { hostId, eventId, range, search, onlyMessages } = filter;

	const getQuery = (cursor) => {
		let q = Firebase
			.firestore()
			.collectionGroup('callsFeedback')
			.where('shopId', '==', shopId)

		if (search) {
			q = q
				.where('customerEmail', '==', search)
				.orderBy('timestamp', 'desc');
		} else {
			if (range) {
				const start = range.start.set({hour: 0, minutes: 0, seconds: 0}).toDate()
				const end = range.end.set({hour: 23, minutes: 59, seconds: 59}).toDate()
				q = q
					.where('timestamp', '<=', end)
					.where('timestamp', '>=', start)
			}
			if (hostId) {
				q = q.where('hostId', '==', hostId);
			}
			if (eventId) {
				q = q.where('eventTypeId', '==', eventId);
			}
			q = q.orderBy('timestamp', 'desc');
		}

		if (cursor) {
			q = q.startAt(cursor);
		}
		return q;
	}

	const getFeedback = async (shopId, limit, cursor = null) => {
		setLoading(true)
		const q = getQuery(cursor);
		const querySnap = await q.limit(limit + 1).get();
		let docs = querySnap.docs;
		let next = null;
		if (querySnap.size > limit) {
			const nextCursor = docs.pop();
			next = () => () => getFeedback(shopId, limit, nextCursor);
		}
		return { docs, next };
	}

	const setNewDocs = (docs) => {
		setFeedback(docs
			.filter(doc => onlyMessages ? !!doc.data().message : true)
			.map(doc => ({ ...doc.data(), id: doc.id }))
		);
	}

	const appendNewDocs = (docs) => {
		setFeedback(prev => [
			...prev,
			...docs
				.filter(doc => onlyMessages ? !!doc.data().message : true)
				.map(doc => ({ ...doc.data(), id: doc.id }))]);
	}

	useEffect(() => {
		if (realtimeUpdates) {
			setLoading(true)
			const q = getQuery();
			const listener = q.limit(rowsCount).onSnapshot((snap) => {
				setNewDocs(snap.docs);
				setNextFeedback(null);
				setLoading(false)
			});
			return () => listener();
		} else {
			getFeedback(shopId, rowsCount * 2)
				.then(({ docs, next }) => {
					setNewDocs(docs);
					setNextFeedback(next);
					setLoading(false)
				});
		}
	}, [])

	const refresh = () => {
		getFeedback(shopId, rowsCount * 2, null)
		.then(({ docs, next }) => {
			setNewDocs(docs);
			setNextFeedback(next);
			setLoading(false)
		});
	};

	const getMoreFeedback = () => {
		if (getNextFeedback) {
			getNextFeedback()
			.then(({ docs, next }) => {
				appendNewDocs(docs);
				setNextFeedback(next);
			});
		}
	}
	
	return {
		feedback,
		getMoreFeedback,		
		refresh,
		loading
	};
}
