import React, { useState, useEffect } from 'react'
import { getRandom } from '../../../../../../utils/formatters';
import './styles.css';

export const AllHostsAvatar = ({ hosts }) => {
    const [currentHosts, setCurrentHosts] = useState([])
    useEffect(() => {
        if (hosts.length > 4) {
            setCurrentHosts(getRandom(hosts, 3))
        } else {
            setCurrentHosts(getRandom(hosts, 4))
        }
    }, [hosts]);

    return (
        <div className={`multiple-host-avatar-container${currentHosts.length === 2 ? ' row' : ''}`}>
            {currentHosts.map((hostData, index) => <ResizableHostAvatar key={`${hostData.hostId}${index}`} avatar={hostData} />)}
            {(currentHosts.length === 3 && hosts.length > 4) && <ResizableHostAvatar
                key='counter'
                avatar={{ initials: `+${hosts.length - 3}` }}
                style={{ '--font-color': 'var(--light-font-color)', '--font-size': 14 }}
            />}
        </div>
    )
}

const ResizableHostAvatar = ({ avatar, ...props }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showInitials, setShowInitials] = useState(false);

    const fallbackToInitials = () => {
        setShowInitials(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }

    return (
        <div className='host-avatar-container-wrapper' {...props}>
            {avatar.avatarUrl ?
                showInitials ?
                    <span className='host-avatar initials'>{`${avatar.firstName && avatar.firstName[0]}${avatar.lastName && avatar.lastName[0]}`}</span>
                    : <img onError={() => fallbackToInitials()} onLoad={animateImageOnLoad} className={`host-avatar${isImageLoaded ? ' loaded' : ''}`} src={avatar.avatarUrl} />
                : <span className='host-avatar initials'>{`${avatar.firstName && avatar.firstName[0]}${avatar.lastName && avatar.lastName[0]}`}</span>}
        </div>
    )
}
