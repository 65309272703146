import { useEffect, useState } from 'react';

import useShopEvents from './useShopEvents';
import useCaazamREST from './useCaazamREST';

var slotsCache = {};

export default function useShopCalendar(shopId, startDate, endDate, withSlots) {

  const { getSchedulingSlots } = useCaazamREST();
  const { shopEvents, shopEventsLoading, shopEventsError } = useShopEvents(shopId, startDate, endDate);

  const [slotsStart, setSlotsStart] = useState(startDate?.toISOString());
  const [slotsEnd, setSlotsEnd] = useState(endDate?.toISOString());
  const [slots, setSlots] = useState([]);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [slotsError, setSlotsError] = useState(null);

  useEffect(()=>{
    setSlotsStart(startDate.toISOString());
    setSlotsEnd(endDate.toISOString());
  },[startDate, endDate])

  useEffect(() => {
      if (!withSlots) {
        setSlots([]);
        return;
      }
      
      const cacheKey = `${slotsStart}-${slotsEnd}`;
      if(slotsCache[cacheKey]) {   
        setSlots(slotsCache[cacheKey])
      }

      setSlotsLoading(true);
      getSchedulingSlots(shopId, slotsStart, slotsEnd)
        .then(({ slots }) => {
          setSlots(slots)
          slotsCache[cacheKey] = slots;
        })
        .catch(error => {
          console.error('getSchedulingSlots error', error)
          setSlotsError(error);
        })
        .finally(() => setSlotsLoading(false));
  }, [slotsStart, slotsEnd, withSlots]);

  return {
    shopEvents, shopEventsLoading, shopEventsError,
    slots, slotsLoading, slotsError,
  };
}
