
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { UserRole } from '../utils/permissions';

export default function useShopSubscription(shopId, role) {

    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('subscription').doc('active') : null;
    const [subData, subDataLoading, subDataError] = useDocumentData(ref);
    subDataError && console.error('useShopSubscription', subDataError);

    return { subData, subDataLoading, subDataError }
}
