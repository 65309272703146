import React, { useCallback, useState, useEffect } from 'react';
import { DropZone, Stack, Modal, Banner, List, TextStyle } from '@shopify/polaris';
import { useShopProvider } from '../../../../components/ShopProvider';
import useCaazamREST from '../../../../hooks/useCaazamREST';
import { UploadingVideoItem } from './components/UploadingVideoItem';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import { useStoriesProvider } from '../StoryiesProvider';

const ERROR_MESSAGES = {
  UNSUPPORTED_FORMAT: 'Unsupported file format',
  MEDIA_NO_AUDIO_TRACK: 'No audio track detected',
  NOT_PORTRAIT_ORIENTATION: 'Please upload video in portrait mode',
  NOT_VALID_MEDIA_DURATION: 'Video duration should be less than 60 seconds',
  NOT_SUPPORTED_BY_BROWSER: `This video file could not be read by your browser. If you are uploading a video from an iPhone, please use Safari. To continue, choose another file.`,
  VIDEO_CREATE_ERROR: 'Video create error',
  VIDEO_UPLOAD_ERROR: 'Video upload error',
}
const MAX_VIDEO_DURATION = 75;
const POSTESR_HEIGHT = 720;
const POSTER_TIMESTAMP = 1.0;

export const VideoUploader = ({
  isOpen,
  setIsOpen,
  setUploadingStates,
  uploadingStates,
}) => {
  const { shopOrigin } = useShopProvider();
  const {onClearValidationError, isBoutiqClientStory} = useStoriesProvider()
  const { createStoryVideo } = useCaazamREST();
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessages, setErrorMessagess] = useState([]);
  const [warnMessages, setWarnMessagess] = useState([]);
  const [thumbnailSource, setThumbnailSource] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [isUploadAvailable, setIsUploadAvailable] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasAudio, setHasAudio] = useState(true);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const removeFromVideoBatch = (canceledFile, event) => {
    event.stopPropagation();
    setFile(null);
  }

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    [],
  );

  const snapImage = (video) => {
    let canvas = document.createElement('canvas');
    const posterWidth = video.videoWidth * POSTESR_HEIGHT / video.videoHeight
    canvas.width = posterWidth;
    canvas.height = POSTESR_HEIGHT;
    canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, posterWidth, POSTESR_HEIGHT);
    const image = canvas.toDataURL('image/jpeg');
    const success = image.length > 100;
    if (success) {
      let img = document.createElement('img');
      img.src = image;
      setThumbnailSource(image);
      setPreviewImage(img.src);
    }
    return success;
  };

  const checkAudio = (video) => {
    return video.mozHasAudio ||
      Boolean(video.webkitAudioDecodedByteCount) ||
      Boolean(video.audioTracks && video.audioTracks.length);
  }

  const videoItemHandler = (file) => {
    let errors = [];
    let warnings = [];
    var fileReader = new FileReader();

    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement('video');

      const takePosterSnapshot = () => {
        snapImage(video);
        video.removeEventListener('seeked', takePosterSnapshot);
        setIsPreviewLoading(false);
        video.remove();
      }

      video.addEventListener('loadeddata', async () => {
        if (Number((video.duration).toFixed(0)) > MAX_VIDEO_DURATION) {
          errors = [...errors, ERROR_MESSAGES.NOT_VALID_MEDIA_DURATION]
        }
        if (video.videoWidth > video.videoHeight) {
          errors = [...errors, ERROR_MESSAGES.NOT_PORTRAIT_ORIENTATION]
        }

        let videoHasAudio = checkAudio(video);
        setHasAudio(videoHasAudio);
        if (!videoHasAudio) {
          warnings.push(ERROR_MESSAGES.MEDIA_NO_AUDIO_TRACK);
        }
        setErrorMessagess(errors);
        setWarnMessagess(warnings);
        video.currentTime = POSTER_TIMESTAMP;
      });
      video.addEventListener('seeked', takePosterSnapshot);
      video.preload = 'metadata';
      video.src = url;

      // Load video in Safari / IE11 - safari has to play the video in order to fire the seeked event
      video.muted = true;
      video.playsInline = true;
      video.play();
    };
    fileReader.readAsArrayBuffer(file);
  }

  useEffect(() => {
    if (file) {
      setThumbnailSource(null);
      setPreviewImage(null);
      let errors = [];
      if (file.type.match('video.*')) {
        if (file.type !== 'video/quicktime') {
          videoItemHandler(file);
        } else {
          if (isSafari) {
            videoItemHandler(file);
          } else {
            errors = [...errors, ERROR_MESSAGES.NOT_SUPPORTED_BY_BROWSER];
            setIsPreviewLoading(false);
          }
        }
      } else {
        errors = [...errors, ERROR_MESSAGES.UNSUPPORTED_FORMAT];
        setIsPreviewLoading(false);
      }
      setErrorMessagess(errors);
    }
    return () => {
      setErrorMessagess([]);
      setWarnMessagess([]);
      setHasAudio(true);
    }
  }, [file])

  useEffect(() => {
    setIsUploadAvailable(errorMessages.length === 0 && file)
  }, [errorMessages, file])

  const uploadVideo = async () => {
    if (file) {
      onClearValidationError()
      setIsUploading(true);
      try {
        const { videoId, storageUrl, storageUrlPoster } = await createStoryVideo(shopOrigin)
        setUploadingStates([...uploadingStates, ...[{ videoId, poster: previewImage }]])
        try {
          let imgRef = Firebase.storage().refFromURL(storageUrlPoster).child(`${file.name.split('.').slice(0, -1).join('.')}_${Date.now()}.jpeg`);
          await imgRef.putString(previewImage, 'data_url', { customMetadata: { shopId: shopOrigin, videoId } })
        }
        catch (error) {
          console.error(error);
        }
        const fileExtension = file.name.split('.').pop();
        let ref = Firebase.storage().refFromURL(storageUrl).child(`${file.name.split('.').slice(0, -1).join('.')}_${Date.now()}.${fileExtension}`);
        let uploadTask = ref.put(file, { customMetadata: { shopId: shopOrigin, videoId, audio: hasAudio } });
        setIsOpen(false);
        uploadTask.on('state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadingStates([...uploadingStates, ...[{ videoId, poster: previewImage, progress }]]);
            switch (snapshot.state) {
              case Firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
              case Firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
          },
          (error) => {
            console.error('Upload error', error);
            setUploadingStates([...uploadingStates, ...[{ videoId, poster: previewImage, error: [`${ERROR_MESSAGES.VIDEO_UPLOAD_ERROR}: ${error.message}`] }]])
            setIsUploading(false);
          },
          () => {
            Firebase.analytics().logEvent('story_video_upload');
            setIsUploading(false);
            // Handle successful uploads on complete
          });
      } catch (error) {
        console.error('Create error', error);
        setErrorMessagess([`${ERROR_MESSAGES.VIDEO_CREATE_ERROR}: ${error.message}`]);
        setIsUploading(false);
        return;
      }
    }
  setFile(null);
}

const fileUpload = !file && <DropZone.FileUpload actionHint={'Upload a video file'} />;
const uploadedFiles = file && (
  <Stack vertical>
    {file &&
      <UploadingVideoItem
        isDisabled={errorMessages.length > 0}
        thumbnailSource={thumbnailSource}
        isPreviewLoading={isPreviewLoading}
        isFileReady={setIsUploadAvailable}
        setPreviewImage={setPreviewImage}
        cancelVideo={removeFromVideoBatch}
        file={file} />
    })
  </Stack>
);

  function ErrorBanners({ errorMessages, warnMessages }) {
    if (errorMessages.length > 0) {
      return (
        <Stack.Item>
          <Banner status='critical'>
            <List type="bullet">
              {errorMessages.map((error, index) =>
                <List.Item key={index}>{error}</List.Item>
              )}
            </List>
          </Banner>
        </Stack.Item>
      )
    } else if (warnMessages.length > 0) {
      return (
        <Stack.Item>
          <Banner status='warning'>
            <List type="bullet">
              {warnMessages.map((warning, index) =>
                <List.Item key={index}>{warning}</List.Item>
              )}
            </List>
          </Banner>
        </Stack.Item>
      )
    } else return null;
}

return (
  <Modal
    onClose={() => { setIsOpen(false); setFile(null); }}
    open={isOpen}
    title='Upload video'
    primaryAction={{
      content: 'Upload',
      onAction: uploadVideo,
      loading: isUploading,
      disabled: !isUploadAvailable
    }}
    secondaryActions={[
      {
        content: 'Cancel',
        onAction: () => { setIsOpen(false); setFile(null); },
        disabled: isUploading,
      },
    ]}
  >
    <Modal.Section>
      <Stack vertical>
        <TextStyle variation='subdued'>
          <p>We recommend:</p>
          {isBoutiqClientStory
            ? <List type='bullet'>
              <List.Item>a short video (from 10-15 seconds up to 20-30 seconds)</List.Item>
              <List.Item>taken in portrait mode (using a mobile phone works great)</List.Item>
              <List.Item>combine personal welcome and short introduction or feature a product</List.Item>
            </List>
            : <List type='bullet'>
              <List.Item>a short video (from 10-15 seconds up to 60 seconds)</List.Item>
              <List.Item>taken in portrait mode (using a mobile phone works great)</List.Item>
              <List.Item>Feature a product or highlight your brand</List.Item>
            </List>}
        </TextStyle>
        <Stack.Item fill>
          <DropZone
            variableHeight
            accept='video/*'
            type='file'
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
          >
            {uploadedFiles}
            {fileUpload}
          </DropZone>
        </Stack.Item>
        <ErrorBanners errorMessages={errorMessages} warnMessages={warnMessages}/>
      </Stack>
    </Modal.Section>
  </Modal>
);
}
