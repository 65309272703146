import { Button, ActionList, Popover } from '@shopify/polaris';
import React, { useState, useCallback } from 'react';
import { AVAILABLE_FONTS, DEFAULT_FONT_FAMILY } from '../../utils/consts';

export const FontSettings = ({ selectedFont, onFontFamilyChange }) => {
    const [active, setActive] = useState(false);
    const customFontEnabled = typeof selectedFont === 'object' &&
        !Array.isArray(selectedFont) &&
        selectedFont !== null;
    const unofficialFont = !AVAILABLE_FONTS.includes(selectedFont);
    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const activator = (
        <Button disabled={customFontEnabled || unofficialFont} textAlign='left' fullWidth={true} onClick={toggleActive} disclosure>
            {customFontEnabled || unofficialFont ? 'Custom font enabled' : 
                selectedFont === DEFAULT_FONT_FAMILY ? `${selectedFont} (default)` : selectedFont}
        </Button>
    );

    const selectActive = useCallback((selected) => {
        onFontFamilyChange(selected)
        toggleActive()
    }, []);

    return (
        <Popover
            fullWidth={true}
            active={active}
            activator={activator}
            onClose={toggleActive}
            preferredAlignment="left"
        >
            <ActionList
                actionRole="menuitem"
                items={
                    AVAILABLE_FONTS.map((font =>
                    ({
                        onAction: () => selectActive(font),
                        content: <span style={{ fontFamily: font }}>{font !== DEFAULT_FONT_FAMILY ? font : `${font} (default)`}</span>,
                        active: font === selectedFont,
                    })))
                }
            />
        </Popover>
    )
}
