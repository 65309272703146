import React from "react";

const CustomizedHostAxisTick = ({hosts, x, y, payload}) => {
  let value = payload.value;
  let fill = '#666'
  const host = hosts.find(host => host.id === payload.value)
  if (host) {
    value = `${host.firstName} ${host.lastName}`;
  } else {
    value = `*${value.substring(0,5)}...`;
    fill = '#999'
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill={fill} transform={`rotate(-25)`}>{value}</text>
    </g>
  );
}

export default CustomizedHostAxisTick;
