import React from 'react';
import {TextField, Icon, Select, Button, Stack} from '@shopify/polaris';
import {CancelSmallMinor} from '@shopify/polaris-icons';

import './styles.css';

export default function UrlRuleBlock({deleteRule, addRule, ruleId, handleSelectUrlChange, selectedUrlRule, url, changeUrl, OPTIONS, error}) {

  const deleteCurrentRule = () => deleteRule(ruleId);

  return (

      <Stack alignment='leading' spacing='tight'>
        <div className='current-url-block_label'>
          <p>Page URL</p>
        </div>
        <Select
          options={OPTIONS}
          onChange={handleSelectUrlChange}
          value={selectedUrlRule}
      />
      <Stack.Item fill>
        <TextField value={url} onChange={changeUrl} error={error} placeholder={'/'} />
      </Stack.Item>
        {deleteRule && <Button icon={CancelSmallMinor} onClick={deleteCurrentRule}></Button>}
        {addRule && <Button onClick={addRule}>Add</Button>}
      </Stack>
  );
}
