import React, { createContext, useContext } from 'react';
import { useShopProvider } from '../../../components/ShopProvider';
import useShopShopifyTheme from '../../../hooks/useShopShopifyTheme';
import useShopLinks from '../../../hooks/useShopLinks';

const ClientSettingsContext = createContext(null);

export const ClientSettingsProvider = ({children}) => {
  const { shopOrigin, headlessConfig, headlessConfigLoading } = useShopProvider()
  const { shopifyTheme, shopifyThemeLoading ,verifyingSections, supportsThemeSections } = useShopShopifyTheme(shopOrigin);
  const { links } = useShopLinks(shopOrigin);

  return (
    <ClientSettingsContext.Provider
      value={{
        headlessConfig,
        headlessConfigLoading,
        shopifyTheme, 
        shopifyThemeLoading,
        verifyingSections, 
        supportsThemeSections,
        shopLinks: links,
      }}
    >
      {children}
    </ClientSettingsContext.Provider>
  )
}

export function useClientSettingsProvider() {
  const context = useContext(ClientSettingsContext);
  if (!context) {
    throw new Error('useClientSettingsProvider must be used within the ClientSettingsProvider');
  }
  return context;
}
