
import { useCallback, useState, useEffect } from 'react';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

export const ShopIntegrations  = {
    endear: {
        id: 'endear',
        doc: 'endear',
        name: 'Endear',
        postSave: async (shopId) => {
            const fetchCustomFields = Firebase.functions().httpsCallable('endear-fetchCustomFields');
            await fetchCustomFields({ shopId });
        },
        isCustomerProfile: true,
        auth: 'token',
        logo: '/integrations/endear.png',

    },
    klaviyo: {
        id: 'klaviyo',
        doc: 'klaviyo',
        name: 'Klaviyo',
        isCustomerProfile: false,
        auth: 'privateAPIKey',
        logo: '/integrations/klaviyo.jpeg',
    },
    segment: {
        id: 'segment',
        doc: 'segment',
        name: 'Segment',
        isCustomerProfile: false,
        auth: 'writeAPIKey',
        logo: '/integrations/segment.svg',
    }

}

export default function useShopIntegrationsConfig(shopId) {

    const [integrations, setIntegrations] = useState({});
    const [integrationsLoading, setIntegrationsLoading] = useState(true);
    const [integrationsError, setIntegrationsError] = useState();

    useEffect(()=>{
        if (shopId) {
            const unsub = Firebase.firestore().collection('shops').doc(shopId).collection('integrations').onSnapshot(snapshot => {
                setIntegrations(snapshot.docs.reduce((agg, doc) => ({ ...agg, [doc.id]: doc.data()}), {}));
                setIntegrationsLoading(false);
                setIntegrationsError(null);
            }, (error) => {
                setIntegrations({});
                setIntegrationsLoading(false);
                setIntegrationsError(error);
                console.error('useShopIntegrationsConfig', error);
            });

            return unsub;
        }

    },[shopId])

    const saveIntegrationConfig = useCallback(async (type, config) => {
        const integ = ShopIntegrations[type];
        if (!integ) {
            throw new Error(`${type} not an unsupported integration` );
        }

        const doc = Firebase.firestore().collection('shops').doc(shopId).collection('integrations').doc(integ.doc);
        if (config[integ.auth]) {
            await doc.set({
                ...config,
                name: integ.name,
                isCustomerProfile: integ.isCustomerProfile,
                error: Firebase.firestore.FieldValue.delete(),
                updatedAt: Firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true });
            if (integ.postSave) {
                await integ.postSave(shopId);
            }
        }
        else
            await doc.delete();
    }, [shopId]);

    return { integrations, integrationsLoading, integrationsError, saveIntegrationConfig }
}
