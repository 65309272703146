import React, { useState, useEffect, useCallback } from 'react';
import { Card, Checkbox, ContextualSaveBar, Stack, Subheading, TextContainer, TextStyle } from "@shopify/polaris";
import objEqual from 'fast-deep-equal';
import { useShopProvider } from './ShopProvider';
import useClientFormConfig from '../hooks/useClientFormConfig';
import { objectMergeDeep } from '../utils/objectMergeDeep';
import { ScheduleForm } from './scheduleForm/ScheduleForm';
import { omit } from '../utils/objects';
import { OPTIONS_REQUIRED } from './scheduleForm/consts';
import { WysiwygTextEditor } from './WysiwygTextEditor';
import { WYSIWYG_TERMS_EDITOR_OPTIONS } from '../utils/consts';

const defaultConfig = {
    videoCall: {
        email: {
            hidden: false,
            required: false,
        },
        terms: {
            label: '',
            hidden: true,
        }
    },
    scheduling: {
        phone: {
            hidden: false,
            required: false,
        }
    },
}

export default function ClientFormConfigurator() {
    const { shopOrigin } = useShopProvider();
    const [errors, setErrors] = useState({});
    const { formConfigData, formConfigLoading, setClientFormConfig: saveConfig } = useClientFormConfig(shopOrigin);
    const [clientAppFormConfig, setClientAppFormConfig] = useState({ ...objectMergeDeep({}, defaultConfig) })
    const [dirtyBit, setDirtyBit] = useState(false);
    const [countOfTerms, setCountOfTerms] = useState(0);

    useEffect(() => {
        if (!formConfigLoading) {
            setClientAppFormConfig({ ...objectMergeDeep({}, defaultConfig, formConfigData) });
        }
    }, [formConfigData, formConfigLoading]);

    useEffect(() => {
        setDirtyBit(!objEqual(objectMergeDeep({}, defaultConfig, formConfigData), clientAppFormConfig));
    }, [formConfigData, clientAppFormConfig])

    const isFieldValid = (field) => {
        if (OPTIONS_REQUIRED.includes(field.type) && (!field.options || field.options.length === 0)) {
            setErrors({ ...errors, [field.id]: true })
        } else {
            setErrors(omit(field.id, errors))
        }
    }

    const updateValue = (value, id) => {
        const newConfig = { ...objectMergeDeep({}, clientAppFormConfig) };
        switch (id) {
            case 'videoCall.email.hidden':
                newConfig.videoCall.email.hidden = !value;
                if (!value)
                    newConfig.videoCall.email.required = false;
                break;
            case 'videoCall.email.required':
                newConfig.videoCall.email.required = value;
                break;
            case 'scheduling.phone.required':
                newConfig.scheduling.phone.required = value;
                break;
            case 'schedulingSchema':
                isFieldValid(value)
                if (newConfig.schedulingSchema?.fields.find(item => item.id === value.id)) {
                    newConfig.schedulingSchema.fields = newConfig.schedulingSchema.fields.map(field => {
                        if (field.id === value.id) {
                            return ({ ...value })
                        }
                        return field;
                    })
                } else {
                    if (newConfig.schedulingSchema?.fields) {
                        newConfig.schedulingSchema.fields = [...newConfig.schedulingSchema.fields, value]
                    } else {
                        newConfig.schedulingSchema = { ...newConfig.schedulingSchema, fields: [value] }
                    }
                }
                break;
            case 'videoCall.terms.hidden':
                newConfig.videoCall.terms.hidden = !value;
                break;
            case 'videoCall.terms.label':
                newConfig.videoCall.terms.label = value;
                break;
            default:
                break;
        }
        setClientAppFormConfig(newConfig);
    }

    const onReorderSchema = (index, direction = false) => {
        const reorderedList = [...clientAppFormConfig.schedulingSchema.fields];
        const currentIndexPosition = direction ? index + 1 : index - 1;
        reorderedList.splice(currentIndexPosition, 0, reorderedList.splice(index, 1)[0]);
        setClientAppFormConfig({ ...clientAppFormConfig, schedulingSchema: { ...clientAppFormConfig.schedulingSchema, fields: reorderedList } });
    }

    const onDeleteSchema = (id) => {
        if (id === 'all') {
            setErrors({})
            setClientAppFormConfig({ ...clientAppFormConfig, schedulingSchema: null });
        } else {
            setErrors(omit(id, errors))
            const filteredList = [...clientAppFormConfig.schedulingSchema.fields];
            setClientAppFormConfig({ ...clientAppFormConfig, schedulingSchema: { ...clientAppFormConfig.schedulingSchema, fields: filteredList.filter(field => field.id !== id) } });
        }
    }

    const discard = () => {
        setClientAppFormConfig({ ...objectMergeDeep({}, defaultConfig, formConfigData) });
    }

    const onError = (value) => {
        if (value.error && !errors[`${value.fieldId}-${value.index}`]) {
            setErrors({ ...errors, [`${value.fieldId}-${value.index}`]: true })
        } else if (!value.error && errors[`${value.fieldId}-${value.index}`]) {
            setErrors(omit(`${value.fieldId}-${value.index}`, errors))
        }
    }

    const save = () => {
        let saveData = { ...clientAppFormConfig };
        let now = new Date().toISOString();
        if (saveData?.schedulingSchema?.fields.length > 0) {
            saveData.schedulingSchema[formConfigData?.schedulingSchema ? 'updatedAt' : 'createdAt'] = now;
            saveData.schedulingSchema.version = now;
        }

        saveConfig(saveData)
            .catch(error => console.error('ClientFormConfigurator save ', error));
    }

    const changeDescription = useCallback((newValue) => updateValue(newValue, 'videoCall.terms.label'), [updateValue]);

    const customFormSchemaEnabled = clientAppFormConfig.schedulingSchema?.fields.length > 0

    return (
        <>
            <Card sectioned>
                <p>Set which form fields are requested and whether they are required or optional:</p>
                <Card.Section title='Pre-video call'>
                    <Stack distribution='fill'>
                        <Stack vertical>
                            <Stack>
                                <Checkbox
                                    label='Client email requested'
                                    id={'videoCall.email.hidden'}
                                    checked={!clientAppFormConfig?.videoCall?.email?.hidden}
                                    onChange={updateValue}
                                />
                                <Checkbox
                                    label='Client email required'
                                    id={'videoCall.email.required'}
                                    checked={clientAppFormConfig?.videoCall?.email?.required}
                                    onChange={updateValue}
                                    disabled={clientAppFormConfig?.videoCall?.email?.hidden}
                                />
                            </Stack>
                            <p>
                                <TextStyle variation='subdued'>
                                    Requesting the client's email will allow Boutiq to fetch and display their client profile on the host app when a video call is connected
                                </TextStyle>
                            </p>
                        </Stack>
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <div style={{ zIndex: 1, position: 'relative' }}>
                        <Stack vertical spacing='tight'>

                            <Checkbox
                                label='Confirm terms and conditions required'
                                id={'videoCall.terms.hidden'}
                                checked={!clientAppFormConfig?.videoCall?.terms?.hidden}
                                onChange={updateValue}
                            />
                            <TextContainer spacing='tight'>
                                <p>
                                    <TextStyle variation='subdued'>
                                        Add a "terms and conditions" checkbox for the client to confirm before joining a call
                                    </TextStyle>
                                </p>
                                <p>
                                    <TextStyle variation='subdued'>
                                        Edit the checkbox label and add optional links to your store's T&Cs
                                    </TextStyle>
                                </p>
                            </TextContainer>
                            <WysiwygTextEditor                                
                                options={WYSIWYG_TERMS_EDITOR_OPTIONS}
                                htmlContent={clientAppFormConfig?.videoCall?.terms?.label ?? ''}
                                maxLength={70}
                                onChange={changeDescription}
                                readOnly={clientAppFormConfig?.videoCall?.terms?.hidden}
                                onLengthChange={(count) => setCountOfTerms(count)}
                            />                            
                        </Stack>
                    </div>
                </Card.Section>
            </Card>
            <Card sectioned>
                <Card.Section title={
                    <Subheading>
                        <TextStyle variation={customFormSchemaEnabled && 'subdued'}>
                            Scheduling {customFormSchemaEnabled ? ' (disabled when there is a custom client form)' : ''}
                        </TextStyle>
                    </Subheading>
                }>
                    <Stack vertical>
                        <Stack>
                            <Checkbox
                                label='Client phone requested'
                                id={'scheduling.phone.hidden'}
                                checked={true}
                                disabled={true || customFormSchemaEnabled}
                            />
                            <Checkbox
                                label='Client phone required'
                                id={'scheduling.phone.required'}
                                checked={clientAppFormConfig?.scheduling?.phone?.required}
                                disabled={formConfigLoading || customFormSchemaEnabled}
                                onChange={updateValue}
                            />
                        </Stack>
                        <p>
                            <TextStyle variation='subdued'>
                                Requesting the client's phone number will allow the host to contact the client before or after the scheduled appointment
                            </TextStyle>
                        </p>
                    </Stack>
                </Card.Section>
            </Card>
            <Card sectioned>
                <ScheduleForm
                    updateValue={updateValue}
                    onDelete={onDeleteSchema}
                    onReorder={onReorderSchema}
                    onError={onError}
                    isLoading={formConfigLoading}
                    formSchema={clientAppFormConfig.schedulingSchema?.fields} />
            </Card>
            {dirtyBit && <ContextualSaveBar
                message="Unsaved changes to client form settings"
                saveAction={{
                    onAction: () => save(),
                    disabled: Object.values(errors).length > 0 || countOfTerms === 0 && !clientAppFormConfig?.videoCall?.terms?.hidden
                }}
                discardAction={{
                    onAction: () => discard(),
                }}
            />}
        </>
    )
}
