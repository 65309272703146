import { useEffect, useState } from 'react';
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import useCallComposition from './useCallComposition';

const useCallRecording = (shopId, callId, calls) => {
  const [loading, setLoading] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [audioSrc, setAudioSrc] = useState('')
  const callAudioRef = document.getElementById(`audio-${callId}`)
  const {callComposition, callCompositionLoading} = useCallComposition(callId, shopId)

  useEffect(() => {
    return () => onRemoveListeners()
  }, [])

  useEffect(() => {
    if (callComposition?.status === 'completed') {
      getRecordUrl()
    }
  }, [callComposition?.status])

  const getRecordUrl = async () => {
    setLoading(true)
    try {
      const url = await getDownloadURL(ref(getStorage(), callComposition?.uri))
      setAudioSrc(url)
      return url
    } catch (e) {
      console.log(e)
    }
  }

  const onPlayAudio = async () => {
    onStopAllAudio()
    let url = ''
    if (!callAudioRef.src) {
      url = await getRecordUrl()
      callAudioRef.src = url
      callAudioRef.currentTime = 0
    } else {
      callAudioRef.play().then(() => {
        setPlaying(true)
      })
    }
    callAudioRef.addEventListener('canplay', canplayEvent);
    callAudioRef.addEventListener('ended', onEndEvent);
    callAudioRef.addEventListener('pause', onPauseEvent);
  }

  const onPauseAudio = () => {
    callAudioRef.pause()
    setPlaying(false)
  }

  const canplayEvent = () => {
    setLoading(false)
    callAudioRef.play().then(() => {
      setPlaying(true)
    })
  }

  const onEndEvent = () => {
    setPlaying(false)
    setLoading(false)
  }

  const onPauseEvent = () => {
    setPlaying(false)
  }

  const onStopAllAudio = () => {
    if (calls) {
      calls.forEach(({id}) => {
        const audioRef = document.getElementById(`audio-${id}`)
        audioRef.pause()
      })
    }
  }

  const onRemoveListeners = () => {
    if (calls) {
      calls.forEach(({id}) => {
        const audioRef = document.getElementById(`audio-${id}`)
        audioRef.removeEventListener('canplay', () => null)
        audioRef.removeEventListener('ended', () => null)
        audioRef.removeEventListener('pause', () => null)
      })
    } else if (callAudioRef) {
      callAudioRef.removeEventListener('canplay', () => null)
      callAudioRef.removeEventListener('ended', () => null)
      callAudioRef.removeEventListener('pause', () => null)
    }
  }

  return ({
    recordingAudioLoading: loading,
    callCompositionLoading,
    playing,
    onPauseAudio,
    onPlayAudio,
    audioSrc,
    callComposition
  })
}

export default useCallRecording;
