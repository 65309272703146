import React, {useEffect, useState} from 'react'
import AdminPage from "../../components/AdminPage";
import AppPage from "../../components/AppPage";

import { useHistory, useParams } from "react-router-dom";
import ShowroomPresetDetails from "./components/ShowroomPresetDetails";
import useShowroomPresetAction from "../../hooks/useShowroomPresetAction";

const ShowroomPresetDetailsPage = () => {
    const history = useHistory();
    const {id} = useParams();
    const {getPreset, loading} = useShowroomPresetAction()
    const [preset, setPreset] = useState(null)


    useEffect(() => {
       if (id !== 'create') {
           getPreset(id).then(setPreset)
       }
    }, [id])

    const pageTitle = () => {
        if (loading) {
            return ''
        } else {
            return `${preset ? `Edit: ${preset.name}` : 'Create showroom'}`
        }
    }

    return (
        <AdminPage>
            <AppPage
              title={pageTitle()}
              breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            >
                <ShowroomPresetDetails preset={preset} loadPreset={loading}/>
            </AppPage>
        </AdminPage>
    )
}

export default ShowroomPresetDetailsPage;