import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import objEqual from 'fast-deep-equal';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Layout, Card, TextField, SkeletonBodyText, Stack, Checkbox, ContextualSaveBar, Banner, TextStyle, Toast, Button, InlineError } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useShopHeadlessActions } from '../../hooks/useShopHeadlessConfig';
import { useShopProvider } from '../../components/ShopProvider';
import { DuplicateMinor } from '@shopify/polaris-icons';
import CopyToClipboard from 'react-copy-to-clipboard';


const HEADLESS_DEFAULT_CONFIG = {
    isHeadless: false,
    baseUrl: null,
    path: '/boutiq-video',
}

function HeadlessConfig() {
    const history = useHistory();
    const { shopOrigin, shopData, shopDataLoading, headlessConfig, headlessConfigLoading, } = useShopProvider();
    const { setHeadlessConfig } = useShopHeadlessActions(shopOrigin);
    const shopPrimaryDomain = shopData?.primaryDomain?.url;
    const SHOP_HEADLESS_DEFAULT_CONFIG = { ...HEADLESS_DEFAULT_CONFIG, baseUrl: shopPrimaryDomain };
    const [config, setConfig] = useState({ ...SHOP_HEADLESS_DEFAULT_CONFIG });
    const [dirtyBit, setDirtyBit] = useState(false);
    const [baseError, setBaseError] = useState(false);
    const [pathError, setPathError] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [saveError, setSaveError] = useState(false);

    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);

    useEffect(() => {
        if (!headlessConfigLoading) {
            setConfig({ ...config, ...SHOP_HEADLESS_DEFAULT_CONFIG, ...headlessConfig });
        }
    }, [headlessConfig, headlessConfigLoading])

    useEffect(() => {
        const prevConfig = { ...SHOP_HEADLESS_DEFAULT_CONFIG, ...headlessConfig }
        setDirtyBit(!objEqual(prevConfig, config));
    }, [headlessConfig, config, shopPrimaryDomain])

    const handleChangeHeadless = (checked) => {
        setConfig(prev => ({ ...prev, isHeadless: checked }));
        if (!checked) {
            setBaseError(null);
            setPathError(null);
        } else {
            if (!config.baseUrl) {
                setBaseError(new Error('please enter a valid URL origin, e.g https://www.your-shop.com'));
            }
            if (!config.path) {
                setPathError(new Error('please enter a valid URL path, e.g /boutiq-video'));
            }
        }
    }

    const handleChangeBase = (val) => {
        setBaseError(null);
        setDirtyBit(val !== config.baseUrl)
        setConfig({ ...config, baseUrl: val });

    }

    const validateBase = () => {
        if (config.isHeadless) {
            let baseRegex = /^((https?|http):\/\/)(www.)?([a-z0-9_\-]+\.)+[a-z]*$/
            if (!baseRegex.test(config.baseUrl)) {
                setBaseError(new Error('please enter a valid URL origin, e.g https://www.your-shop.com'));
                return false;
            } else {
                setBaseError(null);
                return true;
            }
        }
        return true;
    }

    const handleChangePath = (val) => {
        setPathError(null);
        setDirtyBit(val !== config.path)
        setConfig({ ...config, path: val });

    }

    const validatePath = () => {
        if (config.isHeadless) {
            let pathRegex = /^(\/[a-zA-Z0-9#_\-\.]+\/?)*$/
            if (!pathRegex.test(config.path)) {
                setPathError(new Error('please enter a valid URL path, e.g /boutiq-video'));
                return false;
            } else {
                setPathError(null);
                return true;
            }
        }
        return true;
    }

    const save = async () => {

        const ref = Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('headless');
        try {
            setSaving(true);
            setSaveError(null);
            if (!validateBase()) return;
            if (!validatePath()) return;
            await setHeadlessConfig(config);
        } catch (error) {
            console.error('HeadlessConfig failed to save', error);
            setSaveError(error.message);

        } finally {
            setSaving(false);
        }

    }

    const discard = () => {
        setConfig({ ...SHOP_HEADLESS_DEFAULT_CONFIG, ...headlessConfig });
        setBaseError(null);
        setPathError(null);
        setSaveError(null);
    }

    const ConfigText = <p>Boutiq requires additional setup in your headless storefront. Please <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact Boutiq support</a> for further instructions.</p>

    const toastMarkup = showToast ? (
        <Toast content="Key copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Headless storefronts'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <Card.Section>
                            {headlessConfigLoading && <SkeletonBodyText />}
                            {!headlessConfigLoading &&
                                <Stack vertical>
                                    {!headlessConfig?.isHeadless &&
                                        <Banner
                                            status='warning'
                                            title='Using a headless storefront'

                                        >
                                            <p>
                                                <TextStyle variation='strong'>
                                                    Only enable this setting if your store has a headless storefront using Shopify Hydrogen or Shopify Storefront API.
                                                </TextStyle>
                                            </p>
                                            {ConfigText}
                                        </Banner>}
                                    <Checkbox
                                        label='Enable headless mode'
                                        checked={!!config.isHeadless}
                                        onChange={handleChangeHeadless}
                                    />
                                    <TextField
                                        label='Base URL'
                                        placeholder='https://www.your-shop.com'
                                        value={config?.baseUrl}
                                        disabled={!config.isHeadless}
                                        onChange={handleChangeBase}
                                        onBlur={validateBase}
                                        error={baseError?.message}
                                        helpText='The URL origin of your headless storefront, e.g. https://www.your-shop.com'
                                    />
                                    <TextField
                                        label='Path for Boutiq links'
                                        placeholder='/path'
                                        value={config?.path}
                                        disabled={!config.isHeadless}
                                        onChange={handleChangePath}
                                        onBlur={validatePath}
                                        error={pathError?.message}
                                        helpText='The custom URL path used for Boutiq special links such as call links or cart recovery links'
                                    />
                                    {headlessConfig?.isHeadless && (config.baseUrl !== headlessConfig.baseUrl || config.path !== headlessConfig.path) &&
                                        <Banner
                                            status='warning'
                                        >
                                            <p>
                                                Changing the base url and Boutiq link path may affect links already generated and delivered to clients.
                                            </p>
                                        </Banner>
                                    }
                                    {saveError && <InlineError message={saveError}/>}
                                </Stack>}
                        </Card.Section>
                        {headlessConfig?.isHeadless &&
                            <Card.Section>
                                <Stack vertical>
                                    <Banner status='info'>
                                        {ConfigText}
                                    </Banner>
                                    <Stack vertical spacing='tight'>
                                        <p>Boutiq app key</p>
                                        <Stack alignment='center'>
                                            <span className='code-snippet code-background'>{headlessConfig?.appKey}</span>
                                            <CopyToClipboard text={headlessConfig?.appKey} onCopy={toggleToastActive}>
                                                <Button icon={DuplicateMinor} />
                                            </CopyToClipboard>
                                        </Stack>
                                    </Stack>
                                    <p>Call Link exmaple URL</p>
                                    <span className='code-snippet code-background'>{headlessConfig.baseUrl}{headlessConfig.path}?callLink=[id]</span>
                                </Stack>
                            </Card.Section>
                        }

                    </Card>
                </Layout.Section>
            </Layout>
            {dirtyBit && <ContextualSaveBar
                message="Save headless settings?"
                saveAction={{
                    onAction: save,
                    disabled: !!pathError || !!baseError,
                    loading: isSaving,
                }}
                discardAction={{
                    disabled: isSaving,
                    onAction: discard,
                }}
            />}
            {toastMarkup}
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <HeadlessConfig />
        </AdminPage>
    )
}
