
export const StoryWidgetSize = {
    standard: 'standard',
    large: 'large',
}

export const DefaultStoryWidgetSize = StoryWidgetSize.standard;

export const StoryWidgetShape = {
    circle: 'circle',
    rectangle: 'rectangle',
}

export const DefaultStoryWidgetShape = StoryWidgetShape.circle;

export const StoryWidgetPosition = {
    bottom_left: 'bottom_left',
    bottom_right: 'bottom_right',
}

export const StoryWidgetPositionLabel = {
    bottom_left: 'Bottom left',
    bottom_right: 'Bottom right',
}

export const DefaultStoryWidgetPosition = StoryWidgetPosition.bottom_right;

export const STORY_DISPLAY_TYPE = {
    widget: 'widget',
    section: 'section'
}

export const STORY_FIELD = {
    storyId: 'storyId',
    videos: 'videos',
    title: 'title',
    useAsBoutiqClientStory: 'useAsBoutiqClientStory',
    storyDisplayType: 'storyDisplayType',
    widget: {
        size: 'widget.size',
        shape: 'widget.shape',
        position: 'widget.position'
    },
    conditionUrl: 'conditionUrl'
}
