import { DatePicker } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@shopify/polaris';

export const CalendarDatePicker = ({ open, onClose, date, setDateTo }) => {
    const [{ month, year }, setDate] = useState({ month: new Date().getMonth(), year: new Date().getFullYear() });
    const [selectedDates, setSelectedDates] = useState({
        start: date,
        end: date,
    });

    const setNewDate = (range) => {
        setDateTo(range.start);
        setSelectedDates(range);
        onClose();
    }

    const handleMonthChange = useCallback(
        (month, year) => setDate({ month, year }),
        [],
    );

    return (

        <Modal
            open={open}
            onClose={onClose}
            title={'Select'}
        >
            <Modal.Section>
                <DatePicker
                    month={month}
                    year={year}
                    onChange={setNewDate}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                />
            </Modal.Section>
        </Modal>
    );
}
