import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@shopify/polaris';
import { useStoriesProvider } from '../../../../components/StoryiesProvider';
import './styles.css'
import { DefaultStoryWidgetPosition } from '../../../../consts';

const ShoppableWidgetPreview = () => {
  const {
    currentStory
  } = useStoriesProvider()
  const {widget, videos} = currentStory;

  const videoRef = useRef();
  const imageRef = useRef();

  const [videoIndex, setVideoIndex] = useState(0)
  const [isVideoEnded, setIsVideoEnded] = useState(false)

  useEffect(() => {
    const videoData = videos[videoIndex]?.data;
    imageRef.current.src = videoData?.publicPosterUrl ?? videoData?.videoPosterUrl
    imageRef.current.classList.add('visible');
  }, [videoIndex])

  useEffect(() => {
    if (isVideoEnded) {
      if (videoIndex === videos.length - 1) {
        setVideoIndex(0)
      } else {
        setVideoIndex(prev => prev + 1)
      }
      setIsVideoEnded(false)
    }
  }, [isVideoEnded])

  useEffect(() => {
    videoRef.current.addEventListener('ended',  () => {
      setIsVideoEnded(true)
      videoRef.current.classList.add('hidden')
      imageRef.current.classList.remove('hidden');
    }, true);
  }, [])

  const onShowVideos = () => {
    const videoData = videos[videoIndex]?.data;
    videoRef.current.src = videoData?.publicPreviewUrl ?? videoData?.videoUrl;
    const playPromise = videoRef.current.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        videoRef.current.classList.remove('hidden');
        imageRef.current.classList.add('hidden');
      })
    }
  }

  return (
    <Box className={`boutiq-shoppable-widget ${widget.shape} ${widget.size} ${widget.position ?? DefaultStoryWidgetPosition}`}>
      <Box className={'video-container-wrapper'}>
        <img className={'video-poster'} ref={imageRef} onLoad={onShowVideos} />
        <Box className={'video-container'}>
          <video
            muted={true}
            playsInline={true}
            autoPlay={true}
            ref={videoRef}
            className={'video-element'}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ShoppableWidgetPreview;
