import React, {useMemo, useState} from 'react'
import {
    Card,
    Layout,
    ResourceList,
    Filters,
    Button,
    Text,
    ResourceItem,
    Thumbnail,
    EmptyState,
    TextContainer,
    Stack,
    TextStyle
} from '@shopify/polaris';
import './styles.css';
import useShowroomPresets from "../../../hooks/useShowroomPresets";
import {useHistory} from "react-router-dom";
import PresetSkeleton from './PresetSkeleton';
import DeleteModal from "../../../components/DeleteModal";

const ShowroomPresets = () => {
    const history = useHistory()
    const {showroomPresetsList, loading, deletePreset} = useShowroomPresets()
    const [filterValue, setFilterValue, ] = useState('');
    const [deletedPreset, setDeletedPreset] = useState(null)

    const filteredPresets = useMemo(() => (
        showroomPresetsList.filter(({name, products}) => (
            name.toLowerCase().includes(filterValue.toLowerCase().trim())
            || products.filter(({productTitle}) => productTitle.toLowerCase().includes(filterValue.toLowerCase().trim())).length
        ))
    ), [showroomPresetsList, filterValue])

    const createPreset = () => history.push('/showrooms/create')

    const onHideDeleteModal = () => setDeletedPreset(null)

    const onPresDeletePreset = (preset) => {
        setDeletedPreset(preset)
    }

    const onDeletePresetAction = () => {
        deletePreset(deletedPreset.id)
        onHideDeleteModal()
    }

    const contentFilter = (
        <Filters
            queryValue={filterValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={setFilterValue}
            onQueryClear={() => setFilterValue('')}
            onClearAll={() => setFilterValue('')}
        >
            <div style={{paddingLeft: '8px'}}>
                <Button
                    textAlign={'center'}
                    onClick={createPreset}
                    primary
                >
                    {'Create showroom'}
                </Button>
            </div>
        </Filters>
    )

    const renderItem = (preset) => (
        <ResourceItem
            id={preset.id}
            url={`/showrooms/${preset.id}`}
            shortcutActions={[{
                content: 'Delete',
                onAction: () => onPresDeletePreset(preset),
                destructive: true,
                monochrome: true,
                outline: true
            }]}
        >
            <Stack alignment='center' spacing='tight'>
                <Text fontWeight={'medium'} as={"h3"} variant={'headingXl'}>{preset.name}</Text>
                <Text variant='bodySm'>({preset.products.length})</Text>
            </Stack>
            <div className={'showroom-preset-products-wrapper'}>
                {preset.products.map((product, index) => (
                  product.image ? (
                      <Thumbnail
                        transparent={!!product.image}
                        key={index}
                        source={product.image}
                        size={"large"}
                        alt={product.title}
                      />
                    ) : (
                    <div className='empty-product-thumbnail'>
                        <p className='empty-product-title'>
                            {product.title || product.productTitle}
                        </p>
                    </div>
                    )
                ))}
            </div>
        </ResourceItem>
    )

    const emptyState = (
        <EmptyState
            heading={'Create your first Showroom'}
            action={{ content: 'Create Showroom', onAction: createPreset }}
        >
        </EmptyState>
    )

    return (
        <Layout>
            <Layout.Section>
                <div style={{ padding: '0 1rem 1rem' }}>
                    <TextContainer spacing='tight'>
                        <p>
                            <TextStyle variation='subdued'>Create product Showrooms that you can easily load onto client video calls.</TextStyle>
                        </p>
                        <p>
                            <TextStyle variation='subdued'>You can load a saved Showroom to a scheduled call before it starts or to any call while it's live.</TextStyle>
                        </p>
                    </TextContainer>
                </div>
                <Card>
                    {loading
                        ? <PresetSkeleton />
                    : <ResourceList
                      emptyState={emptyState}
                      items={filteredPresets}
                      renderItem={renderItem}
                      idForItem={({ id }) => id}
                      filterControl={showroomPresetsList.length ? contentFilter : null}
                    />}
                </Card>
            </Layout.Section>
            <DeleteModal
              isOpen={!!deletedPreset}
              title={'Confirm Delete Showroom'}
              text={`Are you sure you want to delete Showroom ${deletedPreset?.name}?`}
              onClose={onHideDeleteModal}
              deleteAction={onDeletePresetAction}
            />
        </Layout>

    )
}

export default ShowroomPresets;