import React, { useState, useEffect } from 'react';
import { Stack, Button, TextField, Subheading, SkeletonBodyText, ChoiceList, Checkbox, TextStyle, Card, RadioButton } from '@shopify/polaris';
import { TwitterPicker } from 'react-color';

import { useShopProvider } from '../ShopProvider';

import { COLOR_PICKER_DEFAULTS, COLOR_PICKER_DISABLED } from '../../utils/consts';
import * as DEFAULTS from './consts';

import WidgetPosition from './position';
import WidgetLocationRules, { URL_RULE_OPTIONS, LocationRuleTypes } from './locationRules';
import './styles.css';

export default function WidgetConfig({ config, loading, onConfigChange, disabled, showMoreConfig = false }) {
    const [color, setColor] = useState(config?.color ?? DEFAULTS.DEFAULT_WIDGET_COLOR);
    const [helperText, setHelperText] = useState(config?.helperText ?? DEFAULTS.DEFAULT_HELPER_TEXT);
    const [position, setPosition] = useState(config?.position ?? DEFAULTS.DEFAULT_WIDGET_POSITION);
    const [size, setSize] = useState([config?.size ?? DEFAULTS.DEFAULT_WIDGET_SIZE]);
    const [hostAvatars, setHostAvatars] = useState(config?.hostAvatars ?? DEFAULTS.DEFAULT_WIDGET_HOST_AVATARS);
    const [locationRuleType, setLocationRuleType] = useState(config?.hostAvatars ?? DEFAULTS.DEFAULT_WIDGET_LOCATION_TYPE);
    const [customLocationRules, setCustomLocationRules] = useState(config?.hostAvatars ?? DEFAULTS.DEFAULT_WIDGET_LOCATION_RULES);
    const [isChangingPositionActive, setIsChangingPositionActive] = useState(false);

    const { hosts } = useShopProvider();
    const avatars = hosts.filter(host => !!host.avatarUrl).map(host => host.avatarUrl);
    const currentConfig = { color, helperText, position, size: size[0], hostAvatars, locationRuleType, customLocationRules };

    useEffect(() => {
        if (!loading) {
            setColor(config?.color ?? DEFAULTS.DEFAULT_WIDGET_COLOR);
            setHelperText(config?.helperText ?? DEFAULTS.DEFAULT_HELPER_TEXT);
            setPosition(config?.position ?? DEFAULTS.DEFAULT_WIDGET_POSITION);
            setSize([config?.size ?? DEFAULTS.DEFAULT_WIDGET_SIZE]);
            setHostAvatars(config?.hostAvatars ?? DEFAULTS.DEFAULT_WIDGET_HOST_AVATARS);
            setLocationRuleType(config?.locationRuleType ?? DEFAULTS.DEFAULT_WIDGET_LOCATION_TYPE);
            setCustomLocationRules(config?.customLocationRules ?? DEFAULTS.DEFAULT_WIDGET_LOCATION_RULES);
        }

    }, [loading, config])

    const onChangeColor = (newColor) => {
        setColor(newColor);
        if (newColor !== config?.color) {
            onConfigChange({ ...currentConfig, color: newColor });
        }
    }

    const onChangePosition = (newPosition) => {
        setPosition(newPosition)
        if (newPosition.top !== config?.position?.top ||
            newPosition.right !== config?.position?.right ||
            newPosition.bottom !== config?.position?.bottom ||
            newPosition.left !== config?.position?.left) {
            onConfigChange({ ...currentConfig, position: newPosition });
        }
    }

    const onBlurHelperText = () => {
        if (helperText !== config?.helperText) {
            onConfigChange({ ...currentConfig, helperText });
        }
    }

    const editWidgetPosition = () => {
        document.body.style.overflow = 'hidden';
        setIsChangingPositionActive(true);
    }

    const onChangeSize = (val) => {
        setSize(val);
        let newVal = val[0];
        if (newVal !== config?.size) {
            onConfigChange({ ...currentConfig, size: newVal });
        }
    }

    const onChangeHostAvatars = (val) => {
        let newVal = val ? avatars : null;
        let newSize = val ? size[0] : 'standard';
        setHostAvatars(newVal);
        setSize([newSize]);
        if (newVal !== config?.hostAvatars) {
            onConfigChange({ ...currentConfig, hostAvatars: newVal, size: newSize });
        }
    }

    const onChangeLocationRules = (type, rules) => {
        setLocationRuleType(type);
        if (type !== config?.locationRuleType) {
            onConfigChange({ ...currentConfig, locationRuleType: type });
        }
        if (type === LocationRuleTypes.custom) {
            setCustomLocationRules(rules);
            onConfigChange({ ...currentConfig, locationRuleType: type, customLocationRules: rules });
        }
    }

    function WidgetMoreConfig({ }) {
        return (
            <Card.Section>
                <Stack spacing='extraLoose'>
                    <Stack vertical spacing='extraTight'>
                        <Checkbox
                            label='Use host avatars'
                            helpText={!avatars?.length ? '(disabled) no host avatars have been set' : `will use ${avatars.length} host avatar${avatars.length > 1 ? 's' : ''}`}
                            checked={!!hostAvatars}
                            onChange={onChangeHostAvatars}
                            disabled={!avatars?.length || disabled}
                        />
                    </Stack>
                    <Stack vertical spacing='extraTight'>
                        <ChoiceList
                            title='Size'
                            choices={[{ label: 'standard', value: 'standard' }, { label: 'large', value: 'large' }]}
                            selected={size}
                            onChange={onChangeSize}
                            disabled={disabled || !hostAvatars?.length}
                        />
                    </Stack>
                </Stack>
            </Card.Section>
        )
    }

    function WidgetDisplayRules({ }) {
        return (
            <Card sectioned title={<Subheading>Display rules</Subheading>}>
                <Card.Section>
                    <Stack vertical>
                        <p>Choose on which pages of your online store the Boutiq widget will be displayed </p>
                        <Stack vertical spacing='extraTight'>
                            <RadioButton
                                label={<p><TextStyle variation='strong'>All</TextStyle> pages</p>}
                                helpText={`Show the Boutiq widget on every page of your online store`}
                                checked={locationRuleType === LocationRuleTypes.all}
                                id="all"
                                name="location_options"
                                onChange={() => onChangeLocationRules(LocationRuleTypes.all)}
                                disabled={disabled}
                            />
                            <RadioButton
                                label={<p>Only <TextStyle variation='strong'>product</TextStyle> pages</p>}
                                helpText={`Show the Boutiq widget only on product pages (will have '/products' in page URL)`}
                                id="products"
                                name="location_options"
                                checked={locationRuleType === LocationRuleTypes.products}
                                onChange={() => onChangeLocationRules(LocationRuleTypes.products, [{ id: 'products', rule: URL_RULE_OPTIONS[0].value, url: '/products' }])}
                                disabled={disabled}
                            />
                            <RadioButton
                                label={<p><TextStyle variation='strong'>Custom</TextStyle> pages</p>}
                                helpText={`Set rules for pages where the Boutiq widget should be displayed`}
                                id="custom"
                                name="location_options"
                                checked={locationRuleType === LocationRuleTypes.custom}
                                onChange={() => onChangeLocationRules(LocationRuleTypes.custom, customLocationRules)}
                                disabled={disabled}
                            />
                        </Stack>
                    </Stack>
                    {!disabled && locationRuleType === LocationRuleTypes.custom &&
                        <WidgetLocationRules
                            rules={customLocationRules}
                            onRulesChange={newRules => onChangeLocationRules(LocationRuleTypes.custom, newRules)}
                        />}
                </Card.Section>
            </Card>
        )
    }

    if (loading) {
        return (<SkeletonBodyText />);
    }

    return (
        <>
            <Card sectioned title={
            <Stack alignment='trailing' distribution='equalSpacing'>
                <Subheading>Appearance</Subheading>
                {!disabled && <p className='widget-preview-text'>Widget preview displayed on screen</p>}
            </Stack>}>
                <Card.Section>
                    <Stack vertical spacing='tight'>
                        <Stack spacing='extraLoose'>
                            <Stack vertical spacing='extraTight'>
                                <p>Color</p>
                                <TwitterPicker
                                    triangle='hide'
                                    color={color}
                                    onChangeComplete={disabled ? () => { } : (color) => onChangeColor(color.hex)}
                                    colors={disabled ? COLOR_PICKER_DISABLED : COLOR_PICKER_DEFAULTS}
                                />
                            </Stack>
                            <Stack.Item fill>
                                <TextField
                                    maxLength={33}
                                    label='Helper text (optional)'
                                    placeholder="Text displayed on hover"
                                    value={helperText}
                                    onChange={setHelperText}
                                    onBlur={onBlurHelperText}
                                    clearButton
                                    onClearButtonClick={() => setHelperText(null)}
                                    disabled={disabled}
                                    showCharacterCount
                                />
                            </Stack.Item>
                            <Stack vertical spacing='extraTight'>
                                <p>Position</p>
                                <Button onClick={editWidgetPosition} disabled={disabled}>Change</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card.Section>
                {showMoreConfig && <WidgetMoreConfig />}
            </Card>
            {showMoreConfig && <WidgetDisplayRules />}
            <WidgetPosition
                size={size[0]}
                hostAvatars={hostAvatars}
                color={color}
                text={helperText}
                position={position}
                isChangingPositionActive={isChangingPositionActive}
                setIsChangingPositionActive={setIsChangingPositionActive}
                setPosition={onChangePosition}
            />
        </>
    )
}
