
import React from 'react'
import { Link } from 'react-router-dom'

/* 
By default, App Bridge applies URL changes from outside your app, such as changes from a navigation item being clicked, by updating the iframe URL. 
If your app uses client-side routing, such as React Router, then you need to override this behavior to avoid unnecessary full-page reloads.
https://shopify.dev/tools/app-bridge/react-components/client-router
 */
export default function PolarisLinkComponent({ children, url = '', external, ...rest }) {
    // react-router only supports links to pages it can handle itself. It does not
    // support arbirary links, so anything that is not a path-based link should
    // use a reglar old `a` tag
    if (external /* || IS_EXTERNAL_LINK_REGEX.test(url) */) {
        rest.target = '_blank';
        rest.rel = 'noopener noreferrer';
        return (
            <a href={url} {...rest}>
                {children}
            </a>
        );
    }

    return (
        <Link to={url || '#'} {...rest}>
            {children}
        </Link>
    );
}
