import React from 'react';

import { FooterHelp, Loading, Frame } from '@shopify/polaris';

import { useAppAuthState } from '../authState';

export default function PublicPage({ children }) {
  const { authLoading } = useAppAuthState()

  if (authLoading) {
    return <Frame><Loading /></Frame>;
  }

  return (
    <Frame>
      {children}
      <FooterHelp>
        Need help?{' '}<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact us</a>
      </FooterHelp>
    </Frame>
  )
}