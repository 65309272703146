export const toPresetProduct = (product) => {
  const {
    currencyCode,
    image,
    maxVariantPrice,
    minVariantPrice,
    price,
    productId,
    title,
    descriptionHtml,
    productTitle,
    productDescription,
    compareAtPrice = null
  } = product;
  return ({
    currencyCode,
    image,
    maxVariantPrice,
    minVariantPrice,
    price,
    productId: parseIdString(productId),
    compareAtPrice,
    productTitle: title ?? productTitle,
    productDescription: descriptionHtml ?? productDescription
  })
}

export const parseIdString = (idString) => {
  if (!idString) {
    return ''
  }
  const idStringArray = idString.split('/');
  return idStringArray[idStringArray.length - 1];
};