import { Card, Layout, Text } from '@shopify/polaris';
import VideoStoryCardSkeleton from '../VideoStoryCardSkeleton';
import StoryEditableCard from './components/StoryEditableCard';
import { VideoLibrary } from '../VideoLibrary';
import { VIDEO_STATUS } from '../../../../constants/story';
import React, { useState } from 'react';
import { useShopProvider } from '../../../../components/ShopProvider';
import { useStoriesProvider } from '../StoryiesProvider';
import { VideoUploader } from '../VideoUploader';
import useStoryVideosListener from '../../../../hooks/useStoryVideosListener';

const StoryMediaCard = ({onPreview, onViewStoryPage, onDelete, settingsCard}) => {

  const {shopVideos, shopVideosLoading} = useShopProvider()
  const {
    selectedStory,
    isNewStory,
    currentStory,
    validationError,
    onAddVideo,
    onRemoveVideo,
    onChangeVideosOrder,
    setUploadingStates,
    uploadingStates,
    videoStoryLoading
  } = useStoriesProvider();

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const {videos} = useStoryVideosListener(currentStory.storyId, currentStory.videos)

  const onClearUploadingState = (video) => {
    onAddVideo(video)
    setUploadingStates(prev => prev.filter(({videoId}) => videoId !== video.id))
  }

  const errorMessage = () => (
    <Text
      as={'p'}
      variant={'bodySm'}
      color={'critical'}
    >
      {selectedStory?.errorMessage || validationError.videosError}
    </Text>
  )

  const isSkeletonVisible = () => {
    if (shopVideosLoading || videoStoryLoading) {
      return true
    }
    if (selectedStory === undefined) {
      return false
    }
    return false;
  }

  return (
    <>
      <Layout.Section>
        {isSkeletonVisible() ? (
          <VideoStoryCardSkeleton/>
        ) : (
          <StoryEditableCard
            title={currentStory.title}
            error={(selectedStory?.errorMessage || validationError.videosError) && errorMessage()}
            videos={videos}
            onPreview={onPreview}
            onViewStoryPage={onViewStoryPage}
            story={!isNewStory ? selectedStory : null}
            onDelete={!isNewStory ? onDelete : null}
            onChangeVideosOrder={onChangeVideosOrder}
            onRemoveVideo={onRemoveVideo}
            onOpenUploader={() => setIsUploadDialogOpen(true)}
            uploadingStates={uploadingStates}
          />
        )}
      </Layout.Section>
      {!!settingsCard && (
        <Layout.Section secondary>
          {settingsCard}
        </Layout.Section>
      )}
      <Layout.Section>
        <Card
          sectioned
          title={`Video library (${shopVideos.length})`}
        >
          <VideoLibrary
            onAddStory={videos.filter((video) => video.data.status !== VIDEO_STATUS.DELETED).length <= 4 ? onAddVideo : null}
            selectedItems={videos.filter((video) => video.data.status !== VIDEO_STATUS.DELETED)}
            uploadingStates={uploadingStates}
            onClearUploadingState={onClearUploadingState}
          />
        </Card>
      </Layout.Section>
      <VideoUploader
        setUploadingStates={setUploadingStates}
        uploadingStates={uploadingStates}
        isOpen={isUploadDialogOpen}
        setIsOpen={setIsUploadDialogOpen}
      />
    </>
  )
}

export default StoryMediaCard;
