import {Modal} from "@shopify/polaris";
import React from "react";
import './styles.css'

const VideoPlayModal = ({playingStoryData, isPlayingAreaOpen, onClose}) => {
  
  return (
    <Modal
      small
      onClose={onClose}
      open={isPlayingAreaOpen}
      title='Preview'
    >
      <Modal.Section>
        <div className='video-player-container'>
          <video
            className='video-player'
            src={playingStoryData && playingStoryData.url}
            controls
          />
        </div>
      </Modal.Section>
    </Modal>
  )
}

export default VideoPlayModal;