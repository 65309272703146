import React from 'react';

import { Stack, DescriptionList, TextContainer, Heading, List } from '@shopify/polaris';
import { useAppAuthState } from '../../authState';
import { useShopProvider } from '../../components/ShopProvider';

function Checkmark() {
    return <img src='/onboard/styled_checkmark.png' style={{ width: '20px' }} />
}

export default function ThankYou() {
    const { userData } = useAppAuthState();
    const { shopData } = useShopProvider();
    return (
        <Stack vertical spacing='loose'>
            <img src='/onboard/onboard_congrats_banner.png' style={{ width: '100%' }} />
            <p>Next steps:</p>
            <Stack vertical spacing='tight'>
                <Stack wrap={false}>
                    <Checkmark />
                    <Stack.Item fill>
                        <TextContainer>
                            <p>Test a video call - follow our <a href={`${process.env.REACT_APP_WEB_DOMAIN}/testing-guide`} target='_blank' rel="noopener noreferrer">testing guidelines</a></p>
                        </TextContainer>
                    </Stack.Item>
                </Stack>
                <Stack wrap={false}>
                    <Checkmark />
                    <Stack.Item fill>
                        <TextContainer>
                            <p>Add more hosts from Boutiq app settings</p>
                        </TextContainer>
                    </Stack.Item>
                </Stack>
                <Stack wrap={false}>
                    <Checkmark />
                    <Stack.Item fill>
                        <TextContainer>
                            <p>Let your customers know you're available for them</p>
                        </TextContainer>
                    </Stack.Item>
                </Stack>
                <Stack wrap={false}>
                    <Checkmark />
                    <Stack.Item fill>
                        <TextContainer>
                            <p>Let us know what you think - we would love hearing from you at <a href={`mailto:${process.env.REACT_APP_FEEDBACK_EMAIL}`} target='_blank' rel="noopener noreferrer">{process.env.REACT_APP_FEEDBACK_EMAIL}</a></p>
                        </TextContainer>
                    </Stack.Item>
                </Stack>
                <Stack wrap={false}>
                    <Checkmark />
                    <Stack.Item fill>
                        <TextContainer>
                            <p>Bring your online store to LIFE!</p>
                        </TextContainer>
                    </Stack.Item>
                </Stack>
            </Stack>
            <p>We are here for you: <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">{process.env.REACT_APP_SUPPORT_EMAIL}</a> </p>
            <TextContainer spacing='tight'>
                <p>THANK YOU!</p>
                <p>The Boutiq team</p>
            </TextContainer>
        </Stack>
    )
}
