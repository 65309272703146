import React, { useEffect, useState } from 'react'
import { ProgressBar, Spinner, Banner, Stack } from '@shopify/polaris';
import { STORY_STATUS, STATUS_DESCRIPTION } from './consts/index';
import './styles.css';
import { VIDEO_STATUS } from '../../../../../../constants/story';

export const StatusVisualEffects = ({
    status,
    uploadingProgress = null,
    errorMessage
}) => {
    const [effect, setEffect] = useState(null);

    useEffect(() => {
        if (status) {
            setEffect(effectContainer(status))
        }
    }, [status, uploadingProgress])

    const renderStatus = (status) => {
        switch (status) {
            case STORY_STATUS.processing_pending:
                return <Stack vertical alignment='center' spacing='none'>
                    <Stack spacing='extraTight' >
                        <span className='status-message'>
                            {STATUS_DESCRIPTION.processing}
                        </span>
                        <Spinner size="small" />
                    </Stack>
                    <span className='status-message-subline'>
                        {STATUS_DESCRIPTION.processing_message}
                    </span>
                </Stack>
            case STORY_STATUS.processing:
                return <Stack vertical alignment='center' spacing='none'>
                    <Stack spacing='extraTight'>
                        <span className='status-message'>
                            {STATUS_DESCRIPTION.processing}
                        </span>
                        <Spinner size="small" />
                    </Stack>
                    <span className='status-message-subline'>
                        {STATUS_DESCRIPTION.processing_message}
                    </span>
                </Stack>
            default:
                return null;
        }
    }

    const effectContainer = (status) => {
        if (uploadingProgress && status === STORY_STATUS.pending) {
            return <div className='loader-container'>
                        <div className='loader-item'>
                            <ProgressBar progress={uploadingProgress} size='small' />
                        </div>
                    </div>
        }
        if (errorMessage) {
            return <div className='error-container'>
                <Banner status='critical'>
                    <p>{errorMessage}</p>
                </Banner>
            </div>
        }
        if (status === STORY_STATUS.published || status === VIDEO_STATUS.UNPUBLISHED) {
            return null;
        } else if (status === STORY_STATUS.ready) {
            return null;
        } else if (status === STORY_STATUS.pending && !uploadingProgress) {
            return null;
        } else {
            return <div className='processing-container'>
                {renderStatus(status)}
            </div>
        }
    }

    return (
        <div className='video-status-effects-container'>
            {effect}
        </div>
    )
}
