import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { Layout, Card, Stack, Button, Spinner, TextField, Subheading } from '@shopify/polaris';
import { TwitterPicker } from 'react-color';

import { useShopProvider } from '../../../components/ShopProvider';
import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';

import useCaazamREST from '../../../hooks/useCaazamREST'
import useShopTemplate from '../../../hooks/useShopTemplate';
import useShopNotifications from '../../../hooks/useShopNotifications';
import { useAppAuthState } from '../../../authState';
import useShopServices from '../../../hooks/useShopServices';
import HeaderPreview from './HeaderPreview';

import { Preview as ScheduleConfirmEmailPreview } from './scheduleConfirmEmail'

import { DEFAULT_COLOR, COLOR_PICKER_DEFAULTS } from '../../../utils/consts';
import { isEmailValid } from '../../../utils/strings';
import { formatUploadFilename } from '../../../utils/formatters';

function CustomizeTemplate() {

    const history = useHistory();
    const type = 'scheduleConfirmEmail';
    const { shopOrigin, shopData, shopDataLoading, shopLocales, shopLocalesLoading } = useShopProvider();
    const { userData } = useAppAuthState();
    const currentLocale = shopLocales[0];

    const [isSaving, setIsSaving] = useState(false);
    const [dirtyBit, setDirtyBit] = useState(false);
    const [errorBit, setErrorBit] = useState(false);
    const [customTemplate, setCustomTemplate] = useState({});
    const [newLogo, setNewLogo] = useState(null);

    const [imageSrc, setImageSrc] = useState(null);
    const fileInput = useRef(null);

    const [emailError, setEmailError] = useState(null);

    const { shopNotifications, shopNotificationsLoading, toggleDisable } = useShopNotifications(shopOrigin);
    const { template, isTemplateLoading } = useShopTemplate(shopOrigin, type, currentLocale);
    const { defaultServiceData, defaultServiceLoading } = useShopServices(shopOrigin);

    const { notificationLogoUploadUrl } = useCaazamREST();
    const currentLogo = shopNotifications && shopNotifications.logoUrl;


    useEffect(() => {
        setCustomTemplate(shopNotifications || {});
    }, [shopNotifications])

    useEffect(() => {
        if (currentLogo) {
            const fileRef = Firebase.storage().refFromURL(currentLogo);
            fileRef.getDownloadURL()
                .then(url => setImageSrc(url))
                .catch(error => {
                    console.error(error.code || error);
                })
        }
    }, [currentLogo])

    useEffect(() => {
        if (newLogo) {
            let reader = new FileReader();
            reader.readAsDataURL(newLogo);
            reader.onloadend = (e) => {
                setImageSrc(reader.result);
            }
        } else {
            setImageSrc(null);
        }

    }, [newLogo])


    const save = useCallback(async () => {
        setIsSaving(true);
        try {
            if (newLogo) {
                await uploadLogo();
            }

            await Firebase.firestore()
                .collection('shops').doc(shopOrigin)
                .collection('installData').doc('notifications')
                .set(customTemplate, { merge: true });

        } catch (error) {
            console.error(`Failed to save notification template customizations`, error);
        } finally {
            setIsSaving(false);
            setDirtyBit(false);
        }
    }, [newLogo, customTemplate]);

    const uploadLogo = useCallback(async () => {
        try {
            const fileName = customTemplate.logoUrl.split('/').slice(-1)[0];
            const { uploadUrl } = await notificationLogoUploadUrl({ fileName, contentType: newLogo.type });
            await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': newLogo.type,
                },
                body: newLogo,
            })
        } catch (error) {
            console.error('Failed to upload file', error);
        }

    }, [newLogo, customTemplate.logoUrl]);

    const isLoading = isTemplateLoading || shopDataLoading || shopLocalesLoading || shopNotificationsLoading || defaultServiceLoading;

    const color = (customTemplate && customTemplate.color) || DEFAULT_COLOR;
    const replyTo = (customTemplate && customTemplate.replyTo) || {};

    const setColor = (color) => {
        setDirtyBit(true);
        setCustomTemplate({ ...customTemplate, color });
    }

    const setReplyToName = (name) => {
        const replyTo = Object.assign({ ...customTemplate.replyTo }, { name });
        if (!name) delete replyTo.name;
        setCustomTemplate({ ...customTemplate, replyTo });
        setDirtyBit(true);
    }

    const setReplyToEmail = (email) => {
        const replyTo = Object.assign({ ...customTemplate.replyTo }, { email });
        if (email) replyTo.email = replyTo.email.toLowerCase()
        else delete replyTo.email;
        setCustomTemplate({ ...customTemplate, replyTo });
        setEmailError(null);
        setDirtyBit(true);
    }

    const handleUploadButtonClick = () => {
        fileInput.current.click();
    }

    const handleNewLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            const targetName = formatUploadFilename(file.name);
            const logoUrl = `${process.env.REACT_APP_CONFIG_BUCKET}/${shopOrigin}/notifications/${targetName}`;
            setCustomTemplate({ ...customTemplate, logoUrl, });
            setNewLogo(file);
            setDirtyBit(true);
        }
    }

    const removeLogo = () => {
        setCustomTemplate({ ...customTemplate, logoUrl: null });
        setNewLogo(null);
        setImageSrc(null);
        setDirtyBit(true);
    }

    const validateEmail = () => {
        if (customTemplate && customTemplate.replyTo && customTemplate.replyTo.email && !isEmailValid(customTemplate.replyTo.email)) {
            setEmailError('email is not valid');
            setErrorBit(true);
            return false;
        }
        setEmailError(null);
        setErrorBit(false);
        return true;
    }

    return (
        <AppPage
            title='Customize Template'
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            primaryAction={<Button primary disabled={errorBit || !dirtyBit} loading={isSaving} onClick={save}>Save</Button>}
        >
            <Layout>
                <Layout.Section>
                    <Card title={<Stack><Subheading>Preview example Email</Subheading>{isLoading && <Spinner size='small' />}</Stack>} sectioned>
                        {!isLoading &&
                            <div className='preview-container'>
                                <Stack vertical>
                                    <HeaderPreview shopData={shopData} templateData={template} shopNotifications={customTemplate} locale={currentLocale} />
                                    <div className='preview-accent' style={{ backgroundColor: color }}></div>
                                    {imageSrc && <Stack distribution='center'>
                                        <img src={imageSrc} alt='notification logo' className='preview-logo' />
                                    </Stack>}
                                    <ScheduleConfirmEmailPreview locale={currentLocale} templateData={template} shopData={shopData} customData={template} userData={userData} serviceData={defaultServiceData} />
                                </Stack>
                            </div>
                        }
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card sectioned >
                        {!isLoading && <>
                            <Card.Section title='color'>
                                <Stack vertical spacing='tight'>
                                    <p>Accent color</p>
                                    <TwitterPicker triangle='hide' color={color} onChangeComplete={(color) => setColor(color.hex)} colors={COLOR_PICKER_DEFAULTS} width='100%' />
                                </Stack>
                            </Card.Section>
                            <Card.Section title='logo'>
                                <Stack vertical spacing='loose'>
                                    {imageSrc && <img src={imageSrc} alt='notification logo' style={{ width: '100%', maxHeight: '60px', objectFit: 'contain' }} />}
                                    <Stack spacing='tight' alignment='center'>
                                        <Button onClick={handleUploadButtonClick}>Choose File</Button>
                                        <input ref={fileInput} type="file" accept="image/jpeg,image/png" onChange={handleNewLogo} style={{ display: 'none' }} />
                                        {(currentLogo || newLogo) && <Button plain onClick={removeLogo}>Remove</Button>}
                                    </Stack>
                                </Stack>
                            </Card.Section>
                            <Card.Section title='Reply To'>
                                <Stack vertical>
                                    <TextField label="name" value={replyTo.name} onChange={setReplyToName} placeholder={shopData.name} />
                                    <TextField
                                        label="email"
                                        type='email'
                                        value={replyTo.email}
                                        onChange={setReplyToEmail}
                                        placeholder={shopData.contactEmail}
                                        onBlur={validateEmail}
                                        error={emailError && emailError}
                                    />
                                </Stack>
                            </Card.Section>
                        </>
                        }
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <CustomizeTemplate />
        </AdminPage>
    )
}
