import React from 'react';
import './styles.css';

export default function ClientPopUpMockup({primaryColor = '#08b273', title, description = '', cta = '', image = null}) {

  return (
    <div className='widget-popup-container'>
      <div style={{backgroundColor: primaryColor}} className='widget-popup-header'>
        <p className='widget-popup-header-text'>{title}</p>
      </div>
      <div className='form-container'>
        <div className='form'>
          {
            image &&
              <img className='widget-popup-image' src={image}/>
          }
          <div className='campains-modal-description' dangerouslySetInnerHTML={{ __html: description }}/>
          <div className="actions-container">
            <button style={{background: primaryColor}}
                    className='caazam-button'
                    type="button">{cta}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
