import { FEEDBACK_TYPE } from "../../utils/consts";
import { utcStartDay, utcEndDay } from "../../utils/formatters";

export const feedbackQuery = ({ feedbackType = 'csat', dateFrom, dateTo, tz = 'UTC', hosts = [], granularity = null, hostsComparison = false, eventTypesComparison = false, eventTypes = [] }) => {

  let measures = [];
  switch (feedbackType) {
    case FEEDBACK_TYPE.NPS:
      measures = [
        "Feedback.npsPromoters",
        "Feedback.npsPassives",
        "Feedback.npsDetractors",
        "Feedback.npsCount",
      ];
      break;
    case FEEDBACK_TYPE.CSAT:
      measures = [
        "Feedback.csatPromoters",
        "Feedback.csatCount",
      ];
      break;
    case FEEDBACK_TYPE.SIMPLE:
      measures = [
        "Feedback.avgRating",
      ];
      break;
    default:
      break;
  }
  
  const query = {
    timezone: tz,
    order: {
      'Feedback.timestamp': 'desc',
    },
    timeDimensions: [
      {
        dimension: 'Feedback.timestamp',
        granularity,
        dateRange: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ],
      },
    ],
    measures,
    filters: [
      {
        member: "Feedback.ratingType",
        operator: "equals",
        values: [feedbackType]
      }
    ]
  };

  if (hosts && hosts.length > 0) {
    query.filters = [
      ...query.filters,
      {
        member: "Feedback.hostId",
        operator: "equals",
        values: hosts
      },
    ]
  }

  if (hostsComparison) {
    query.filters = [
      ...query.filters,
      {
        member: "Feedback.timestamp",
        operator: "inDateRange",
        values: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ]
      }];

    query.dimensions = [
      "Feedback.hostId",
    ]

    delete query.timeDimensions;
  }

  if (eventTypesComparison) {
    query.dimensions = [
      ...(query?.dimensions || []),
      "Feedback.callId",
    ]
  }

  if (eventTypes?.length > 0) {
    query.dimensions = [
      "Feedback.scheduleEventTypeId",
      ...(query?.dimensions || []),
    ]

    query.filters = [
      ...query.filters,
      {
        member: "Feedback.scheduleEventTypeId",
        operator: "equals",
        values: eventTypes
      }
    ];
  }

  return query;
};
