import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import 'firebase/compat/firestore';
import {
  Stack,
  TextStyle,
  Layout,
  Card,
  Button,
  DataTable,
  TextContainer,
  SkeletonBodyText,
  EmptyState,
  Modal,
  Badge,
  Banner
} from '@shopify/polaris';

import {useShopProvider} from '../../components/ShopProvider';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useCampaignsActions } from '../../hooks/useCampaigns'
import VimeoVideoModal from '../../components/VimeoVideoModal';

import './index.css';

function CampaignsPage() {

  const history = useHistory();
  const {shopOrigin, campaigns, campaignsLoading} = useShopProvider();
  const { deleteCampaign } = useCampaignsActions(shopOrigin);
  const [tableData, setTableData] = useState([]);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [deleteWorking, setDeleteWorking] = useState(false);
  const [videoModal, showVideoModal] = useState(false);

  useEffect(() => {
    if (!campaignsLoading && campaigns && campaigns.length > 0) {
      const data = campaigns.map(buildCampaignsRows);
      setTableData(data);
    } else if (tableData.length > 0 && campaigns && campaigns.length === 0) {
      setTableData([]);
    }
  }, [campaigns, campaignsLoading])

  const gotoCreateCampaign = () => history.push('/campaigns/create');
  const gotoEditCampaign = (id) => history.push(`/campaigns/edit/${id}`);

  const deleteCampaignItem = async (id) => {
    setDeleteWorking(true);
    try {
      await deleteCampaign(id);
    } catch (err) {
      console.error(`Failed deleting campaign ${id}`, err);
    } finally {
      setDeleteWorking(false);
      setCampaignToDelete(null);
    }
  }

  const closeModal = () => setCampaignToDelete(null);

  const buildCampaignsRows = (data) => {
    return [
      <Stack vertical spacing='extraTight'>
        <Stack alignment='center'>
          <span>{data.name}</span>
        </Stack>
        {
          data.timestamp &&
          <p className='link-created-at'>
            <TextStyle variation='subdued'>
              created {new Intl.DateTimeFormat(navigator.language, {
              dateStyle: 'short',
              timeStyle: 'short'
            }).format(data.timestamp.toDate())}
            </TextStyle>
          </p>
        }
      </Stack>,
      <Stack alignment='center'>
        <span>{`${data.locale}`}</span>
      </Stack>,
      <Stack alignment='center'>
        {data.enabled 
        ? <Badge status="success" progress=''>enabled</Badge>
        : <Badge status="critical" progress=''>disbaled</Badge>
        }
      </Stack>,
      <Stack alignment='center'>
        <Button plain onClick={() => gotoEditCampaign(data.id)}>Edit</Button>
        <Button plain destructive onClick={() => setCampaignToDelete(data.id)}>Delete</Button>
      </Stack>
    ]
  }

  return (
    <AppPage
      title='Campaigns'
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Stack vertical>
            <TextContainer spacing='tight'>
              <p> Use campaigns to increase engagement and promote virtual shopping experiences to your store's visitors.</p>
              <p> Create unique campaigns - each with a customized appearance, trigger conditions and action.</p>
            </TextContainer>
            <Banner>
              <p><Button plain monochrome onClick={() => showVideoModal(true)}>View a short video tutorial</Button> on how to setup campaigns</p>
            </Banner>
            </Stack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card sectioned actions={tableData.length > 0 ? [{content: 'Create Campaign', onAction: gotoCreateCampaign}] : []}>
            {
              tableData.length > 0
                  ? <DataTable
                      columnContentTypes={['text', 'text', 'text', 'text']}
                      headings={['Name', 'Locale', 'Status', 'Actions']}
                      columnWidths={[50, 5, 20, 25]}
                      rows={tableData}/>
                  : <EmptyState
                      heading="Create Your first campaign"
                      action={{content: 'Create campaign', onAction: gotoCreateCampaign}}
                      //image={`https://storage.googleapis.com/config-${process.env.REACT_APP_FIREBASE_PROJECT_ID}/campaigns/default_hero_image.png`} TODO: need to replace with pop up screen shot when ready
                    />
            }
            {campaignsLoading && <SkeletonBodyText lines={4}/>}
          </Card>
        </Layout.Section>
      </Layout>
      <Modal
        open={campaignToDelete}
        onClose={closeModal}
        title='Confirm Delete Campaign'
        primaryAction={{
          content: 'delete',
          onAction: () => deleteCampaignItem(campaignToDelete),
          destructive: true,
          loading: deleteWorking,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: closeModal,
            disabled: deleteWorking,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this campaign? </p>
            <p><TextStyle variation='strong'>Make sure you don't need this campaign</TextStyle></p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <VimeoVideoModal
        open={videoModal}
        onClose={() => showVideoModal(false)}
        videoUrl='https://player.vimeo.com/video/740804539#t=08m25s?h=887c30bd41&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        videoTitle='Integration Options'
      />
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <CampaignsPage/>
    </AdminPage>
  )
}
