import {utcStartDay, utcEndDay} from "../../utils/formatters";

export const callsQuery = ({dateFrom, dateTo, tz = 'UTC', hosts = [], hostsComparison = false, eventTypesComparison = false, eventTypes = []}) => {
  const query = {
    timezone: tz,
    order: {
      'Calls.timestamp': 'desc',
    },
    timeDimensions: [
      {
        dimension: 'Calls.timestamp',
        granularity: "day",
        dateRange: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ]
      },
    ],
    measures: [
      "Calls.count",
      "Calls.duration",
      "Calls.avgDuration",
      "Calls.callsCompleted",
      "Calls.callsFailed",
      "Calls.durationMedian",
      "Calls.durationPercent90",
      "Orders.count",
      "Orders.ordersTotal",
      "Orders.avgOrder",
      "Calls.orderPerCall",
      "Calls.orderPerDuration",
      "Calls.conversionRate",
      "Orders.ordersCount",
      "Calls.ordersConversionRate",
    ],
  };

  if (hosts && hosts.length > 0) {
    query.filters = [
      {
        member: "Calls.hostId",
        operator: "equals",
        values: hosts
      },
    ]
  }

  if (hostsComparison) {
    query.filters = [{
      member: "Calls.timestamp",
      operator: "inDateRange",
      values: [
        utcStartDay(dateFrom),
        utcEndDay(dateTo),
      ]
    }];

    query.dimensions = [
      "Calls.hostId",
    ]

    query.measures = [
      ...(query?.measures || []),
    ]

    delete query.timeDimensions;
  }

  if (eventTypesComparison) {
    query.dimensions = [
      "Calls.scheduleEventTypeId",
      "Calls.callId",
      "Calls.status",
    ]
  }

  if (eventTypes?.length > 0) {
    query.dimensions = [
      "Calls.scheduleEventTypeId",
      ...(query?.dimensions || []),
    ]

    query.filters = [
      ...(query?.filters || []),
      {
        member: "Calls.scheduleEventTypeId",
        operator: "equals",
        values: eventTypes
      }
    ];
  }

  return query;
};
