import React, { useEffect, useRef, useState }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { Layout, Card, Stack, Banner, Button, Spinner, ButtonGroup, Subheading} from '@shopify/polaris';

import { useShopProvider } from '../../../components/ShopProvider';
import SelectLocale from '../../../components/SelectLocale';
import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';

import useShopTemplate from '../../../hooks/useShopTemplate';
import useShopServices from '../../../hooks/useShopServices';
import useShopNotifications from '../../../hooks/useShopNotifications';

import HeaderPreview from './HeaderPreview';
import {
    Preview as ScheduleConfirmEmailPreview,
    Customize as ScheduleConfirmEmailCustomize,
} from './scheduleConfirmEmail'

import {
    Preview as ScheduleCancelEmailPreview,
    Customize as ScheduleCancelEmailCustomize,
} from './scheduleCancelEmail'

import {
    Preview as ScheduleRequestEmailPreview,
    Customize as ScheduleRequestEmailCustomize,
} from './scheduleRequestEmail'

import { DEFAULT_COLOR } from '../../../utils/consts';
import { useAppAuthState } from '../../../authState';

export const NotificationTypes = {
    scheduleConfirmEmail: {
        name: 'Confirm appointment',
        description: 'Sent to the client after they have successfuly scheduled an appointment.',
        preview: ScheduleConfirmEmailPreview,
        customize: ScheduleConfirmEmailCustomize,
    },
    scheduleInviteEmail: {
        name: 'Invited to appointment',
        description: 'Sent to the client when a host has invited them to a scheduled appointment.',
        preview: ScheduleConfirmEmailPreview,
        customize: ScheduleConfirmEmailCustomize,
    },
    scheduleStartChangedEmail: {
        name: 'Appointment rescheduled',
        description: 'Sent to the client when a host changes the start time of a scheduled appointment.',
        preview: ScheduleConfirmEmailPreview,
        customize: ScheduleConfirmEmailCustomize,
    },
    scheduleAlertEmail: {
        name: 'Appointment reminder',
        description: 'Sent to the client 10 minutes before start time of a scheduled appointment.',
        preview: ScheduleConfirmEmailPreview,
        customize: ScheduleConfirmEmailCustomize,
    },
    scheduleRequestChange: {
        name: 'Request reschedule',
        description: 'Request the client to reschedule an appointment to a new time.',
        preview: ScheduleRequestEmailPreview,
        customize: ScheduleRequestEmailCustomize,
    },
    scheduleCancelledEmail: {
        name: 'Appointment cancelled',
        description: 'Sent to the client when a host cancels a scheduled appointment.',
        preview: ScheduleCancelEmailPreview,
        customize: ScheduleCancelEmailCustomize,
    }
}

function Notification() {

    const history = useHistory();
    const { type } = useParams();
    const { userData } = useAppAuthState();
    const { shopOrigin, shopData, shopDataLoading, shopLocales, shopLocalesLoading } = useShopProvider();
    const { defaultServiceData, defaultServiceLoading } = useShopServices(shopOrigin);

    const [isSaving, setIsSaving] = useState(false);
    const [dirtyBit, setDirtyBit] = useState(false);
    const [errorBit, setErrorBit] = useState(false);
    const [ customData, setCustomData] = useState({});
    const [currentLocale, setCurrentLocale] = useState(shopLocales[0]);
    const { template, isTemplateLoading } = useShopTemplate(shopOrigin, type, currentLocale);
    const { shopNotifications, shopNotificationsLoading, toggleDisable } = useShopNotifications(shopOrigin);
    const [logoImageSrc, setLogoImageSrc] = useState(null);
    const logoImageRef = useRef(null);

    const save = async () => {
        setIsSaving(true);
        try {
            await Firebase.firestore()
                .collection('shops').doc(shopOrigin)
                .collection('templates').doc(type)
                .collection('locales').doc(currentLocale)
                .set(customData, { merge: true });
        } catch (error) {
            console.error(`Failed to save custom template ${type} ${currentLocale}`, error);
        } finally {
            setIsSaving(false);
            setDirtyBit(false);
        }
    }

    const renderPreview = (type, props) => {
        const Preview = NotificationTypes[type].preview;

        return <Preview {...props} />
    }

    const renderCustomize = (type, props) => {
        const Customize = NotificationTypes[type].customize;

        return <Customize {...props} />
    }

    useEffect(() => {
        setCurrentLocale(shopLocales[0]);
    }, [shopLocales])

    useEffect(() => {
        setDirtyBit(false);
        setErrorBit(false);
        setCustomData(null);
    }, [currentLocale])

    useEffect(()=>{
        if (template) {
            const { customText, salutation } = template;            
            const customTemaplteData = {}
            if (customText) {
                customTemaplteData.customText = customText                
            }
            if (salutation) {
                customTemaplteData.salutation = salutation                
            }
            setCustomData(customTemaplteData);
        } else {
            setCustomData({});
        }                
    },[template])

    useEffect(() => {
        if (shopNotifications && shopNotifications.logoUrl) {
            const fileRef = Firebase.storage().refFromURL(shopNotifications.logoUrl);
            fileRef.getDownloadURL()
                .then(url => setLogoImageSrc(url))
                .catch(error => {
                    console.error(error.code || error);
                })
        }
    }, [shopNotifications])

    useEffect(() => {
        if (logoImageRef.current) {
            logoImageRef.current.src = logoImageSrc;
        }
    });

    const isLoading = isTemplateLoading || shopDataLoading || shopLocalesLoading || shopNotificationsLoading || defaultServiceLoading;
    const isDisabled = shopNotifications && shopNotifications.disabled && shopNotifications.disabled[type];

    const handleCustomChanges = (obj) => {
        setDirtyBit(true);
        setCustomData(obj);
    }

    const OnError = (val) => setErrorBit(val);

    const primaryAction = <ButtonGroup>
        {shopLocales.length > 1 && <SelectLocale currentLocale={currentLocale} options={shopLocales} onChange={setCurrentLocale} />}
        <Button primary disabled={errorBit || !dirtyBit} loading={isSaving} onClick={save}>Save</Button>
    </ButtonGroup>

    const color = (shopNotifications && shopNotifications.color) || DEFAULT_COLOR;

    return (
        <AppPage
        title={NotificationTypes[type].name}
        breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
        primaryAction={primaryAction}
            secondaryActions={[{ content: isDisabled ? 'Enable Notification' : 'Disable Notification', onAction: () => toggleDisable(type) }]}
        >
            <Layout>
                { isDisabled && <Layout.Section>
                    <Banner
                        title="Notification is disabled"
                        action={{ content: 'Enable', onAction: () => toggleDisable(type) }}
                        status='warning'/>
                </Layout.Section>}
                <Layout.Section>
                    <Card title={<Stack><Subheading>Preview</Subheading>{isLoading && <Spinner size='small' />}</Stack>} sectioned>

                        {!isLoading &&
                            <div className='preview-container'>
                                <Stack vertical>
                                    <HeaderPreview shopData={shopData} templateData={template} shopNotifications={shopNotifications || {}} locale={currentLocale} />
                                    <div className='preview-accent' style={{ backgroundColor: color }}></div>
                                    {logoImageSrc && <Stack distribution='center'>
                                        <img ref={logoImageRef} alt='notification logo' className='preview-logo' />
                                    </Stack>}
                                    {renderPreview(type, { locale: currentLocale, templateData: template, shopData, customData, serviceData: defaultServiceData, userData })}
                                </Stack>
                            </div>
                        }
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card sectioned >
                        {!isLoading && renderCustomize(type, { onChange: handleCustomChanges, customData, OnError })}
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <Notification/>
        </AdminPage>
    )
}
