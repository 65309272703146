import React, { useState, useEffect } from 'react';

import { Stack, RadioButton, TextStyle, Link } from '@shopify/polaris';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { useShopProvider } from './ShopProvider';
import  { useAppAuthState } from '../authState'

export const CallRoutePolicy = {
    roundRobin: 'roundRobin',
    callAll: 'callAll',
    custom: 'custom',
}

export const callPolicyValue = (callPolicyConfig) => {
    const isDefault = !callPolicyConfig || !callPolicyConfig.callRoutingPolicy;
    return isDefault ? CallRoutePolicy.callAll : callPolicyConfig.callRoutingPolicy.type;
}

export function CallRoutingPolicySummary({callPolicyConfig}) {
    switch (callPolicyConfig?.callRoutingPolicy?.type) {
        case CallRoutePolicy.roundRobin:
            return <TextStyle variation='strong'>Call routing to single host (round-robin)</TextStyle>;
            break;
        case CallRoutePolicy.custom:
            return <TextStyle variation='strong'>Call routing to all hosts with custom availability at time of call</TextStyle>;
            break;
        default:
            return <TextStyle variation='strong'>Call routing to all hosts</TextStyle>;
    }
}

export default function CallRoutingPolicyConfig({ disabled = false}) {
    const { shopOrigin } = useAppAuthState();
    const { callPolicyConfig, callPolicyConfigLoading, shopData } = useShopProvider();
    const [ callPolicy, setCallPolicy ] = useState(null)

    useEffect(()=>{
        if (!callPolicyConfigLoading) {
            setCallPolicy(callPolicyValue(callPolicyConfig));
        }
    },[ callPolicyConfig, callPolicyConfigLoading ])

    const onCallPolicyChange = (newValue) => {
        setCallPolicy(newValue);
        const update = {
            name: shopData.name,
            timezone: shopData.ianaTimezone,
            url: shopData.url,
            currencyCode: shopData.currencyCode,
        }

        const ref = Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('callsPolicy');
        const updateFunc = !callPolicyConfig ? () => ref.set({...update,callRoutingPolicy: {type: newValue}}) : () => ref.update({...update, 'callRoutingPolicy.type': newValue});

        updateFunc().catch(error => console.error('onCallPolicyChange', error));
    }

    return (
        <Stack vertical spacing='tight'>
            <p>
                Calls routing policy determines who receives incoming instant video calls.<br/>
                <TextStyle variation='subdued'>(Regardless of the calls routing policy, calls by-appointment and/or by-invitation will always be routed only to the Host who created the invite or accepted the pending appointment request)</TextStyle></p>
            <RadioButton
                label="All hosts"
                helpText={<>When an instant video call comes in, <strong>all</strong> available hosts will be notified. The first host to respond will pick up the call.</>}
                checked={callPolicy === CallRoutePolicy.callAll}
                id={CallRoutePolicy.callAll}
                name="callRoutePolicy"
                onChange={() => onCallPolicyChange(CallRoutePolicy.callAll)}
                disabled={disabled}
            />
            <RadioButton
                label="Single host"
                helpText={<>When an instant video call comes in, <strong>one</strong> available host will be notified. Incoming calls will be rotated among available hosts one-at-a-time (round-robin).</>}
                checked={callPolicy === CallRoutePolicy.roundRobin}
                id={CallRoutePolicy.roundRobin}
                name="callRoutePolicy"
                onChange={() => onCallPolicyChange(CallRoutePolicy.roundRobin)}
                disabled={disabled}
            />
            <RadioButton
                label="Custom availability"
                helpText={<>When an instant video call comes in, all hosts with configured <strong>custom availability</strong> for instant calls at the time of the call will be notified.</>}
                checked={callPolicy === CallRoutePolicy.custom}
                id={CallRoutePolicy.custom}
                name="callRoutePolicy"
                onChange={() => onCallPolicyChange(CallRoutePolicy.custom)}
                disabled={disabled}
            />
            {callPolicy === CallRoutePolicy.custom &&
                <p style={{ marginLeft: '2em' }}> Set instant call availability for hosts in <Link url='/calendar/hosts'>Host Settings</Link>.</p>
            }
        </Stack>)
}
