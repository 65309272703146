const asc = arr => arr.sort((a, b) => a - b);

export const quantile = (arr, q) => {
  const a = arr.slice();
  const data = asc(a);
  const p = ((data.length) - 1) * q / 100;
  const b = Math.floor(p);

  const remainder = p - b;

  if (data[b+1]!==undefined){
    return parseFloat(data[b]) + remainder * (parseFloat(data[b+1]) - parseFloat(data[b]));
  }else{
    return parseFloat(data[b]);
  }
};
