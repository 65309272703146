import React from 'react';
import { CustomLabel } from '../partial/customLabel/CustomLabel';
import './index.scss';

export const FormLabelField = ({ 
    value, 
    options, 
    index, 
    fieldId,
    inEditMode, 
    setTempValue, 
    isOption, 
    onError,
    formLabel = 'Add label' }) => {

    return (
        <div className='custom-form-label-container'>
            <div className='custom-form-input-wrapper'>
                <CustomLabel 
                    formLabel={formLabel} 
                    isOption={isOption} 
                    defaultValue={value} 
                    fieldId={fieldId}
                    onError={onError}
                    inEditMode={inEditMode} 
                    options={options}
                    index={index}
                    onChange={val => { setTempValue(val)}} />
            </div>
        </div>
    )
}
