import React, { useEffect, useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Layout, Card, Stack, TextField, TextStyle, TextContainer, Spinner, SkeletonBodyText } from '@shopify/polaris';


import { useShopProvider } from '../../../components/ShopProvider';
import { DEFAULT_SCHEDULING_SUCCESS_MESSAGE } from '../../../utils/consts'
import { Locales } from '../../../utils/locales'


export default function SchedulingSuccess({ schedulingConfig, schedulingConfigLoading }) {

    const [confirmMessage, setConfirmMessage] = useState({});
    const [customLink, setCusotmLink] = useState({});
    const { shopOrigin, shopData, shopLocales, shopLocalesLoading } = useShopProvider();
    const isLoading = shopLocalesLoading || schedulingConfigLoading;
    const defaultLocale = shopLocales[0];

    useEffect(() => {
        if (!schedulingConfigLoading && schedulingConfig) {
            setConfirmMessage(schedulingConfig.customConfirmMessage || {});
            setCusotmLink(schedulingConfig.customConfirmLink || {});
        }
    }, [schedulingConfig, schedulingConfigLoading]);

    const onConfirmMessageChange = (val, locale) => {
        const newConfirmMessage = { ...confirmMessage, [locale]: val || null };
        setConfirmMessage(newConfirmMessage);
    }

    const onCustomLinkChange = (val, locale) => {
        const newCustomLink = { ...customLink, [locale]: val || null };
        setCusotmLink(newCustomLink);
    }

    const onConfirmMessageBlur = () => {
        Firebase.firestore().collection('shops').doc(shopOrigin)
            .collection('installData').doc('scheduling').set({ customConfirmMessage: confirmMessage }, { merge: true })
            .catch(error => console.error('onConfirmMessageBlur', error));
    }

    const onCustomLinkBlur = () => {
        Firebase.firestore().collection('shops').doc(shopOrigin)
            .collection('installData').doc('scheduling').set({ customConfirmLink: customLink }, { merge: true })
            .catch(error => console.error('onCustomLinkBlur', error));
    }

    const rednerConfirmMessageForm = () => {
        return shopLocales.length > 1
            ? shopLocales.map(locale => (
                <TextField
                    key={locale}
                    label={Locales[locale].label}
                    value={confirmMessage[locale]}
                    onChange={(val) => onConfirmMessageChange(val, locale)}
                    onBlur={onConfirmMessageBlur}
                    type='text'
                    placeholder={DEFAULT_SCHEDULING_SUCCESS_MESSAGE[locale]}
                    multiline={2}
                />
            ))
            : <TextField
                value={confirmMessage[defaultLocale]}
                onChange={(val) => onConfirmMessageChange(val, defaultLocale)}
                onBlur={onConfirmMessageBlur}
                type='text'
                placeholder={DEFAULT_SCHEDULING_SUCCESS_MESSAGE[defaultLocale]}
                multiline={2}
            />

    }

    const renderCustomLinkForm = () => {
        return shopLocales.length > 1
            ? shopLocales.map(locale => (
                <TextField
                    key={locale}
                    label={Locales[locale].label}
                    value={customLink[locale]}
                    onChange={(val) => onCustomLinkChange(val, locale)}
                    onBlur={onCustomLinkBlur}
                    type='text'
                    placeholder='https://...'
                />
            ))
            : <TextField
                value={customLink[defaultLocale]}
                onChange={(val) => onCustomLinkChange(val, defaultLocale)}
                onBlur={onCustomLinkBlur}
                type='text'
                placeholder='https://...'
            />
    }

    return (

        <Layout.AnnotatedSection
            title="Scheduling confirmation"
            description="Customize the scheduling confirmation screen in the client app"
        >
            <Card title={<Stack>{isLoading && <Spinner size='small' />}</Stack>}>
                <Card.Section title='custom confirm message'>
                    <Stack vertical>
                        <TextContainer spacing='tight'>
                            <p>Set a custom message for clients when successfully scheduling a new appointment</p>
                            <p><TextStyle variation='subdued'>Default message will display if this field is not set</TextStyle></p>
                        </TextContainer>
                        {isLoading && <SkeletonBodyText />}
                        {!isLoading && rednerConfirmMessageForm()}
                    </Stack>
                </Card.Section>
                <Card.Section title='custom link'>
                    <Stack vertical>
                        <TextContainer spacing='tight'>
                            <p>Set an optional link for clients when successfully scheduling a new appointment</p>
                            <p><TextStyle variation='subdued'>Example: add a link to a form if more details are required from the client to prepare for the appointment</TextStyle></p>
                        </TextContainer>
                        {isLoading && <SkeletonBodyText />}
                        {!isLoading && renderCustomLinkForm()}

                    </Stack>
                </Card.Section>
            </Card>
        </Layout.AnnotatedSection>

    );
}
