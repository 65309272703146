import { utcEndDay, utcStartDay } from '../../utils/formatters';

export const storiesEventsAnalytics = (timezone, startDate, endDate) => ({
  timezone,
  order: {
    "StoryEvents.eventTimestamp": "asc"
  },
  timeDimensions: [
    {
      dimension: "StoryEvents.eventTimestamp",
      granularity: "day",
      dateRange: [
        utcStartDay(startDate),
        utcEndDay(endDate),
      ]
    },
  ],
  measures: [
    "StoryEvents.views",
    "StoryEvents.clicks",
  ],
})

export const storiesPlaytimeAnalytics = (timezone, startDate, endDate) => ({
  timezone,
  order: {
    "StoryPlaytime.eventTimestamp": "asc"
  },
  timeDimensions: [
    {
      dimension: "StoryPlaytime.eventTimestamp",
      granularity: "day",
      dateRange: [
        utcStartDay(startDate),
        utcEndDay(endDate),
      ]
    },
  ],
  measures: [
    "StoryPlaytime.playtime",
  ],
})

export const topTenStories = (timezone, startDate, endDate) => ({
  timezone,
  order: {
    "StoryEvents.views": "desc"
  },
  filters: [
    {
      dimension: "StoryEvents.eventTimestamp",
      operator: "inDateRange",
      values: [
        utcStartDay(startDate),
        utcEndDay(endDate),
      ]
    },
  ],
  measures: [
    'StoryEvents.views',
    'StoryEvents.clicks',
  ],
  dimensions: [
    "StoryEvents.storyId",
    "StoryEvents.videoId",
    "StoryEvents.storyName",
  ],
  limit: 10,
})

export const storiesEventDetailAnalytics = (timezone, startDate, endDate) => ({
  timezone,
  order: {
    "StoryEvents.eventTimestamp": "desc"
  },
  filters: [
    {
      dimension: "StoryEvents.eventTimestamp",
      operator: "inDateRange",
      values: [
        utcStartDay(startDate),
        utcEndDay(endDate),
      ]
    },
  ],
  dimensions: [
    "StoryEvents.shopId",
    "StoryEvents.storyId",
    "StoryEvents.videoId",
    "StoryEvents.eventName",
    "StoryEvents.eventAction",
    "StoryEvents.eventTimestamp",
    "StoryEvents.eventValue",
    "StoryEvents.eventCurrencyCode",
    "StoryEvents.storyName",
    "StoryEvents.productId",
    "StoryEvents.productName",
    "StoryEvents.publishedPage",
    "StoryEvents.sessionId"
  ],
})

export const storiesPlaytimeExportAnalytics = (timezone, startDate, endDate) => ({
  timezone,
  order: {
    "StoryPlaytime.eventTimestamp": "asc"
  },
  timeDimensions: [
    {
      dimension: "StoryPlaytime.eventTimestamp",
      granularity: "day",
      dateRange: [
        utcStartDay(startDate),
        utcEndDay(endDate),
      ]
    },
  ],
  dimensions: [
    "StoryPlaytime.shopId",
    "StoryPlaytime.storyId",
    "StoryPlaytime.videoId",
    "StoryPlaytime.eventName",
    "StoryPlaytime.eventTimestamp",
    "StoryPlaytime.storyName",
    "StoryPlaytime.productId",
    "StoryPlaytime.productName",
    "StoryPlaytime.publishedPage",
    "StoryPlaytime.sessionId"
  ],
  measures: [
    "StoryPlaytime.playtime",
  ],
})

