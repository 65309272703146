import React, { createContext, useContext, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import * as FacebookPixel from '../utils/facebookPixel';

import { getUserToken } from '../utilsFirebase';
import useInstallData from '../hooks/useInstallData';
import CaazamError from "../utils/errors";
import {handleResponse} from "../hooks/useCaazamREST";
import { hasAdminAppPermissions, getAdminAppPermission, isHostRole } from '../utils/permissions';

//import { useAuthState } from 'react-firebase-hooks/auth';
export const AuthStateContext = createContext(null);

export default function AppAuthStateProvider({ shopOrigin, children }) {

  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [shopsWithPermissions, setShopsWithPermissions] = useState([]);
  const { installHmac, installKey } = useInstallData(shopOrigin);


  const addAdmin = async (tokenUser, shopId, installKey, installHMAC, adminName) => {
    return getUserToken(tokenUser).then(token => {

      const body = {
        installKey,
        installHMAC,
      }
      if (adminName) {
        body.name = adminName;
      }

      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/onboard/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(body),
      }).then(respsone => {
        firebase.analytics().logEvent('admin.onboard.admin_created');
        FacebookPixel.track('CompleteRegistration',{ content_name: 'admin.onboard.admin_created', status: true, value:0, currency: 'USD' });
        if (!respsone.ok) {
          firebase.analytics().logEvent('admin.onboard.admin_failed');
          FacebookPixel.track('CompleteRegistration',{ content_name: 'admin.onboard.admin_created', status: false, value:0, currency: 'USD' });
          throw new Error(respsone.statusText);
        }
      });
    });
  }

  const getUserData = async (userToGet) => {
    try {
      const ref = firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(userToGet.uid);
      const userData = (await ref.get()).data();
      setUserData(userData);
    } catch (error) {
      console.error('getUserData error', error);
      setUserData(null);
    }
  }

  const signInAdmin = async (email, password) => {
    const userCred = await firebase.auth().signInWithEmailAndPassword(email, password);
    //const userCred = await firebase.auth().signInWithCustomToken(password);
    const { user: newUser } = userCred;
    setUser(newUser);
    firebase.analytics().logEvent('login', { method: 'password' });

    if (installHmac && installKey) {
      try {
        // first time install, after signin we need to set with backend this is an admin user
        await addAdmin(newUser, shopOrigin, installKey, installHmac);
      } catch (error) {
        //if onboarding failed by the server, the user won;t have admin role and will be forced to signout.
        console.error('signInAdmin onbaording ERROR', error);
      }
    }
    await getUserData(newUser);
    return;
  }

  const signUpAdmin = async (email, password, firstName, lastName) => {
    const userCred = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const { user: newUser } = userCred;
    setUser(newUser);
    firebase.analytics().logEvent('sign_up', { method: 'password'});

    if (installHmac && installKey) {
      try {
        // first time install, after signin we need to set with backend this is an admin user
        await addAdmin(newUser, shopOrigin, installKey, installHmac, { firstName, lastName });
      } catch (error) {
        //if onboarding failed by the server, the user won;t have admin role and will be forced to signout.
        console.error('signUpAdmin onbaording ERROR', error);
      }
    }
    await getUserData(newUser);
    return;
  }

  const signOut = async () => {
    //await stopMessaging();
    await firebase.auth().signOut();
    localStorage.removeItem('analyticsToken');
  }

  useEffect(() => {
    let off = firebase.auth().onAuthStateChanged((authUser => {
      if (authUser) {
        getUserData(authUser).then(()=> {
          setUser(authUser);
          setAuthLoading(false);
          firebase.analytics().setUserId(authUser.uid);
        });
      } else {
        setUser(null);
        firebase.analytics().setUserId(null);
        setAuthLoading(false);
      }
    }));

    return () => off();
  }, []);

  useEffect(() => {
    if (user && shopOrigin) {
      const shopUsersRef = firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(user.uid);
      const unsubShopUsers = shopUsersRef.onSnapshot(userDoc => {
        setUserData(userDoc.data());
      });

      const permissionsRef = firebase.firestore().collection('users').doc(user.uid).collection('private').doc('permissions');
      const unsubPermissions = permissionsRef.onSnapshot(permissionsDoc => {
        let permissions = [];
        if (permissionsDoc.exists) {
          permissions = Object.keys(permissionsDoc.data()).filter(shopId => hasAdminAppPermissions(permissionsDoc.data()[shopId])).sort();
        }
        setShopsWithPermissions(permissions);
      });

      return () => {
        unsubShopUsers();
        unsubPermissions();
        setUserData(null);
        setShopsWithPermissions([]);
      }
    }
  }, [user])

  const isAdminAppUser = hasAdminAppPermissions(userData?.roles);
  const adminRole = getAdminAppPermission(userData?.roles);
  const isHost = isHostRole(userData?.roles);

  return <AuthStateContext.Provider value={{ user, isAdminAppUser, adminRole, isHost, userData, shopsWithPermissions,  authLoading, signOut, signInAdmin, signUpAdmin, shopOrigin }}>{children}</AuthStateContext.Provider>;
}

export function useAppAuthState() {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error('useAppAuthState must be used within the AppAuthStateProvider');
  }
  return context;
}

export const cubeAuth = (user, shopOrigin) => {
  if (!user || !shopOrigin) {
    throw new CaazamError(401, 'Unauthorized user');
  }
  return getUserToken(user).then(token => {
    return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/auth/analytics`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      },
    }).then(response => {
      return handleResponse(response);
    });
  });
}

export const hostAppAuth = (user, shopOrigin) => {
  if (!user || !shopOrigin) {
    throw new CaazamError(401, 'Unauthorized user');
  }
  return getUserToken(user).then(token => {
    return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/auth/host_app`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      },
    }).then(response => {
      return handleResponse(response);
    });
  });
}

