
import { useState, useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

export default function useShopShopifyTheme(shopId) {

    const [verifyingSections, setVerifying] = useState(true);
    const [supportsThemeSections, setSupportsThemeSections] = useState(false);

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('shopifyTheme') : null;
    const [shopifyTheme, shopifyThemeLoading, shopifyThemeError] = useDocumentData(ref);

    useEffect(() => {
        if (!shopifyThemeLoading && shopId) {
            if (shopifyTheme?.sectionSupport) {
                setSupportsThemeSections(shopifyTheme.sectionSupport.length > 0);
                setVerifying(false);
            } else {
                Firebase.functions().httpsCallable('shopifyTheme-verifyTheme')({ shopId })
                    .then(res => {
                        setSupportsThemeSections(res.length > 0);
                    })
                    .catch(error => {
                        console.error('shopifyTheme-verifyTheme', error);
                        setSupportsThemeSections(false);
                    })
                    .finally(() => setVerifying(false));
            }
        }

    }, [shopifyTheme, shopifyThemeLoading]);

    return { shopifyTheme, shopifyThemeLoading, shopifyThemeError, verifyingSections, supportsThemeSections }
}
