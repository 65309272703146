
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function useScheduleHistory(shopId, scheduleId) {

    const ref = (shopId && scheduleId)
        ? Firebase.firestore().collection('shops').doc(shopId).collection('schedule').doc(scheduleId).collection('changeLog').orderBy('timestamp','desc')
        : null;

    const [scheduleHistory,scheduleHistoryLoading, scheduleHistoryError ] = useCollection(ref);
    scheduleHistoryError && console.error(scheduleHistoryError);
    return { scheduleHistory,scheduleHistoryLoading, scheduleHistoryError }
}
