
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Locales } from '../utils/locales';

export default function useShopTemplateLocales(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('shopLocales') : null;
    const [localesData, shopLocalesLoading, shopLocalesError] = useDocumentData(ref);

    // this will return the common locales supproted by the shop and by our templates (Locales enum)
    // these are not necsssary all locales support by shop
    const filteredLocales = localesData
        ? localesData.shopLocales
            .filter(locale => locale.published)
            .filter(locale => !!Locales[locale.locale])
            .sort(locale => locale.primary ? -1 : 0)
            .map(locale => locale.locale)
        : [];

    return { shopLocales: filteredLocales.length > 0 ? filteredLocales : ['en'] , shopLocalesLoading, shopLocalesError }
}
