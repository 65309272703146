import React from 'react'
import { Button, Stack, Icon, TextContainer } from '@shopify/polaris'
import { FormLabelField } from '../FormLabelField/FormLabelField';
import { CancelSmallMinor, RiskMinor } from '@shopify/polaris-icons';
import { FormTextField } from '../FormTextField/FormTextField';
import './index.scss';

export const FormSelectField = ({
    isEditMode,
    fieldId,
    options,
    removeOption,
    onOptionChange,
    onError,
    placeholder,
    handleFieldPlaceholderChange,
}) =>
    <Stack distribution='fill' vertical>
        <FormTextField
            placeholder={placeholder || 'Please choose'}
            onPlaceholderChange={handleFieldPlaceholderChange} />
        {options && options.length > 0 ? options.map((option, index) => <Stack.Item key={index} fill>
            <Stack wrap={false} alignment='baseline'>
                <Stack.Item fill>
                    <FormLabelField
                        fieldId={fieldId}
                        inEditMode={isEditMode}
                        options={options}
                        onError={onError}
                        setTempValue={(value) => onOptionChange(value, index)}
                        isOption={true}
                        index={index}
                        value={option.label}
                        allowRemove={true} />
                </Stack.Item>
                {isEditMode && <Button onClick={() => removeOption(index)} icon={CancelSmallMinor} plain size='slim' destructive></Button>}
            </Stack>
        </Stack.Item>) :
            <Stack wrap={false}>
                 <Icon
                    source={RiskMinor}
                    color='warning'
                />
                <TextContainer>
                    <p>No options available. Please add options</p>
                </TextContainer>
            </Stack>}
    </Stack>
