import { useEffect, useState } from 'react';
import { query, collection, getFirestore, orderBy, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { STORY_STATUS } from '../pages/Stories/components/VideoCard/components/StatusVisualEffects/consts';

export default function useShopVideos(shopId) {
  const [shopVideosLoading, setShopVideosLoading] = useState(true)
  const [shopVideos, setShopVideos] = useState([])
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    if (shopId) {
      setShopVideosLoading(true)
      const collectionRef = collection(db, `shops/${shopId}/videos`)
      const videosQuery = query(collectionRef, orderBy('createdAt', 'desc'))
      const unsub = onSnapshot(videosQuery, (snapshot) => {
        const promise = Promise.allSettled(snapshot.docs
          .reduce((agg, doc) => {
            agg = [...agg, ...[{id: doc.id, data: doc.data()}]]
            return agg;
          }, [])
          .map(async ({id, data}) => {
            let videoUrl, videoPosterUrl = ''
            try {
              if (data.videoUrl && data.status === STORY_STATUS.ready) {
                videoUrl = await getDownloadURL(ref(storage, data.videoUrl));
              }
              if (data.videoPosterUrl) {
                videoPosterUrl = await getDownloadURL(ref(storage, data.videoPosterUrl));
              }
            } catch (e) {
              console.log(e)
            }
            return ({
              id,
              data: {
                ...data,
                videoPosterUrl,
                videoUrl,
                storeVideoUrl: data.videoUrl,
                storeVideoPosterUrl: data.videoPosterUrl
              }
            })
          })
        )
        promise.then((videos) => {
          setShopVideos(videos.map(({value}) => value))
          setShopVideosLoading(false)
        })
      })
      return () => {
        setShopVideos([])
        unsub()
      }
    }
  }, [shopId])

  return {shopVideos, shopVideosLoading}
}
