import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics';

import { Stack, InlineError, Layout, Card, Spinner } from '@shopify/polaris';
import { useShopProvider } from '../components/ShopProvider';
import PromoCode from '../components/PromoCode'
import useCaazamREST from '../hooks/useCaazamREST'

import AppPage from '../components/AppPage'
import AdminPage from '../components/AdminPage'

function PromoCodePage() {

    const { subData, subDataLoading } = useShopProvider();

    const [loading, setLoading] = useState(false);
    const [planError, setPlanError] = useState(null);

    const [promoData, setPromoData] = useState(null);
    const promoCode = promoData ? promoData.code : null;

    const { subPlanChange } = useCaazamREST();
    const history = useHistory();

    if (!subDataLoading && !subData) {
        return <Redirect to='/select_plan' />
    }

    const applyCode = (promoCode) => {

        if (!promoCode) return;

        setLoading(true);
        setPlanError(null);
        subPlanChange({ planId: subData.planId, redirectUrl: window.location.origin + '/admin/subscription', promoCode })
            .then(confirmUrl => {
                firebase.analytics().logEvent('admin.promo_code', { promo_code: promoCode });
                window.location.assign(confirmUrl);
            })
            .catch(error => {
                console.error('subPlanChange failed', error);
                setLoading(false);
                setPlanError(error);
            })

    }

    const onValidPromoCode = (code, data) => {
        applyCode(code);
    }

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.replace('/subscription') }]}
            title='Use Promo Code'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned title='Apply a promo code to your Boutiq subscription' >
                        <Stack vertical>
                            <PromoCode onValidCode={onValidPromoCode} collapsible={false} disabled={loading}/>
                            {loading && <Stack alignment='center'>
                                <Spinner size='small'/>
                                <p>Loading Shopify app subscription accept page</p>
                            </Stack>}
                            {planError && <InlineError message={planError.reason || planError.message} />}
                        </Stack>
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>

    )
}

export default function() {
    return (
        <AdminPage>
            <PromoCodePage/>
        </AdminPage>
    )
}
