import React from 'react';
import {useHistory } from 'react-router-dom';
import { Layout, Card, Link, DescriptionList } from '@shopify/polaris';

import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';

function HostSettings() {

    const history = useHistory();

    return (
        <AppPage title='Host app experience' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.AnnotatedSection
                    title="Host Settings"
                    description='Customize the Boutiq host app'
                >
                    <Card>
                        <Card.Section>
                            <DescriptionList
                                items={[                                    
                                    {
                                        term: <Link url={`/hostSettings/clientProfile`}>Client profile</Link>,
                                        description: 'Customize client profiles displayed to hosts',
                                    },
                                    {
                                        term: <Link url={`/hostSettings/draftOrders`}>Draft orders</Link>,
                                        description: 'Enable hosts to create draft orders for clients during a Boutiq video call',
                                    },
                                ]}
                            />
                        </Card.Section>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}


export default function () {
    return (
        <AdminPage>
            <HostSettings />
        </AdminPage>
    )
}
