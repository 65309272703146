
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopCheckoutConfig(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('checkout') : null;
    const [checkoutConfig, checkoutConfigLoading, checkoutConfigError] = useDocumentData(ref);
    return { checkoutConfig, checkoutConfigLoading, checkoutConfigError }
}
