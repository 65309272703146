import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useHostSelection(shopId) {
    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('hostSelect') : null;

    const setHostSelectionConfig = async (config) => ref ?
        ref.set(config, { merge: true }) : () => {}

    const [hostSelectionConfig, hostSelectionConfigLoading, hostSelectionConfigError] = useDocumentData(ref);
    return { hostSelectionConfig, hostSelectionConfigLoading, hostSelectionConfigError, setHostSelectionConfig }
}
