import {Card} from "@shopify/polaris";
import {DraggableVideos} from "../DraggableVideos";
import React, { useMemo } from "react";
import usePublishButton from '../../../../hooks/usePublishButton';
import { useShopProvider } from '../../../../../../components/ShopProvider';
import { VIDEO_STATUS, STORY_STATUS } from '../../../../../../constants/story';

const StoryEditableCard = ({title, videos, error, onChangeVideosOrder, onRemoveVideo, onPreview, onViewStoryPage, story, onDelete, onOpenUploader, uploadingStates}) => {
  const publishButton = usePublishButton(story, videos)
  const {shopVideos} = useShopProvider()

  const canViewPage = useMemo(() => videos.length > 0 && !!story?.conditionUrl, [story, videos]);

  const actions = useMemo(() => {
    const actions = []
    if (videos.length > 0) {
      actions.push({content: 'Preview', onAction: onPreview, disabled: videos.length === 0})
      onViewStoryPage && story?.status === STORY_STATUS.PUBLISHED && actions.push({content: 'View page', onAction: onViewStoryPage, disabled: !canViewPage})
      if (story && onRemoveVideo) {
        actions.push({content: 'Delete', onAction: onDelete, destructive: true})
      }
    }
    if (story) {
      actions.push(publishButton)
    }
    return actions
  }, [videos, story, onDelete, onPreview, onViewStoryPage, publishButton, canViewPage])

  const uploadAction = {
    content: 'Upload video',
    onAction: onOpenUploader
  }

  return (
    <Card
      sectioned
      title={title}
      actions={actions}
    >
      <DraggableVideos
        setStoriesOrder={onChangeVideosOrder}
        videos={[...videos, ...shopVideos.filter(({data}) => data.status !== VIDEO_STATUS.READY)]}
        deletePublishedStory={onRemoveVideo}
        error={error}
        uploadingStates={uploadingStates}
        uploadAction={uploadAction}
      />
    </Card>
  )
}

export default StoryEditableCard;
