import React from 'react';
import { Stack, TextStyle, Button, Modal, ButtonGroup, Spinner, Icon, Scrollable } from '@shopify/polaris';
import { useCreateNewAppointmentProvider, VIEWS } from '../../../../components/CreateNewAppointmentProvider';
import { ClientDataTab } from './components/clientDataTab';
import { EventDataTab } from './components/eventDataTab';
import { HostDataTab } from './components/hostDataTab';
import { SlotDataTab } from './components/slotDataTab';
import { SummaryDataTab } from './components/summaryDataTab';
import { ScheduleResultTab } from './components/scheduleResultTab';
import { MobileAcceptMajor } from '@shopify/polaris-icons';
import './styles.css';

export const CaazamEventEditorModal = () => {

    const {
        open,
        tabs,
        handleOnSubmit,
        selectedTab,
        onClose,
        handleTabChange,
        isScheduling,
        formError,
        slotsErrorMessage,
        isBtnsVisible,
        isReschedule,
    } = useCreateNewAppointmentProvider();
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={<Stack vertical>
                <Stack>
                    <TextStyle>
                        {isReschedule ? 'Reschedule' : 'Invite a client'}
                    </TextStyle>
                </Stack>
            </Stack>}>
            <Modal.Section>
                {tabs && Object.keys(tabs).length > 0 ?
                    <Stack vertical>
                        <Stack.Item fill>
                            <div className='event-editor-btns-group'>
                                <ButtonGroup fullWidth segmented>
                                    {Object.keys(tabs).map((tab, index) => <Button
                                        icon={
                                            <span className={`event-editor-btn-icon-wrapper${index < Object.keys(tabs).findIndex(tab => tab === selectedTab) ? ' valid' : ''}`}>
                                                <Icon
                                                    source={MobileAcceptMajor}
                                                    color="base"
                                                />
                                            </span>}
                                        monochrome={selectedTab !== tab}
                                        disabled={selectedTab !== tab}
                                        pressed={selectedTab === tab}
                                        key={tab}>
                                        {tabs[tab].content}
                                    </Button>)}
                                </ButtonGroup>
                            </div>
                        </Stack.Item>
                        <Stack.Item fill>
                            <Scrollable style={{ height: 400 }}>
                                {selectedTab === VIEWS.CLIENT_DATA &&
                                    <ClientDataTab />}
                                {selectedTab === VIEWS.EVENT_DATA &&
                                    <EventDataTab />}
                                {selectedTab === VIEWS.HOST_DATA &&
                                    <HostDataTab />}
                                {selectedTab === VIEWS.TIMESLOT_DATA &&
                                    <SlotDataTab />}
                                {selectedTab === VIEWS.SUMMARY_DATA &&
                                    <SummaryDataTab />}
                                {selectedTab === VIEWS.SCHEDULE_RESULT_DATA &&
                                    <ScheduleResultTab />}
                            </Scrollable>
                        </Stack.Item>
                    </Stack> :
                    <div style={{ height: '452px', display: 'grid' }}>
                        <div style={{ justifySelf: 'center', alignSelf: 'center' }}><Spinner /></div>
                    </div>}
            </Modal.Section>
            <Modal.Section>
                <Stack>
                    {formError &&
                        <TextStyle variation='negative'>
                            {formError}
                        </TextStyle>}
                    <Stack.Item fill>
                        <Stack distribution='trailing' alignment='center'>
                            <div style={{ opacity: isBtnsVisible ? 1 : 0, pointerEvents: isBtnsVisible ? 'all' : 'none' }}>
                               <ButtonGroup>
                                    {tabs && Object.keys(tabs).length > 0 && Object.keys(tabs)[0] !== selectedTab && selectedTab &&
                                        <Button
                                            disabled={isScheduling}
                                            onClick={() => handleTabChange(selectedTab, false)}>Back</Button>}
                                    {VIEWS.SUMMARY_DATA !== selectedTab ?
                                        <Button
                                            onClick={() => handleTabChange(selectedTab)}
                                            disabled={!tabs || Object.keys(tabs).length === 0 || (slotsErrorMessage && VIEWS.TIMESLOT_DATA === selectedTab)}
                                            primary>Next</Button> :
                                        VIEWS.SUMMARY_DATA === selectedTab && <Button
                                            onClick={handleOnSubmit}
                                            disabled={isScheduling}
                                            primary>
                                            {isReschedule ? 'Reschedule' : 'Schedule'}
                                        </Button>}
                                </ButtonGroup>
                            </div>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Modal.Section>
        </Modal>
    )
}
