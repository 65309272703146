import React from 'react';
import './index.css'

const WelcomeLogo = ({ src }) => {

    return (
        <div className="shop-logo-container-wrapper">
            <div className="shop-logo-content-wrapper">
                <div className="shop-logo-content">
                    {src && 
                    <img src={src} className='shop-logo' />}
                </div>
            </div>
        </div>
    );
};

export default WelcomeLogo;
