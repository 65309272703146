import React from 'react';
import Select from "react-select";

export const EventTypesSelector = ({eventTypesOptions, value, onChange, maxMenuHeight }) => {
  return (
    <div style={{ minWidth: 200 }}>
      <Select
        options={eventTypesOptions}
        onChange={onChange}
        maxMenuHeight={maxMenuHeight}
        value={value}/>
    </div>
  )
}
