import React from 'react';
import {useHistory } from 'react-router-dom';
import { Layout, Card, Link, DescriptionList, MediaCard } from '@shopify/polaris';
import {
    AdjustMinor,
  } from '@shopify/polaris-icons';

import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';

function ClientSettings() {

    const history = useHistory();

    return (
        <AppPage title='Client app experience' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.AnnotatedSection
                    title="Client Settings"
                    description='Customize the Boutiq client app'
                >
                    <Card>
                        <Card.Section>
                            <DescriptionList
                                items={[                                    
                                    {
                                        term: <Link url={`/clientSettings/clientAppTheme`}>Client app theme</Link>,
                                        description: 'Customize the Boutiq client app theme to match your online brand: logo, colors and fonts',
                                    },
                                    {
                                        term: <Link url={`/stories/boutiq`}>Client intro video</Link>,
                                        description: 'Add an intro video that will be shown to your clients when they first open the Boutiq app.',
                                    },
                                    {
                                        term: <Link url={`/clientSettings/welcomeSettings`}>Client welcome screen</Link>,
                                        description: 'Customize the Boutiq client app welcome screen content',
                                    },
                                    {
                                        term: <Link url={`/clientSettings/forms`}>Client forms</Link>,
                                        description: 'Configure the pre-call form and scheduling form to determine info requested from the client',
                                    },
                                    {
                                        term: <Link url={`/clientSettings/advancedSettings`}>Advanced client settings</Link>,
                                        description: 'Manage advanced configuration options for the Boutiq client app',
                                    },
                                ]}
                            />
                        </Card.Section>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}


export default function () {
    return (
        <AdminPage>
            <ClientSettings />
        </AdminPage>
    )
}
