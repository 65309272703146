export const storyAnalyticsDataAggregation = (cubeResult, parse, condition = 'date') => (
  cubeResult.loadResponse.results
    .reduce((agg, cubeItem) => {
      cubeItem.data
        .map(parse)
        .forEach((item) => {
          const index = agg.map((aggItem) => aggItem[condition]).indexOf(item[condition])
          if (index >= 0 && item[condition]) {
            const newValue = {
              ...agg[index],
              ...item,
            }
            agg.splice(index, 1, newValue)
          } else {
            agg.push(item)
          }
        })
      return agg
    }, [])
)
