import AdminPage from "../../../components/AdminPage";
import React, { useEffect, useState } from "react";
import AppPage from "../../../components/AppPage";
import {
  Layout,
  ContextualSaveBar,
  Card,
  IndexTable,
  Stack,
  EmptyState,
  Banner,
  Text,
  Button,
  SkeletonBodyText
} from '@shopify/polaris';
import { useHistory } from "react-router-dom";
import RecordingRuleModal from "../components/RecordingRuleModal";
import { useShopProvider } from "../../../components/ShopProvider";
import useRecordsRules from "../../../hooks/useRecordsRules";
import useShopInsightsConfig from '../../../hooks/useShopInsightsConfig';
import {
  DeleteMinor,
} from '@shopify/polaris-icons';
import DeleteModal from "../../../components/DeleteModal";
import RecordingConsentCard from "../components/RecordingConsentCard";

const RecordingsRulesPage = () => {
  const history = useHistory();

  const { shopOrigin, hosts, eventTypes } = useShopProvider();

  const { rules, loading, onAddRule, onEditRule, onRemoveRule } = useRecordsRules(shopOrigin);
  const { insightsConfigData, insightsConfigLoading, setInsightsConfig } = useShopInsightsConfig(shopOrigin);

  const [openAddModal, setAddModal] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState(null);
  const [editedRule, setEditedRule] = useState(null);

  const [recConsentMessage, setRecConsentMessage] = useState({});
  const [isConsentMandatory, setConsentMandatory] = useState(false);
  const [dirtyBit, setDirtyBit] = useState(false);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    if (!openAddModal) {
      setEditedRule(null)
    }
  }, [openAddModal]);

  useEffect(() => {
    setRecConsentMessage(insightsConfigData?.recConsentMessage ?? {});
    setConsentMandatory(insightsConfigData?.isConsentMandatory ?? false);
  }, [insightsConfigData]);


  const toggleAddModal = () => {
    setAddModal(prev => !prev);
  }

  const onHideDeleteModal = () => {
    setDeleteRuleId(null)
  }

  const onOpenEditRule = (rule) => {
    setEditedRule(rule)
    toggleAddModal()
  }

  const onSubmitRule = (rule) => {
    if (editedRule) {
      onEditRule(editedRule.id, rule)
    } else {
      onAddRule(rule)
    }
  }

  const onDeleteRule = () => {
    onRemoveRule(deleteRuleId)
    setDeleteRuleId(null)
  }

  const handleRecConsentMessageChange = (val, locale) => {
    setRecConsentMessage((state) => ({...state, [locale]: val}));
    setDirtyBit(true);
  }
  
  const handleConsentMandatoryChange = (newCheck) => {
    setConsentMandatory(newCheck);
    setDirtyBit(true);
  }
  

  const save = async () => {
    setSaving(true);
    try {
      await setInsightsConfig({ recConsentMessage, isConsentMandatory });
    } catch (error) {
      console.error('Failed saving recordingConsent data', error);
    } finally {
      setSaving(false);
      setDirtyBit(false);
    }
  }

  const onDiscard = () => {
    setRecConsentMessage(insightsConfigData?.recConsentMessage ?? {});
    setConsentMandatory(insightsConfigData?.isConsentMandatory ?? false);
    setDirtyBit(false);
  }

  const addRuleAction = { content: 'Add rule', onAction: toggleAddModal };

  const tableRows = rules.map((rule, index) => {
    const host = hosts.find(({ id }) => id === rule.hostId)
    const event = eventTypes.docs.find(({ id }) => id === rule.eventTypeId)
    return (
      <IndexTable.Row
        id={rule.id}
        key={rule.id}
        position={index}
      >
        <IndexTable.Cell>
          <Text as="span">
            {host ? host.firstName + ' ' + host.lastName : rule.hostId}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span">
            {event ? event.data().name : rule.eventTypeId}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" color={'subdued'}>
            {new Intl.DateTimeFormat(navigator.language, {
              dateStyle: 'long',
              timeStyle: 'short',
            }).format(rule.updatedAt?.toDate())}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Stack distribution={'trailing'} alignment='center'>
            <Button plain onClick={() => onOpenEditRule(rule)}>
              Edit
            </Button>
            <Button plain destructive onClick={() => setDeleteRuleId(rule.id)}>Delete</Button>
          </Stack>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  })

  return (
    <AppPage
      title='Recordings - Rules'
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
    >
      <Layout>
        <Layout.Section>
          <Banner status='warning'>
            <Text>If you choose to record call audio, you need to comply with certain laws and regulations, including
              those regarding obtaining consent to record </Text>
          </Banner>
        </Layout.Section>
        <Layout.AnnotatedSection
          title='Recording rules'
          description='Set call recording rules - only calls that match a rule will be recorded and analyzed'
        >
          <Card
            sectioned
            actions={rules.length ? [addRuleAction] : null}

          >
            {loading ? (
              <Stack vertical>
                <SkeletonBodyText lines={4} />
              </Stack>
            ) : (
              <IndexTable
                itemCount={rules.length}
                headings={[
                  { title: 'Host' },
                  { title: 'Event type' },
                  { title: 'Date' },
                  { title: '' },
                ]}
                emptyState={(
                  <EmptyState
                    heading={'No recording rules set'}
                    action={{ content: 'Add rule', onAction: toggleAddModal }}
                  >
                    <Text>No calls will be recorded</Text>
                  </EmptyState>
                )}
                selectable={false}
              >
                {tableRows}
              </IndexTable>
            )}
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Recording consent'
          description='Manage client consent for call audio recording'
        >
          <RecordingConsentCard
            isLoading={insightsConfigLoading}
            onRecConsentMessageChange={handleRecConsentMessageChange}
            recConsentMessage={recConsentMessage}
            onConsentMandatoryChange={handleConsentMandatoryChange}
            isConsentMandatory={isConsentMandatory}
          />
        </Layout.AnnotatedSection>
      </Layout>
      <RecordingRuleModal
        onSubmitRule={onSubmitRule}
        open={openAddModal}
        onClose={toggleAddModal}
        editedRule={editedRule}
        rules={rules}
      />
      <DeleteModal
        isOpen={!!deleteRuleId}
        title={'Confirm Delete recording rule'}
        text={`Are you sure you want to delete rule?`}
        onClose={onHideDeleteModal}
        deleteAction={onDeleteRule}
      />
      {dirtyBit && <ContextualSaveBar
        message="Save recording settings?"
        saveAction={{
          onAction: save,
          loading: isSaving,
        }}
        discardAction={{
          onAction: onDiscard,
          disabled: isSaving,
        }}
      />}
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <RecordingsRulesPage />
    </AdminPage>
  )
}
