

import React, { useState } from 'react';

import { Button, Stack, TextStyle, Collapsible, TextField, Form, FormLayout, InlineError } from '@shopify/polaris';
import useCaazamREST from '../hooks/useCaazamREST';

export default function PromoCode({ onValidCode, disabled = false, collapsible = true }) {
    const [code, setCode] = useState(null);
    const [codeData, setCodeData] = useState(null);    
    const [codeError, setCodeError] = useState(null);

    const [open, setOpen] = useState(!collapsible);
    const [loading, setLoading] = useState(false);

    const { promoCodeValidate} = useCaazamREST();

    const onSubmit = () => {
        setCodeData(null);
        setLoading(true);
        setCodeError(null);
        promoCodeValidate(code)
            .then(data => {
                setCodeData(data);
                onValidCode && onValidCode(code, data);
            })
            .catch(error => {
                setCodeError(error);
                onValidCode && onValidCode(null, null);
            })
            .finally(()=>{
                setLoading(false);
            });
    }

    const presentCodeData = () => {
        return (
            <TextStyle variation='positive'>
                Code is valid: {codeData.freeTrialDays && `${codeData.freeTrialDays} extra days free trial`}
            </TextStyle>
        )
    }

    const onClear = () => {
        setCode(null);
        setCodeData(null);
        setCodeError(null);
        onValidCode && onValidCode(null, null);
    }

    return (
        <div style={{ marginBottom: '1rem' }}>
            <Stack vertical spacing='tight'>
                {collapsible && <Button plain onClick={() => setOpen(!open)}>
                    Have a promo code?
                </Button>}

                <Collapsible open={open}
                    id="basic-collapsible"
                    transition={{ duration: '150ms', timingFunction: 'ease' }}
                >

                    <Form onSubmit={onSubmit}>
                        <FormLayout>
                            <Stack alignment='center'>
                                <p> Enter code</p>
                                <div style={{ width: '230px' }}>
                                    <TextField value={code} onChange={setCode} clearButton onClearButtonClick={onClear} />
                                </div>
                                <Button submit loading={loading} disabled={disabled}>Submit</Button>
                                {codeError &&
                                    <InlineError message={codeError.reason || codeError.message} />}
                                {codeData && presentCodeData()}
                            </Stack>
                        </FormLayout>
                    </Form>
                </Collapsible>                
            </Stack>
        </div>
    )

}