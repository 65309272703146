import React from 'react';
import { Select, Stack } from '@shopify/polaris';
import { DEFAULT_HOST_VALUE } from '../consts';

export default function HostFilter({ currentHost, availableHosts, onHostUpdate, allowAll = true }) {
    const allowAllOption = allowAll ? [DEFAULT_HOST_VALUE] : [];
    const selectHostOptionsList = allowAllOption
        .concat(Object.keys(availableHosts)
            .map(key => ({ label: `${availableHosts[key].firstName} ${availableHosts[key].lastName}`, value: availableHosts[key].id })));

    return (
        <Stack>
            <Select
                options={selectHostOptionsList}
                value={currentHost}
                onChange={onHostUpdate}
            />
        </Stack>
    )
}
