import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@shopify/polaris';
import { ReactComponent as WidgetIcon } from './icon/WidgetIcon.svg';
import { DEFAULT_WIDGET_POSITION } from './consts';
import {
    TickSmallMinor
} from '@shopify/polaris-icons';

import './styles.css';

export default function WidgetPosition({ color, text, position, isChangingPositionActive, setIsChangingPositionActive, setPosition, size, hostAvatars }) {
    const [hostAvatarIndex, setHostAvatarIndex] = useState(null);
    const [hostAvatarLoadingError, setHostAvatarLoadingError] = useState(false);
    const [hostAvatarLoaded, setHostAvatarLoaded] = useState(false);
    const [hostAvatarsTemp, setHostAvatarsTemp] = useState([]);
    const [isPositionVisible, setPositionVisible] = useState(false);
    const [positionVisualizatorAlignment, setPositionVisualizatorAlignment] = useState(null);
    const [positionData, setPositionData] = useState({
        directionX: null,
        directionY: null,
        valueX: null,
        valueY: null,
    })
    const [isDroppableArea, setIsDroppableArea] = useState(false);
    const backdropRef = useRef(null);
    const widgetElement = useRef(null);

    useEffect(() => {
        if (hostAvatars && hostAvatars.length > 0) {
            setHostAvatarsTemp(hostAvatars);
            setHostAvatarIndex(Math.floor(Math.random()*hostAvatars.length));
        } else {
            setHostAvatarIndex(null);
            setHostAvatarLoaded(false);
        }
    }, [hostAvatars])

    const handleOnErrorLoading = () => {
        if (hostAvatarsTemp.length > 1) {
            setHostAvatarIndex(0);
            setHostAvatarsTemp(hostAvatarsTemp.filter((_, index) => index !== hostAvatarIndex));
        } else {
            setHostAvatarLoadingError(true)
        }
    }

    useEffect(() => {
        if (position) {
            widgetElement.current.style.top = position.top;
            widgetElement.current.style.right = position.right;
            widgetElement.current.style.bottom = position.bottom;
            widgetElement.current.style.left = position.left;
        }
    }, [position])

    const positionHelper = (posX, posY) => {

        if (posX <= 0) {
            posX = 0;
        } else if (posX >= window.innerWidth - widgetElement.current.offsetWidth) {
            posX = window.innerWidth - widgetElement.current.offsetWidth;
        }
        if (posY <= 0) {
            posY = 0;
        } else if (posY >= window.innerHeight - widgetElement.current.offsetHeight) {
            posY = window.innerHeight - widgetElement.current.offsetHeight;
        }

        let coordinates = {};
        if (window.innerWidth / 2 > posX) {
            setPositionVisualizatorAlignment('right');
            coordinates = { ...coordinates, 'directionX': 'left:', 'valueX': Math.round(posX) + 'px' };
        } else {
            setPositionVisualizatorAlignment('left');
            coordinates = { ...coordinates, 'directionX': 'right:', 'valueX': Math.round(window.innerWidth - posX - widgetElement.current.offsetWidth) + 'px' };
        }
        if (window.innerHeight / 2 > posY) {
            coordinates = { ...coordinates, 'directionY': 'top:', 'valueY': Math.round(posY) + 'px' };
        } else {
            coordinates = { ...coordinates, 'directionY': 'bottom:', 'valueY': Math.round(window.innerHeight - posY - widgetElement.current.offsetHeight) + 'px' };
        }
        setPositionData(coordinates);
        return { posX, posY }
    }

    const onMoveWidget = (top, left) => {
        let coordinates = {}
        if (!isNaN(top) && !isNaN(left)) {
            if (top > window.innerHeight / 2) {
                coordinates = { ...coordinates, 'bottom': `${window.innerHeight - top - widgetElement.current.offsetHeight}px`, 'top': 'auto' }
            } else {
                coordinates = { ...coordinates, 'top': `${top}px`, 'bottom': 'auto' }
            }
            if (left > window.innerWidth / 2) {
                coordinates = { ...coordinates, 'right': `${window.innerWidth - left - widgetElement.current.offsetWidth}px`, 'left': 'auto' }
            } else {
                coordinates = { ...coordinates, 'left': `${left}px`, 'right': 'auto' }
            }
            widgetElement.current.style.top = coordinates.top;
            widgetElement.current.style.right = coordinates.right;
            widgetElement.current.style.bottom = coordinates.bottom;
            widgetElement.current.style.left = coordinates.left;
        }
    }

    const onHoverIn = (event) => {
        const button = event.target;
        button.classList.add('with-helper-text');
    }

    const onHoverOut = (event) => {
        const button = event.target;
        button.classList.remove('with-helper-text');
    }

    const mousedown = (event) => {
        event.preventDefault();
        const { pageX, pageY } = event;
        setPositionVisible(true);
        const x = pageX - widgetElement.current.offsetWidth / 2;
        const y = pageY - widgetElement.current.offsetHeight / 2;
        const { posX, posY } = positionHelper(x, y);
        widgetElement.current.style.left = posX + 'px';
        widgetElement.current.style.top = posY + 'px';

        widgetElement.current.addEventListener('mouseup', mouseup);
        backdropRef.current.addEventListener('mousemove', mousemove);
        widgetElement.current.addEventListener('mousemove', mousemove);
    }

    const mousemove = (event) => {
        event.preventDefault();
        const { pageX, pageY } = event;
        const x = pageX - widgetElement.current.offsetWidth / 2;
        const y = pageY - widgetElement.current.offsetHeight / 2;
        const { posX, posY } = positionHelper(x, y);
        widgetElement.current.style.left = posX + 'px';
        widgetElement.current.style.top = posY + 'px';
        let elemBelow = document.elementsFromPoint(event.clientX, event.clientY);
        if (!elemBelow) return;
        let droppableBelow = elemBelow.filter(item => item.tagName === "DIV" && item.classList.contains('droppable'));

        if (droppableBelow && droppableBelow.length > 0) {
            const parentEl = droppableBelow[0].parentElement;
            backdropRef.current.classList.add('allow');
            setIsDroppableArea(true);
            if (!parentEl.classList.contains('active')) {
                droppableBelow[0].parentElement.classList.add('active')
            }
        } else {
            backdropRef.current.childNodes.forEach(node => node.classList.remove('active'));
            backdropRef.current.classList.remove('allow');
            setIsDroppableArea(false);
        }
        onMoveWidget(posY, posX)
    }

    const mouseup = (event) => {
        event.preventDefault();
        setPositionVisible(false);
        widgetElement.current.removeEventListener('mouseup', mouseup);
        backdropRef.current.removeEventListener('mousemove', mousemove);
        widgetElement.current.removeEventListener('mousemove', mousemove);
    }


    const touchstart = (event) => {
        event.preventDefault();
        const { clientX, clientY } = event.touches[0];
        setPositionVisible(true);
        const x = clientX - widgetElement.current.offsetWidth / 2;
        const y = clientY - widgetElement.current.offsetHeight / 2;
        const { posX, posY } = positionHelper(x, y);
        widgetElement.current.style.left = posX + 'px';
        widgetElement.current.style.top = posY + 'px';

        backdropRef.current.addEventListener('touchmove', touchmove);
        widgetElement.current.addEventListener('touchmove', touchmove);
        widgetElement.current.addEventListener('touchend', touchend);
        widgetElement.current.addEventListener('touchcancel', touchend);
    }

    const touchmove = (event) => {
        event.preventDefault();
        const { clientX, clientY } = event.changedTouches[0];
        let x = clientX - widgetElement.current.offsetWidth / 2;
        let y = clientY - widgetElement.current.offsetHeight / 2;

        const { posX, posY } = positionHelper(x, y);
        widgetElement.current.style.left = posX + 'px';
        widgetElement.current.style.top = posY + 'px';
        let elemBelow = document.elementsFromPoint(clientX, clientY);
        if (!elemBelow) return;
        let droppableBelow = elemBelow.filter(item => item.tagName === "DIV" && item.classList.contains('droppable'));
        if (droppableBelow && droppableBelow.length > 0) {
            const parentEl = droppableBelow[0].parentElement;
            backdropRef.current.classList.add('allow');
            setIsDroppableArea(true);
            if (!parentEl.classList.contains('active')) {
                droppableBelow[0].parentElement.classList.add('active')
            }
        } else {
            backdropRef.current.childNodes.forEach(node => node.classList.remove('active'));
            backdropRef.current.classList.remove('allow');
            setIsDroppableArea(false);
        }
    }

    const touchend = (event) => {
        event.preventDefault();
        setPositionVisible(false);
        const left = Number(widgetElement.current.style.left.replace('px', ''));
        const top = Number(widgetElement.current.style.top.replace('px', ''));
        onMoveWidget(top, left)

        backdropRef.current.removeEventListener('touchmove', touchmove);
        widgetElement.current.removeEventListener('touchmove', touchmove);
        widgetElement.current.removeEventListener('touchend', touchend);
        widgetElement.current.removeEventListener('touchcancel', touchend);
    }

    const preventDoubleTap = (event) => {
        event = event.originalEvent || event;
        if (event.scale > 1) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        const caazamButton = widgetElement.current;
        if (text) {
            caazamButton.addEventListener('mouseenter', onHoverIn);
            caazamButton.addEventListener('mouseleave', onHoverOut);

            if (isChangingPositionActive) {
                caazamButton.removeEventListener('mouseenter', onHoverIn);
                caazamButton.removeEventListener('mouseleave', onHoverOut);
                const { x, y } = widgetElement.current.getBoundingClientRect();
                let elemBelow = document.elementsFromPoint(x + widgetElement.current.offsetWidth / 2, y + widgetElement.current.offsetHeight / 2);
                if (!elemBelow) return;
                let droppableBelow = elemBelow.filter(item => item.tagName === "DIV" && item.classList.contains('droppable'));

                if (droppableBelow && droppableBelow.length > 0) {
                    const parentEl = droppableBelow[0].parentElement;
                    backdropRef.current.classList.add('allow');
                    setIsDroppableArea(true);
                    if (!parentEl.classList.contains('active')) {
                        droppableBelow[0].parentElement.classList.add('active')
                    }
                }

                widgetElement.current.addEventListener('mousedown', mousedown);
                widgetElement.current.addEventListener('touchstart', touchstart);
                document.addEventListener('gesturestart', preventDoubleTap, false);
            }

            return () => {
                document.removeEventListener('gesturestart', preventDoubleTap, false);
                caazamButton.removeEventListener('mouseenter', onHoverIn);
                caazamButton.removeEventListener('mouseleave', onHoverOut);
                widgetElement.current.removeEventListener('mousedown', mousedown);
                widgetElement.current.removeEventListener('touchstart', touchstart);
            }
        }
    }, [text, isChangingPositionActive])

    const cancelPosition = () => {
        widgetElement.current.style.top = position.top;
        widgetElement.current.style.right = position.right;
        widgetElement.current.style.bottom = position.bottom;
        widgetElement.current.style.left = position.left;
        setIsChangingPositionActive(false);
        document.body.style.overflow = null;
    }

    const resetPosition = () => {
        setPosition(DEFAULT_WIDGET_POSITION);
        setIsChangingPositionActive(false);
        document.body.style.overflow = null;
    }

    const savePosition = () => {
        const coordinates = {
            top: widgetElement.current.style.top,
            right: widgetElement.current.style.right,
            bottom: widgetElement.current.style.bottom,
            left: widgetElement.current.style.left,
        }
        setPosition(coordinates);
        setIsChangingPositionActive(false);
        document.body.style.overflow = null;
    }

    const widgetStyles = () => {
        let styles = { 'backgroundColor': '#08b273' };
        if (color) {
            styles = { ...styles, 'backgroundColor': color }
        }
        if (position) {
            styles = { ...styles, 'top': position.top, 'bottom': position.bottom, 'left': position.left, 'right': position.right }
        }
        return styles;
    }

    return (
        <>
            {isChangingPositionActive &&
                <div ref={backdropRef} className='change-widget-position-backdrop'>
                    <div className='message'>
                        <p>Position the widget within one of the corner areas</p>
                        <div className='actions'>
                            <Button onClick={cancelPosition}>Cancel</Button>
                            <Button onClick={resetPosition}>Reset</Button>
                            <Button icon={TickSmallMinor} onClick={savePosition} disabled={!isDroppableArea} primary>Apply</Button>
                        </div>
                    </div>
                    <div className='access-area top top-left'>
                        <div className='side-left-area target droppable top-left'></div>
                        <div className='side-top-area target droppable top-left'></div>
                    </div>
                    <div className='access-area top top-right'>
                        <div className='side-right-area target droppable top-right'></div>
                        <div className='side-top-area target droppable top-right'></div>
                    </div>
                    <div className='access-area bottom bottom-left'>
                        <div className='side-left-area target droppable bottom-left'></div>
                        <div className='side-bottom-area target droppable bottom-left'></div>
                    </div>
                    <div className='access-area bottom bottom-right'>
                        <div className='side-right-area target droppable bottom-right'></div>
                        <div className='side-bottom-area target droppable bottom-right'></div>
                    </div>
                </div>}
            <button className={`caazam-widget${size ? ` ${size}` : ' standard'}`} ref={widgetElement} style={widgetStyles()}>
                {isPositionVisible &&
                    <div className={`position-vizualizator-container ${positionVisualizatorAlignment}`}>
                        <div>
                            <span>{positionData.directionY}</span>
                            <span>{positionData.valueY}</span>
                        </div>
                        <div>
                            <span>{positionData.directionX}</span>
                            <span>{positionData.valueX}</span>
                        </div>
                    </div>}
                {hostAvatarIndex !== null && !hostAvatarLoadingError ?
                    <div className={`caazam-widget-host-avatar-container${hostAvatarLoaded ? ' loaded' : ''}`}>
                        <img
                            onLoad={() => setHostAvatarLoaded(true)}
                            onError={() => handleOnErrorLoading()}
                            src={hostAvatarsTemp[hostAvatarIndex]} />
                    </div> :
                    <div className='caazam-widget-container'>
                        <WidgetIcon />
                    </div>}
                    <div className='caazam-widget-helper-text-wrapper'>
                        <span id="caazam-widget-helper-text">{text}</span>
                    </div>
            </button>
        </>
    )
}


