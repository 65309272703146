
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopChatConfig(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('chat') : null;

    const [chatConfig, chatConfigLoading, chatConfigError] = useDocumentData(ref);
    return { chatConfig, chatConfigLoading, chatConfigError }
}
