import Moment from 'moment-timezone';

export const FORM_OBJECT_TYPES = {
    select: 'select',
    dateTime: 'dateTime',
    date: 'date',
    time: 'time'
};

export const FORM_ELEMENTS_PROPS = [
    (formValues) => ([
        {
            name: `startTime`,
            type: FORM_OBJECT_TYPES.dateTime,
            label: 'Start time',
            value: formValues.startTime,
            minDate: formValues.startTime,
            maxDate: formValues.endTime,
            isValid: Moment(formValues.endTime) > Moment(formValues.startTime)
        },
        {
            name: `endTime`,
            type: FORM_OBJECT_TYPES.dateTime,
            label: 'End time',
            minDate: formValues.startTime,
            maxDate: null,
            value: formValues.endTime,
            isValid: Moment(formValues.endTime) > Moment(formValues.startTime)
        }
    ]),
    (formValues) => ([
        {
            name: `repeat_mode`,
            type: FORM_OBJECT_TYPES.select,
            label: 'repeat',
            value: formValues.repeat_mode,
            isValid: true,
            values: [
                {
                    label: 'none',
                    value: false,
                },
                {
                    label: 'every week',
                    value: true,
                }
            ],
        }
    ]),

    (formValues) => {
        const repeat_end = () => (
            formValues.repeat_mode ? {
                name: `repeat_end`,
                type: FORM_OBJECT_TYPES.select,
                label: 'repeat end',
                value: formValues.repeat_end,
                isValid: true,
                values: [
                    {
                        label: 'never',
                        value: false,
                    },
                    {
                        label: 'select date',
                        value: true,
                    }
                ],
            } : null);

        const repeat_end_date = () => (
            formValues.repeat_end ?
                formValues.repeat_mode ? {
                    name: `repeat_end_date`,
                    type: FORM_OBJECT_TYPES.date,
                    label: '',
                    isValid: true,
                    minDate: Moment(formValues.endTime).add(1, 'days').toDate(),
                    maxDate: null,
                    value: formValues.repeat_end_date
                } : null : null
        );
        return [repeat_end(), repeat_end_date()];
    }
]
