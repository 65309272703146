import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Card, Popover, SkeletonBodyText, ActionList, Button, DataTable, EmptyState, Badge  } from '@shopify/polaris';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useShopProvider } from '../../components/ShopProvider';
import EventTypeBadge from '../../components/eventTypeBadge';
import DeleteEventType from './editEventType/delete';

import {
  MobileVerticalDotsMajor,
  ArrowUpMinor,
  ArrowDownMinor,
  EditMinor,
  DeleteMinor,
  CircleDisableMinor,
  CircleTickOutlineMinor,

} from '@shopify/polaris-icons';

function MoreMenu({ eventTypeId, eventTypeData, index, length, onDelete, onReorder, onStatus }) {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <Button plain icon={MobileVerticalDotsMajor} onClick={toggleActive}/>
  );

  const actions = [
    {
      content: 'Edit',
      onAction: () => history.push(`/calendar/eventTypes/${eventTypeId}`),
      icon: EditMinor,
    },
  ]

  if (index > 0) {
    actions.push({
      content: 'Move up',
      onAction: () => {
        onReorder(index, index-1)
        toggleActive();
      },
      icon: ArrowUpMinor,
    })
  }

  if (index < length - 1) {
    actions.push({
      content: 'Move down',
      onAction: () => {
        onReorder(index, index+1)
        toggleActive();
      },
      icon: ArrowDownMinor,
    })
  }

  actions.push({
    content: eventTypeData.isActive ? 'Disable' : 'Activate',
    onAction: () => {
      onStatus(eventTypeId, !eventTypeData.isActive);
      toggleActive();
    },
    icon: eventTypeData.isActive ? CircleDisableMinor : CircleTickOutlineMinor,
  });

  actions.push({
    content: 'Delete',
    destructive: true,
    onAction: () => onDelete(),
    icon: DeleteMinor
  });

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={toggleActive}
    >
      <ActionList
        actionRole="menuitem"
        items={actions}
      />
    </Popover>
  );

}

function EventTypeSetttings() {
  const history = useHistory();
  const { eventTypes, eventTypesLoading, shopOrigin } = useShopProvider();
  const [tableData, setTableData] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(false);

  useEffect(()=>{
    if (!eventTypesLoading && eventTypes) {
      const data = eventTypes.docs.map(createTableRow);
      setTableData(data);
  }

  }, [eventTypes, eventTypesLoading])

  function createTableRow(eventType) {
    const data = eventType.data();
    return [
      <EventTypeBadge name={data.name} color={data.color} />,
      data.isActive ? <Badge status={'success'}>active</Badge> : null,
      data.access,
      <MoreMenu
        eventTypeId={eventType.id}
        eventTypeData={data}
        index={eventTypes.docs.findIndex(doc => doc.id === eventType.id)}
        length = {eventTypes.docs.length}
        onDelete={() => onDelete(eventType.id, data.name)}
        onReorder={onReorder}
        onStatus={onStatus}
        />,
    ]
  }

  function onDelete(eventTypeId, eventTypeName ) {
    setEventToDelete({eventTypeId, eventTypeName});
    setShowDeleteDialog(true);
  }

  function onStatus(eventTypeId, status) {
    Firebase.firestore().collection('shops').doc(shopOrigin).collection('scheduleEventTypes').doc(eventTypeId).update({ isActive: status});
  }

  function onReorder(oldIndex, newIndex) {

    let order = eventTypes.docs.map(d => d.id);
    let [elem] = order.splice(oldIndex, 1);
    order.splice(newIndex, 0, elem);
    let batchUpdate = Firebase.firestore().batch();
    for (const [displayIndex, eventId] of order.entries()) {
      batchUpdate.update(Firebase.firestore().collection('shops').doc(shopOrigin).collection('scheduleEventTypes').doc(eventId), { displayIndex });
    }
    batchUpdate.commit().catch(error => console.error('Error reordering event types', error));
  }

  function onCloseDelete() {
    setShowDeleteDialog(false);
    setEventToDelete(null);
  }

  const createAction = () => history.push(`/calendar/eventTypes/create`);

  return (
    <AppPage
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
      title='Event Types'
    >
      <Layout>
        <Layout.Section>
          <Card actions={tableData.length > 0 && [{ content: 'Add event type', onAction: createAction }]}>
            {tableData.length > 0 &&
              <DataTable columnContentTypes={['text', 'text', 'text', 'numeric']} headings={['Name', '', '', '']} rows={tableData} />
            }
            {tableData.length === 0 && !eventTypesLoading && <EmptyState
              heading="Create event types"
              action={{ content: 'Create event type', onAction: createAction }}
            >
              <p>Event types are the services you offer for clients to schedule</p>
            </EmptyState>}
            {eventTypesLoading && <SkeletonBodyText lines={4} />}
          </Card>
        </Layout.Section>
      </Layout>
      <DeleteEventType shopOrigin={shopOrigin} open={showDeleteDialog} onClose={onCloseDelete} eventTypeId={eventToDelete?.eventTypeId} eventTypeName={eventToDelete?.eventTypeName}/>
    </AppPage>
  );
}

export default function () {
  return (
    <AdminPage>
      <EventTypeSetttings />
    </AdminPage>
  )
}
