
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { UserRole } from '../utils/permissions';
export default function useShopHeadlessConfig(shopId, role) {

    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('headless') : null;
    const [headlessConfig, headlessConfigLoading, headlessConfigError] = useDocumentData(ref);

    return { headlessConfig, headlessConfigLoading, headlessConfigError }
}

export function useShopHeadlessActions(shopId) {

    const setHeadlessConfig = async (config) => {
        return Firebase.functions().httpsCallable('headless-setConfig')({ shopId, config });
    }

    return { setHeadlessConfig };
}
