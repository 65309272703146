import React from "react";
import {Heading, Spinner} from "@shopify/polaris";

import './styles.css';

const ChartTitle = ({isLoading = false, title = ''}) => {
  return (
    <div className='charts-title'>
      <Heading>{title}</Heading>
      {isLoading && <Spinner size='small' />}
    </div>
  )
}

export default ChartTitle;
