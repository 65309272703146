
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function useShopInsightsConfig(shopId) {
  
  const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('insights') : null;
  const [insightsConfigData, insightsConfigLoading, insightsConfigError] = useDocumentData(ref);

  const setInsightsConfig = async (data) => {
    let recConsentMessage = Object.keys(data?.recConsentMessage ?? {}).reduce((agg, locale) => {
      agg[locale] = data.recConsentMessage[locale] ? data.recConsentMessage[locale] : Firebase.firestore.FieldValue.delete();
      return agg;
    }, {})
    await ref.set({
      ...data,
      recConsentMessage,
    }
      , { merge: true })

  }
  
  return { insightsConfigData, insightsConfigLoading, insightsConfigError, setInsightsConfig };
}
