import {HOST_AVAILABILITY} from "../constants/const";

export const getRandomColor = () => `#${Math.floor(Math.random() * 10000000).toString(16)}`;

export const availabilityColorByType = (type) => {
  switch (type) {
    case HOST_AVAILABILITY.EVENTS:
      return '#FFBE88'
    case HOST_AVAILABILITY.CALLS:
      return '#6E76F2'
  }
}
