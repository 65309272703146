
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function useShopLink(shopId, callLinkId) {

    const ref = shopId
        ? Firebase.firestore().collection('shops').doc(shopId).collection('permalinks').doc(callLinkId)
        : null;

    const [linkData, linkLoading, linkError] = useDocumentData(ref);

    linkError && console.error(linkError);
    return { linkData, linkLoading, linkError}
}
