import React, { useEffect } from 'react';
import { PreviewStoriesMockup } from '../../../../components/mockup/PreviewStoriesMockup';
import { CloseIcon } from '../../../../components/mockup/PreviewStoriesMockup/icons/close';
import { useShopProvider } from '../../../../components/ShopProvider';
import {
    DEFAULT_COLOR,
    DEFAULT_CORNER_STYLE,
    DEFAULT_FONT_FAMILY
} from '../../../../utils/consts';
import { FontsLoader } from '../../../../utils/fonts-loader';
import './styles.css';

export const StoryPreviewModal = ({ isOpen, setIsOpen, stories, isBoutiqClientStory }) => {
    const { appStylesConfig } = useShopProvider();

    const primaryColor = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.primaryColor) || DEFAULT_COLOR;
    const cornerStyle = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.cornerStyle) || DEFAULT_CORNER_STYLE;
    const fontFamily = appStylesConfig?.clientAppTheme?.fontFamily ?? DEFAULT_FONT_FAMILY;

    useEffect(() => {
        if (fontFamily) {
            FontsLoader(fontFamily, document.body)
        }
    }, [fontFamily])

    return (
        <>
            {isOpen &&
                <>
                    <div className='preview-dialog' style={{ '--main-color': primaryColor }}>
                        <div className='close-preview-container'>
                            <button onClick={() => setIsOpen(false)} className='close-preview-dialog-btn'>
                                <CloseIcon />
                            </button>
                        </div>
                        <PreviewStoriesMockup
                            cornerStyle={cornerStyle}
                            primaryColor={primaryColor}
                            playList={stories}
                            isBoutiqClientStory={isBoutiqClientStory}
                        />
                    </div>
                    <div className='preview-dialog-backdrop'></div>
                </>}
        </>
    )
}
