import {utcStartDay, utcEndDay} from "../../utils/formatters";

export const callsKPIQuery = ({dateFrom, dateTo, tz = 'UTC', hosts= [], eventTypes= []}) => {
  const query = {
    timezone: tz,
    order: {
      'CallsKPI.timestamp': 'desc',
    },
    filters: [
      {
        member: 'CallsKPI.timestamp',
        operator: "inDateRange",
        values: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ]
      },
    ],
    measures: [
      "CallsKPI.count",
      "CallsKPI.duration",
      "CallsKPI.avgDuration",
      "CallsKPI.callsCompleted",
      "CallsKPI.callsFailed",
      "CallsKPI.durationMedian",
      "CallsKPI.durationPercent90",
      "OrdersKPI.count",
      "OrdersKPI.ordersTotal",
      "OrdersKPI.avgOrder",
      "CallsKPI.orderPerCall",
      "CallsKPI.orderPerDuration",
      "CallsKPI.conversionRate",
    ],
  };

  if (hosts && hosts.length > 0) {
    query.filters.push(
      {
        member: "CallsKPI.hostId",
        operator: "equals",
        values: hosts
      })
  }

  if (eventTypes && eventTypes.length > 0) {
    query.filters.push(
      {
        member: "CallsKPI.scheduleEventTypeId",
        operator: "equals",
        values: eventTypes
      })
  }

  return query;
};

export const granularityCallsQuery = ({dateFrom, dateTo}) => {
  const query = {
    timeDimensions: [
      {
        dimension: 'Calls.timestamp',
        granularity: "day",
        dateRange: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ]
      },
    ],
    measures: [
      "Calls.count",
    ],
  };
  return query;
}
