import React from 'react';
import AppPage from "../../components/AppPage";
import AdminPage from "../../components/AdminPage";
import ShowroomPresets from "./components/ShowroomPresets";

function Showrooms() {
    return (
        <AppPage title={'Showrooms'}>
            <ShowroomPresets />
        </AppPage>
    )
}

export default function() {
    return (
        <AdminPage>
            <Showrooms />
        </AdminPage>
    )
}
