import React, {createElement, forwardRef} from 'react';
import { FORM_OBJECT_TYPES } from '../formBuilder/templateFormBuilder';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import './OptionElement.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Select, Stack, TextField } from '@shopify/polaris';

export const OptionElement = ({ disableDateTimePicker, disableDatePicker, disableSelect, element, onValueChange, index }) => {
    const CustomInput = (props, ref) => {
        return (
          <TextField {...props} />
        );
      };

    return (
        <Stack>
            <Stack distribution='fill' wrap={false}>
                {FORM_OBJECT_TYPES.select === element.type &&
                 <Stack alignment='center'>
                    {element.label && <p className='option-label'>{element.label}:</p>}
                    <Select
                        disabled={disableSelect} 
                        value={element.value} 
                        className='option-value' 
                        onChange={(selected) => onValueChange(element.name, selected)} 
                        name={element.name}
                        options={element.values}>
                    </Select>
                </Stack>
                }
                {FORM_OBJECT_TYPES.date === element.type &&
                <Stack alignment='center'>
                    {element.label && <p className='option-label'>{element.label}:</p>}
                    <DatePicker
                        popperClassName={'custom-popper-datepicler-container'}
                        calendarClassName={'custom-datepicler-container'}
                        disabled={disableDatePicker}
                        name={element.name}
                        selected={element.value}
                        onChange={(date) => onValueChange(element.name, date)}
                        minDate={element.minDate}
                        customInput={createElement(forwardRef(CustomInput))}
                        dateFormat='d MMM yyyy'
                        timeIntervals={30}
                    />
                </Stack>
                }
                {FORM_OBJECT_TYPES.dateTime === element.type &&
                    <Stack alignment='center'>
                        {element.label && <p className='option-label'>{element.label}:</p>}
                        <DatePicker
                            popperClassName={'custom-popper-datepicler-container'}
                            calendarClassName={'custom-datepicler-container'}
                            disabled={disableDateTimePicker}
                            name={element.name}
                            selected={element.value}
                            onChange={(date) => onValueChange(element.name, date)}
                            showTimeSelect
                            timeFormat='h:mm aa'
                            minDate={element.minDate}
                            timeIntervals={30}
                            customInput={createElement(forwardRef(CustomInput))}
                            injectTimes={[
                                Moment().set({hour:23,minute:59}).toDate()
                            ]}
                            dateFormat='d MMM yyyy h:mm aa'
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '-85px, 0px'
                              },
                            }}
                        />
                    </Stack>
                }
            </Stack>
        </Stack>
    )
}
