import React from 'react';
import WelcomeLogo from './WelcomeLogo';
import WelcomeMessage from './WelcomeMessage';
import WelcomeHostAvatars from './WelcomeAvatars';
import WelcomeButtons from './WelcomeButtons';
import WelcomeBackground from './WelcomeBackground';
import './index.css'

const IntroWelcomeMockup = ({
    welcome,
    line1,
    line2,
    logoSrc,
    cornerStyle,
    primaryColor,
    primaryButton,
    secondaryButton,
    backgroundImage,
    backgroundColor,
    backgroundOpacity,
    isLogoAvailable,
    showAvatars,
    avatars = [],
    isChatEnabled
}) => {
    return (
        <div style={{'--main-color': primaryColor}} className={`intro-welcome-mockup-container${cornerStyle ? ` ${cornerStyle}` : ''}`}>
            <div style={{ color: primaryColor }} className={`intro-welcome-mockup-border${cornerStyle ? ` ${cornerStyle}` : ''}`}>
            </div>
            <WelcomeBackground
                backgroundImage={backgroundImage}
                backgroundColor={backgroundColor}
                backgroundOpacity={backgroundOpacity}
            />
            {(avatars.length === 0 && showAvatars || (isLogoAvailable && avatars.length > 0)) && <WelcomeLogo src={logoSrc} />}
            {avatars.length > 0 && showAvatars && <div className={`shop-avatars-wrapper${!isLogoAvailable ? ' no-logo' : ''}`}>
                <div className='shop-avatars-content'>
                    <div className='available-hosts'>
                        <WelcomeHostAvatars avatars={avatars} />
                    </div>
                </div>
            </div>}
            <WelcomeMessage
                isLogoAvailable={isLogoAvailable}
                welcome={welcome}
                line1={line1}
                line2={line2} />
            <WelcomeButtons
                isChatEnabled={isChatEnabled}
                primaryButton={primaryButton}
                secondaryButton={secondaryButton}
                primaryColor={primaryColor}
                cornerStyle={cornerStyle} />
        </div>
    );
};

export default IntroWelcomeMockup;
