import React from 'react';

import { Modal, Banner, TextContainer, TextStyle } from '@shopify/polaris';

export default function PermissionsModal({ shopId, userEmail, open, onAction }) {

    return (
        <Modal
            open={open}
            
        >
            <Banner
                status='critical'
                title={"There's a Permissions Problem"}
                action={{ content: 'Sign Out', onAction }}
            >
                <TextContainer>
                    <p>Your user with email <TextStyle variation='strong'>{userEmail}</TextStyle> does not have admin permissions for <TextStyle variation='strong'>{shopId}</TextStyle> on Boutiq.</p>
                    <p>Please contact the store admin.</p>
                    <p>If you were trying to log into the Boutiq host app <a href={process.env.REACT_APP_CAAZAM_HOST_APP} target='_blank' rel="noopener noreferrer"> then click here</a>  </p>
                </TextContainer>
            </Banner>
        </Modal>
    );
}