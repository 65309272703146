export const isInvalid = (value, pattern) => {
  if (!value) {
    return true;
  }
  return !new RegExp(pattern).test(value);
};

export const isEmailValid = (email) => {
  var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
};

export const isStringNonEmpty = (toTest) => {
  if (!toTest) {
    return false;
  }

  var regex = /([^\s])/;
  return regex.test(toTest);
};