import { useEffect, useState, useRef } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { UserRole } from '../utils/permissions';

export default function useCampaigns(shopId, role) {

    const campaignsListener = useRef(null);
    const [campaigns, setCampaigns] = useState([]);
    const [isCampaignsLoading, setCampaignsLoadingState] = useState(true);
    const [campaignsError, setCampaignsError] = useState(null);

    const listenCampaigns = () => {
        return Firebase
            .firestore()
            .collection('shops')
            .doc(shopId)
            .collection('campaigns')
            .orderBy('timestamp', 'desc')
            .onSnapshot(
                (snapshot) => {
                    const data = snapshot.docs.map(item => {
                        const data = item.data();
                        return {
                            ...data,
                            id: item.id,
                            timestamp: data.timestamp || null
                        }
                    });
                    setCampaigns(data);
                    setCampaignsLoadingState(false);
                }, listenError => {
                    console.error('listenCampaigns', listenError);
                    setCampaignsError(listenError);
                    setCampaignsLoadingState(false);

                }
            );
    }

    useEffect(() => {
        if (shopId && role === UserRole.admin) {
            campaignsListener.current = listenCampaigns();
            return () => {
                campaignsListener.current();
            }
        }
    }, [shopId, role])

    return {
        campaigns,
        campaignsLoading: isCampaignsLoading,
        campaignsError,
    }
}

export function useCampaignsActions(shopId) {

    const deleteCampaign = async (campaignId) => {

        await Firebase
            .firestore()
            .collection('shops')
            .doc(shopId)
            .collection('campaigns')
            .doc(campaignId)
            .delete();

        Firebase.analytics().logEvent("admin.campaign_delete");
    }

    const addCampaign = async (campaign) => {

        const docRef = Firebase
            .firestore()
            .collection('shops')
            .doc(shopId)
            .collection('campaigns')
            .doc();

        await docRef.set({
            ...campaign,
            id: docRef.id,
            timestamp: Firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true });

        Firebase.analytics().logEvent("admin.campaign_create");

    }

    const updateCampaign = async (campaign) => {

        await Firebase
            .firestore()
            .collection('shops')
            .doc(shopId)
            .collection('campaigns')
            .doc(campaign.id)
            .set({
                ...campaign
            }, { merge: true });

        Firebase.analytics().logEvent("admin.campaign_update");
    }

    return {
        deleteCampaign,
        addCampaign,
        updateCampaign,
    }
}
