import React, { useState } from 'react';
import { AllHostsItemData } from './components/AllHostsItemData';
import { HostItemData } from './components/HostItemData';
import './styles.css';

export const HostSelectionMarkup = ({
    title = 'Select host',
    subTitle = 'Select your preferred host',
    cornerStyle,
    primaryColor,
    hosts = [],
    anyAvailableEnabled = true,
    disabled
}) => {

    return (
        <div className={`select-host-mockup-container${cornerStyle ? ` ${cornerStyle}` : ''}${disabled ? ' disabled' : ''}`}>
            <div style={{ color: primaryColor }} className={`select-host-mockup-border${cornerStyle ? ` ${cornerStyle}` : ''}`}>
            </div>
            <div className='schedule-header'>
                <h3 className='schedule-title'>
                    {title}
                </h3>
                {subTitle && <p className='schedule-subtitle'>
                    {subTitle}
                </p>}
            </div>
            <div className='host-select-list-wrapper'>
                <div className='select-host-container'>
                    <ul className='select-host-list' style={{ '--main-color': primaryColor }}>
                        {hosts.length > 0 ?
                            <>
                                {hosts.map((host, index) =>
                                    <HostItemData
                                        key={index}
                                        data={host} />)}
                                {hosts.length > 1 && anyAvailableEnabled && <AllHostsItemData
                                    key={'all'}
                                    hosts={hosts}
                                />}
                            </>
                            : <div className='no-hosts-message-container'>
                                <p>
                                    No hosts available
                                </p>
                            </div>}
                    </ul>
                </div>
            </div>
            <div className='select-host-footer'>
                <div className='actions-container'>
                    <button
                        style={{ background: primaryColor }}
                        className={`caazam-button${cornerStyle ? ` ${cornerStyle}` : ''}`}
                        type="button">
                        Select host
                    </button>
                    <span className='secondary-action' >
                        Or leave us a message
                    </span>
                </div>
            </div>
            <div className="caazam-mark">Powered by Boutiq</div>
        </div>
    )
}
