import React, { useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { TextField, Popover, Spinner, Button, Icon, TextStyle, DataTable, Stack } from '@shopify/polaris';
import { useShopProvider } from '../../../../components/ShopProvider';
import { SearchMinor } from '@shopify/polaris-icons';
import { formatEventDate } from '../../utils';
import useShopUsers from '../../../../hooks/useShopUsers';
import { ScheduleStatus } from '../../../../utils/consts';
import EventTypeBadge from '../../../../components/eventTypeBadge';
import { isStringNonEmpty } from '../../../../utils/strings';
import './styles.css';

export const AppointmentSearcher = ({ goToEvent }) => {
    const [value, setValue] = useState('')
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(null);
    const { shopOrigin } = useShopProvider();
    const { hosts } = useShopUsers(shopOrigin);
    const [popoverActive, setPopoverActive] = useState(false);

    const toggleActive = () => setActive((active) => !active);

    const togglePopoverActive = () => {
        setPopoverActive((popoverActive) => !popoverActive);
        setActive(false);
    }

    const appointmentData = (item) => {
        return (
            <div onClick={() => goToEvent(item)} className={`date-time table-section${item.status === ScheduleStatus.deleted ? ' disabled' : ''}`}>
                <TextStyle variation='strong'>{formatEventDate(item.startTimestamp.toDate())}</TextStyle>
            </div>
        )
    }

    const statusMapper = (data) => {
        if (data.status === ScheduleStatus.deleted) {
            if (data.noShow)
                return 'no show'
            else
                return 'canceled'
        }
        return data.status;
    }

    const statusData = (item) => {
        return (
            <div onClick={() => goToEvent(item)} className={`table-section${item.status === ScheduleStatus.deleted ? ' disabled' : ''}`}>
                <TextStyle variation='strong'>{statusMapper(item)}</TextStyle>
            </div>
        )
    }

    const hostData = (item) => {
        const host = hosts.find(host => host.id === item.hostId)
        return (
            <div onClick={() => goToEvent(item)} className={`table-section${item.status === ScheduleStatus.deleted ? ' disabled' : ''}`} >
                <Stack vertical spacing='extraTight'>
                    <TextStyle>
                        {host ? `${host.firstName} ${host.lastName}` : item.hostId}
                    </TextStyle>
                    {item.eventType?.name ?
                        <EventTypeBadge name={item.eventType.name} color={item.eventType.color} />
                        : null}
                </Stack>

            </div>
        )
    }

    const fetchClientAppointments = async (clientEmail) => {
        let emailToSearch = clientEmail.toLowerCase().trim();
        return await Firebase.firestore().collection('shops').doc(shopOrigin).collection('schedule')
            .where('customer.email', '==', emailToSearch)
            .orderBy('startTimestamp', 'desc')
            .limit(20)
            .get();
    }

    const handleKeyPress = async (event) => {
        const enterKeyPressed = event.keyCode === 13;
        if (enterKeyPressed && isStringNonEmpty(value)) {
            event.preventDefault();
            setActive(true)
            setIsLoading(true)
            try {
                const doc = await fetchClientAppointments(value);
                let deletedAppointments = []
                const activeAppointments = doc.docs.reduce((agg, item) => {
                    const data = item.data();
                    if (data.status === ScheduleStatus.deleted) {
                        deletedAppointments = [...deletedAppointments, { ...data, id: item.id }]
                    } else {
                        agg = [...agg, { ...data, id: item.id }]
                    }
                    return agg;
                }, [])

                setResults([...activeAppointments, ...deletedAppointments]);
            } catch (error) {
                console.error('schedule search by email:', error);
                setResults([]);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleOnClick = () => {
        if (value && results) {
            setActive(true);
        }
    }

    const activator = (
        <div className='custom-search-container'>
            <div className={`search-field-wrapper${popoverActive ? ' active' : ''}`}>
                <div className='search-field' onKeyDown={handleKeyPress} onClick={handleOnClick}>
                    <TextField
                        type='search'
                        placeholder='Search by client email'
                        autoComplete='off'
                        value={value}
                        onChange={setValue}
                    />
                </div>
            </div>
            <Button onClick={togglePopoverActive}>
                <Icon
                    source={SearchMinor}
                    color='base'
                />
            </Button>
        </div>
    )

    const CustomActionList = ({ items, isLoading }) => {
        return (
            <div className='list-wrapper'>
                {isLoading ?
                    <div className='list-item loading' style={{ width: '100%' }}>
                        <Spinner size='small' />
                    </div> :
                    items.length > 0 ?
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[]}
                            rows={items.reduce((agg, item) => {
                                return agg = [...agg, [
                                    appointmentData(item),
                                    statusData(item),
                                    hostData(item),
                                ]]
                            }, [])}
                        /> :
                        <div className='list-item loading' style={{ width: '100%' }}>
                            No results found
                        </div>
                }
            </div>
        )
    }

    return (
        <Popover
            fullWidth
            active={active}
            activator={activator}
            autofocusTarget='first-node'
            onClose={toggleActive}
        >
            <CustomActionList
                isLoading={isLoading}
                items={results}
            />
        </Popover>
    )
}
