import Moment from 'moment-timezone';
import { FEEDBACK_TYPE } from './consts';

export const mapCallToCsvTable = (doc) => {
    const callData = doc.data();

    return [
        callData.shopId.toString(),
        callData.timestamp.toDate(),
        callData.customerName,
        callData.customerEmail,
        ...callContext(callData),
        callData.host ? callData.host.id : null,
        callData.host ? callData.host.name || [callData.host.firstName,callData.host.lastName].join(' ') : null,
        callData.eventType?.name ? callData.eventType.eventTypeId : null,
        callData.eventType?.name ? callData.eventType.name : null,
        callData.totalOrderAmount,
        callData.totalOrderAmount
            ? callData.totalOrderAmountCurrency || callData.orders[0].currency
            : null,
        ...feedbackValue(callData)
    ];
}

const feedbackValue = (callData) => {
    let feedback = [null, null, null];
    const feedbackData = callData.callFeedback?.[0] ?? null;
    if (feedbackData) {
        if (feedbackData.csatRating != null) {
                feedback = [feedbackData.csatRating, FEEDBACK_TYPE.CSAT ,feedbackData.message];
        } else if (feedbackData.npsRating != null) {
            feedback = [feedbackData.npsRating, FEEDBACK_TYPE.NPS, feedbackData.message]
        } else if (feedbackData.rating != null) {
            feedback = [feedbackData.rating, FEEDBACK_TYPE.SIMPLE];
        }
    }
    return feedback;
}

export const tableColumnNames = () => {
    let CSVTableHeaders = [
        'Shop Id',
        'Date and time',
        'Client name',
        'Client email',
        'Duration (min)',
        'Call status',
        'Host Id',
        'Host name',
        'Event type Id',
        'Event type name',
        `Sales amount`,
        'Currency',
        'Rating',
        'Rating Type',
        'Feedback message',
    ];
    return [CSVTableHeaders, ...['\n']];
}

const callContext = (callData) => {
    let context = [];
    if (callData.status !== 'error') {
        return context = [Math.ceil(Moment.duration(callData.videoDuration, 'seconds').asMinutes()), null];
    }
    else if (callData.error && callData.error.error) {
        context = [null]
        switch (callData.error.error.code) {
            case 403:
                return context = [context, ...['banned']];
            case 404:
                return context = [context, ...['host unavailable']];
            case 408:
                return context = [context, ...['missed']];
            case 409:
                return context = [context, ...['rejected']];
            case 429:
                return context = [context, ...['over quota']];
            case 499:
                return context = [context, ...['cancelled']];
            default:
                return context = [context, ...['error']];
        }
    } else {
        return context = [null, ...['error']];
    }
}

export const csvBuilder = (dataRows) => {
    const processRow = (row) => {
        let finalVal = '';
        for (let j = 0; j < row.length; j++) {
            let innerValue = row[j] == null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            let result = innerValue.replace(/"/g, '""').replace(', ', ' - ');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    let csvFile = '';

    if (dataRows.length > 0) {
        for (let i = 0; i < dataRows.length; i++) {
            csvFile += processRow(dataRows[i]);
        }
    }

    return csvFile;
}

export const csvLoader = (csvFile, subName = '') => {
    const filename = `boutiq_${subName}${Moment().format('MM/DD/YYYY_h:mm')}.csv`;

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }
    }
}
