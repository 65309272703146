import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Card, Stack, RadioButton, ContextualSaveBar, Button, ActionList, ChoiceList, Popover, Badge } from '@shopify/polaris';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { useAppAuthState } from '../../../authState'
import { TwitterPicker } from 'react-color';
import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import useCaazamREST from '../../../hooks/useCaazamREST'
import { useShopProvider } from '../../../components/ShopProvider';
import ClientAppMockup from '../../../components/mockup/ClientAppMockup';

import {
    DEFAULT_COLOR,
    DEFAULT_CORNER_STYLE,
    COLOR_PICKER_DEFAULTS,
    DEFAULT_FONT_FAMILY
} from '../../../utils/consts';
import { formatUploadFilename } from '../../../utils/formatters';
import { FontSettings } from '../../../components/fontSettings';
import { FontsLoader } from '../../../utils/fonts-loader';


export function isCustomClientTheme(config) {
    if (config && config.clientAppTheme) {
        const clientAppTheme = config.clientAppTheme;
        return (clientAppTheme.primaryColor && clientAppTheme.primaryColor !== DEFAULT_COLOR)
            || (clientAppTheme.cornerStyle && clientAppTheme.cornerStyle !== DEFAULT_CORNER_STYLE)
    } else
        return false
}

function ClientAppThemeSettings() {
    const history = useHistory();
    const { userData } = useAppAuthState();
    const { appStylesConfig, appStylesConfigLoading, shopOrigin } = useShopProvider();
    const [dirtyBit, setDirtyBit] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [primaryColor, setPrimaryColor] = useState(DEFAULT_COLOR);
    const [cornerStyle, setCornerStyle] = useState(DEFAULT_CORNER_STYLE);
    const [customLogo, setCustomLogo] = useState(null);
    const [selectedFont, setSelectedFont] = useState(DEFAULT_FONT_FAMILY);

    const [newLogoFile, setNewLogoFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const fileInput = useRef(null);
    const currentLogo = appStylesConfig && appStylesConfig.customLogo;

    const { clientAppLogoUploadUrl } = useCaazamREST();

    useEffect(() => {
        loadFromConfig(appStylesConfig);
    }, [appStylesConfig]);

    useEffect(() => {
        if (selectedFont) {
            FontsLoader(selectedFont, document.body)
        }
    }, [selectedFont])

    useEffect(() => {
        if (currentLogo) {
            displayCurrentLogo();
        }
    }, [currentLogo])

    useEffect(() => {
        if (newLogoFile) {
            let reader = new FileReader();
            reader.readAsDataURL(newLogoFile);
            reader.onloadend = (e) => {
                setImageSrc(reader.result);
            }
        } else {
            setImageSrc(null);
        }

    }, [newLogoFile])

    const displayCurrentLogo = () => {
        const fileRef = Firebase.storage().refFromURL(currentLogo);
        fileRef.getDownloadURL()
            .then(url => setImageSrc(url))
            .catch(error => {
                console.error(error.code || error);
            })
    }

    const handleChange = useCallback(
        (_checked, newValue) => {
            setCornerStyle(newValue);
            setDirtyBit(true);
        }, []);

    const onColorChange = (color) => {
        setPrimaryColor(color);
        setDirtyBit(true);
    }

    const loadFromConfig = (config) => {
        setPrimaryColor(config?.clientAppTheme?.primaryColor ?? DEFAULT_COLOR);
        setCornerStyle(config?.clientAppTheme?.cornerStyle ?? DEFAULT_CORNER_STYLE);
        setSelectedFont(config?.clientAppTheme?.fontFamily ?? DEFAULT_FONT_FAMILY);
        setCustomLogo(config?.customLogo ?? null);
    }

    const handleUploadButtonClick = () => {
        fileInput.current.click();
    }

    const handleNewLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            const targetName = formatUploadFilename(file.name);
            const logoUrl = `${process.env.REACT_APP_CONFIG_BUCKET}/${shopOrigin}/online_store/custom_logo/${targetName}`;
            setCustomLogo(logoUrl)
            setNewLogoFile(file);
            setDirtyBit(true);
        }
    }

    const removeLogo = () => {
        setCustomLogo(null);
        setNewLogoFile(null);
        setImageSrc(null);
        setDirtyBit(true);
    }

    const save = useCallback(async () => {
        setSaving(true);
        try {
            if (newLogoFile) {
                await uploadLogo();
            }

            let newConfig = {
                clientAppTheme: {
                    primaryColor,
                    cornerStyle,
                    fontFamily: selectedFont !== DEFAULT_FONT_FAMILY ? selectedFont : Firebase.firestore.FieldValue.delete(),
                },
                customLogo,
            }

            await Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('appTheme').set(newConfig, { merge: true });
        } catch (error) {
            console.error('Failed to config app', error);
        } finally {
            setSaving(false);
            setDirtyBit(false);
        }
    }, [newLogoFile, primaryColor, cornerStyle, customLogo, selectedFont])

    const onDiscard = () => {
        loadFromConfig(appStylesConfig);
        setDirtyBit(false);
        setNewLogoFile(null);
        displayCurrentLogo();
    }

    const uploadLogo = useCallback(async () => {
        try {

            const fileName = customLogo.split('/').slice(-1)[0];
            const { uploadUrl } = await clientAppLogoUploadUrl({ fileName, contentType: newLogoFile.type });
            await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': newLogoFile.type,
                },
                body: newLogoFile,
            })
        } catch (error) {
            console.error('Failed to upload file', error);
        }

    }, [customLogo, newLogoFile]);


    const onFontFamilyChange = (e) => {
        setSelectedFont(e);
        setDirtyBit(true);
    }

    return (
        <>
            <AppPage
                breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
                title='Customize Client App Theme'
            >
                <Layout>
                    <Layout.Section >
                        <Card sectioned title='Preview' subdued>
                            <Stack spacing='extraLoose' distribution='center'>
                                <ClientAppMockup
                                    cornerStyle={cornerStyle}
                                    primaryColor={primaryColor}
                                    userName={userData && userData.firstName}
                                />
                            </Stack>
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        {!appStylesConfigLoading && <Card>
                            <Card.Section title='color'>
                                <Stack vertical spacing='tight'>
                                    <p>Accent color</p>
                                    <TwitterPicker
                                        triangle='hide'
                                        color={primaryColor}
                                        onChangeComplete={(primaryColor) => onColorChange(primaryColor.hex)}
                                        colors={COLOR_PICKER_DEFAULTS} />
                                </Stack>
                            </Card.Section>
                            <Card.Section title='corner style'>
                                <Stack vertical>
                                    <RadioButton
                                        label="Sharp"
                                        checked={cornerStyle === 'sharp'}
                                        id="sharp"
                                        name="app_corner_style"
                                        onChange={handleChange}
                                    />
                                    <RadioButton
                                        label="Round"
                                        id="round"
                                        name="app_corner_style"
                                        checked={cornerStyle === 'round'}
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </Card.Section>
                            <Card.Section title='logo'>
                                <Stack vertical spacing='loose'>
                                    {imageSrc && <img src={imageSrc} alt='custom logo' style={{ width: '100%', maxHeight: '60px', objectFit: 'contain' }} />}
                                    <Stack spacing='tight' alignment='center'>
                                        <Button onClick={handleUploadButtonClick}>Choose File</Button>
                                        <input ref={fileInput} type="file" accept="image/jpeg,image/png" onChange={handleNewLogo} style={{ display: 'none' }} />
                                        {imageSrc && <Button plain onClick={removeLogo}>Remove</Button>}
                                    </Stack>
                                </Stack>
                            </Card.Section>
                            <Card.Section title='Client font settings'>
                                <FontSettings
                                    selectedFont={selectedFont}
                                    onFontFamilyChange={onFontFamilyChange} />
                            </Card.Section>
                        </Card>}
                    </Layout.Section>
                </Layout>
            </AppPage>

            {dirtyBit && <ContextualSaveBar
                message="Save custom client app theme?"
                saveAction={{
                    onAction: save,
                    loading: isSaving,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: isSaving,
                }}
            />}
        </>
    );
}

export default function () {
    return (
        <AdminPage>
            <ClientAppThemeSettings />
        </AdminPage>
    )
}
