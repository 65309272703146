import React, {useState, useEffect} from 'react';
import {Button, Stack, TextField} from '@shopify/polaris';
import {DateRange} from '../../Dashboard/useDateRange';
import 'firebase/compat/analytics';
import {DEFAULT_EVENT_TYPE_FILTER, DEFAULT_HOST_FILTER} from "../../../constants/analytics";
import {CancelMajor, ExportMinor, SearchMajor} from "@shopify/polaris-icons";
import './styles.css'
import CombinedSelector from "../../../components/Selectors/CombinedSelector";

export default function CallHistoryFilter({onChangeFilter, onRefresh, onExport, isExportInProgress, defaultFilter, hideExport}) {
  const [selectedHost, setSelectedHost] = useState(DEFAULT_HOST_FILTER);
  const [selectedEvent, setSelectedEvent] = useState(DEFAULT_EVENT_TYPE_FILTER);
  const [selectedRange, setSelectedRange] = useState(DateRange.last30);
  const [searchValue, setSearchValue] = useState('');

  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    onRefresh();
  }, [selectedHost, selectedEvent, selectedRange]);

  useEffect(() => {
    if (!showSearchBar) {
      onRefresh();
    }
  }, [showSearchBar])

  const toggleSearchBar = () => {
    setSelectedHost(DEFAULT_HOST_FILTER)
    setSelectedEvent(DEFAULT_EVENT_TYPE_FILTER)
    setSelectedRange(DateRange.last30)
    setSearchValue('')
    if (showSearchBar) {
      onChangeFilter(defaultFilter)
      setShowSearchBar(false)
    } else {
      onChangeFilter({...defaultFilter, range: null})
      setShowSearchBar(true)
    }
  }

  const onChangeHost = (host) => {
    setSelectedHost(host);
    onChangeFilter(prev => ({
      ...prev,
      hostId: host.id !== DEFAULT_HOST_FILTER.id ? host.id : null
    }))
  }

  const onChangeEvent = (event) => {
    setSelectedEvent(event);
    onChangeFilter(prev => ({
      ...prev,
      eventId: event.id !== DEFAULT_EVENT_TYPE_FILTER.id ? event.id : null
    }))
  }

  const onChangeRange = (range) => {
    setSelectedRange(range)
    onChangeFilter(prev => ({
      ...prev,
      range
    }))
  }

  const onChangeSearch = (search) => {
    setSearchValue(search)
    onChangeFilter(prev => ({
      ...prev,
      search
    }))
  }

  const onHandleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onRefresh();
    }
  }

  return (
    <div className={'call-history-filter-container'}>
      <Stack alignment={'center'} distribution={'equalSpacing'}>
        <Stack>
          <div className={`host-search-wrapper ${showSearchBar ? '' : 'hide'}`} onKeyDown={onHandleKeyPress}>
            <TextField
              label={'Email'}
              placeholder={'Filter by client email'}
              type={'email'}
              value={searchValue}
              onChange={onChangeSearch}
              autoComplete={'off'}
              labelHidden
              connectedRight={(
                <Button icon={showSearchBar ? CancelMajor : SearchMajor} onClick={toggleSearchBar}/>
              )}
            />
          </div>
          <div className={`call-history-filters-wrapper ${showSearchBar ? 'hide' : ''}`}>
            <CombinedSelector
              maxMenuHeight={200}
              selectedHost={selectedHost}
              onChangeHost={onChangeHost}
              selectedEvent={selectedEvent}
              onChangeEvent={onChangeEvent}
              selectedRange={selectedRange}
              onChangeRange={onChangeRange}
              onRefresh={onRefresh}
            />
          </div>
        </Stack>
        {!hideExport && (
          <Button
            loading={isExportInProgress}
            removeUnderline
            plain
            monochrome
            onClick={onExport}
            icon={ExportMinor}
            disabled={!onExport}
          >
            Export
          </Button>
        )}
      </Stack>
    </div>
  )
}
