import React from 'react';
import {TextField, Button, Stack} from '@shopify/polaris';
import {CancelSmallMinor} from '@shopify/polaris-icons';

import './styles.css';

export default function QueryParamBlock({deleteRule, ruleId, queryParameterName, setQueryParameterName, queryParameterValue, setQueryParameterValue, queryParamNameError, queryParamValueError}) {

  const deleteCurrentRule = () => deleteRule(ruleId);

  return (
    <Stack alignment='leading' spacing='extraTight'>
      <div className='query-param-block-label'>
        <p>URL Query</p>
      </div>
      <TextField value={queryParameterName} onChange={setQueryParameterName} error={queryParamNameError} placeholder={'Param name'} />
      <Stack.Item fill>
        <TextField value={queryParameterValue} onChange={setQueryParameterValue} error={queryParamValueError} placeholder={'Param value'} />
      </Stack.Item>
      <Button icon={CancelSmallMinor} onClick={deleteCurrentRule}></Button>
    </Stack>
  );
}
