import React, { useEffect, useState } from 'react'

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import { Card, Banner, TextStyle, Avatar, Stack, Button, Caption, Modal, TextContainer, SkeletonBodyText, Select, Text, Spinner } from '@shopify/polaris';
import { useShopProvider } from './ShopProvider';
import { useAppAuthState } from '../authState'
import { hasAdminAppPermissions, UserRole, getAdminAppPermission } from '../utils/permissions';

const adminRoleOptions = [{ label: 'Full admin', value: UserRole.admin }, { label: 'Calendar admin', value: UserRole.calendarAdmin }];

function AddAdminModal({ shopId, open, onClose, adminUserOptions }) {
    const [adminUserToAdd, setAdminUserToAdd] = useState(null);
    const [newAdminRole, setNewAdminRole] = useState(UserRole.admin);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        setAdminUserToAdd(null);
        setNewAdminRole(UserRole.admin);
    }, [open]);

    const addAdmin = async () => {
        if (!adminUserToAdd) return;
        setIsAdding(true);
        try {
            await Firebase.firestore().collection('shops').doc(shopId).collection('shopUsers').doc(adminUserToAdd).update({
                roles: Firebase.firestore.FieldValue.arrayUnion(newAdminRole),
                updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            })
        } catch (addError) {
            console.error('Failed to add admin', addError);
        }
        onClose();
        setIsAdding(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Add admin user'
            primaryAction={{
                content: 'Add',
                onAction: addAdmin,
                loading: isAdding,
                disabled: !adminUserToAdd || isAdding || adminUserToAdd == 'please_select',
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: onClose,
                    disabled: isAdding,
                },
            ]}
        >
            <Modal.Section>
                <Stack vertical>
                    <TextContainer spacing='tight'>
                        <p>Please select an exsiting user that should be assigned to an admin role</p>
                        <p>You can also add a new user with an admin role using the "Invite" button</p>
                    </TextContainer>
                    <Select
                        options={adminUserOptions}
                        onChange={setAdminUserToAdd}
                        value={adminUserToAdd || 'please_select'}
                    />
                    <Select
                        options={adminRoleOptions}
                        onChange={setNewAdminRole}
                        value={newAdminRole}
                    />
                    {newAdminRole === UserRole.calendarAdmin
                        ? <Banner status='warning'>user will only have permissions to manage the store's calendar</Banner>
                        : <Banner status='warning'>user will have full admin permissions for your store's Boutiq app</Banner>
                    }
                </Stack>
            </Modal.Section>
        </Modal>
    )
}

function ConfirmAdminUser({ shopId, open, onClose, user, role, isWorking, setIsWorking }) {

    const updateAdmin = async () => {
        try {
            setIsWorking(true);
            await Firebase.firestore().runTransaction(async t => {
                let ref = Firebase.firestore().collection('shops').doc(shopId).collection('shopUsers').doc(user.id);
                let current = await t.get(ref);
                let { roles } = current.data();
                if (roles?.includes(role)) return;
                let newRoles = [role];
                for (const oldRole of roles) {
                    if (role === UserRole.admin && oldRole !== UserRole.calendarAdmin) {
                        newRoles.push(oldRole);
                    } else if (role === UserRole.calendarAdmin && oldRole !== UserRole.admin) {
                        newRoles.push(oldRole);
                    }
                }
                await t.update(ref, {
                    roles: newRoles,
                    updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                })

            });
        } catch (updateError) {
            console.error('Failed to update admin', updateError);
        }
        onClose();
        setIsWorking(false);
    }

    const roleName = adminRoleOptions.find(r => r.value === role)?.label;

    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Update admin role'
            primaryAction={{
                content: 'Update',
                onAction: updateAdmin,
                loading: isWorking,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: onClose,
                    disabled: isWorking,
                },
            ]}
        >
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <p>Please confirm udpating admin <TextStyle variation="strong">{user.firstName} {user.lastName} </TextStyle> ({user.email}){' '} to {roleName?.toLowerCase()}</p>
                    {role === UserRole.calendarAdmin
                        ? <Banner status='warning'>{user.firstName} will have permissions to manage the store's calendar</Banner>
                        : <Banner status='warning'>{user.firstName} will have full admin permissions for your store's Boutiq app</Banner>
                    }
                </Stack>
            </Modal.Section>
        </Modal>)
}

function DeleteAdminUser({ shopId, open, onClose, user, isWorking, setIsWorking }) {

    const deleteAdmin = async () => {
        setIsWorking(true);
        try {
            await Firebase.firestore().collection('shops').doc(shopId).collection('shopUsers').doc(user.id).update({
                roles: Firebase.firestore.FieldValue.arrayRemove(UserRole.admin, UserRole.calendarAdmin),
                updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            })
        } catch (deleteError) {
            console.error('Failed to delete admin', deleteError);
        }
        onClose();
        setIsWorking(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Remove admin user'
            primaryAction={{
                content: 'Remove',
                onAction: deleteAdmin,
                destructive: true,
                loading: isWorking,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: onClose,
                    disabled: isWorking,
                },
            ]}
        >
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <p>Are you sure you want to remove admin <TextStyle variation="strong">{user.firstName} {user.lastName} </TextStyle> ({user.email}){' '}?</p>
                    <Banner status='warning'>{user.firstName} won't be able to manage your Boutiq app</Banner>
                </Stack>
            </Modal.Section>
        </Modal>)
}

export function AdminItem({ admin }) {

    const { shopOrigin } = useAppAuthState();
    const [working, setWorking] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [newAdminRole, setNewAdminRole] = useState(null);

    const isInstaller = !!admin.installKey;

    return (
        <div style={{ paddingBottom: '0.8rem' }}>
            <Stack alignment='center'>
                <Avatar source={admin.avatarUrl ? admin.avatarUrl : null} name={admin.firstName} initials={`${admin.firstName && admin.firstName[0]}${admin.lastName && admin.lastName[0]}`} />
                <Stack.Item fill>
                    <Stack distribution='leading' alignment='center'>
                        <Stack.Item fill>
                            <div>
                                <h3>
                                    <TextStyle variation="strong">{admin.firstName} {admin.lastName}</TextStyle><Caption>{admin.email}</Caption>
                                </h3>
                            </div>
                        </Stack.Item>
                        {!isInstaller && !working &&
                            <Select
                                options={adminRoleOptions}
                                onChange={(newVal) => {
                                    setNewAdminRole(newVal)
                                    setUpdateModalOpen(true)
                                }}
                                value={getAdminAppPermission(admin.roles)}
                            />
                        }
                        {isInstaller && !working &&
                            <Text>Full admin</Text>
                        }
                        {!isInstaller && working && <Spinner size='small' />}
                        {<Button plain destructive={!isInstaller} disabled={working || isInstaller} onClick={() => setRemoveModalOpen(true)}>remove</Button>}
                        <DeleteAdminUser
                            shopId={shopOrigin}
                            open={removeModalOpen}
                            onClose={() => setRemoveModalOpen(false)}
                            user={admin}
                            isWorking={working}
                            setIsWorking={setWorking}
                        />
                        <ConfirmAdminUser
                            shopId={shopOrigin}
                            open={updateModalOpen}
                            onClose={() => setUpdateModalOpen(false)}
                            user={admin}
                            role={newAdminRole}
                            isWorking={working}
                            setIsWorking={setWorking}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    )
}




export default function Admins({ }) {
    const {
        users, usersLoading, usersError, shopOrigin,
    } = useShopProvider();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const admins = users.filter(user => hasAdminAppPermissions(user.roles));
    const nonAdmins = users.filter(user => !hasAdminAppPermissions(user.roles));

    const title = !usersLoading
        ? `Admins`
        : 'Loading Hosts';

    const actions = nonAdmins.length > 0 ? [{ content: 'Add admin', onAction: () => setAddModalOpen(true) }] : [];
    const adminUserOptions = [{ label: 'select user', value: 'please_select' }, ...nonAdmins.map(user => ({ label: `${user.firstName} ${user.lastName} (${user.email})`, value: user.id }))]


    return (
        <>
            <Card sectioned title={title} actions={actions}>
                {usersError && <Banner
                    title={usersError.message}
                    status='critical'
                />}
                {usersLoading && <SkeletonBodyText lines={4} />}
                {!usersLoading && admins.map((admin) => (
                    <AdminItem admin={admin} key={admin.id} />
                ))}
            </Card>
            <AddAdminModal
                open={addModalOpen}
                onClose={() => setAddModalOpen(false)}
                adminUserOptions={adminUserOptions}
                shopId={shopOrigin}
            />

        </>
    )
}
