import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';

import Moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import {Layout, Card, SkeletonBodyText} from '@shopify/polaris';

import {
    DetailedPopUpMajor
  } from '@shopify/polaris-icons';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'

import DashboardFilter from './DashboardFilter'
import DashboardHeader from './DashboardHeader'
import useDateRange, { DateRange } from './useDateRange';
import {KPIObject, cubeTimeSeriesDataMapper, cubeAggKPIDataMapper} from './useDataset';
import { useShopProvider} from '../../components/ShopProvider'

import CaazamAppWarnings from '../../components/AppWarnings';
import {currencyFormatter, percentFormatter, numberFormatter, formatHostNameForCharts} from '../../utils/formatters';
import './Dashboard.css';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
    ComposedChart,
    ReferenceLine,
    Cell,
} from 'recharts';

import {useCubeQuery} from "@cubejs-client/react";
import {callsQuery} from '../../cubeManager/queries/calls';
import {callsEventTypesQuery} from '../../cubeManager/queries/callsEventTypes';
import {callsKPIQuery, granularityCallsQuery} from '../../cubeManager/queries/callsKPI';
import {feedbackQuery} from '../../cubeManager/queries/feedback';
import {callEventsQuery} from '../../cubeManager/queries/callEvents';
import {CHARTS_LABELS, COMPARE_TYPES, DEFAULT_EVENT_TYPE_FILTER, DEFAULT_HOST_FILTER, NO_TYPE} from "../../constants/analytics";
import {getRandomColor} from "../../utils/colors";
import CustomizedDateAxisTick from "../../components/charts/CustomizedDateAxisTick";
import CustomizedHostAxisTick from "../../components/charts/CustomizedHostAxisTick";
import CustomizedEventTypeAxisTick from "../../components/charts/CustomizedEventTypeAxisTick";
import ChartTitle from "../../components/charts/ChartTitle";
import ChartModal from "../../components/charts/ChartModal";
import { FEEDBACK_TYPE } from '../../utils/consts';
import { useCubeProvider } from '../../cubeManager';
import CallFeedbackList from '../CallFeedback/CallFeedbackList';

const getDataPointsArray = (startDate, endDate) => {
    var arr = [];
    var dt = Moment(startDate);
    while (dt.isSameOrBefore(endDate)) {
        arr.push({
            'Calls.date': dt.format('YYYY-MM-DD'),
            'Calls.count': 0,
            'Calls.callsFailed': 0,
            'Orders.count': 0,
            'Orders.ordersTotal': 0,
            'Calls.callsCompleted': 0,
            'Calls.avgDuration': 0,
            'Calls.duration': 0,
        });
        dt = dt.add(1, 'day');
    }
    return arr;
}

function Dashboard() {

    const { hosts, shopUserTeams, shopDataLoading, shopData, shopOrigin, feedbackConfig, eventTypes } = useShopProvider();
    const feedbackType = feedbackConfig?.type ?? FEEDBACK_TYPE.CSAT;
    const [range, setRange] = useState(DateRange.last30);
    const [filterType, changeFilterType] = useState('all');
    const [cubeQueries, setCubeQueries] = useState(null);
    const [host, setHost] = useState(DEFAULT_HOST_FILTER);
    const [eventType, setEventType] = useState(DEFAULT_EVENT_TYPE_FILTER);
    const [aggKPIResult, setAggKPIResult] = useState(null);
    const [eventTypesKeys, setEventTypesKeys] = useState([]);
    const [shopEventTypesList, setEventTypesList] = useState([]);
    const [startDate, endDate, previousStart, previousEnd] = useDateRange(range);
    const [mergedData, setMergedData] = useState(getDataPointsArray(startDate, endDate));
    const {cubeOptions, onCubeError} = useCubeProvider()

    const chartModalContent = useRef(null);
    const [isChartModelOpened, showChartModal] = useState(false);
    const [chartModalTitle, setChartModalTitle] = useState(null);

    const history = useHistory();

    const initCubeListener = useCallback(({action, period, comparisonHost, granularity, comparisonEventTypes, useEventTypes, feedbackType }) => {
        const query = {
            dateFrom: startDate,
            dateTo: endDate,
            tz: shopData.ianaTimezone,
            granularity,
            feedbackType,
            ...period
        };

        if (comparisonHost) {
            query.hosts = hosts.map(host => host.id);
            query.eventTypes = [];
            query.hostsComparison = true;
            query.eventTypesComparison = false;
        } else if (comparisonEventTypes) {
            query.hosts = [];
            query.hostsComparison = false;
            query.eventTypesComparison = true;
            query.eventTypes = [];
        }

        if (useEventTypes) {
            query.eventTypes = eventType.value !== 'all' ? [eventType.value] : [];
        }

        if (host.id !== "all") {
            query.hosts = host.optionValue;
        }

        if (eventType.id !== "all") {
            query.eventTypes = [eventType.value];
        }
        return action(query)
    },[startDate, endDate, shopData.ianaTimezone, host.id, eventType.id]);

    useEffect(() => {
        if (eventTypes?.size) {
            const formattedEventTypes = eventTypes.docs.map(et => {
                return {
                    label: et.data().name,
                    value: et.id,
                    id: et.id,
                }
            })
            setEventTypesList([DEFAULT_EVENT_TYPE_FILTER].concat(NO_TYPE).concat(formattedEventTypes));
        }
    }, [eventTypes])

    useEffect(() => {
        let queries = [];
        if (filterType === COMPARE_TYPES.HOSTS) {
            queries = [
                initCubeListener({action: callsQuery, comparisonHost: true}),
                initCubeListener({action: callEventsQuery, comparisonHost: true}),
                initCubeListener({action: feedbackQuery, comparisonHost: true, granularity: 'day', feedbackType }),
                initCubeListener({action: callsEventTypesQuery, comparisonHost: true})
            ];
        } else if (filterType === COMPARE_TYPES.EVENT_TYPES) {
            queries = [
              initCubeListener({action: callsQuery, comparisonEventTypes: true}),
              initCubeListener({action: callEventsQuery, comparisonEventTypes: true}),
              initCubeListener({action: feedbackQuery, comparisonEventTypes: true, granularity: 'day', feedbackType}),
              initCubeListener({action: callsEventTypesQuery, comparisonEventTypes: true})
            ]
        } else {
            queries = [
                initCubeListener({action: callsQuery}),
                initCubeListener({action: callEventsQuery}),
                initCubeListener({action: feedbackQuery, granularity: 'day', feedbackType}),
                initCubeListener({action: callsEventTypesQuery}),
            ];
        }
        setCubeQueries(queries);
    }, [filterType, host, startDate, endDate, shopData.ianaTimezone, eventType])

    const aggKPI = useCubeQuery([
      initCubeListener({action: callsKPIQuery}),
      initCubeListener({action: callsKPIQuery, period: {dateFrom: previousStart, dateTo: previousEnd}}),
      initCubeListener({action: granularityCallsQuery}), // @Roman: what is this query for?
      initCubeListener({action: feedbackQuery, granularity: null, useEventTypes: true, feedbackType}),
      initCubeListener({action: feedbackQuery, granularity: null, period: {dateFrom: previousStart, dateTo: previousEnd}, useEventTypes: true, feedbackType,}),
    ], cubeOptions);

    const {resultSet, error, isLoading} = useCubeQuery(cubeQueries, cubeOptions);

    useEffect(() => {
        const err = error ? error : aggKPI.error;
        onCubeError(err)
    }, [error, aggKPI.error])

    useEffect(() => {
        if (resultSet) {
            const {dataset, eventTypesKeys} = cubeTimeSeriesDataMapper(resultSet, startDate, endDate, getDataPointsArray, shopData.currencyCode, filterType, hosts, eventTypes);
            setMergedData(dataset);
            setEventTypesKeys(eventTypesKeys);
        }
    }, [resultSet])

    useEffect(() => {
        if (aggKPI.resultSet) {
            const output = cubeAggKPIDataMapper(filterType, aggKPI.resultSet, shopData.currencyCode, feedbackType);
            if (output !== null) {
                setAggKPIResult(output);
            }
        }
    }, [aggKPI.resultSet, feedbackType])

    const chartCurrencyFormatter = (value) => currencyFormatter(shopData.currencyCode).format(value)
    const minutesFormatter = (decimals) => (value) => (value / 60).toFixed(decimals || 0);
    const chartPercentFormatter = (value) => percentFormatter().format(value);
    const chartNumberFormatter = (value) => numberFormatter().format(value);

    const FeedbackTooltipFormatter = (label) => (value, name, props) => ([chartNumberFormatter(value),label]);
    const XAxisDateChart = () => <XAxis key='xaxis' dataKey='Calls.date' height={80} tick={<CustomizedDateAxisTick />} interval={parseInt(mergedData.length / 15)} />;
    const XAxisHostChart = () => <XAxis key='xaxis' dataKey='Calls.hostId' height={80} tick={<CustomizedHostAxisTick hosts={hosts} />} interval={parseInt(mergedData.length / 15)} />;
    const XAxisEventTypesChart = () => <XAxis key='xaxis' dataKey='CallsEventTypes.name' height={80} tick={<CustomizedEventTypeAxisTick />} interval={parseInt(mergedData.length / 15)} />;

    const customOrdersTooltip = (value, name, props) => {
        switch (props.dataKey) {
            case 'Orders.ordersTotal':
                return currencyFormatter(shopData.currencyCode).format(value);
            case 'Orders.count':
                return numberFormatter().format(value);
            default:
                return '';
        }
    }

    const openChartModal = (title, children) => {
        chartModalContent.current = children;
        showChartModal(true);
        setChartModalTitle(title);
    }

    const closeChartModal = () => {
        chartModalContent.current = null;
        showChartModal(false);
        setChartModalTitle(null);
    }

    const renderChart = ({children = null, title = ''}) => {
        return (
          <Layout.Section oneHalf>
              <Card title={<ChartTitle title={title} isLoading={isLoading}/>} actions={[{
                  icon: DetailedPopUpMajor,
                  onAction: () => openChartModal(title, children),
                  disabled: isLoading,
              }]}>
                  <ResponsiveContainer width="100%" height={300}>
                      {children}
                  </ResponsiveContainer>
              </Card>
          </Layout.Section>
        );
    }

    const renderBarChart = ({bars = [], key = 'Calls.date'}) => {
        return (
          <BarChart
            data={mergedData}
            margin={{top: 32, right: 16, left: 16, bottom: 8}}
          >
              <CartesianGrid strokeDasharray="3 3" />
              {bars.map(bar => bar)}
          </BarChart>
        );
    }

    const renderComposedChart = ({bars = [], key = 'Calls.date'}) => {
        return (
          <ComposedChart
            data={mergedData}
            margin={{top: 32, right: 16, left: 16, bottom: 8}}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis yAxisId='amount' tickFormatter={chartCurrencyFormatter}/>
              <YAxis yAxisId='count' tickFormatter={chartNumberFormatter} orientation='right'/>
              {bars.map(bar => bar)}
          </ComposedChart>
        );
    }

    const renderLineChart = ({bars = [], key = 'Calls.date'}) => {
        return (
          <LineChart
            data={mergedData}
            margin={{top: 32, right: 16, left: 16, bottom: 8}}
          >
              <CartesianGrid strokeDasharray="3 3" />
              {bars.map(bar => bar)}
          </LineChart>
        );
    }

    const setFilterType = (value) => {
        changeFilterType(value);
        if (value === COMPARE_TYPES.HOSTS) {
            setHost(DEFAULT_HOST_FILTER);
        } else if (value === COMPARE_TYPES.EVENT_TYPES) {
            setEventType(DEFAULT_EVENT_TYPE_FILTER);
        }
    }

    const updateCurrentHost = (value, type) => {
        if (filterType === COMPARE_TYPES.HOSTS) {
            changeFilterType('all');
        }
        setHost(value);
    }

    const updateEventType = (value, type) => {
        if (filterType === COMPARE_TYPES.EVENT_TYPES) {
            changeFilterType('all');
        }
        setEventType(value);
    }

    const buildEventTypeRenderEntity = (key, params) => {
        if (key === null) {
            return {...params, color: `#808080`, name: '- none -', id: key};
        }

        if (key && params) {
            return {...params, id: key}
        }

        if (key) {
            return {...params, color: getRandomColor(), name: key, id: key}
        }
    }

    const generateEventTypesBars = () => {
        const bars = [];
        if (filterType === COMPARE_TYPES.EVENT_TYPES) {
            bars.push(
              <Bar
                key={`CallsEventTypes.count`}
                dataKey={`CallsEventTypes.count`}
                stackId='a'
                name={`Count`}
                fill={`#FFBE88`}>
                  {
                      mergedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry['CallsEventTypes.color']} />
                      ))
                  }
              </Bar>
            );
        } else {
            eventTypesKeys.forEach(key => {
                const eventParameters = eventTypes?.docs.find(et => {
                    if (et?.id === key) {
                        return et;
                    }
                    return null;
                });
                const parsedEventType = buildEventTypeRenderEntity(key, eventParameters?.data());
                bars.push(
                  <Bar
                    key={`CallsEventTypes.${parsedEventType?.id}.count`}
                    dataKey={`CallsEventTypes.${parsedEventType?.id}.count`}
                    stackId='a'
                    name={parsedEventType?.name}
                    fill={parsedEventType?.color}
                  />
                );
            });
        }
        return bars;
    }

    const renderXAxis = () => {
        if (filterType === COMPARE_TYPES.HOSTS) {
            return XAxisHostChart();
        }
        return XAxisEventTypesChart();
    }

    const renderTooltipLabel = (label) => formatHostNameForCharts(filterType, hosts, label);

    const renderFeedbackChart = () => {
        switch (feedbackType) {
            case FEEDBACK_TYPE.NPS:
                {
                    return renderChart({
                        title: CHARTS_LABELS.NPS_SCORE,
                        children: renderBarChart({
                            bars: [
                                XAxisDateChart(),
                                <YAxis key='nps_per_day_yaxis' type='number' />,
                                <Tooltip key='nps_feedback_per_day_tooltip' formatter={FeedbackTooltipFormatter('NPS Score')} />,
                                <ReferenceLine key='nps_ref_line' y={0} stroke="#8d8d8d" />,
                                <Bar key='nps_feedback_per_day_bad' dataKey="Nps.score_bad" fill="#f06e63" stackId="stack" />,
                                <Bar key='nps_feedback_per_day_warning' dataKey="Nps.score_warning" fill="#f9b63e" stackId="stack" />,
                                <Bar key='nps_feedback_per_day_good' dataKey="Nps.score_good" fill="#3dc373" stackId="stack" />,
                                <Bar key='nps_feedback_per_day_exelent' dataKey="Nps.score_excellent" fill="#20ab58" stackId="stack" />
                            ]
                        })
                    })
                }
            case FEEDBACK_TYPE.CSAT:
                {
                    return renderChart({
                        title: CHARTS_LABELS.CSAT_SCORE,
                        children: renderBarChart({
                            bars: [
                                XAxisDateChart(),
                                <YAxis key='csat_per_day_yaxis' type='number' domain={[0,100]}/>,
                                <Tooltip key='csat_feedback_per_day_tooltip' formatter={FeedbackTooltipFormatter('CSAT Score')} />,
                                <Bar key='csat_feedback_per_day_bad' dataKey="Csat.score_bad" fill="#f06e63" stackId="stack" />,
                                <Bar key='csat_feedback_per_day_warning' dataKey="Csat.score_warning" fill="#f9b63e" stackId="stack" />,
                                <Bar key='csat_feedback_per_day_good' dataKey="Csat.score_good" fill="#3dc373" stackId="stack" />,
                                <Bar key='csat_feedback_per_day_exelent' dataKey="Csat.score_excellent" fill="#20ab58" stackId="stack" />
                            ]
                        })
                    })
                }
            case FEEDBACK_TYPE.SIMPLE:
                {
                    return renderChart({
                        title: CHARTS_LABELS.CALL_FEEDBACK,
                        children: renderBarChart({
                            bars: [
                                XAxisDateChart(),
                                <YAxis key='feedback_per_day_yaxis' type='number' domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />,
                                <Tooltip key='nps_feedback_per_day_tooltip' formatter={FeedbackTooltipFormatter('Avg feedback')} />,
                                <Bar key='nps_feedback_per_day' dataKey="Feedback.avgRating" fill="#FFBE88" stackId="stack" />,
                            ]
                        })
                    })
                }
            case FEEDBACK_TYPE.NONE:
            default:
                return null;
        }
    }

    const renderFeedbackComparisonChart = () => {
        switch (feedbackType) {
            case FEEDBACK_TYPE.NPS:
                {
                    return renderChart({
                        title: CHARTS_LABELS.NPS_SCORE,
                        children: renderBarChart({
                            key: 'Calls.hostId',
                            bars: [
                                renderXAxis(),
                                <YAxis key='nps_per_day_yaxis' type='number' />,
                                <Tooltip labelFormatter={renderTooltipLabel} key='nps_feedback_per_day_tooltip' formatter={FeedbackTooltipFormatter('NPS Score')} />,
                                <ReferenceLine key='nps_ref_line' y={0} stroke="#8d8d8d" />,
                                <Bar key='nps_feedback_per_day_bad' dataKey="Nps.score_bad" fill="#f06e63" stackId="stack" />,
                                <Bar key='nps_feedback_per_day_warning' dataKey="Nps.score_warning" fill="#f9b63e" stackId="stack" />,
                                <Bar key='nps_feedback_per_day_good' dataKey="Nps.score_good" fill="#3dc373" stackId="stack" />,
                                <Bar key='nps_feedback_per_day_exelent' dataKey="Nps.score_excellent" fill="#20ab58" stackId="stack" />
                            ]
                        })
                    })
                }
            case FEEDBACK_TYPE.CSAT:
                {
                    return renderChart({
                        title: CHARTS_LABELS.CSAT_SCORE,
                        children: renderBarChart({
                            key: 'Calls.hostId',
                            bars: [
                                renderXAxis(),
                                <YAxis key='csat_per_day_yaxis' type='number' domain={[0,100]}/>,
                                <Tooltip labelFormatter={renderTooltipLabel} key='csat_feedback_per_day_tooltip' formatter={FeedbackTooltipFormatter('CSAT Score')} />,
                                <Bar key='csat_feedback_per_day_bad' dataKey="Csat.score_bad" fill="#f06e63" stackId="stack" />,
                                <Bar key='csat_feedback_per_day_warning' dataKey="Csat.score_warning" fill="#f9b63e" stackId="stack" />,
                                <Bar key='csat_feedback_per_day_good' dataKey="Csat.score_good" fill="#3dc373" stackId="stack" />,
                                <Bar key='csat_feedback_per_day_exelent' dataKey="Csat.score_excellent" fill="#20ab58" stackId="stack" />
                            ]
                        })
                    })
                }
            case FEEDBACK_TYPE.SIMPLE:
                {
                    return renderChart({
                        title: CHARTS_LABELS.CALL_FEEDBACK,
                        children: renderBarChart({
                            key: 'Calls.hostId',
                            bars: [
                                renderXAxis(),
                                <YAxis key='feedback_per_day_yaxis' type='number' domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />,
                                <Tooltip labelFormatter={renderTooltipLabel} key='nps_feedback_per_day_tooltip' formatter={FeedbackTooltipFormatter('Avg feedback')} />,
                                <Bar key='nps_feedback_per_day' dataKey="Feedback.avgRating" fill="#FFBE88" stackId="stack" />,
                            ]
                        })
                    })
                }

            case FEEDBACK_TYPE.NONE:
            default:
                return null;

        }
    }

    return (
      <AppPage
        title='Dashboard'
      >
          {shopDataLoading && <SkeletonBodyText />}
          {!shopDataLoading &&
            <Layout>
                    <Layout.Section>
                        <CaazamAppWarnings />
                    </Layout.Section>
                    <Layout.Section>
                        <DashboardFilter
                            onRangeUpdate={setRange}
                            currentRange={range}
                            currentHost={host}
                            currentEventType={eventType}
                            setEventType={updateEventType}
                            shopEventTypesList={shopEventTypesList}
                            setCurrentHost={updateCurrentHost}
                            hosts={hosts}
                            shopUserTeams={shopUserTeams}
                            setFilterType={setFilterType}
                            filterType={filterType}
                        />
                    </Layout.Section>
                    <Layout.Section>
                        <DashboardHeader
                            isLoading={aggKPI.isLoading}
                            kpis={aggKPIResult || KPIObject(shopData.currencyCode, feedbackType)} />
                        <Card sectioned title='Recent client feedback' actions={[{ content: 'View all', onAction: () => history.push('/call_feedback') }]}>
                            <CallFeedbackList
                                withFilter={false}
                                withPagniation={false}
                                rowCount={3}
                                emptyStateMessage='No recent client feedback'
                                realtimeUpdates={true}
                            />
                        </Card>
                    </Layout.Section>
                    {
                        filterType === 'all'
                        ? <>
                          {renderChart({
                              title: CHARTS_LABELS.CALLS_PER_DAY,
                              children: renderBarChart({
                                  bars: [
                                      XAxisDateChart(),
                                      <Tooltip key='calls_per_day_tooltip'/>,
                                      <Bar key='calls_per_day_bar_completed' dataKey="Calls.callsCompleted" stackId='a' name="Completed Calls" fill="#FFBE88"/>,
                                      <Bar key='calls_per_day_bar_failed' dataKey="Calls.callsFailed" stackId='a' name="Missed Calls" fill="#FF000050"/>
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.SALES_PER_DAY,
                              children: renderComposedChart({
                                  bars: [
                                      XAxisDateChart(),
                                      <Tooltip formatter={customOrdersTooltip}/>,
                                      <Bar key='sales_per_day_bar_total' dataKey="Orders.ordersTotal" name="Sales Amount" fill="#6E76F2" yAxisId='amount'/>,
                                      <Line key='sales_per_day_bar_count' type="monotone" dataKey="Orders.count" name="Number of Orders" stroke="#FFBE88" yAxisId='count'/>
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.CONVERSATION_RATE,
                              children: renderLineChart({
                                  bars: [
                                      XAxisDateChart(),
                                      <YAxis key='conv_rate_yaxis' type='number' domain={[0,1]} tickFormatter={chartPercentFormatter}/>,
                                      <Tooltip key='conv_rate_tooltip' formatter={chartPercentFormatter}/>,
                                      <Line key='conv_rate_line' type="monotone" dataKey="Calls.conversionRate" name="rate" stroke="#6E76F2" connectNulls={true} />
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.AVERAGE_ORDER_VALUE,
                              children: renderBarChart({
                                  bars: [
                                      XAxisDateChart(),
                                      <YAxis key='avg_order_yaxis' tickFormatter={chartCurrencyFormatter}/>,
                                      <Tooltip key='avg_order_tooltip' formatter={chartCurrencyFormatter}/>,
                                      <Bar key='avg_order_bar' dataKey="Orders.avgOrder" name="AOV" fill="#6E76F2"/>
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.CALL_DURATION,
                              children: renderLineChart({
                                  bars: [
                                      XAxisDateChart(),
                                      <YAxis key='duration_yaxis' unit=' min' tickFormatter={minutesFormatter()}/>,
                                      <Tooltip key='duration_tooltip' formatter={minutesFormatter(2)}/>,
                                      <Legend key='duration_legend' verticalAlign='top' />,
                                      <Line key='duration_medium_line' type="monotone" dataKey="Calls.durationMedian" name="50% percentile" stroke="#FFBE88" connectNulls={true} />,
                                      <Line key='duration_percent_line' type="monotone" dataKey="Calls.durationPercent90" name="90% percentile" stroke="#6E76F2" connectNulls={true} />
                                  ]
                              })
                          })}
                          {renderFeedbackChart()}
                          {renderChart({
                              title: CHARTS_LABELS.AIR_TIME_SPLIT,
                              children: renderBarChart({
                                  bars: [
                                      XAxisDateChart(),
                                      <YAxis key='air_yaxis' unit=' min' tickFormatter={minutesFormatter()}/>,
                                      <Tooltip key='air_tooltip' formatter={minutesFormatter(2)}/>,
                                      <Bar key='air_host_total' dataKey="CallEvents.airTimeHostTotal" stackId='a' name="Hosts" fill="#FFBE88"/>,
                                      <Bar key='air_customer_total'  dataKey="CallEvents.airTimeCustomersTotal" stackId='a' name="Customers" fill="#BDC4FF"/>
                                  ]
                              })
                          })}
                          {
                              shopEventTypesList?.length > 0 &&
                                  renderChart({
                                      title: CHARTS_LABELS.COMPLETED_CALLS_BY_EVENT_TYPES,
                                      children: renderBarChart({
                                          chartData: mergedData.eventTypes,
                                          bars: [
                                              XAxisDateChart(),
                                              <Tooltip key='calls_per_day_tooltip'/>,
                                              ...generateEventTypesBars(),
                                          ]
                                      })
                                  })
                          }
                      </>
                        : <>
                          {renderChart({
                              title: CHARTS_LABELS.CALLS_FOR_THE_PERIOD,
                              children: renderBarChart({
                                  key: 'Calls.hostId',
                                  bars: [
                                      renderXAxis(),
                                      <YAxis key='calls_per_day_yaxis'/>,
                                      <Tooltip labelFormatter={renderTooltipLabel} key='calls_per_day_tooltip'/>,
                                      <Bar key={`alls_per_day_bar_completed`} dataKey={`Calls.callsCompleted`} stackId='a' name="Completed Calls" fill="#FFBE88"/>,
                                      <Bar key={`calls_per_day_bar_failed`} dataKey={`Calls.callsFailed`} stackId='a' name="Missed Calls" fill="#FF000050"/>,
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.SALES_FOR_THE_PERIOD,
                              children: renderComposedChart({
                                  key: 'Calls.hostId',
                                  bars: [
                                      renderXAxis(),
                                      <Tooltip labelFormatter={renderTooltipLabel} key='sales_for_period'/>,
                                      <Bar key='sales_per_day_bar_total' dataKey="Orders.ordersTotal" name="Sales Amount" fill="#6E76F2" yAxisId='amount'/>,
                                      <Line key='sales_per_day_bar_count' type="monotone" dataKey="Orders.count" name="Number of Orders" stroke="#FFBE88" yAxisId='count'/>
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.CONVERSATION_RATE,
                              children: renderBarChart({
                                  key: 'Calls.hostId',
                                  bars: [
                                      renderXAxis(),
                                      <YAxis key='conv_rate_yaxis' type='number' domain={[0,1]} tickFormatter={chartPercentFormatter}/>,
                                      <Tooltip labelFormatter={renderTooltipLabel} key='conv_rate_tooltip' formatter={chartPercentFormatter}/>,
                                      <Bar key={`conv_rate_line`} dataKey={`Calls.conversionRate`} name="rate" fill="#6E76F2" />
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.AVERAGE_ORDER_VALUE,
                              children: renderBarChart({
                                  key: 'Calls.hostId',
                                  bars: [
                                      renderXAxis(),
                                      <YAxis key='avg_order_yaxis' tickFormatter={chartCurrencyFormatter}/>,
                                      <Tooltip labelFormatter={renderTooltipLabel} key='avg_order_tooltip' formatter={chartCurrencyFormatter}/>,
                                      <Bar key='avg_order_bar' dataKey="Orders.avgOrder" name="AOV" fill="#6E76F2"/>
                                  ]
                              })
                          })}
                          {renderFeedbackComparisonChart()}
                          {renderChart({
                              title: CHARTS_LABELS.CALL_DURATION,
                              children: renderBarChart({
                                  key: 'Calls.hostId',
                                  bars: [
                                      renderXAxis(),
                                      <YAxis key='duration_yaxis' unit=' min' tickFormatter={minutesFormatter()}/>,
                                      <Tooltip labelFormatter={renderTooltipLabel} key='duration_tooltip' formatter={minutesFormatter(2)}/>,
                                      <Legend key='duration_legend' verticalAlign='top' />,
                                      <Bar key={`duration_medium_line`} dataKey={`Calls.durationMedian`} stackId='a' name="50% percentile" fill="#FFBE88"/>,
                                      <Bar key={`duration_percent_line`} dataKey={`Calls.durationPercent90`} stackId='a' name="90% percentile" fill="#BDC4FF"/>,
                                  ]
                              })
                          })}
                          {renderChart({
                              title: CHARTS_LABELS.AIR_TIME_SPLIT,
                              children: renderBarChart({
                                  key: 'Calls.hostId',
                                  bars: [
                                      renderXAxis(),
                                      <YAxis key='air_yaxis' unit=' min' tickFormatter={minutesFormatter()}/>,
                                      <Tooltip labelFormatter={renderTooltipLabel} key='air_tooltip' formatter={minutesFormatter(2)}/>,
                                      <Bar key='air_host_total' dataKey="CallEvents.airTimeHostTotal" stackId='a' name="Hosts" fill="#FFBE88"/>,
                                      <Bar key='air_customer_total'  dataKey="CallEvents.airTimeCustomersTotal" stackId='a' name="Customers" fill="#BDC4FF"/>
                                  ]
                              })
                          })}
                          {
                              shopEventTypesList?.length > 0 && filterType !== COMPARE_TYPES.EVENT_TYPES &&
                                  renderChart({
                                      title: CHARTS_LABELS.COMPLETED_CALLS_BY_EVENT_TYPES,
                                      children: renderBarChart({
                                          key: 'CallsEventTypes.hostId',
                                          bars: [
                                              renderXAxis(),
                                              <Tooltip labelFormatter={renderTooltipLabel} key='event_types_tooltip'/>,
                                              ...generateEventTypesBars(),
                                          ]
                                      })
                                  })
                          }
                      </>
                }
            </Layout>
          }
          <ChartModal children={chartModalContent.current} title={chartModalTitle} open={isChartModelOpened} onClose={closeChartModal}/>
      </AppPage>
    );
}

export default function() {
    return (
      <AdminPage>
          <Dashboard/>
      </AdminPage>
    )
}

