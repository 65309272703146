
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useShopProvider } from './ShopProvider'
import { isBusinessHoursValid } from './BusinessHours'

import { Banner, Stack } from '@shopify/polaris';
import { useAppAuthState } from '../authState';
import { UserRole } from '../utils/permissions';

export default function CaazamAppWarnings({ }) {
    const history = useHistory();
    const { adminRole } = useAppAuthState();
    const location = useLocation();
    const { subData, subDataLoading, hosts, usersLoading, businessHoursConfig, businessHoursLoading } = useShopProvider();

    const validBusinessHours = businessHoursLoading || isBusinessHoursValid(businessHoursConfig);

    return (
        <Stack vertical>
            {!subDataLoading && !subData && adminRole === UserRole.admin &&
                <Banner
                    title="No Boutiq Subscription"
                    action={{ content: 'Manage subscription', onAction: () => history.push('/subscription') }}
                    status="critical">
                    <p>Boutiq calls will not be connected without an active subscription</p>
                </Banner>
            }
            {!usersLoading && hosts.length === 0 &&
                <Banner
                    title="No hosts"
                    action={location.pathname !== '/settings' && { content: 'Go to settings', onAction: () => history.push('/settings') }}
                    status='warning'>
                    <p>Hosts are your sales associates that would respond on incoming video calls</p>
                </Banner>
            }
            {!validBusinessHours &&
                <Banner
                    title="Business hours not set"
                    action={{ content: 'Go to business hours settings', onAction: () => history.push('/businessConfig') }}
                    status='critical'>
                    <p>Business hours determine when clients can start or schedule a video call</p>
                </Banner>
            }
        </Stack>
    )
}