
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopFeedbackConfig(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('feedback') : null;
    const [feedbackConfig, feedbackConfigLoading, feedbackConfigError] = useDocumentData(ref);
    return { feedbackConfig, feedbackConfigLoading, feedbackConfigError }
}
