import React, { useState } from "react";
import './styles.css'

export const HostAvatarIcon = ({ data, className ='', ...props }) => {

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showInitials, setShowInitials] = useState(false);

    const fallbackToInitials = () => {
        setShowInitials(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }

    return (
        <div className={`host-avatar-container ${className}`}  {...props}>
            {data.avatarUrl ?
                showInitials ?
                    <span className='host-avatar initials'>{`${data.firstName && data.firstName[0]}${data.lastName && data.lastName[0]}`}</span>
                    : <img onError={() => fallbackToInitials()} onLoad={animateImageOnLoad} className={`host-avatar${isImageLoaded ? ' loaded' : ''}`} src={data.avatarUrl} />
                : <span className='host-avatar initials'>{`${data.firstName && data.firstName[0]}${data.lastName && data.lastName[0]}`}</span>}

        </div>
    )
}