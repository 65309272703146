import React from 'react';
import {TextField} from "@shopify/polaris";
import {isSafariBrowser} from "../utils/system";
import {TIME_PATTERN} from "../constants/time";

const TimeField = ({id, value, onChange, disabled, label = ''}) => {
  if (isSafariBrowser()) {
    return (
      <TextField
        placeholder={'--:--'}
        id={id}
        label={`${label}`}
        type='text'
        pattern={TIME_PATTERN}
        value={value}
        disabled={disabled}
        onChange={onChange}
        maxLength={5}
      />
    );
  }

  return (
    <TextField
      id={id}
      label={label}
      type='time'
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default TimeField;
