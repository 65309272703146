import {Card, DataTable} from '@shopify/polaris';
import React from 'react';
import {formatHostNameForTable, formatShortEventDate, statusMapper} from "../../utils/formatters";

function CalendarAnalyticsDataTable({dataset, hosts}) {

  const rows = dataset.map(data => ([
    `${data.title}\n${data.clientEmail}`,
    data?.eventType?.name || null,
    statusMapper(data),
    formatHostNameForTable(hosts, data?.hostId),
    formatShortEventDate(data?.start)]));

  return (
    <Card>
      <DataTable
        defaultSortDirection='descending'
        initialSortColumnIndex={4}
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
        headings={['Client', 'Event type', 'Status', 'Host', 'Date']}
        rows={rows}
      />
    </Card>
  );
}

export default CalendarAnalyticsDataTable;
