import React from 'react';

import { Layout, Card, Stack, TextField, SkeletonBodyText, List, TextStyle, Link } from '@shopify/polaris';

export function GoogleEventTracking({ loading, googleTrackingId, setGoogleTrackingId, editMode, setEditMode, onReset, errorMsg }) {

    const labelAction = !editMode
        ? { content: 'Change', onAction: () => setEditMode(true) }        
        : googleTrackingId
            ? { content: 'Cancel', onAction: onReset }
            : null;

    return (

        <Layout.AnnotatedSection
            title="Google Analytics"
            description='Use Google Analytics to track how visitors to your store are interacting with Boutiq. Generate reports that will help you with marketing virtual shopping experiences'
        >
            <Card>
                <Card.Section>
                    {loading && <SkeletonBodyText />}
                    {!loading && <Stack vertical>
                        <TextField
                            label='Google Analytics account'
                            placeholder='G-XXXXXXX'
                            value={googleTrackingId}
                            onChange={setGoogleTrackingId}
                            labelAction={labelAction}
                            disabled={!editMode}
                            error={errorMsg}
                        />
                        <Stack vertical spacing='tight'>
                            <p> The following events will be reported to your Google Analytics property:</p>
                            <List type='bullet'>
                                <List.Item>Client app opened</List.Item>
                                <List.Item>Video call started</List.Item>
                                <List.Item>Video call scheduled</List.Item>
                                <List.Item>Items added to cart</List.Item>
                                <List.Item>Checkout started from video call</List.Item>
                                <List.Item>Campaign viewed and clicked</List.Item>
                            </List>
                        </Stack>
                    </Stack>
                    }
                </Card.Section>

            </Card>
        </Layout.AnnotatedSection>
    );
}

export function FacebookEventTracking({ loading, facebookTrackingId, setFacebookTrackingId, editMode, setEditMode, onReset, errorMsg }) {

    const labelAction = !editMode
        ? { content: 'Change', onAction: () => setEditMode(true) }        
        : facebookTrackingId
            ? { content: 'Cancel', onAction: onReset }
            : null;

    return (

        <Layout.AnnotatedSection
            title="Facebook Pixel"
            description='Connect to your Facebook Pixel to share how visitors to your store are interacting with Boutiq'>
            <Card>
                <Card.Section>
                    {loading && <SkeletonBodyText />}
                    {!loading && <Stack vertical>
                        <TextField
                            label='Facebook Pixel'
                            placeholder='Your Facebook Pixel ID'
                            value={facebookTrackingId}
                            onChange={setFacebookTrackingId}
                            labelAction={labelAction}
                            disabled={!editMode}
                            error={errorMsg}
                        />
                        <Stack vertical spacing='tight'>
                            <p><TextStyle variation='strong'>Requires that <Link url="https://help.shopify.com/en/manual/promoting-marketing/analyze-marketing/facebook-pixel" external>Facebook data sharing </Link>
                                is already setup for your online store using the same Pixel ID entered</TextStyle></p>
                            <p>The following events will be reported to your Facebook Pixel:</p>
                            <List type='bullet'>
                                <List.Item>Client app opened</List.Item>
                                <List.Item>Video call started</List.Item>
                                <List.Item>Video call scheduled</List.Item>
                                <List.Item>Items added to cart</List.Item>
                                <List.Item>Checkout started from video call</List.Item>
                                <List.Item>Campaign viewed and clicked</List.Item>
                            </List>
                        </Stack>
                    </Stack>
                    }
                </Card.Section>

            </Card>
        </Layout.AnnotatedSection>
    );
}

