import React, { useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

import { Banner, InlineError, Text, TextContainer, List } from "@shopify/polaris";
import { useShopProvider } from '../../../../components/ShopProvider';

export default function FeatureEnableBanner({ isBlockEnabled, onClose }) {
    const { shopOrigin: shopId, headlessConfig } = useShopProvider();
    const [working, setWorking] = useState(false);
    const [verifyError, setVerifyError] = useState(null);
    const activateUrl = `https://${shopId}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_BOUTIQ_SHOPIFY_APP_EXT_ID}/boutiq-shoppable-videos`
    
    async function verifyAction() {
        try {
            setVerifyError(null);
            setWorking(true);
            const verifyShoppableAppExtension = Firebase.functions().httpsCallable('shopifyTheme-verifyShoppableAppExtension');
            const {isBlockEnabled } = await verifyShoppableAppExtension({ shopId });
            if (!isBlockEnabled) {
                setVerifyError(new Error('Boutiq story app embed is not activated - please try again'));
            }
        } catch (error) {
            setVerifyError(error);
        } finally {
            setWorking(false)
        }
    }
    if (!isBlockEnabled) {
        if (!headlessConfig?.isHeadless) {
            return (
                <Banner
                    status='warning'
                    title='Boutiq Shoppable Videos not enabled on your online store'
                    action={{ content: 'Verify', onAction: verifyAction, loading: working }}
                >
                    <TextContainer spacing='tight'>
                        <Text>
                            To activate Boutiq Shoppable Videos on your online store theme, you need to have Shopify online store admin permissions.
                        </Text>
                        <Text>
                            Please <a href={activateUrl} target='_blank' rel="noopener noreferrer">follow this link</a> and then:
                        </Text>
                        <List type='number'>
                            <List.Item>A new browser tab will be open with your Shopify theme editor showing your app embeds</List.Item>
                            <List.Item>Make sure the toggle next to the Boutiq Shoppable Video app embed is turned on, and </List.Item>
                            <List.Item>Save the changes in the theme editor, and next</List.Item>
                            <List.Item>Switch back to this Boutiq Admin page and click on the verify button below to compelte complete the activation process.</List.Item>
                        </List>
                        {verifyError && <InlineError message={verifyError.message} />}
                    </TextContainer>
                </Banner>
            )
        } else {
            return (
                <Banner
                    status='warning'
                    title='Boutiq Shoppable Videos not enabled on your online store'
                >
                    <TextContainer spacing='tight'>
                        <Text>
                            To activate Boutiq Shoppable Videos on your headless store please <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact Boutiq support</a> for further instructions.
                        </Text>
                    </TextContainer>
                </Banner>
            )
        }
    } else {
        return (
            <Banner
                status='success'
                title='Boutiq Shoppable Videos enabled on your online store'
                action={{ content: 'Hide', onAction: onClose }}
            />
        )
    }
}