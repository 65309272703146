import React from 'react'
import { useCreateNewAppointmentProvider } from '../../../../../../components/CreateNewAppointmentProvider'
import moment from 'moment-timezone';
import { Banner, Button, Icon, Stack, TextField, TextStyle } from '@shopify/polaris';
import { DuplicateMinor, CircleTickOutlineMinor } from '@shopify/polaris-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import './styles.css';
import {localTimezone} from '../../../../../../utils/time';

export const ScheduleResultTab = () => {

    const { scheduleRequestError, linkUrl, selectedSlot, clientEmail, toggleToastActive, availableHosts, selectedHostId } = useCreateNewAppointmentProvider();

    const currentHost = availableHosts.find(host => host.id === selectedHostId);

    const displayDateFormatter = (date) => {
        return new Intl.DateTimeFormat([], {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).format(date);
    }

    return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!scheduleRequestError ?
            <Stack vertical alignment='center'>
                <span className='schedule-result-icon-wrapper'>
                    <Icon
                        source={CircleTickOutlineMinor}
                        color='success'
                    />
                </span>
                <TextStyle variation='strong'>{`Call is scheduled ${displayDateFormatter(selectedSlot.timeSlot)}`}</TextStyle>
                <TextStyle>
                    <p style={{ textAlign: 'center' }}>
                        At the scheduled time, when the client clicks the link a new Boutiq video call will start and
                        <TextStyle variation='strong'>
                            {` ${currentHost?.firstName} `}
                        </TextStyle> 
                        will be able to answer it
                    </p>
                </TextStyle>
                <TextStyle>
                    <p style={{ textAlign: 'center' }}>
                        {'Confirmation email with call link sent to the client at '}
                        <TextStyle variation='strong'>
                            {clientEmail}
                        </TextStyle>
                    </p>
                </TextStyle>
                {!!linkUrl && <Stack alignment='center'>
                    <p>Client call link:</p>
                    <Stack.Item fill>
                        <TextField
                            value={linkUrl}
                            disabled={true}
                            connectedRight={<CopyToClipboard text={linkUrl} onCopy={toggleToastActive}>
                                <Button icon={DuplicateMinor} />
                            </CopyToClipboard>}
                        />
                    </Stack.Item>
                </Stack>}
            </Stack>
            : <Stack vertical alignment='center'>
                {scheduleRequestError?.statusCode === 403
                    ? <Banner status='critical' title='This client is banned'>
                        <p>
                            Cannot schedule a call for this client
                        </p>
                    </Banner>
                    : <Banner status='critical' title='Something went wrong'>
                        <p>
                            {scheduleRequestError?.message}: {scheduleRequestError?.reason}
                        </p>
                    </Banner>
                }
            </Stack>}
    </div>
}
