import React, { createContext, useContext, useEffect, useState } from 'react';
import { useShopProvider } from '../../../../components/ShopProvider';
import useShopVideoStories from '../../../../hooks/useShopVideoStories';
import useShopVideoStoriesActions from '../../../../hooks/useShopVideoStoriesActions';
import useStoryConfigurations from '../../pages/EditStory/hooks/useStoryConfigurations';
import useShopShopifyTheme from '../../../../hooks/useShopShopifyTheme';

const StoriesContext = createContext(null);

export const StoriesProvider = ({children}) => {
  const { shopOrigin, shopData } = useShopProvider()

  const [selectedStory, setSelectedStory] = useState(null)
  const [isBoutiqClientStory, setBoutiqClientStory] = useState(false)
  const [uploadingStates, setUploadingStates] = useState([])
  const [shoppablePreviewVisible, setShoppablePreviewVisible] = useState(false)
  const { shopifyTheme, verifyingSections, supportsThemeSections } = useShopShopifyTheme(shopOrigin);

  const {
    isStoriesChangeLoading,
    setStoriesChangeLoading,
    onSaveStory,
    onDeleteStory,
    onPublish,
    onUnPublish,
    publishLoading,
    onChangePublishLoading
  } = useShopVideoStoriesActions(shopOrigin)

  const {
    videoStoryLoading,
    shopVideoStories,
  } = useShopVideoStories(shopOrigin, setStoriesChangeLoading)

  const {
    isNewStory,
    isSaveVisible,
    currentStory,
    validationError,
    isConfigurationValid,
    onChangeCurrentStory,
    onAddVideo,
    onRemoveVideo,
    onChangeVideosOrder,
    onDiscardStoryChanges,
    onClearErrors
  } = useStoryConfigurations(selectedStory, shopVideoStories)

  useEffect(() => {
    if (isStoriesChangeLoading || publishLoading) {
      setShoppablePreviewVisible(false)
    }
  }, [isStoriesChangeLoading, publishLoading])

  const onChangeSelectedStory = (story) => {
    setSelectedStory(story)
  }

  const onViewStoryPage = (story) => {
    if (!story?.conditionUrl && story.status === 'published') return;
    const { primaryDomain } = shopData;
    window.open(primaryDomain?.url + story?.conditionUrl, '_blank');
  }

  return (
    <StoriesContext.Provider
      value={{
        onChangeSelectedStory,
        videoStoryLoading,
        shopVideoStories,
        isStoriesChangeLoading,
        onSaveStory,
        onDeleteStory,
        onPublish,
        onUnPublish,
        publishLoading,
        onChangePublishLoading,
        isNewStory,
        isSaveVisible,
        currentStory,
        validationError,
        isConfigurationValid,
        onChangeCurrentStory,
        onAddVideo,
        onRemoveVideo,
        onChangeVideosOrder,
        selectedStory,
        uploadingStates,
        setUploadingStates,
        onViewStoryPage,
        onDiscardStoryChanges,
        onClearValidationError: onClearErrors,
        isBoutiqClientStory, setBoutiqClientStory,
        shoppablePreviewVisible, setShoppablePreviewVisible,
        verifyingSections, supportsThemeSections,
        shopifyTheme,
      }}
    >
      {children}
    </StoriesContext.Provider>
  )
}

export function useStoriesProvider() {
  const context = useContext(StoriesContext);
  if (!context) {
    throw new Error('useStoriesProvider must be used within the StoriesContextProvider');
  }
  return context;
}
