import React, {useEffect, useMemo, useState} from 'react';

import { Stack, Button, Modal } from '@shopify/polaris';
import Moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import {FORM_ELEMENTS_PROPS, FORM_OBJECT_TYPES} from './formBuilder/templateFormBuilder';
import { OptionElement } from './OptionElement';
import { FormMessages } from './FormMessages';
import {HOST_AVAILABILITY} from "../../constants/const";
import './availability.scss'

export default function CaazamAvailability({ open, data, add, update, updateWindowRecurrenceRule, handleDelete, onClose, isCustomCallPolicy, onChangeHostAvailabilityType, selectedHostAvailabilityType, actionLoading }) {
    const [isValidForm, setIsValidForm] = useState(true);

    const hostAvailability = useMemo(() => (
      [
          {name: HOST_AVAILABILITY.EVENTS, value: HOST_AVAILABILITY.EVENTS, label: 'Scheduled calls'},
          {name: HOST_AVAILABILITY.CALLS, value: HOST_AVAILABILITY.CALLS, label: 'Instant calls'}
      ]
    ), [])

    const defaultFormValues = (data) => {
        return {
            'id': data.id ? data.id : null,
            'start': data.start,
            'startTime': data.start,
            'end': data.end,
            'endTime': data.end,
            'repeat_mode': data.isRecurring,
            'repeat_end': !!data.recurrenceEnd,
            'repeat_end_date': data.recurrenceEnd ? data.recurrenceEnd : Moment(data.end).add(1, 'days').toDate(),
        };
    }

    const [formValues, setFormValues] = useState(data ? defaultFormValues(data) : null);
    const [formMessage, setFormMessage] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const [isUpdateSlot, setIsUpdateSlot] = useState(false);
    const [isDeleteSlot, setIsDeleteSlot] = useState(false);
    const [isTimeUpdate, setIsTimeUpdate] = useState(false);
    const [isRecurreceUpdate, setIsRecurreceUpdate] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false)

    useEffect(() => {
        setIsLoadingData(Object.values(actionLoading).reduce((acc, current) => acc || current, false))
    }, [actionLoading])

    const ActionsContainer = ({ isRecurring, isUpdateSlot, isDeleteSlot }) => {
        const mainActions = actions(!data.id, isRecurring, isUpdateSlot, isDeleteSlot, isRecurreceUpdate);
        return (
            <Stack wrap={false}>
                {mainActions.map((action, index) => (
                    <Stack.Item key={`${index}-${action.name}`} fill={index === 0}>
                        <Button disabled={!action.isActive} onClick={() => action.func()} loading={action.loading}>
                            {action.label}
                        </Button>
                    </Stack.Item>

                ))}
                {(isUpdateSlot || isDeleteSlot) && isRecurring && !isRecurreceUpdate &&
                    enhancedActions(isUpdateSlot).map((action, index) => (
                        <Stack.Item key={`${index}-${action.name}`}>
                            <Button disabled={!action.isActive} onClick={() => action.func()} loading={action.loading}>
                                {action.label}
                            </Button>
                        </Stack.Item>
                    ))}
            </Stack>
        )
    }

    const actions = (isNewSlot, isRecurring, isUpdateSlot, isDeleteSlot, isRecurreceUpdate) => {
        if (isNewSlot) {
            return [
                {
                    label: 'Cancel',
                    isActive: !isLoadingData,
                    func: () => onClose()
                },
                {
                    label: 'Save',
                    isActive: isValidForm && !isLoadingData,
                    func: async () => await addSlot(formValues),
                    loading: actionLoading.addHostLoading
                }
            ]
        } else {
            if (isUpdateSlot) {
                if (isRecurring) {
                    if (isRecurreceUpdate) {
                        return [
                            {
                                label: 'Cancel',
                                isActive: !isLoadingData,
                                func: () => onResetChanges()
                            },
                            {
                                label: 'Update',
                                isActive: !isLoadingData,
                                func: () => updateSlot(),
                                loading: actionLoading.updateHostLoading
                            },
                        ]
                    } else {
                        return [
                            {
                                label: 'Cancel',
                                isActive: !isLoadingData,
                                func: () => onResetChanges()
                            },
                        ]
                    }

                } else {
                    return [
                        {
                            label: 'Cancel',
                            isActive: !isLoadingData,
                            func: () => onResetChanges()
                        },
                        {
                            label: 'Update',
                            isActive: !isLoadingData,
                            func: () => updateSlot(),
                            loading: actionLoading.updateHostLoading
                        },

                    ]
                }
            } else if (isDeleteSlot) {
                if (isRecurring) {
                    return [
                        {
                            label: 'Cancel',
                            isActive: !isLoadingData,
                            func: () => onResetChanges()
                        }
                    ]
                } else {
                    return [
                        {
                            label: 'Cancel',
                            isActive: !isLoadingData,
                            func: () => onResetChanges()
                        },
                        {
                            label: 'Delete',
                            isActive: !isLoadingData,
                            func: async () => await handleDelete(false),
                            loading: actionLoading.deleteHostLoading
                        }
                    ]
                }
            } else {
                return [
                    {
                        label: 'Delete',
                        isActive: !isLoadingData,
                        func: () => setIsDeleteSlot(true),
                        loading: actionLoading.deleteHostRecurrenceLoading
                    },
                ]
            }
        }
    }

    const enhancedActions = (isUpdate) => {
        if (isUpdate) {
            return [
                {
                    label: 'Update this instance',
                    isActive: isValidForm && !isLoadingData,
                    func: () => updateSlot(false),
                    loading: actionLoading.updateHostLoading
                },
                {
                    label: 'Update all future instances',
                    isActive: isValidForm && !isLoadingData,
                    func: () => updateSlot(),
                    loading: actionLoading.updateHostLoadingRecurrence
                }
            ]
        } else {
            return [
                {
                    label: 'Delete this instance',
                    isActive: isValidForm && !isLoadingData,
                    func: async () => await handleDelete(false),
                    loading: actionLoading.deleteHostLoading
                },
                {
                    label: 'Delete all future instances',
                    isActive: isValidForm && !isLoadingData,
                    func: async () => await handleDelete(),
                    loading: actionLoading.deleteHostRecurrenceLoading
                }
            ]
        }
    }

    useEffect(() => {
        if (data) {
            setIsUpdateSlot(false);
            setIsDeleteSlot(false);
            setIsTimeUpdate(false);
            setIsRecurreceUpdate(false);
            setFormMessage(null);
            if (data.forcePopup) {
                data.forcePopup = false;
            }
            setFormValues(defaultFormValues(data));

            if (formValues) {
                setFormFields(FORM_ELEMENTS_PROPS.map(item => item(formValues, data.end)).map(item => item.filter(subItem => subItem !== null)));
            }
        }
    }, [data])

    useEffect(() => {
        if (formValues) {

            const formElements = FORM_ELEMENTS_PROPS.map(item => item(formValues, data.end)).map(item => item.filter(subItem => subItem !== null));
            const isFieldsValid = formElements.every(row => row.every(element => element.isValid));
            setIsValidForm(isFieldsValid)
            setFormFields(formElements);
        }
    }, [formValues])

    useEffect(() => {
        if (!isValidForm) {
            setFormMessage({
                // icon: <ErrorOutlineIcon style={{ fontSize: '14px', marginRight: '1ch' }} />,
                icon: null,
                type: 'error',
                text: 'Close time must be after open time'
            })

        } else {
            setFormMessage(null)
        }
    }, [isValidForm, isDeleteSlot])

    useEffect(() => {
        if (data && data.id) {
            if (Moment(formValues.startTime).valueOf() !== Moment(data.start).valueOf() ||
                Moment(formValues.endTime).valueOf() !== Moment(data.end).valueOf()) {
                setIsUpdateSlot(true);
                setIsTimeUpdate(true);
            } else if (formValues.repeat_end && Moment(formValues.repeat_end_date).valueOf() !== Moment(data.recurrenceEnd).valueOf() ||
                formValues.repeat_mode !== data.isRecurring || formValues.repeat_end !== !!data.recurrenceEnd) {
                setIsUpdateSlot(true);
                setIsRecurreceUpdate(true);
            } else {
                setFormMessage(null);
                setIsUpdateSlot(false);
                setIsTimeUpdate(false);
                setIsRecurreceUpdate(false);
            }
        }
    }, [formValues])

    const onResetChanges = () => {
        setIsUpdateSlot(false);
        setIsDeleteSlot(false);
        setIsTimeUpdate(false);
        setIsRecurreceUpdate(false);
        setFormMessage(null);
        setFormValues({ ...formValues, ...defaultFormValues(data) });
    }

    const onValueChange = (name, value) => {
        if (value === 'true' || value === 'false') {
            value = JSON.parse(value);
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const addSlot = async () => {
        await add(formValues);
    }

    const updateSlot = async (allFuture = true) => {
        if (isTimeUpdate) {
            await onUpdateStartEndTime(allFuture);
        } else {
            await updateRecurrence();
        }
        onClose();
    }

    const onUpdateStartEndTime = async (allFuture = true) => {
        await update(formValues, allFuture);
    }

    const updateRecurrence = async () => {
        await updateWindowRecurrenceRule(formValues);
    }

    const onTypeChange = (_, value) => {
        onChangeHostAvailabilityType(value)
    }

    return (

        <Modal
            open={open}
            onClose={() => { onClose(); setFormMessage(null); }}
            title={data ? data.title : ''}
        >
            {isCustomCallPolicy && (
              <Modal.Section>
                  <div className='custom-host-availability'>
                      <OptionElement
                        disableSelect={data?.id}
                        element={{
                            value: selectedHostAvailabilityType,
                            label: 'Host availability',
                            visibility: 'visible',
                            type: FORM_OBJECT_TYPES.select,
                            values: hostAvailability,
                            name: 'Host availability',
                        }}
                        onValueChange={onTypeChange}
                      />
                  </div>
              </Modal.Section>
            )}
            <Modal.Section>
                <div style={{ height: '400px' }}>

                    <Stack vertical>
                        <Stack vertical>
                            {formFields.map((row, rowIndex) => (
                                <Stack key={rowIndex}>
                                    {row.map((element, index) => (
                                        <OptionElement
                                            index={index}
                                            disableDateTimePicker={isRecurreceUpdate || isDeleteSlot}
                                            disableDatePicker={isTimeUpdate || isDeleteSlot}
                                            disableSelect={isTimeUpdate || isDeleteSlot}
                                            slotData={data}
                                            key={`${index}-${element.name}`}
                                            element={element}
                                            onValueChange={onValueChange}
                                        />
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                        <Stack>
                            <FormMessages message={formMessage} />
                        </Stack>

                    </Stack>
                </div>
                {data && <ActionsContainer
                    isRecurring={data.isRecurring}
                    isUpdateSlot={isUpdateSlot}
                    isDeleteSlot={isDeleteSlot}
                />}
            </Modal.Section>
        </Modal>
    )
}
