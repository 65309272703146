import React from 'react'
import './index.css';

export default function WelcomeMessage({ isLogoAvailable, welcome, line1, line2 }) {

    return (
        <div className='welcome-message-container-wrapper'>
            <div className='welcome-message-container'>
                <p className={`primary-text${isLogoAvailable ? '' : ' header-text'}`}>
                    {welcome}
                </p>
                <p className='secondary-text'>
                    {line1}
                </p>
                <p className='secondary-text'>
                    {line2}
                </p>
            </div>
        </div>
    )
}
