import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Layout, Card, Stack, Checkbox, SkeletonBodyText, Banner } from '@shopify/polaris';
import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';
import UpgradeBanner from '../../../components/UpgradeBanner';


import { useShopProvider } from '../../../components/ShopProvider';
import useShopDraftOrdersConfig from '../../../hooks/useShopDraftOrdersConfig';

function DraftOrderSettings() {

    const history = useHistory();
    const { shopOrigin, shopData, shopDataLoading, productsConfig, productsConfigLoading } = useShopProvider();
    const { draftOrderConfig, draftOrderConfigLoading, setDraftOrders } = useShopDraftOrdersConfig(shopOrigin)
    const [supportsDraftOrders, setSupportsDraftOrders] = useState(false);
    const [enableDraftOrders, setEnableDraftOrders] = useState(false);
    const [warningBanner, showWarningBanner] = useState(false);
    const isLoading = shopDataLoading || productsConfigLoading || draftOrderConfigLoading;

    useEffect(() => {
        if (!shopDataLoading && !draftOrderConfigLoading && !productsConfigLoading) {
            let { productPolicy } = shopData;
            setSupportsDraftOrders(!!productPolicy.supportsDraftOrders);
            setEnableDraftOrders(draftOrderConfig?.enabled);
            showWarningBanner(!draftOrderConfig?.enabled && productsConfig?.searchIncludeUnpublished);
        }
    }, [draftOrderConfigLoading, draftOrderConfig, productsConfigLoading, productsConfig])

    const onChange = (newChecked) => {
        setEnableDraftOrders(newChecked);
        setDraftOrders(newChecked).catch(error => console.error('DraftOrderSettings', error));
    }

    return (
        <AppPage title='Draft orders' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.Section>
                    <Card sectioned >
                        <Stack vertical spacing='loose'>
                            {warningBanner &&
                                <Banner
                                    title='Draft orders recommended'
                                    status='warning'
                                    secondaryAction={{ content: 'Go to products settings', url: '/productsConfig' }}
                                >
                                    Boutiq is configured to display unpublished products. It is recommedned draft orders are enabled to allow purchases.
                                </Banner>}
                            <p>Enable hosts to create custom draft orders for clients during Boutiq video calls</p>
                            {isLoading && <SkeletonBodyText lines='4' />}
                            {!isLoading && !supportsDraftOrders &&
                                <UpgradeBanner content='Please upgrade the Boutiq app to add draft order permissions for your store'/>
                            }
                            {!isLoading && supportsDraftOrders &&
                                <Stack vertical>
                                    <Checkbox
                                        label='Enable draft orders'
                                        checked={enableDraftOrders}
                                        onChange={onChange}
                                    />
                                </Stack>}
                        </Stack>

                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <DraftOrderSettings />
        </AdminPage>
    )
}
