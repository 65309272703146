import React, { useState } from 'react';

import { Stack, Button, RadioButton, TextStyle, Collapsible } from '@shopify/polaris';

import { getCallPolicy, getCallPolicyDescription } from '../../components/CallPolicyConfig';

export function isCustomPolicy(options) {
    return options && ('disableIncoming' in options || 'disableScheduling' in options);
}

export default function CallLinkPolicyConfig({ schedulingConfig, options, setOptions }) {
    
    const [custom, setCustom] = useState(isCustomPolicy(options));

    function handleCustomChange() {
        if (custom) {
            setOptions({});
        }
        setCustom(!custom);
    }
    
    function handleInstantConfigChange(val) {
        setOptions({ ...options, disableIncoming: val });
    }

    function handleSchedulingConfigChange(val) {
        setOptions({ ...options, disableScheduling: val });

    }

    return (
        <Stack vertical spacing='tight'>
            <p> Call Link Policy</p>
            <Stack>
                <p><TextStyle variation={custom && 'subdued'}>{getCallPolicyDescription(getCallPolicy(schedulingConfig))} (default)</TextStyle></p>
                <Button plain onClick={handleCustomChange}>{custom ? 'Unset' : 'Set'} custom policy</Button>
            </Stack>
            <Collapsible open={custom}>
                <Stack vertical spacing='extraTight'>
                    <Stack alignment='center'>
                        <p>This link can start instant calls:</p>
                        <Stack>
                            <RadioButton
                                label="default"
                                checked={options?.disableIncoming == null}
                                id="default"
                                name="instant"
                                disabled={schedulingConfig?.disableIncoming && options?.disableScheduling}
                                onChange={() => handleInstantConfigChange(undefined)}
                            />
                            <RadioButton
                                label="always"
                                checked={options?.disableIncoming === false}
                                id="always"
                                name="instant"
                                onChange={() => handleInstantConfigChange(false)}
                            />
                            <RadioButton
                                label="never"
                                checked={options?.disableIncoming === true}
                                id="never"
                                name="instant"
                                disabled={options?.disableScheduling || (schedulingConfig?.disableScheduling && options?.disableScheduling === undefined )}
                                onChange={() => handleInstantConfigChange(true)}
                            />
                        </Stack>
                    </Stack>
                    <Stack alignment='center'>
                        <p>This link can schedule calls:</p>
                        <Stack distribution=''>
                            <RadioButton
                                label="default"
                                checked={options?.disableScheduling == null}
                                id="default_s"
                                name="schedule"
                                disabled={schedulingConfig?.disableScheduling && options?.disableIncoming}
                                onChange={() => handleSchedulingConfigChange(undefined)}
                            />
                            <RadioButton
                                label="always"
                                checked={options?.disableScheduling === false}
                                id="always_s"
                                name="schedule"
                                onChange={() => handleSchedulingConfigChange(false)}
                            />
                            <RadioButton
                                label="never"
                                checked={options?.disableScheduling === true}
                                id="never_s"
                                name="schedule"
                                disabled={options?.disableIncoming || (schedulingConfig?.disableIncoming && options?.disableIncoming === undefined )}
                                onChange={() => handleSchedulingConfigChange(true)}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Collapsible>
        </Stack>
    )
}