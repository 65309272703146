import React, { useState, useCallback, useMemo } from 'react';
import { useLocation, Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { FooterHelp, Badge, Loading, Frame, TopBar, Navigation, Icon, Stack, Subheading, Link, Button, Popover, ActionList } from '@shopify/polaris';
import {
  AnalyticsMajor, SettingsMajor, DesktopMajor, TransferMajor, ListMajor, BuyButtonMajor, CalendarMajor, DetailedPopUpMajor, CameraMajor, LogOutMinor, CollectionsMajor, MagicMajor, PlayCircleMajor
} from '@shopify/polaris-icons';

import { useAppAuthState } from '../authState';
import { useShopProvider } from '../components/ShopProvider'
import useHostApp from '../hooks/useHostApp';
import { isMobile } from 'react-device-detect';
import { useAdminShops } from '../hooks/useAdminShops';
import PermissionsModal from './PermissionsModal';
import SubscriptionModal from './SubscriptionModal';
import { UserRole } from '../utils/permissions';

const UserMenu = ({ user, isUserMenuOpen, toggleIsUserMenuOpen, signOut }) => {
  return (
    <TopBar.UserMenu
      name={user.firstName && `${user.firstName} ${user.lastName}`}
      detail={user.email}
      avatar={user.avatar}
      //initials={user.firstName && `${user.firstName[0]}${user.lastName[0]}`}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
      actions={[
        {
          items: [{ content: 'Log out', onAction: signOut, icon: LogOutMinor }],
        }
      ]}
    />);
}

const CaazamNavigation = ({ shopList = [], isHost, adminRole }) => {
  const { adminShops } = useAdminShops();
  const location = useLocation();
  const { shopData } = useShopProvider();
  const { openHostApp } = useHostApp();
  const [shopSelectPopoverOpen, setShopSelectPopoverOpen] = useState(false);
  const multipleShops = shopList.length > 1;

  const toggelShopSelection = useCallback(() => setShopSelectPopoverOpen(!shopSelectPopoverOpen), [shopSelectPopoverOpen])

  const popoverActivator = <Button plain monochrome disclosure onClick={toggelShopSelection}>
    <Subheading>{shopData && shopData.name}</Subheading>
  </Button>

  const ShopSelectorPopover = () => (
    <Popover
      active={shopSelectPopoverOpen}
      activator={popoverActivator}
      onClose={toggelShopSelection}
    >
      <ActionList items={shopList.map(shop => ({
        content: adminShops && adminShops[shop]?.name || shop,
        active: shop === shopData?.myshopifyDomain,
        helpText: (adminShops && adminShops[shop]?.name) ? shop : null,
        onAction: () => window.location.replace(`${window.location.origin}${window.location.pathname}?shop=${shop}`)
      }))} />
    </Popover>
  );

  const DesktopShopNavSection = () => (
    <Navigation.Section title={multipleShops ? <ShopSelectorPopover /> : shopData && shopData.name}
      items={[]}
    />
  );

  const MobileShopNavSection = () => (
    <> <Navigation.Section title={multipleShops ? popoverActivator : shopData && shopData.name}
      items={[]}
    />
      {shopSelectPopoverOpen && <ActionList items={shopSelectPopoverOpen ? shopList.map(shop => ({
        content: adminShops && adminShops[shop]?.name || shop,
        active: shop === shopData.myshopifyDomain,
        helpText: (adminShops && adminShops[shop]?.name) ? shop : null,
        onAction: () => window.location.replace(`${window.location.origin}${window.location.pathname}?shop=${shop}`)
      })) : []} />}
    </>
  );

  let menuItems = useMemo(() => {
    let items = [];
    if (adminRole === UserRole.admin) {
      items = [
        {
          url: '/',
          selected: location.pathname === '/',
          label: 'Dashboard',
          icon: AnalyticsMajor,
        },
        {
          url: '/calendar',
          label: 'Calendar',
          icon: CalendarMajor,
          subNavigationItems: [
            {
              url: '/calendar/analytics',
              label: 'Reports',

            }
          ]
        },
        {
          url: '/boutiq-ai',
          label: <>Boutiq<sup>AI</sup>{'  '}<Badge status='new'>beta</Badge></>,
          icon: MagicMajor,
        },
        {
          key: 'shoppable stories',
          url: '/stories/shoppable',
          label: <>Shoppable Videos{'  '}<Badge status='new'>beta</Badge></>,
          icon: CameraMajor,
          selected: location.pathname.includes('stories') && location.pathname !== '/stories/boutiq',
          subNavigationItems: [
            {
              url: '/stories/analytics',
              label: 'Reports',
            }
          ]
        },
        {
          url: '/campaigns',
          label: 'Campaigns',
          icon: DetailedPopUpMajor,
        },
        {
          url: '/shop_links',
          label: 'Call Links',
          icon: BuyButtonMajor,
        },
        {
          url: '/showrooms',
          label: 'Showrooms',
          icon: CollectionsMajor,
        },
        {
          url: '/call_history',
          label: 'Call History',
          icon: ListMajor,
        }
      ];
    } else if (adminRole === UserRole.calendarAdmin) {
      items = [
        {
          url: '/calendar',
          label: 'Calendar',
          icon: CalendarMajor,
        },
      ]
    }

    if (!isMobile) {
      items.push({
        onClick: () => openHostApp(),
        url: false,
        matches: true,
        selected: false,
        label: 'Host App',
        icon: DesktopMajor,
        badge: <Icon source={TransferMajor} color={isHost ? 'inkLightest' : 'subdued'}/>,
        disabled: !isHost,
      });
    }

    return items;
  }, [adminRole]);

  return (
    <Navigation location={location.pathname}>
      {isMobile
        ? <MobileShopNavSection />
        : <DesktopShopNavSection />
      }
      <Navigation.Section
        items={menuItems}
        separator
      />
      {adminRole === UserRole.admin && <Navigation.Section
        items={[
          {
            url: '/settings',
            label: 'Settings',
            icon: SettingsMajor,
          },
        ]}
        separator
      />}
    </Navigation>);
}

function CaazamTopBar({ user, showNavigation, toggleMobileNavigationActive, signOut }) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleIsUserMenuOpen = () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen);


  return (
    <TopBar
      showNavigationToggle={showNavigation}
      userMenu={user && <UserMenu user={user} isUserMenuOpen={isUserMenuOpen} toggleIsUserMenuOpen={toggleIsUserMenuOpen} signOut={signOut} />}
      //searchResultsVisible={searchActive}
      //searchField={searchFieldMarkup}
      //searchResults={searchResultsMarkup}
      //onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={showNavigation && toggleMobileNavigationActive}
    />
  );
}

export default function AdminPage({ showNavigation = true, children }) {
  const { user, userData, authLoading, isAdminAppUser, isHost, signOut, shopOrigin, shopsWithPermissions, adminRole } = useAppAuthState();
  const history = useHistory();
  const { shopData, subData, subDataLoading } = useShopProvider();
  const matchOnboard = useRouteMatch('/onboard/:step?');
  const matchCalendar = useRouteMatch('/calendar');

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false)
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const isWaitingForCriticalShopData = isAdminAppUser && (!shopData || subDataLoading);

  if (authLoading || (user && isWaitingForCriticalShopData)) {
    // if auth is loading or we have an admin user and no shop data then wait
    return <Frame topBar={<CaazamTopBar />}><Loading /></Frame>;
  } else if (!authLoading && !user) {
    // if we don;t have a user then reroute to landing
    return (<Redirect to="/landing" />)
  }

  if (adminRole === UserRole.calendarAdmin && !matchCalendar?.isExact) {
    return (<Redirect to="/calendar" />)
  }

  const userForTopBar = {
    email: user.email,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    avatar: userData?.avatarUrl,
  }

  const logo = {
    height: 42,
    topBarSource: isMobile ? '/boutiq_dark.svg' : '/boutiq_light.svg',
    url: '/',
    accessibilityLabel: 'Boutiq',
    contextualSaveBarSource: '/boutiq_dark.svg',
  };

  const showPermissionsModal = user && !isAdminAppUser;
  const showSubscriptionModal = !showPermissionsModal && adminRole === UserRole.admin && !subData && !matchOnboard;

  return (
    <Frame
      topBar={<CaazamTopBar user={userForTopBar} showNavigation={showNavigation} toggleMobileNavigationActive={toggleMobileNavigationActive} signOut={signOut}/>}
      logo={logo}
      navigation={showNavigation && <CaazamNavigation shopList={shopsWithPermissions} isHost={isHost} adminRole={adminRole} />}
      showMobileNavigation={showNavigation && mobileNavigationActive}
      onNavigationDismiss={showNavigation && toggleMobileNavigationActive}
    >
      <PermissionsModal
        open={showPermissionsModal}
        onAction={signOut}
        userEmail={user.email}
        shopId={shopOrigin}
      />
      <SubscriptionModal
        open={showSubscriptionModal}
        shopId={shopOrigin}
        isAdmin={true}
        onboardingAction={() => history.replace('/onboard')}
        signOutAction={signOut}
      />
      {isAdminAppUser && children}
      <FooterHelp>
        Need help?{' '}<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact us</a>
      </FooterHelp>
    </Frame>
  )
}
