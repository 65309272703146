import React, { useEffect, useState } from 'react';
import {
  Text,
  TextContainer,
  Subheading, Layout, Stack, Box, Card, EmptyState, Banner
} from '@shopify/polaris';
import AppPage from '../../../../components/AppPage'
import AdminPage from '../../../../components/AdminPage'
import { useHistory, Link } from "react-router-dom";
import { useStoriesProvider } from '../../components/StoryiesProvider';
import VideoStoryCardSkeleton from '../../components/VideoStoryCardSkeleton';
import { VideoLibrary } from '../../components/VideoLibrary';
import { VideoUploader } from '../../components/VideoUploader';
import { StoryPreviewModal } from '../../components/StoryPreviewModal';
import { useShopProvider } from '../../../../components/ShopProvider';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import StoryPreviewCard from './StoryPreviewCard';
import FeatureEnableBanner from '../../components/FeatureEnableBanner';

function ShoppableStories() {
  const history = useHistory();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const { shopVideos, shopVideosLoading, shopOrigin, storiesAppConfig, storiesAppConfigLoading } = useShopProvider();
  const { videoStoryLoading, onViewStoryPage, onDiscardStoryChanges } = useStoriesProvider()
  const [uploadingStates, setUploadingStates] = useState([])
  const [previewStory, setPreviewStory] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const { shopVideoStories } = useStoriesProvider()
  const stories = shopVideoStories.filter(({ useAsBoutiqClientStory }) => !useAsBoutiqClientStory)

  useEffect(() => {
    if (!storiesAppConfigLoading && !storiesAppConfig?.isBlockEnabled) {
      setShowBanner(true);
    }
  }, [storiesAppConfig, storiesAppConfigLoading]);

  const onClearUploadingState = (video) => {
    setUploadingStates(prev => prev.filter(({ videoId }) => videoId !== video.id))
  }

  const emptyStateUploadAction = {
    content: 'Upload video',
    onAction: () => setIsUploadDialogOpen(true)
  }

  const deleteVideo = async (videoId) => {
    if (!videoId) return;
    await Firebase.firestore().collection('shops').doc(shopOrigin).collection('videos').doc(videoId).delete()
  }

  const storyContainer = () => (
    stories.length === 0 ? (
      <EmptyState
        heading="Create a new shoppable video"
        action={createStoryAction}
      >
        <p>Create your first video, feature a product or welcome your clients, and publish to your online store</p>
      </EmptyState>
    ) : (
      stories.map((story, index) => (
        <StoryPreviewCard
          key={index}
          story={story}
          onPreview={() => setPreviewStory(story)}
          onViewStoryPage={() => onViewStoryPage(story)}
        />
      ))
    )
  )

  const createStoryAction = {
    content: 'Create video',
    onAction: () => {
      onDiscardStoryChanges()
      history.push({ pathname: '/stories/sequence', state: {} })
    },
    disabled: !storiesAppConfig?.isBlockEnabled,
  }

  return (
    <AppPage
      title='Shoppable Videos'
      primaryAction={stories.length > 0 && createStoryAction}
    >
      <Layout>
        <Layout.Section>
          <Banner status="info" title="Boutiq Shoppable Videos beta">
            <Text>Boutiq Shoppable Videos is available free of charge during the beta program.</Text>
            <Text>At the end of the beta program this feature will require a paid subscription.</Text>
          </Banner>
        </Layout.Section>
        <Layout.Section>
          <Stack vertical>
            <Box style={{ padding: '0 1rem' }}>
              <TextContainer spacing='tight'>
                <Text color='subdued'>Boutiq Shoppable Videos are short videos in "story" format you can use to welcome your customers and feature your products.</Text>
                <Text color='subdued'>You can set them to appear on any page of your online store.</Text>
                <Text color='subdued'>You can also <Link to={'/stories/boutiq'}>add a video introduction</Link> to the Boutiq client app.</Text>

              </TextContainer>
            </Box>
            {showBanner && <FeatureEnableBanner isBlockEnabled={storiesAppConfig?.isBlockEnabled} onClose={() => setShowBanner(false)} />}
            {storiesAppConfig?.isBlockEnabled && <Card
              sectioned
              title={<Subheading>Shoppable Videos</Subheading>}
            >
              {shopVideosLoading || videoStoryLoading || storiesAppConfigLoading ? <VideoStoryCardSkeleton /> : storyContainer()}
            </Card>}
            <Card
              sectioned
              title={`Video library (${shopVideos.length})`}
              actions={[{
                content: 'Upload video',
                onAction: () => setIsUploadDialogOpen(true),
                disabled: shopVideosLoading && !shopVideos.length
              }
              ]}
            >
              <VideoLibrary
                uploadingStates={uploadingStates}
                uploadAction={emptyStateUploadAction}
                deleteVideo={deleteVideo}
                uploadVideoStory={() => setIsUploadDialogOpen(true)}
                isStoryPage={true}
                onClearUploadingState={onClearUploadingState}
              />
            </Card>
          </Stack>
        </Layout.Section>
      </Layout>
      <VideoUploader
        setUploadingStates={setUploadingStates}
        uploadingStates={uploadingStates}
        isOpen={isUploadDialogOpen}
        setIsOpen={setIsUploadDialogOpen}
      />
      <StoryPreviewModal
        stories={previewStory?.videos}
        isOpen={!!previewStory}
        setIsOpen={setPreviewStory}
        isBoutiqClientStory={previewStory?.useAsBoutiqClientStory}
      />
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <ShoppableStories />
    </AdminPage>
  )
}
