import React, { useState } from "react";
import { STORY_STATUS } from "../StatusVisualEffects/consts";
import './styles.css';

export const VideoCardPoster = ({ status, url, children }) => {

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showEmptyImage, setShowEmptyImage] = useState(false);


    const fallbackToInitials = () => {
        setShowEmptyImage(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }


    return (
        <>
            {showEmptyImage ? <div className='image-error-message-container'>
                <p>Image loading error</p>
            </div> :
            <img
                onError={() => fallbackToInitials()}
                onLoad={animateImageOnLoad}
                className={`card__body-cover-image${isImageLoaded ? ' loaded' : ''}${status === STORY_STATUS.pending ? ' unactive' : ''}`}
                src={url} />}
            {children}
        </>
    )
}
