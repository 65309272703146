import React, { useMemo, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { Stack, TextStyle, Layout, Card, List, Button, TextContainer, Banner, SkeletonBodyText, Text, Select, Grid } from '@shopify/polaris';

import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import AddButton from './CustomCode/AddButton';
import AddLink from './CustomCode/AddLinks';
import VimeoVideoModal from '../../../components/VimeoVideoModal';

import { useShopProvider } from '../../../components/ShopProvider';
import { useClientSettingsProvider } from './ClientSettingsProvider';
import { getCallPolicy, getCallPolicyDescription } from '../../../components/CallPolicyConfig';



function CustomCodePage({ }) {
    const { schedulingConfig, shopOrigin } = useShopProvider();
    const history = useHistory();
    const policy = getCallPolicy(schedulingConfig);
    const currentGlobalConfig = getCallPolicyDescription(policy);
    const [videoModal, showVideoModal] = useState(false);
    const { shopifyTheme, shopifyThemeLoading, verifyingSections, supportsThemeSections, headlessConfigLoading, headlessConfig } = useClientSettingsProvider();
    const [templateFile, setTemplateFile] = useState(null);

    const themeEditorDeepLink = useMemo(() => {
        let target = 'newAppsSection';
        if (templateFile === 'product') {
            const productTemplate = shopifyTheme?.sectionSupport.find(s => s.templateFilename === 'product');
            if (productTemplate?.acceptsAppBlock) {
                target = 'mainSection'
            }
        }
        return `https://${shopOrigin}/admin/themes/current/editor?template=${templateFile}&addAppBlockId=${process.env.REACT_APP_BOUTIQ_SHOPIFY_APP_EXT_ID}/boutiq-cta-button&target=${target}`;

    }, [templateFile, shopifyTheme])

    function renderThemeEditorContent() {
        if (shopifyThemeLoading || verifyingSections || headlessConfigLoading) {
            return (
                <Layout.Section>
                    <Card sectioned title="Learn how to add Boutiq actions to your online store">
                        <SkeletonBodyText />
                    </Card>
                </Layout.Section>
            )
        }

        if (headlessConfig?.isHeadless) return null;

        if (supportsThemeSections) {
            return (
                <Layout.Section>
                    <Card sectioned title="How to add a Boutiq button to your online store using the theme editor">
                        <Stack vertical>
                            <Grid>
                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                    <Stack vertical>
                                        <Text>You can add a Boutiq button to any online store page (example: product page) that will activate the Boutiq client app to start a new video call and/or schedule a future call.</Text>
                                        <Text>Simply add the <Text as='span' fontWeight='semibold'>Boutiq CTA Button</Text> section to an existing or new theme template and configure its appearance inside the theme editor.</Text>
                                    </Stack>
                                </Grid.Cell>
                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                    <img src='/settings/online_store_button.png' alt='example boutiq button on page' width='100%' style={{ border: '1px solid #acacac' }} />
                                </Grid.Cell>
                            </Grid>
                            <Stack vertical spacing='tight'>
                                <Stack alignment='center'>
                                    <Text variant='headingSm'>Select a template to add a Boutiq button:</Text>
                                    <Select
                                        options={[{ label: 'Select', value: null},...shopifyTheme.sectionSupport.map(s => ({ label: s.templateFilename, value: s.templateFilename }))]}
                                        onChange={setTemplateFile}
                                        value={templateFile}
                                    />
                                    <Button url={themeEditorDeepLink} external plain disabled={!templateFile}>Add to template</Button>
                                </Stack>
                                <Stack alignment='center'>
                                    <Text variant='headingSm'>OR create a new template in the theme editor and add the <Text as='span' fontWeight='semibold'>Boutiq CTA Button</Text> section yourself:</Text>
                                    <Button plain url={`https://${shopOrigin}/admin/themes/current/editor`} external>Open theme editor</Button>
                                </Stack>
                                <Text>Don't forget to save your changes in the theme editor after configuring the Boutiq button.</Text>
                            </Stack>
                        </Stack>
                    </Card>
                </Layout.Section>)

        } else {
            return null;
            return (
                <Layout.Section>
                    <Card sectioned title="How to add a Boutiq button to your online store using the theme editor">
                        <Stack vertical>
                            <Grid>
                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                    <Stack vertical>
                                        <Banner status='warning'>Your online store is using the theme <Text as='span' fontWeight='semibold'>{shopifyTheme?.theme?.name ?? 'unknown'}</Text> that does not support JSON templates and adding app blocks to theme templates. </Banner>
                                        <Text>Adding a Boutiq button using the theme editor either requires an Online Store 2.0 theme or theme code changes by your theme admin.</Text>
                                        <Text>Please see below for instructions or <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact Boutiq support</a> for further information.</Text>
                                    </Stack>
                                </Grid.Cell>
                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                                    <img src='/settings/online_store_button.png' alt='example boutiq button on page' width='100%' style={{ border: '1px solid #acacac' }} />
                                </Grid.Cell>
                            </Grid>
                        </Stack>

                    </Card>
                </Layout.Section>)
        }
    }

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Buttons &amp; custom code'
        >
            <Layout>
                {renderThemeEditorContent()}
                <Layout.Section>
                    <Card sectioned title="How to add a Boutiq button to your online store code">
                        <Card.Section>
                            <Stack vertical>
                                <p>You can add Boutiq actions to links, buttons and other HTML elements in your online store code that will activate the Boutiq client app to start a new video call and/or schedule a future call.</p>
                                <Banner status='warning'>
                                    It's recommended that code changes to your theme be made by individuals who have experience editing online store theme code.
                                </Banner>
                                <p>There are two actions that can be used to envoke the Boutiq client app from your online store:</p>
                                <List type='number'>
                                    <List.Item>
                                        <Stack vertical>
                                            <Stack alignment='baseline'>
                                                <span className='code-snippet code-background'>boutiqApp.open()</span>
                                                <p>open the Boutiq client for instant and/or scheduled calls based on the global configuration.</p>
                                            </Stack>
                                            <Stack alignment='center'>
                                                <p><TextStyle variation={null}>Your current global setting is: <TextStyle variation='strong'>{currentGlobalConfig}</TextStyle></TextStyle></p>
                                                <Button plain url='/callPolicyConfig'>(change)</Button>
                                            </Stack>
                                            <div style={{ marginBottom: '0.5em' }} />
                                        </Stack>
                                    </List.Item>
                                    <List.Item>
                                        <Stack vertical>
                                            <Stack alignment='baseline'>
                                                <span className='code-snippet code-background'>boutiqApp.openPreset(<span style={{ fontStyle: 'italic' }}>code</span>)</span>
                                                <p>open the Boutiq client using a call link configuration.</p>
                                            </Stack>
                                            <TextStyle variation={null}>
                                                <TextContainer spacing='tight'>
                                                    <p>Each <Link to='/shop_links' >Boutiq Call Link</Link> creates a preset code that can open the Boutiq client with the call link's configuration.</p>
                                                    <p>View the call link's details to get its specific preset code and view detailed instructions on how to apply it.</p>
                                                </TextContainer>
                                            </TextStyle>
                                            <div style={{ marginBottom: '0.5em' }} />
                                        </Stack>
                                    </List.Item>
                                </List>
                                {/* <Banner>
                                    <p><Button plain monochrome onClick={() => showVideoModal(true)}>View a short video tutorial</Button> on how to add Boutiq actions to your online store</p>
                                </Banner> */}
                            </Stack>
                        </Card.Section>
                        <AddButton />
                        <AddLink />
                    </Card>
                    <VimeoVideoModal
                        open={videoModal}
                        onClose={() => showVideoModal(false)}
                        videoUrl='https://player.vimeo.com/video/740804539#t=05m12s?h=887c30bd41&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                        videoTitle='Integration Options'
                    />
                </Layout.Section>
            </Layout>
        </AppPage>
    )
}

export default function () {
    return (
        <AdminPage>
            <CustomCodePage />
        </AdminPage>
    )
}