import React, {useEffect, useMemo, useState} from 'react';
import {
  Card,
  Stack,
  Text,
  SkeletonBodyText,
  Avatar,
  Banner,
  Box,
  Thumbnail,
} from '@shopify/polaris';
import {useShopProvider} from "../../../../components/ShopProvider";
import useCallTranscriptData from '../../../../hooks/useCallTranscriptData';
import { UserRole } from '../../../../utils/permissions';
import { ECommActionType, NO_VARIANT_TITLE } from '../../../../utils/consts';
import { currencyFormatter } from '../../../../utils/formatters';


export default function TranscriptView({ transcript }) {

    const { hosts } = useShopProvider();
    const { transcriptData, transcriptLoading, transcriptError } = useCallTranscriptData(transcript?.uriJson);

    const host = useMemo(()=>{
        const source =  transcript.sources.find(s => s.participant.role === UserRole.host);
        if (source) {
            return hosts.find(h => h.id === source.participant.uuid);
        } else {
            return null;
        }

    }, [transcript, hosts]);

    return (
        <Card title='Call transcript' sectioned>
            <Card.Section>
                <Stack vertical>
                    {transcriptLoading && <SkeletonBodyText />}
                    {!transcriptLoading &&
                        <Stack vertical>
                            {transcriptData.map(m => <TranscriptMessage message={m} host={host} key={m.startOffset}/>)}
                        </Stack>
                    }
                </Stack>
            </Card.Section>
        </Card>
    )
}

function getInitials(displayName) {
    return displayName.split(' ').map(w => w?.[0] ?? '').slice(0,2).join('').toUpperCase();
}

function timeFormatter(time)
{
    const timeInSec = parseInt(time);
    var hours   = Math.floor(timeInSec / 3600);
    var minutes = Math.floor((timeInSec - (hours * 3600)) / 60);
    var seconds = timeInSec - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

function TranscriptMessage({ message, host }) {

    const initials = getInitials(message.displayName);
    const isHost = message.role === UserRole.host;
    return (
        <Box
          style={{
              display: 'grid',
              gridTemplateColumns: isHost ? '70% 50px' : '50px 70%',
              justifyContent: isHost ? 'end' : 'start',
              justifyItems: isHost ? 'end' : 'start',
          }}
        >
            {!isHost && (
              <Box>
                  <Avatar name={message.displayName} initials={initials} />
              </Box>
            )}
            <Stack distribution={isHost && 'trailing'} alignment='leading'>
                <Stack vertical spacing='extraTight'>
                    <Text alignment={isHost && 'end'} color='subdued' variant='bodySm'>{timeFormatter(message.startOffset)}</Text>
                    {!message.event && <Text alignment={isHost && 'end'}>{message.content.join(' ')}</Text>}
                    {message.event && <TranscriptMessageEvent event={message.event} isHost={isHost}/>}
                </Stack>
            </Stack>
            {isHost && (
              <Box>
                  {host
                    ? <Avatar source={host.avatarUrl ? host.avatarUrl : null} name={host.firstName} initials={`${host.firstName && host.firstName[0]}${host.lastName && host.lastName[0]}`} />
                    : <Avatar name={message.displayName} initials={initials} />
                  }
              </Box>
            )}
        </Box>
    )
}

function chatMessageForEvent(event) {

    function getVariantTitle(event) {
        return event.item.variantTitle === NO_VARIANT_TITLE
            ? event.item.productTitle
            : event.item.productTitle + ' ' + event.item.variantTitle
    }
        // TODO: deal with different languages/locales currently only support 'en'
    switch (event.action) {
        case ECommActionType.showroomAdd:
            return `I've added the product ${event.item.productTitle} to the call's showroom.`;
        case ECommActionType.showroomDelete:
            return `I've removed the product ${event.item.productTitle} to the call's showroom.`;
        case ECommActionType.cartAdd:
            {
                const variantTitle = getVariantTitle(event);
                return `I've added the product ${variantTitle} with the cost of ${event.item.currentPrice} ${event.item.currencyCode} to the call's cart.`;
            }
        case ECommActionType.cartUpdate:
            {
                const variantTitle = getVariantTitle(event);
                return `I've updated the product ${variantTitle} with the cost of ${event.item.currentPrice} ${event.item.currencyCode} in the call's cart.`;
            }
        case ECommActionType.cartDelete:
            {
                const variantTitle = getVariantTitle(event);
                return `I've removed the product ${variantTitle} with the cost of ${event.item.currentPrice} ${event.item.currencyCode} from the call's cart.`;
            }
        case ECommActionType.checkout:
            return `I've started a checkout for call's cart.`;
        case ECommActionType.order:
            return <Text as='span'>I've created an order for the total of {' '}
                <Text as='span' fontWeight='semibold'>
                    {currencyFormatter(event.order.currencyCode).format(event.order.orderAmount)}
                </Text>
            </Text>;
        default:
            break;
    }
}

function chatTitleForEvent(action) {

        // TODO: deal with different languages/locales currently only support 'en'
    switch (action) {
        case ECommActionType.showroomAdd:
            return `Add to Showroom`;
        case ECommActionType.showroomDelete:
            return `Remove from Showroom`;
        case ECommActionType.cartAdd:
            return `Add to cart`;
        case ECommActionType.cartUpdate:
            return `Update to cart`;
        case ECommActionType.cartDelete:
            return `Remove from cart`;
        case ECommActionType.checkout:
            return `Cart checkout`;
        case ECommActionType.order:
            return `Order created`;
        default:
            break;
    }
}

function TranscriptMessageEvent({ event, isHost }) {

    function getBannerStatus(action) {
        switch (action) {
            case ECommActionType.checkout:
                return 'warning';
            case ECommActionType.order:
                return 'success';
            default:
                return null;
        }
    }
    return (
        <Banner title={chatTitleForEvent(event.action)} status={getBannerStatus(event.action)}>
            <Text>{chatMessageForEvent(event)}</Text>
            <Stack distribution={isHost ? 'trailing' : null}>
                {event.item?.image && <Thumbnail source={event.item.image} size='medium' />}
            </Stack>
        </Banner>
    )
}
