const positionBySteps = (start, end, step, steps) => {
    return Math.max(0, Math.min(end, easeOutQuad(start, end, step, steps)));
}

const easeOutQuad = (start, end, t, steps) => {
    t /= steps;
    t--;
    return Math.round(-end * (t * t * t * t - 1) + start);
}

export const smoothScrollBySteps = (el, startPosition, endPosition, axis = 'top', direction = 1) => {
    return new Promise(function (resolve) {
        let step = 0;
        const start = 0;
        const end = direction > 0 ? endPosition - startPosition : startPosition - endPosition;
        const currentSteps = 25;
        let requestID;

        const animate = () => {
            const contentPosition = positionBySteps(
                start,
                end,
                step,
                currentSteps);
            if (step <= currentSteps) {
                step++;
                let scrollObj;
                if (axis === 'top') {
                    scrollObj = {
                        top: startPosition + direction * contentPosition,
                        left: 0
                    }
                } else {
                    scrollObj = {
                        top: 0,
                        left: startPosition + direction * contentPosition
                    }
                }
                el.scrollTo(scrollObj)
                requestID = requestAnimationFrame(() => {
                    animate();
                });
            } else {
                cancelAnimationFrame(requestID);
                resolve();
            }
        }
        requestID = requestAnimationFrame(animate);
    });
}
