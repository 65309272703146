import {utcStartDay, utcEndDay} from "../../utils/formatters";

export const callEventsQuery = ({dateFrom, dateTo, tz = 'UTC', hosts = []}) => {
  const query = {
    timezone: tz,
    order: {
      'CallEvents.callId': 'desc',
    },
    timeDimensions: [
      {
        dimension: 'Calls.timestamp',
        granularity: "day",
        dateRange: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ]
      },
    ],
    dimensions: [
      "CallEvents.callId",
      "CallEvents.shopId",
      "CallEvents.airtime",
      "Calls.hostId",
    ],
    measures: [
      "CallEvents.airTimeCustomersTotal",
      "CallEvents.airTimeHostTotal",
      "CallEvents.airTimeTotal",
    ],
  };

  if (hosts && hosts.length > 0) {
    query.filters = [
      {
        member: "Calls.hostId",
        operator: "equals",
        values: hosts
      },
    ]
  }

  return query;
};
