import React, { useEffect, useState, useMemo }  from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, SkeletonBodyText, Stack, TextStyle, ContextualSaveBar, Text} from '@shopify/polaris';


import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';
import { useShopProvider } from '../../../components/ShopProvider';
import useShopIntegrationsConfig, { ShopIntegrations } from '../../../hooks/useShopIntegrationsConfig';

import { GoogleEventTracking, FacebookEventTracking } from '../../../components/EventsTracking';
import KlaviyoTrackingConfig from '../../../components/KlaviyoTracking';
import SegmentTrackingConfig from '../../../components/SegmentTracking';
import EndearConfig from '../../../components/EndearConfig';
import useRecordsRules from '../../../hooks/useRecordsRules';

import './styles.css';

export function IntegrationsSummary({ shopId }) {

    const { onlineStoreConfig, onlineStoreConfigLoading} = useShopProvider()
    const { integrations, integrationsLoading } = useShopIntegrationsConfig(shopId);

    if (onlineStoreConfigLoading || integrationsLoading) {
        return <SkeletonBodyText />
    }

    return <Stack vertical spacing='tight'>
        <Stack alignment='center'>
            <img src='/integrations/google_analytics.png' className='integ-logo-icon' />
            <p>Google Analytics account
                {onlineStoreConfig && onlineStoreConfig.eventsGoogleTrackingId
                    ? <TextStyle variation='strong'> set to {onlineStoreConfig.eventsGoogleTrackingId}</TextStyle>
                    : " not set"
                }
            </p>
        </Stack> 
        {onlineStoreConfig && onlineStoreConfig.eventsFacebookTrackingId &&
            <Stack alignment='center'>
                <img src='/integrations/facebook.svg' className='integ-logo-icon' />
                <p>Facebook Pixel
                    <TextStyle variation='strong'> ID {onlineStoreConfig.eventsFacebookTrackingId}</TextStyle>
                </p>
            </Stack>     
        }
        {integrations && Object.keys(integrations).map(integId => (
            <Stack alignment='center' key={integId}>
                {ShopIntegrations[integId].logo && <img src={ShopIntegrations[integId].logo} className='integ-logo-icon' />}
                <p key={integId}>{integrations[integId].name} integration { integrations[integId].error ? 'not active' : <TextStyle variation='strong'>active</TextStyle>}</p>
            </Stack>
        ))}
    </Stack>
}

function IntegrationSettings() {

    const history = useHistory();
    const { shopOrigin, onlineStoreConfig, onlineStoreConfigLoading, saveOnlineStoreConfig } = useShopProvider()
    const { integrations, integrationsLoading, saveIntegrationConfig } = useShopIntegrationsConfig(shopOrigin);
    const { rules } = useRecordsRules(shopOrigin);
    const endearConfig = useMemo(()=> integrations?.['endear'],[integrations]);
    const klaviyoConfig = useMemo(()=> integrations?.['klaviyo'],[integrations]);
    const segmentConfig = useMemo(()=> integrations?.['segment'],[integrations]);
    
    const [editGoogleTrackingId, setEditGoogleTrackingId] = useState(false);
    const [googleTrackingId, setGoogleTrackingId] = useState();
    const [googleTrackingIdError, setGoogleTrackingIdError] = useState();
    const [pixelsLoading, setPixelsLoading] = useState(true);

    const [editFacebookTrackingId, setEditFacebookTrackingId] = useState(false);
    const [facebookTrackingId, setFacebookTrackingId] = useState();
    //const [facebookTrackingIdError, setFacebookTrackingIdError] = useState();
    
    const [editKlaviyoKey, setEditKlaviyoKey] = useState(false);
    const [klaviyoPrivateKey, setKlaviyoPrivateKey] = useState();
    const [klaviyoKeyError, setKlaviyoKeyError] = useState();
    const [klLoading, setKlLoading] = useState(true);

    const [editSegmentKey, setEditSegmentKey] = useState(false);
    const [segmentWriteKey, setSegmentWriteKey] = useState();
    const [segmentKeyError, setSegementKeyError] = useState();
    const [segmentLoading, setSegementLoading] = useState(true);
    
    const [editEndearToken, setEditEndearToken] = useState(false);
    const [endearToken, setEndearToken] = useState();
    const [endearTokenError, setEndearTokenError] = useState();
    const [endearOptions, setEndearOptions] = useState(null);

    const [saving, setSaving] = useState(false);
    const [dirtyBit, setDirtyBit] = useState(false);

    useEffect(() => {
        if (!onlineStoreConfigLoading) {
            setGoogleTrackingId(onlineStoreConfig.eventsGoogleTrackingId);
            setFacebookTrackingId(onlineStoreConfig.eventsFacebookTrackingId);
            setPixelsLoading(false);
            setEditGoogleTrackingId(!onlineStoreConfig.eventsGoogleTrackingId);
            setEditFacebookTrackingId(!onlineStoreConfig.eventsFacebookTrackingId);
        }
    }, [onlineStoreConfig, onlineStoreConfigLoading])

    useEffect(() => {
        if (!integrationsLoading) {
            setKlaviyoPrivateKey(klaviyoConfig && klaviyoConfig.privateAPIKey);
            setKlLoading(false);
            setEditKlaviyoKey(!(klaviyoConfig && klaviyoConfig.privateAPIKey));
            
            setSegmentWriteKey(segmentConfig?.writeAPIKey);
            setSegementLoading(false);
            setEditSegmentKey(!segmentConfig?.writeAPIKey);
        }
    }, [klaviyoConfig, segmentConfig, integrationsLoading])

    useEffect(() => {
        if (!integrationsLoading) {
            setEndearToken(endearConfig?.token);
            setEditEndearToken(!(endearConfig?.token));
            setEndearTokenError(endearConfig?.error ?? null);
            setEndearOptions(endearConfig?.options);
        }

    }, [integrations, integrationsLoading])

    useEffect(() => {
        let bit = false;
        if (onlineStoreConfig) {
            bit = bit || googleTrackingId !== onlineStoreConfig.eventsGoogleTrackingId;
            bit = bit || facebookTrackingId !== onlineStoreConfig.eventsFacebookTrackingId;
        }
        else {
            bit = bit || !!googleTrackingId;
            bit = bit || !!facebookTrackingId;
        }

        if (klaviyoConfig)
            bit = bit || klaviyoPrivateKey !== klaviyoConfig.privateAPIKey
        else
            bit = bit || !!klaviyoPrivateKey;

        if (endearConfig) {
            bit = bit || endearToken !== endearConfig.token
            bit = bit || !!endearOptions?.assignHosts !==  !!endearConfig?.options?.assignHosts || !!endearOptions?.insertSummaries !==  !!endearConfig?.options?.insertSummaries 
        }
        else {
            bit = bit || !!endearToken;
            bit = bit || !!endearOptions;
        }

        if (segmentConfig)
            bit = bit || segmentWriteKey !== segmentConfig.writeAPIKey
        else
            bit = bit || !!segmentWriteKey;
 
        setDirtyBit(bit);
    }, [googleTrackingId, facebookTrackingId ,onlineStoreConfig , klaviyoPrivateKey, klaviyoConfig, segmentWriteKey, segmentConfig, endearConfig, integrations, endearToken, endearOptions ])

    const onGoogleTrackingIdChange = (val) => {
        setGoogleTrackingIdError(null);
        setGoogleTrackingId(val);
    }

    const onFacebookTrackingIdChange = (val) => {
        //setFacebookTrackingIdError(null);
        setFacebookTrackingId(val);
    }

    const onKlaviyoKeyChange = (val) => {
        setKlaviyoKeyError(null);
        setKlaviyoPrivateKey(val);
    }

    const onSegmentKeyChange = (val) => {
        setSegementKeyError(null);
        setSegmentWriteKey(val);
    }

    const onEndearTokenChange = (val) => {
        setEndearTokenError(null);
        setEndearToken(val);
    }

    const save = async () => {
        try {
            setSaving(true);
            if (googleTrackingId && !/^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/i.test(googleTrackingId)) {
                throw new Error('Analytics account ID does not look valid (UA-xxxx-x or G-xxxxxxx)');
            } else {
                await saveOnlineStoreConfig({ 
                    eventsGoogleTrackingId: googleTrackingId || null ,
                    eventsFacebookTrackingId: facebookTrackingId || null 

                });
            }
        } catch (error) {
            console.error('EventSettings error saving tracking ID', error);
            setGoogleTrackingIdError(error.message);
        }

        try {
            await saveIntegrationConfig(ShopIntegrations.klaviyo.id, { privateAPIKey: klaviyoPrivateKey || null });
        } catch (error) {
            console.error('EventSettings error saving klaviyo key', error);
            setKlaviyoKeyError(error.message);
        }

        try {
            await saveIntegrationConfig(ShopIntegrations.segment.id, { writeAPIKey: segmentWriteKey || null });
        } catch (error) {
            console.error('EventSettings error saving segment key', error);
            setSegementKeyError(error.message);
        }

        try {
            await saveIntegrationConfig(ShopIntegrations.endear.id, { token: endearToken ?? null, options: endearOptions ?? null });            
        } catch (error) {
            console.error('EventSettings error saving endear token', error, error.code,  error.details);
            setEndearTokenError(error.details || error.message);
        }

        setSaving(false);
     };

    const resetGoogleTrackingId = () => {        
        setGoogleTrackingId(onlineStoreConfig.eventsGoogleTrackingId);
        setEditGoogleTrackingId(!onlineStoreConfig.eventsGoogleTrackingId);
        setGoogleTrackingIdError(null);
    }

    const resetFacebookTrackingId = () => {        
        setFacebookTrackingId(onlineStoreConfig.eventsFacebookTrackingId);
        setEditFacebookTrackingId(!onlineStoreConfig.eventsFacebookTrackingId);
        //setFacebookTrackingIdError(null);
    }

    const resetKlaviyoKey = () => { 
        setKlaviyoPrivateKey(klaviyoConfig && klaviyoConfig.publicAPIKey);
        setEditKlaviyoKey(!(klaviyoConfig && klaviyoConfig.publicAPIKey));
        setKlaviyoKeyError(null);
    }

    const resetSegmentKey = () => { 
        setSegmentWriteKey(segmentConfig?.writeAPIKey);
        setEditSegmentKey(!segmentConfig?.writeAPIKey);
        setSegementKeyError(null);
    }

    const resetEndearToken = () => { 
        setEndearToken(endearConfig?.token);
        setEditEndearToken(!(endearConfig?.token));
        setEndearTokenError(null);
    }

    const resetEndear = () => { 
        resetEndearToken();
        setEndearOptions(endearConfig?.options);
    }

    const discard = () => {
        resetGoogleTrackingId();  
        resetFacebookTrackingId();  
        resetKlaviyoKey(); 
        resetEndear();
        resetSegmentKey();   
    }

    return (
        <AppPage title='Integrations' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.Section>
                    <Text variant='bodyMd' color='subdued'>Boutiq integrates with Shopify’s Customer Privacy API and adheres to the Customer Privacy settings you have selected for your Shopify Online Store. These settings dictate how Boutiq reports events for your online store visitors in the EU, EEA, UK, and Switzerland.</Text>
                </Layout.Section>
                <GoogleEventTracking
                    googleTrackingId={googleTrackingId}
                    setGoogleTrackingId={onGoogleTrackingIdChange}
                    errorMsg={googleTrackingIdError}
                    loading={pixelsLoading}
                    editMode={editGoogleTrackingId}
                    setEditMode={setEditGoogleTrackingId}
                    onReset={resetGoogleTrackingId}
                />
                <FacebookEventTracking
                    facebookTrackingId={facebookTrackingId}
                    setFacebookTrackingId={onFacebookTrackingIdChange}
                    //errorMsg={googleTrackingIdError}
                    loading={pixelsLoading}
                    editMode={editFacebookTrackingId}
                    setEditMode={setEditFacebookTrackingId}
                    onReset={resetFacebookTrackingId}
                />
                <KlaviyoTrackingConfig
                    privateAPIKey={klaviyoPrivateKey}
                    setPrivateAPIKey={onKlaviyoKeyChange}
                    errorMsg={klaviyoKeyError}
                    loading={klLoading}
                    editMode={editKlaviyoKey}
                    setEditMode={setEditKlaviyoKey}
                    onReset={resetKlaviyoKey}
                />
                <SegmentTrackingConfig
                    segmentWriteKey={segmentWriteKey}
                    setWriteKey={onSegmentKeyChange}
                    errorMsg={segmentKeyError}
                    loading={segmentLoading}
                    editMode={editSegmentKey}
                    setEditMode={setEditSegmentKey}
                    onReset={resetSegmentKey}
                />
                <EndearConfig
                    token={endearToken}     
                    customFields={integrations?.['endear']?.customFields}         
                    options={endearOptions}
                    onOptionsChange={setEndearOptions}
                    setToken={onEndearTokenChange}
                    errorMsg={endearTokenError}
                    loading={integrationsLoading}
                    editMode={editEndearToken}
                    setEditMode={setEditEndearToken}
                    onReset={resetEndearToken}
                    saving={saving}
                    boutiqAI={!!rules?.length}
                    boutiqBotUser={endearConfig?.boutiqBotUser}
                />
            </Layout>
            {(dirtyBit || saving) && <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                    onAction: save,
                    loading: saving,
                }}
                discardAction={{
                    onAction: discard,
                }}
            />}
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <IntegrationSettings/>
        </AdminPage>
    )
}
