import React from 'react';
import { useIntroPage } from '../IntroPageProvider';
import IntroContent from '../introContent/introContent';
import { VolumeOffIcon } from '../icons/volumeOf';
import { VolumeUpIcon } from '../icons/volumeUp';
import './index.css';

export default function UserActivityButtons({
    outgoingCallsAllowed,
    cornerStyle,
    isBoutiqClientStory
}) {
    const {
        isMuted,
        setIsMuted,
        isChatEnabled,
        playList,
    } = useIntroPage();

    const soundControl = () => {
        setIsMuted(!isMuted);
    }

    return (
        <div className={`intro-main-layout-wrapper intro-main-layout-wrapper-btns`}>
            <IntroContent />
            <div className='intro-main-layout'>
                <div className='main-buttons-wrapper'>
                    {isBoutiqClientStory && (
                      <>
                          <div className={`main-buttons${!outgoingCallsAllowed ? ' single' : ''}`}>
                              {outgoingCallsAllowed &&
                                <button className={`caazam-button filled-button connect ${cornerStyle}`}>Connect
                                    now</button>}
                              <button className={`caazam-button schedule ${cornerStyle}`}>Schedule</button>
                          </div>
                          <div className={`chat-link-preview-container`}>
                              {isChatEnabled && (
                                <span className='chat-link'>
                                    Or chat with our team
                                </span>
                              )}
                          </div>
                      </>
                    )}
                </div>
                {playList.length > 0 ? <button className='sound-control-container'>
                    <div className='sound-control' onClick={soundControl}>
                        {isMuted ?
                            <VolumeOffIcon className='unmuted' /> :
                            <VolumeUpIcon className='unmuted' />}
                    </div>
                </button> : null}
            </div>
        </div>
    )
}
