import React, { useEffect, useState } from 'react'
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Card, TextStyle, Stack, List, SkeletonBodyText, Select, TextContainer } from '@shopify/polaris';
import { useShopProvider } from '../../../components/ShopProvider';
import useHostSchedulingConfig from '../../../hooks/useHostSchedulingConfig';


const HostAutoAssignMethod = {
    none: 'none',
    loadBalance: 'loadBalance',
    roundRobin: 'roundRobin',
}

export default function AutoAssignConfig({ }) {
    const [autoAssignHosts, setAutoAssignHosts] = useState(HostAutoAssignMethod.none);
    const [hostCount, setHostCount] = useState(true);

    const {
        shopOrigin,
        schedulingConfig,
        schedulingConfigLoading,
    } = useShopProvider();

    const {
        hostSchedulingConfig,
        hostSchedulingConfigLoading,
    } = useHostSchedulingConfig(shopOrigin);

    useEffect(() => {
        if (!schedulingConfigLoading) {
            setAutoAssignHosts(schedulingConfig?.autoAssignHosts ?? HostAutoAssignMethod.none);
        }
    }, [schedulingConfig, schedulingConfigLoading])

    useEffect(()=>{
        if (!hostSchedulingConfigLoading) {
            setHostCount(hostSchedulingConfig.size);
        }

    }, [hostSchedulingConfig, hostSchedulingConfigLoading])

    const selectOptions = hostCount > 1
        ? [
            { label: 'None (default)', value: HostAutoAssignMethod.none },
            { label: 'Auto - Load Balancing', value: HostAutoAssignMethod.loadBalance },
            { label: 'Auto - Round Robin', value: HostAutoAssignMethod.roundRobin },
        ]
        : [
            { label: 'None (default)', value: HostAutoAssignMethod.none },
            { label: 'Auto', value: HostAutoAssignMethod.loadBalance },
        ];

    const onChange = async (val) => {
        setAutoAssignHosts(val);
        await Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('scheduling')
        .set({ autoAssignHosts: val !== HostAutoAssignMethod.none ? val : Firebase.firestore.FieldValue.delete()  }, { merge: true })
            .catch(error => console.error('AutoAssignConfig', error));
    }

    if (schedulingConfigLoading || hostSchedulingConfigLoading) {
        return (<Card sectioned><SkeletonBodyText /></Card>)
    }

    if (hostCount < 1)
        return (
            <Card sectioned>
                <p><TextStyle variation='subdued'>Setting is available only when at least one host can be assigned to scheduled appointments</TextStyle></p>
            </Card>
        )

    return (
        <Card sectioned>
            <Stack vertical>

                <p>Host auto-assignment options:</p>
                <List type='bullet' >
                    <List.Item>
                        <TextStyle variation={autoAssignHosts === HostAutoAssignMethod.none ? 'strong' : null}>
                            None: Hosts should manually assign themselves to pending appointment requests
                        </TextStyle>
                    </List.Item>
                    <List.Item>
                        <TextStyle variation={autoAssignHosts === HostAutoAssignMethod.loadBalance ? 'strong' : null}>
                            {`Auto${hostCount > 1 ? ' - Load Balancing' : ''}: appointments are auto-assigned to the available host with the fewest upcoming appointments`}
                        </TextStyle>
                    </List.Item>
                    {hostCount > 1 && <List.Item>
                        <TextStyle variation={autoAssignHosts === HostAutoAssignMethod.roundRobin ? 'strong' : null}>
                            Auto - Round Robin: appointments are auto-assigned to the available host assigned least recently
                        </TextStyle>
                    </List.Item>}

                </List>
                <Select
                    options={selectOptions}
                    onChange={onChange}
                    value={autoAssignHosts}
                    disabled={hostCount < 1}
                />
                <p><TextStyle variation='subdued'>This setting applies only to appointments that are not scheduled for/by a specific host</TextStyle> </p>
            </Stack>
        </Card>
    )
}
