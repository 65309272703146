import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

import {
    ViewMinor,
    DeleteMinor,
    ChevronDownMinor,
    ChevronUpMinor,
    TransferMajor,
} from '@shopify/polaris-icons';

import { Stack, Icon, Button, List, TextStyle, Layout, Card, Banner, SkeletonBodyText, Collapsible, Text } from '@shopify/polaris';

import AppPage from '../../../../components/AppPage'
import AdminPage from '../../../../components/AdminPage'

import { useShopProvider } from '../../../../components/ShopProvider';
import VimeoVideoModal from '../../../../components/VimeoVideoModal';
import { useClientSettingsProvider } from '../ClientSettingsProvider';

import './LandingPage.scss';
import { useMemo } from 'react';
function ShopifyLandingPage({ }) {
    const { shopOrigin } = useShopProvider();
    const history = useHistory();
    const [working, workingSet] = useState(false);
    const [apiError, apiErrorSet] = useState(null);
    const [videoModal, showVideoModal] = useState(false);
    const { shopifyTheme, shopifyThemeLoading, verifyingSections, supportsThemeSections, headlessConfig } = useClientSettingsProvider();
    const boutiqPageTemplateInstalled = shopifyTheme?.boutiqThemeInstalled?.boutiqPageTemplate;
    const currentLandingPageId = shopifyTheme?.landing?.pageId;
    const currentLandingPageUrl = shopifyTheme?.landing
        ? `https://${shopOrigin}/pages/${shopifyTheme.landing.pageHandle}`
        : null;

    const boutiqPageTemplate = useMemo(()=>{
        if (typeof shopifyTheme?.boutiqThemeInstalled?.boutiqPageTemplate === 'string') 
            return shopifyTheme?.boutiqThemeInstalled?.boutiqPageTemplate?.split('.')?.[1];
        else 
            return shopifyTheme?.boutiqThemeInstalled?.boutiqPageTemplate ? 'boutiqapp' : null;
    },[shopifyTheme])

    const setLandingPage = async (deletePage = false) => {
        workingSet(true);
        apiErrorSet(null);
        const setFunc = Firebase.functions().httpsCallable('landing-setPage');
        try {
            await setFunc({ shopId: shopOrigin, deletePage });
        } catch (error) {
            console.error(`Failed setting landing page`, error);
            apiErrorSet(error);
        } finally {
            workingSet(false);
        }
    }

    const supportLink = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Landing Page Template for ${shopOrigin}`;

    function LandingOptionCreate() {
        const [details, showDetails] = useState(false);

        return (
            <Card.Section
                title="Option 2: Create a new landing page"
            >
                <Stack vertical>
                    <Stack alignment='center'>
                        <p>Manually add a landing page to your online store using Shopify tools</p>
                        <Button plain icon={details ? ChevronUpMinor : ChevronDownMinor} onClick={() => showDetails(prev => !prev)}>{details ? 'Hide' : 'Show'} steps</Button>
                    </Stack>
                    <Collapsible open={details} id='LandingOptionOne'>
                        <List type='number'>
                            <List.Item>
                                <Stack spacing='tight'>
                                    <p>Open the <a href={`https://${shopOrigin}/admin/pages`} target='_blank' rel="noopener noreferrer">Shopify page editor</a></p>
                                    <Icon source={TransferMajor} color='subdued' />
                                </Stack>
                            </List.Item>
                            <List.Item>Add a new page and assign it the theme template named <Text as='span' fontWeight='semibold'>{boutiqPageTemplate}</Text>  - this will automatically add the Boutiq App action button to the page.</List.Item>
                            <List.Item>Edit the page content as desired.</List.Item>
                            <List.Item>
                                <Stack vertical spacing='extraTight'>
                                    <Stack spacing='tight'>
                                        <p>You can customize the template's action button in the Shopify <a href={`https://${shopOrigin}/admin/themes/${shopifyTheme?.theme?.id}/editor`} target='_blank' rel="noopener noreferrer">visual theme editor</a></p>
                                        <Icon source={TransferMajor} color='subdued' />
                                    </Stack>
                                    <List>
                                        <List.Item>Set the button's text, color, &amp; position</List.Item>
                                        <List.Item>Set the button's action to allow for calling &amp; scheduling or for scheduling only</List.Item>
                                    </List>
                                </Stack>
                            </List.Item>
                            <List.Item>
                                <Stack spacing='tight'>
                                    <p>Don't forget to add the page to your store's <a href={`https://${shopOrigin}/admin/menus`} target='_blank' rel="noopener noreferrer">navigation menus </a></p>
                                    <Icon source={TransferMajor} color='subdued' />
                                </Stack>
                            </List.Item>
                        </List>
                    </Collapsible>
                </Stack>
            </Card.Section>
        )
    }

    function LandingOptionDefault() {

        const actions = currentLandingPageId && !working
            ? [
                { content: 'View page', icon: ViewMinor, external: true, url: currentLandingPageUrl },
                { content: 'Remove page', destructive: true, icon: DeleteMinor, onAction: () => setLandingPage(true) }
            ]
            : null;

        const landingPageContentEditorUrl = `https://${shopOrigin}/admin/pages/${currentLandingPageId}`;
        const landingPageThemeEditorUrl = `https://${shopOrigin}/admin/themes/current/editor?previewPath=%2Fpages%2F${shopifyTheme?.landing?.pageHandle}`;

        return (
            <Card.Section
                title="Option 1: use the default landing page"
                actions={actions}
            >
                <Stack vertical>
                    <p>Boutiq can add a default landing page to your online store that can be easily customized using Shopify tools.</p>
                    <Stack>
                        <Stack.Item fill>
                            <Stack vertical>
                                <Button
                                    primary={!currentLandingPageId}
                                    disabled={working || currentLandingPageId}
                                    loading={working}
                                    onClick={() => setLandingPage()}
                                >
                                    {currentLandingPageId ? 'Landing Page Added' : 'Add Landing Page'}
                                </Button>
                                {apiError && <p><TextStyle variation='negative'>{apiError.message}</TextStyle> </p>}
                                {currentLandingPageId &&
                                    <Stack vertical>
                                        <p>How to customize the page:</p>
                                        <List type='number'>
                                            <List.Item>
                                                <Stack spacing='tight'>
                                                    <p>Customize the page content using the <a href={landingPageContentEditorUrl} target='_blank' rel="noopener noreferrer">Shopify page editor</a></p>
                                                    <Icon source={TransferMajor} color='subdued' />
                                                </Stack>
                                            </List.Item>
                                            <List.Item>
                                                <Stack vertical spacing='extraTight'>
                                                    <Stack spacing='tight'>
                                                        <p>You can customize the template's action button in the Shopify <a href={landingPageThemeEditorUrl} target='_blank' rel="noopener noreferrer">visual theme editor</a></p>
                                                        <Icon source={TransferMajor} color='subdued' />
                                                    </Stack>
                                                    <List>
                                                        <List.Item>Set the button's text, color, &amp; position</List.Item>
                                                        <List.Item>Set the button's action to allow for calling &amp; scheduling or for scheduling only</List.Item>
                                                    </List>
                                                </Stack>
                                            </List.Item>
                                            <List.Item>
                                                <Stack spacing='tight'>
                                                    <p>Don't forget to add the page to your store's <a href={`https://${shopOrigin}/admin/menus`} target='_blank' rel="noopener noreferrer">navigation menus </a></p>
                                                    <Icon source={TransferMajor} color='subdued' />
                                                </Stack>
                                            </List.Item>
                                        </List>
                                    </Stack>
                                }

                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Card.Section>
        )
    }

    if (headlessConfig?.isHeadless) {
        return null;
    }
    
    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Landing page settings'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <div className='landing-header'>
                            <div className='landing-header-text'>
                                <Stack vertical spacing='extraLoose'>
                                    <p >Add a dedicated personal video shopping landing page so your clients can easily find, start or schedule personal video shopping sessions. </p>
                                    <Banner>
                                        <p><Button plain monochrome onClick={() => showVideoModal(true)}>View a short video tutorial</Button> on how to add a landing page to your online store</p>
                                    </Banner>
                                </Stack>
                            </div>
                            <img className='landing-header-image' src='/shopifyLanding/landing_default_image.png' alt='default landing page' width='360px' />
                        </div>
                    </Card>
                    <Card sectioned title='Build your own custom landing page (recommended)'>
                        <Card.Section flush>
                            <Stack vertical spacing='tight'>
                                <p>For the most personal and on-brand experience for your customers, you can build your own custom page and add the required Boutiq actions to buttons and other page elements.</p>
                                {supportsThemeSections &&
                                    <p>Simply add the <Text as='span' fontWeight='semibold'>Boutiq CTA Button</Text> section to an existing or new page template and configure its appearance inside the theme editor.</p>
                                }
                                <Button plain onClick={() => history.push('/clientSettings/customCodeConfig')}>See how</Button>
                            </Stack>
                        </Card.Section>

                    </Card>
                    <Card sectioned title='Use a pre-installed landing page template'>
                        {shopifyThemeLoading && <SkeletonBodyText />}
                        {!shopifyThemeLoading &&
                            <>
                                <Card.Section>
                                    <Stack vertical>
                                        <p><TextStyle variation={boutiqPageTemplateInstalled ? null : 'subdued'}>
                                            Use the pre-installed Boutiq App page template to easliy create and edit a landing page using Shopify tools.
                                        </TextStyle>
                                        </p>
                                        {!boutiqPageTemplateInstalled &&
                                            <Banner status='warning'>
                                                The pre-installed template is not available for your store. Please <a href={supportLink} target='_blank' rel="noopener noreferrer">contact us</a> for more details.
                                            </Banner>
                                        }
                                    </Stack>
                                </Card.Section>
                                {boutiqPageTemplateInstalled &&
                                    <LandingOptionDefault />
                                }
                                {boutiqPageTemplateInstalled &&
                                    <LandingOptionCreate />
                                }
                            </>
                        }
                    </Card>
                    <VimeoVideoModal
                        open={videoModal}
                        onClose={() => showVideoModal(false)}
                        videoUrl='https://player.vimeo.com/video/740804539#t=03m59s?h=887c30bd41&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                        videoTitle='Integration Options'
                    />
                </Layout.Section>
            </Layout>

        </AppPage >

    )
}



export default function () {
    return (
        <AdminPage>
            <ShopifyLandingPage />
        </AdminPage>
    )
}
