import React, { useRef, useEffect, useState } from 'react';
import { Stack, Icon } from '@shopify/polaris'
import { RiskMinor } from '@shopify/polaris-icons';
import './CustomLabel.scss';

export const CustomLabel = ({
    formLabel = false,
    isOption = false,
    fieldId,
    options,
    defaultValue,
    onChange,
    onError,
    inEditMode,
    index
}) => {
    const [value, setValue] = useState(defaultValue);
    const [isActive, setIsActive] = useState(false);
    const [error, setError] = useState(false)
    const fieldRef = useRef(null);

    const verifyValue = () => {
        if (formLabel) {
            if (fieldRef.current && !fieldRef.current.innerText) {
                setError(true);
                onError({ error: true, index, fieldId })
            } else {
                setError(false);
                onError({ error: false, index, fieldId })
            }
        } else {
            const validationResult = isOptionValueValid(value, index);
            setError(validationResult);
            onError({ error: validationResult, index, fieldId })
        }
    }

    useEffect(() => {
        if (fieldRef.current) {
            if (fieldRef.current.innerText !== defaultValue)
            fieldRef.current.innerText = defaultValue;
            verifyValue()
        }
    }, [defaultValue, fieldRef.current, inEditMode])

    const handleOnInput = (e) => {
        const key = e.charCode || e.keyCode;
        if (key !== 13) {
            setValue(fieldRef.current.innerText);
            verifyValue()
        } else {
            e.preventDefault();
        }
    }

    const isOptionValueValid = (value, index) => {
        if (!value) return true
        if (!options) return true
        const duplicatedElIndex = options.findIndex(option => option.value === value);
        if (duplicatedElIndex >= 0)
            return duplicatedElIndex >= 0 && index > duplicatedElIndex;
    }

    return (
        <div className={`custom-form-input-container${inEditMode ? ' edit' : '', isActive ? ' active' : ''}`}>
            <p
                ref={fieldRef}
                onFocus={() => setIsActive(true)}
                onBlur={() => { setIsActive(false); onChange(value) }}
                onKeyUp={handleOnInput}
                error-message-attr={error ? `No ${isOption ? 'option' : 'label'} available, please add ${isOption ? 'option' : 'label'}` : ''}
                className={`custom-form-input${formLabel ? ' main' : ''}${isOption ? ' option' : ''}${error ? ' error' : ''}`}
                contentEditable={inEditMode}
                suppressContentEditableWarning={true}></p>
            {error && <Stack.Item>
                <Icon
                    source={RiskMinor}
                    color='warning'
                />
            </Stack.Item>}
        </div>
    )
}
