import React, { useEffect, useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Card, Stack, RadioButton, TextStyle, TextField, SkeletonBodyText, Banner } from '@shopify/polaris';
import { useShopProvider } from './ShopProvider'

import useShopCheckoutConfig from '../hooks/useShopCheckoutConfig';

const CheckoutMethod = {
    checkout: 'checkout',
    cart: 'cart',
    custom: 'custom',
}

export default function CheckoutSettings() {

    const { shopOrigin, shopData, shopDataLoading, headlessConfig } = useShopProvider();
    const { checkoutConfig, checkoutConfigLoading } = useShopCheckoutConfig(shopOrigin)
    const [method, setMethod] = useState(null);
    const [customUrl, setCustomUrl] = useState(null);

    const baseUrl = shopData.primaryDomain.url;
    const isLoading = shopDataLoading && checkoutConfigLoading;

    const dbRef = Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('checkout');

    const onMethodChange = async (method) => {
        setMethod(method);
        switch (method) {
            case CheckoutMethod.checkout:
                await dbRef.set({ checkoutUrl: Firebase.firestore.FieldValue.delete() }, { merge: true });
                break;
            case CheckoutMethod.cart:
                await dbRef.set({ checkoutUrl: '/cart' }, { merge: true });
                break;
            default:
                break;
        }
    }

    const onCustomUrlBlur = async () => {
        await dbRef.set({ checkoutUrl: customUrl }, { merge: true });
    }

    useEffect(() => {
        if (!checkoutConfigLoading) {
            let method = CheckoutMethod.checkout, custom = null;
            if (checkoutConfig && checkoutConfig.checkoutUrl) {
                if (checkoutConfig.checkoutUrl === '/cart') {
                    method = CheckoutMethod.cart;
                }
                else {
                    method = CheckoutMethod.custom;
                    custom = checkoutConfig.checkoutUrl;
                }
            }
            setMethod(method);
            setCustomUrl(custom);
        }
    }, [checkoutConfig, checkoutConfigLoading])

    return (

        <Card sectioned title='Checkout target page'>
            <Stack vertical>
                <p>Customize what happens when client clicks on Boutiq in-app checkout button</p>
                {isLoading && <SkeletonBodyText lines='4' />}
                {!isLoading &&
                    <Stack vertical>
                        <RadioButton
                            label={<p>Shopify <TextStyle variation='strong'>checkout</TextStyle> page (default)</p>}
                            helpText={`Show Shopify checkout page`}
                            checked={method === CheckoutMethod.checkout}
                            id="checkout"
                            name="checkout_options"
                            onChange={() => onMethodChange(CheckoutMethod.checkout)}
                        />
                        <RadioButton
                            label={<p>Shopify <TextStyle variation='strong'>cart</TextStyle> page</p>}
                            helpText={`Show Shopify cart page`}
                            id="cart"
                            name="checkout_options"
                            checked={method === CheckoutMethod.cart}
                            onChange={() => onMethodChange(CheckoutMethod.cart)}
                        />
                        <RadioButton
                            label="Custom page"
                            helpText={`Show a custom Shopify store page. Use this option if the store is using a custom checkout`}
                            id="custom"
                            name="checkout_options"
                            checked={method === CheckoutMethod.custom}
                            onChange={() => onMethodChange(CheckoutMethod.custom)}
                        />
                        {method === CheckoutMethod.custom &&
                            <Stack alignment='center' spacing='tight'>
                                <p>{baseUrl}</p>
                                <TextField
                                    type='url'
                                    onChange={setCustomUrl}
                                    onBlur={onCustomUrlBlur}
                                    value={customUrl}
                                    placeholder='/your/custom/path'
                                />

                            </Stack>
                        }
                        {headlessConfig?.isHeadless && method && method !== CheckoutMethod.checkout &&
                        <Banner status='warning'>
                            <p>Custom checkout targets are not supported in headless mode. Please <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target='_blank' rel="noopener noreferrer">contact Boutiq support</a> for further info</p>
                            </Banner>}
                    </Stack>
                    }
            </Stack>

        </Card>
    );
}

