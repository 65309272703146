
export const compareObjects = (qo1, qo2) => {
    const o1 = clearEmpties(rmNullEntries(qo1));
    const o2 = clearEmpties(rmNullEntries(qo2));
    return objectsEqual(o1, o2)
}

const clearEmpties = (o) => {
    for (var k in o) {
        if (!o[k] || typeof o[k] !== "object") {
            continue
        }
        clearEmpties(o[k]);
        if (Object.keys(o[k]).length === 0) {
            delete o[k];
        }
    }
    return o;
}

const rmNullEntries = (obj) => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => v != null)
            .map(([k, v]) => [k, v === Object(v) ? rmNullEntries(v) : v])
    );
}

const objectsEqual = (o1, o2) => {
    return o1 instanceof Object && o2 instanceof Object
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
}

export const omit = (key, { [key]: _, ...obj }) => obj;
