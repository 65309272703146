import React from 'react';

import { Modal, Banner, TextContainer, TextStyle } from '@shopify/polaris';

export default function SubscriptionModal({ shopId, isAdmin, open, onboardingAction, signOutAction }) {

    const action = isAdmin
    ? { content: 'Complete onboarding', onAction: onboardingAction}
    : { content: 'Sign Out', onAction: signOutAction }

    const message = isAdmin
    ? `Please compelete the onboarding process to activate your store's Boutiq subscription.`
    : 'Please contact the store admin.'

    return (
        <Modal
            open={open}
        >
            <Banner
                status='critical'
                title={"There's a Subscription Problem"}
                action={action}
            >                                
                <TextContainer spacing='tight'>
                    <p>The store <TextStyle variation='strong'>{shopId}</TextStyle> doesn't have an active subscription on Boutiq.</p>
                    <p>{message}</p>
                </TextContainer>
            </Banner>
        </Modal>
    );
}