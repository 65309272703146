import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Stack, EmptyState, Text, Box, TextContainer } from '@shopify/polaris';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { isMobile } from 'react-device-detect';
import VideoCard from '../../../VideoCard';
import { VIDEO_STATUS } from '../../../../../../constants/story';
import { useLocation } from 'react-router-dom';
import UploadVideoCard from '../../../VideoCard/components/UploadVideoCard';
import VideoStatistic from '../../../VideoCard/components/VideoStatistic';
import { useStoriesProvider } from '../../../StoryiesProvider';

export const DraggableVideos = ({videos, deletePublishedStory, setStoriesOrder, error, uploadingStates, uploadAction}) => {
  const location = useLocation();
  const {storyId} = location.state ?? {}
  const { isBoutiqClientStory } = useStoriesProvider();
  const [isDraggable, setIsDraggable] = useState(false)
  const [state, setState] = useState({items: []});

  useEffect(() => {
    if (videos && videos.filter(video => video.data.status !== VIDEO_STATUS.DELETED).length >= 0) {
      setState({
        items: videos.map(video => ({
            ...video,
            content: (
              <VideoCard
                storyId={storyId}
                key={video.id}
                isCloseBtn={false}
                metadataOnly={true}
                deleteStory={deletePublishedStory}
                videoItem={video}
                uploadingStates={uploadingStates.find(state => state.videoId === video.id)}
              />
            )
          }
        ))
      })
    }
  }, [JSON.stringify(videos), JSON.stringify(uploadingStates)])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none', margin: `.8rem 0 0 .8rem`, ...draggableStyle,
  });

  const getListStyle = isDraggingOver => ({
    display: 'flex', margin: '-.8rem 0 0 -.8rem', overflow: 'auto'
  });

  const onDragStart = () => {
    setIsDraggable(true)
  }

  const onDragEnd = (result) => {
    setIsDraggable(false)
    if (!result.destination) {
      return;
    }
    const items = reorder(state.items, result.source.index, result.destination.index);
    setState({items});

    if (items.length > 0) {
      setStoriesOrder(items.map(item => videos.find(story => story.id === item.id)))
    }
  }

  const renderVideoCard = (story, index) => {
    if (story.data.status === VIDEO_STATUS.DELETED) {
      return <Fragment key={index} />
    }
    return (
      <Draggable key={`draggable_${story.id}`} draggableId={story.id} index={index}>
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box style={{position: 'relative'}}>
              {story.content}
              <VideoStatistic views={story.data.views} clicks={story.data.clicks} />
            </Box>
          </Box>
        )}
      </Draggable>
    )
  }

  return (
    <Stack vertical>
      {videos.length === 0 ? (
        <EmptyState
          heading={isBoutiqClientStory ? "Add a video to your client intro" : "Add a video"}
          image="/stories/story_play.png"
          action={uploadAction}
        >
          <p>
            Add a video from your video library or upload a new video. When ready, preview and publish.
          </p>
          {error}
        </EmptyState>
      ) : (
        <Stack vertical spacing='tight'>
          {error}
          <Stack spacing='tight'>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <>
                      {state.items.map(renderVideoCard)}
                      {!isDraggable && state.items.filter(story => story.data.status !== VIDEO_STATUS.DELETED).length <= 4 && (
                        <UploadVideoCard
                          key={'upload-video-card'}
                          uploadHandler={uploadAction}
                        />
                      )}
                    </>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
          {videos.length > 0 && !isMobile &&
            <TextContainer spacing='tight'>
              {!isBoutiqClientStory && <Text color='subdued'>Click a video to attach and configure products</Text>}
              <Text color='subdued'>Drag and drop videos to reorder. Don't forget to publish changes.</Text>              
            </TextContainer>
          }
          {videos.length > 0 && isMobile &&
            <TextContainer>
              {!isBoutiqClientStory && <Text color='subdued'>Tap a video to attach and configure products</Text>}
              <Text color='subdued'>Tap and hold videos to reorder. Don't forget to publish changes.</Text>              
            </TextContainer>
          }
        </Stack>
      )}
    </Stack>
  )
}
