import React, { useState, useCallback } from 'react';
import { Autocomplete, Icon, Stack, TextField, TextStyle } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useShopProvider } from '../../../../components/ShopProvider';
import useCaazamREST from '../../../../hooks/useCaazamREST';

export const AutocompleteClientSearch = ({
	defaultValue = '',
	onChangeName = () => { },
	onChangeSelected = () => { },
	error }) => {

	const { searchCustomers } = useCaazamREST()
	const { shopOrigin } = useShopProvider();
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const updateSelection = useCallback(
		(selected) => {
			const selectedValue = selected.map((selectedItem) =>
				options.find((option) => option.value.match(selectedItem)));
			if (selectedValue[0]) {
				setSelectedOptions(selected);
				setInputValue(selectedValue[0].value);
				onChangeSelected({ name: selectedValue[0].value, email: selectedValue[0].email })
			} else {
				onChangeSelected({ name: selected[0], email: '' })
			}
		},
		[options],
	);

	const onSearch = (input) => {
		return searchCustomers(shopOrigin, input)
			.then((searchResults) => {
				return searchResults.map((res, index) => ({
					id: `${res.displayName}_${res.email}_${index}`,
					value: res.displayName,
					email: res.email,
					media: <Stack vertical spacing='none' >
						<TextStyle variation='strong'>
							{res.displayName}
						</TextStyle>
						<TextStyle variation='subdued'>
							{res.email}
						</TextStyle>
					</Stack>
				}));
			})
			.catch((error) => {
				console.error('Search failed', error);
				return [];
			})
	}

	const updateText = useCallback(
		(value) => {
			setInputValue(value);
			onChangeName(value);
			if (!loading) {
				setLoading(true);
			}

			setTimeout(async () => {
				if (value === '') {
					setOptions([]);
					setLoading(false);
					return;
				}
				const results = await onSearch(value)

				setOptions(results);
				setLoading(false);
			}, 400);
		},
		[loading, onSearch],
	);

	const textField = (
		<Autocomplete.TextField
			onChange={updateText}
			autoComplete='off'
			error={error}
			label='Client'
			value={inputValue || defaultValue}
			prefix={<Icon source={SearchMinor} color='base' />}
			placeholder='Search'
		/>
	);

	const optionsList = () => {
		let anonimousProfile;
		if (inputValue) {
			anonimousProfile = {
				value: inputValue,
				email: '',
				media: <Stack vertical>
					<TextStyle variation='strong'>
						{inputValue}
					</TextStyle>

				</Stack>
			}
		} else {
			anonimousProfile = {
				value: '',
				email: '',
				media: <Stack vertical>
					<TextStyle variation='subdued'>
						{inputValue || 'Enter client name'}
					</TextStyle>

				</Stack>
			}
		}
		return [...[anonimousProfile], ...options]
	}

	return (
		<Autocomplete
			options={optionsList()}
			selected={selectedOptions}
			onSelect={updateSelection}
			textField={textField}
			loading={loading}
		/>
	);
}
