import React, { useState } from 'react';
import { Card, Stack, DataTable, Modal, TextContainer, Button, Text, SkeletonBodyText } from '@shopify/polaris';
import { useAppAuthState } from '../../../authState';
import { DateRange } from '../../Dashboard/useDateRange';
import CallHistoryFilter from '../../CallHistory/components/CallHistoryFilter';
import CallHistoryTablePagination from '../../CallHistory/components/CallHistoryTablePagination';
import { getFeedbackTypeHeader, formatCallFeedback } from '../../../utils/feedback';
import { formatCallTimestamp, formatCallEventType } from '../../../utils/calls';
import { useShopProvider } from '../../../components/ShopProvider';
import { useHistory } from 'react-router-dom';
import useShopCallsFeedback from '../../../hooks/useShopCallsFeedback';

const ROWS_PER_PAGE = 15;
const CONTENT_TYPES = ['text', 'text', 'text', 'text'];
const HEADERS = [
  'Feedback',
  'Date',
  'Client',
  'Host',
]

const defaultFilter = {
  hostId: null,
  eventId: null,
  range: DateRange.last30,
  search: '',
  onlyMessages: true,
}

export default function CallFeedbackList({
  rowCount = ROWS_PER_PAGE,
  withPagniation = true,
  withFilter = true,
  emptyStateMessage = 'No call feedback found',
  realtimeUpdates = false,
}) {
  const history = useHistory();
  const { shopOrigin } = useAppAuthState();
  const { hosts, eventTypes} = useShopProvider();
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const {
    feedback,
    refresh: refreshFeedback,
    getMoreFeedback,
    loading
  } = useShopCallsFeedback(
    shopOrigin,
    rowCount,
    filter,
    realtimeUpdates,
  );

  //backwards compatability when feedback doc didn't persist full host data
  function getHostData(hostId) {
    const findHost = hosts.find(h => h.id === hostId);
    if (findHost) {
      return {
        id: hostId,
        name: [findHost.firstName,findHost.lastName].join(' '),
      }
    } else {
      return {
        id: hostId,
        name: hostId,
      }
    }
  }

  //backwards compatability when feedback doc didn't persist full event type data
  function getEventType(eventTypeId) {
    const findEventType = eventTypes.docs.find(e => e.id === eventTypeId);
    if (findEventType) {
      return findEventType.data();
    } else {
      if (eventTypeId) {
        return {
          eventTypeId,
          name: eventTypeId.substring(0, 8) + '...',
          color: null,
        }
      }
    }
    return null;
  }

  const refreshTable = () => {
    refreshFeedback();
    setCurrentPage(0);
  }

  const handlerOnChangePage = (page) => {
    if ((page + 1) * rowCount >= feedback.length) {
      getMoreFeedback();
    }
    setCurrentPage(page);
  }

  const mapFeedbackToTable = (feedbackData) => {    
    /* ['Feedback',
    'Date',
    'Client',
    'Host',] */

    const { feedback, message, type } = formatCallFeedback(feedbackData);
    const feedbackType = getFeedbackTypeHeader(type);
    const feedbackDataCell = (<Stack vertical spacing='tight'>
      <Text>{message}</Text>
      <Stack alignment='center' spacing='extraTight'>
        <Text color='subdued' variant='bodySm'>{feedbackType}:</Text>
        <Text color='subdued' variant='bodySm'>{feedback}</Text>
      </Stack>
    </Stack>)

    const { customerName, customerEmail, eventType, host, hostId, eventTypeId } = feedbackData;
    const clientDataCell = (
      <div>
        <Text>{customerName}</Text>
        <Text>{customerEmail}</Text>
      </div>
    )

    const name = host?.name ?? getHostData(hostId)?.name;
    const eventTypeData = eventType ?? getEventType(eventTypeId);
    const hostDataCell = (
      <div>
        <Text>{name}</Text>
        {formatCallEventType(eventTypeData)}
      </div>
    )


    return [
      feedbackDataCell,
      formatCallTimestamp(feedbackData.timestamp),
      clientDataCell,
      hostDataCell,
    ]
  }

  const onRowClick = (feedbackData) => {
    if (feedbackData) {
      // history.push(`/boutiq-ai/call/${callData.id}`)
    }
  }

  const currentFeedback = feedback.slice(currentPage * rowCount, (currentPage + 1) * rowCount).map(mapFeedbackToTable);
  const hasNext = (feedback.length > (currentPage + 1) * rowCount);

  const footerContent = () => {
    if (!feedback?.length && loading) return <SkeletonBodyText lines={4} />;
    if (feedback.length > 0) {
      if (withPagniation && !realtimeUpdates){
      return (
        <CallHistoryTablePagination
          currentPage={currentPage}
          hasNext={hasNext}
          itemsPerPage={rowCount}
          onChangePage={handlerOnChangePage}
        />
      )
      }
    } else {
      return (<Text>{emptyStateMessage}</Text>);
    }

  }

  return (
    <div
      /* onClick={(e) => {
        const index = e.target.parentElement.rowIndex
        if (index) {
          onRowClick(feedback[index - 1])
        }
      }} */
    >
      <Stack vertical>
        {withFilter && <CallHistoryFilter
          hideExport={true}
          onChangeFilter={setFilter}
          onRefresh={refreshTable}
          defaultFilter={defaultFilter}
        />}
        <Card>
          <DataTable    
            columnContentTypes={CONTENT_TYPES}
            headings={HEADERS}
            rows={currentFeedback}
            footerContent={footerContent()}
          />
        </Card>
      </Stack>
    </div>
  )
}

