import {Modal, Stack, Subheading, TextStyle, Spinner, Button} from "@shopify/polaris"
import React, {useMemo} from "react"
import EventTypeBadge from "../../../../../../components/eventTypeBadge"
import { useCreateNewAppointmentProvider } from "../../../../../../components/CreateNewAppointmentProvider"
import './styles.css';
import {formatEventDate, instanceDuration} from '../../../../utils';
import moment from 'moment-timezone';
import {TimezoneSelector} from '../timezoneSelector';
import {useShopProvider} from '../../../../../../components/ShopProvider';

export const SummaryDataTab = () => {

    const {
        isScheduling,
        selectedEventType,
        availableHosts,
        eventTypes,
        selectedHostId,
        clientName,
        clientEmail,
        selectedSlot,
        timezone
    } = useCreateNewAppointmentProvider();
    const { shopData } = useShopProvider();

    const selectedTimezone = useMemo(() => (
      timezone ?? {id: shopData.ianaTimezone}
    ), [timezone, shopData])

    const currentHost = availableHosts.find(host => host.id === selectedHostId);
    const currentEvent = eventTypes.find(event => event.id === selectedEventType);

    return (
            <div className="schedule-data-container-wrapper">
                <div className={`schedule-data-container${isScheduling ? ' pending' : ''}`}>
                    <Modal.Section>
                        <Stack vertical spacing='tight'>
                            <Subheading>Date and time </Subheading>
                            <Stack alignment='center'>
                                <TextStyle variation='strong'>{formatEventDate(selectedSlot.timeSlot)}</TextStyle>
                            </Stack>
                            <TextStyle>{instanceDuration(moment(selectedSlot.timeSlot), moment(selectedSlot.timeSlotEnd))} minutes</TextStyle>
                        </Stack>
                    </Modal.Section>
                    <Modal.Section>
                        <Stack vertical spacing='tight'>
                            <Subheading>Client details</Subheading>
                            <p>{clientName}</p>
                            <p>{clientEmail}</p>
                            <Stack spacing={'tight'} alignment={'baseline'}>
                                <p>{selectedTimezone.id}</p>
                                <TimezoneSelector
                                  activator={(action) => (
                                    <Button onClick={action} plain>
                                        change
                                    </Button>
                                  )}
                                  timezone={selectedTimezone}
                                />
                                <p>({formatEventDate(selectedSlot.timeSlot, selectedTimezone.id)})</p>
                            </Stack>
                        </Stack>
                    </Modal.Section>
                    <Modal.Section>
                        <Stack vertical spacing='tight'>
                            <Subheading>Call details</Subheading>
                            {currentEvent?.name && <EventTypeBadge name={currentEvent.name} color={currentEvent.color} />}
                            {<Stack alignment='center'>
                                <Stack>
                                    <p>Host:</p>
                                    <p>{`${currentHost.firstName} ${currentHost.lastName}`}</p>
                                    <p>{currentHost.email}</p>
                                </Stack>
                            </Stack>}
                        </Stack>
                    </Modal.Section>
                </div>
                {isScheduling && <div className="scheduling-loader-container"><Spinner /></div>}
            </div>
    )
}
