import React, { useState, useEffect } from 'react';
import { Stack, Select, TextField, InlineError, TextStyle, } from '@shopify/polaris';
import { DEFAULT_SLOT_DURATION } from '../../utils/consts';


const durationOptions = [
    { label: '10 minutes', value: 10 },
    { label: '15 minutes', value: 15 },
    { label: '20 minutes', value: 20 },
    { label: '25 minutes', value: 25 },
    { label: '30 minutes (default)', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '60 minutes', value: 60 },
]

export function SchedulingEventDuration({ duration, onChange, disabled }) {

    const onDurationChange = (duration) => {
        const newVal = parseInt(duration) || DEFAULT_SLOT_DURATION;
        onChange(newVal);
    }

    return (
        <TextStyle variation={disabled ? 'subdued' : null}>
            <Stack vertical spacing='tight'>
                <p> Set the duration of appointment slots presented to clients</p>
                <p><TextStyle variation='subdued'>This setting doesn't affect the actual duration of a video call</TextStyle></p>
                <Select
                    options={durationOptions}
                    value={duration}
                    onChange={onDurationChange}
                    disabled={disabled}
                />
            </Stack>
        </TextStyle>
    )
}

export function SchedulingEventPadding({ paddingAfter, duration, onChange, onError, disabled }) {

    const [paddingAfterError, setPaddingAfterError] = useState(null);

    useEffect(() => {
        if (paddingAfter && paddingAfter > duration) {
            setPaddingAfterError('padding cannot be longer than appointment duration');
            onError(new Error('padding cannot be longer than appointment duration'));
        } else {
            setPaddingAfterError(null);
            onError(null);
        }
    }, [paddingAfter]);

    const onPaddingAfterChange = (val) => {
        const newVal = isNaN(parseInt(val)) ? '' : parseInt(val);
        onChange(newVal);
    }

    return (
        <TextStyle variation={disabled ? 'subdued' : null}>
            <Stack vertical spacing='tight'>

                <p> Keep some buffer time at the end of the appointment time slot to prevent new appointments from being scheduled immediately after it</p>
                <Stack alignment='center'>
                    <p>Keep</p>
                    <div style={{ maxWidth: '200px' }}>
                        <TextField
                            type='number'
                            min={0}
                            placeholder='-'
                            value={paddingAfter}
                            onChange={onPaddingAfterChange}
                            suffix={paddingAfter != null && 'minutes'}
                            disabled={disabled}
                        />
                    </div>
                    <p>as padding at the end of appointments</p>
                </Stack>
                {paddingAfterError && <InlineError message={paddingAfterError} />}
            </Stack>
        </TextStyle>
    )
}