import React, { useCallback } from 'react';
import useCaazamREST from './useCaazamREST';

export default function useShopCalendarEvent(shopId) {

  const { events } = useCaazamREST();

  const createScheduledCall = useCallback(
    (data) => events.createEvent(shopId,data),
    [shopId, events]);

  const cancelScheduledCall = useCallback(
    (eventId, options) => events.cancelEvent(shopId, eventId, options),
    [shopId, events]);

  const deleteScheduledCall = useCallback(
    (eventId) => events.deleteEvent(shopId, eventId),
    [shopId, events]);

  const rescheduleCall = useCallback(
    (options, eventId) => events.rescheduleEvent(shopId, eventId, options),
    [shopId, events]);

  const unassignCall = useCallback(
    (eventId) => events.unassignEvent(shopId, eventId),
    [shopId, events]);

  const assignCall = useCallback(
    (eventId, hostId) => events.assignEvent(shopId, eventId, hostId),
    [shopId, events]);

  return {
    createScheduledCall,
    cancelScheduledCall,
    deleteScheduledCall,
    rescheduleCall,
    unassignCall,
    assignCall,
  };
};
