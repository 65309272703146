import {utcStartDay, utcEndDay} from "../../utils/formatters";

export const callsEventTypesQuery = ({dateFrom, dateTo, tz = 'UTC', hosts = [], hostsComparison = false, eventTypesComparison = false, eventTypes = []}) => {
  const query = {
    timezone: tz,
    order: {
      'CallsEventTypes.timestamp': 'desc',
    },
    timeDimensions: [
      {
        dimension: 'CallsEventTypes.timestamp',
        granularity: 'day',
        dateRange: [
          utcStartDay(dateFrom),
          utcEndDay(dateTo),
        ]
      },
    ],
    dimensions: [
      "CallsEventTypes.scheduleEventTypeId",
    ],
    measures: [
      "CallsEventTypes.count",
    ],

  };

  if (hosts && hosts.length > 0) {
    query.filters = [
      {
        member: "CallsEventTypes.hostId",
        operator: "equals",
        values: hosts
      },
    ]
  }

  if (hostsComparison || eventTypesComparison) {
    query.filters = [{
      member: "CallsEventTypes.timestamp",
      operator: "inDateRange",
      values: [
        utcStartDay(dateFrom),
        utcEndDay(dateTo),
      ]
    }];

    query.dimensions = [
      ...query.dimensions,
      "CallsEventTypes.hostId",
      "CallsEventTypes.callId",
    ]

    delete query.timeDimensions;
  }

  if (eventTypes?.length > 0) {
    query.filters = [
      ...(query?.filters || []),
      {
        member: "CallsEventTypes.scheduleEventTypeId",
        operator: "equals",
        values: eventTypes
      }
    ];
  }

  return query;
};

