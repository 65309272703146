import React from 'react';
import './index.css'

const WelcomeButtons = ({ 
    cornerStyle, 
    primaryColor, 
    primaryButton, 
    secondaryButton, 
    isChatEnabled, 
}) => {
    return (
        <div className={`intro-main-layout-wrapper-btns`}>
            <div className='intro-main-layout'>
                <div className='main-buttons-wrapper'>
                    <div className='main-buttons'>
                        <button style={{ color: primaryColor }} className={`caazam-button filled-button ${cornerStyle}`}>{primaryButton}</button>
                        <button style={{ color: primaryColor }} className={`caazam-button schedule ${cornerStyle}`}>{secondaryButton}</button>
                    </div>
                    <div className={`chat-link-container`}>
                        {isChatEnabled && <span>
                            Or chat with our team
                        </span>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeButtons;
