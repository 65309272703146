import React, { useState }  from 'react';
import Moment from 'moment-timezone';
import Handlebars from 'handlebars';
import { DEFAULT_SERVICE_NAME, DEFAULT_HOST_REQUEST_NOTE } from '../../../utils/consts'

import { Card, Stack, TextField, TextStyle, TextContainer} from '@shopify/polaris';
import './index.css';

export function Preview( {locale, templateData, customData, shopData, serviceData, userData}) {

    const hello = Handlebars.compile(templateData.text.hello);
    const confirmation = Handlebars.compile(templateData.text.confirmation);
    const callInstructionsTemplate = Handlebars.compile(templateData.text.call_instructions);
    
    const formattedTimestamp = Moment().tz(shopData.ianaTimezone).locale(locale).format('LLLL (z)');
    const serviceNoun = (serviceData && serviceData.serviceNoun && serviceData.serviceNoun[locale]) || DEFAULT_SERVICE_NAME[locale];

    const customText = customData?.customText ? Handlebars.compile(customData?.customText) : null;
    const salutation = customData?.salutation ? Handlebars.compile(customData?.salutation) : null;

    return (
        <Card.Section>
            <Stack vertical spacing='loose'>
                <br />
                <p className='custom-text-preview' dangerouslySetInnerHTML={{ __html: hello({ clientName: userData?.firstName ?? 'Client' }) }} />
                <TextContainer spacing='tight'>
                    <p className='custom-text-preview' dangerouslySetInnerHTML={{ __html: confirmation({ shopName: shopData.name, serviceNoun, formattedTimestamp }) }} />
                </TextContainer>
                <TextContainer spacing='tight'>
                    <p className='custom-text-preview' dangerouslySetInnerHTML={{ __html: callInstructionsTemplate({ shopName: shopData.name, serviceNoun, formattedTimestamp }) }} />
                    <p className='personal-note'>{DEFAULT_HOST_REQUEST_NOTE[locale]}</p>
                </TextContainer>
                {customText && <p className='custom-text-preview' dangerouslySetInnerHTML={{ __html: customText({ shopName: shopData.name, serviceNoun }) }} />}
                {salutation && <p className='custom-text-preview' dangerouslySetInnerHTML={{ __html: salutation({shopName: shopData.name, serviceNoun}) }} />}
                <p className='email-footer'>{templateData.text.footer}</p>
            </Stack>
        </Card.Section>
    )

}

export function Customize({ customData, onChange, OnError }) {

    const handleCustomTextChange = (val) => onChange({...customData, customText: val || null });
    const handleSalutationChange = (val) => onChange({...customData, salutation: val || null });


    const customText = customData && customData.customText;
    const salutation = customData && customData.salutation;

    return (
        <>
        <Card.Section title='Custom message'>
                <TextField value={customText} onChange={handleCustomTextChange} multiline={6} />
        </Card.Section>
        <Card.Section title='Salutation'>
                <TextField value={salutation} onChange={handleSalutationChange} multiline={3} />
        </Card.Section>
        </>


    )
}