import React from 'react';
import '../../styles.css'
import { Button, Text, Stack, Box } from '@shopify/polaris';

const UploadVideoCard = ({uploadHandler}) => {
  return (
    <div className={'card'} style={{margin: '.8rem 0 0 .8rem'}}>
      <div className='card__body' style={{
        border: '1px dashed #c2c2c2'
      }}>
        <div className='card__body-cover' style={{zIndex: 100}}>
          <Stack vertical alignment={'center'} distribution={'center'} spacing={'tight'}>
            <Button
              outline
              onClick={uploadHandler.onAction}
            >
              Upload
            </Button>
            <Text as={'p'} variant={'bodyMd'} alignment={'center'}>Or add from library</Text>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default UploadVideoCard;
