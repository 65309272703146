import React from "react";
import { FEEDBACK_TYPE } from "./consts";
import ThumbUpIcon from '../pages/CallHistory/components/icon/ThumbUpIcon';
import ThumbDownIcon from '../pages/CallHistory/components/icon/ThumbDownIcon';

export function getFeedbackTypeHeader(type)  {
    let feedbackHeader = 'NPS Score';
	switch (type) {
		case FEEDBACK_TYPE.CSAT:
			feedbackHeader = 'CSAT Score';
			break;
		case FEEDBACK_TYPE.SIMPLE:
		case FEEDBACK_TYPE.NONE:
			feedbackHeader = 'Feedback';
			break;
		default:
			feedbackHeader = 'NPS Score';
	}
    return feedbackHeader;
}

export const formatCallFeedback = (feedbackData) => {
	let feedback = null;
	let message = null;
	let type = FEEDBACK_TYPE.NONE;
	
	if (feedbackData) {
	  if (feedbackData.npsRating != null) {
		feedback = feedbackData.npsRating;
		message = feedbackData.message;
		type = FEEDBACK_TYPE.NPS;
	  } else if (feedbackData.csatRating != null) {
		feedback = feedbackData.csatRating;
		message = feedbackData.message;
		type = FEEDBACK_TYPE.CSAT;
	  } else if (feedbackData.rating != null) {
		if (feedbackData.rating === 5) {
		  feedback = <ThumbUpIcon style={{ fill: '#08b273' }} />
		} else {
		  feedback = <ThumbDownIcon style={{ fill: '#d83a68' }} />
		}
		type = FEEDBACK_TYPE.SIMPLE;
	  }
	}
  
	return { feedback, message, type };
  }