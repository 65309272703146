import cubejs from "@cubejs-client/core";
import WebSocketTransport from "@cubejs-client/ws-transport";
import configs from "../../configs";
import {cubeAuth} from "../../authState";
import {useMemo, useState} from "react";

export default function  useCubeApi(user = null, shopOrigin = null) {
  const [cubeToken, setCubeToken] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const getStoredToken = (shopOrigin) => {
    const analyticsToken = localStorage.getItem('analyticsToken');
    if (analyticsToken) {
      const analyticsTokenObj = JSON.parse(analyticsToken);
      return analyticsTokenObj[shopOrigin];
    } else return null;
  }

  const setStoredToken = (shopOrigin, token) => {
    var analyticsTokenObj ={};
    const analyticsToken = localStorage.getItem('analyticsToken');
    if (analyticsToken)
      analyticsTokenObj = JSON.parse(analyticsToken);

    analyticsTokenObj[shopOrigin] = token;
    localStorage.setItem('analyticsToken', JSON.stringify(analyticsTokenObj));
  }

  const refreshToken = async (user, shopOrigin) => {
    if (!inProgress) {
      try {
        setInProgress(true);
        const refreshedToken = await cubeAuth(user, shopOrigin);
        if (!refreshedToken) {
          console.error(`Unauthorized. Token is ${refreshedToken}`)
          setStoredToken(shopOrigin, null);
          return;
        }
        setStoredToken(shopOrigin, refreshedToken.token);
        setCubeToken(refreshedToken.token);
        setInProgress(false);
        return refreshedToken;
      } catch (e) {
        setInProgress(false);
        console.error(`Unauthorized. ${e}`)
      }
    }
  }

  const cube = useMemo(() => {
    if (user && shopOrigin) {
      return cubejs(
        async () => {
          const userToken = getStoredToken(shopOrigin);
          const cubeAuthRes = userToken
            ? { token: userToken }
            : await refreshToken(user, shopOrigin);
          return cubeAuthRes.token;
        },
        {
          transport: new WebSocketTransport({
            apiUrl: configs.cube.url,
          })
        });
    }
    return null;
  }, [shopOrigin, user, cubeToken]);

  return {
    refreshToken,
    cube,
  };
}
