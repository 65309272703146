import { AVAILABLE_FONTS } from "./consts";

export const FontsLoader = (fontFamilyConfig, root, overridePropName = '--font-family') => {
	if (!fontFamilyConfig) return;
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	if (typeof fontFamilyConfig !== 'object' && AVAILABLE_FONTS.includes(fontFamilyConfig)) {
		root.style.setProperty(overridePropName, `'${fontFamilyConfig}', Poppins, sans-serif`);
	}
}
