export const formatEventDate = (date, timezone) => {
    return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'long',
        timeStyle: 'short',
        timeZone: timezone
    }).format(date);
}

export const instanceDuration = (start, end) =>
    end.diff(start, 'minutes');

export const localTimezoneGMTOffset = () => Math.abs(new Date().getTimezoneOffset()/60)
