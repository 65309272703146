import {Button, Popover, Select, Stack, TextField} from "@shopify/polaris";
import React, {useCallback, useState} from "react";
import {DateRange} from "../../../pages/Dashboard/useDateRange";
import Moment from "moment-timezone";

const DateRangeSelector = ({range, onSelectRange, onApply, disabled}) => {
  
  const [rangePopoverActive, setRangePopoverActive] = useState(false);
  const toggleRangePopoverActive = useCallback(() => setRangePopoverActive((rangePopoverActive) => !rangePopoverActive), []);
  
  const activator = (
    <div style={{width: 160}}>
      <Button onClick={toggleRangePopoverActive} disclosure fullWidth>
        {range.name}
      </Button>
    </div>
  );
  
  const selectRangeOptionsList = Object.keys(DateRange)
    .map(key => ({ label: DateRange[key].name, value: key }))
    .concat({ label: 'Custom', value: 'custom' });
  
  const onRangeChange = useCallback((id) => {
    if (id !== 'custom') {
      onSelectRange(DateRange[id])
      toggleRangePopoverActive()
    } else {
      onSelectRange({ id: 'custom', name: 'Custom', start: Moment().subtract(1, 'month'), end: Moment().endOf('day'), comparePeriod: 30, compareUnit: 'DAY' })
    }
  }, []);
  
  const onStartChange = (date) => {
    onSelectRange({ ...range, start: Moment(date), comparePeriod: range.end.diff(Moment(date), 'days') });
  }
  
  const onEndChange = (date) => {
    onSelectRange({ ...range, end: Moment(date).endOf('day'), comparePeriod: Moment(date).endOf('day').diff(range.start, 'days') });
  }
  
  return (
    <Popover
      active={rangePopoverActive}
      activator={activator}
      onClose={toggleRangePopoverActive}
      fluidContent={true}
      preferredAlignment='left'
    >
      <Popover.Pane fixed>
        <div style={{ margin: '1rem ' }}>
          <Stack vertical={true}>
            <Select
              label="Date range"
              options={selectRangeOptionsList}
              value={range.id}
              onChange={onRangeChange}
              disabled={disabled}
            />
            <Stack>
              <TextField
                label='Starting'
                value={range.start.format('YYYY-MM-DD')}
                type='date'
                onChange={onStartChange}
                disabled={range.id !== 'custom'}
                max={range.end.format('YYYY-MM-DD')}
              />
              <TextField
                label='Ending'
                value={range.end.format('YYYY-MM-DD')}
                type='date'
                onChange={onEndChange}
                disabled={range.id !== 'custom'}
                min={range.start.format('YYYY-MM-DD')}
              />
            </Stack>
          </Stack>
          {onApply && (
            <Stack distribution='trailing'>
              <Button
                primary
                disabled={disabled}
                onClick={onApply}>
                Apply
              </Button>
            </Stack>
          )}
        </div>
      </Popover.Pane>
    </Popover>
  )
}

export default DateRangeSelector;