import React from 'react';
import { Icon } from '@shopify/polaris';
import './styles.css';

export default function ClientAppMockup({cornerStyle, primaryColor, userName }) {
    return (
        <div style={{color: primaryColor }} className={`widget-mockup-container${cornerStyle ? ` ${cornerStyle}` : ''}`}>
            <div className='video-room-container'>
            <Icon color='white' source={`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 76 76" style="enable-background:new 0 0 76 76;" xml:space="preserve">
                                            <g id="_x37_7_Essential_Icons_72_">
                                                <path id="Video_Camera" d="M72.9,14.4L56,25.3V22c0-4.4-3.6-8-8-8H8c-4.4,0-8,3.6-8,8v32c0,4.4,3.6,8,8,8h40c4.4,0,8-3.6,8-8v-3.3   l16.9,10.9c1.9,1,3.1-0.7,3.1-1.7V16C76,15,74.9,13.2,72.9,14.4z M52,54c0,2.2-1.8,4-4,4H8c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h40   c2.2,0,4,1.8,4,4V54z M72,56.3L56,46V30l16-10.3V56.3z"/>
                                            </g></svg>`} />
            </div>
            <div className='form-container'>
                <div className='form'>
                    <h3>Hi {userName}, we're connecting you</h3>
                    <input placeholder="Name*" name="name" style={{color: primaryColor}} className={`caazam-input${cornerStyle ? ` ${cornerStyle}` : ''}`} />
                    <input placeholder="Email*" name="email" style={{color: primaryColor}} className={`caazam-input${cornerStyle ? ` ${cornerStyle}` : ''}`} />
                    <div className="actions-container">
                        <button style={{background: primaryColor}} className={`caazam-button${cornerStyle ? ` ${cornerStyle}` : ''}`} type="button">I'm Ready</button>
                        <button className='schedule'>Schedule for later</button>
                    </div>
                    <div className='footer'>Powered by Boutiq</div>
                </div>
            </div>
        </div>
    );
}
