import React, { useState, useEffect } from 'react';
import { Icon } from '@shopify/polaris';
import { StatusVisualEffects } from './components/StatusVisualEffects';
import { STORY_STATUS } from './components/StatusVisualEffects/consts/index';
import {
  DeleteMinor,
  MobileCancelMajor,
  PlayMajor,
  SettingsMajor
} from '@shopify/polaris-icons';
import './styles.css';
import { VideoCardPoster } from './components/VideoCardPoster';
import { VideoMetadata } from './components/VideoMetadata';
import { useHistory } from 'react-router-dom';
import StoryProductsPreview from '../StoryProductsPreview';
import { useStoriesProvider } from '../StoryiesProvider';

const VideoCard = ({
    videoItem,
    uploadingStates,
    metadataOnly = false,
    storyId,
    showDelete = true,
    isCloseBtn = true,
    deleteStory = () => {},
    onViewVideo = () => {},
    isConfigPage = false,
    products,
    plusButton,
    isSelected,
    isVideoLibrary,
    onUpdateVideoDuration = () => {}
}) => {
    const history = useHistory();
    const [isVideoCardReady, setIsVideoCardReady] = useState(false);
    const {selectedStory, isBoutiqClientStory} = useStoriesProvider()

    useEffect(() => {
      if (videoItem) {
        if (isVideoLibrary) {
          if (videoItem.data.status === STORY_STATUS.ready ||
            videoItem.data.status === STORY_STATUS.published) {
            setIsVideoCardReady(true);
          } else {
            setIsVideoCardReady(false);
          }
        } else {
          setIsVideoCardReady(true);
        }
      }
    }, [videoItem])

    const hasError = () => {
        let error;
        if (uploadingStates?.error) {
            error = uploadingStates.error;
        } else if (videoItem?.data?.error) {
            error = videoItem.data.error.message;
        }
        return error;
    }

    const playHandler = (e) => {
        e.preventDefault();
        onViewVideo({ url: videoItem.data.videoUrl });
    }

    const playButton = () =>
      <div onClick={playHandler} className='card__body-header-play'>
          <Icon color='base' source={PlayMajor} />
      </div>

    const onClickCard = ({target}) => {
      if (storyId && !uploadingStates && !isBoutiqClientStory && target.className === 'card__body') {
        history.push({
          pathname: '/stories/config',
          state: {storyId, videoId: videoItem.id, isBoutiqClientStory: selectedStory.useAsBoutiqClientStory}
        })
      }
    }

    return (
      <div className={`card${!isVideoCardReady ? ' disabled' : ''}${isSelected ? ' checked' : ''}`}>
        <div className='card__body' onClick={onClickCard}>
          <div className='card__body-cover'>
            <>
              <>
                {plusButton?.()}
                <VideoCardPoster
                  status={videoItem.data.status}
                  url={videoItem.data.videoPosterUrl || uploadingStates?.poster}
                  children={(!metadataOnly && isVideoCardReady) && playButton()}
                />
                {isConfigPage && (
                  <StoryProductsPreview products={products} />
                )}
              </>
              {storyId && !uploadingStates && !isBoutiqClientStory && (
                <span
                  onClick={() => history.push({
                    pathname: '/stories/config',
                    state: {storyId, videoId: videoItem.id, isBoutiqClientStory: selectedStory.useAsBoutiqClientStory}
                  })}
                  className={`product-config-button ${videoItem.data.products?.length > 0 ? 'primary' : 'secondary'}`}
                >
                    <Icon color={videoItem.data.products?.length > 0 ? null : 'subdued'} source={SettingsMajor}/>
                </span>
              )}
              {(hasError() || (videoItem.data.status === STORY_STATUS.pending && !uploadingStates) || isVideoCardReady) && showDelete && !uploadingStates &&
                <span
                  onClick={() => deleteStory(videoItem.id)}
                  className='card__body-cover-checkbox'
                >
                    {isCloseBtn ? <Icon source={DeleteMinor}/> : <Icon source={MobileCancelMajor}/>}
                </span>
              }
            </>
          </div>
          <VideoMetadata
            setVideoDuration={onUpdateVideoDuration}
            src={videoItem.data.videoUrl}
          />
          <StatusVisualEffects
            status={videoItem.data.status}
            uploadingProgress={uploadingStates ? uploadingStates.progress : null}
            errorMessage={hasError()}
          />
        </div>
      </div>
    );
}

export default VideoCard
