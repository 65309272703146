import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useClientFormConfig(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('clientForms') : null;
    const [formConfigData, formConfigLoading, formConfigError] = useDocumentData(ref);
    formConfigError && console.error('useClientFormConfig', formConfigError)

    const setClientFormConfig = (object) => {
        return ref.set(object, { merge: true });
    }

    return { formConfigData, formConfigLoading, setClientFormConfig }
}
