import React from 'react'
import { DisplayText, TextStyle, Stack } from '@shopify/polaris';
import GaugeChart from 'react-gauge-chart';
import { currencyFormatter, numberFormatter, percentFormatter, filterTimeFormatter } from '../../utils/formatters';
import { COMPARE_TYPES } from "../../constants/analytics";
import { getRandomColor } from "../../utils/colors";
import { isNumber } from "recharts/lib/util/DataUtils";
import { quantile } from "../../utils/calculation";
import Moment from "moment-timezone";
import { FEEDBACK_TYPE, ScheduleStatus } from "../../utils/consts";
import { getFeedbackTypeHeader } from '../../utils/feedback';

export const KPIObject = (currencyCode, feedbackType) => {
    return ({
        calls_completed: {
            name: 'Completed Calls',
            value: 0,
            render: (value) => numberFormatter().format(value),
            isAgg: true,
            display: true,
            description: 'Total number of answered Boutiq calls',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        orders_total: {
            name: 'Total Sales',
            value: 0,
            render: (value) => currencyFormatter(currencyCode).format(value),
            isAgg: true,
            display: true,
            description: 'Total sales generated on Boutiq calls',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        orders_count: {
            name: 'Number of Orders',
            value: 0,
            render: (value) => numberFormatter().format(value),
            isAgg: true,
            display: true,
            description: 'Number of orders generated on Boutiq calls',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        conversion_rate: {
            name: 'Conversion Rate',
            value: 0,
            render: (value) => percentFormatter().format(value),
            display: true,
            description: 'ratio of Boutiq calls resulting in a sale vs all Boutiq calls',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        calls_failed: {
            name: 'Missed Calls',
            value: 0,
            render: (value) => numberFormatter().format(value),
            isAgg: true,
            display: true,
            description: 'Total number of Boutiq calls there were unanswered',
            comparisonColor: (ratio) => ratio < 1.0 ? 'success' : ratio > 1.0 ? 'critical' : null,
        },
        total_duration: {
            name: 'Total Duration',
            value: 0,
            render: (value) => numberFormatter().format(value / 60),
            isAgg: true,
            display: false,
            description: 'Total duration of Boutiq calls in minutes',
            comparisonColor: (ratio) => null,
        },
        avg_duration: {
            name: 'Average Call Time',
            value: 0,
            render: (value) => (value / 60).toFixed(2),
            display: true,
            description: 'Avergae duration of Boutiq calls (in minutes)',
            comparisonColor: (ratio) => null,
        },

        order_per_call: {
            name: 'Sales per Call',
            value: 0,
            render: (value) => currencyFormatter(currencyCode).format(value),
            display: true,
            description: 'Average sales generated on a Boutiq call',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        order_per_duration: {
            name: 'Sales per Minute',
            value: 0,
            render: (value) => currencyFormatter(currencyCode).format(value),
            display: false,
            description: 'Sales generated on average per minute of a Boutiq call',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        average_order: {
            name: 'AOV',
            value: 0,
            render: (value) => currencyFormatter(currencyCode).format(value),
            display: true,
            description: 'Average Order Value for orders generated on Boutiq calls',
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
        feedback: {
            name: getFeedbackTypeHeader(feedbackType),
            value: null,
            isAgg: true,
            render: (value) => value !== null ? (value).toFixed(2) : null,
            customRender: renderFeedbackKPI(feedbackType),
            display: feedbackType !== FEEDBACK_TYPE.NONE,
            description: getFeedbackKPIDescription(feedbackType),
            comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
        },
    })
};

function renderFeedbackKPI(feedbackType) {
    switch (feedbackType) {
        case FEEDBACK_TYPE.NPS:
            return (value) => {
                return (
                    <Stack wrap={false} alignment='baseline' spacing='extraTight'>
                        <div style={{ marginRight: 4 }} >
                            <DisplayText>
                                <TextStyle variation='strong'>{value != null ? numberFormatter().format(value) : '-'}</TextStyle>
                            </DisplayText>
                        </div>
                        {value !== null && <div style={{ width: '55px' }}>
                            <GaugeChart
                                nrOfLevels={420}
                                arcsLength={[0.6, 0.15, 0.2, .15]}
                                colors={['#f06e63', '#f9b63e', '#3dc373', '#20ab58']}
                                percent={(Number(value) + 100) * .005}
                                cornerRadius={0}
                                arcPadding={0}
                                needleColor="#345243"
                                hideText={true}
                                marginInPercent={0}
                            />
                        </div>}
                    </Stack>)
            }

        case FEEDBACK_TYPE.CSAT:
            return (value) => {
                return (
                    <Stack wrap={false} alignment='baseline' spacing='extraTight'>
                        <div style={{ marginRight: 4 }} >
                            <DisplayText>
                                <TextStyle variation='strong'>{value != null ? numberFormatter().format(value) : '-'}</TextStyle>
                            </DisplayText>
                        </div>
                        {value !== null && <div style={{ width: '55px' }}>
                            <GaugeChart
                                nrOfLevels={420}
                                arcsLength={[0.25,0.25,0.25,0.25]}
                                colors={['#f06e63', '#f9b63e', '#3dc373', '#20ab58']}
                                percent={Number(value) / 100}
                                cornerRadius={0}
                                arcPadding={0}
                                needleColor="#345243"
                                hideText={true}
                                marginInPercent={0}
                            />
                        </div>}
                    </Stack>)
            }
        case FEEDBACK_TYPE.SIMPLE:
            return (value) => (
                <DisplayText>
                    <TextStyle variation='strong'>{value != null ? numberFormatter().format(value) : '-'}</TextStyle>
                </DisplayText>
            )
        default:
            return (value) => null;
    }
}

function getFeedbackKPIDescription(feedbackType) {
    switch( feedbackType) {
        case FEEDBACK_TYPE.NPS:
            return 'Client call NPS score (-100 to 100)';
        case FEEDBACK_TYPE.CSAT:
            return 'Client call CSAT score (0 to 100)';
        case FEEDBACK_TYPE.SIMPLE:
            return 'Avg client call feedback (max 5.0)';
        default:
            return '';
    }
}

const buildHostCallsEntity = (data, hostId, date) => {
    const postfix = hostId === null ? '' : `_${hostId}`
    return {
        [`Calls.avgDuration${postfix}`]: data['Calls.avgDuration'],
        [`Calls.callsCompleted${postfix}`]: data['Calls.callsCompleted'],
        [`Calls.callsFailed${postfix}`]: data['Calls.callsFailed'],
        [`Calls.conversionRate${postfix}`]: data['Calls.ordersConversionRate'],
        [`Calls.count${postfix}`]: data['Calls.count'],
        [`Calls.duration${postfix}`]: data['Calls.duration'],
        [`Calls.durationMedian${postfix}`]: data['Calls.durationMedian'],
        [`Calls.durationPercent90${postfix}`]: data['Calls.durationPercent90'],
        [`Calls.timestamp${postfix}`]: data['Calls.timestamp'],
        [`Calls.timestamp.day${postfix}`]: data['Calls.timestamp.day'],
        [`Orders.count${postfix}`]: data['Orders.ordersCount'],
        [`time.day${postfix}`]: data['time.day'],
        [`Calls.date`]: date, // TODO: update in the cube if it's possible
        [`Orders.ordersTotal${postfix}`]: Number(data['Orders.ordersTotal']),
        [`Orders.avgOrder${postfix}`]: Number(data['Orders.avgOrder']),
        [`Calls.orderPerDuration${postfix}`]: Number(data['Calls.orderPerDuration']),
        [`Calls.orderPerCall${postfix}`]: Number(data['Calls.orderPerCall']),
    }
}

const sumAirParams = (air) => {
    let airMap = {};
    air.forEach(data => {
        if (Object.keys(airMap).length === 0) {
            airMap = data;
        } else {
            airMap[`CallEvents.airTimeCustomersTotal`] += data['CallEvents.airTimeCustomersTotal'];
            airMap[`CallEvents.airTimeHostTotal`] += data['CallEvents.airTimeHostTotal'];
            airMap[`CallEvents.airTimeTotal`] += data['CallEvents.airTimeTotal'];
        }
    });
    return airMap;
}

const getOrdersDataByEventTypes = (calls = [], eventTypeId = null) => {
    const orderData = {};
    const uniqueByEventType = calls.filter(c => c['Calls.scheduleEventTypeId'] === eventTypeId);
    const uniqueByNotEmptyOrderTotal = uniqueByEventType.filter(c => c['Orders.ordersTotal'] !== null);
    const completed = uniqueByEventType.filter(data => data['Calls.status'] === 'completed');
    const callsDuration = completed.map(c => c['Calls.duration']).filter(Number);
    orderData[`Orders.count`] = uniqueByNotEmptyOrderTotal.map(i => i['Orders.ordersCount']).reduce((a, b) => a + b, 0) || 0;
    orderData[`Orders.ordersTotal`] = uniqueByNotEmptyOrderTotal.map(i => Number(i['Orders.ordersTotal'])).reduce((a, b) => a + b, 0) || 0;
    orderData[`Orders.avgOrder`] = orderData[`Orders.ordersTotal`] / orderData[`Orders.count`];
    orderData[`Calls.conversionRate`] = orderData[`Orders.count`] / completed.length || 0;
    orderData[`Calls.durationPercent90`] = quantile(callsDuration, 90);
    orderData[`Calls.durationMedian`] = quantile(callsDuration, 50);
    return orderData;
}

const sumCallsByEventType = (calls = [], eventTypeId = null) => {
    const filteredCalls = calls.filter(c => c['Calls.scheduleEventTypeId'] === eventTypeId);
    const aggregatedCallByEventType = {};

    filteredCalls.forEach(data => {
        aggregatedCallByEventType[`Calls.timestamp`] = data['Calls.timestamp'];
        aggregatedCallByEventType[`Calls.avgDuration`] = (aggregatedCallByEventType?.[`Calls.avgDuration`] || 0) + data['Calls.avgDuration'];
        aggregatedCallByEventType[`Calls.callsCompleted`] = (aggregatedCallByEventType?.[`Calls.callsCompleted`] || 0) + data['Calls.callsCompleted'];
        aggregatedCallByEventType[`Calls.callsFailed`] = (aggregatedCallByEventType?.[`Calls.callsFailed`] || 0) + data['Calls.callsFailed'];
        aggregatedCallByEventType[`Calls.count`] = (aggregatedCallByEventType?.[`Calls.count`] || 0) + data['Calls.count'];
        aggregatedCallByEventType[`Calls.duration`] = (aggregatedCallByEventType?.[`Calls.duration`] || 0) + data['Calls.duration'];
        aggregatedCallByEventType[`Calls.durationMedian$`] = (aggregatedCallByEventType?.[`Calls.durationMedian`] || 0) + data['Calls.durationMedian'];
        aggregatedCallByEventType[`Calls.orderPerCall`] = (aggregatedCallByEventType?.[`Calls.orderPerCall`] || 0) + Number(data['Calls.orderPerCall']);
    });
    return { ...aggregatedCallByEventType, ...getOrdersDataByEventTypes(calls, eventTypeId, aggregatedCallByEventType[`Calls.timestamp`]) };
}

const sumFeedbackByEventType = (feedback = [], eventTypes = [], eventTypeId = null) => {
    const aggregatedFeedbackByEventType = {};
    feedback.forEach(data => {
        const activeEventType = eventTypes.find(et => et['CallsEventTypes.callId'] === data['Feedback.callId']);
        if (activeEventType?.['CallsEventTypes.scheduleEventTypeId'] === eventTypeId) {
            aggregatedFeedbackByEventType[`Feedback.avgRating`] = (aggregatedFeedbackByEventType?.[`Feedback.avgRating`] || 0) + data['Feedback.avgRating'];
            aggregatedFeedbackByEventType[`Feedback.npsCount`] = (aggregatedFeedbackByEventType?.[`Feedback.npsCount`] || 0) + data['Feedback.npsCount'];
            aggregatedFeedbackByEventType[`Feedback.npsDetractors`] = (aggregatedFeedbackByEventType?.[`Feedback.npsDetractors`] || 0) + data['Feedback.npsDetractors'];
            aggregatedFeedbackByEventType[`Feedback.npsPassives`] = (aggregatedFeedbackByEventType?.[`Feedback.npsPassives`] || 0) + data['Feedback.npsPassives'];
            aggregatedFeedbackByEventType[`Feedback.npsPromoters`] = (aggregatedFeedbackByEventType?.[`Feedback.npsPromoters`] || 0) + data['Feedback.npsPromoters'];
            aggregatedFeedbackByEventType[`Feedback.csatPromoters`] = (aggregatedFeedbackByEventType?.[`Feedback.csatPromoters`] || 0) + data['Feedback.csatPromoters'];
            aggregatedFeedbackByEventType[`Feedback.csatCount`] = (aggregatedFeedbackByEventType?.[`Feedback.csatCount`] || 0) + data['Feedback.csatCount'];
        }
    })
    const analizedFeedback = analyzieFeedbackResponse([aggregatedFeedbackByEventType])
    return analizedFeedback[0];
}

const sumCallEventsByEventType = (callEvents = [], eventTypes = [], eventTypeId = null) => {
    const aggregatedCallEventsByEventType = {};
    callEvents.forEach(data => {
        const activeEventType = eventTypes.find(et => et['CallsEventTypes.callId'] === data['CallEvents.callId']);
        if (activeEventType?.['CallsEventTypes.scheduleEventTypeId'] === eventTypeId) {
            aggregatedCallEventsByEventType[`CallEvents.airTimeCustomersTotal`] = (aggregatedCallEventsByEventType?.[`CallEvents.airTimeCustomersTotal`] || 0) + data['CallEvents.airTimeCustomersTotal'];
            aggregatedCallEventsByEventType[`CallEvents.airTimeHostTotal`] = (aggregatedCallEventsByEventType?.[`CallEvents.airTimeHostTotal`] || 0) + data['CallEvents.airTimeHostTotal'];
            aggregatedCallEventsByEventType[`CallEvents.airTimeTotal`] = (aggregatedCallEventsByEventType?.[`CallEvents.airTimeTotal`] || 0) + data['CallEvents.airTimeTotal'];
            aggregatedCallEventsByEventType[`CallEvents.airtime`] = (aggregatedCallEventsByEventType?.[`CallEvents.airtime`] || 0) + data['CallEvents.airtime'];
        }
    })
    return aggregatedCallEventsByEventType;
}

const aggregateEventTypes = (eventTypes = [], eventTypeId = null) => {
    const filteredEventTypes = eventTypes.filter(et => et['CallsEventTypes.scheduleEventTypeId'] === eventTypeId);
    const aggregatedEventTypes = {};
    filteredEventTypes.forEach(data => {
        aggregatedEventTypes[`CallsEventTypes.count`] = (aggregatedEventTypes?.[`CallsEventTypes.count`] || 0) + data['CallsEventTypes.count'];
    });
    return aggregatedEventTypes;
}

const getNPSvalue = (data = {}) => {
    const promoters = data['Feedback.npsPromoters'];
    const detractors = data['Feedback.npsDetractors'];
    const count = data['Feedback.npsCount'];
    let NPS = null;
    if (count) {
        NPS = Math.round((promoters - detractors) / count * 100);
    }
    return NPS;
}

const getCSATvalue = (data = {}) => {
    const promoters = data['Feedback.csatPromoters'];
    const count = data['Feedback.csatCount'];
    let CSAT = null;
    if (count) {
        CSAT = Math.round((promoters / count) * 100);
    }
    return CSAT;
}

const getNPSscoreChartType = (data, npsValue) => {
    let result;
    
    if (npsValue < 0) {
        result = { ...data, ...{ 'Nps.score_bad': npsValue } };
    } else if (npsValue >= 0 && npsValue < 30) {
        result = { ...data, ...{ 'Nps.score_warning': npsValue } };
    } else if (npsValue >= 30 && npsValue < 70) {
        result = { ...data, ...{ 'Nps.score_good': npsValue } };
    } else if (npsValue >= 70) {
        result = { ...data, ...{ 'Nps.score_excellent': npsValue } };
    }
    return result;
}

const getCSATscoreChartType = (data, csatValue) => {
    let result;
    if (csatValue >= 0 && csatValue < 25) {
        result = { ...data, ...{ 'Csat.score_bad': csatValue } };
    } else if (csatValue >= 25 && csatValue < 50) {
        result = { ...data, ...{ 'Csat.score_warning': csatValue } };
    } else if (csatValue >= 50 && csatValue < 75) {
        result = { ...data, ...{ 'Csat.score_good': csatValue } };
    } else if (csatValue >= 75) {
        result = { ...data, ...{ 'Csat.score_excellent': csatValue } };
    }
    return result;
}

const analyzieFeedbackResponse = (feedback) => {
    const feedbackMap = feedback.map(data => {
        const NPS = getNPSvalue(data);
        if (NPS != null) {
            return getNPSscoreChartType(data, NPS)
        } else {
            const CSAT = getCSATvalue(data);
            if (CSAT != null) {
                return getCSATscoreChartType(data, CSAT)
            } else {
                return data;
            }
        }
    });
    return feedbackMap;
}

const buildHostAirEntity = (air) => {
    const airMap = {};
    air.forEach(data => {
        const date = filterTimeFormatter(data['Calls.timestamp']);
        if (!airMap[date]) {
            airMap[date] = { ...data, [`Calls.date`]: date };
        } else {
            airMap[date][`CallEvents.airTimeCustomersTotal`] += data['CallEvents.airTimeCustomersTotal'];
            airMap[date][`CallEvents.airTimeHostTotal`] += data['CallEvents.airTimeHostTotal'];
            airMap[date][`CallEvents.airTimeTotal`] += data['CallEvents.airTimeTotal'];
        }
    });
    return airMap;
}

const buildEventTypesEntity = (eventTypes, eventTypesParams) => {
    const eventTypesMap = {};
    let eventTypesKeys = [null];

    const formattedEventTypes = eventTypesParams.docs.map(et => ({ ...et.data(), id: et.id }));
    eventTypes.forEach(data => {
        const date = filterTimeFormatter(data['CallsEventTypes.timestamp']);
        const eventTypeParameters = formattedEventTypes.find(et => et.id === data['CallsEventTypes.scheduleEventTypeId']);
        eventTypesKeys = eventTypesKeys.concat(data['CallsEventTypes.scheduleEventTypeId']);
        eventTypesMap[date] = {
            ...eventTypesMap[date],
            [`CallsEventTypes.count`]: data['CallsEventTypes.count'],
            [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.id`]: data['CallsEventTypes.scheduleEventTypeId'],
            [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.count`]: data['CallsEventTypes.count'],
            [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.name`]: eventTypeParameters?.name || data['CallsEventTypes.scheduleEventTypeId'],
            [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.color`]: eventTypeParameters?.color || '#FFBE88',
        };
    });

    eventTypesKeys = eventTypesKeys.filter((value, index, self) => self.indexOf(value) === index)
    return { eventTypesMap, eventTypesKeys };
}

const buildHostFeedbackEntity = (feedback) => {
    let feedbackMap = {};
    feedback.forEach(data => {
        const date = filterTimeFormatter(data['Feedback.timestamp']);
        const NPS = getNPSvalue(data);
        if (NPS != null) {
            feedbackMap[date] = getNPSscoreChartType(data, NPS);
            return;
        } else {
            const CSAT = getCSATvalue(data);
            if (CSAT != null) {
                feedbackMap[date] = getCSATscoreChartType(data, CSAT);
                return;
            } else {
                feedbackMap[date] = data;
                return;
            }
        }
    });
    return feedbackMap;
}

const defaultMapper = ({ calls, air, feedback, eventTypes, startDate, endDate, getDataPointsArray, hostId = null, eventTypesList }) => {
    let airMap = buildHostAirEntity(air);
    let { eventTypesMap, eventTypesKeys } = buildEventTypesEntity(eventTypes, eventTypesList);
    let feedbackMap = buildHostFeedbackEntity(feedback);
    let dateMap = {};
    calls.forEach(day => {
        const date = filterTimeFormatter(day['Calls.timestamp']);
        dateMap[date] = {
            ...airMap[date],
            ...eventTypesMap[date],
            ...feedbackMap[date] ? feedbackMap[date] : null,
            ...day,
            ...buildHostCallsEntity(day, hostId, date),
        };
    });
    const newMergedData = getDataPointsArray(startDate, endDate).map(item => {
        if (dateMap[item['Calls.date']]) {
            return dateMap[item['Calls.date']];
        }
        return item;
    });

    return { newMergedData, map: dateMap, eventTypesKeys };
}

const calcHostsEventTypes = (eventTypes, eventTypesParams, hostId) => {
    let eventTypesMap = {};
    let eventTypesKeys = [null];
    const formattedEventTypes = eventTypesParams.docs.map(et => ({ ...et.data(), id: et.id }));
    eventTypes.forEach(data => {
        if (data['CallsEventTypes.hostId'] === hostId) {
            const eventTypeParameters = formattedEventTypes.find(et => et.id === data['CallsEventTypes.scheduleEventTypeId']);
            eventTypesKeys = eventTypesKeys.concat(data['CallsEventTypes.scheduleEventTypeId']);
            eventTypesMap = {
                ...eventTypesMap,
                [`CallsEventTypes.count`]: (eventTypesMap?.[`CallsEventTypes.count`] || 0) + data['CallsEventTypes.count'],
                [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.id`]: data['CallsEventTypes.scheduleEventTypeId'],
                [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.count`]: (eventTypesMap?.[`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.count`] || 0) + data['CallsEventTypes.count'],
                [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.name`]: eventTypeParameters?.name,
                [`CallsEventTypes.${data['CallsEventTypes.scheduleEventTypeId']}.color`]: eventTypeParameters?.color,
            };
        }
    });
    return { eventTypesMap, eventTypesKeys };
}

const normalizeComparisonCalls = (calls) => {
    const output = calls;
    if (calls['Orders.ordersTotal'] === null) {
        output['Orders.avgOrder'] = 0;
        output['Orders.count'] = 0;
        output['Orders.ordersTotal'] = 0;
        output['Calls.conversionRate'] = 0;
    }
    return output;
}

const comparisonMapper = ({ data, eventTypesList }) => {
    const calls = data.loadResponse.results[0].data || [];
    const air = data.loadResponse.results[1].data || [];
    const feedback = analyzieFeedbackResponse(data.loadResponse.results[2].data || []);
    let eventTypeIds = [];
    calls.forEach((call, index) => {
        let { eventTypesMap, eventTypesKeys } = calcHostsEventTypes(data.loadResponse.results[3].data || [], eventTypesList, call['Calls.hostId']);
        eventTypeIds = eventTypeIds.concat(eventTypesKeys)
        calls[index] = {
            ...normalizeComparisonCalls(calls[index]),
            ...sumAirParams(air.filter(airValues => airValues['Calls.hostId'] === call['Calls.hostId']) || []),
            ...feedback.find(feedbackValues => feedbackValues['Feedback.hostId'] === call['Calls.hostId']) || [],
            ...eventTypesMap,
        }
        calls[index][`Orders.count`] = calls[index][`Orders.ordersCount`];
        calls[index][`Orders.avgOrder`] = calls[index][`Orders.ordersTotal`] / calls[index][`Orders.ordersCount`] || 0;
        calls[index]['Calls.conversionRate'] = calls[index][`Orders.ordersCount`] / calls[index][`Calls.callsCompleted`] || 0;
    });
    eventTypeIds = eventTypeIds.filter((value, index, self) => self.indexOf(value) === index)
    return { newMergedData: calls, eventTypesKeys: eventTypeIds };
}

const comparisonEventTypesMapper = ({ data, eventTypesList }) => {
    const calls = data.loadResponse.results[0].data || [];
    const air = data.loadResponse.results[1].data || [];
    const feedback = data.loadResponse.results[2].data || [];
    let eventTypes = data.loadResponse.results[3].data || [];
    const eventTypeIds = [...new Set(eventTypes.map(item => item['CallsEventTypes.scheduleEventTypeId']))]
    const output = [];
    eventTypeIds.forEach(eventTypeId => {
        const eventTypeParameters = eventTypesList.docs.find(et => et.id === eventTypeId)?.data();
        output.push({
            'CallsEventTypes.name': eventTypeId === null
                ? '- none -'
                : (eventTypeParameters ? eventTypeParameters?.name : eventTypeId),
            'CallsEventTypes.color': eventTypeId === null
                ? `#808080`
                : (eventTypeParameters ? eventTypeParameters?.color : getRandomColor()),
            ...sumCallsByEventType(calls, eventTypeId),
            ...sumFeedbackByEventType(feedback, eventTypes, eventTypeId),
            ...sumCallEventsByEventType(air, eventTypes, eventTypeId),
            ...aggregateEventTypes(eventTypes, eventTypeId),
        });
    })
    return { newMergedData: output, eventTypesKeys: eventTypeIds };
}

const aggKPIMapper = (currentKPI, previousKPI, currentFeedbackKpi, previousFeedbackKpi, currencyCode, feedbackType) => {
    const getValue = (list, key) => (list[key] || 0);
    const setValues = (key, cubeKey) => {
        return {
            ...kpi[key],
            value: Number(getValue(currentKPI, cubeKey)),
            previousValue: Number(getValue(previousKPI, cubeKey))
        }
    };

    const setFeedbackValues = (key, feedbackType) => {
        switch (feedbackType) {
            case FEEDBACK_TYPE.NPS:
                {
                    const calcCurrentValue = getNPSvalue(currentFeedbackKpi);
                    const calcPreviousValue = getNPSvalue(previousFeedbackKpi);
                    return {
                        ...kpi[key],
                        value: calcCurrentValue != null ? Number(calcCurrentValue) : null,
                        previousValue: calcPreviousValue != null ? Number(calcPreviousValue) : null
                    }
                }
            case FEEDBACK_TYPE.CSAT:
                {
                    const calcCurrentValue = getCSATvalue(currentFeedbackKpi);
                    const calcPreviousValue = getCSATvalue(previousFeedbackKpi);
                    return {
                        ...kpi[key],
                        value: calcCurrentValue != null ? Number(calcCurrentValue) : null,
                        previousValue: calcPreviousValue != null ? Number(calcPreviousValue) : null
                    }
                }
            case FEEDBACK_TYPE.SIMPLE:
                const calcCurrentValue = currentFeedbackKpi?.['Feedback.avgRating'];
                const calcPreviousValue = previousFeedbackKpi?.['Feedback.avgRating'];
                return {
                    ...kpi[key],
                    value: calcCurrentValue != null ? Number(calcCurrentValue) : null,
                    previousValue: calcPreviousValue != null ? Number(calcPreviousValue) : null
                }
            default:
                return {
                    ...kpi[key],
                }
        }
    }

    const kpi = KPIObject(currencyCode, feedbackType);
    kpi['average_order'] = setValues('average_order', 'OrdersKPI.avgOrder');
    kpi['avg_duration'] = setValues('avg_duration', 'CallsKPI.avgDuration');
    kpi['calls_completed'] = setValues('calls_completed', 'CallsKPI.callsCompleted');
    kpi['calls_failed'] = setValues('calls_failed', 'CallsKPI.callsFailed');
    kpi['conversion_rate'] = setValues('conversion_rate', 'CallsKPI.conversionRate');
    kpi['order_per_call'] = setValues('order_per_call', 'CallsKPI.orderPerCall');
    kpi['order_per_duration'] = setValues('order_per_duration', 'CallsKPI.orderPerDuration');
    kpi['orders_count'] = setValues('orders_count', 'OrdersKPI.count');
    kpi['orders_total'] = setValues('orders_total', 'OrdersKPI.ordersTotal');
    kpi['total_duration'] = setValues('total_duration', 'CallsKPI.duration');
    kpi['feedback'] = setFeedbackValues('feedback', feedbackType);
    return kpi;
}

export const cubeTimeSeriesDataMapper = (data, startDate, endDate, getDataPointsArray, currencyCode, filterType, hosts, eventTypesList) => {
    if (!data || !data.loadResponse || !data.loadResponse.results || data.loadResponse.results.length === 0) {
        return;
    }

    let output = [];

    if (filterType === 'all') {
        const { newMergedData, eventTypesKeys } = defaultMapper({
            calls: data.loadResponse.results[0].data,
            air: data.loadResponse.results[1] ? data.loadResponse.results[1].data : [],
            feedback: data.loadResponse.results[2] ? data.loadResponse.results[2].data : [],
            eventTypes: data.loadResponse.results[3] ? data.loadResponse.results[3].data : [],
            startDate, endDate, getDataPointsArray, eventTypesList
        });
        output = { newMergedData, eventTypesKeys };
    }

    if (filterType === COMPARE_TYPES.HOSTS) {
        const { newMergedData, eventTypesKeys } = comparisonMapper({ data, eventTypesList });
        output = { newMergedData, eventTypesKeys };
    }

    if (filterType === COMPARE_TYPES.EVENT_TYPES) {
        const { newMergedData, eventTypesKeys } = comparisonEventTypesMapper({ data, eventTypesList });
        output = { newMergedData, eventTypesKeys };
    }

    return { dataset: output.newMergedData, eventTypesKeys: output.eventTypesKeys };
}

const normalizeFeedbackKpi = (feedback = []) => {
    const kpi = {};
    feedback.data.forEach(data => {
        kpi[`Feedback.avgRating`] = (kpi?.[`Feedback.avgRating`] || 0) + data['Feedback.avgRating'];
        kpi[`Feedback.npsCount`] = (kpi?.[`Feedback.npsCount`] || 0) + data['Feedback.npsCount'];
        kpi[`Feedback.npsDetractors`] = (kpi?.[`Feedback.npsDetractors`] || 0) + data['Feedback.npsDetractors'];
        kpi[`Feedback.npsPassives`] = (kpi?.[`Feedback.npsPassives`] || 0) + data['Feedback.npsPassives'];
        kpi[`Feedback.npsPromoters`] = (kpi?.[`Feedback.npsPromoters`] || 0) + data['Feedback.npsPromoters'];
        kpi[`Feedback.csatCount`] = (kpi?.[`Feedback.csatCount`] || 0) + data['Feedback.csatCount'];
        kpi[`Feedback.csatPromoters`] = (kpi?.[`Feedback.csatPromoters`] || 0) + data['Feedback.csatPromoters'];
    })
    return kpi;
}

export const cubeAggKPIDataMapper = (filterType, data, currencyCode, feedbackType) => {
    if (data === null) {
        return null;
    }

    const currentKpi = data.loadResponse.results?.[0].data?.[0];
    const previousKpi = data.loadResponse.results?.[1].data?.[0];

    const currentFeedbackKpi = data.loadResponse.results?.[3].data?.[0];
    const previousFeedbackKpi = data.loadResponse.results?.[4].data?.[0];

    if (currentKpi && previousKpi) {
        return aggKPIMapper(currentKpi, previousKpi, currentFeedbackKpi, previousFeedbackKpi, currencyCode, feedbackType);
    } else {
        return null;
    }

}

export const scheduledEventsDataMapper = (data, startDate, endDate, eventType) => {
    const datePoints = [];
    let date = Moment(startDate);
    let dataset = data;
    if (eventType?.id !== 'all') {
        dataset = dataset.filter(d => d?.eventType?.eventTypeId === eventType?.id);
    }
    while (date.isSameOrBefore(endDate)) {
        const dayEvents = dataset?.filter(d => Moment(Moment(d?.start).startOf('day'))?.isSame(date, 'day'));
        const completedDayEvents = dayEvents.filter(d => d.status === ScheduleStatus.completed);
        const canceledDayEvents = dayEvents.filter(d => d.status === ScheduleStatus.deleted && !d.noShow);
        const noShowDayEvents = dayEvents.filter(d => d.noShow);
        datePoints.push({
            date: date.format('YYYY-MM-DD'),
            total: dayEvents.length,
            completed: completedDayEvents.length,
            canceled: canceledDayEvents.length,
            noShow: noShowDayEvents.length,
        });
        date = date.add(1, 'day');
    }
    return { datePoints, dataset };
}

export const scheduledEventsHostsComparisonDataMapper = (data, startDate, endDate, hosts, eventType) => {
    const hostPoints = [];
    let dataset = data;
    if (eventType?.id !== 'all') {
        dataset = dataset.filter(d => d?.eventType?.eventTypeId === eventType?.id);
    }
    hosts.forEach(host => {
        const totalHostEvents = dataset.filter(d => d.hostId === host?.id);
        const completedHostEvents = totalHostEvents.filter(d => d.status === ScheduleStatus.completed);
        const canceledHostEvents = totalHostEvents.filter(d => d.status === ScheduleStatus.deleted && !d.noShow);
        const noShowHostEvents = totalHostEvents.filter(d => d.noShow);
        hostPoints.push({
            hostId: host?.id,
            total: totalHostEvents.length,
            completed: completedHostEvents.length,
            canceled: canceledHostEvents.length,
            noShow: noShowHostEvents.length,
        });
    })
    return { hostPoints, dataset };
}

export const scheduledEventsTypesComparisonDataMapper = (data, startDate, endDate, eventTypes) => {
    const eventTypesPoints = [];
    let dataset = data;
    eventTypes.forEach(eventType => {
        const total = dataset.filter(d => (d?.eventType?.eventTypeId === eventType?.id
            || eventType?.id === 'default' && !d?.eventType
            || eventType?.id === 'default' && !d?.eventType?.eventTypeId));

        const completed = total.filter(d => d.status === ScheduleStatus.completed);
        const canceled = total.filter(d => d.status === ScheduleStatus.deleted && !d.noShow);
        const noShow = total.filter(d => d.noShow);
        eventTypesPoints.push({
            eventTypeId: eventType?.id,
            eventTypeColor: eventType?.color,
            eventTypeName: eventType?.label,
            total: total.length,
            completed: completed.length,
            canceled: canceled.length,
            noShow: noShow.length,
        });
    })
    return { eventTypesPoints, dataset };
}
