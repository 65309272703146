import { useEffect, useState, useCallback, useMemo } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { useCollection } from 'react-firebase-hooks/firestore'
import { ScheduleStatus } from '../utils/consts';

export default function useShopEvents(shopId, startDate, endDate) {

	const scheduleRef = shopId
		? Firebase.firestore().collection('shops').doc(shopId).collection('schedule')
			.where('startTimestamp', '>=', startDate)
			.where('startTimestamp', '<', endDate)
			.where('status', 'in', [ScheduleStatus.assigned, ScheduleStatus.pending, ScheduleStatus.completed, ScheduleStatus.deleted])
		: null;

	const [ shopEvents, shopEventsLoading, shopEventsError ] = useCollection(scheduleRef);

	return { shopEvents, shopEventsLoading, shopEventsError };
}
