import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Stack, RadioButton, InlineError, Banner, TextStyle } from '@shopify/polaris';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { useShopProvider } from './ShopProvider';
import { BusinessHoursSummary, isBusinessHoursValid } from './BusinessHours'
import  { useAppAuthState } from '../authState'

export const CallPolicyMode = {
    both: 'both',
    scheduling_only: 'scheduling_only',
    instant_only: 'instant_only',
}

export function getCallPolicy(schedulingConfig) {
    if (schedulingConfig?.disableIncoming && !schedulingConfig?.disableScheduling)
        return CallPolicyMode.scheduling_only;
    else if (!schedulingConfig?.disableIncoming && schedulingConfig?.disableScheduling)
        return CallPolicyMode.instant_only;
    else
        return CallPolicyMode.both;
}

export function getCallPolicyDescription(policy) {
    switch (policy) {
        case CallPolicyMode.scheduling_only:
            return 'By appointment only';
        case CallPolicyMode.instant_only:
            return 'Accept instant calls only';
        default:
            return 'Accept both instant calls and scheduling appointments';

    }
}

export default function CallPolicyConfig() {
    const { shopOrigin } = useAppAuthState();
    const { schedulingConfig, schedulingConfigLoading, businessHoursConfig, businessHoursLoading } = useShopProvider();
    const [callPolicy, setCallPolicy] = useState(CallPolicyMode.both);
    const [businessHoursValid, setBusinessHoursValid] = useState(false);
    const history = useHistory();

    useEffect(()=>{
        if (!schedulingConfigLoading) {
            setCallPolicy(getCallPolicy(schedulingConfig));
        }
    },[ schedulingConfig, schedulingConfigLoading]);

    useEffect(()=>{
        if (!businessHoursLoading && businessHoursConfig) {
            setBusinessHoursValid(isBusinessHoursValid(businessHoursConfig));
        }

    }, [businessHoursConfig, businessHoursLoading]);

    const onIncomingCallsChange = (policy) => {
        setCallPolicy(policy);
        const update = {
            disableIncoming: policy === CallPolicyMode.scheduling_only,
            disableScheduling: policy === CallPolicyMode.instant_only,
        }

        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('scheduling').set(update, { merge: true })
            .catch(error => console.error('onIncomingCallsChange', error));
    }

    return (
        <Stack vertical>
            {!businessHoursValid && <Banner
                status='warning'
                action={{ content: 'Business hours settings', onAction: () => history.push('/businessConfig') }}
            >
                <p>
                    Calls will not be available when no valid business hours are configured.
                </p>
            </Banner>}
            <RadioButton
                label="Accept both instant calls and scheduling appointments"
                helpText="Clients can choose either to start a video call on the spot or to schedule an appointment for a later time/day"
                checked={callPolicy == CallPolicyMode.both}
                id={CallPolicyMode.both}
                name="availability"
                onChange={() => onIncomingCallsChange(CallPolicyMode.both)}
            />
            {callPolicy == CallPolicyMode.both &&
                <>
                    <Stack><p>Appointments are scheduled based on your <TextStyle variation='strong'>hosts' availability</TextStyle>. Go to the <Link to={'/calendar'}>store calendar</Link> to view and edit availability settings</p></Stack>
                    {businessHoursValid && <Stack><span>Instant calls are available:</span><BusinessHoursSummary config={businessHoursConfig} /><span>(change in <Link to={'/businessConfig'}>bussines hours settings</Link>)</span></Stack>}
                </>}
            <RadioButton
                label="By appointment only"
                helpText="Clients can schedule a virtual personal shopping experience with one of your hosts for a later time but they cannot just start a video call on the spot. Hosts can still invite clients to personal calls at any time."
                checked={callPolicy == CallPolicyMode.scheduling_only}
                id={CallPolicyMode.scheduling_only}
                name="availability"
                onChange={() => onIncomingCallsChange(CallPolicyMode.scheduling_only)}
            />
            {callPolicy == CallPolicyMode.scheduling_only &&
                <Stack><p>Appointments are scheduled based on your <TextStyle variation='strong'>hosts' availability</TextStyle>. Go to the <Link to={'/calendar'}>store calendar</Link> to view and edit availability settings</p></Stack>
            }
            <RadioButton
                label="Accept instant calls only"
                helpText="Clients can start a video call on the spot but they cannot schedule appointments using Boutiq."
                checked={callPolicy == CallPolicyMode.instant_only}
                id={CallPolicyMode.instant_only}
                name="availability"
                onChange={() => onIncomingCallsChange(CallPolicyMode.instant_only)}
            />
            {callPolicy == CallPolicyMode.instant_only &&
                <>
                    {businessHoursValid && <Stack><span>Instant calls are available:</span><BusinessHoursSummary config={businessHoursConfig} /><span>(change in <Link to={'/businessConfig'}>bussines hours settings</Link>)</span></Stack>}
                </>}
        </Stack>)
}
