import { Button, Text, Stack, Box } from '@shopify/polaris';
import ProductPrice from '../../../../../../components/products/ProductPrice';
import React from 'react';
import '../../styles.css'

const StoryPreviewProductDescription = ({product}) => {
  const {storiesConfig} = product;

  return (
    <Box className={`product-preview-description-container ${storiesConfig.action ? 'action' : ''}`}>
      <Box>
        <Stack vertical wrap={false} spacing={'extraTight'}>
          <Text as={'p'} variant={'headingSm'}>{product.title}</Text>
          <ProductPrice product={product} variant={'bodyMd'}/>
        </Stack>
      </Box>
      {storiesConfig.action && (
        <Box style={{justifySelf: 'center'}}>
          <Box className={'product-preview-action-button'} style={{backgroundColor: storiesConfig.action.color}}>
            <Text color={'text-inverse'} as={'p'} variant={'bodySm'}>{storiesConfig.action.label}</Text>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default StoryPreviewProductDescription;
