import { useMemo, useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useAppAuthState } from "../authState";
import { useCollection } from 'react-firebase-hooks/firestore';



export default function useBlacklist(shopId) {

    const blacklistRef = useMemo(() => {
        if (!shopId) return null;
        return Firebase.firestore().collection('shops').doc(shopId).collection('blacklist').orderBy('updatedAt','desc');
    }, [shopId]);

    const [blacklistData, blacklistLoading, blackListError] = useCollection(blacklistRef);
    blackListError && console.error('useBlacklist', blackListError);

    return {
        blacklistData, blacklistLoading, blackListError
    }
}

export function useBlacklistActions(shopId) {

    const { user } = useAppAuthState();
    const blacklistRef = useMemo(() => Firebase.firestore().collection('shops').doc(shopId).collection('blacklist'), [shopId]);

    const addToBlacklist = async (email, note = '') => {
        const timestamp = Firebase.firestore.FieldValue.serverTimestamp();

        const client = await blacklistRef.doc(email).get();
        if (client.exists) {
            throw new Error('Client already banned');
        }

        blacklistRef
            .doc(email)
            .set({
                createdAt: timestamp,
                updatedAt: timestamp,
                note,
                updatedBy: user.uid,
            })
            .catch(err => {
                console.error('banned list add ' + email, err);
                throw err;
            });
    }

    const addBulkBlacklist = async (clients) => {
        let batch = Firebase.firestore().batch();


        for (const c of clients) {
            const email = c.email.toLowerCase().trim();
            const client = blacklistRef.doc(email);
            const timestamp = Firebase.firestore.FieldValue.serverTimestamp();
            const mergeFields = ['updatedAt', 'updatedBy'];
            if (c.note) mergeFields.push('note');
            batch.set(client, {
                createdAt: timestamp,
                updatedAt: timestamp,
                note: c.note ?? null,
                updatedBy: user.uid,
            }, { mergeFields });
        }

        batch.commit()
          .then(() => {
            }).catch(err => {
                console.error('banned list add ', err);
                throw err;
            });
    }

    const deleteFromBlacklist = async (emails = []) => {
        let batchDelete = Firebase.firestore().batch();
        emails.forEach((email) => {
            batchDelete.delete(blacklistRef.doc(email));
        })

        return batchDelete.commit();
    }

    return {
        addToBlacklist,
        addBulkBlacklist,
        deleteFromBlacklist,
    }

}
