
import { useAppAuthState } from '../authState'
import { getUserToken } from '../utilsFirebase';
import CaazamError from '../utils/errors';

export const handleResponse = (response) => {
  if (response.ok) return response.json();
  else {
    return response
      .json()
      .catch((nonJsonError) => {
        throw new CaazamError(
          response.status,
          nonJsonError.message || 'Something went wrong'
        );
      })
      .then((jsonError) => {
        throw new CaazamError(jsonError.error.code, jsonError.error.reason);
      });
  }
};

function buildHeaders(token) {
  let headers = {
    'Content-Type': 'application/json',
    'X-Boutiq-App': 'admin',
  }
  if (token) {
    headers['authorization'] = 'Bearer ' + token;
  }
  return headers;
}

export default function useCaazamREST() {
  const { user, shopOrigin } = useAppAuthState();

  const onboardingCheck = (shopId, installKey, installHMAC) => {
    console.log(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/onboard/check`)
    return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/onboard/check`, {
      method: 'POST',
      headers: buildHeaders(),
      body: JSON.stringify({
        installKey,
        installHMAC,
      }),
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    }).catch(error => {
      console.error(error);
      throw error
    });
  }

  const callsAnalyticsReq = (body) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/analytics/calls`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify(body)
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const subPlanChange = (body) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/subscription/change`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify(body)
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const promoCodeValidate = (code) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/subscription/promoCode/${code}`, {
        method: 'POST',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const shopLinkCreate = (hostId = null, options = null, description = null) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/callLinks/shopLink`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify({ hostId, options, description })
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const shopLinkUpdate = (callLinkId, options = null, description = null) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/callLinks/shopLink/${callLinkId}`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify({ options, description })
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const notificationLogoUploadUrl = (body) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/settings/notifications_logo`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify(body),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const clientAppLogoUploadUrl = (body) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/settings/client_app_logo`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify(body),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const uploadCampaignImage = (body) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/settings/campaign-image`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify(body),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const clientAppBackgroundUploadUrl = (body) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/settings/client_app_background`, {
        method: 'POST',
        headers: buildHeaders(token),
        body: JSON.stringify(body),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const getAppinstallData = () => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/settings/appInstall`, {
        method: 'GET',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const getLocations = () => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/settings/locations`, {
        method: 'GET',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const createStoryVideo = (shopId) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/story/video`, {
        method: 'POST',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const getSchedulingSlots = (shopId, startDate, endDate, eventTypeId) => {
    return getUserToken(user).then(token => {
      let params = new URLSearchParams();
      if (startDate && endDate) {
        params.append('startDate', startDate);
        params.append('endDate', endDate);
      }
      if (eventTypeId) {
        params.append('eventTypeId', eventTypeId);
      }
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/hostSlots?${params.toString()}`, {
        method: 'GET',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const events = {
    createEvent : (shopId, callData) => {
      return getUserToken(user).then(token => {
        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule`, {
          method: 'POST',
          headers: buildHeaders(token),
          body: JSON.stringify(callData),
        }).then(response => {
          return handleResponse(response);
        });
      });
    },
    rescheduleEvent : (shopId, scheduleId, options) => {
      return getUserToken(user).then(token => {
        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/event/${scheduleId}/reschedule`, {
          method: 'POST',
          headers: buildHeaders(token),
          body: JSON.stringify(options),
        }).then(response => {
          if (response.ok) return;
          else return handleResponse(response);
        });
      });
    },
    assignEvent : (shopId, scheduleId, hostId) => {
      return getUserToken(user).then(token => {
        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/event/${scheduleId}/assign`, {
          method: 'POST',
          headers: buildHeaders(token),
          body: JSON.stringify({hostId}),
        }).then(response => {
          if (response.ok) return;
          else return handleResponse(response);
        });
      });
    },
    unassignEvent : (shopId, scheduleId) => {
      return getUserToken(user).then(token => {
        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/event/${scheduleId}/unassign`, {
          method: 'POST',
          headers: buildHeaders(token),
        }).then(response => {
          if (response.ok) return;
          else return handleResponse(response);
        });
      });
    },
    cancelEvent : (shopId, scheduleId, options) => {
      return getUserToken(user).then(token => {
        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/event/${scheduleId}/cancel`, {
          method: 'POST',
          headers: buildHeaders(token),
          body: JSON.stringify(options),
        }).then(response => {
          if (response.ok) return;
          else return handleResponse(response);
        });
      });
    },
    deleteEvent : (shopId, scheduleId) => {
      return getUserToken(user).then(token => {
        return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/event/${scheduleId}`, {
          method: 'DELETE',
          headers: buildHeaders(token),
        }).then(response => {
          if (response.ok) return;
          else return handleResponse(response);
        });
      });
    },
  }

  const searchCustomers = (shopId, searchString) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/customers/search?search=${encodeURIComponent(searchString)}`, {
        method: 'GET',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const getEventTypes = (shopId) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/schedule/eventTypes`, {
        method: 'GET',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    });
  }

  const searchProducts = (params) => {
    return getUserToken(user).then(token => {
      return fetch(`${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopOrigin}/productsv2/search?${params.toString()}`, {
        method: 'GET',
        headers: buildHeaders(token),
      }).then(response => {
        return handleResponse(response);
      });
    })
  }

  return {
    onboardingCheck,
    callsAnalyticsReq,
    subPlanChange,
    promoCodeValidate,
    shopLinkCreate,
    shopLinkUpdate,
    notificationLogoUploadUrl,
    clientAppLogoUploadUrl,
    clientAppBackgroundUploadUrl,
    uploadCampaignImage,
    createStoryVideo,
    getSchedulingSlots,
    getAppinstallData,
    getLocations,
    events,
    getEventTypes,
    searchCustomers,
    searchProducts,
  };
}
