import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import 'firebase/compat/firestore';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Stack, TextStyle, Toast, Layout, Card, Button, DataTable, EmptyState, TextContainer, SkeletonBodyText, Badge, Modal } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';

import { useShopProvider } from '../../components/ShopProvider';
import useShopLinks from '../../hooks/useShopLinks';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'

import { isCustomPolicy } from './customPolicy';

import './index.css';

function ShopLinksPage() {

    const history = useHistory();

    const { hosts, shopOrigin } = useShopProvider();
    const { links, linksLoading, revokeLink } = useShopLinks(shopOrigin);
    const [tableData, setTableData] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [linkToDelete, setLinkToDelete] = useState(null);
    const [deleteWorking, setDeleteWorking] = useState(false);

    const gotoCreateLink = () => history.push('/shop_links/create');
    const gotoEditLink = (linkId) => history.push(`/shop_links/edit/${linkId}`);

    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);

    const toastMarkup = showToast ? (
        <Toast content="Link URL copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;

    const getAssignedHostData = (hostId) => {
        const index = hosts.findIndex(host => host.id === hostId);
        if (index >= 0) return `${hosts[index].firstName} ${hosts[index].lastName}`
        else return 'Unknown';
    }

    const deleteLink = async (linkId) => {
        setDeleteWorking(true);
        try {
            await revokeLink(linkId);
        } catch (err) {
            console.error(`Failed deleting link ${linkId}`,err);
        } finally {
            setDeleteWorking(false);
            setLinkToDelete(null);
        }
    }

    const createLinkRow = (link) => {
        const data = link.data();
        const custom = isCustomPolicy(data.options);
        return [
            <Stack vertical spacing='extraTight'>
                <Stack alignment='center'>
                    <span>{data.description ?? data.callLinkUrl}</span>
                    <CopyToClipboard text={data.callLinkUrl} onCopy={toggleToastActive}>
                        <Button icon={DuplicateMinor} />
                    </CopyToClipboard>
                </Stack>
                <Stack alignment='center'>
                    {data.createdAt &&
                        <p className='link-created-at'>
                            <TextStyle variation='subdued'>
                                created {new Intl.DateTimeFormat(navigator.language, { dateStyle: 'short', timeStyle: 'short' }).format(data.createdAt.toDate())}
                            </TextStyle>
                        </p>}
                    {custom && <Badge status='info'>custom policy</Badge>}
                    {data.default && <Badge>default</Badge>}
                </Stack>
            </Stack>,
            !data.hostId ? 'All hosts' : getAssignedHostData(data.hostId),
            <Stack vertical spacing='extraTight'>
                <Button plain onClick={() => gotoEditLink(link.id)}>Details</Button>
                <Button plain destructive onClick={() => setLinkToDelete(link.id)}>Revoke</Button>
            </Stack>,
        ]
    }

    useEffect(() => {
        if (!linksLoading && links) {
            const data = links.docs.map(createLinkRow);
            setTableData(data);
        }
    }, [links, linksLoading])

    return (
        <AppPage
            title='Call Links'
        >
            <Layout>
                <Layout.Section>
                    <div style={{ marginTop: '2rem', padding: '0 1rem' }}>
                        <TextContainer spacing='tight'>
                            <p>
                                <TextStyle variation='subdued'>
                                    Call links can be shared in emails, social media and ad campaigns.
                                    </TextStyle>
                            </p>
                            <p>
                                <TextStyle variation='subdued'>
                                    Clients can click on the shared link to start video calls or schedule appointments with the store.
                                    </TextStyle>
                            </p>
                            {/* <p>
                                    Hosts can also create personal links in the Boutiq Host App
                                </p> */}
                        </TextContainer>
                    </div>
                </Layout.Section>
                <Layout.Section>
                    <Card sectioned actions={[{ content: 'Create Link', onAction: gotoCreateLink }]} >
                        {tableData.length > 0 &&
                            <DataTable columnContentTypes={['text', 'text', 'numeric']} headings={['Link', 'Assigned host', '']} rows={tableData} />
                        }
                        {tableData.length === 0 && !linksLoading && <EmptyState
                            heading="Create your first call link"
                            action={{ content: 'Create Link', onAction: gotoCreateLink }}
                        />}
                        {linksLoading && <SkeletonBodyText lines={4}/>}
                    </Card>
                </Layout.Section>
            </Layout>
            <Modal
                open={linkToDelete}
                onClose={() => setLinkToDelete(null)}
                title='Confirm Revoke Link'
                primaryAction={{
                    content: 'Revoke',
                    onAction: () => deleteLink(linkToDelete),
                    destructive: true,
                    loading: deleteWorking,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => setLinkToDelete(null),
                        disabled: deleteWorking,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>Are you sure you want to revoke and delete this call link? </p>
                        <p><TextStyle variation='strong'>Make sure you are not using the link on your online store</TextStyle></p>
                        <p>Clients who already have the link will not be able to use it for starting or scheduling calls anymore</p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
            {toastMarkup}
        </AppPage>


    )
}

export default function () {
    return (
        <AdminPage>
            <ShopLinksPage />
        </AdminPage>
    )
}
