import useCaazamREST from "./useCaazamREST";

const useProductSearch = (pricingContext = null) => {
    const {searchProducts: searchProductsRest} = useCaazamREST()

    const searchProducts = async (searchTerm, limit, cursor) => {

        const params = new URLSearchParams({
            search: searchTerm,
            limit,
        });
        if (cursor) params.append('cursor', cursor);
        if (pricingContext) params.append('pricingContext', pricingContext);

        return await searchProductsRest(params);
    };

    return ({
        searchProducts
    })
};

export default useProductSearch;
