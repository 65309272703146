import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Moment from 'moment-timezone';

import { Layout, Card, TextStyle, Stack, SkeletonBodyText, ProgressBar, Badge } from '@shopify/polaris';
import AppPage from '../components/AppPage'
import AdminPage from '../components/AdminPage'

import { useShopProvider } from '../components/ShopProvider';

export const minutesFormatter = () =>
    new Intl.NumberFormat(window.navigator.language, {
        maximumFractionDigits: 0,
    });

function Subscription() {
    const { subData, subDataLoading, activePlansData, currentBillingPeriod, billingLoading } = useShopProvider();
    const history = useHistory();

    if (!subDataLoading && !subData) {
        return <Redirect to='/select_plan' />
    }

    const subName = subData
        ? `${subData.planData.name}/${subData.planData.plan === 'annual' ? 'Annualy' : 'Monthly'}`
        : 'n/a'

    const subDate = subData
        ? Moment(subData.createdAt.toDate()).format('ll')
        : 'n/a'

    const subStatus = subData
        ? subData.shopify.subStatus
        : 'n/a'


    const minuteUsage = currentBillingPeriod
        ? (currentBillingPeriod.videoMinutes.quota - currentBillingPeriod.videoMinutes.amount) / currentBillingPeriod.videoMinutes.quota
        : 0

    var freeTrialDaysLeft = -1;
    if (subData && subData.freeTrialPeriodEnd) {
        freeTrialDaysLeft = Moment(subData.freeTrialPeriodEnd.toDate()).diff(Moment(),'days',true);
    }

    const isCustomPlan = subData?.planData?.custom;
    const isLegacyPlan = subData?.planId && !activePlansData?.subscriptionsList?.find(sub => sub === subData.planId)

    const customBadgeMarkup = () => {
        if (isCustomPlan) {
            return <Badge>custom </Badge>
        } else if (isLegacyPlan) {
            return <Badge>legacy </Badge>
        } else {
            return null;
        }
    }

    /* const hostUsage = subData
    ? subData.currentPeriodTotals.usedHosts / subData.planData.maxHosts
    : 0 */

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.replace('/settings') }]}
            title='Subscription'
            //primaryAction={<Button onClick={() => history.push('/select_plan')}>Change plan</Button>}
            secondaryActions={[{ content: 'Use Promo Code', onAction: () => history.push('/promo_code')}, { content: 'Change Plan', onAction: () => history.push('/select_plan')}]}
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned title='Your Boutiq Subscription'>
                        {freeTrialDaysLeft >= 0 && <Badge status='attention'>free trial - {Math.round(freeTrialDaysLeft)} days left</Badge>}
                        <Card.Section>
                            {subDataLoading && <SkeletonBodyText />}
                            {!subDataLoading && <Stack distribution='fill'>
                                <Stack vertical spacing='tight'>
                                    <TextStyle variation='strong'>Current plan</TextStyle>
                                    <Stack>
                                        <p>{subName}</p>
                                        {customBadgeMarkup()}
                                    </Stack>
                                </Stack>
                                <Stack vertical spacing='tight'>
                                    <TextStyle variation='strong'>Since</TextStyle>
                                    <p>{subDate}</p>
                                </Stack>
                                <Stack vertical spacing='tight'>
                                    <TextStyle variation='strong'>Status</TextStyle>
                                    <p>{subStatus}</p>
                                </Stack>
                                <Stack vertical spacing='tight'>
                                    <TextStyle variation='strong'>Max Hosts</TextStyle>
                                    <p>{subData.planData.maxHosts}</p>
                                </Stack>
                                <Stack vertical spacing='tight'>
                                    <TextStyle variation='strong'>Video Hours</TextStyle>
                                    <p>{subData.planData.videoHours}</p>
                                </Stack>
                            </Stack>}
                        </Card.Section>
                        {billingLoading && <Card.Section title='usage'>
                            <SkeletonBodyText />
                        </Card.Section>}
                        {!billingLoading && currentBillingPeriod &&
                            <Card.Section title={`usage ${Moment(currentBillingPeriod.periodStart.toDate()).format('ll')} to ${Moment(currentBillingPeriod.periodEnd.toDate()).format('ll')}`}>
                                <Stack vertical >
                                    <Stack alignment='center' >
                                        <Stack.Item fill>
                                            <p>Video Minutes</p>
                                        </Stack.Item>

                                        <div style={{ width: '40vw' }}>
                                            <span style={{ color: minuteUsage > 1.0 ? 'red' : null }}>
                                                <TextStyle variation='strong'>
                                                    Used {minutesFormatter().format(currentBillingPeriod.videoMinutes.quota - currentBillingPeriod.videoMinutes.amount)} minutes
                                                </TextStyle>
                                            </span>
                                            <ProgressBar progress={Math.min(minuteUsage * 100, 100)} />
                                            <Stack distribution='trailing'>
                                                <p> out of {minutesFormatter().format(currentBillingPeriod.videoMinutes.quota)} minutes</p>
                                            </Stack>
                                        </div>

                                    </Stack>
                                    {/* <Stack alignment='center'>
                                        <Stack.Item fill>
                                            <p>Invited Hosts</p>
                                        </Stack.Item>

                                        <div style={{ width: '40vw' }}>
                                            <span> <TextStyle variation='strong'>{subData.currentPeriodTotals.usedHosts} hosts active or invited </TextStyle></span>
                                            <ProgressBar progress={hostUsage * 100} />
                                            <Stack distribution='trailing'>
                                                <p>{subData.planData.maxHosts} hosts included</p>
                                            </Stack>
                                        </div>
                                    </Stack> */}
                                </Stack>
                            </Card.Section>}
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <Subscription/>
        </AdminPage>
    )
}
