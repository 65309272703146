import React, {useEffect, useState} from 'react';
import 'firebase/compat/firestore';

import {Stack, Subheading, TextField, TextStyle} from '@shopify/polaris';
import { useShopProvider } from '../../ShopProvider';

export default function WidgetBlock({helperText, changeHelperText, autoPopUpConfig, setAutoPopUpConfig, AUTO_POP_UP_DEFAULTS}) {

  const [disabled, setDisabled] = useState(false);

  useEffect(()=>{
    setDisabled(autoPopUpConfig.open < 0);
  },[autoPopUpConfig.open])

  const onChangeOpen = (val) => {
    setAutoPopUpConfig({ ...autoPopUpConfig, open: parseInt(val) });
  }

  const onChangeClose = (val) => {
    setAutoPopUpConfig({ ...autoPopUpConfig, close: parseInt(val) });
  }

  const onChangeDebounce = (val) => {
    setAutoPopUpConfig({ ...autoPopUpConfig, debounceTime: parseInt(val) });
  }

  return (
    <>
      <Stack vertical spacing='tight'>
        <TextField
          maxLength={33}
          label={<Subheading>Helper text (optional)</Subheading>}
          placeholder="Text displayed on hover"
          value={helperText}
          onChange={changeHelperText}
          clearButton
          onClearButtonClick={() => changeHelperText(null)}
        />
      </Stack>
      <Stack vertical spacing='tight'>
        <p> Set time after page load to auto display the helper text </p>
        <p><TextStyle variation='subdued'>Set to 0 to always display</TextStyle> </p>
        <TextField
          type='number'
          label='Open'
          suffix='sec'
          value={autoPopUpConfig.open.toString()}
          onChange={onChangeOpen}
          disabled={disabled}
          placeholder={AUTO_POP_UP_DEFAULTS.open}
          min={0}
        />
      </Stack>
      <Stack vertical spacing='tight'>
        <p> Set time helper text is displayed </p>
        <p><TextStyle variation='subdued'>Set to 0 to always display</TextStyle> </p>
        <TextField
          type='number'
          label='Close'
          suffix='sec'
          value={autoPopUpConfig.close.toString()}
          disabled={disabled}
          onChange={onChangeClose}
          placeholder={AUTO_POP_UP_DEFAULTS.close}
          min={0}
        />
      </Stack>
      <Stack vertical spacing='tight'>
        <p> Set minimum time gap between auto displaying the helper text </p>
        <p><TextStyle variation='subdued'>Set to 0 to display on every page load</TextStyle> </p>
        <TextField
          type='number'
          label='Min gap'
          suffix='sec'
          disabled={autoPopUpConfig.open <= 0}
          value={autoPopUpConfig.debounceTime.toString()}
          onChange={onChangeDebounce}
          placeholder={AUTO_POP_UP_DEFAULTS.debounceTime}
          min={0}
        />
      </Stack>
    </>
  );
}
