import {
    Popover,
    TextField,
    Icon,
    Spinner,
    EmptyState,
    Text,
    ButtonGroup,
    Button, Box
} from "@shopify/polaris";
import React, {useState} from "react";
import useProductSearch from "../../hooks/useProductSearch";
import {SearchMinor} from "@shopify/polaris-icons";
import ProductList from "./ProductList";
import { parseIdString } from '../../utils/products';
import './styles.css'

const ProductSearch = ({onUpdateProducts, onSavePreset, onSaveAs, presetProducts = [], disabled = false}) => {
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false)
    const [pressSearch, setPressSearch] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [cursor, setCursor] = useState(null);
    const [products, setProducts] = useState([]);
    const {searchProducts} = useProductSearch()

    const parseProducts = (products) => products.map((product) => ({...product, productId: parseIdString(product.productId)}))

    const searchForItems = async () => {
        if (!searchText) return;
        if (!loading) {
            setPressSearch(true)
            setLoading(true);
            try {
                const { products, cursor } = await searchProducts(searchText, 20);
                setProducts(parseProducts(products))
                setCurrentPage(0)
                setCursor(cursor)
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            searchForItems()
        }
    }

    const clearState = () => {
        setSearchText('')
        setProducts([])
        setPressSearch(false)
    }

    const updateText = (value) => {
        setSearchText(value)
    }

    const clearProducts = () => {
        onUpdateProducts(null)
    }

    const onSelectProduct = (product) => {
        onUpdateProducts(product)
        clearState()
    }

    const activator = (
      <Box onKeyDown={handleKeyPress}>
          <TextField
            disabled={disabled}
            prefix={<Icon source={SearchMinor}/>}
            onChange={updateText}
            placeholder="Search for products"
            labelHidden={true}
            value={searchText}
            autoComplete="off"
            label={'Search fo1r products'}
            connectedRight={<Button onClick={searchForItems} icon={SearchMinor}/>}
          />
      </Box>
    );

    return (
      <Box className='product-search-wrapper'>
          <Popover
            fullWidth={true}
            active={pressSearch}
            activator={activator}
            onClose={clearState}
          >
              {loading ? (
                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner size="large" />
                </Box>
              ) : (
                <ProductList
                  onSelectProduct={onSelectProduct}
                  products={products}
                  loading={loading}
                  emptyState={(
                    <EmptyState
                      image={''}>
                        <Text as={'p'} variant={'bodyMd'}>You can search for products to add to your preset</Text>
                    </EmptyState>
                  )}
                />
              )}
          </Popover>
          <ButtonGroup>
              <Button disabled={!presetProducts.length} onClick={clearProducts}>Clear</Button>
              <Button
                disabled={!presetProducts.length}
                onClick={onSavePreset}
                primary
                connectedDisclosure={onSaveAs ? {
                    disabled: !presetProducts.length,
                    actions: [{content: 'Save As...', onAction: onSaveAs}],
                } : null}
              >
                  Save
              </Button>
          </ButtonGroup>
      </Box>
    )
}

export default ProductSearch;
