import React, { useState } from 'react';
import {
	Stack,
	Card,
	DataTable,
	Modal,
	TextContainer,
	Text,
	SkeletonBodyText
} from '@shopify/polaris';
import CallHistoryTablePagination from './CallHistoryTablePagination';
import { useAppAuthState } from '../../../authState';
import useShopCalls from '../../../hooks/useShopCalls';

import CallHistoryFilter from './CallHistoryFilter';
import { DateRange } from '../../Dashboard/useDateRange';
import { useShopProvider } from '../../../components/ShopProvider';
import 'firebase/compat/analytics';
import './styles.css';
import { getFeedbackTypeHeader } from '../../../utils/feedback';
import { FEEDBACK_TYPE } from '../../../utils/consts';
import useExportCalls from '../../../hooks/useExportCalls';
import callDataTableRow from '../../../utils/calls';

const ROWS_PER_PAGE = 15;
const CONTENT_TYPES = ['text', 'text', 'text', 'text', 'text', 'text'];
const HEADERS = (feedbackType) => [
	'Date',
	'Client Data',
	'Duration',
	'Host Name',
	'Sales Amount',
	getFeedbackTypeHeader(feedbackType),
]

const defaultFilter = {
	hostId: null,
	eventId: null,
	range: DateRange.last30,
	search: ''
}

export default function CallHistoryTable() {
	const { feedbackConfig } = useShopProvider();
	const feedbackType = feedbackConfig?.type ?? FEEDBACK_TYPE.NPS;

	const [filter, setFilter] = useState(defaultFilter);

	const { shopOrigin } = useAppAuthState();
	const [modalOpen, setModalOpen] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState(null);
	const statusOptions = ['completed', 'error'];

	const {
		calls,
		getMoreCalls,
		refresh: refreshCalls,
		getHistoryCalls,
		loading
	} = useShopCalls(
		shopOrigin,
		statusOptions,
		ROWS_PER_PAGE,
		filter
	);

	const {exportHistoryCalls, isExportInProgress} = useExportCalls(getHistoryCalls, shopOrigin, filter)

	const [currentPage, setCurrentPage] = useState(0);

	const openReadMessage = (message) => {
		setFeedbackMessage(message);
		setModalOpen(true);
	}

	const mapCallToTable = (doc) => {
		const callData = doc.data();
		const {row} = callDataTableRow({
			callData,
			openReadMessage
		})
		return row
	}

	const handlerOnChangePage = (page) => {
		if ((page + 1) * ROWS_PER_PAGE >= calls.length) {
			getMoreCalls();
		}
		setCurrentPage(page);
	}

	const refreshTable = () => {
		refreshCalls();
		setCurrentPage(0);
	}

	const currentCalls = calls.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE).map(mapCallToTable);
	const hasNext = (calls.length > (currentPage + 1) * ROWS_PER_PAGE);

	const footerContent = () => {
		if (calls.length > 0) {
			return (
				<CallHistoryTablePagination
					currentPage={currentPage}
					hasNext={hasNext}
					itemsPerPage={ROWS_PER_PAGE}
					onChangePage={handlerOnChangePage}
				/>
			)
		}
		return (
			loading ? (
				<SkeletonBodyText lines={4} />
			) : (
				<Text>{'No calls found'}</Text>
			)
		)
	}

	return (
		<>
			<Stack vertical>
				<CallHistoryFilter
					onChangeFilter={setFilter}
					onRefresh={refreshTable}
					onExport={currentCalls?.length ? exportHistoryCalls : null}
					isExportInProgress={isExportInProgress}
					defaultFilter={defaultFilter}
				/>
				<Card>
					<DataTable
						columnContentTypes={CONTENT_TYPES}
						headings={HEADERS(feedbackType)}
						rows={currentCalls}
						footerContent={footerContent()}
					/>
					<Modal
						open={modalOpen}
						onClose={() => setModalOpen(false)}
					>
						<Modal.Section>
							<TextContainer>
								<p style={{ margin: 20 }}>{feedbackMessage}</p>
							</TextContainer>
						</Modal.Section>
					</Modal>
				</Card>
			</Stack>
		</>
	);
}
