import React from 'react';
import { Modal } from '@shopify/polaris';
import 'react-datepicker/dist/react-datepicker.css';
import { HostConfig } from './consts';

export default function CaazamConfirmCustomHours({ open, onClose, hostConfig, newHostConfig, setDefaultBusinessHours, removeHours }) {

    function getTitle() {
        if (newHostConfig === HostConfig.None) {
            return `Changing this setting will remove the host's availability for scheduled appointments`;
        } else if (newHostConfig === HostConfig.Default) {
            return `Changing this setting will set the host's availability to the store's business hours`;
        } else {
            return `Changing this settings means the host's availability will be set to a custom schedule`;
        }
    }

    function getBody() {
        if (hostConfig === HostConfig.Custom) {
            return (<Modal.Section> <p>You will lose any custom availability settings</p></Modal.Section>)
        } else
            return null;
    }

    function getAction() {
        if (newHostConfig === HostConfig.None) {
            return { content: `Set to ${newHostConfig}`, onAction: () => { onClose(); removeHours(); }, destructive: true };
        } else {
            return { content: `Set to ${newHostConfig}`, onAction: () => { onClose(); setDefaultBusinessHours(newHostConfig === HostConfig.Default); } };
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            title={getTitle()}
            primaryAction={getAction()}
            secondaryActions={{ content: 'Cancel', onAction: onClose }}
        >
            {getBody()}
        </Modal>
    )
}
