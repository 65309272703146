import React, { useState, useEffect } from 'react';

import { Select } from '@shopify/polaris';
import { Locales } from '../utils/locales';


export default function SelectLocale({ currentLocale, options, onChange }) {
    return <Select
        options={options.map(option => Locales[option])}
        value={currentLocale}
        onChange={onChange}
    />
}