import { useShopProvider } from '../components/ShopProvider';
import { useEffect, useState } from 'react';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';

const useStoryVideosListener = (storyId, storyVideos) => {
  const {shopOrigin} = useShopProvider()
  const [videos, setVideos] = useState(storyVideos)

  const db = getFirestore();

  useEffect(() => {
    setVideos(storyVideos ?? [])
  }, [JSON.stringify(storyVideos)])

  useEffect(() => {
    if (shopOrigin && storyId) {
      const videosCollection = collection(db, `shops/${shopOrigin}/videoStories/${storyId}/videoStoriesVideos`)
      const unsub = onSnapshot(videosCollection, snapshot => {
        const videosMetadata = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          .map(({id, clicks, views}) => ({id, clicks, views}))
        setVideos(videos => (
          videos.map(video => ({
            ...video,
            data: {
              ...video.data,
              ...videosMetadata.find(({id}) => id === video.id)
            }
          }))
        ))
      })

      return () => unsub()
    }
  }, [shopOrigin, storyId])

  return {
    videos: videos?.length === 0 ? storyVideos : videos
  }
}

export default useStoryVideosListener;
