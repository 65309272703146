import { useEffect, useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Moment from 'moment-timezone';
import { useCollection } from 'react-firebase-hooks/firestore';
import RRule, { RRuleSet, rrulestr } from 'rrule';
import {convertDateInstance, convertRRuleInstance, convertToRRuleDate} from "../utils/rruleConvert";

export default function useHostAvailability(shopId, currentRange, collectionPath, availableForFetch = true) {
  const [availabilityWindows, setAvailabilityWindows] = useState([]);

  const startFrom = currentRange.start
  const endOn = currentRange.end

  const startTime = startFrom ? Moment(startFrom).startOf('day') : Moment().startOf('week');
  const endTime = endOn ? Moment(endOn).endOf('day') : Moment(startTime).add(1, 'week').endOf('day');

  const availabilityRef = Firebase
    .firestore()
    .collection('shops')
    .doc(shopId)
    .collection(collectionPath);

  const ref = shopId && availabilityRef.where('endOn', '>', startTime.toDate());

  const [events, loading, eventsError] = useCollection(ref);
  eventsError && console.error('useHostAvailability', eventsError)

  useEffect(() => {
    if (events && availableForFetch) {
      const filteredEvents = events.docs.filter(doc => doc.data().startFrom.toDate() < endTime.toDate());
      const hostWindows = [];

      for (const eventDoc of filteredEvents) {
        const event = eventDoc.data();
        const { hostId, duration } = event;
        const eventId = eventDoc.id;
        if (event.recurrencePattern) {
          const ruleSet = new RRuleSet();
          const rule = rrulestr(event.recurrencePattern);
          ruleSet.rrule(rule);
          if (event.exceptionDates) {
            for (const expcetion of event.exceptionDates) {
              ruleSet.exdate(convertToRRuleDate(Moment.tz(expcetion, rule.origOptions.tzid)));
            }
          }

          const startTimeUTC = convertDateInstance(startTime.toDate());
          const endTimeUTC = convertDateInstance(endTime.toDate());
          // get the previous event occurence in case the window is happening currently
          const beforeEvent = convertRRuleInstance(ruleSet.before(startTimeUTC));
          if (beforeEvent) {
            const beforeEventEnd = Moment(beforeEvent).add(duration, 'minutes').toDate();
            if (beforeEventEnd > startTime)
              hostWindows.push({
                databaseEvent: {
                  id: eventId,
                  ...event
                },
                eventId,
                hostId,
                windowStart: beforeEvent,
                windowEnd: beforeEventEnd,
                type: collectionPath
              });
          }

          // get the future occurences until endDate
          const ruleEvents = ruleSet.between(startTimeUTC, endTimeUTC, true).map(d => convertRRuleInstance(d));
          const eventEndOn = event.endOn.toDate();
          hostWindows.push(...ruleEvents.map(ruleEvent => ({
            databaseEvent: {
              id: eventId,
              ...event
            },
            eventId,
            hostId,
            windowStart: ruleEvent,
            windowEnd: Moment(ruleEvent).add(duration, 'minutes').toDate(),
            isRecurring: true,
            recurrenceEnd: Moment(eventEndOn).year() >= 2099 ? null : eventEndOn,
            type: collectionPath,
          })));
        } else {
          hostWindows.push({
            databaseEvent: {
              id: eventId,
              ...event
            },
            eventId,
            hostId,
            windowStart: event.startFrom.toDate(),
            windowEnd: event.endOn.toDate(),
            isRecurring: false,
            type: collectionPath
          });
        }
      }
      hostWindows.sort((a, b) => a.windowStart - b.windowStart);
      setAvailabilityWindows(hostWindows);
    } else {
      setAvailabilityWindows([])
    }
  }, [events, availableForFetch])



  return {
    availabilityWindows,
    loading,
  }
}
