import React from 'react';
import './index.css'

const WelcomeBackground = ({
    backgroundImage,
    backgroundColor,
    backgroundOpacity
}) => {
    return (
        <div
            className='intro-welcome-wrapper'
            style={{ background: backgroundColor ? backgroundColor : 'none' }}  >
            {backgroundImage && <img src={backgroundImage}
                className='welcome-background-image'
                style={{
                    opacity: backgroundOpacity !== null ?
                        backgroundOpacity : 1
                }}>
            </img>}
          
        </div>
    );
};

export default WelcomeBackground;
