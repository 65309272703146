import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, Stack, Button, Toast, List, Text } from '@shopify/polaris';

import {
    DuplicateMinor,
    ChevronDownMinor,
    ChevronUpMinor,
} from '@shopify/polaris-icons';

import { useShopProvider } from '../../../../components/ShopProvider';
import { useClientSettingsProvider } from '../ClientSettingsProvider';

export default function AddLink() {
    const { shopOrigin, shopData } = useShopProvider();
    const { shopLinks } = useClientSettingsProvider();

    const [showToast, setShowToast] = useState(false);
    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);
    const toastMarkup = showToast ? (
        <Toast content="Code copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;

    const [details, showDetails] = useState(false);

    const editorExmaple = `<a href="javascript:boutiqApp.open()">Click to open</a>`;
    const CopyEditorAction = () =>
        <CopyToClipboard text={editorExmaple} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const editorPresetExmaple = `<a href="javascript:boutiqApp.openPreset('insert_code_here')">Click to open</a>`;
    const CopyEditorPresetAction = () =>
        <CopyToClipboard text={editorPresetExmaple} onCopy={toggleToastActive}>
            <Button icon={DuplicateMinor} />
        </CopyToClipboard>

    const editorCallLinkExmaple = `<a href="${shopLinks.docs?.[0].data()?.callLinkUrl
        ?? `${shopData.primaryDomain.url}/apps/video/vs/meet/[code]`
        }">Click to open</a>`;

    return (
        <Card.Section title="Activate Boutiq from an HTML link">
            <Stack vertical>
                <Stack vertical>
                    <Text>(a) Use any Boutiq Call Link to open the Boutiq app using the call link's configuration</Text>
                    {/* <Button plain icon={details ? ChevronUpMinor : ChevronDownMinor} onClick={() => showDetails(prev => !prev)}>{details ? 'Hide' : 'Show'} steps</Button> */}

                    <List type='number'>
                        <List.Item>Go to the <Link to='/shop_links' >Call Links</Link> page.</List.Item>
                        <List.Item>Create a call link and configure its expected behavior.</List.Item>
                        <List.Item>
                            <Stack vertical>
                                <Text>Click the call link's "Details" button to view the URL and detailed instructions on how to apply it.</Text>
                            </Stack>
                        </List.Item>
                    </List>
                    <Text> Call link example:</Text>
                    <span className='code-snippet code-background'>{editorCallLinkExmaple}</span>
                </Stack>
                <Stack vertical>
                    <Text>(b) You can force the Boutiq app to open without navigating to a new page by using the "javascript" prefix in the "href" attribute: </Text>
                    <Stack alignment='center'>
                        <span className='code-snippet code-background'>{editorExmaple}</span>
                        <CopyEditorAction />
                    </Stack>
                    <Text>or</Text>
                    <Stack alignment='center'>
                        <span className='code-snippet code-background'>{editorPresetExmaple}</span>
                        <CopyEditorPresetAction />
                    </Stack>
                </Stack>
            </Stack>
            {toastMarkup}
        </Card.Section >
    )
}