import moment from "moment-timezone";

import {TIME_LENGTH, TIME_PATTERN} from '../constants/time';
import {isSafariBrowser} from './system';
import {isInvalid} from './strings';

const validateTime = (time = '') => {
  if (time.length < TIME_LENGTH && !isSafariBrowser()) {
    return '';
  }
  return time;
};

const isIncorrectTimePeriod = (closeTime, openTime) => {
  return closeTime <= openTime || (
    isSafariBrowser()
      ? (isInvalid(openTime, TIME_PATTERN) || isInvalid(closeTime, TIME_PATTERN))
      : (openTime === '' || closeTime === '')
  );
};

const getPreviousPeriod = (dateFrom, dateTo) => {
  const diff = dateTo.diff(dateFrom, 'days') + 1;
  const previousStart = moment(dateFrom).subtract(diff, "days");
  const previousEnd = moment(dateTo).subtract(diff, "days");
  return {start: previousStart, end: previousEnd};
};

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const timezoneGMT = (timezone) => (
  new Intl.DateTimeFormat([], {
    timeZoneName: "short",
    timeZone: timezone
  })
    .formatToParts()
    .find(({type}) => type === "timeZoneName")
    .value
)

export {
  validateTime,
  isIncorrectTimePeriod,
  getPreviousPeriod,
  localTimezone,
  timezoneGMT
}
