import React, { useState, useRef } from 'react';
import { ReactComponent as ImageIcon } from './icons/image.svg';
import { ReactComponent as BagIcon } from './icons/bag.svg';
import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as ValueIcon } from './icons/value.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as CreditIcon } from './icons/credit.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as TotalRefunds } from './icons/total_refunds.svg';
import { ReactComponent as LabelIcon } from './icons/label_fill.svg';

import { currencyFormatter } from '../../../utils/formatters'
import './styles.css';

const PURCHASE_DATA = (config) => [
    config.showAvgBasket.isActive && {
        label: 'Avg basket',
        icon: <BagIcon />
    },
    config.showMaxBasket.isActive && {
        label: 'Max basket',
        icon: <CartIcon />
    },
    config.showTotalSpend.isActive && {
        label: 'Total spend',
        icon: <ValueIcon />
    }
];

const ADDITIONAL_NOTES = (currencyCode, config) => [
    config.showLastPurchaseDate.isActive && {
        label: 'Last purchase',
        icon: <CalendarIcon />,
        value: '1 Jul 2021'
    },
    config.showTotalCredit.isActive && {
        label: 'Credit',
        icon: <CreditIcon />,
        value: currencyFormatter(currencyCode).format(100),
    },
    config.showTotalRefunds.isActive && {
        label: 'Total refunds',
        icon: <TotalRefunds />,
        value: currencyFormatter(currencyCode).format(20),
    }
];

const PRODUCT_SUGGECTIONS = (config) => ({
    tabs: [
        config.showRecentlyViewedProducts.isActive && {
            label: 'Recently viewed'
        },
        config.showProductRecommendations.isActive && {
            label: 'Recommendations'
        },
    ],
});

const RECENT_PURCHASES = () => ({
    tabs: [
        {
            label: 'Recent purchases'
        }
    ]
});

export const CustomerProfileSectionsMockup = ({ currencyCode, config }) => {
    const randomNum  = useRef(Math.random() * 1000);
    const ProductRowSection = ({ sectionProps }) => {
        const [selectedTab, setSelectedTab] = useState(0);
        const multipleTabs = sectionProps.tabs.filter(item => item).length > 1;
        return sectionProps && <div className='products-row-section'>
            <div className='products-row-label-container'>
                {sectionProps.tabs.filter(item => item).map((item, index) =>
                    <p onClick={() => setSelectedTab(index)} className={`label${multipleTabs ? ' multiple' : ''}${index === selectedTab ? ' active' : ''}`} key={index}>{item.label}</p>
                )}
            </div>
            <div className='products-array-container-wrapper'>
                <div className='products-array-container'>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-image'>
                            <ImageIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <div className='customer-profile-container'>
            <div className='customer-name-section'>
                <p className='customer-name'>Name Surname</p>
                <p className='customer-email'>customer@email.com</p>
            </div>
            <div className='purchase-data-section'>
                {PURCHASE_DATA(config).filter(item => item).map((item, index) =>
                    <div key={index} className='purchase-data-item'>
                        <div className='icon-container'>
                            {item.icon}
                        </div>
                        <p className='label'>{item.label}</p>
                        <p className='value'>{currencyFormatter(currencyCode).format(randomNum.current)}</p>
                        <meter
                            min='0'
                            max='500'
                            value={100}></meter>
                    </div>)}
            </div>
            <div className='customer-additional-notes-section'>
                {ADDITIONAL_NOTES(currencyCode, config).filter(item => item)
                    .map((item, index) =>
                        <div key={index} className='customer-additional-notes-item'>
                            <div className='icon-container'>
                                {item.icon}
                            </div>
                            <p className='label-container'><span className='label'>{item.label}</span>:</p>
                            <p className='value'>{item.value}</p>
                        </div>)}
                {Object.keys(config).filter(fieldId => config[fieldId].isCustom && config[fieldId].isActive).map(fieldId => (
                    <div key={fieldId} className='customer-additional-notes-item'>
                        <div className='icon-container'>
                            <LabelIcon/>
                        </div>
                        <p className='label-container'><span className='label'>{config[fieldId].label}:</span></p>
                        <p className='value'>...</p>
                    </div>)
                )}
            {config.showClientNote.isActive && 
                <div className='customer-additional-notes-item'>
                    <div className='icon-container'>
                        <ListIcon />
                    </div>
                    <p className='label-container'><span className='lable'>Note</span>:</p>
                    <p className='value'>Text</p>
                </div>}

            </div>
            {config.showRecentPurchases.isActive &&
                <ProductRowSection sectionProps={RECENT_PURCHASES()} />}
            {(config.showRecentlyViewedProducts.isActive || config.showProductRecommendations.isActive) &&
                <ProductRowSection sectionProps={PRODUCT_SUGGECTIONS(config)} />}
        </div>
    )
}
