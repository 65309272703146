
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopDraftOrdersConfig(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('draftOrders') : null;
    const [draftOrderConfig, draftOrderConfigLoading, draftOrderConfigError] = useDocumentData(ref);

    const setDraftOrders = (enabled) => ref.set({ enabled }, { merge: true });

    return { draftOrderConfig,  draftOrderConfigLoading, draftOrderConfigError, setDraftOrders }
}
