import React, { useEffect, useState } from "react"
import {Stack, TextStyle, EmptyState, List, Icon, Box} from '@shopify/polaris';
import VideoCard  from "../VideoCard";
import './styles.css';
import '../VideoCard/styles.css'
import {PlusMinor} from "@shopify/polaris-icons";
import VideoPlayModal from "../VideoPlayModal";
import VideoDeleteModal from '../VideoDeleteModal';
import VideoLibrarySkeleton from '../VideoLibrarySkeleton';
import { useShopProvider } from '../../../../components/ShopProvider';
import { VIDEO_STATUS } from '../../../../constants/story';

export const VideoLibrary = ({
    uploadAction,
    uploadingStates = [],
    deleteVideo,
    selectedItems,
    onAddStory,
    isStoryPage = false,
    onClearUploadingState
}) => {
    const {shopVideos, shopVideosLoading} = useShopProvider()
    const [activeVideoId, setActiveVideoId] = useState(null);
    const [playingStoryData, setPlayingStoryData] = useState(null);
    const [isPlayingAreaOpen, setIsPlayingAreaOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(() => {
      const videos = shopVideos.filter((shopVideo) => uploadingStates.some(({videoId}) => videoId === shopVideo.id))
      if (videos.some(({data}) => data.status === VIDEO_STATUS.READY)) {
        const video = videos.find(({data}) => data.status === VIDEO_STATUS.READY);
        onClearUploadingState(video)
      }
    }, [JSON.stringify(shopVideos), JSON.stringify(uploadingStates)])

    const deleteStory = (id) => {
        setIsDeleteDialogOpen(true);
        setActiveVideoId(id);
    }

    const onPlay = (videoData) => {
        setPlayingStoryData(videoData);
        setIsPlayingAreaOpen(true);
    }

    const plusButton = (videoItem) => {
      return (
        <div
          onClick={() => onAddStory(videoItem)}
          className={`video-addBtn`}
        >
          <Icon source={PlusMinor}/>
        </div>
      )
    }

    const videoLibraryContainer = () => (
      shopVideos.length === 0 ? (
        <>
          <Box style={{marginTop: '-2rem', marginBottom: '-4rem'}}>
            <EmptyState
              heading="Upload a video to your video library"
              fullWidth
              action={uploadAction}
              image="/stories/story_upload.png"
            />
          </Box>
          <Stack vertical alignment='center' spacing='extraTight'>
            <Stack vertical spacing='extraTight'>
              <TextStyle variation='subdued'>
                <p>We recommend:</p>
                <List type='bullet'>
                  <List.Item>a short video (from 10-15 seconds up to 60 seconds)</List.Item>
                  <List.Item>taken in portrait mode (using a mobile phone works great)</List.Item>
                </List>
              </TextStyle>
            </Stack>
          </Stack>
        </>
      ) : (
        <Box style={{display: 'flex', flexFlow: 'row', overflow: 'auto', flexWrap: 'unset'}}>
          {shopVideos.map(item => (
            <Box style={{margin: '0.8rem 0px 0px 0.8rem'}} key={item.id}>
              <VideoCard
                isSelected={isStoryPage ? false : !!selectedItems?.find(option => option.id === item.id)}
                plusButton={onAddStory ? () => plusButton(item) : null}
                videoItem={item}
                uploadingStates={uploadingStates.find(state => state.videoId === item.id)}
                onViewVideo={onPlay}
                showDelete={!!deleteVideo}
                deleteStory={deleteStory}
              />
            </Box>
          ))}
        </Box>
      )
    )

    return (
      <>
        {shopVideosLoading ? <VideoLibrarySkeleton /> : videoLibraryContainer()}
        <VideoPlayModal
          playingStoryData={playingStoryData}
          isPlayingAreaOpen={isPlayingAreaOpen}
          onClose={() => {
            setIsPlayingAreaOpen(false);
            setPlayingStoryData(null);
          }}
        />
        <VideoDeleteModal
          open={isDeleteDialogOpen}
          title={'Confirm delete video'}
          subTitle={'Are you sure you want to delete this video? This action cannot be undone'}
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          onDelete={() => {
            deleteVideo(activeVideoId);
            setIsDeleteDialogOpen(false);
          }}
        />
      </>
    )
}
