import React, { useEffect, useState } from 'react';
import { Banner } from '@shopify/polaris';
import useCaazamREST from '../hooks/useCaazamREST';

export default function UpgradeBanner({ content }) {
    const { getAppinstallData } = useCaazamREST();
    const [appLaunchUrl, setAppLuanchUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAppinstallData()
            .then(res => setAppLuanchUrl(res.currentAppInstallation?.launchUrl))
            .catch(error => console.error('getAppinstallData', error))
            .finally(() => setLoading(false));
    }, [])

    const action = {
        content: 'Upgrade',
        onAction: () => { setLoading(true); window.location.href = appLaunchUrl;},
        disabled: !appLaunchUrl,
        loading: loading,
    }

    return (

        <Banner status='warning' title='Boutiq requires an upgrade' action={action}>
            {content ?? 'Please upgrade the Boutiq app'}
        </Banner>
    )
}