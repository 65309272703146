import React, { useEffect } from 'react';

import { Stack, TextContainer, Heading } from '@shopify/polaris';
import WidgetSettingsComponent, { OnlineStoreConfigMethod, DefaultWidgetConfig, saveWidgetEnabled, saveWidgetConfig } from "../../components/Widget";
import * as FacebookPixel from '../../utils/facebookPixel';
import { useShopProvider } from '../../components/ShopProvider'
import './index.css';

export default function OnlineStore() {

    const query = new URLSearchParams(window.location.search);
    const subConfirmed = query.get('subConfirmed');
    const { subData, onlineStoreConfig, onlineStoreConfigLoading, shopOrigin } = useShopProvider();
    const currentPlan = subData ? subData.planData : null;
    const isWidget = onlineStoreConfig && onlineStoreConfig.method === OnlineStoreConfigMethod.widget.id;

    useEffect(() => {
        if (subConfirmed && currentPlan) {
            FacebookPixel.track('Subscribe', { currency: 'USD', value: currentPlan.price.month });
        }

    }, [subConfirmed, currentPlan]);

    useEffect(() => {
        if (!onlineStoreConfigLoading) {
            if (onlineStoreConfig?.method == null || onlineStoreConfig?.methodConfig == null)  {
                saveWidgetConfig(shopOrigin, true, DefaultWidgetConfig);
            }
        }
    }, [onlineStoreConfig, onlineStoreConfigLoading]);

    const onWidgetToggle = async (val) => {
        try {
            await saveWidgetEnabled(shopOrigin, val);
        } catch (error) {
            console.error('saveWidgetEnabled', error)
        }
    }

    const onConfigChange = (config) => {
        saveWidgetConfig(shopOrigin, isWidget, config);
    }

    return (
        <Stack vertical spacing='loose'>
            <TextContainer>
                <Heading>Add Boutiq to your online store:</Heading>
                <TextContainer spacing='tight'>
                    <p>Let’s make sure your online store visitors can easily start a video call</p>
                    <p>You can find more options for integrating Boutiq into your online store later on in the Settings page.</p>
                </TextContainer>
                <WidgetSettingsComponent
                    enabled={isWidget}
                    config={onlineStoreConfig?.methodConfig}
                    loading={onlineStoreConfigLoading || !onlineStoreConfig?.methodConfig}
                    onEnable={onWidgetToggle}
                    onConfigChange={onConfigChange} />
            </TextContainer>
        </Stack>
    )
}