const FACEBOOK_PIXEL = process.env.REACT_APP_FACEBOOK_PIXEL;

let initialized = !!window.fbq;

const verifyInit = () => {
    if (!initialized) {
        console.error('Facebook pixel not initialized before use');
    }
    return initialized;
};

function init() {
    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
    );
    /* eslint-enable */

    window.fbq('init', FACEBOOK_PIXEL);
    window.fbq('track', 'PageView');
    initialized = true;
}

init();

exports.setUserProperties = (email = null, firstName = null, lastName = null) => {
    if (!verifyInit()) {
        return;
    }

    window.fbq('init', FACEBOOK_PIXEL, {
        em: email,         // Values will be hashed
        fn: firstName,    // automatically by the pixel
        ln: lastName      // using SHA-256
      });
}

exports.track = (title, data) => {
    if (!verifyInit()) {
        return;
    }

    window.fbq('track', title, data);
}

exports.trackCustom = (title, data) => {
    if (!verifyInit()) {
        return;
    }

    window.fbq('trackCustom', title, data);
}