import { Card, Stack, Text, Badge, Subheading, Box } from "@shopify/polaris";
import React, { useMemo } from "react";
import {useHistory} from "react-router-dom";
import VideoCard from "../../../components/VideoCard";
import { VIDEO_STATUS, STORY_STATUS } from '../../../../../constants/story';
import usePublishButton from '../../../hooks/usePublishButton';
import VideoStatistic from '../../../components/VideoCard/components/VideoStatistic';
import { updatedAtFormat } from '../../../../../utils/formatters';
import useStoryVideosListener from '../../../../../hooks/useStoryVideosListener';

const StoryPreviewCard = ({story, onPreview, onViewStoryPage}) => {
  const history = useHistory();
  const {title} = story ?? {};
  const {videos} = useStoryVideosListener(story.storyId, story.videos)
  const publishButton = usePublishButton(story, videos)

  const canViewPage = useMemo(()=> videos.length > 0 && story?.conditionUrl,[story, videos.length]);

  const actions = useMemo(() => {
    let actions = [
      { content: 'Preview', onAction: onPreview, disabled: videos.length === 0 },
      { content: 'Edit', onAction: () => history.push({ pathname: '/stories/sequence', state: { storyId: story.storyId } }) },
      publishButton,
    ];
    if (onViewStoryPage && story.status === STORY_STATUS.PUBLISHED) {
      actions.splice(1,0,{ content: 'View page', onAction: onViewStoryPage, disabled: !canViewPage })
    }
    return actions;

  }, [onViewStoryPage, canViewPage, story, videos.length])

  return (
    <>
      <Card
        sectioned
        title={(
          <Stack vertical spacing='tight'>
            <Subheading>{title}</Subheading>
            <Text variant={'bodySm'} color={'subdued'}>{updatedAtFormat(story?.updatedAt?.toDate())}</Text>
          </Stack>
        )}
        actions={actions}
      >
        <Stack vertical spacing='tight'>
          {story?.errorMessage && (
            <Text color={'critical'}>{story?.errorMessage}</Text>
          )}
          <div style={{display: 'flex', flexFlow: 'row', overflow: 'auto', flexWrap: 'unset'}}>
            {videos.filter((video) => video.data.status !== VIDEO_STATUS.DELETED).map(item => (
              <Box key={item.id} style={{margin: '0.8rem 0px 0px 0.8rem'}}>
                <Box>
                  <VideoCard
                    key={item.id}
                    isCloseBtn={false}
                    metadataOnly={true}
                    showDelete={false}
                    videoItem={item}
                  />
                </Box>
                <VideoStatistic position={'relative'} views={item.data.views} clicks={item.data.clicks} />
              </Box>
            ))}
          </div>
        </Stack>
      </Card>
    </>
  )
}

export default StoryPreviewCard;
