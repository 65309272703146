import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

import { FormLayout, TextField, TextStyle, Page, Form, Button, InlineError, Stack } from '@shopify/polaris';

import useInstallData from '../../hooks/useInstallData';
import { useAppAuthState } from '../../authState';
import PublicPage from '../../components/PublicPage';
import { isEmailValid } from '../../utils/strings'

import './login.css';

function Login() {
    const { shopOrigin, signInAdmin } = useAppAuthState();
    const history = useHistory();
    const { installKey, removeInstallData } = useInstallData(shopOrigin);
    const postInstall = useRef(!!installKey);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState(null);

    const [logInError, setLogInError] = useState(null);
    const [isAuthLoading, setAuthLoading] = useState(false);

    useEffect(() => {
        if (!!postInstall.current) {
            firebase.analytics().logEvent('admin.onboard.login');
        }
    }, []);

    const handleEmailChange = (newEmail) => {
        setEmail(newEmail);
        setEmailError(null);
    }

    const validateEmail = () => {
        if (email === '' || !email) {
            setEmailError('email is required');
            return false;
        } else if (!isEmailValid(email)) {
            setEmailError('email is not valid');
            return false;
        }
        setEmailError(null);
        return true;
    }

    const handleSubmit = async () => {
        setLogInError(null);

        if (!validateEmail()) {
            return;
        }

        setAuthLoading(true);
        try {
            await signInAdmin(email, password);

            removeInstallData();
            history.push(postInstall.current ? '/onboard' : '/');
        } catch (error) {
            setAuthLoading(false);
            setPassword('');
            switch (error.code) {
                case 'auth/user-disabled':
                    setLogInError(new Error(`User ${email} is disabled`));
                    break;
                case 'auth/user-not-found':
                    setLogInError(new Error(`User not found: ${email}`));
                    break;
                case 'auth/wrong-password':
                    setLogInError(new Error(`Wrong password`));
                    break;
                case 'auth/invalid-email':
                    setLogInError(new Error(`invalid email address`));
                    break;
                case 'caazam/not-admin':
                    setLogInError(error);
                    break;
                default:
                    console.error(error);
                    setLogInError(new Error(`An error occured`));
            }
        }
    }

    const handleResetPassword = () => {
        const resetUrl = new URL(process.env.REACT_APP_CAAZAM_HOST_APP);
        resetUrl.pathname = '/reset_password';
        resetUrl.searchParams.append('redirectUrl', window.location );
        window.location.replace(resetUrl.toString());
    }

    return (
        <Page>
            <div className='login-container'>
                <div className='login-row'>
                    <div className='login-content'>
                        <img src='/boutiq_dark.svg' className='logo' alt='Boutiq logo' />
                        {postInstall.current && <div className='trailing'>
                            <p>
                            <TextStyle variation='subdued'>Need a new account ? <Link to={'/signup'}>Signup</Link></TextStyle>
                            </p>
                        </div>}
                        <div className='login-form'>
                            <Form preventDefault noValidate implicitSubmit={false}>
                                <FormLayout>
                                    <Stack vertical spacing='loose'>
                                        <TextField
                                            value={email}
                                            onChange={handleEmailChange}
                                            label="Email"
                                            type="email"
                                            onBlur={validateEmail}
                                            error={emailError}
                                        />
                                        <TextField
                                            value={password}
                                            onChange={setPassword}
                                            label="Password"
                                            type="password"
                                        />
                                        <Stack vertical spacing='tight'>
                                            <Button primary size='large' onClick={handleSubmit} loading={isAuthLoading}>Log In</Button>
                                            <Button plain size='slim' onClick={handleResetPassword}>Forgot?</Button>
                                        </Stack>
                                    </Stack>
                                </FormLayout>

                            </Form>
                        </div>
                        <InlineError message={logInError && logInError.message} />
                    </div>
                    <div className='landing-media'>
                        <img src='/landing_page.png' alt='Host video app' />
                    </div>
                </div>
            </div>
        </Page>
    );

}

export default function() {
    return (
        <PublicPage>
            <Login/>
        </PublicPage>
    )
}
