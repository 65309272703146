import { Box, Card, Text, Thumbnail } from "@shopify/polaris";
import {ImageMajor} from "@shopify/polaris-icons";
import React  from "react";
import ProductPrice from "../../../../../../components/products/ProductPrice";
import StoryProductSettings from "../StoryProductSettings";
//import './styles.css';

const StoryProductList = ({products, emptyState, onDelete, isBoutiqClientStory, onChangeProductConfig, videoDuration, productError }) => {
  if (!products?.length) return emptyState;
  return (
    <>
      {products.map((product, index) => {
        return (
          <Card key={index} title={`Product #${index + 1}`} sectioned actions={[{ content: 'Delete', onAction: () => onDelete(product), destructive: true }]}>
            <Card.Section>
              <Box style={{ display: 'grid', gridTemplateColumns: '90px 1fr' }}>
                <Thumbnail
                  transparent={!!product.image}
                  source={product.image ?? ImageMajor}
                  size={"large"}
                  alt={product.title}
                />
                <Box>
                  <Text fontWeight={'medium'} as={"h4"} variant={'headingMd'}>{product.title || product.productTitle}</Text>
                  <ProductPrice product={product} />
                </Box>
              </Box>
            </Card.Section>
            <StoryProductSettings
              product={product}
              isBoutiqClientStory={isBoutiqClientStory}
              onChangeProductConfig={onChangeProductConfig}
              videoDuration={videoDuration}
              productError={productError}

            />
          </Card>
        )
      })
      }
    </>
  )
}

export default StoryProductList;
