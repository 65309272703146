import React, { useState } from 'react';
import {
  ACTION_TYPE_LABELS,
  ACTION_TYPES,
  DEFAULT_POSITION_CONFIG,
  EXPAND_STORIES_WIDGET_SIZE,
  SIZE_CONFIG, TOP_LEFT_POSITION,
  TOP_POSITION,
} from '../../consts';
import {
  Card,
  ChoiceList,
  Stack,
  Text,
  TextField,
  Popover,
  ActionList,
  Button, Box
} from '@shopify/polaris';
import { TwitterPicker } from 'react-color';
import { COLOR_PICKER_DEFAULTS, DEFAULT_COLOR, DEFAULT_STORY_PRODUCT_CTA } from '../../../../../../utils/consts';
import { useShopProvider } from '../../../../../../components/ShopProvider';

const StoryProductSettings = ({ product, onChangeProductConfig, videoDuration, productError, isBoutiqClientStory }) => {
  const { appStylesConfig, shopLocales } = useShopProvider();
  const [popoverActive, setPopoverActive] = useState(false);

  const defaultColor = appStylesConfig?.clientAppTheme?.primaryColor ?? DEFAULT_COLOR

  const actionItems = Object.keys(ACTION_TYPES)
    .map((key) => ({ content: ACTION_TYPE_LABELS[key], onAction: () => onChangeAction(ACTION_TYPES[key]) }))

  const actionTypeLabel = (type) => {
    switch (type) {
      case ACTION_TYPES.SHOW_PRODUCT_PAGE:
        return ACTION_TYPE_LABELS.SHOW_PRODUCT_PAGE;
      case ACTION_TYPES.ADD_TO_CART:
        return ACTION_TYPE_LABELS.ADD_TO_CART;
      case ACTION_TYPES.OPEN_BOUTQ:
        return ACTION_TYPE_LABELS.OPEN_BOUTQ;
      case ACTION_TYPES.GO_TO_PAGE:
        return ACTION_TYPE_LABELS.GO_TO_PAGE;
      default:
        return 'none'
    }
  }

  const onChangeColor = (color) => {
    const storiesConfig = { ...product.storiesConfig, color }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangePosition = (val) => {
    const position = Array.isArray(val) ? val.pop() : val
    const storiesConfig = { ...product.storiesConfig, position }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeShowFrom = (showFrom) => {
    if (showFrom < 0 || showFrom > videoDuration) {
      return
    }
    const storiesConfig = { ...product.storiesConfig, showFrom }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeShowUntil = (showUntil) => {
    if (showUntil < 0) {
      return
    }
    const storiesConfig = { ...product.storiesConfig, showUntil }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeSize = (val) => {
    const size = Array.isArray(val) ? val.pop() : val
    const storiesConfig = { ...product.storiesConfig, size, position: size === EXPAND_STORIES_WIDGET_SIZE ? TOP_POSITION : TOP_LEFT_POSITION }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeAction = (type) => {
    let storiesConfig;
    const color = product.storiesConfig.action?.color ?? defaultColor
    const localeCTA = DEFAULT_STORY_PRODUCT_CTA[shopLocales[0]]
    switch (type) {
      case ACTION_TYPES.NOT_SELECTED:
        storiesConfig = { ...product.storiesConfig, action: null }
        break;
      case ACTION_TYPES.SHOW_PRODUCT_PAGE:
        storiesConfig = {
          ...product.storiesConfig, action: {
            type,
            label: localeCTA.SHOW_PRODUCT_PAGE,
            destinationPage: product.onlineStoreUrl,
            color
          }
        }
        break;
      case ACTION_TYPES.ADD_TO_CART:
        storiesConfig = {
          ...product.storiesConfig, action: {
            type,
            label: localeCTA.ADD_TO_CART,
            color
          }
        }
        break;
      case ACTION_TYPES.OPEN_BOUTQ:
        storiesConfig = {
          ...product.storiesConfig, action: {
            type,
            label: localeCTA.OPEN_BOUTQ,
            color
          }
        }
        break;
      case ACTION_TYPES.GO_TO_PAGE:
        storiesConfig = {
          ...product.storiesConfig, action: {
            type,
            label: localeCTA.GO_TO_PAGE,
            color
          }
        }
        break;
    }
    togglePopoverActive()
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeDestinationPage = (destinationPage) => {
    const storiesConfig = { ...product.storiesConfig, action: { ...product.storiesConfig.action, type: ACTION_TYPES.GO_TO_PAGE, destinationPage } }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onClearDestinationPage = () => {
    const storiesConfig = { ...product.storiesConfig, action: { ...product.storiesConfig.action, destinationPage: '' } }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onClearActionLabel = () => {
    const storiesConfig = { ...product.storiesConfig, action: { ...product.storiesConfig.action, label: '' } }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeActionLabel = (label) => {
    const storiesConfig = { ...product.storiesConfig, action: { ...product.storiesConfig.action, label } }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const onChangeActionButtonColor = (color) => {
    const storiesConfig = { ...product.storiesConfig, action: { ...product.storiesConfig.action, color } }
    onChangeProductConfig({ ...product, storiesConfig })
  }

  const togglePopoverActive = () => setPopoverActive(prev => !prev);

  const activator = (
    <Button fullWidth onClick={togglePopoverActive} disclosure textAlign={'left'}>
      {actionTypeLabel(product.storiesConfig.action?.type)}
    </Button>
  );

  return (
    <>
      <Card.Section title={'Product display'}>
        <Stack vertical>
          <Stack distribution='fillEvenly'>
            <ChoiceList
              title='Initial state'
              choices={SIZE_CONFIG}
              selected={product.storiesConfig.size}
              onChange={onChangeSize}
            />
            <ChoiceList
              title='Position'
              choices={DEFAULT_POSITION_CONFIG}
              disabled={product.storiesConfig.size === EXPAND_STORIES_WIDGET_SIZE}
              selected={product.storiesConfig.position}
              onChange={onChangePosition}
            />
          </Stack>
          <Stack vertical spacing='extraTight'>
            <Stack distribution='fillEvenly' wrap={false}>
              <TextField
                label={'Show from'}
                type={'number'}
                suffix={'sec'}
                max={videoDuration}
                min={0}
                autoComplete='off'
                value={product.storiesConfig.showFrom}
                onChange={onChangeShowFrom}
                helpText='Set to 0 to always display'
              />
              <TextField
                label={'Display until'}
                type={'number'}
                suffix={'sec'}
                max={videoDuration}
                min={0}
                autoComplete='off'
                value={product.storiesConfig.showUntil ?? '0'}
                onChange={onChangeShowUntil}
                helpText={'Set to 0 to display to end'}
                error={productError?.showUntil && productError?.productId === product.productId && (
                  <Box style={{ maxWidth: 180 }}>
                    <Text>{productError.showUntil}</Text>
                  </Box>
                )}
              />
            </Stack>
            <Text color='subdued'>video duration is {videoDuration} sec</Text>
          </Stack>
          <Stack vertical spacing={'extraTight'}>
            <Text as={'p'} variant={'bodyMd'}>{'Background color'}</Text>
            <TwitterPicker
              triangle='hide'
              color={product.storiesConfig.color}
              onChange={({ hex }) => onChangeColor(hex)}
              colors={['#FFFFFF', '#C4C2C2', '#A6A4A4']}
              styles={{
                default: {
                  hash: {
                    display: 'none',
                  },
                  input: {
                    display: 'none',
                  }
                }
              }}
            />
          </Stack>
        </Stack>
      </Card.Section>
      {!isBoutiqClientStory && (
        <Card.Section title={'Product action'}>
          <Stack vertical>
            <Stack vertical spacing={'extraTight'}>
              <Stack wrap={false}>
                <Box width={'200px'}>
                  <Box className={'Polaris-Labelled__LabelWrapper'}>
                    <Text as={'p'} variant={'bodyMd'}>{'Select action type'}</Text>
                  </Box>
                  <Popover
                    fullWidth={true}
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                    preferredAlignment="left"
                  >
                    <ActionList
                      actionRole="menuitem"
                      items={actionItems}
                    />
                  </Popover>
                </Box>
                <TextField
                  label={'Action label'}
                  disabled={!product.storiesConfig?.action}
                  value={product.storiesConfig?.action?.label}
                  placeholder={'Action label'}
                  onChange={onChangeActionLabel}
                  onClearButtonClick={onClearActionLabel}
                  error={product.storiesConfig?.action?.label?.length === 0 && productError.actionLabel}
                  autoComplete={'off'}
                />
              </Stack>
            </Stack>
            {product.storiesConfig?.action?.type === ACTION_TYPES.GO_TO_PAGE && (
              <TextField
                label={'Destination page*'}
                value={product.storiesConfig?.action?.destinationPage}
                placeholder={'url such as: https://myshop.com/products/my-product'}
                onChange={onChangeDestinationPage}
                error={!product.storiesConfig?.action?.destinationPage && productError.destinationPage}
                onClearButtonClick={onClearDestinationPage}
                autoComplete={'off'}
                helpText='Set the destination page'
              />
            )}
            {product.storiesConfig.action && (
              <Stack vertical spacing={'extraTight'}>
                <Text as={'p'} variant={'bodyMd'}>{'Action button color'}</Text>
                <TwitterPicker
                  color={product.storiesConfig.action?.color}
                  onChange={({ hex }) => onChangeColor(hex)}
                  triangle='hide'
                  onChangeComplete={(color) => onChangeActionButtonColor(color.hex)}
                  colors={COLOR_PICKER_DEFAULTS}
                />
              </Stack>
            )}
          </Stack>
        </Card.Section>
      )}
    </>
  )
}

export default StoryProductSettings;
