import { useEffect, useState, useCallback } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

export default function useShopCalls(shopId, statusOptions, rowsCount, filter) {
	const [calls, setCalls] = useState([]);
	const [loading, setLoading] = useState(false)
	const [getNextCalls, setNextCalls] = useState(null);
	const {hostId, eventId, range, search, insightsFlag} = filter;

	const getQuery = (cursor) => {
		let q = Firebase
			.firestore()
			.collection('calls')
			.where('shopId', '==', shopId)

		q = q.where('status', 'in', statusOptions)
		if (insightsFlag) {
			q = q
				.where('insightsFlag', '==', true)
		}

		if (search) {
			q = q
				.where('customerEmail', '==', search)
		} else {
			if (range) {
				const start = range.start.set({hour: 0, minutes: 0, seconds: 0}).toDate()
				const end = range.end.set({hour: 23, minutes: 59, seconds: 59}).toDate()
				q = q
					.where('timestamp', '<=', end)
					.where('timestamp', '>=', start)
			}
			if (hostId) {
				q = q.where('host.id', '==', hostId);
			}
			if (eventId) {
				q = q.where('eventType.eventTypeId', '==', eventId);
			}
			q = q.orderBy('timestamp', 'desc');
		}

		if (cursor) {
			q = q.startAt(cursor);
		}
		return q;
	}

	const getCalls = async (shopId, statusOptions, limit, cursor = null) => {
		setLoading(true)
		const q = getQuery(cursor);
		const querySnap = await q.limit(limit + 1).get();
		let docs = querySnap.docs;
		let next = null;
		if (querySnap.size > limit) {
			const nextCursor = docs.pop();
			next = () => () => getCalls(shopId, statusOptions, limit, nextCursor);
		}
		return { docs, next };
	}

	useEffect(() => {
		getCalls(shopId, statusOptions, rowsCount * 2, null).then(({ docs, next }) => {
			setCalls([...calls, ...docs]);
			setNextCalls(next);
			setLoading(false)
		});
	}, [])

	const refresh = () => {
		getCalls(shopId, statusOptions, rowsCount * 2, null).then(({ docs, next }) => {
			setCalls([...docs]);
			setNextCalls(next);
			setLoading(false)
		});
	};

	const getMoreCalls = () => {
		if (getNextCalls) {
			getNextCalls().then(({ docs, next }) => {
				setCalls([...calls, ...docs]);
				setNextCalls(next);
			});
		}
	}

	const getHistoryCalls = async (limit, cursor = null) => {
		const query = getQuery(cursor);
		const querySnap = await query.limit(limit + 1).get();
		const docs = querySnap.docs;
		let next = null;
		if (querySnap.size > limit) {
			const nextCursor = docs.pop();
			next = () => getHistoryCalls(limit, nextCursor);
		}
		return { docs, next };
	}

	return {
		calls,
		getMoreCalls,
		refresh,
		getHistoryCalls,
		loading
	};
}

export function useCallData(callId) {

	const ref = callId ? Firebase.firestore().collection('calls').doc(callId) : null;
	const [ callData, callDataLoading, callDataError] = useDocumentDataOnce(ref);
	return {
		callData, callDataLoading, callDataError,
	}

}
