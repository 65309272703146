import React from 'react';

import { Layout, Card, Stack, TextField, SkeletonBodyText, List } from '@shopify/polaris';

export default function SegmentEventTracking({ loading, segmentWriteKey, setWriteKey, editMode, setEditMode, onReset, errorMsg }) {

    const labelAction = !editMode
        ? { content: 'Change', onAction: () => setEditMode(true) }        
        : segmentWriteKey
            ? { content: 'Cancel', onAction: onReset }
            : null;

    return (

        <Layout.AnnotatedSection
            title="Segment Analytics"
            description='Set Boutiq as a Segment source and report client actions to Segment'
        >
            <Card>
                <Card.Section>
                    {loading && <SkeletonBodyText />}
                    {!loading && <Stack vertical>
                        <TextField
                            id='segment'
                            label='Segment Source API Write Key'
                            placeholder='XXXXXX'
                            value={segmentWriteKey}
                            onChange={setWriteKey}
                            labelAction={labelAction}
                            disabled={!editMode}
                            error={errorMsg}
                        />
                        <Stack vertical spacing='tight'>
                            <p> The following client actions will be reported to Segment:</p>
                            <List type='bullet'>                                                        
                                <List.Item>Video call scheduled</List.Item>
                                <List.Item>Video call completed</List.Item>
                                <List.Item>Video call missed</List.Item>
                            </List>
                        </Stack>
                    </Stack>
                    }
                </Card.Section>

            </Card>
        </Layout.AnnotatedSection>
    );
}

