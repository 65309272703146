import React, { useEffect, useState } from 'react';
import { Card, DataTable, SkeletonDisplayText, Text, Box, Thumbnail, Stack } from '@shopify/polaris';
import { useShopProvider } from '../../../../../../components/ShopProvider';
import { topTenStories } from '../../../../../../cubeManager/queries/stories';
import { useCubeProvider } from '../../../../../../cubeManager';
import { useCubeQuery } from '@cubejs-client/react';
import {
  CameraMajor
} from '@shopify/polaris-icons';
import '../../styles.css'

const StoriesAnalyticsTable = ({startDate, endDate}) => {
  const [analyticData, setAnalyticData] = useState([])

  const {cubeOptions, onCubeError} = useCubeProvider()
  const { shopData, shopVideos, shopVideosLoading } = useShopProvider();

  const {resultSet, error, isLoading} = useCubeQuery(topTenStories(shopData.ianaTimezone, startDate, endDate), cubeOptions);

  useEffect(() => {
    if (resultSet?.loadResponse?.results && !shopVideosLoading) {
      const data = resultSet.loadResponse.results[0].data?.map((item) => ({
        videoPreview: shopVideos?.find(({id}) => id === item['StoryEvents.videoId'])?.data?.videoPosterUrl,
        storyId: item['StoryEvents.storyId'],
        storyName: item['StoryEvents.storyName'],
        clicks: item['StoryEvents.clicks'],
        views: item['StoryEvents.views'],
      }))
      setAnalyticData(data)
    } else {
      setAnalyticData([])
    }
  }, [resultSet, shopVideosLoading])

  useEffect(() => {
    onCubeError(error)
  }, [error])

  const rows = analyticData.map(data => ([
    <Thumbnail source={data.videoPreview ?? CameraMajor} alt="empty image" />,
    <Stack vertical spacing={'extraTight'}>
      <Text fontWeight={'semibold'} variant={'bodyMd'}>{data.storyName}</Text>
    </Stack>,
    <Stack vertical spacing={'extraTight'}>
      <Text variant={'bodySm'}>views: {data.views}</Text>
      <Text variant={'bodySm'}>clicks: {data.clicks}</Text>
    </Stack>,
  ]));

  const footerContent = () => (
    isLoading || shopVideosLoading ? (
      <Box style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gridTemplateRows: 'auto auto',
        gridRowGap: 12
      }}>
        {Array(6).fill(0).map((_, key) => (
          <SkeletonDisplayText key={key}/>
        ))}
      </Box>
    ) : (
      <Text>{'No stories events found'}</Text>
    )
  )

  return (
    <Card title={'Top 10 videos'}>
      <Box className={'stories-analytics-wrapper'}>
        <DataTable
          hoverable={true}
          hasZebraStripingOnData={true}
          defaultSortDirection='descending'
          initialSortColumnIndex={4}
          columnContentTypes={['text', 'text', 'text']}
          headings={['Video', 'Title', 'Engagement']}
          rows={rows}
          footerContent={footerContent()}
        />
      </Box>
    </Card>
  )
}

export default StoriesAnalyticsTable;
