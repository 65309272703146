import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export default function useCallTranscripts(callId, shopId) {

  const ref = callId && shopId
    ? Firebase.firestore().collection('callTranscripts')
      .where('shopId', '==', shopId)
      .where('callId', '==', callId)
      .where('recordingRule.source', '==', 'user')
    : null;
  const [transcripts, transcriptsLoading, transcriptsError] = useCollectionData(ref);
  return { transcripts, transcriptsLoading, transcriptsError }

}

