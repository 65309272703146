import { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function useHostSchedulingConfig(shopId) {

  const configCollection = firebase.firestore().collection(`shops/${shopId}/hostAvailabilityConfig`);

  const [hostSchedulingConfig, hostSchedulingConfigLoading, error] = useCollection(configCollection);
  error && console.error('useHostSchedulingConfig', error);

  const getHostConfig = useCallback((hostId) => {
    let hostDoc = hostSchedulingConfig && hostSchedulingConfig.docs.find(doc => doc.id === hostId);
    return hostDoc ? hostDoc.data() : null;
  }, [hostSchedulingConfig])

  const setDefaultBusinessHours = useCallback(async (hostId, defaultBusinessHours)=>{
      await configCollection.doc(hostId).set({ defaultBusinessHours }, { merge: true});
  },[hostSchedulingConfig])

  const removeHours = useCallback(async (hostId)=>{
    await configCollection.doc(hostId).delete();
},[hostSchedulingConfig])

  return {
    hostSchedulingConfig,
    hostSchedulingConfigLoading,
    getHostConfig,
    setDefaultBusinessHours,
    removeHours,
  };
}
