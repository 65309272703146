
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { UserRole } from '../utils/permissions';

export default function useShopProductsConfig(shopId, role) {

    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('products') : null;
    const [productsConfig, productsConfigLoading, productsConfigError] = useDocumentData(ref);
    return { productsConfig, productsConfigLoading, productsConfigError }
}
