import {
  BOTTOM_LEFT_POSITION,
  BOTTOM_RIGHT_POSITION,
  DEFAULT_STORIES_WIDGET_SIZE,
  EXPAND_STORIES_WIDGET_SIZE,
  TOP_LEFT_POSITION,
  TOP_RIGHT_POSITION
} from '../pages/Stories/pages/StoryProductConfig/consts';
import { useEffect, useMemo } from 'react';

const collapsedFilter = (products, position, currentTime) => (
  currentTime === null
    ? products.filter(({storiesConfig}) => storiesConfig.position === position)
    : filter(
      products.filter(({storiesConfig}) => storiesConfig.position === position),
      currentTime
    )
)

const expandedFilter = (products, currentTime) => (
  currentTime === null
    ? products.filter(({storiesConfig}) => storiesConfig.size === EXPAND_STORIES_WIDGET_SIZE)
    : filter(
      products.filter(({storiesConfig}) => storiesConfig.size === EXPAND_STORIES_WIDGET_SIZE),
      currentTime
    )
)

const filter = (products, currentTime) => (
  products
    .filter(({storiesConfig}) => parseInt(storiesConfig.showFrom) === 0 || parseInt(storiesConfig.showFrom) < Math.round(currentTime))
    .filter(({storiesConfig}) => {
      const showFrom = parseInt(storiesConfig.showFrom)
      const showUntil = parseInt(storiesConfig.showUntil ?? 0)
      return showUntil === 0 || Math.round(currentTime) >= showFrom && Math.round(currentTime) <= showUntil
    })
)

const useStoriesProductStyle = (size, products, product, currentTime) => {
  const topLeft = useMemo(() => (
    collapsedFilter(products, TOP_LEFT_POSITION, currentTime)
  ), [products, currentTime])
  const topRight = useMemo(() => (
    collapsedFilter(products, TOP_RIGHT_POSITION, currentTime)
  ), [products, currentTime])
  const bottomLeft = useMemo(() => (
    collapsedFilter(products, BOTTOM_LEFT_POSITION, currentTime)
  ), [products, currentTime])
  const bottomRight = useMemo(() => (
    collapsedFilter(products, BOTTOM_RIGHT_POSITION, currentTime)
  ), [products, currentTime])

  const expanded = useMemo(() => (
    expandedFilter(products, currentTime)
  ), [products, currentTime])

  const {position} = product.storiesConfig;

  const top = useMemo(() => {
    if (size === DEFAULT_STORIES_WIDGET_SIZE) {
      if (position === BOTTOM_RIGHT_POSITION || position === BOTTOM_LEFT_POSITION) {
        return 'auto'
      } else {
        if (position === TOP_LEFT_POSITION) {
          const index = topLeft.findIndex(({productId}) => product.productId === productId)
          return 35 + 15 * index + 92 * index
        }
        if (position === TOP_RIGHT_POSITION) {
          const index = topRight.findIndex(({productId}) => product.productId === productId)
          return 35 + 15 * index + 92 * index
        }
      }
    } else {
      if (expanded.findIndex(({productId}) => product.productId === productId) === -1) {
        return -1
      }
      const index = Math.max(topLeft.length, topRight.length) + expanded.findIndex(({productId}) => product.productId === productId)
      return 35 + 15 * index + 92 * index
    }
  }, [size, position, JSON.stringify(topLeft), JSON.stringify(topRight), JSON.stringify(expanded)])

  const bottom = useMemo(() => {
    if (size === DEFAULT_STORIES_WIDGET_SIZE) {
      if (position === BOTTOM_RIGHT_POSITION) {
        const index = bottomRight.findIndex(({productId}) => product.productId === productId)
        return 65 + 15 * index + 92 * index
      }
      if (position === BOTTOM_LEFT_POSITION) {
        const index = bottomLeft.findIndex(({productId}) => product.productId === productId)
        return 65 + 15 * index + 92 * index
      }
    } else {
      return 'auto'
    }
  }, [size, position, JSON.stringify(bottomLeft), JSON.stringify(bottomRight)])

  const left = useMemo(() => {
    if (size === DEFAULT_STORIES_WIDGET_SIZE) {
      if (position === TOP_LEFT_POSITION || position === BOTTOM_LEFT_POSITION) {
        return 25
      }
      if (position === TOP_RIGHT_POSITION || position === BOTTOM_RIGHT_POSITION) {
        return 'auto'
      }
    }
    if (size === EXPAND_STORIES_WIDGET_SIZE) {
      return 25
    }
  }, [size, position])

  const right = useMemo(() => {
    if (size === DEFAULT_STORIES_WIDGET_SIZE) {
      if (position === TOP_LEFT_POSITION || position === BOTTOM_LEFT_POSITION) {
        return 'auto'
      }
      if (position === TOP_RIGHT_POSITION || position === BOTTOM_RIGHT_POSITION) {
        return 25
      }
    }
    if (size === EXPAND_STORIES_WIDGET_SIZE) {
      return 25
    }
  }, [size, position])

  return ({
    top,
    bottom,
    left,
    right
  })
}

export default useStoriesProductStyle;
