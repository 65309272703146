import React from 'react';

import { Layout, Card, Stack, TextField, SkeletonBodyText, List, Link, Text, Checkbox, Icon, Banner } from '@shopify/polaris';
import {
    TickMinor
  } from '@shopify/polaris-icons';

export default function EndearConfig({ 
    token,
    customFields, // this field, if it exists also means the Endear initial connection was successful
    setToken,
    errorMsg,
    loading,
    editMode,
    setEditMode,
    onReset,
    saving,
    options,
    onOptionsChange,
    boutiqAI,
    boutiqBotUser
}) {

    const labelAction = !editMode
        ? { content: 'Change', onAction: () => setEditMode(true) }        
        : token
            ? { content: 'Cancel', onAction: onReset }
            : null;

    return (

        <Layout.AnnotatedSection
            title="Endear"
            description='Fetch custom client data from your Endear instance to display in Boutiq client profiles'
        >
            <Card>
                <Card.Section>
                    {loading && <SkeletonBodyText />}
                    {!loading && <Stack vertical>
                        <TextField
                            id='endear'
                            label='Endear API key'
                            type='password'
                            placeholder='endr...'
                            value={token}
                            onChange={setToken}
                            labelAction={labelAction}
                            disabled={!editMode}
                            error={errorMsg}
                            helpText='paste your Endear API Key here'
                        />
                        {saving && <SkeletonBodyText/>}
                        { !saving && customFields && <Stack vertical spacing='tight'>
                            <p> The following client custom fields are now available:</p>
                            <List type='bullet'>                                                        
                            {customFields.map(field=>(
                                <List.Item key={field.id}>{field.label}: <Text as='span' color='subdued'>{field.description}</Text></List.Item>
                            ))}
                            </List>
                            <p> You can select custom fields for display by managing the <Link url='/hostSettings/clientProfile'>client profile settings</Link></p>
                        </Stack>}
                    </Stack>
                    }
                </Card.Section>
                {!loading && <Card.Section>
                    <Stack vertical spacing='tight'>
                        <Text>Endear options</Text>                        
                        <Checkbox
                            label='Assign last host to Endear customer'
                            helpText='Requires each Boutiq host to have an Endear user'
                            disabled={!token || saving || errorMsg}
                            checked={!!options?.assignHosts}
                            onChange={(newChecked) => onOptionsChange({ ...options, assignHosts: newChecked })}
                        />
                        <Checkbox
                            label={<>Insert Boutiq<sup>AI</sup> call summaries to Endear customer note</>}
                            disabled={!token || saving || !boutiqAI || errorMsg}
                            checked={boutiqAI ? !!options?.insertSummaries : false}
                            onChange={(newChecked) => onOptionsChange({ ...options, insertSummaries: newChecked })}
                        />
                        {(options?.assignHosts || options?.insertSummaries) && !boutiqBotUser?.id && !errorMsg && customFields &&
                            <Banner status='warning'>
                                <Text>Please invite the user with email <Text as='span' fontWeight='semibold'>boutiqbot@getboutiq.com</Text> to your Endear instance as an Admin</Text>
                            </Banner>}
                        {boutiqBotUser?.id &&
                            <Stack wrap={false} spacing='extraTight'>
                                <Icon source={TickMinor} color='success'></Icon>
                                <Text color='subdued'>boutiqbot@getboutiq.com added to your Endear instance</Text>
                            </Stack>
                        }
                    </Stack>
                </Card.Section>}
            </Card>
        </Layout.AnnotatedSection>
    );
}
