import {useEffect, useState} from "react";
import {
  getFirestore,
  collection,
  getDoc,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where
} from 'firebase/firestore';

const useRecordsRules = (shopId) => {
  const [loading, setLoading] = useState(false)
  const [rules, setRules] = useState([])
  
  const db = getFirestore();
  const rulesCollection = collection(db, `shops/${shopId}/recordingRules`)
  
  useEffect(() => {
    if (shopId) {
      setLoading(true)
      const q = query(rulesCollection, where('source', '==', 'user'));
      const unsub = onSnapshot(q, (snapshot) => {
          setRules(snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })));
          setLoading(false);
        },
        () => {
          setRules([])
          setLoading(false)
        },
        () => {
          setLoading(false)
        })
      return () => unsub()
    } else {
      setRules([])
    }
  }, [shopId])
  
  const onAddRule = async (rule) => {
    try {
      setLoading(true)
      await addDoc(rulesCollection, {
        ...rule,
        updatedAt: serverTimestamp()
      })
    } catch (e) {
      console.error('onAddRule ', e)
    } finally {
      setLoading(false)
    }
  }
  
  const onEditRule = async (id, rule) => {
    try {
      setLoading(true)
      const ruleRef = doc(db, `shops/${shopId}/recordingRules`, id);
      await updateDoc(ruleRef, {
        ...rule,
        updatedAt: serverTimestamp()
      })
    } catch (e) {
      console.error('onEditRule ', e)
    } finally {
      setLoading(false)
    }
  }
  
  const onRemoveRule = async (id) => {
    try {
      setLoading(true)
      await deleteDoc(doc(db, `shops/${shopId}/recordingRules`, id))
    } catch (e) {
      console.error('onRemoveRule ', e)
    } finally {
      setLoading(false)
    }
  }
  
  return ({
    rules,
    loading,
    onAddRule,
    onEditRule,
    onRemoveRule
  })
}

export default useRecordsRules;