import React, {useEffect, useState} from 'react';
import {
  Card,
  Stack,
  TextField,
  Spinner,
  SkeletonBodyText,
  Checkbox,
} from '@shopify/polaris';
import {useShopProvider} from "../../../../components/ShopProvider";
import {Locales} from "../../../../utils/locales";

const DEFAULT_REC_CONSENT_STRING = {
  en: "I agree the audio of this call may be recorded for quality and training purposes",
  fr: "J'accepte que l'audio de cet appel soit enregistré à des fins de qualité et de formation",
  it: "Accetto che l'audio di questa chiamata possa essere registrato per scopi di qualità e formazione",
  de: "Ich bin damit einverstanden, dass der Ton des Anrufs aus Qualitätsgründen aufgezeichnet wird",
  es: "Acepto que el audio de esta llamada se pueda grabar con fines de calidad y capacitación",
}

const RecordingConsentCard = ({ isLoading, recConsentMessage, isConsentMandatory, onRecConsentMessageChange, onConsentMandatoryChange  }) => {

  const {shopLocales, shopLocalesLoading} = useShopProvider();
  
  const isCardLoading = shopLocalesLoading || isLoading;
  const defaultLocale = shopLocales[0];
  
  const renderRecConsentMessageForm = () => {
    return shopLocales.length > 1
      ? shopLocales.map(locale => (
        <TextField
          key={locale}
          label={Locales[locale].label}
          value={recConsentMessage[locale]}
          onChange={(val) => onRecConsentMessageChange(val, locale)}
          type='text'
          maxLength={96}
          multiline={2}
          showCharacterCount
          placeholder={DEFAULT_REC_CONSENT_STRING[locale]}
        />
      ))
      : <TextField
        value={recConsentMessage[defaultLocale]}
        onChange={(val) => onRecConsentMessageChange(val, defaultLocale)}
        type='text'
        maxLength={96}
        multiline={2}
        showCharacterCount
        placeholder={DEFAULT_REC_CONSENT_STRING[defaultLocale]}
      />
  }
  
  return (
      <Card title={<Stack>{isCardLoading && <Spinner size='small'/>}</Stack>}>
        <Card.Section title='Recording consent message'>
          <Stack vertical>            
            {isCardLoading && <SkeletonBodyText/>}
            {!isCardLoading &&
              <Stack vertical spacing='tight'>
                <Checkbox
                  label='Recording consent mandatory'
                  checked={isConsentMandatory}
                  onChange={onConsentMandatoryChange}
                  helpText='If checked, clients cannot start a call w/o consenting for audio recording'
                />
                {renderRecConsentMessageForm()}
              </Stack>
            }
          </Stack>
        </Card.Section>
      </Card>
  );
}

export default RecordingConsentCard
