import React, { useEffect, useState } from "react";
import './styles.css';
import VideoState from '../../../VideoState';
import { ClockMinor } from '@shopify/polaris-icons';

export const VideoMetadata = ({ src, setVideoDuration }) => {
    const [duration, setDuration] = useState(null)

    useEffect(() => {
        setVideoDuration(duration)
    }, [duration])

    useEffect(() => {
        if (src) {
            const video = document.createElement('video');
            video.addEventListener('loadedmetadata', () => {
                setDuration(Number((video.duration).toFixed(0)));
            });
            video.preload = 'metadata';
            video.src = src;

            return () => {
                video.removeEventListener('loadedmetadata', () => { });
            }
        }
    }, [src])

    return duration && <VideoState data={duration} icon={ClockMinor} />
}
