import React, {useEffect, useState} from "react";
import {useIntroPage} from "../IntroPageProvider";
import StoryProductsPreview from '../../../../pages/Stories/components/StoryProductsPreview';

const StoryProductsMockup = ({videoElement}) => {
    const [products, setProducts] = useState([])
    const {playList, playingVideoIndex} = useIntroPage()

    useEffect(() => {
        if (playList && playList[playingVideoIndex]) {
            const config = playList[playingVideoIndex].storiesConfig
            setProducts(config?.products)
        }
        return () => {
            setProducts([])
        }
    }, [playingVideoIndex, playList])

    return (
        <StoryProductsPreview products={products} videoElement={videoElement} />
    )
}

export default StoryProductsMockup;
