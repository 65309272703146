import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

import { useAppAuthState } from '../authState/index';

export default function useScreenViews(shopId) {
    let location = useLocation();
    let { authLoading, user } = useAppAuthState();

    useEffect(() => {
        if (shopId)
            firebase.analytics().setUserProperties({ shop_id: shopId || null  });
    }, [shopId]);

    useEffect(() => {
        firebase.analytics().setUserProperties({ role: !!user ? 'admin' : null });
    }, [!!user])

    useEffect(() => {
        if (!authLoading) {
            firebase.analytics().logEvent('screen_view', { firebase_screen: '/admin' + location.pathname, firebase_screen_class: 'Admin' });
        }
    }, [location, authLoading]);
}
