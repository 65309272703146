import React, {useEffect, useMemo, useState} from 'react';
import './styles.scss';
import useTimezones from "../../../../../../hooks/useTimezones";
import {localTimezoneGMTOffset} from "../../../../utils";
import {Spinner, Popover} from "@shopify/polaris";
import {useCreateNewAppointmentProvider} from "../../../../../../components/CreateNewAppointmentProvider";
import {localTimezone, timezoneGMT} from '../../../../../../utils/time';

export const TimezoneSelector = ({activator, timezone}) => {
  const { onSetTimezone } = useCreateNewAppointmentProvider();
  const [timezoneSettingVisible, setTimezoneSettingVisible] = useState(false);
  const {firebaseTimezones, loadTimezones} = useTimezones()

  const timezones = useMemo(() => (
    (firebaseTimezones?.length ? [{
      id: localTimezone,
      offset: localTimezoneGMTOffset(),
    }, ...firebaseTimezones] : [])
      .sort((a, b) => a.offset - b.offset)
  ), [firebaseTimezones])

  useEffect(() => {
    if (timezoneSettingVisible) {
      const selectedTimezoneIndex = timezones.findIndex(({id}) => id === timezone.id)
      let element = document.getElementById(`timezone ${selectedTimezoneIndex}`)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }, [timezoneSettingVisible, loadTimezones])

  const changeListVisibility = () => setTimezoneSettingVisible(current => !current)

  const timezoneListItem = (item, index) => {
    const {id} = item;
    const time = new Intl.DateTimeFormat([], {timeZone: id, timeStyle: 'short'}).format(new Date())

    return (
      <div
        id={`timezone ${index}`}
        key={`timezone ${index}`}
        onClick={() => {
          onSetTimezone(item)
          changeListVisibility()
        }}
        className={`locale-item${id === timezone?.id ? ' selected' : ''}`}
      >
        <span>{id}</span>
        <span className='timezone-time'>{`${time}(${timezoneGMT(id)})`}</span>
      </div>
    )
  }

  return (
    <div className='timezone-selector'>
      <Popover
        active={timezoneSettingVisible}
        activator={activator(changeListVisibility)}
        onClose={changeListVisibility}
        fixed={true}
      >
        {loadTimezones ? (
          <div style={{alignSelf: 'center'}}>
            <Spinner size={'small'}/>
          </div>
        ) : (
          timezones.map(timezoneListItem)
        )}
      </Popover>
    </div>
  )
}