import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ProductList from '../../../../../../components/products/ProductList';
import useProductSearch from '../../../../../../hooks/useProductSearch';
import { parseIdString } from '../../../../../../utils/products';
import {
  Popover,
  TextField,
  Stack,
  EmptyState,
  Text,
  Button,
  Box, SkeletonThumbnail, SkeletonDisplayText, SkeletonBodyText
} from "@shopify/polaris";
import { CancelMajor } from '@shopify/polaris-icons';
import '../styles.css'
import { CloseIcon } from '../../../../../../components/mockup/PreviewStoriesMockup/icons/close';

const StoryProductSearch = ({onUpdateProducts, isDisabled, showSearchIcon}, ref) => {
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [pressSearch, setPressSearch] = useState(false)
  const [products, setProducts] = useState([]);
  const {searchProducts} = useProductSearch()
  const [popoverActive, setPopoverActive] = useState(false);

  useImperativeHandle(ref,() => ({
    onChangePopoverActive: setPopoverActive
  }), []);

  const togglePopoverActive = () => {
    setPopoverActive((popoverActive) => !popoverActive);
  }
  const parseProducts = (products) => products.map((product) => ({...product, productId: parseIdString(product.productId)}))

  const searchForItems = async () => {
    if (!searchText) return;
    if (!loading) {
      setPressSearch(true)
      setLoading(true);
      try {
        const { products, cursor } = await searchProducts(searchText, 20);
        setProducts(parseProducts(products))
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      searchForItems()
    }
  }

  const clearState = () => {
    setSearchText('')
    setProducts([])
    setPressSearch(false)
    setPopoverActive(false)
  }


  const onSelectProduct = (product) => {
    onUpdateProducts(product)
    clearState()
  }

  const activator = (
    <div className='story-product-search-container'>
      <div className={`story-search-field-wrapper${popoverActive ? ' active' : ''}`}>
        <div className='story-search-field' onKeyDown={handleKeyPress}>
          <TextField
            type='search'
            placeholder='Search products'
            autoComplete='off'
            value={searchText}
            onChange={setSearchText}
            clearButton={true}
            onClearButtonClick={clearState}
          />
        </div>
      </div>
      {showSearchIcon && (
        <Button fullWidth onClick={togglePopoverActive} disabled={isDisabled} plain>
          Add product
        </Button>
      )}
    </div>
  )

  const productSkeleton = () => (
    <Box padding={'2'} style={{display: 'grid', gridTemplateColumns: '6rem 1fr', margin: '1rem'}}>
      <SkeletonThumbnail size="large" />
      <Stack vertical>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText lines={2} />
      </Stack>
    </Box>
  )

  const productList = () => (
    <ProductList
      onSelectProduct={onSelectProduct}
      products={products}
      loading={loading}
      emptyState={(
        <EmptyState
          image={''}>
          <Text as={'p'} variant={'bodyMd'}>Product not found</Text>
        </EmptyState>
      )}
    />
  )

  return (
    <Popover
      fullWidth
      active={pressSearch}
      activator={activator}
      autofocusTarget='first-node'
      onClose={clearState}
    >
      {loading ? productSkeleton() : productList()}
    </Popover>
  )
}

export default forwardRef(StoryProductSearch);
