export const translations = {
    en: {
        header: "You're Invited!",
        description: `
<p>We'd like to invite you to a virtual personal shopping experience with a human touch.</p>
<p>We are looking forward to seeing you!</p>
    `,
        button: [{
            label: 'Call Now',
            value: 'regular',
        }, {
            label: 'Schedule for Later',
            value: 'scheduling',
        }]
    },
    fr: {
        header: 'Vous êtes invités!',
        description: `
<p>Nous aimerions vous inviter à une expérience de magasinage personnelle virtuelle avec une touche humaine.</p>
<p>Nous avons hâte de te voir!</p>
     `,
        button: [{
            label: 'Appelle maintenant',
            value: 'regular',
        }, {
            label: 'Programmer pour plus tard',
            value: 'scheduling',
        }]
    },
    it: {
        header: 'Voi siete invitati!',
        description: `
<p>Vorremmo invitarti a un'esperienza di shopping personale virtuale con un tocco umano.</p>
<p>Stiamo cercando di vederti!</p>
     `,
        button: [{
            label: 'Chiamare ora',
            value: 'regular',
        }, {
            label: 'Programma per dopo',
            value: 'scheduling',
        }]
    },
    de: {
        header: 'Ihr seid eingeladen!',
        description: `
<p>Wir möchten Sie zu einem virtuellen persönlichen Einkaufserlebnis mit menschlicher Note einladen.</p>
<p>Wir freuen uns Dich zu sehen!</p>
     `,
        button: [{
            label: 'Jetzt anrufen',
            value: 'regular',
        }, {
            label: 'Zeitplan für später',
            value: 'scheduling',
        }]
    },
    es: {
        header: "¡Estás invitado!",
        description: `
<p>Nos gustaría invitarte a una experiencia de virtual shopper personal con un toque humano.</p>
<p>¡Esperamos con ansias saber de ti!</p>
    `,
        button: [{
            label: 'Conectar ahora',
            value: 'regular',
        }, {
            label: 'Programar para luego',
            value: 'scheduling',
        }]
    },
}
