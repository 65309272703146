import React, { useState, useEffect } from 'react';
import { Button, Stack, Checkbox, Select, Card, ButtonGroup, TextField } from '@shopify/polaris'
import { DeleteMinor } from '@shopify/polaris-icons';
import { ReoderTools } from '../partial/customLabel/ReorderTools/ReoderTools';
import { FormLabelField } from '../FormLabelField/FormLabelField';
import { FormTextField } from '../FormTextField/FormTextField';
import { FormSelectField } from '../FormSelectField/FormSelectField';
import { FormChoiceField } from '../FormChoiceField/FormChoiceField';
import { ALLOW_OPTION_TYPES, ALLOW_OTHER_TYPES, OPTIONS } from '../../consts';
import { omit } from '../../../../utils/objects';
import './FieldControl.scss';

export const FieldControl = ({
    index,
    fieldsAmmount,
    fiedlData,
    onFieldChange,
    availableOptions = OPTIONS,
    availableLabel = true,
    onDelete,
    onReorder,
    disabled = false,
    isOption = false,
    allowDeleteBtn,
    allowSetOrder,
    allowSetType,
    allowSubmitBtn,
    allowSetRequired,
    allowAddOption,
    allowChangeRowsCount,
    isEditMode,
    onError = () => { },
    setEditMode = () => { },
}) => {
    const [tempFieldData, setTempFieldData] = useState(fiedlData);
    const [disableTools, setDisableTools] = useState(disabled);

    useEffect(() => {
        setTempFieldData(fiedlData)
    }, [fiedlData])

    useEffect(() => {
        setDisableTools(disabled)
    }, [disabled])

    const handleFieldLabelChange = (value) => {
        const updatedField = { ...tempFieldData, label: value }
        onFieldChange(updatedField)
    }

    const handleSelectChange = (value) => {
        let updatedField = { ...tempFieldData, type: value }
        updatedField = omit('rows', updatedField)
        onFieldChange(updatedField)
    }

    const handleRequiredChange = (value) => {
        const updatedField = { ...tempFieldData, required: value }
        onFieldChange(updatedField)
    }

    const handlePlaceholderChange = (value) => {
        const updatedField = { ...tempFieldData, placeholder: value }
        onFieldChange(updatedField)
    }

    const handleCountOfRowsChange = (value) => {
        let updatedField;
        if (value > 1) {
            updatedField = { ...tempFieldData, rows: value }
        } else {
            updatedField = omit('rows', tempFieldData)
        }
        onFieldChange(updatedField)
    }

    const handleOptionChange = (value, index) => {
        const updatedOptionList = [...tempFieldData.options];
        if (updatedOptionList[index].innerComponent) {
            updatedOptionList[index] = { ...updatedOptionList[index], ...{ innerComponent: value } }
        } else {
            updatedOptionList[index] = { label: value, value: value }
        }
        const updatedField = { ...tempFieldData, options: updatedOptionList }
        onFieldChange(updatedField)
    }

    const handleRemoveOptions = (key) => {
        const updatedOptionsList = [...tempFieldData.options];
        updatedOptionsList.splice(key, 1);
        const updatedField = { ...tempFieldData, options: updatedOptionsList }
        onFieldChange(updatedField)
    }

    const handleAddOption = () => {
        let index = 0;
        let isOptionsHaveOther;
        let currentOptions = tempFieldData.options || []
        if (tempFieldData.options) {
            index = tempFieldData.options.length
            isOptionsHaveOther = tempFieldData.options.some(option => option.innerComponent)
        }
        const currentValueSuff = ++index
        if (isOptionsHaveOther) {
            let updatedList = [];
            if (tempFieldData.options) {
                updatedList = [...currentOptions];
                updatedList.splice(updatedList.length - 1, 0, { label: `Option ${currentValueSuff}`, value: `Option ${currentValueSuff}` });
            }
            const updatedField = { ...tempFieldData, options: updatedList }
            onFieldChange(updatedField)
        } else {
            const updatedField = { ...tempFieldData, options: [...currentOptions, { label: `Option ${currentValueSuff}`, value: `Option ${currentValueSuff}` }] }
            onFieldChange(updatedField)
        }
    }

    const onAddOther = () => {
        const option = {
            value: 'other',
            innerComponent: {
                id: new Date().toISOString(),
                placeholder: '',
                required: false,
                type: 'text'
            }
        }
        let currentOptions = tempFieldData.options || []
        const updatedField = { ...tempFieldData, options: [...currentOptions, option] }
        onFieldChange(updatedField)
    }

    const typeHandler = (type) => {
        switch (type) {
            case 'title': case 'checkbox':
                return null
            case 'text': case 'email': case 'phone':
                return <FormTextField
                    rows={tempFieldData.rows}
                    placeholder={tempFieldData.placeholder || ''}
                    onPlaceholderChange={handlePlaceholderChange}
                />
            case 'select':
                return <FormSelectField
                    fieldId={tempFieldData.id}
                    isEditMode={isEditMode}
                    options={tempFieldData.options}
                    removeOption={handleRemoveOptions}
                    onOptionChange={handleOptionChange}
                    onError={onError}
                    placeholder={tempFieldData.placeholder || ''}
                    handleFieldPlaceholderChange={handlePlaceholderChange}
                />
            case 'choice': case 'multi-choice':
                return <FormChoiceField
                    fieldId={tempFieldData.id}
                    isEditMode={isEditMode}
                    disabled={disableTools}
                    onError={onError}
                    options={tempFieldData.options}
                    removeOption={handleRemoveOptions}
                    onOptionChange={handleOptionChange}
                />
            default:
                return null
        }
    }

    const renderTopSectionTools = () => {
        return <Stack alignment='center'>
            {allowSetType && <div className='common-edit-tools-container'>
                <Select
                    disabled={disableTools}
                    label='Type:'
                    labelInline
                    options={availableOptions}
                    onChange={handleSelectChange}
                    value={tempFieldData.type} />
            </div>}
            {allowSetOrder && <div className='order-tools-container'>
                <ReoderTools
                    maxLength={fieldsAmmount}
                    index={index}
                    onReorder={onReorder} />
            </div>}
        </Stack>
    }

    return (
        <Card sectioned>
            <Stack spacing='loose' vertical>
                <div className={`field-container${isEditMode ? ' edit-mode' : ''}`} onClick={!isEditMode && !isOption ? () => setEditMode(tempFieldData.id) : () => { }}>
                    <Stack spacing='loose' vertical>
                        {availableLabel ? <>
                            <div style={{ paddingBottom: '1.2rem' }}>
                                <Stack wrap={false}>
                                    <Stack.Item fill>
                                        <FormLabelField
                                            fieldId={tempFieldData.id}
                                            index={'label'}
                                            onError={onError}
                                            inEditMode={isEditMode}
                                            setTempValue={handleFieldLabelChange}
                                            value={tempFieldData.label} />
                                    </Stack.Item>
                                    {renderTopSectionTools()}
                                </Stack>
                            </div>
                            <Stack.Item>
                                {typeHandler(tempFieldData && tempFieldData.type)}
                            </Stack.Item>
                        </> :
                            <Stack alignment='center'>
                                <Stack.Item fill>
                                    {typeHandler(tempFieldData && tempFieldData.type)}
                                </Stack.Item>
                                {renderTopSectionTools()}
                            </Stack>}
                        <Stack wrap={false} distribution='trailing'>
                            {allowAddOption && ALLOW_OPTION_TYPES.includes(tempFieldData.type) &&
                                    <Stack.Item wrap={true} fill>
                                        <Button onClick={() => handleAddOption()} size='slim' plain>Add option</Button>
                                        {ALLOW_OTHER_TYPES.includes(tempFieldData.type) && !(tempFieldData.options && tempFieldData.options.some(option => option.innerComponent)) &&
                                            <Button onClick={() => onAddOther()} size='slim' plain>Add other</Button>}
                                    </Stack.Item>}
                            {tempFieldData.type === 'text' && allowChangeRowsCount &&
                                <Stack.Item fill>
                                    <Stack>
                                    <TextField
                                        label="Text rows"
                                        type="number"
                                        disabled={disableTools}
                                        value={tempFieldData.rows || "1"}
                                        onChange={handleCountOfRowsChange}
                                        autoComplete="off"
                                    />
                                    </Stack>
                                </Stack.Item>
                            }
                            <Stack alignment='baseline'>
                                {allowSetRequired && tempFieldData.type !== 'title' && <Checkbox
                                    label='is required'
                                    disabled={disableTools && isOption}
                                    checked={fiedlData && fiedlData.required}
                                    onChange={(e) => handleRequiredChange(e, index)}
                                />}
                                {allowDeleteBtn && <Button
                                    onClick={() => onDelete(fiedlData.id)}
                                    icon={DeleteMinor}
                                    plain
                                    size='slim'
                                    destructive>
                                </Button>}
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
                {allowSubmitBtn && <Stack distribution='trailing'>
                    <Button
                        disabled={disableTools}
                        primary
                        size='slim'
                        onClick={() => setEditMode(null)}>Done</Button>
                </Stack>}
            </Stack>
        </Card>
    )
}
