import React, { useEffect, useState, useCallback } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { useShopProvider } from '../../components/ShopProvider';
import { Stack, TextStyle, Button, Modal, TextField, Avatar, Text } from '@shopify/polaris';
import DatePicker from 'react-datepicker';
import { CustomSelect } from './components/CustomSelect/CustomSelect';
import moment from 'moment-timezone';
import { omit } from '../../utils/objects';
import useShopCalendarEvent from '../../hooks/useShopCalendarEvent';
import { isStringNonEmpty } from '../../utils/strings';
import { useHistory } from 'react-router-dom';
import Moment from 'moment-timezone';

const defaultStartTime = () => roundToNearest30(moment()).toDate()
const defaultEndTime = () => roundToNearest30(moment()).add(1, 'hour').toDate()

function roundToNearest30(date) {
    const min = date.minute()
    let dateTime = moment().add(min > 30 && 1, 'hours').minutes(min <= 30 ? 30 : 0).set({ seconds: 0, milliseconds: 0 });
    return dateTime
}

export default function CaazamNewBlockOff({
    open,
    onClose,
    data,
    isHostFreeForEvent
}) {
    const history = useHistory();
    const { hosts, shopOrigin } = useShopProvider();
    const { createScheduledCall, rescheduleCall, deleteScheduledCall } = useShopCalendarEvent(shopOrigin);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startTime, setStartTime] = useState(defaultStartTime);
    const [endTime, setEndTime] = useState(defaultEndTime);
    const [selectedHost, setSelectedHost] = useState(null);
    const [hostDetails, setHostDetails] = useState(null);
    const [selectHostOptionsList, setSelectHostOptionsList] = useState([])
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteFlow, setIsDeleteFlow] = useState(false);
    const [dirtyBit, setDirtyBit] = useState(false);

    const handleTitleChange = useCallback((newValue) => setTitle(newValue), []);
    const handleDescriptionChange = useCallback((newValue) => setDescription(newValue), []);
    const handleStartTimeChange = useCallback((newValue) => setStartTime(newValue), []);
    const handleEndTimeChange = useCallback((newValue) => setEndTime(newValue), []);
    const handleHostChange = useCallback((newValue) => setSelectedHost(newValue), []);

    useEffect(() => {
        if (open) {
            if (data) {
                setTitle(data.title || '');
                setDescription(data.description || '');
                setStartTime(data.start || defaultStartTime);
                setEndTime(data.end || defaultEndTime);
                setSelectedHost(data.resourceId || null);
            } else {
                setTitle('');
                setDescription('');
                setStartTime(defaultStartTime);
                setEndTime(defaultEndTime);
                setSelectedHost(null);
            }
        }
    }, [data, open])

    useEffect(() => {
        if (data) {
            let activeHostDetails = hosts.find(host => host.id === data.resourceId);
            if (activeHostDetails) {
                activeHostDetails = {
                    name: `${activeHostDetails.firstName} ${activeHostDetails.lastName}`,
                    avatar: activeHostDetails.avatarUrl,
                    initials: `${activeHostDetails.firstName[0]}${activeHostDetails.lastName[0]}`,
                    email: activeHostDetails.email
                }
            }
            setHostDetails(activeHostDetails)
        } else {
            if (open) {
                let availableHosts = hosts.map(host => (
                    {
                        content: `${host.firstName} ${host.lastName}`,
                        avatar: host.avatarUrl,
                        initials: `${host.firstName[0]}${host.lastName[0]}`,
                        id: host.id,
                        alert: !isHostFreeForEvent(host.id, { startTimestamp: startTime, endTimestamp: endTime }),
                    })).sort((a, b) => a.alert === b.alert ? 0 : a.alert ? 1 : -1);
                setSelectHostOptionsList(availableHosts);
            }
        }

    }, [data, open, startTime, endTime]);


    useEffect(() => {
        if (data && open) {
            let changes = {};
            if (data.start !== startTime) {
                changes = { ...changes, start: true }
            } else {
                changes = omit('start', changes)
            }
            if (data.end !== endTime) {
                changes = { ...changes, end: true }
            } else {
                changes = omit('end', changes)
            }
            if (data.title !== title) {
                changes = { ...changes, title: true }
            } else {
                changes = omit('title', changes)
            }
            if (data.description !== description) {
                changes = { ...changes, description: true }
            } else {
                changes = omit('description', changes)
            }
            setDirtyBit(Object.values(changes).some(change => change))
        }
    }, [title, description, startTime, endTime])


    if (!open) return null;

    const validateForm = () => {
        let currentFormErrors = { ...errors };
        let errorAction = false
        currentFormErrors = omit('onSave', currentFormErrors)

        if (!endTime) {
            currentFormErrors = { ...currentFormErrors, endTime: 'Please update an end time' }
        } else {
            currentFormErrors = omit('endTime', currentFormErrors)
        }
        if (!startTime) {
            currentFormErrors = { ...currentFormErrors, startTime: 'Please update a start time' }
        } else {
            currentFormErrors = omit('startTime', currentFormErrors)
        }
        if (endTime && startTime) {
            if (moment(endTime) > moment(startTime)) {
                currentFormErrors = omit('slot', currentFormErrors)
            } else {
                currentFormErrors = { ...currentFormErrors, slot: 'End time must be after start time' }
            }
            if (moment(endTime).diff(moment(startTime), 'h', true) > 12 && selectedHost) {
                currentFormErrors = {
                    ...currentFormErrors,
                    slot: 'Block off time max duration is 12 hours. If you need to change your availability for longer please ',
                }
            }
        }
        if (selectedHost) {
            currentFormErrors = omit('host', currentFormErrors)
        } else {
            currentFormErrors = { ...currentFormErrors, host: 'Please select a host' }
        }
        if (title && isStringNonEmpty(title)) {
            currentFormErrors = omit('title', currentFormErrors)
        } else {
            currentFormErrors = { ...currentFormErrors, title: 'Please set a title' }
        }
        setErrors(currentFormErrors);

        return Object.values(currentFormErrors).length === 0;
    }

    const updateEvent = async (id, data) => {
        await Firebase
            .firestore()
            .collection('shops')
            .doc(shopOrigin)
            .collection('schedule')
            .doc(id)
            .set({
                ...data
            }, { merge: true });
    }

    const onDelete = async () => {
        if (!isDeleteFlow) {
            setIsDeleteFlow(true);
            return;
        }
        setIsLoading(true);
        try {
            await deleteScheduledCall(data.id);
            setIsLoading(false);
            handleOnClose();
        } catch (error) {
            setIsLoading(false);
            console.error(error)
        }
    }

    const onSubmit = async () => {
        const isForValid = validateForm();
        if (!isForValid) return;
        setIsLoading(true)

        const start = moment(startTime).set({ second: 0, millisecond: 0 }).toISOString();
        const end = moment(endTime).set({ second: 0, millisecond: 0 }).toISOString();

        if (data) {
            let errorOnSave;
            if (data.start !== startTime || data.end !== endTime) {
                try {
                    await rescheduleCall({slot: {start, end}}, data.id)
                } catch (error) {
                    errorOnSave = true;
                    setErrors({ ...errors, onSave: error.reason ?? error.message })
                    console.error(error);
                }
            }
            if (data.title !== title || data.description !== description) {
                try {
                    await updateEvent(data.id, { title, description, updatedAt: Firebase.firestore.FieldValue.serverTimestamp() });
                } catch (error) {
                    errorOnSave = true;
                    setErrors({ ...errors, onSave: error.reason ?? error.message })
                    console.error(error);
                }
            }
            if (!errorOnSave) {
                handleOnClose();
            }
        } else {
            const callData = {
                title,
                description,
                slot: {
                    start,
                    end
                },
                hostId: selectedHost
            }
            try {
                await createScheduledCall(callData);
                handleOnClose();
            } catch (error) {
                console.error(error);
                setErrors({ ...errors, onSave: error.reason ?? error.message })
            }
        }

        setIsLoading(false);
    }

    const onDiscard = () => {
        if (isDeleteFlow) {
            setIsDeleteFlow(false);
        } else {
            handleOnClose();
        }
    }

    const handleOnClose = () => {
        setErrors({});
        setDirtyBit(false)
        setIsDeleteFlow(false);
        onClose();
    }

    const onNavigateToHostAvailability = () => {
        history.push(`/calendar/hosts/${selectedHost}`, {
            eventId: 'no_event_id',
            start: Moment(startTime).startOf('week').toDate(),
            end: Moment(endTime).startOf('week').toDate()
        })
    }

    const errorMessage = () => (
      <Text as={'p'} variant="bodyMd" color='critical'>
          <span>{Object.values(errors)[0]}</span>
          {Object.values(errors)[0]?.includes('12 hours') && (
            <span
              onClick={onNavigateToHostAvailability}
              style={{textDecoration: 'underline', cursor: 'pointer'}}
            >
                {'edit host availability'}
            </span>
          )}
      </Text>
    )

    return (
        <Modal
            open={open}
            onClose={handleOnClose}
            title={(data ? 'Edit' : 'New') + ' block off'}
        >
            <Modal.Section>
                <Stack vertical>
                    <Stack distribution='fillEvenly'>
                        <Stack>
                            <p>Start</p>
                            <DatePicker
                                popperClassName={'custom-popper-datepicler-container'}
                                calendarClassName={'custom-datepicler-container'}
                                name={'startTime'}
                                selected={startTime}
                                onChange={handleStartTimeChange}
                                showTimeSelect
                                timeFormat='h:mm aa'
                                minDate={moment().toDate()}
                                minTime={moment().set({ hour: 0, minute: 0 }).toDate()}
                                maxTime={moment().set({ hour: 23, minute: 59 }).toDate()}
                                timeIntervals={30}
                                autoComplete={'off'}
                                injectTimes={[
                                    moment().set({ hour: 23, minute: 59 }).toDate()
                                ]}
                                dateFormat='d MMM yyyy h:mm aa'
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: '-85px, 0px'
                                    },
                                }}
                            />
                        </Stack>
                        <Stack>
                            <p>End</p>
                            <DatePicker
                                popperClassName={'custom-popper-datepicler-container'}
                                calendarClassName={'custom-datepicler-container'}
                                name={'endTime'}
                                selected={endTime}
                                onChange={handleEndTimeChange}
                                showTimeSelect
                                timeFormat='h:mm aa'
                                minDate={startTime}
                                timeIntervals={30}
                                autoComplete={'off'}
                                injectTimes={[
                                    moment().set({ hour: 23, minute: 59 }).toDate()
                                ]}
                                dateFormat='d MMM yyyy h:mm aa'
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: '-85px, 0px'
                                    },
                                }}
                            />
                        </Stack>
                    </Stack>
                    {data?.resourceId ?
                        <Stack vertical spacing='tight'>
                            {hostDetails ? <Stack>
                                <Avatar size="extraSmall" source={hostDetails.avatar} name={hostDetails.content} initials={hostDetails.initials} />
                                <Stack vertical spacing='none'>
                                    <p>{hostDetails.name}</p>
                                    <p>{hostDetails.email}</p>
                                </Stack>
                            </Stack> : <p>{data?.resourceId}</p>}
                        </Stack> :
                        <Stack fill alignment='center' spacing='tight'>
                            <CustomSelect
                                helperText='Host is busy'
                                availableHosts={selectHostOptionsList}
                                selectedHost={selectedHost}
                                onChangeHost={handleHostChange} />
                        </Stack>
                    }
                </Stack>
            </Modal.Section>
            <Modal.Section>
                <TextField
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    autoComplete="off"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    multiline={4}
                    autoComplete="off"
                    placeholder='(optional)'
                />
            </Modal.Section>
            <Modal.Section>
                {!data &&
                    <Stack>
                        {errors && errorMessage()}
                        <Stack.Item fill>
                            <Stack distribution='trailing'>
                                <Button
                                    onClick={onSubmit}
                                    primary
                                    loading={isLoading}>
                                    Create block off
                                </Button>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                }
                {data &&
                    <>
                        {isDeleteFlow &&
                            <Stack>
                                <p>
                                    <TextStyle variation='strong'>
                                        Are you sure you want to delete this block off event?
                                    </TextStyle>
                                </p>
                                <Stack.Item fill>
                                    <Stack distribution='trailing'>
                                        <Button onClick={onDiscard}>Cancel</Button>
                                        <Button onClick={onDelete}
                                            destructive
                                            loading={isLoading && isDeleteFlow}>
                                            Delete
                                        </Button>
                                    </Stack>
                                </Stack.Item>
                            </Stack>}
                        {!isDeleteFlow &&
                            <Stack>
                                {!dirtyBit && <Button onClick={onDelete}
                                    destructive
                                    outline>
                                    Delete
                                </Button>}
                                {errors && errorMessage()}
                                <Stack.Item fill>
                                    {dirtyBit ? <Stack distribution='trailing'>
                                        <Button onClick={onDiscard}>Cancel</Button>
                                        <Button
                                            onClick={onSubmit}
                                            primary
                                            loading={isLoading}>
                                            Save
                                        </Button>
                                    </Stack> : <Stack distribution='trailing'>
                                        <Button primary onClick={onDiscard}>Ok</Button>
                                    </Stack>}
                                </Stack.Item>
                            </Stack>}
                    </>
                }
            </Modal.Section>
        </Modal>
    )
}
