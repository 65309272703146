import { Button, Stack, EmptyState, Modal, Card } from '@shopify/polaris';
import { DeleteMinor, ExportMinor } from '@shopify/polaris-icons';
import React, { useState, useCallback, useMemo } from 'react';
import { FieldControl } from './components/FieldControl/FieldControl';
import { OPTIONS, OPTION_RULES } from './consts';
import ExportFormDataModal from './components/ExportFormDataModal';

export const ScheduleForm = ({ isLoading, formSchema, updateValue, onDelete, onReorder, onError }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(null);
    const [deleteCandidate, setDeleteCandidate] = useState(null);
    const [isDeleteConfirmActive, setIsDeleteConfirmActive] = useState(false)
    const [showExportDataModal, setShowExportDataModal] = useState(false);
    const handleChange = useCallback(() => setIsDeleteConfirmActive(!isDeleteConfirmActive), [isDeleteConfirmActive]);

    const onAddNewField = () => {
        const id = new Date().getTime();
        const newField = {
            id,
            label: 'Field label',
            required: false,
            type: 'text'
        }
        updateValue(newField, 'schedulingSchema')
        setActiveItemIndex(id);
    }

    const deleteConfirmationDialog = (fieldId) => {
        setDeleteCandidate(fieldId);
        handleChange()
    }

    const availableOptionsHandler = (type, options) => {
        if (!options || options.length === 0) {
            return OPTIONS;
        }
        switch (type) {
            case 'text': case 'phone': case 'email':
                return OPTION_RULES.textType
            case 'select':
                return OPTION_RULES.choiceTypeNoInnerComponent
            case 'choice': case 'multi-choice':
                if (options && options.some(option => option.innerComponent)) {
                    return OPTION_RULES.choiceType
                } else {
                    return OPTION_RULES.choiceTypeNoInnerComponent
                }
            default:
                return OPTIONS
        }
    }

    const removeAll = () => {
        setIsDeleteConfirmActive(true);
        setDeleteCandidate('all')
    }

    return (
        <Card.Section
            title='Custom client scheduling form'
            actions={!isLoading && formSchema && formSchema.length
                ? [
                    {
                        content: 'Export data',
                        icon: ExportMinor,
                        onAction: () => setShowExportDataModal(true),
                    },
                    {
                        content: 'Delete form',
                        icon: DeleteMinor,
                        destructive: true,
                        onAction: removeAll
                    }
                ]
                : []}
        >
            <Stack vertical>
                {isLoading ? <></> :
                    formSchema && formSchema.length > 0
                        ? <Stack vertical>
                            {formSchema.map((field, index) => {
                                const isEditMode = activeItemIndex === field.id;
                                return <FieldControl
                                    key={`${index}-${field.id}`}
                                    index={index}
                                    fieldsAmmount={formSchema.length}
                                    isEditMode={isEditMode}
                                    setEditMode={setActiveItemIndex}
                                    fiedlData={field}
                                    onFieldChange={(e) => updateValue(e, 'schedulingSchema')}
                                    availableOptions={availableOptionsHandler(field.type, field.options)}
                                    availableLable={true}
                                    disabled={!isEditMode}
                                    onDelete={deleteConfirmationDialog}
                                    onReorder={onReorder}
                                    allowDeleteBtn={true}
                                    allowSetOrder={true}
                                    allowSetType={true}
                                    allowSubmitBtn={isEditMode}
                                    allowSetRequired={true}
                                    allowAddOption={isEditMode}
                                    availableLabel={true}
                                    allowChangeRowsCount={true}
                                    onError={onError}
                                />
                            })}
                            <Stack.Item spacing='extraLoose'>
                                <Button onClick={() => onAddNewField()} size='slim' plain primary>Add field</Button>
                            </Stack.Item>
                        </Stack>
                        : <EmptyState
                            action={{
                                content: "Create a new form",
                                onAction: () => onAddNewField()
                            }}
                        >
                            <p>Create a customized form that clients fill as part of the scheduling flow. Hosts will view responses in the scheduled appointments and live video calls</p>
                        </EmptyState>
                }
                <Modal
                    open={isDeleteConfirmActive}
                    onClose={handleChange}
                    title={`Are you sure you want to delete this ${deleteCandidate === 'all' ? 'form' : ' field'}?`}
                    primaryAction={{
                        content: "Delete",
                        destructive: true,
                        onAction: () => { handleChange(); onDelete(deleteCandidate) },
                    }}
                    secondaryActions={[
                        {
                            content: "Cancel",
                            onAction: () => { handleChange(); setDeleteCandidate(null) }
                        },
                    ]}
                >
                </Modal>
                <ExportFormDataModal open={showExportDataModal} onClose={() => setShowExportDataModal(false)} />
            </Stack>
        </Card.Section>
    )
}
