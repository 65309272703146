import React, { useRef, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

import { DisplayText, TextStyle, Stack, Button, Page } from '@shopify/polaris';

import useInstallData from '../../hooks/useInstallData';
import { useAppAuthState } from '../../authState';
import PublicPage from '../../components/PublicPage';

import './landing.css';

function Landing() {

    const history = useHistory();
    const { shopOrigin, isAdminAppUser } = useAppAuthState();
    const { installKey } = useInstallData(shopOrigin);

    const isPostInstall = useRef(!!installKey);

    const onLoginAction = () => {
        history.push('/login');
    }

    const onSignupAction = () => {
        history.push('/signup');
    }

    const mainCTA = isPostInstall.current ? 'Start Now' : 'Log In';
    const mainAction = isPostInstall.current ? onSignupAction : onLoginAction

    useEffect(()=>{
        if (isPostInstall.current) {
            firebase.analytics().logEvent('admin.onboard.landing');
        }
    },[])

    // if there's a logged in admin and its not a post install journey then redirect
    if (isAdminAppUser && !isPostInstall.current) {
        console.log('Landing: there is a logged in admin and its not a post install onboard journey then redirect');
        return <Redirect to='/' />
    }

    return (
        <Page>
            <div className='landing-container'>
                    <div className='landing-row'>
                        <div className='landing-content'>

                                <img src='/boutiq_dark.svg' style={{ height: '42px' }} alt='Boutiq logo'/>
                                <Stack vertical spacing='extraTight'>
                                    <DisplayText>Welcome to Boutiq</DisplayText>
                                    <DisplayText>Bring your online store to LIFE</DisplayText>
                                </Stack>
                                <DisplayText size='small'>
                                    <TextStyle variation='subdued'>
                                        Ready to provide a more personal service, leverage your staff talent, gain higher conversion rates and WIN more business?
                                    </TextStyle>
                                </DisplayText>
                                <div className='landing-cta'>
                                    <Button primary size='large' onClick={mainAction}>{mainCTA}</Button>
                                </div>
                                <p>By clicking <strong>{mainCTA}</strong>, you agree to accept Boutiq’s{' '}
                                    <a href={`${process.env.REACT_APP_WEB_DOMAIN}/terms`} target='_blank' rel="noopener noreferrer" >terms and conditions</a></p>

                        </div>
                        <div className='landing-media'>
                            <img src='/landing_page.png' alt='Host video app'/>
                        </div>
                    </div>
            </div>
        </Page>
    );
}

export default function() {
    return (
        <PublicPage>
            <Landing/>
        </PublicPage>
    )
}
