import React from 'react';
import {Stack, SkeletonDisplayText, SkeletonThumbnail} from "@shopify/polaris";

const PresetSkeleton = () => {
  return (
    <div style={{padding: '1rem'}}>
      <Stack vertical={true} spacing={'baseTight'}>
        <Stack vertical={true} spacing={'baseTight'}>
          <SkeletonDisplayText size="small" />
          <Stack spacing={'baseTight'}>
            <SkeletonThumbnail size="large" />
            <SkeletonThumbnail size="large" />
            <SkeletonThumbnail size="large" />
          </Stack>
        </Stack>
        <Stack vertical={true} spacing={'baseTight'}>
          <SkeletonDisplayText size="small" />
          <Stack spacing={'baseTight'}>
            <SkeletonThumbnail size="large" />
            <SkeletonThumbnail size="large" />
            <SkeletonThumbnail size="large" />
          </Stack>
        </Stack>
      </Stack>
    </div>
  )
}

export default PresetSkeleton;