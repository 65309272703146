import React from "react";
import { Select } from '@shopify/polaris';

export const SELECT_ALL_HOSTS = { label: 'All Hosts', value: 'allHosts' };

export default function SelectHost({ hosts, selecetedHost, onHostChange, label = null }) {

    const selectHostOptionsList = [SELECT_ALL_HOSTS]
        .concat(Object.keys(hosts)
            .map(key => ({ label: `${hosts[key].firstName} ${hosts[key].lastName}`, value: hosts[key].id })));

    return (
        <Select
            label={label || "Host name"}
            options={selectHostOptionsList}
            value={selecetedHost}
            onChange={onHostChange}
        />);
}