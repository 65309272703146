

export const DEFAULT_WIDGET_POSITION = {
    top: 'auto',
    right: '25px',
    bottom: '25px',
    left: 'auto',
}
export const DEFAULT_WIDGET_COLOR = '#6E76F2';
export const DEFAULT_HELPER_TEXT = 'Video call with our team';
export const DEFAULT_WIDGET_SIZE = 'standard';
export const DEFAULT_WIDGET_HOST_AVATARS = null;
export const DEFAULT_WIDGET_LOCATION_TYPE = 'all';
export const DEFAULT_WIDGET_LOCATION_RULES = [];

export const DefaultWidgetConfig = {
    color: DEFAULT_WIDGET_COLOR,
    helperText: DEFAULT_HELPER_TEXT,
    position: DEFAULT_WIDGET_POSITION,
    size: DEFAULT_WIDGET_SIZE,
    hostAvatars: DEFAULT_WIDGET_HOST_AVATARS,
    locationRuleType: DEFAULT_WIDGET_LOCATION_TYPE,
    customLocationRules: DEFAULT_WIDGET_LOCATION_RULES,
}
