import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { Layout, Card, Stack, TextField, SkeletonBodyText, Checkbox, TextContainer } from '@shopify/polaris';

import { useShopProvider } from '../../../components/ShopProvider';
import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';

import { isEmailValid } from '../../../utils/strings';
import useShopNotifications from '../../../hooks/useShopNotifications';



function HostNotifications() {

    const history = useHistory();
    const { shopOrigin, shopData, shopDataLoading } = useShopProvider();
    const { shopNotifications, shopNotificationsLoading, setContactEmail, setDisableHostContact } = useShopNotifications(shopOrigin);
    const [email, setEmail] = useState(null);
    const [disableSendContactFormToHosts, setDisableSendContactFormToHosts] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(shopDataLoading || shopNotificationsLoading);
    }, [shopDataLoading, shopNotificationsLoading]);

    useEffect(() => {
        if (!shopNotificationsLoading && shopNotifications) {
            setEmail(shopNotifications.clientContactEmail);
            setDisableSendContactFormToHosts(shopNotifications.disableContactEmailToHost ?? false);
        }

    }, [shopNotifications, shopNotificationsLoading]);

    const setConatctEmail = (value) => {
        const newEmail = value ? value.toLowerCase() : value;
        setEmail(newEmail);
        setEmailError(null);
    }

    const saveContactEmail = async () => {
        if (email && !isEmailValid(email)) {
            setEmailError('email is not valid');
            return false;
        }
        await setContactEmail(email);
        setEmailError(null);
        return true;
    }

    const onDisableSendContactFormToHosts = async (checked) => {
        setDisableSendContactFormToHosts(!checked);
        setDisableHostContact(!checked);
    }

    return (
        <AppPage
            title='Client contact email'
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
        >
            <Layout.AnnotatedSection
                title="Store contact email"
                description='Manage handling of client messages sent through Boutiq'
            >
                <Card sectioned>
                    <Card.Section>
                        <TextContainer spacing='tight'>
                            <p>Clients send contact emails from the Boutiq "Contact us" form or when they encounter a problem.</p>
                            <p>The client contact email is also used as the "reply-to" address to all client emails sent by Boutiq on behalf of the store.</p>
                        </TextContainer>
                    </Card.Section>
                    <Card.Section>
                        {isLoading
                            ? <SkeletonBodyText />
                            : <Stack vertical spacing='loose'>
                                <TextField
                                    label="Client contact email"
                                    type='email'
                                    value={email}
                                    onChange={setConatctEmail}
                                    onBlur={saveContactEmail}
                                    placeholder={shopData.contactEmail}
                                    error={emailError && emailError}
                                    helpText="Defaults to the store's client contact email"
                                />
                                <Checkbox
                                    label="CC hosts on client contact emails"
                                    checked={!disableSendContactFormToHosts}
                                    onChange={onDisableSendContactFormToHosts}
                                    helpText="In case clients are contacting a specific host, should the host be CC'd to the client contact email"
                                />
                            </Stack>
                        }
                    </Card.Section>
                </Card>
                {/* <div style={{ marginTop: '4px' }}>
                        <InlineError message={formError && formError.message} />
                    </div> */}
            </Layout.AnnotatedSection>

        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <HostNotifications />
        </AdminPage>
    )
}
