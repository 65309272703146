import React, { useState} from 'react';
import { Stack, Button, TextField, Subheading, SkeletonBodyText, ChoiceList, Checkbox, TextStyle, Card, RadioButton } from '@shopify/polaris';
import UrlRuleBlock from '../urlRuleBlock';

export const LocationRuleTypes = {
    all: 'all',
    products: 'products',
    custom: 'custom',
}

export const URL_RULE_OPTIONS = [{
    label: 'contains',
    value: 'contains',
  }, /* {
    label: "doesn't contain",
    value: 'does_not_contain',
  } */];

export default function WidgetLocationRules({ rules, onRulesChange }) {

    const [newRule, setNewRule] = useState({ rule: URL_RULE_OPTIONS[0].value, url: null});
    const [newRuleError, setNewRuleError] = useState(null);

    function deleteRule(index) {
        let newRules = [...rules];
        newRules.splice(index,1);
        onRulesChange(newRules);
    }

    function addRule() {
        if (newRule?.url == null || newRule?.url === '' ) {
            setNewRuleError('url cannot be empty')
            return;
        }
        setNewRuleError(null);
        let newRules = [...rules, {...newRule}];
        onRulesChange(newRules);
        setNewRule({ rule: URL_RULE_OPTIONS[0].value, url: null});
    }

    function updateRule(index, rule) {
        let newRules = [...rules];
        newRules[index].rule = rule;
        onRulesChange(newRules);
    }

    function updateUrl(index, url) {
        let newRules = [...rules];
        newRules[index].url = url;
        onRulesChange(newRules);
    }

    return (
        <div style={{ margin: '1rem' }}>
            <Stack spacing='tight' vertical>
                {rules?.map((rule, index) => {
                    return <UrlRuleBlock
                        error={null}
                        ruleId={index}
                        key={index}
                        deleteRule={() => deleteRule(index)}
                        changeUrl={(url) => updateUrl(index, url)}
                        url={rule.url}
                        OPTIONS={URL_RULE_OPTIONS}
                        handleSelectUrlChange={(rule) => updateRule(index, rule)}
                        selectedUrlRule={rule.rule}
                    />;
                })
                }
                <UrlRuleBlock
                    error={newRuleError}
                    ruleId={'new'}
                    key={'new'}
                    addRule={addRule}
                    changeUrl={(url) => setNewRule({ ...newRule, url })}
                    url={newRule.url}
                    OPTIONS={URL_RULE_OPTIONS}
                    handleSelectUrlChange={(rule) => setNewRule({ ...newRule, rule })}
                    selectedUrlRule={newRule.rule}
                />;
            </Stack>
        </div>
    )
}

