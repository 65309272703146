import React, { useEffect, useState }  from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Card, Stack, ContextualSaveBar, TextField, TextContainer, TextStyle, Spinner, Heading, SkeletonBodyText } from '@shopify/polaris';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useShopProvider } from '../../components/ShopProvider';
import useShopServices from '../../hooks/useShopServices';

import { DEFAULT_HOST_NAME, DEFAULT_SERVICE_NAME } from '../../utils/consts'
import { Locales } from '../../utils/locales'

export function ServicesSummary({ serviceData, shopLocales }) {
    const defaultLocale = shopLocales[0];
    const serviceNoun = serviceData && serviceData.serviceNoun && serviceData.serviceNoun[defaultLocale];
    const hostNoun = serviceData && serviceData.hostNoun && serviceData.hostNoun[defaultLocale];

    return <Stack vertical spacing='tight'>
        <Stack>
            <p>Service name:</p>
            <p><TextStyle variation='strong'>{serviceNoun || DEFAULT_SERVICE_NAME[defaultLocale]}</TextStyle>{!serviceNoun && ' (default)'}</p>            
        </Stack>
        <Stack>
            <p>host name:</p>
            <p><TextStyle variation='strong'>{hostNoun || DEFAULT_HOST_NAME[defaultLocale]}</TextStyle>{!hostNoun && ' (default)'}</p>            
        </Stack>
    </Stack>
}

function Services() {

    const history = useHistory();
    const [serviceNoun, setServiceNoun] = useState({});
    const [hostNoun, setHostNoun] = useState({});
    const [dirtyBit, setDirtyBit] = useState(false);
    const [isSaving, setSaving] = useState(false);    
    const { shopOrigin, shopData, shopLocales, shopLocalesLoading } = useShopProvider();
    const {  defaultServiceData, defaultServiceLoading, setDefaultService} = useShopServices(shopOrigin);
    const isLoading =  shopLocalesLoading || defaultServiceLoading;
    const defaultLocale = shopLocales[0];

    useEffect(() => {
        setServiceNoun((defaultServiceData && defaultServiceData.serviceNoun) || {});
        setHostNoun((defaultServiceData && defaultServiceData.hostNoun) || {});
    }, [defaultServiceData]);

    const handleServiceNameChange = (val, locale) => {
        setServiceNoun({...serviceNoun, [locale]: val });
        setDirtyBit(true);
    }

    const handleHostNameChange = (val, locale) => {
        setHostNoun({...hostNoun, [locale]: val });
        setDirtyBit(true);
    }

    const save = async () => {
        setSaving(true);
        try {
            await setDefaultService({ hostNoun, serviceNoun });
        } catch (error) {
            console.error('Failed saving default service data', error);
        } finally {
            setSaving(false);
            setDirtyBit(false);
        }
    }

    const onDiscard = () => {
        setServiceNoun({});
        setHostNoun({});
        setDirtyBit(false);
    }

    const renderServiceForm = () => {
        return shopLocales.length > 1
            ? shopLocales.map(locale => (
                <TextField key={locale} label={Locales[locale].label} value={serviceNoun[locale]} onChange={(val) => handleServiceNameChange(val, locale)} type='text' placeholder={DEFAULT_SERVICE_NAME[locale]} />
            ))
            : <TextField value={serviceNoun[defaultLocale]} onChange={(val) => handleServiceNameChange(val, defaultLocale)} type='text' placeholder={DEFAULT_SERVICE_NAME[defaultLocale]} />

    }

    const renderHostForm = () => {
        return shopLocales.length > 1
            ? shopLocales.map(locale => (
                <TextField key={locale} label={Locales[locale].label} value={hostNoun[locale]} onChange={(val) => handleHostNameChange(val, locale)} type='text' placeholder={DEFAULT_HOST_NAME[locale]} />
            ))
            : <TextField value={hostNoun[defaultLocale]} onChange={(val) => handleHostNameChange(val, defaultLocale)} type='text' placeholder={DEFAULT_HOST_NAME[defaultLocale]} />
    }

    return (
        <AppPage title='Service Terminology' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.AnnotatedSection
                    title="Default service terminology"
                    description='Set how your Boutiq service is presented to clients in the Boutiq client app and in client notifications'
                >
                    <Card title={<Stack>{isLoading && <Spinner size='small' />}</Stack>}>
                        <Card.Section title='service name'>                            
                            <Stack vertical>
                                <TextContainer spacing='tight'>
                                    <p>The term used to describe your service to clients. For exmaple: </p>
                                    <p><TextStyle variation='subdued'>You have scheduled a <TextStyle variation='strong'>{DEFAULT_SERVICE_NAME['en']}</TextStyle> with {shopData.name}</TextStyle></p>
                                </TextContainer>
                                {isLoading && <SkeletonBodyText/>}
                                {!isLoading && renderServiceForm()}
                            </Stack>
                        </Card.Section>
                        <Card.Section title='host name'>
                            <Stack vertical>
                                <TextContainer spacing='tight'>
                                    <p>The term used to describe the person providing the service to clients. For exmaple: </p>
                                    <p><TextStyle variation='subdued'>Your <TextStyle variation='strong'>{DEFAULT_HOST_NAME['en']}</TextStyle> can add products they recommend</TextStyle></p>
                                </TextContainer>
                                {isLoading && <SkeletonBodyText/>}
                                {!isLoading && renderHostForm()}
                                
                            </Stack>
                        </Card.Section>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
            {dirtyBit && <ContextualSaveBar
                message="Save service settings?"
                saveAction={{
                    onAction: save,
                    loading: isSaving,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: isSaving,
                }}
            />}
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <Services/>
        </AdminPage>
    )
}
