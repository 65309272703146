import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { Layout, Form, FormLayout, TextField, Button, ButtonGroup, Checkbox, InlineError, Stack, ChoiceList, Banner } from '@shopify/polaris';

import AppPage from '../components/AppPage'
import AdminPage from '../components/AdminPage';

import { useAppAuthState } from '../authState'
import { useShopProvider } from '../components/ShopProvider';
import { isEmailValid } from '../utils/strings'
import { UserRole } from '../utils/permissions';

function InviteHost() {

    const history = useHistory();
    const { shopOrigin: shopId, user } = useAppAuthState();
    const { hosts, users, usersLoading, invites, invitesLoading, subData } = useShopProvider();

    const maxHosts = subData ? subData.planData.maxHosts : null;
    const currentHosts = hosts.length + invites.length;

    const loading = usersLoading && invitesLoading;

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [formError, setFormError] = useState('');
    const [quotaError, setQuotaError] = useState(null);
    const [adminRoles, setAdminRoles] = useState([]);
    const [working, setWorking] = useState(false);

    const closeForm = () => {
        history.goBack();
    }

    const onEmailChange = (newEmail) => {
        setEmail(newEmail);
        setFormError(null);
    }

    const onRolesChecked = (checked) => {
        setFormError(null);
        setAdminRoles(checked ? [UserRole.admin] : [])
    }

    const validateEmail = () => {
        if (!isEmailValid(email)) {
            setFormError(new Error('email is not valid'));
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (!validateEmail()) return;

        // don't allow inviting a host that already is active
        const currentUser = users.find(user => user.email === email);
        const roles = [UserRole.host, ...adminRoles];

        if (currentUser) {
            for (const role of roles) {
                if (currentUser.roles?.includes(role)) {
                    return setFormError(new Error(`Person with email ${email} is already a ${role} for this store`))
                }
            }
        }

        setWorking(true);

        // if invite already exists
        const existingInviteIndex = invites.findIndex(invite => invite.email === email);
        try {
            if (existingInviteIndex >= 0) {
                throw new Error(`Pending invite already exists for person with email ${email} `);
            }

            await Firebase.firestore().collection('shops').doc(shopId).collection('invites').add({
                firstName,
                lastName,
                email,
                shopId,
                createdAt: Firebase.firestore.Timestamp.now(),
                invitedBy: user.uid,
                status: 'pending',
                roles,
            })
            Firebase.analytics().logEvent('admin.host_invited');
            closeForm();
        } catch (error) {
            console.error('host invite failed', error);
            setFormError(error);
            setWorking(false);
        }
    }

    useEffect(() => {
        if (!working) {
            setQuotaError(currentHosts >= maxHosts ? new Error(`Cannot invite ${email || 'new host'} - reached quota of ${maxHosts} hosts`) : null);
        }
    }, [maxHosts, currentHosts, working])

    return (
        <AppPage title='Invite host' >
            <Layout>
                <Layout.AnnotatedSection
                    title="Host"
                    description="Invite someone to host Boutiq virtual personal shopping experiences so that they can help customers"
                >
                    <Stack vertical>
                        <Form onSubmit={handleSubmit} implicitSubmit={false}>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        value={firstName}
                                        onChange={setFirstName}
                                        label="First name"
                                        type='text'
                                    />
                                    <TextField
                                        value={lastName}
                                        onChange={setLastName}
                                        label="Last name"
                                        type='text'
                                    />
                                </FormLayout.Group>
                                <TextField
                                    value={email}
                                    onChange={onEmailChange}
                                    onBlur={validateEmail}
                                    label="Email"
                                    type="email"
                                />
                                <Stack vertical spacing='extraTight'>
                                    <Checkbox
                                        label='Make this user an Admin'
                                        checked={adminRoles.length}
                                        onChange={onRolesChecked}
                                    />
                                    <div style={{ paddingLeft: '16px' }}>
                                        <ChoiceList
                                            choices={[{ label: 'Full admin', value: UserRole.admin }, { label: 'Calendar admin', value: UserRole.calendarAdmin }]}
                                            selected={adminRoles}
                                            onChange={setAdminRoles}
                                            disabled={!adminRoles.length}
                                        />
                                    </div>
                                    {adminRoles?.[0] === UserRole.admin &&
                                        <Banner status='warning'>This user will have full admin permissions for your store's Boutiq app</Banner>
                                    }
                                    {adminRoles?.[0] === UserRole.calendarAdmin &&
                                        <Banner status='warning'>This user will have permissions to manage the store's calendar</Banner>
                                    }
                                </Stack>

                                <div style={{ float: 'right' }}>
                                    <ButtonGroup>
                                        <Button onClick={closeForm}>Cancel</Button>
                                        <Button primary submit disabled={loading || formError || quotaError} loading={working}>Send Invite</Button>
                                    </ButtonGroup>
                                </div>
                            </FormLayout>
                        </Form>
                        <div style={{ marginTop: '4px' }}>
                            <InlineError message={formError && formError.message} />
                            <InlineError message={quotaError && quotaError.message} />
                        </div>
                    </Stack>
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <InviteHost />
        </AdminPage>
    )
}
