import { Modal, TextContainer } from '@shopify/polaris';
import React from 'react';

const DeleteModal = ({isOpen, onClose, text, deleteAction, title}) => {
  
  return (
    <Modal
      title={title}
      onClose={onClose}
      open={isOpen}
      primaryAction={{
        content: 'Delete',
        onAction: deleteAction,
        destructive: true
      }}
      secondaryActions={[{
        content: 'Cancel',
        onAction: onClose,
      },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            {text}
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

export default DeleteModal;