import React from 'react';
import { Modal } from '@shopify/polaris'
import { useCreateNewAppointmentProvider } from '../../../../../../components/CreateNewAppointmentProvider';
import { ListItem } from '../listItem';

export const EventDataTab = () => {

    const { eventTypes, selectedEventType, setSelectedEventType, setFormError } = useCreateNewAppointmentProvider();

    return (
        <Modal.Section>
            {eventTypes.map((item, index) =>
                <ListItem
                    key={index}
                    isActive={item.id === selectedEventType}
                    label={item.name}
                    subline={`${item.duration} minutes`}
                    description={item.description}
                    onClick={() => { setSelectedEventType(item.id); setFormError(null); }} />
            )}
        </Modal.Section>
    )
}
