import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const useCallComposition = (callId, shopId) => {
  const db = getFirestore();

  const ref = callId && shopId && query(
    collection(db, 'callCompositions'), ...[
      where('shopId', '==', shopId),
      where('callId', '==', callId),
      where('recordingRule.source', '==', 'user')
    ])

  const [callCompositions, callCompositionLoading, callCompositionError] = useCollectionData(ref);
  return ({
    callComposition: callCompositions?.[0],
    callCompositionLoading,
    callCompositionError
  })
}

export default useCallComposition;
