import {EmptyState, Layout, Card, Text, Stack} from "@shopify/polaris";
import React, {useEffect, useState} from "react";
import './styles.css'
import ProductSearch from '../../../components/products/ProductsSearch';
import ProductList from "../../../components/products/ProductList";
import ProductSkeleton from '../../../components/products/ProductSkeleton';
import useShowroomPresetAction from '../../../hooks/useShowroomPresetAction';
import { useHistory } from 'react-router-dom';
import SaveModal from './SaveModal';
import { SAVE_ACTION } from '../../../constants/const';
import { MAX_SHOWROOM_PRODUCTS } from "../../../utils/consts";

const ShowroomPresetDetails = ({preset, loadPreset}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([])
    const {loading: actionLoading, onUpdatePreset, onSaveNewPreset} = useShowroomPresetAction()
    const [saveActionType, setSaveActionType] = useState(null)

    useEffect(() => {
        setLoading(loadPreset)
        if (preset) {
            setProducts(preset.products)
        }
    }, [preset, loadPreset])

    const onHideSaveModal = () => setSaveActionType(null)

    const onSaveAs = () => setSaveActionType(SAVE_ACTION.SAVE_AS)

    const onSavePreset = () => setSaveActionType(SAVE_ACTION.SAVE)

    const onSaveAction = async (presetName) => {
        onHideSaveModal()
        if (saveActionType === SAVE_ACTION.SAVE) {
            if (preset) {
                await onUpdatePreset(preset.id, {products, name: presetName, owner: 'public'})
            } else {
                await onSaveNewPreset(products, presetName)
            }
        } else {
            await onSaveNewPreset(products, presetName)
        }
        history.goBack()
    }

    const deleteProduct = (id) => {
        setProducts(prev => prev.filter(({productId}) => productId !== id))
    }

    const onUpdateProducts = (product) => {
        if (product) {
            setProducts(prev => {
                if (!prev.some(({productId}) => product.productId === productId)) {
                    return [...prev, product]
                }
                return prev
            })
        } else {
            setProducts([])
        }
    }

    const emptyState = () => (
      loading ? (
        <ProductSkeleton />
      ) : (
        <EmptyState
          heading="Create a client showroom"
          image={''}
        >
            <p>
                Search and add products to the showroom and save it for later
            </p>
        </EmptyState>
      )
    )

    return (
        <Layout>
            <Layout.Section>
                <Card>
                    <Stack vertical spacing='extraTight'>
                        <ProductSearch
                            disabled={products.length >= MAX_SHOWROOM_PRODUCTS}
                            onUpdateProducts={onUpdateProducts}
                            onSavePreset={onSavePreset}
                            onSaveAs={preset ? onSaveAs : null}
                            presetProducts={products}
                        />

                        <Stack distribution='trailing'>
                            {products.length > 0 &&
                                <div style={{paddingRight: '1rem'}}>
                                    <Text variant='bodySm' color={products.length >= MAX_SHOWROOM_PRODUCTS && 'critical'}>
                                        {products.length} product{products.length > 1 ? 's' : ''}
                                    </Text>
                                </div>}
                        </Stack>
                        {products.length
                            ? (
                                <ProductList
                                    products={products}
                                    loading={loading || actionLoading}
                                    shortcutActions={[{
                                        content: 'Delete',
                                        onAction: (product) => deleteProduct(product.productId),
                                        destructive: true,
                                        monochrome: true,
                                        outline: true
                                    }]}
                                />
                            ) : emptyState()}
                    </Stack>
                </Card>
                <SaveModal
                  preset={saveActionType === SAVE_ACTION.SAVE ? preset : null}
                  isOpen={!!saveActionType}
                  onClose={onHideSaveModal}
                  title={'Save preset'}
                  saveAction={onSaveAction}
                />
            </Layout.Section>
        </Layout>
    )
}

export default ShowroomPresetDetails;
