import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Card, Stack, SkeletonBodyText, TextField, Checkbox, RadioButton, Banner, ContextualSaveBar } from '@shopify/polaris';
import { TwitterPicker } from 'react-color';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import { useShopProvider } from '../../../components/ShopProvider';
import DeleteEventType from './delete';
import { SchedulingEventDuration, SchedulingEventPadding } from '../../../components/settings/SchedulingEvent';
import './styles.css';
import { DEFAULT_SLOT_DURATION } from '../../../utils/consts';

const COLOR_PICKER_DEFAULTS = ['#08b273', '#FFE767', '#EB144C', '#0000ff', '#ff9500', '#00D084', '#555555', '#FF6900', '#0693E3', '#6E76F2'];

const DEFAULT_EVENT_TYPE = {
    isActive: true,
    access: 'public',
}

function EditEventType() {
    const history = useHistory();
    let { id } = useParams();
    const [eventTypeId, setEventTypeId] = useState(id);
    const { eventTypes, eventTypesLoading, shopOrigin, schedulingConfig, schedulingConfigLoading } = useShopProvider();
    const [configEventData, setConfigEventData] = useState(null);
    const [mode, setMode] = useState(null);
    const [formError, setFormError] = useState(null);
    const [inputError, seInputError] = useState(null);
    const [newEventData, setNewEventData] = useState(null);
    const [colorPicker, setColorPicker] = useState(COLOR_PICKER_DEFAULTS);
    const [customSchedulingSettings, setCustomSchedulingSettings] = useState(false);

    const [dirtyBit, setDirtyBit] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        if (!eventTypesLoading) {
            if (eventTypeId !== 'create') {
                let eventData = eventTypes.docs.find(d => d.id === eventTypeId);
                if (!eventData) {
                    setFormError('Event type is invalid');
                } else {
                    setConfigEventData({ ...eventData.data() });
                    setMode('edit');
                }
            } else {
                setMode('create');
            }
        }
        return () => {
            setFormError(null);
        }

    }, [eventTypeId, eventTypes]);

    useEffect(() => {
        if (mode === 'edit') {
            setNewEventData(configEventData);
            setCustomSchedulingSettings(!!configEventData?.scheduling);
        } else if (mode === 'create') {
            let index = eventTypes.docs.length;
            let color = COLOR_PICKER_DEFAULTS[index];
            setNewEventData({ ...DEFAULT_EVENT_TYPE, color });
        }
    }, [configEventData, mode])

    useEffect(() => {
        if (newEventData?.color) {
            let exists = colorPicker.find(c => c === newEventData.color);
            if (!exists) {
                setColorPicker([...colorPicker.slice(0, -1), newEventData.color])
            }
        }

    }, [newEventData?.color])

    let title = mode === 'create' ? 'Create event type' : `Edit event type`;

    function onChange(update) {
        setNewEventData({ ...newEventData, ...update })
        setDirtyBit(true);
    }

    function onChangeSchedulingSettings(update) {
        setNewEventData({ ...newEventData, scheduling: { ...newEventData.scheduling, ...update } });
        setDirtyBit(true);
    }

    function onCustomSchedulingSettings(val) {
        if (val) {
            setCustomSchedulingSettings(true);
            setNewEventData({ ...newEventData, scheduling: { slotDuration: schedulingConfig.slotDuration ?? DEFAULT_SLOT_DURATION, paddingAfter: schedulingConfig.paddingAfter} });
        } else {
            setCustomSchedulingSettings(false);
            setNewEventData({ ...newEventData, scheduling: null });
        }
        setDirtyBit(true);
    }

    const onSave = useCallback(async () => {
        setSaving(true);
        try {
            if (mode === 'create') {
                let [lastIndex] = eventTypes.docs.slice(-1);
                let displayIndex = lastIndex?.data() ? lastIndex?.data().displayIndex + 1 : 0;
                let newEvent = await Firebase.firestore().collection('shops').doc(shopOrigin).collection('scheduleEventTypes').add({ ...newEventData, displayIndex });
                setEventTypeId(newEvent.id);
                history.goBack();
            } else {
                await Firebase.firestore().collection('shops').doc(shopOrigin).collection('scheduleEventTypes').doc(eventTypeId).set(newEventData, { merge: true });
            }
        } catch (error) {
            console.error('Failed to set new event type', error);
        } finally {
            setSaving(false);
            setDirtyBit(false);
        }
    }, [newEventData, eventTypeId, eventTypes, mode])

    function onDiscard() {
        if (mode === 'edit') {
            setNewEventData(configEventData);
            setCustomSchedulingSettings(!!configEventData?.scheduling);
            setDirtyBit(false);
        } else if (mode === 'create') {
            history.goBack();
        }
    }

    function onCloseDelete(deleted) {
        if (!deleted) {
            setShowDeleteDialog(false)
        } else {
            history.goBack();
        }
    }

    return (
        <AppPage
            primaryAction={mode === 'edit' && { content: 'Delete', destructive: true, onAction: () => setShowDeleteDialog(true) }}
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title={title}
        >
            <Layout>
                <Layout.Section>
                    {formError && <Banner status='critical'>Invalid event type</Banner>}
                    {!formError &&
                        <Card>
                            <Card.Section>
                                {eventTypesLoading && <SkeletonBodyText lines={4} />}
                                {!eventTypesLoading &&
                                    <Stack vertical>
                                        <div className='event-type-limit-width'>
                                            <TextField
                                                label='Name'
                                                value={newEventData?.name}
                                                onChange={(val) => onChange({ name: val })}
                                                error={dirtyBit && !newEventData?.name && 'Please set a name for this event type'}
                                            />
                                        </div>
                                        <Stack vertical spacing='tight'>
                                            <p>Color</p>
                                            <div className='event-type-color-picker-wrapper'>
                                                <div className='color' style={{ borderTopColor: newEventData?.color }} />
                                                <TwitterPicker
                                                    triangle='hide'
                                                    color={newEventData?.color}
                                                    onChangeComplete={(primaryColor) => onChange({ color: primaryColor.hex })}
                                                    colors={colorPicker}
                                                />
                                            </div>
                                        </Stack>

                                        <TextField
                                            label='Description'
                                            value={newEventData?.description}
                                            multiline={true}
                                            onChange={(val) => onChange({ description: val })}
                                            error={dirtyBit && !newEventData?.description && 'Please set a description for this event type'}
                                        />
                                        <Stack distribution='fillEvenly'>
                                            <div className='event-type-limit-width'>
                                                <Checkbox
                                                    label='Is active'
                                                    helpText='When an event type is inactive appointments using this type cannot be created'
                                                    checked={newEventData?.isActive}
                                                    onChange={(val) => onChange({ isActive: val })}
                                                />
                                            </div>
                                            <div className='event-type-limit-width'>
                                                <Stack vertical spacing='extraTight'>
                                                    <RadioButton
                                                        name='access'
                                                        label='Public'
                                                        helpText='Both clients and hosts can create events of this type'
                                                        checked={newEventData?.access === 'public'}
                                                        id="public"
                                                        onChange={(val) => onChange({ access: 'public' })}
                                                    />
                                                    <RadioButton
                                                        name='access'
                                                        label='Private'
                                                        helpText='Only hosts can create events of this type'
                                                        checked={newEventData?.access === 'private'}
                                                        id="private"
                                                        onChange={(val) => onChange({ access: 'private' })}
                                                    />
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </Stack>
                                }
                            </Card.Section>
                            <Card.Section>
                                <div style={{ maxWidth: 600 }}>
                                    {schedulingConfigLoading && <SkeletonBodyText lines={4} />}
                                    {!schedulingConfigLoading && <Stack vertical>
                                        <Checkbox
                                            label='Custom scheduling settings'
                                            checked={customSchedulingSettings}
                                            onChange={onCustomSchedulingSettings}
                                        />
                                        <SchedulingEventDuration
                                            duration={newEventData?.scheduling?.slotDuration ?? schedulingConfig.slotDuration ?? DEFAULT_SLOT_DURATION}
                                            onChange={(val) => onChangeSchedulingSettings({ slotDuration: val })}
                                            disabled={!customSchedulingSettings}
                                        />
                                        <SchedulingEventPadding
                                            paddingAfter={newEventData?.scheduling?.paddingAfter ?? schedulingConfig.paddingAfter ?? ''}
                                            duration={newEventData?.scheduling?.slotDuration ?? schedulingConfig.slotDuration}
                                            onChange={(val) => onChangeSchedulingSettings({ paddingAfter: val })}
                                            onError={seInputError}
                                            disabled={!customSchedulingSettings}
                                        />
                                    </Stack>}
                                </div>
                            </Card.Section>
                        </Card>
                    }
                </Layout.Section>
            </Layout>
            <DeleteEventType shopOrigin={shopOrigin} open={showDeleteDialog} onClose={onCloseDelete} eventTypeId={eventTypeId} eventTypeName={configEventData?.name} />
            {dirtyBit && <ContextualSaveBar
                message="Save event type"
                saveAction={{
                    onAction: onSave,
                    loading: saving,
                    disabled: inputError || !newEventData?.name || !newEventData?.description,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: saving,
                }}
            />}
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <EditEventType />
        </AdminPage>
    )
}
