import React from 'react'
import { Modal, Stack, TextField, TextStyle } from '@shopify/polaris'
import { useCreateNewAppointmentProvider } from '../../../../../../components/CreateNewAppointmentProvider'
import { AutocompleteClientSearch } from '../../../searchClient'

export const ClientDataTab = () => {

    const {
        clientName,
        nameError,
        setNameError,
        clientEmail,
        emailError,
        setEmailError,
        setClientName,
        setClientEmail
    } = useCreateNewAppointmentProvider();

    const handleOnAutocompleteChange = ({ name, email }) => {
        setClientName(name);
        setNameError(null);
        if (email) {
            setClientEmail(email);
            setEmailError(null);
        };
    }

    return (
        <>
            <Modal.Section>
                <Stack vertical>
                    <p>Search for a client or manually enter an email address</p>
                    <AutocompleteClientSearch
                        defaultValue={clientName}
                        error={nameError}
                        onChangeName={(value) => { setClientName(value); setNameError(null); }}
                        onChangeSelected={handleOnAutocompleteChange} />
                    <TextField
                        autoComplete='off'
                        onChange={(value) => { setClientEmail(value); setEmailError(null); }}
                        label='Email'
                        value={clientEmail}
                        error={emailError} />
                </Stack>
            </Modal.Section>
        </>
    )
}
