import React from 'react'
import './styles.css'
import { Icon, Stack, Text } from '@shopify/polaris';

const VideoState = ({data, icon, styles}) => {
  return (
    <div className='metadata' style={styles}>
      <Stack spacing='extraTight' alignment='center' distribution='center'>
        <div className='metadata-icon'>
          <Icon
            color='subdued'
            source={icon}
          />
        </div>
        <Text variant={'bodySm'}>
          {data}
        </Text>
      </Stack>
    </div>
  )
}

export default VideoState;
