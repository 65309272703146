import React from 'react'
import { Button, Stack } from '@shopify/polaris'
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons';
import './ReoderTools.scss';

export const ReoderTools = ({ onReorder, index, maxLength, disabled }) =>
    <Stack>
        <Button onClick={() => onReorder(index, false)} disabled={index === 0 || disabled} icon={ChevronUpMinor} plain size='slim'></Button>
        <Button onClick={() => onReorder(index, true)} disabled={index === maxLength - 1 || disabled} icon={ChevronDownMinor} plain size='slim'></Button>
    </Stack>
