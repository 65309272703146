import React from 'react';
import { createContext, useContext } from 'react';
import useCubeApi from './hooks/useCubeApi';
import { useAppAuthState } from '../authState';
import { useShopProvider } from '../components/ShopProvider';

const CubeContext = createContext(null);

export const CubeProvider = ({children}) => {
  const { user } = useAppAuthState();
  const { shopOrigin } = useShopProvider();
  const {cube, refreshToken} = useCubeApi(user, shopOrigin);
  const cubeOptions = { subscribe: true, cubeApi: cube };

  const onCubeError = (error) => {
    if (error && error.message === 'Not authorized') {
      refreshToken(user, shopOrigin);
    }
  }

  return (
    <CubeContext.Provider
      value={{
        cubeOptions,
        onCubeError
      }}
    >
      {children}
    </CubeContext.Provider>
  )
}


export function useCubeProvider() {
  const context = useContext(CubeContext);
  if (!context) {
    throw new Error('useCubeProvider must be used within the CubeContextProvider');
  }
  return context;
}
