import React from 'react';
import { PlayArrowIcon } from '../icons/playArrow';
import './index.css'

export default function UserPlayInteraction({onUserInteraction}) {

    return (
        <div className='play-interaction-container'>
            <button onClick={onUserInteraction} className='play-interaction-button'> 
                <PlayArrowIcon />
            </button>
        </div>
    )
}
