import AdminPage from '../../../components/AdminPage';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AppPage from '../../../components/AppPage';
import { Layout, Card, Text, Spinner, Stack, SkeletonBodyText, Avatar } from "@shopify/polaris";
import { useHistory } from 'react-router-dom';
import useCallRecording from '../../../hooks/useCallRecords';
import { useShopProvider } from '../../../components/ShopProvider';

import { useCallData } from '../../../hooks/useShopCalls';
import { formatCallDuration, formatCallEventType, formatCallOrder, formatCallTimestamp } from '../../../utils/calls';
import { formatCallFeedback } from '../../../utils/feedback';
import { getFeedbackTypeHeader } from '../../../utils/feedback'
import useCallTranscripts from '../../../hooks/useCallTranscripts';
import { FEEDBACK_TYPE } from '../../../utils/consts';
import TranscriptView from '../components/TranscriptView';

const CallInsights = () => {
  const history = useHistory();
  const { callId } = useParams();
  const { shopOrigin, hosts } = useShopProvider();
  const { callData, callDataLoading, callDataError } = useCallData(callId);
  const { audioSrc, recordingAudioLoading, callComposition, callCompositionLoading } = useCallRecording(shopOrigin, callId);
  const {  transcripts, transcriptsLoading, transcriptsError } = useCallTranscripts(callId, shopOrigin);

  const host = useMemo(() => {
    if (callData && hosts) {
      return hosts.find(h => h.id === callData.host.id);
    } else {
      return null;
    }
  }, [hosts, callData]);

  const feedback = useMemo(() => {
    if (callData) {
      const { feedback, message, type } = formatCallFeedback(callData.callFeedback);
      return { feedback, message, type };
    } else {
      return null;
    }
  }, [callData]);

  const callSummary = useMemo(() => {

    let transcript = transcripts?.find(t => !!t.summary);
    if (transcript) {
      return transcript.summary;
    } else {
      return null;
    }

  }, [transcripts]);

  const ecommTranscript = useMemo(() => {
    let transcript = transcripts?.find(t => t.name === 'chatMixWithEcomm' && !!t.uriJson);
    return transcript ?? null;    
  }, [transcripts]);

  const hostMarkup = () => (
    <Stack>
      <Avatar source={host.avatarUrl ? host.avatarUrl : null} name={host.firstName} initials={`${host.firstName && host.firstName[0]}${host.lastName && host.lastName[0]}`} />
      <Stack.Item fill>
        <Stack distribution='leading'>
          <Stack.Item fill>
            <Stack>
              <Text fontWeight='semibold'>{host.firstName} {host.lastName}</Text>
            </Stack>
            <Text>{host.email}</Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  )

  const summaryMarkup = () => {
    if (typeof callSummary == 'string') {
      return (<Text>{callSummary}</Text>)
    } else {
      return (
        <Stack vertical>
          {callSummary.customer_background &&
            <Text>{callSummary.customer_background}</Text>
          }
          {callSummary.call_summary &&
            <Text>{callSummary.call_summary}</Text>
          }
          {callSummary.conslusion_and_followup &&
            <Text>{callSummary.conslusion_and_followup}</Text>
          }
          {callSummary.measurements?.length &&
            <Stack vertical spacing='extraTight'>
              {callSummary.measurements.map(m => (
                <Stack key={m.measurement_name}>
                  <Text>{m.measurement_name}:</Text>
                  <Text>{m.value}:</Text>
                  <Text>{m.unit}:</Text>
                </Stack>
              ))}
            </Stack>
          }
        </Stack>
      )
    }

  }

  const audioCard = () => {
    if (callCompositionLoading) {
      return <SkeletonBodyText />
    }
    switch (callComposition?.status) {
      case 'enqueued':
        return <Text>{'Boutiq is processing the call recording and it will be available soon'}</Text>
      case 'failed':
        return <Text>{'Boutiq encountered an error while processing the recording for this call'}</Text>
      case 'completed':
        return (
          audioSrc ? (
            <audio id={`audio-${callId}`} autoPlay={false} controls={true}>
              <source src={audioSrc} />
            </audio>
          ) : (
            <SkeletonBodyText />
          )
        )
    }
    return <Text>{'Recording is not available for this call'}</Text>
  }


  return (
    <AppPage
      title={'Call insights'}
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
    >
      <Layout>
        <Layout.Section>
          {callDataLoading && <Card><SkeletonBodyText /></Card>}
          {!callDataLoading &&
            <Card title={`Call with ${callData.customerName}`} sectioned>
              <Card.Section>
                <Stack distribution='fillEvenly'>
                  <Stack vertical>
                    <Stack>
                      <Text fontWeight='semibold'>{formatCallTimestamp(callData.timestamp)}</Text>
                      <Text >{formatCallDuration(callData.videoDuration)}</Text>
                    </Stack>
                    {formatCallEventType(callData.eventType)}
                  </Stack>
                  {host && hostMarkup()}
                </Stack>
              </Card.Section>
              <Card.Section>
                <Stack vertical>
                  <Stack vertical spacing='extraTight'>
                    <Text fontWeight='semibold'>{callData.customerName}</Text>
                    {callData.customerEmail && <Text>{callData.customerEmail}</Text>}
                  </Stack>
                  {feedback?.type !== FEEDBACK_TYPE.NONE && <Stack>
                    <Text>{getFeedbackTypeHeader(feedback.type)}:</Text>
                    <div>{feedback.feedback}</div>
                    <Text>{feedback.message}</Text>
                  </Stack>}
                  {callData.totalOrderAmount && <Text fontWeight='semibold'>Order total: {formatCallOrder(callData)}</Text>}
                </Stack>
              </Card.Section>
            </Card>
          }
          <Card title='Call recording'>
            <Card.Section>
              {audioCard()}
            </Card.Section>
          </Card>
          {callSummary &&
            <Card title='Call summary'>
              <Card.Section>
                {summaryMarkup()}
              </Card.Section>
            </Card>
          }
          { ecommTranscript && <TranscriptView transcript={ecommTranscript}/>}
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <CallInsights />
    </AdminPage>
  )
}
