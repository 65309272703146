export const STORY_STATUS = {
    pending: 'pending',
    uploading: 'uploading',
    processing_pending: 'processing_pending',
    processing: 'processing',
    ready: 'ready',
    published: 'published',
    error: 'error'
}

export const STATUS_DESCRIPTION = {
    pending: 'Pending',
    uploading: 'Uploading',
    processing_pending: 'Processing pending',
    processing: 'Processing',
    processing_message: '(can take a few seconds)'
}
