import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Card, TextStyle, SkeletonBodyText, Checkbox, Stack, Select } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useShopProvider } from '../../components/ShopProvider';
import HostsTable from './components/HostsTable'
import AutoAssignConfig from './components/AutoAssign';
import { CallRoutePolicy } from '../../components/CallRoutingPolicyConfig';

function HostsSetttings() {
  const history = useHistory();
  const { hostSelectionConfig, hostSelectionConfigLoading, callPolicyConfig } = useShopProvider();
  const hostSelectionEnabled = hostSelectionConfig && hostSelectionConfig.hostSelectionEnabled;

  return (
    <AppPage
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
      title='Host settings'
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Host scheduling availability"
          description={ callPolicyConfig?.callRoutingPolicy?.type !== CallRoutePolicy.custom 
            ? "Set if a host offers appointment slots and whether they are set to default business hours or custom hours"
            : "Set if a host offers appointment slots and whether they are set to default business hours or custom hours for appointments and instant calls"
          }
        >
          <HostsTable />
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Host selection"
          description="Set if clients can view a list of host bios and select a specific host when scheduling appointments"
        >
          <Card sectioned actions={[{ content: 'Manage host selection', onAction: () => history.push('/hostSelectionSettings') }]}>
            {hostSelectionConfigLoading && <SkeletonBodyText />}
            {!hostSelectionConfigLoading && <p>Host selection is <TextStyle variation='strong'>{hostSelectionEnabled ? 'enabled' : 'disabled'}</TextStyle></p>}
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Host auto-assignment"
          description="Set whether and how hosts get automatically assigned to client appointment requests"
        >
          <AutoAssignConfig />
        </Layout.AnnotatedSection>

      </Layout>
    </AppPage>
  );
}

export default function () {
  return (
    <AdminPage>
      <HostsSetttings />
    </AdminPage>
  )
}
