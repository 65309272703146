import { useEffect, useState, useCallback, useMemo } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { useCollection } from 'react-firebase-hooks/firestore'
import { ScheduleStatus } from '../utils/consts';

const eventConverter = {
	toFirestore: () => ({}),
	fromFirestore: (snap) => {
		let data = snap.data();
		let id = snap.id;
		return {
			id,
			isCustomerEvent: !!data.customer,
			clientEmail: data.customer?.email,
			title: (data.customer?.name ?? data.customer?.email) ?? data.title,
			start: data.startTimestamp.toDate(),
			end: data.endTimestamp.toDate(),
			hostId: data.hostId,
			eventType: data.eventType,
			status: data.status,
			noShow: data.status === ScheduleStatus.deleted && data.noShow,
		}
	}

}

export default function useAllShopEvents(shopId, startDate, endDate, hostId, teamId) {

	const [filteredEvents, setFilteredEvents] = useState([]);

	const scheduleRef = useMemo(() => {
		if (!shopId) return null;

		let ref = Firebase.firestore().collection('shops').doc(shopId).collection('schedule').withConverter(eventConverter)
			.where('startTimestamp', '>=', startDate)
			.where('startTimestamp', '<', endDate)

		if (hostId) {
			ref = ref.where('hostId','==',hostId)
		}

		if (teamId) {
			ref = ref.where('teamId','==',teamId)
		}

		return ref.where('status', 'in', [ScheduleStatus.assigned, ScheduleStatus.pending, ScheduleStatus.completed, ScheduleStatus.deleted]);
	}, [shopId, startDate, endDate, hostId, teamId]);

	const [ shopEvents, shopEventsLoading, shopEventsError ] = useCollection(scheduleRef);

	useEffect(()=>{
		if (!shopEventsLoading) {
			if (!shopEvents?.empty && shopEvents?.docs) {
				setFilteredEvents(shopEvents?.docs.map(e => e?.data())?.filter(e => e.isCustomerEvent));
			} else {
				setFilteredEvents([]);
			}
		}
	},[shopEvents, shopEventsLoading])

	return { shopEvents: filteredEvents, shopEventsLoading, shopEventsError };
}
