import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Layout, Card, Stack, TextField, TextStyle, SkeletonBodyText, ContextualSaveBar, Checkbox } from '@shopify/polaris';
import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import { OnlineStoreConfigMethod } from "../../../components/Widget";
import { useShopProvider } from '../../../components/ShopProvider';

const AUTO_POP_UP_DEFAULTS = {
    open: 10,
    close: 5,
    debounceTime: 2 * 60,
}

const Z_INDEX_DEFAULT = 99;

function OnlineStoreAdvancedSettings() {
    const history = useHistory();
    const { onlineStoreConfig, onlineStoreConfigLoading, shopOrigin } = useShopProvider();
    const isWidget = onlineStoreConfig && onlineStoreConfig.method === OnlineStoreConfigMethod.widget.id;
    const [ autoPopUpConfig, setAutoPopUpConfig ] = useState(AUTO_POP_UP_DEFAULTS);
    const [ zIndex, setZIndex] = useState();

    const [dirtyBit, setDirtyBit] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(()=>{
        if (!onlineStoreConfigLoading && onlineStoreConfig && onlineStoreConfig.methodConfig) {
            const newConfig = {...AUTO_POP_UP_DEFAULTS, ...onlineStoreConfig.methodConfig.autoPopUp}
            setAutoPopUpConfig(newConfig);
            setZIndex(onlineStoreConfig?.methodConfig?.zIndex);
        }
    }, [onlineStoreConfig, onlineStoreConfigLoading])

    useEffect(()=>{
        setDisabled(autoPopUpConfig.open < 0);
    },[autoPopUpConfig.open])

    if (!onlineStoreConfigLoading && !isWidget) {
        return <Redirect to='/clientSettings/onlineStoreSettings' />
    }

    const onChangeOpen = (val) => {
        setError(val === '');
        setAutoPopUpConfig({ ...autoPopUpConfig, open: parseInt(val) });
        setDirtyBit(true);
    }

    const onChangeClose = (val) => {
        setError(val === '');
        setAutoPopUpConfig({ ...autoPopUpConfig, close: parseInt(val) });
        setDirtyBit(true);

    }

    const onChangeDebounce = (val) => {
        setError(val === '');
        setAutoPopUpConfig({ ...autoPopUpConfig, debounceTime: parseInt(val) });
        setDirtyBit(true);
    }

    const onChangeZIndex = (val) => {
        setZIndex(val ? parseInt(val) : null);
        setDirtyBit(true);
    }

    const onEnableToggle = (val) => {
        if (!val) {
            onChangeOpen(AUTO_POP_UP_DEFAULTS.open)
        } else {
            onChangeOpen(-1)
        }
    }

    const save = () => {
        setSaving(true);

        let update = {
            methodConfig: {
                autoPopUp: autoPopUpConfig,
                zIndex: zIndex ?? Firebase.firestore.FieldValue.delete(),
            }
        }

        Firebase.firestore().collection('shops').doc(shopOrigin)
            .collection('installData').doc('onlineStore')
            .set(update, { merge: true })
            .catch(err => console.error('Failed to save OnlineStoreAdvancedSettings', err))
            .finally(() => {
                setSaving(false);
                setDirtyBit(false);
            })
    }

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Widget Advanced Settings'
        >
            <Layout>
                <Layout.AnnotatedSection title='Helper text auto expand' description='Configure how the widget expands automatically to display its helper text'>
                    {onlineStoreConfigLoading &&
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>}
                    {!onlineStoreConfigLoading && <Card sectioned>
                        <Card.Section>
                            <Checkbox
                                label='disable auto exapnd'
                                checked={disabled}
                                onChange={onEnableToggle}
                            />
                        </Card.Section>
                        <Card.Section>
                            <Stack vertical spacing='tight'>
                                <p> Set time after page load to auto display the helper text </p>
                                <p><TextStyle variation='subdued'>Set to 0 to always display</TextStyle> </p>
                                <TextField
                                    type='number'
                                    label='Open'
                                    suffix='sec'
                                    value={autoPopUpConfig.open.toString()}
                                    onChange={onChangeOpen}
                                    disabled={disabled}
                                    placeholder={AUTO_POP_UP_DEFAULTS.open}
                                    min={0}
                                />
                            </Stack>
                        </Card.Section>
                        <Card.Section>
                            <Stack vertical spacing='tight'>
                                <p> Set time helper text is displayed </p>
                                <p><TextStyle variation='subdued'>Set to 0 to always display</TextStyle> </p>
                                <TextField
                                    type='number'
                                    label='Close'
                                    suffix='sec'
                                    value={autoPopUpConfig.close.toString()}
                                    disabled={disabled}
                                    onChange={onChangeClose}
                                    placeholder={AUTO_POP_UP_DEFAULTS.close}
                                    min={0}
                                />
                            </Stack>
                        </Card.Section>
                        <Card.Section>
                            <Stack vertical spacing='tight'>
                                <p> Set minimum time gap between auto displaying the helper text </p>
                                <p><TextStyle variation='subdued'>Set to 0 to display on every page load</TextStyle> </p>
                                <TextField
                                    type='number'
                                    label='Min gap'
                                    suffix='sec'
                                    disabled={autoPopUpConfig.open <= 0}
                                    value={autoPopUpConfig.debounceTime.toString()}
                                    onChange={onChangeDebounce}
                                    placeholder={AUTO_POP_UP_DEFAULTS.debounceTime}
                                    min={0}
                                />
                            </Stack>
                        </Card.Section>
                    </Card>}
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection title='Widget z-Index' description='Set the stack order of the widget relative to other page components.'>
                    {onlineStoreConfigLoading &&
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>}
                    {!onlineStoreConfigLoading && <Card sectioned>
                        <Card.Section>
                            <TextField
                                type='number'
                                label='Widget z-index'
                                value={zIndex?.toString()}
                                onChange={onChangeZIndex}
                                placeholder={Z_INDEX_DEFAULT}
                                min={0}
                                max={50000}
                            />
                        </Card.Section>
                    </Card>
                    }
                </Layout.AnnotatedSection>
            </Layout>
            {dirtyBit && <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                    onAction: save,
                    loading: isSaving,
                    disabled: isError,
                }}
                discardAction={{
                    onAction: () => history.goBack(),
                }}
            />}
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <OnlineStoreAdvancedSettings />
        </AdminPage>
    )
}
