import React, { useState } from 'react';

import { Stack, TextContainer, Heading, TextStyle, Spinner, InlineError } from '@shopify/polaris';
import { useShopProvider } from '../../components/ShopProvider';
import PricingPlan from '../../components/PricingPlan'
import PromoCode from '../../components/PromoCode'
import useCaazamREST from '../../hooks/useCaazamREST'

import './index.css';

export default function ConfirmPlan() {

    const { subData, pricingPlans, activePlansData, plansDataLoading } = useShopProvider();
    const entryPlan = pricingPlans[0];
    const currentPlan = subData ? subData.planId : null;

    const [loading, setLoading] = useState(false);
    const [planError, setPlanError] = useState(null);

    const [promoData, setPromoData] = useState(null);
    const promoCode = promoData ? promoData.code : null;
    const promoFreeTrialDays = (promoData && promoData.freeTrialDays) || 0;
    const freeTrial = activePlansData.freeTrialDays + promoFreeTrialDays;

    const { subPlanChange } = useCaazamREST();

    const onSelectPlan = (planId) => {
        setLoading(true);
        setPlanError(null);
        subPlanChange({ planId, redirectUrl: window.location.origin + '/admin/onboard/online?subConfirmed=true', promoCode })
            .then(confirmUrl => {
                window.location.assign(confirmUrl);
            })
            .catch(error => {
                console.error('subPlanChange failed', error);
                setLoading(false);
                setPlanError(error);
            })
    }

    const onValidPromoCode = (code, data) => {
        setPromoData({ code, ...data });
    }

    if(plansDataLoading) return (
        <Stack vertical alignment='center'>
                {plansDataLoading && <Heading><TextStyle variation='subdued'>Please wait...</TextStyle> </Heading>}
                {plansDataLoading && <Spinner />}
        </Stack>
    )

    if (!entryPlan) return null;

    return (
        <Stack vertical>
            <TextContainer>
                <Heading>Confirm Boutiq subscription plan and start your {activePlansData.freeTrialDays}-day trial:</Heading>
                <p>After the {activePlansData.freeTrialDays}-day trial you will automatically be enrolled into our {entryPlan.name} subscription plan. You can change plans later from the Boutiq app settings. </p>
            </TextContainer>
            {!currentPlan && <PromoCode onValidCode={onValidPromoCode} />}
            <Stack vertical alignment='center'>
                {loading && <Heading><TextStyle variation='subdued'>Please wait...</TextStyle> </Heading>}
                {loading && <Spinner />}
                {planError && <InlineError message={planError.reason || planError.message} />}
            </Stack>

            <div className='onboard-plan'>
                <PricingPlan
                    planData={{ ...entryPlan, freeTrial }}
                    currentPlan={currentPlan}
                    loading={loading}
                    onSelectPlan={onSelectPlan}
                    CTA={freeTrial ? 'Start Free Trial' : 'Confirm Plan'}
                />
            </div>
        </Stack>
    )
}