
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useRef } from 'react';
import { UserRole } from '../utils/permissions';

export default function useShopBilling(shopId, role) {
    const now = useRef(new Date())

    const query = shopId && role === UserRole.admin
        ? Firebase.firestore().collection('billing')
            .where('periodEnd', '>', now.current)
            .where('shopId', '==', shopId)
            .where('status', '==', 'active')
            .orderBy('periodEnd')
        : null;

    const [billingQuery, billingLoading, billingError] = useCollection(query);

    const billingPeriods = billingQuery
        ? billingQuery.docs
            .filter(periodDoc => periodDoc.data().periodStart.toDate() <= now.current)
            .sort((a, b) => a.data().periodStart.toDate() - b.data().periodStart.toDate())
        : [];

    const currentBillingPeriod = billingPeriods[0] ? billingPeriods[0].data() : null;

    return { currentBillingPeriod, billingLoading, billingError };
}
