import React from 'react';
import { Card, Box, SkeletonDisplayText } from "@shopify/polaris";
import VideoLibrarySkeleton from '../VideoLibrarySkeleton';

const VideoStoryCardSkeleton = () => {
  return (
    <Card
      sectioned
      title={(
        <Box width={'100%'} style={{flexDirection: 'row', display: 'flex'}}>
          <Box width={'70%'}>
            <SkeletonDisplayText size={'medium'} />
          </Box>
          <Box width={'15%'}>
            <SkeletonDisplayText size={'medium'} />
          </Box>
          <Box width={'15%'}>
            <SkeletonDisplayText size={'medium'} />
          </Box>
        </Box>
      )}
    >
      <VideoLibrarySkeleton />
    </Card>
  )
}

export default VideoStoryCardSkeleton;
