
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function useShopLinks(shopId) {

    const ref = shopId
        ? Firebase.firestore().collection('shops').doc(shopId).collection('permalinks').orderBy('createdAt','desc')
        : null;

    const [links, linksLoading, linksError] = useCollection(ref);

    const revokeLink =  (linkId) => Firebase.firestore().collection('shops').doc(shopId).collection('permalinks').doc(linkId).delete();

    linksError && console.error(linksError);
    return { links, linksLoading, linksError, revokeLink }
}
