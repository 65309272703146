import React from 'react';
import { Modal } from '@shopify/polaris';
import {ResponsiveContainer} from "recharts";

export default function ChartModal({children = null, open, onClose, title = ''}) {

  return (
    <Modal
      large={true}
      open={open}
      onClose={onClose}
      title={title}
      
    >
      <ResponsiveContainer width="100%" height={400}>
        {children ?? <div/>}
      </ResponsiveContainer>
    </Modal>
  )
}
