
export const DEFAULT_COLOR = '#1c1b1b';
export const COLOR_PICKER_DEFAULTS = ['#08b273', '#1C1B1B', '#EB144C', '#0000ff', '#ff9500', '#00D084', '#555555', '#FF6900', '#0693E3', '#6E76F2'];
export const COLOR_PICKER_DISABLED = ['#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0'];
export const BG_COLOR_PICKER_DEFAULTS = ['#ffffff', '#fffddb', '#ffe7d3', '#f7bf81', '#d0fffe', '#ffd3fd','#d2b6e5', '#e4ffde', '#b3bfa1', '#8e8b8d'];
export const DEFAULT_CORNER_STYLE = 'sharp';
export const DEFAULT_BTN_TEXT_STYLE = 'uppercase';
export const DEFAULT_FILLED_BTN_TEXT_COLOR_STYLE = '#fff';
export const DEFAULT_FONT_FAMILY = 'Poppins';
export const DEFAULT_OUTLINED_BTN_TEXT_COLOR_STYLE = '--main-color';

export const AVAILABLE_FONTS = [
    'Poppins',
    'Montserrat',
    'Nunito Sans',
    'Raleway',
    'Roboto',
    'Playfair Display',
    'EB Garamond',
    'PT Serif'
]

export const DEFAULT_SERVICE_NAME = {
    en: 'virtual personal shopping experience',
    fr: 'expérience de magasinage virtuelle',
    it: 'esperienza virtuale di shopping',
    de: 'virtuelles einkaufserlebnis',
    es: 'experiencia de virtual shopper personal',
}

export const DEFAULT_HOST_SELECT_SUBTITLE = {
    en: 'Select your preferred host',
    fr: 'Choisissez votre hôte préféré',
    it: 'Scegli il tuo ospite preferito',
    de: 'Wählen Sie Ihren bevorzugten Gastgeber',
    es: 'Selecciona tu anfitrión preferido',
}

export const DEFAULT_HOST_NAME = {
    en: 'host',
    fr: 'hôte',
    it: 'ospite',
    de: 'gastgeber',
    es: 'anfitrión',
}

export const DEFAULT_SCHEDULING_SUCCESS_MESSAGE = {
    en: 'A confirmation email has been sent to you at {email}',
    fr: 'Un email de confirmation vous a été envoyé sur {email}',
    it: "Ti è stata inviata un'email di conferma su {email}",
    de: 'Eine Bestätigungs Mail wurde an folgende Adresse gesendet: {email}',
    es: 'Se te envió un email de confirmación a {email}',
}

export const DEFAULT_WELCOME_CONTENT_WELCOME = {
    en: 'Welcome to our online store!',
    fr: 'Bienvenue dans notre boutique en ligne!',
    it: 'Benvenuti nel nostro negozio online!',
    de: 'Willkommen in unserem Online-Store!',
    es: '¡Bienvenido a nuestra tienda en línea!',
}

export const DEFAULT_WELCOME_CONTENT_LINE1 = {
    en: "We'd like to invite you to a {service_name} - we'll share personalized products for you in our private showroom and offer a personal experience with a human touch.",
    fr: "Nous aimerions vous inviter à un {service_name} - nous partagerons des produits personnalisés pour vous dans notre salle d'exposition privée et vous offrirons une expérience personnelle avec une touche humaine.",
    it: "Vorremmo invitarti a un {service_name} - condivideremo prodotti personalizzati per te nel nostro showroom privato e offriremo un'esperienza personale con un tocco umano.",
    de: "Wir möchten Sie zu einem {service_name} einladen - in unserem privaten Showroom stellen wir Ihnen personalisierte Produkte vor und bieten Ihnen ein persönliches Erlebnis mit einer menschlichen Note.",
    es: "Nos gustaría invitarte a una {service_name}; compartiremos productos personalizados para ti en nuestro showroom privado y ofreceremos una experiencia personalizada con un toque humano.",
}

export const DEFAULT_WELCOME_CONTENT_PRIMARYBUTTON = {
    en: "Connect now",
    fr: "Connecter",
    it: "Connettiti ora",
    de: "Verbinde jetzt",
    es: "Conectar ahora",
}

export const DEFAULT_WELCOME_CONTENT_SECONDARYBUTTON = {
    en: "Schedule",
    fr: "Je prends RdV",
    it: "Programma",
    de: "Terminplan",
    es: "Programar",
}

export const DEFAULT_WELCOME_CONTENT_LINE2 = {
    en: "We are looking forward to seeing you!",
    fr: "Nous sommes impatients de vous accueillir!",
    it: "Non vediamo l'ora di vederti!",
    de: "Wir freuen uns auf Sie!",
    es: "¡Esperamos con ansias saber de ti!",
}

export const DEFAULT_HOST_REQUEST_NOTE = {
    en: "Personal note from host or admin will go here",
    fr: "La note personnelle de l'hôte ou de l'administrateur va ici",
    it: "La nota personale dell'host o dell'amministratore va qui",
    de: "Persönliche Notiz vom Host oder Admin kommt hier rein",
    es: "La nota personal del anfitrión o administrador irá aquí",
}

export const DEFAULT_SLOT_DURATION = 30;
export const DEFAULT_LOOKAHEAD_DAYS = 7;

export const ScheduleStatus = {
    pending: 'pending',
    assigned: 'assigned',
    completed: 'completed',
    deleted: 'deleted',
}

export const WYSIWYG_TEXT_EDITOR_OPTIONS = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    list: {
        options: ['indent', 'outdent']
    }
}

export const WYSIWYG_TERMS_EDITOR_OPTIONS = {
    options: ['link', 'history'],
    link: {
        inDropdown: false,
        popupClassName: 'link-pop-up-modal-terms',
        showOpenOptionOnHover: false,
        defaultTargetOption: '_blank',
        options: ['link', 'unlink'],
      },
}

export const FEEDBACK_TYPE = {
    NPS: 'nps',
    CSAT: 'csat',
    SIMPLE: 'simple',
    NONE: 'none',
}

export const ECommActionType = {
    showroomAdd: 'showroom_add',
    showroomDelete: 'showroom_delete',
    cartAdd: 'cart_add',
    cartUpdate: 'cart_update',
    cartDelete: 'cart_delete',
    checkout: 'checkout',
    order: 'order',
}
export const NO_VARIANT_TITLE = 'Default Title';

export const DEFAULT_STORY_PRODUCT_CTA = {
    en: {
        ADD_TO_CART: 'Add to cart',
        SHOW_PRODUCT_PAGE: 'View product',
        GO_TO_PAGE: 'Go to page',
        OPEN_BOUTQ: 'Video call'
    },
    fr: {
        ADD_TO_CART: 'Ajouter au panier',
        SHOW_PRODUCT_PAGE: 'Voir le produit',
        GO_TO_PAGE: 'Aller à la page',
        OPEN_BOUTQ: 'Appel vidéo'
    },
    it: {
        ADD_TO_CART: 'Aggiungi al carrello',
        SHOW_PRODUCT_PAGE: 'Visualizzare il prodotto',
        GO_TO_PAGE: 'Vai alla pagina',
        OPEN_BOUTQ: 'Video chiamata'
    },
    de: {
        ADD_TO_CART: 'Zum Warenkorb hinzufügen',
        SHOW_PRODUCT_PAGE: 'Produkt anzeigen',
        GO_TO_PAGE: 'Gehe zur Seite',
        OPEN_BOUTQ: 'Videoanruf'
    },
    es: {
        ADD_TO_CART: 'Agregar al carro',
        SHOW_PRODUCT_PAGE: 'Ver el producto',
        GO_TO_PAGE: 'Ir a la página',
        OPEN_BOUTQ: 'Videollamada'
    },
}

export const MAX_SHOWROOM_PRODUCTS = 20;