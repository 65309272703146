
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { UserRole } from '../utils/permissions';

export default function useClientAppConfig(shopId, role) {
    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('appTheme') : null;
    const [appStylesConfig, appStylesConfigLoading] = useDocumentData(ref);
    return { appStylesConfig, appStylesConfigLoading };
}
