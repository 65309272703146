import { collection, deleteDoc, doc, getFirestore, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { useState } from 'react';
import { STORY_STATUS, VIDEO_STATUS } from '../constants/story';
import { DefaultStoryWidgetPosition, DefaultStoryWidgetShape, DefaultStoryWidgetSize } from '../pages/Stories/consts';

const useShopVideoStoriesActions = (shopId) => {
  const [isStoriesChangeLoading, setStoriesChangeLoading] = useState(false)
  const [publishLoading, setPublishLoading] = useState(null)

  const db = getFirestore();

  const onSaveStory = async (data) => {
    try {
      const batch = writeBatch(db);
      setStoriesChangeLoading(true)
      let id = data.storyId;
      if (id) {
        onUpdateStory({batch, ...data})
      } else {
        id = onCreateStory({batch, ...data})
      }
      await batch.commit()
      return id
    } catch (e) {
      console.error('onSaveStory ' + e)
      setStoriesChangeLoading(false)
    }
  }

  const onUpdateStory = ({batch, storyId, title, videos, useAsBoutiqClientStory, conditionUrl, widget, storyDisplayType}) => {
    const storyRef = doc(db, `shops/${shopId}/videoStories`, storyId);
    for (const [index, video] of changeIndexes(videos)) {
      const videoRef = doc(db, `shops/${shopId}/videoStories/${storyId}/videoStoriesVideos`, video.id)
      batch.set(videoRef, {
        index,
        status: video.data.status,
        videoUrl: video.data.storeVideoUrl,
        videoPosterUrl: video.data.storeVideoPosterUrl,
      }, {merge: true})
    }
    batch.update(storyRef, {
      title,
      useAsBoutiqClientStory,
      conditionUrl,
      storyDisplayType,
      widget: widget ?? {
        size: DefaultStoryWidgetSize,
        shape: DefaultStoryWidgetShape,
        position: DefaultStoryWidgetPosition
      },
      updatedAt: serverTimestamp()
    })
  }

  const onCreateStory = ({batch, title, videos, useAsBoutiqClientStory, conditionUrl, widget, storyDisplayType}) => {
    const storiesCollectionRef = collection(db, `shops/${shopId}/videoStories`);
    const storyRef = doc(storiesCollectionRef)
    const docId = storyRef.id
    for (const [index, video] of videos.entries()) {
      const videoRef = doc(storiesCollectionRef, `${docId}/videoStoriesVideos/${video.id}`)
      batch.set(videoRef, {
        index,
        status: video.data.status,
        videoUrl: video.data.storeVideoUrl,
        videoPosterUrl: video.data.storeVideoPosterUrl,
      })
    }
    batch.set(storyRef, {
      title,
      status: STORY_STATUS.UNPUBLISHED,
      useAsBoutiqClientStory,
      conditionUrl: useAsBoutiqClientStory ? null : conditionUrl,
      storyDisplayType,
      widget: widget ?? {
        size: DefaultStoryWidgetSize,
        shape: DefaultStoryWidgetShape,
        position: DefaultStoryWidgetPosition,
      },
      updatedAt: serverTimestamp(),
      createdAt: serverTimestamp()
    })
    return docId
  }

  const onDeleteStory = async (id) => {
    try {
      setStoriesChangeLoading(true)
      await deleteDoc(doc(db, `shops/${shopId}/videoStories`, id))
    } catch (e) {
      console.error('onDeleteStory ' + e)
    } finally {
      setStoriesChangeLoading(false)
    }
  }

  const onPublish = async (id) => {
    try {
      setPublishLoading(id)
      const storyRef = doc(db, `shops/${shopId}/videoStories`, id);
      await updateDoc(storyRef, {
        status: STORY_STATUS.PUBLISHED,
        updatedAt: serverTimestamp()
      })
    } catch (e) {
      console.error('onPublish ' + e)
      setPublishLoading(null)
    }
  }

  const onUnPublish = async (id) => {
    try {
      setPublishLoading(id)
      const storyRef = doc(db, `shops/${shopId}/videoStories`, id);
      await updateDoc(storyRef, {
        status: STORY_STATUS.UNPUBLISHED,
        updatedAt: serverTimestamp()
      })
    } catch (e) {
      console.error('onUnPublish ' + e)
      setPublishLoading(null)
    }
  }

  const changeIndexes = (videos) => {
    return [
      ...videos.filter(video => video.data.status !== VIDEO_STATUS.DELETED),
      ...videos.filter(video => video.data.status === VIDEO_STATUS.DELETED)
    ].entries()
  }

  return ({
    publishLoading,
    isStoriesChangeLoading,
    setStoriesChangeLoading,
    onChangePublishLoading: setPublishLoading,
    onSaveStory,
    onDeleteStory,
    onPublish,
    onUnPublish,
  })

}

export default useShopVideoStoriesActions;
