import React from 'react';

import { Layout, Card, Stack, TextField, SkeletonBodyText, List, Text } from '@shopify/polaris';

export default function KlaviyoEventTracking({ loading, privateAPIKey, setPrivateAPIKey, editMode, setEditMode, onReset, errorMsg }) {

    const labelAction = !editMode
        ? { content: 'Change', onAction: () => setEditMode(true) }        
        : privateAPIKey
            ? { content: 'Cancel', onAction: onReset }
            : null;

    return (

        <Layout.AnnotatedSection
            title="Klaviyo Events"
            description='Send Boutiq client actions to Klaviyo to trigger marketing flows and build campaigns'
        >
            <Card>
                <Card.Section>
                    {loading && <SkeletonBodyText />}
                    {!loading && <Stack vertical>
                        <TextField
                            id='kalviyo'
                            label='Klaviyo Private API Key'
                            placeholder='XXXXXX'
                            value={privateAPIKey}
                            onChange={setPrivateAPIKey}
                            labelAction={labelAction}
                            disabled={!editMode}
                            error={errorMsg}
                        />
                        <Stack vertical spacing='tight'>
                            <Text fontWeight='semibold'>The Klaviyo private API key should be created with full access for: Events, Profiles, and Webhooks</Text>
                            <p> The following client actions will be reported to Klaviyo:</p>
                            <List type='bullet'>                                                        
                                <List.Item>Video call scheduled</List.Item>
                                <List.Item>Video call completed</List.Item>
                                <List.Item>Video call missed</List.Item>                                
                                <List.Item>Checkout started from video call</List.Item>
                            </List>
                        </Stack>
                    </Stack>
                    }
                </Card.Section>

            </Card>
        </Layout.AnnotatedSection>
    );
}

