
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopEventTypes(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('scheduleEventTypes').orderBy('displayIndex') : null;
    const [ eventTypes, eventTypesLoading, eventTypesError] = useCollection(ref);
    eventTypesError && console.error('useShopEventTypes',eventTypesError);

    return { eventTypes, eventTypesLoading, eventTypesError }
}
