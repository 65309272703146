import React, { useState } from 'react'
import Moment from 'moment-timezone'
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import { Card, EmptyState, Banner, TextStyle, Avatar, Stack, Button, Caption, ButtonGroup, Modal, TextContainer, Badge, Spinner, List } from '@shopify/polaris';
import { useShopProvider } from './ShopProvider';
import { useAppAuthState } from '../authState'

const DEFAULT_MAX_HOSTS = 5; // allow inviting up to 5 hosts even if no sub plan yet

export function HostItem({ teams, host, status }) {

    const defineTeamName = (id) => {
        if (!id) return null;
            const team = teams.find(item => item.id === id);
            if (team) {
                return team.name;
            } else  {
                return null;
            }
    }

    const [working, setWorking] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const { shopOrigin } = useAppAuthState();

    const isOnline = status && status.status
    const isOffline = status && !status.status;
    const isInCall = status && status.currentCallId;

    const deleteHost = async () => {
        setWorking(true);
        try {
            await Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(host.id).update({
                roles: Firebase.firestore.FieldValue.arrayRemove('host'),
                updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            })
        } catch (deleteError) {
            console.error('Failed to delete host', deleteError);
        }
        setModalOpen(false);
    }

    return (
        <div style={{ paddingBottom: '0.8rem' }}>
            <Stack>
                <Avatar source={host.avatarUrl ? host.avatarUrl : null} name={host.firstName} initials={`${host.firstName && host.firstName[0]}${host.lastName && host.lastName[0]}`}/>
                <Stack.Item fill>
                    <Stack distribution='leading'>
                        <Stack.Item fill>
                            <div>
                                <h3>
                                   <Stack>
                                    <TextStyle variation="strong">{host.firstName} {host.lastName}</TextStyle>
                                        {host.teamId &&
                                            <Badge>
                                                <p style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '14ch'}}>
                                                        {defineTeamName(host.teamId)}
                                                </p>
                                            </Badge>}
                                       </Stack>
                                    <Caption>{host.email}</Caption>
                                </h3>
                                {host.lastCall && <p><TextStyle subdued>Last client call: {Moment(host.lastCall.toDate()).format('L LT')}</TextStyle></p>}
                            </div>
                        </Stack.Item>
                        <div style={{ marginRight: '32px' }}>
                            {isOnline && <Badge status="success" progress={isInCall ? 'complete' : 'incomplete'}>available</Badge>}
                            {isOffline && <Badge status="critical" progress=''>not available</Badge>}
                        </div>
                        <Button plain destructive onClick={() => setModalOpen(true)}>remove</Button>
                        <Modal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            title='Confirm Remove Host'
                            primaryAction={{
                                content: 'Remove',
                                onAction: () => deleteHost(),
                                destructive: true,
                                loading: working,
                            }}
                            secondaryActions={[
                                {
                                    content: 'Cancel',
                                    onAction: () => setModalOpen(false),
                                    disabled: working,
                                },
                            ]}
                        >
                            <Modal.Section>
                                <Stack vertical spacing='tight'>
                                    <p>Are you sure you want to remove host <TextStyle variation="strong">{host.firstName} {host.lastName}</TextStyle> ({host.email}){' '}?</p>
                                    <List>
                                        <List.Item>{host.firstName} won't be able to respond to video calls for your store until invited again</List.Item>
                                        <List.Item>All outstanding appointments assigned to {host.firstName} will be set to pending status</List.Item>
                                    </List>
                                </Stack>
                            </Modal.Section>
                        </Modal>
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    )
}

export function InviteItem({ invite }) {
    const [working, setWorking] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const cancelInvitation = async () => {
        setWorking(true);
        await Firebase.firestore().collection('shops').doc(invite.shopId).collection('invites').doc(invite.id).update({
             status: 'cancelled',
             updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    const resendInvitation = async () => {
        setWorking(true);
        const resendInvite = Firebase.functions().httpsCallable('invites-resendInvite');
        try {
            await resendInvite({ shopId: invite.shopId, inviteId: invite.id });
        } catch (error) {
            console.error(`Failed resending invite ${invite.id}`, error);
        } finally {
            setWorking(false);
        }

    }

    return (
        <div style={{ paddingBottom: '0.8rem' }}>
            <Stack distribution='leading'>
                <Stack.Item fill>
                    <div>
                        <h3>
                            <TextStyle variation="strong">{invite.firstName} {invite.lastName}</TextStyle>
                        </h3>
                        {invite.inviteEmailSentAt && !working && <div>Invite sent {Moment(invite.inviteEmailSentAt.toDate()).format('L')} to {invite.email}</div>}
                    </div>
                </Stack.Item>
                {working
                ? <Spinner size='small'/>
                : <ButtonGroup>
                    <Button plain onClick={resendInvitation}>resend</Button>
                    <Button plain destructive onClick={()=>setModalOpen(true)}>delete</Button>

                </ButtonGroup>}
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    title='Confirm Delete Invite'
                    primaryAction={{
                        content: 'Delete',
                        onAction: () => cancelInvitation(),
                        destructive: true,
                        loading: working,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => setModalOpen(false),
                            disabled: working,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <p>Are you sure you want to delete the invite sent to <TextStyle variation="strong">{invite.firstName} {invite.lastName}</TextStyle>{' '}?</p>
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            </Stack>

        </div>
    )
}

export default function Hosts({ showActions = true }) {
    const history = useHistory();
    const {
        hosts, usersLoading, usersError,
        hostsStatus,
        invites, subData,
        shopUserTeams
    } = useShopProvider();

    const maxHosts = subData ? subData.planData.maxHosts : DEFAULT_MAX_HOSTS;
    const currentHosts = hosts.length + invites.length;
    const isPendingInvites = invites.length > 0;

    const inviteHostAction = { content: 'Invite new host', onAction: () => history.push('/inviteHost') }
    const manageUsersAction = { content: 'Manage users', onAction: () => history.push('/usersSettings') }
    const cardActions = currentHosts < maxHosts ? [inviteHostAction, manageUsersAction ] : [manageUsersAction]

    const emptyStateMarkup = (
        <EmptyState
            heading="Invite your first Boutiq host"
            action={inviteHostAction}
        >
            <p>
                Hosts are your sales associates that would respond on incoming video calls
        </p>
        </EmptyState>
    );

    const title = !usersLoading
        ? `Hosts (${(currentHosts)} of ${maxHosts})`
        : 'Loading Hosts';

    return (
        <>
            <Card sectioned title={title} actions={showActions && cardActions}>
                {usersError && <Banner
                    title={usersError.message}
                    status='critical'
                />}
                {hosts.length === 0 && invites.length === 0 && emptyStateMarkup}
                {hosts.map((host, index) => (
                    <HostItem teams={shopUserTeams} host={host} key={host.id} status={hostsStatus[host.id]} />
                ))}
            </Card>
            {isPendingInvites && <Card>
                <Card.Section title={'Pending Invites'} subdued>
                    {invites.map((invite) => (
                        <InviteItem invite={invite} key={invite.id} />
                    ))}
                </Card.Section>
            </Card>
            }
        </>
    )
}
