import React, { useState, useRef, useEffect } from 'react';
import './styles.css'

export const CollapsibleElement = ({ countOfNonCollapsibleRows = 4, text }) => {
    const eventBioRef = useRef(null);

    const [openEventBio, setOpenEventBio] = useState(false);
    const [isToggleButtonAvailable, setIsToggleButtonAvailable] = useState(false);

    const [defaultBioHeight, setDefaultBioHeight] = useState(0);
    const [bioMaxHeight, setBioMaxHeight] = useState(null);

    useEffect(() => {
        if (eventBioRef.current) {
            if (eventBioRef.current.clientHeight < eventBioRef.current.scrollHeight) {
                setIsToggleButtonAvailable(true)
            }
            if (!defaultBioHeight) {
                setDefaultBioHeight(eventBioRef.current.clientHeight)
            }

            setBioMaxHeight(openEventBio ? eventBioRef.current.scrollHeight : `${countOfNonCollapsibleRows * 14 * 1.1}px` );
        }
    }, [eventBioRef.current, openEventBio])

    const toggleEventBio = (e) => {
        e.stopPropagation();
        setOpenEventBio(!openEventBio);
    }

    return (
        <div className='collapsible-container'>
            <p 
                ref={eventBioRef} 
                style={{ 
                    '--countOfRows': countOfNonCollapsibleRows,
                    maxHeight: `${bioMaxHeight}px`, 
                    '--default-max-height': `${bioMaxHeight}px` 
                }} 
                className={`event-bio${openEventBio ? ' open' : ''}`}>
                {text}
            </p>
            <button 
                onClick={(e) => toggleEventBio(e)} 
                className={`event-description-toggle-button ${isToggleButtonAvailable ? ' available' : ''}`} >
                <div className={`event-description-toggle-button-animated-labels-container${openEventBio ? ' open' : ''}`}>
                    <div className='event-description-toggle-button-label'>
                        Show more
                    </div>
                    <div className='event-description-toggle-button-label active'>
                        Show less
                    </div>
                </div>
            </button>
        </div>
    )


}
