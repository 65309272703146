
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useClientAppFeatures(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('clientAppFeatures') : null;
    const [clientAppFeatures, clientAppFeaturesLoading, clientAppFeaturesError] = useDocumentData(ref);
    return { clientAppFeatures, clientAppFeaturesLoading, clientAppFeaturesError }
}
