import AdminPage from '../../../../components/AdminPage';
import React, {useEffect, useRef, useState} from 'react';
import {
  Layout,
  Card, Text,
  EmptyState,
  SkeletonThumbnail, ContextualSaveBar, Box,
} from '@shopify/polaris';
import AppPage from '../../../../components/AppPage'
import { useHistory, useLocation } from 'react-router-dom';
import { StoryPreviewModal } from '../../components/StoryPreviewModal';
import './styles.css'
import StoryProductList from './components/StoryProductList';
import { ACTION_TYPES, DEFAULT_BOUTIQ_PRODUCT_STORIES_CONFIG, DEFAULT_PRODUCT_STORIES_CONFIG } from './consts';
import VideoCard from '../../components/VideoCard';
import useStoryVideoConfig from "../../../../hooks/useStoryVideoConfig";
import { useStoriesProvider } from '../../components/StoryiesProvider';
import StoryProductSearch from './components/StoryProductSearch';
import { compareObjects } from '../../../../utils/objects';
import StoryProductsSettingsSkeleton from './components/StoryProductsSettingsSkeleton';
import VideoStatistic from '../../components/VideoCard/components/VideoStatistic';
import { DEFAULT_COLOR, DEFAULT_STORY_PRODUCT_CTA } from '../../../../utils/consts';
import { useShopProvider } from '../../../../components/ShopProvider';
import useStoryVideosListener from '../../../../hooks/useStoryVideosListener';

const StoryProductConfigPage = () => {
  const history = useHistory();
  const location = useLocation();

  const {videoId, storyId, isBoutiqClientStory} = location?.state ?? {}

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const [videoDuration, setVideoDuration] = useState(null)
  const [products, setProducts] = useState([])
  const [isSaveBarVisible, setIsSaveBarVisible] = useState(false)
  const [productError, setProductError] = useState({})

  const storyProductSearchRef = useRef()
  const {
    shopVideoStories,
    videoStoryLoading,
    currentStory,
    isSaveVisible,
    isConfigurationValid,
    onSaveStory,
    isStoriesChangeLoading
  } = useStoriesProvider()
  const {videos} = useStoryVideosListener(storyId, (isSaveVisible ? currentStory : shopVideoStories.find((story) => story.storyId === storyId))?.videos)
  const videoItem = videos?.find((video) => video.id === videoId)

  const {storiesConfig, onUpdateStoryProducts, isStoryProductsLoading} = useStoryVideoConfig(storyId, videoItem?.id)
  const { appStylesConfig, shopLocales } = useShopProvider();

  useEffect(() => {
    if (!videoStoryLoading && !videoItem) {
      history.goBack()
    }
  }, [videoItem, videoStoryLoading]);

  useEffect(() => {
    setProducts( storiesConfig?.products ?? [])
  }, [storiesConfig])

  useEffect(() => {
    if (!storiesConfig) {
      return;
    }
    if (storiesConfig?.products === undefined) {
      setIsSaveBarVisible(products.length !== 0)
      return;
    }
    if (storiesConfig?.products?.length !== products.length) {
      setIsSaveBarVisible(true)
      return
    }
    const isProductsEqual = storiesConfig?.products
      ?.map((product, index) => compareObjects(product, products[index]))
      ?.reduce((acc, current) => acc && current, true)
    setIsSaveBarVisible(!isProductsEqual)
  }, [storiesConfig, JSON.stringify(products), isStoryProductsLoading])

  const onSaveChanges = async (products) => {
    let product;
    if (products.some((product) => product.storiesConfig.action?.label?.length === 0)) {
      setProductError(prev => ({...prev, actionLabel: 'You must enter the action label'}))
      return;
    }
    product = products.find((product) => {
      const showFrom = parseInt(product.storiesConfig.showFrom)
      const showUntil = parseInt(product.storiesConfig.showUntil ?? 0)
      return showUntil !== 0 && showFrom >= showUntil
    })
    if (!!product) {
      setProductError(prev => ({...prev, showUntil: 'display until should be after display from', productId: product.productId}))
      return;
    }
    product = products.find(product => product.storiesConfig.action?.type === ACTION_TYPES.GO_TO_PAGE);
    if (product && !product.storiesConfig.action.destinationPage) {
      setProductError(prev => ({...prev, destinationPage: 'You must enter the destination page'}))
      return;
    }
    if (isSaveVisible) {
      if (!isConfigurationValid(isBoutiqClientStory)) {
        return
      }
      if (isBoutiqClientStory) {
        await onSaveStory({...currentStory, useAsBoutiqClientStory: true, title: 'Boutiq client story'})
      } else {
        await onSaveStory(currentStory)
      }
    }
    await onUpdateStoryProducts(products)
  }

  const onDiscard = () => {
    setProducts( storiesConfig?.products ?? [])
  }

  const onChangeProductConfig = (product) => {
    setProductError({})
    setProducts(prev => prev.map((storyProduct) => {
      if (storyProduct.productId === product.productId) {
        return product
      }
      return storyProduct
    }))
  }

  const onUpdateProducts = (product) => {
    if (product) {
      setProducts(prev => {
        if (!prev.some(({productId}) => product.productId === productId)) {
          const color = appStylesConfig?.clientAppTheme?.primaryColor ?? DEFAULT_COLOR
          const localeCTA = DEFAULT_STORY_PRODUCT_CTA[shopLocales[0]]
          return [
            ...prev,
            {
              ...product,
              storiesConfig: isBoutiqClientStory
                ? DEFAULT_BOUTIQ_PRODUCT_STORIES_CONFIG
                : {...DEFAULT_PRODUCT_STORIES_CONFIG,
                  action: {
                    ...DEFAULT_PRODUCT_STORIES_CONFIG.action,
                    color,
                    label: localeCTA.ADD_TO_CART
                }}
            }];
        }
        return prev
      })
    } else {
      setProducts([])
    }
  }

  const deleteProduct = (id) => {
    setProducts(prev => prev.filter(({productId}) => productId !== id))
  }

  const onActivePopover = () => {
    storyProductSearchRef.current.onChangePopoverActive(true)
  }

  const ProductEmptyState = () => (
    <EmptyState
      image={''}
      action={{
        content: 'Add Product',
        onAction: onActivePopover,
      }}
    >
      <Text as={'p'} variant={'bodyMd'}>Search and add products to this shoppable video</Text>
    </EmptyState>
  )

  return (
    <AppPage
      breadcrumbs={[{content: 'Back', onAction: history.goBack}]}
      title='Video configuration'
    >
      <Layout>
        <Layout.Section secondary>
          <div className='story'>
            {!videoItem || videoStoryLoading ? (
              <SkeletonThumbnail size={'large'} />
            ) : (
              <Box position={'relative'}>
                <VideoCard
                  videoItem={videoItem}
                  isConfigPage={true}
                  showDelete={false}
                  onViewVideo={() => setIsPreviewDialogOpen(true)}
                  products={products}
                  onUpdateVideoDuration={setVideoDuration}
                />
                <VideoStatistic
                  views={videoItem.data.views}
                  clicks={videoItem.data.clicks}
                  styles={{
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    border: '1px solid rgba(0, 0, 0, 0.2)'
                  }}
                />
              </Box>
            )}
          </div>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card
            title={(
              <StoryProductSearch
                ref={storyProductSearchRef}
                onUpdateProducts={onUpdateProducts}
                isDisabled={products.length === 3}
                showSearchIcon={products.length > 0}
              />
            )}
          >
            {!storiesConfig
              ? <StoryProductsSettingsSkeleton />
              : <Card.Section>
                  <StoryProductList
                    products={products}
                    emptyState={<ProductEmptyState/>}
                    onDelete={(product) => deleteProduct(product.productId)}
                    isBoutiqClientStory={isBoutiqClientStory}
                    onChangeProductConfig={onChangeProductConfig}
                    videoDuration={videoDuration}
                    productError={productError}
                  />
              </Card.Section>
            }
          </Card>
        </Layout.Section>
      </Layout>
      <StoryPreviewModal
        stories={[videoItem]}
        isOpen={isPreviewDialogOpen}
        setIsOpen={setIsPreviewDialogOpen}
      />
      {isSaveBarVisible && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () => onSaveChanges(products),
            loading: isStoryProductsLoading || isStoriesChangeLoading,
          }}
          discardAction={{
            onAction: onDiscard
          }}
        />
      )}
    </AppPage>
  )
}

export default () => {
  return (
    <AdminPage>
      <StoryProductConfigPage/>
    </AdminPage>
  )
}
