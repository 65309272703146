import React from 'react';
import { useHistory } from 'react-router-dom';
import { useShopProvider } from '../../components/ShopProvider';
import { Stack, TextStyle, Modal, Subheading, DataTable, Badge } from '@shopify/polaris';

function formatEventDate(date) {
    return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'medium',
        timeStyle: 'short',
    }).format(date);
}

export default function CaazamSlot({ open, onClose, slot, getHostConfig, editHostAction, schedule = () => { } }) {

    const { hosts } = useShopProvider();
    const history = useHistory();
    if (!slot || !open) return null;

    const primaryAction = { content: 'OK', onAction: () => onClose() };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={slot &&
                <Stack vertical alignment='center' spacing='extraTight'>
                    <p>Time Slot <TextStyle variation='strong'>{formatEventDate(slot.start)}</TextStyle></p>
                </Stack>}
            primaryAction={primaryAction}
        >
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <Subheading>Slot details</Subheading>
                    <DataTable
                        columnContentTypes={['text', 'text', 'text']}
                        headings={['Host name', 'Configuration', '']}
                        rows={slot.hosts.map(hostId => {
                            let hostData = hosts.find(host => host.id === hostId);
                            let hostName = hostData ? hostData.firstName + ' ' + hostData.lastName : hostId;
                            let hostConfig = getHostConfig(hostId)?.defaultBusinessHours
                                ? <><Badge status="new">default business hours</Badge> </>
                                : <><Badge status="info" >custom hours</Badge> {editHostAction(hostId, 'change')} </>;
                            return [hostName, hostConfig, schedule(hostId, slot, 'schedule')];
                        })
                        }
                    />
                </Stack>
            </Modal.Section>
        </Modal>
    )
}
