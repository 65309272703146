import { useStoriesProvider } from '../components/StoryiesProvider';
import { useEffect } from 'react';
import { STORY_STATUS } from '../../../constants/story';

const usePublishButton = (story = {}) => {
  const { onPublish, onUnPublish, publishLoading, onChangePublishLoading } = useStoriesProvider();

  setTimeout(() => {
    const button = document.getElementById(`publish_button_${story?.storyId}`);
    if (button) {
      button.classList.remove('Polaris-Button--plain')
    }
  }, 0)

  useEffect(() => {
    if (story?.status === STORY_STATUS.ERROR) {
      onChangePublishLoading(null)
    } else {
      onChangePublishLoading(
        story?.updatedAt?.toDate()?.getTime() > story?.publishedAt?.toDate()?.getTime()
          ? story?.storyId
          : null
      )
    }
  }, [story])

  const publishAction = () => {
    if (story?.status === STORY_STATUS.PUBLISHED) {
      onUnPublish(story?.storyId)
    } else {
      onPublish(story?.storyId)
    }
  }

  return {
    id: `publish_button_${story?.storyId}`,
    content: story?.status === STORY_STATUS.PUBLISHED ? 'Unpublish' : 'Publish',
    onAction: publishAction,
    loading: publishLoading === story?.storyId,
    primary: true,
    plain: false,
    disabled: !story?.videos || story.videos.length === 0
  }
}

export default usePublishButton;
