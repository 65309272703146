import React from 'react';
import { Page } from '@shopify/polaris';

export default function AppPage({ title, breadcrumbs, primaryAction, secondaryActions, fullWidth, children }) {

    return (
        <Page title={title} breadcrumbs={breadcrumbs} primaryAction={primaryAction} secondaryActions={secondaryActions} fullWidth={fullWidth}>
            {children}
        </Page>
    )
}
