import React, { useEffect, useState } from 'react';
import AppPage from '../../../../components/AppPage'
import AdminPage from '../../../../components/AdminPage'
import { useStoriesProvider } from '../../components/StoryiesProvider';
import { useHistory } from 'react-router-dom';
import { Box, ContextualSaveBar, Layout, Text, TextContainer } from '@shopify/polaris';
import StoryMediaCard from '../../components/StoryMediaCard';
import { StoryPreviewModal } from '../../components/StoryPreviewModal';
import { VIDEO_STATUS } from '../../../../constants/story';
import DeleteStoryModal from '../../components/VideoDeleteModal';

const BoutiqStoryPage = () => {
  const {
    selectedStory,
    onChangeSelectedStory,
    onSaveStory,
    shopVideoStories,
    onDeleteStory,
    isStoriesChangeLoading,
    currentStory,
    isSaveVisible,
    isConfigurationValid,
    onDiscardStoryChanges,
    setBoutiqClientStory,
  } = useStoriesProvider();
  const history = useHistory();

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    setBoutiqClientStory(true);
    return () => setBoutiqClientStory(false);
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);    
  }, [])

  useEffect(() => {
    if (selectedStory) {
      history.replace({pathname: '/stories/boutiq', state: {storyId: selectedStory.storyId}})
    }
  }, [selectedStory])

  useEffect(() => {
    if (shopVideoStories) {
      onChangeSelectedStory(shopVideoStories.find(({useAsBoutiqClientStory}) => useAsBoutiqClientStory))
    }
  }, [JSON.stringify(shopVideoStories)])

  const onSave = async () => {
    if (!isConfigurationValid(true)) {
      return
    }
    await onSaveStory({...currentStory, useAsBoutiqClientStory: true, title: 'Boutiq client story'})
  }

  const onDiscard = () => {
    onDiscardStoryChanges()
  }

  const onDelete = async () => {
    onDeleteStory(selectedStory.storyId)
    setIsDeleteDialogOpen(false);
  }

  return (
    <AppPage
    title='Client intro video'
    breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
      <Layout>
        <Box style={{padding: '0 2.5rem'}}>
          <TextContainer spacing='tight'>
            <Text color='subdued'>The Boutiq client intro video is a sequence of short videos you can use to welcome your customers, educate them about the Boutiq experience, and/or share information before the call.</Text>
            <Text color='subdued'>They will be shown to your clients when they first open the Boutiq app.</Text>
          </TextContainer>
        </Box>
        <StoryMediaCard
          onPreview={() => setIsPreviewDialogOpen(true)}
          onDelete={() => setIsDeleteDialogOpen(true)}
        />
      </Layout>
      <StoryPreviewModal
        stories={currentStory.videos.filter(video => video.data.status !== VIDEO_STATUS.DELETED)}
        isOpen={isPreviewDialogOpen}
        setIsOpen={setIsPreviewDialogOpen}
        isBoutiqClientStory={true}
      />
      <DeleteStoryModal
        open={isDeleteDialogOpen}
        title={'Confirm delete story'}
        subTitle={'Are you sure you want to delete this story? This action cannot be undone'}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onDelete={onDelete}
      />
      {isSaveVisible && (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            onAction: onDiscard
          }}
          saveAction={{
            onAction: onSave,
            loading: isStoriesChangeLoading,
          }}
        />
      )}
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <BoutiqStoryPage />
    </AdminPage>
  )
}
