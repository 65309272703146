export const OPTION_RULES = {
    choiceType: ['choice', 'multi-choice'],
    choiceTypeNoInnerComponent: ['select', 'choice', 'multi-choice'],
    textType: ['text', 'phone', 'email']
}
export const OPTIONS = [
    { label: 'title', value: 'title' },
    { label: 'text', value: 'text' },
    { label: 'phone', value: 'phone' },
    { label: 'email', value: 'email' },
    { label: 'select', value: 'select' },
    { label: 'choice', value: 'choice' },
    { label: 'multi-choice', value: 'multi-choice' },
    { label: 'checkbox', value: 'checkbox' },
];

export const ALLOW_OTHER_TYPES = [
    'choice', 'multi-choice'
]

export const ALLOW_OPTION_TYPES = [
    'choice', 'multi-choice', 'select'
]

export const OPTIONS_REQUIRED = ALLOW_OPTION_TYPES;
