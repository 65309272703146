import React from 'react'
import { useIntroPage, IntroPageContext } from '../IntroPageProvider';
import UserPlayInteraction from '../userPlayInteraction/userPlayInteraction';
import { ArrowBackIcon } from '../icons/arrowBack';
import { ArrowForwardIcon } from '../icons/arrowForward';
import VideoLoader from '../loader/video-loader';
import './index.css';

export default function IntroContent({ }) {
    const {
        playingVideoIndex,
        setPlayingVideoIndex,
        playList,
        isVideoBuffering,
        autoplayPermissionsDenied,
        setAutoplayPermissionsDenied,
        setIsUserInteractWithVideoPlayer,
        stateChecked,
        updateVideoPlayingState,
    } = useIntroPage();

    const prev = () => {
        setIsUserInteractWithVideoPlayer(true);
        setAutoplayPermissionsDenied(false);
        if (playingVideoIndex > 0) {
            setPlayingVideoIndex(playingVideoIndex - 1);
        }
    }

    const next = () => {
        setIsUserInteractWithVideoPlayer(true);
        setAutoplayPermissionsDenied(false);
        updateVideoPlayingState(playingVideoIndex);
        if (playingVideoIndex !== playList.length - 1) {
            setPlayingVideoIndex(playingVideoIndex + 1);
        }
    }

    return (
        <div className='intro-content-wrapper'>
            {
                <div className='video-controls-container' >
                    {playList.length > 0 && stateChecked &&
                        <>
                            <div className='prev-control-container'>
                                <button disabled={playingVideoIndex === 0} className='prev-story' onClick={prev}>
                                    <ArrowBackIcon />
                                </button>
                            </div>
                            <div className='next-control-container'>
                                <button disabled={playingVideoIndex === playList.length - 1} className='next-story' onClick={next}>
                                    <ArrowForwardIcon />
                                </button>
                            </div>
                        </>}
                    <div className='state-observers'>
                        {autoplayPermissionsDenied &&
                            <IntroPageContext.Consumer>
                                {({ playIntroVideo }) => (
                                    <UserPlayInteraction onUserInteraction={playIntroVideo} />
                                )}
                            </IntroPageContext.Consumer>}
                        {(isVideoBuffering && playList.length > 0 && !autoplayPermissionsDenied) &&
                            <div className='video-loader-container'>
                                <VideoLoader />
                            </div>}
                    </div>
                </div>}
        </div>
    )
}
