
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { UserRole } from '../utils/permissions';

export default function useShopOnlineStoreConfig(shopId, role) {

    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('onlineStore') : null;
    const [onlineStoreConfig, onlineStoreConfigLoading, onlineStoreConfigError] = useDocumentData(ref);
    onlineStoreConfigError && console.error(onlineStoreConfigError);

    const saveOnlineStoreConfig = (data) =>  ref.set(data,{ merge: true });

    return { onlineStoreConfig, onlineStoreConfigLoading, onlineStoreConfigError, saveOnlineStoreConfig }
}
