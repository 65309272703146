import React, { useState } from 'react';
import { Card, Stack, DataTable, Modal, TextContainer, Button, Text, SkeletonBodyText } from '@shopify/polaris';
import useShopCalls from '../../../hooks/useShopCalls';
import { useAppAuthState } from '../../../authState';
import { DateRange } from '../../Dashboard/useDateRange';
import CallHistoryFilter from '../../CallHistory/components/CallHistoryFilter';
import CallHistoryTablePagination from '../../CallHistory/components/CallHistoryTablePagination';
import { getFeedbackTypeHeader } from '../../../utils/feedback';
import { useShopProvider } from '../../../components/ShopProvider';
import { FEEDBACK_TYPE } from '../../../utils/consts';
import callDataTableRow from '../../../utils/calls';
import { useHistory } from 'react-router-dom';

const ROWS_PER_PAGE = 15;
const CONTENT_TYPES = ['text', 'text', 'text', 'text', 'text', 'text', 'text'];
const HEADERS = (feedbackType) => [
  'Date',
  'Client Data',
  'Duration',
  'Host Name',
  'Sales Amount',
  getFeedbackTypeHeader(feedbackType),
  '',
]

const defaultFilter = {
  hostId: null,
  eventId: null,
  range: DateRange.last30,
  search: '',
  insightsFlag: true
}

const RecordingCallsList = () => {
  const history = useHistory();
  const { shopOrigin } = useAppAuthState();
  const [currentPage, setCurrentPage] = useState(0);
  const { feedbackConfig } = useShopProvider();
  const feedbackType = feedbackConfig?.type ?? FEEDBACK_TYPE.NPS;
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);
  const {
    calls: callsDocs,
    refresh: refreshCalls,
    getMoreCalls,
    loading
  } = useShopCalls(
    shopOrigin,
    ['completed'],
    ROWS_PER_PAGE,
    filter
  );

  const calls = callsDocs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }))

  const refreshTable = () => {
    refreshCalls();
    setCurrentPage(0);
  }

  const handlerOnChangePage = (page) => {
    if ((page + 1) * ROWS_PER_PAGE >= calls.length) {
      getMoreCalls();
    }
    setCurrentPage(page);
  }

  const openReadMessage = (message) => {
    setFeedbackMessage(message);
    setModalOpen(true);
  }

  const onRowClick = (callData) => {
    if (callData) {
      history.push(`/boutiq-ai/call/${callData.id}`)
    }
  }

  const mapCallToTable = (callData) => {
    const { row, onPushButton } = callDataTableRow({
      callData,
      openReadMessage,
      onRowClick,
    })

    onPushButton((
      <Button plain onClick={() => onRowClick(callData)}>View insights</Button>
    ))

    return row
  }

  const currentCalls = calls.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE).map(mapCallToTable);
  const hasNext = (calls.length > (currentPage + 1) * ROWS_PER_PAGE);

  const footerContent = () => {
    if (calls.length > 0) {
      return (
        <CallHistoryTablePagination
          currentPage={currentPage}
          hasNext={hasNext}
          itemsPerPage={ROWS_PER_PAGE}
          onChangePage={handlerOnChangePage}
        />
      )
    }
    return (
      loading ? (
        <SkeletonBodyText lines={4} />
      ) : (
        <Text>{'No calls found'}</Text>
      )
    )
  }

  return (
    <div
      onClick={(e) => {
        const index = e.target.parentElement.rowIndex
        if (index) {
          onRowClick(calls[index - 1])
        }
      }}
    >
      <Stack vertical>
        <CallHistoryFilter
          hideExport={true}
          onChangeFilter={setFilter}
          onRefresh={refreshTable}
          defaultFilter={defaultFilter}
        />
        <Card>
          <DataTable
            columnContentTypes={CONTENT_TYPES}
            headings={HEADERS(feedbackType)}
            rows={currentCalls}
            footerContent={footerContent()}
          />
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          >
            <Modal.Section>
              <TextContainer>
                <p style={{ margin: 20 }}>{feedbackMessage}</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
        </Card>
      </Stack>
    </div>
  )
}

export default RecordingCallsList;
