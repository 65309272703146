import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Layout,
    Card,
    Stack,
    TextStyle,
    ResourceList,
    ResourceItem,
    Filters,
    EmptyState,
    TextContainer,
} from '@shopify/polaris';

import {
    ImportMinor
  } from '@shopify/polaris-icons';

import { useShopProvider } from '../../../components/ShopProvider';
import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';
import useBlacklist, { useBlacklistActions } from "../../../hooks/useBlacklist";

import AddBannedModal from './addBanned';
import UploadBannedModal from './uploadBanned';

import './styles.css';


function Blacklist() {
    const history = useHistory();
    const { shopOrigin, users } = useShopProvider();
    const { blacklistData, blacklistLoading, } = useBlacklist(shopOrigin);
    const { deleteFromBlacklist } = useBlacklistActions(shopOrigin);
    const [blacklist, setBlacklist] = useState([]);
    const [filterValue, setFilterValue] = useState(null);

    const [selectedItems, setSelectedItems] = useState([]);
    const [openAddModal, setAddModal] = useState(false);
    const [openUploadModal, setUploadModal] = useState(false);
    const [deleteInAction, setDeleteInAction] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (blacklistData) {
            let filteredData = blacklistData.docs.map(doc => ({ email: doc.id, ...doc.data() }));
            if (filterValue) {
                filteredData = filteredData.filter(d => d.email.includes(filterValue))
            }
            setBlacklist(filteredData);
        }
    }, [blacklistData, filterValue]);

    useEffect(() => {
        setLoading(blacklistLoading || deleteInAction);
    }, [blacklistLoading, deleteInAction])

    const toggleAddModal = () => {
        setAddModal(prev => !prev);
    }

    const toggleUploadModal = () => {
        setUploadModal(prev => !prev);
    }

    const deleteAction = async (deleteArray) => {
        try {
            setDeleteInAction(true);
            deleteFromBlacklist(deleteArray);
        } catch (error) {
            console.error('Error unbanning clients', error);
        } finally {
            setSelectedItems([]);
            setDeleteInAction(false);
        }
    }

    const resourceName = {
        singular: 'banned client',
        plural: 'banned clients',
    };

    const promotedBulkActions = [{
        content: 'Unban',
        onAction: async () => {
            await deleteAction(selectedItems);
        },
    }];

    const filterControl = (
        <Filters
            queryValue={filterValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={setFilterValue}
            onQueryClear={() => setFilterValue(null)}
        />
    );

    const emptyStateMarkup =
        blacklistData?.size === 0 && !filterValue
            ? (
                <EmptyState
                    heading="No banned clients"
                    action={{content: 'Add banned client', onAction: toggleAddModal}}
                />

            ) : undefined;

    const renderItem = (item, _, index) => {
        const { updatedAt, email, updatedBy, note } = item;
        const user = users.find(h => h.id === updatedBy);
        const userName = user ? `${user.firstName} ${user.lastName}` : updatedBy;
        return (
            <ResourceItem
                id={email}
                sortOrder={index}
                shortcutActions={[{ content: 'Unban', onAction: () => deleteAction([email]) }]}
            >
                <Stack vertical>
                    <Stack>
                        <Stack vertical spacing='extraTight'>
                            <p>
                                <TextStyle variation="strong">{email}</TextStyle>
                            </p>
                            <p>
                                <TextStyle variation="subdued">{`by ${userName}`}</TextStyle>
                            </p>
                        </Stack>
                        <Stack.Item fill>
                            <Stack distribution='trailing'>
                                {updatedAt &&
                                    <p>{new Intl.DateTimeFormat(navigator.language, {
                                        dateStyle: 'long',
                                        timeStyle: 'short',
                                    }).format(updatedAt?.toDate())}</p>}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <p>{note}</p>
                </Stack>
            </ResourceItem >
        );
    }

    /* const renderPagination = () => {
        const first = offset * OFFSET_STEP + 1;
        const last = offset * OFFSET_STEP + blacklist.length;
        return (
          <Stack>
              <Pagination
                label={`${first} - ${last}`}
                hasPrevious={!!getPrevPage}
                onPrevious={getPrevPage}
                hasNext={!!getNextPage}
                onNext={getNextPage}
              />
          </Stack>
        )
    } */

    return (
        <AppPage
            title='Banned clients'
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            primaryAction={{ content: 'Add banned client', onAction: toggleAddModal }}
            secondaryActions={[{ content: 'Import', onAction: toggleUploadModal, icon: ImportMinor}]}
        >
            <Layout>
                <Layout.Section>
                    <div style={{ marginTop: '2rem', padding: '0 1rem' }}>
                        <TextContainer spacing='tight'>
                            <TextStyle variation='subdued'>
                                <p>
                                    Banning clients prevents them from scheduling appointments or making calls.
                                </p>
                            </TextStyle>
                        </TextContainer>
                    </div>
                </Layout.Section>
                <Layout.Section>
                    <Card sectioned>
                        <Card.Section>
                            <ResourceList
                                emptyState={emptyStateMarkup}
                                totalItemsCount={blacklist.length}
                                resourceName={resourceName}
                                items={blacklist}
                                renderItem={renderItem}
                                selectedItems={selectedItems}
                                onSelectionChange={setSelectedItems}
                                promotedBulkActions={promotedBulkActions}
                                idForItem={({ email }) => email}
                                loading={loading}
                                filterControl={filterControl}
                            />
                            {/* {
                                !isEmptyList &&
                                <div className='blacklist__pagination'>
                                    {renderPagination()}
                                </div>
                            } */}
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <AddBannedModal shopId={shopOrigin} open={openAddModal} onClose={toggleAddModal} />
            <UploadBannedModal shopId={shopOrigin} open={openUploadModal} onClose={toggleUploadModal} />
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <Blacklist />
        </AdminPage>
    )
}
