import React from 'react';
import { useHistory } from 'react-router-dom';

import { Layout } from '@shopify/polaris';

import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'

import CheckoutSettings from '../../../components/CheckoutSettings';
import FeedbackSettings from '../../../components/FeedbackSettings';
import ClientProductSearchSettings from '../../../components/ClientProductSearchSettings';

function AdvancedSettings() {
    const history = useHistory();

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Advanced Client Settings'
        >
            <Layout>
                <Layout.Section>
                    <CheckoutSettings />
                    <FeedbackSettings />
                    <ClientProductSearchSettings/>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <AdvancedSettings />
        </AdminPage>
    )
}
