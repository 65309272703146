import React, { useState, useRef, useEffect } from "react";
import { smoothScrollBySteps } from "../../../../../../utils/smooth-scroll";
import {
    ChevronRightMinor,
    ChevronLeftMinor
} from '@shopify/polaris-icons';
import './styles.css';
import { Icon } from "@shopify/polaris";
import { ScheduledTag } from "../scheduled-tag/ScheduledTag";

export const SelectTimeslotHeader = ({ tabs, selected, onSelect, preselectedData }) => {
    const [isSwipePrevented, setIsSwipePrevented] = useState(false);
	const [preselected, setPreselected] = useState(false);

    const leftArrowStateRef = useRef(false);
    const rightArrowStateRef = useRef(true);

    const wrapperRef = useRef(null);
    const contnetRef = useRef(null);

    const swipe = (direction = true) => {
        setIsSwipePrevented(true);
        if (!isSwipePrevented) {
            const directionValue = direction ? 1 : -1;

            let targetScrollPosition = wrapperRef.current.scrollLeft + directionValue * contnetRef.current.offsetWidth;
            const possibleLeftScrollPos = wrapperRef.current.scrollWidth - directionValue * contnetRef.current.offsetWidth
            if (targetScrollPosition > possibleLeftScrollPos) {
                targetScrollPosition = possibleLeftScrollPos;
            }

            if (targetScrollPosition < 0) {
                targetScrollPosition = 0;
            }

            rightArrowStateRef.current = !(wrapperRef.current.scrollWidth === (targetScrollPosition + contnetRef.current.offsetWidth));
            leftArrowStateRef.current = !(targetScrollPosition === 0)
            smoothScrollBySteps(
                wrapperRef.current,
                wrapperRef.current.scrollLeft,
                targetScrollPosition,
                'left',
                directionValue
            ).then(() => {
                setIsSwipePrevented(false)
            })
        }
    }

    useEffect(() => {
		if (wrapperRef.current &&
			contnetRef.current &&
			tabs && tabs.length > 0 &&
			!preselected) {
			let index = 0;
			setPreselected(true);

            index = tabs.findIndex((item => item.isScheduled))
            if (index < 0) {
                index = tabs.findIndex((item => item.isPreselected))
            }
			if (index >= 0) {
				onSelect(index)
                const countTilesPerView = window.innerWidth > 479 ? 5 : 3
				const countOfSwiping = Math.trunc(index / countTilesPerView)
				if (countOfSwiping >= 1) {
					setIsSwipePrevented(true);
					if (!isSwipePrevented) {
						smoothScrollBySteps(
							wrapperRef.current,
							contnetRef.current.scrollLeft,
							contnetRef.current.scrollLeft + contnetRef.current.offsetWidth * countOfSwiping,
							'left'
						).then(() => setIsSwipePrevented(false))
					}
				}
			}
		}
	}, [wrapperRef.current, contnetRef.current, tabs, preselectedData, preselected, selected])

    return (
        <div className='tabs'>
            <button className={`slots-tabs-navigation${leftArrowStateRef.current ? ' visible' : ''}`} onClick={() => swipe(false)}>
                <Icon
                    source={ChevronLeftMinor}
                    color="base"
                />
            </button>
            <div ref={wrapperRef} className='available-days-list-wrapper'>
                <div ref={contnetRef} className='available-days-list'>
                    {tabs.map((item, index) =>
                        <div key={index} className='date-tile-wrapper'>
                            <span onClick={() => onSelect(item.id)}
                                className={`date-title${selected === index ? ' selected' : ''}`}>
                                {item.isScheduled && <ScheduledTag />}
                                <div className='date-tile-content'>
                                    <span className='day-name'>{item.weekday}</span>
                                    <span className='day-tab-item'>
                                        {item.date}
                                    </span>
                                </div>
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <button className={`slots-tabs-navigation${rightArrowStateRef.current ? ' visible' : ''}`} onClick={() => swipe()}>
                <Icon
                    source={ChevronRightMinor}
                    color="base"
                />
            </button>
        </div>
    )
}
