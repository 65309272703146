import React from 'react'
import { Button, Stack, TextContainer, Icon } from '@shopify/polaris'
import { FormLabelField } from '../FormLabelField/FormLabelField';
import { CancelSmallMinor, RiskMinor } from '@shopify/polaris-icons';
import { FieldControl } from '../FieldControl/FieldControl';
import { OPTION_RULES } from '../../consts';
import './index.scss';

export const FormChoiceField = ({
    isEditMode,
    fieldId,
    onOptionChange,
    onError,
    disabled,
    removeOption,
    options = [],
}) =>
    <Stack vertical>
        {options && options.length > 0 ? options.map((option, index) => {
            if (option.innerComponent) {
                return <Stack wrap={false} key={`${option.value}-${index}`}>
                    <Stack.Item fill alignment='baseline'>
                        <FieldControl
                            index={index}
                            fiedlData={option.innerComponent}
                            availableOptions={OPTION_RULES.textType}
                            availableLabel={false}
                            isEditMode={true}
                            disabled={!isEditMode}
                            isOption={true}
                            allowDeleteBtn={false}
                            allowSetOrder={false}
                            allowSetType={true}
                            allowSubmitBtn={false}
                            allowSetRequired={true}
                            allowAddOption={false}
                            onFieldChange={e => onOptionChange(e, index)}
                        />
                    </Stack.Item>
                    {isEditMode &&
                        <Stack.Item>
                            <Button
                                disabled={disabled}
                                onClick={() => removeOption(index)}
                                icon={CancelSmallMinor}
                                plain
                                size='slim'
                                destructive>
                            </Button>
                        </Stack.Item>
                    }
                </Stack>
            } else {
                return <Stack wrap={false} key={`${option.value}-${index}`} >
                    <Stack.Item fill>
                        <FormLabelField
                            fieldId={fieldId}
                            inEditMode={isEditMode}
                            options={options}
                            onError={onError}
                            setTempValue={(value) => onOptionChange(value, index)}
                            isOption={true}
                            index={index}
                            value={option.label}
                            allowRemove={true} />
                    </Stack.Item>
                    <Stack distribution='center' >
                        {isEditMode &&
                            <Stack.Item>
                                <Button
                                    disabled={disabled}
                                    onClick={() => removeOption(index)}
                                    icon={CancelSmallMinor}
                                    plain
                                    size='slim'
                                    destructive></Button>
                            </Stack.Item>}
                    </Stack>
                </Stack>
            }
        }) :
            <Stack wrap={false}>
                <Icon
                    source={RiskMinor}
                    color='warning'
                />
                <TextContainer>
                    <p>No options available. Please add options</p>
                </TextContainer>
            </Stack>}
    </Stack >
