import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Card, TextStyle, SkeletonBodyText } from '@shopify/polaris';
import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import { useShopProvider } from '../../../components/ShopProvider';
import Hosts from '../../../components/Hosts'
import Admins from '../../../components/Admins'
import { Teams } from '../../../components/Teams';

function ShopUsers() {
    const history = useHistory();
    const { hostSelectionConfig, hostSelectionConfigLoading } = useShopProvider();
    const hostSelectionEnabled = hostSelectionConfig && hostSelectionConfig.hostSelectionEnabled;

    const inviteAction = { content: 'Invite', onAction: () => history.push('/inviteHost') }

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Users and roles'
            primaryAction={inviteAction}
        >
            <Layout>
                <Layout.AnnotatedSection
                    title="Roles"
                    description="Manage all users and what roles they have for your Boutiq app"
                >
                    <Hosts showActions={false} />
                    <Admins/>
                </Layout.AnnotatedSection>                
                <Layout.AnnotatedSection
                    title="Host teams"
                    description="Teams allow you to group hosts for viewing aggregated analytics"
                >
                    <Teams/>
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <ShopUsers />
        </AdminPage>
    )
}
