import React from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Card } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import CallPolicyConfig from "../../components/CallPolicyConfig"
import CallRoutingPolicyConfig from "../../components/CallRoutingPolicyConfig";
import { useShopProvider } from '../../components/ShopProvider';

function CallPolicy() {
    const history = useHistory();
    const { schedulingConfig } = useShopProvider();

    const disableIncoming = schedulingConfig?.disableIncoming;

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Call policy'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned title='Call Policy'>
                        <CallPolicyConfig />
                    </Card>
                    <Card sectioned title='Set Call Routing Policy'>
                        <CallRoutingPolicyConfig disabled={disableIncoming} />
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <CallPolicy />
        </AdminPage>
    )
}