import React, { useState, useEffect, useRef } from 'react';
import { useCreateNewAppointmentProvider } from '../../../../../../components/CreateNewAppointmentProvider';
import { smoothScrollBySteps } from '../../../../../../utils/smooth-scroll';
import { ListItem } from '../listItem';

export const SelectSlotList = ({ currentSlot }) => {
    const {
        selectedSlot,
        setSelectedSlot,
        setFormError,
        preselectedData
    } = useCreateNewAppointmentProvider();
    const contentRef = useRef(null);
    const wrapperRef = useRef(null);
    const [preselected, setPreselected] = useState(false);

    const formatZimezone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone || 'local'
    }

    const formatTime = (dateObject) => new Intl.DateTimeFormat([], {
        hour: 'numeric',
        minute: 'numeric',
    }).format(dateObject)

    const isDateSelected = (dateObject) =>
      selectedSlot &&
      selectedSlot.timeSlot instanceof Date &&
      dateObject.getTime() === selectedSlot.timeSlot.getTime();

    useEffect(() => {
        if (wrapperRef.current &&
            contentRef.current &&
            currentSlot?.slots.length > 0 &&
            !preselected) {

            let index = 0;
            if (selectedSlot) {
                index = currentSlot.slots.findIndex((item => isDateSelected(item.timeSlot)))
            } else if (preselectedData) {
                index = currentSlot.slots.findIndex((item => item.isScheduled))
            }
            if (index > 0) {
                setPreselected(true);
                const targetEl = contentRef.current.childNodes.item(index + 1);
                if (targetEl && targetEl.offsetTop > 0) {
                    smoothScrollBySteps(
                        wrapperRef.current,
                        wrapperRef.current.scrollTop,
                        targetEl.offsetTop - 70
                    ).then(() => { })
                }
            }
        }
    }, [
        currentSlot,
        contentRef.current,
        wrapperRef.current,
        preselectedData,
        selectedSlot,
        preselected])

    return (
        <div ref={wrapperRef} style={{ height: 327, overflowY: 'auto' }}>
            <ul ref={contentRef} className='select-host-list' >
                <ListItem
                  alignment='center'
                  disabled={true}
                  description={formatZimezone()}
                />
                {currentSlot?.slots?.map((session, index) =>
                    <ListItem
                        key={index}
                        alignment='center'
                        specialTag={session.isScheduled}
                        disabled={session.isScheduled && !session.isSlotSelectionAvailable}
                        isActive={selectedSlot?.timeSlot.getTime() === session.timeSlot.getTime()}
                        onClick={() => { setSelectedSlot(session); setFormError(null); }}
                        label={formatTime(session.timeSlot)}
                    />
                )}
            </ul>
        </div>
    )
}
