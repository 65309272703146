import React from 'react';

import { Stack, DescriptionList, TextContainer, Heading, Spinner, TextStyle } from '@shopify/polaris';
import { useAppAuthState } from '../../authState';
import { useShopProvider } from '../../components/ShopProvider';

export default function Welcome() {
    const { userData, authLoading } = useAppAuthState();
    const { shopData, shopDataLoading } = useShopProvider();

    if (authLoading || shopDataLoading) {
        return <Stack vertical><Spinner /></Stack>
    }
    return (
        <Stack vertical spacing='loose'>
            <TextContainer>
                <Heading>Hi {userData && userData.firstName}, let's get started!</Heading>
                <p>It takes just three easy steps and a couple of minutes to activate Boutiq for your <TextStyle variation='strong'>{shopData.name}</TextStyle> online store.</p>
            </TextContainer>
            <DescriptionList
                items={[
                    {
                        term: 'Step 1',
                        description: <TextContainer>
                            <p><TextStyle variation='strong'>Start your free trial</TextStyle></p>
                            Confirm your subscription plan and start your 30-day free trial
                        </TextContainer>
                    },
                    {
                        term: 'Step 2',
                        description: <TextContainer>
                            <p><TextStyle variation='strong'>Let’s make sure your customers can call you</TextStyle></p>
                            Add Boutiq to your online store so visitors can easily start a video call,
                        </TextContainer>
                    },
                    {
                        term: 'Step 3',
                        description: <TextContainer>
                            <p><TextStyle variation='strong'>Let’s make sure your sales associates can answer</TextStyle></p>
                            Invite hosts so they can pick-up incoming video calls
                        </TextContainer>
                    },
                    
                ]}
            />
            <p>And that’s it! Then you can start taking customer video calls and experience the Virtual Guideshop magic.</p>
        </Stack>
    )
}