import React  from 'react';
import Moment from 'moment-timezone';

import { currencyFormatter } from './formatters';
import EventTypeBadge from '../components/eventTypeBadge';
import '../pages/CallHistory/components/styles.css'
import { formatCallFeedback } from './feedback';

export const formatCallTimestamp = (timestamp) => Moment(timestamp.toDate()).format('L LT');
export const formatCallDuration = (videoDuration) => `${Math.ceil(Moment.duration(videoDuration, 'seconds').asMinutes())} min`;


export const formatCallOrder = (callData) => (
  callData.totalOrderAmount
    ? currencyFormatter(callData.totalOrderAmountCurrency || callData.orders[0].currency, 2).format(callData.totalOrderAmount)
    : null
);

export const formatCallEventType = (eventType) => eventType?.name ? <EventTypeBadge name={eventType.name} color={eventType.color}/> : null;

const callDataTableRow = ({
    callData,
    openReadMessage,
    onRowClick = () => {},
    showDate = true,
    showClientData = true,
    showDuration = true,
    showHostName = true,
    showAmount = true,
    showScore = true,
}) => {
  const row = []

  const feedbackCell = () => {

    const { feedback, message } = formatCallFeedback(callData.callFeedback?.[0]);

    return (
      <div className='feedback-container'>
        <div className='feedback-score-container'>{feedback}</div>
        <p onClick={() => openReadMessage(message)} className='feedback-message-container'>{message}</p>
      </div>
    )
  }

  const callAmountCell = () => {
    return formatCallOrder(callData);
  }

  const clientDataCell = () => {
    const {customerName, customerEmail} = callData;
    return (
      <div>
        <p>{customerName}</p>
        <p>{customerEmail}</p>
      </div>
    )
  }

  const hostDataCell = () => {
    const {eventType} = callData
    const name = callData.host ? callData.host.name || [callData.host.firstName, callData.host.lastName].join(' ') : ''
    return (
      <div>
        <p>{name}</p>
        {formatCallEventType(eventType)}
      </div>
    )
  }

  const callContextCell = () => {
    if (callData.status !== 'error') {
      return formatCallDuration(callData.videoDuration);
    } else if (callData.error && callData.error.error) {
      switch (callData.error.error.code) {
        case 403:
          return 'banned';
        case 404:
          return 'host unavailable';
        case 408:
          return 'missed';
        case 409:
          return 'rejected';
        case 429:
          return 'over quota';
        case 499:
          return 'cancelled';
        default:
          return 'error';
      }
    } else {
      return 'error';
    }
  }

  const onPush = (cell) => {
    row.push((
      <div onClick={() => onRowClick(callData)} style={{cursor: 'pointer'}}>
        {cell}
      </div>
    ))
  }

  const onPushButton = (cell) => {
    row.push(cell)
  }

  if (showDate) {
    onPush(formatCallTimestamp(callData.timestamp));    
  }
  if (showClientData) {
    onPush(clientDataCell())
  }
  if (showDuration) {
    onPush(callContextCell())
  }
  if (showHostName) {
    onPush(hostDataCell())
  }
  if (showAmount) {
    onPush(callAmountCell())
  }
  if (showScore) {
    onPush(feedbackCell())
  }
  return ({
    row,
    onPush,
    onPushButton
  })
}

export default callDataTableRow
