
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { hasCalendarAdminPermissions } from '../utils/permissions';

export default function useShopSchedulingConfig(shopId, role) {

    const ref = shopId && hasCalendarAdminPermissions(role) ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('scheduling') : null;
    const [schedulingConfig, schedulingConfigLoading, schedulingConfigError] = useDocumentData(ref);
    return { schedulingConfig, schedulingConfigLoading, schedulingConfigError }
}
