import {Modal, TextField} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';

const SaveModal = ({isOpen, onClose, title, saveAction, preset}) => {
  const [presetName, setPresetName] = useState('')

  useEffect(() => {
    if (preset) {
      setPresetName(preset.name)
    } else {
      setPresetName('')
    }
  }, [preset])

  return (
    <Modal
      title={title}
      onClose={onClose}
      titleHidden={true}
      open={isOpen}
      primaryAction={{
        content: 'Save',
        onAction: () => saveAction(presetName),
      }}
    >
      <Modal.Section>
        <TextField
          label={'Preset name'}
          value={presetName}
          onChange={setPresetName}
          autoComplete="off"
        />
      </Modal.Section>
    </Modal>
  )
}

export default SaveModal;