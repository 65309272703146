
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { UserRole } from '../utils/permissions';

export default function useShopInvites(shopId, role) {

    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('invites').where('status','==','pending') : null;
    const [ query, invitesLoading, invitesError] = useCollection(ref);
    invitesError && console.error(invitesError);

    const invites = query ? query.docs.map(doc => ({ id: doc.id, ...doc.data()})) : [];

    return { invites, invitesLoading, invitesError }
}
