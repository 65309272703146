import {useEffect, useState} from "react";
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {useAppAuthState} from "../authState";
import useShowroomPresetAction from "./useShowroomPresetAction";

const useShowroomPresets = () => {
    const [loading, setLoading] = useState(true)
    const [showroomPresetsList, setShowroomPresetsList] = useState([])
    const { user, shopOrigin } = useAppAuthState();
    const {deletePreset, loading: actionLoading} = useShowroomPresetAction();

    useEffect(() => {
        if (shopOrigin) {
            const unsub = Firebase
                .firestore()
                .collection('shops')
                .doc(shopOrigin)
                .collection('showroomPresets')
                .onSnapshot(snapshot => {
                    const presetList = snapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id
                    }))
                    setShowroomPresetsList(presetList)
                    setLoading(false)
                })

            return () => {
                unsub()
            }
        }
    }, [shopOrigin])

    return ({
        showroomPresetsList,
        loading: loading || actionLoading,
        deletePreset
    })
}

export default useShowroomPresets;
