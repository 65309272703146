export const VIDEO_STATUS = {
  READY: 'ready',
  PROCESSING: 'processing',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  DELETED: 'deleted'
}

export const STORY_STATUS = {
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  ERROR: 'error'
}
