
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { UserRole } from '../utils/permissions';

export default function useShopHostsStatus(shopId, role) {

    const ref = shopId && role === UserRole.admin ? Firebase.firestore().collection('shops').doc(shopId).collection('hostStatus') : null;
    const [ query, hostsStatusLoading, hostStatusError] = useCollection(ref);
    hostStatusError && console.error('useShopHostsStatus',hostStatusError);

    const hostsStatus = query
        ? query.docs.reduce((agg, doc) => {
            agg[doc.id] = doc.data();
            return agg;
        },{})
        : {};

    return { hostsStatus, hostsStatusLoading, hostStatusError }
}
