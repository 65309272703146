import AdminPage from "../../components/AdminPage";
import React from "react";
import AppPage from "../../components/AppPage";
import {
  Banner,
  Layout,
  Text,
} from '@shopify/polaris';
import {AdjustMinor} from "@shopify/polaris-icons";
import {useHistory} from "react-router-dom";
import RecordingCallsList from './RecordingCallsList';

const BoutiqAI = () => {
  const history = useHistory();

  return (
    <AppPage
      title={<>Boutiq <sup>AI</sup></>}
      secondaryActions={[
        { content: 'Settings', icon: AdjustMinor, onAction: () => history.push('/recording') },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Banner status="info" title="Boutiq AI beta">
            <Text>Boutiq<sup>AI</sup> Sales Intelligence suite is available free of charge during the beta program.</Text>
            <Text>At the end of the beta program this feature will require a paid subscription.</Text>
          </Banner>
        </Layout.Section>
        <Layout.Section>
          <RecordingCallsList />
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <BoutiqAI />
    </AdminPage>
  )
}
