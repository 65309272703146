import React, { useEffect, useState } from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Card, Stack, Select ,SkeletonBodyText, List, TextStyle } from '@shopify/polaris';
import { useShopProvider } from './ShopProvider'

import useShopFeedbackConfig from '../hooks/useShopFeedbackConfig';
import { FEEDBACK_TYPE } from '../utils/consts';

export default function FeedbackSettings() {

    const { shopOrigin, shopData } = useShopProvider();
    const { feedbackConfig, feedbackConfigLoading } = useShopFeedbackConfig(shopOrigin)
    const [feedbackType, setFeedbackType] = useState(FEEDBACK_TYPE.CSAT);

    const isLoading = feedbackConfigLoading;

    const dbRef = Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('feedback');

    useEffect(()=>{
            setFeedbackType(feedbackConfig?.type ?? FEEDBACK_TYPE.CSAT);
    }, [feedbackConfig])

    const onFeedbackChange = (val) => {
        setFeedbackType(val);
        dbRef.set({ type: val }, { merge: true })
            .catch(error => console.error('onDisabledChange', error));
    }

    const feedbackOptions = [
        { label: 'CSAT', value: FEEDBACK_TYPE.CSAT },
        { label: 'NPS', value: FEEDBACK_TYPE.NPS },
        { label: 'Simple', value: FEEDBACK_TYPE.SIMPLE },
        { label: 'None', value: FEEDBACK_TYPE.NONE },
    ]

    return (

        <Card sectioned title='Client  feedback'>
            <Stack vertical spacing='loose'>
                <p>Type of feedback form displayed after call ends:</p>
                {isLoading && <SkeletonBodyText lines='4' />}
                {!isLoading &&
                    <Select
                        options={feedbackOptions}
                        value={feedbackType}
                        onChange={onFeedbackChange}
                    />
                }
                <TextStyle variation='subdued'>
                <List>
                    <List.Item><TextStyle variation='strong'>CSAT (default)</TextStyle>: "How would you rate your overall satisfaction with the video call?"</List.Item>
                    <List.Item><TextStyle variation='strong'>NPS</TextStyle>: "Based on this video call, how likely are you to recommend {shopData.name} to a friend?"</List.Item>
                    <List.Item><TextStyle variation='strong'>Simple</TextStyle>: client selects simple thumbs up/down</List.Item>
                    <List.Item><TextStyle variation='strong'>None</TextStyle>: don't ask client for feedback</List.Item>
                </List>
                </TextStyle>
            </Stack>

        </Card>
    );
}
