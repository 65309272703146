import VideoState from '../../../VideoState';
import { formatCounter } from '../../../../../../utils/formatters';
import { ViewMinor, BehaviorMajor } from '@shopify/polaris-icons';
import { Box } from '@shopify/polaris';
import React from 'react';

const VideoStatistic = ({views, clicks, position, styles}) => {

  if (!views) return null;

  return (
    <Box position={position} minHeight={'40px'}>
      <VideoState
        data={formatCounter(views)}
        icon={ViewMinor}
        styles={{
          left: 0,
          right: 'unset',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          ...styles
        }}
      />
      {clicks && (
        <VideoState
          data={formatCounter(Object.values(clicks).reduce((agg, current) => agg + current, 0))}
          icon={BehaviorMajor}
          styles={{
            right: 0,
            left: 'unset',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            ...styles
          }}
        />
      )}
    </Box>
  )
}

export default VideoStatistic;
