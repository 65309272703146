import React, { useState, useEffect } from 'react';
import { useIntroPage } from '../IntroPageProvider';
import VideoPlayer from './videoPlayer/VideoPlayer';
import Progress from '../introVideo/progress/progress';
import { useHistory } from 'react-router-dom';
import './index.css';

export default function IntroVideo({ onVideoEnds = () => {}, onSetVideoElement }) {
    const history = useHistory();
    const {
        shopVideosStates,
        setPlayingVideoIndex,
        playList,
        stateChecked,
        setStateChecked,
        isShopVideoStatesRefreshed,
        isVideoBuffering,
        playingVideoIndex,
        autoplayPermissionsDenied,
        videoElement
    } = useIntroPage();
    const [progressAnimateDuration, setProgressAnimateDuration] = useState(null);
    const onDurationChange = (animationData) => {
        setProgressAnimateDuration(animationData)
    }

    useEffect(() => {
        if (videoElement) {
            onSetVideoElement(videoElement)
        }
    },[videoElement])

    const handleAnimation = (index) => {
        if (index === playingVideoIndex && !autoplayPermissionsDenied) {
            return !isVideoBuffering;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (shopVideosStates.length > 0 && isShopVideoStatesRefreshed) {
            const isPrevWelcome = history.entries && history.entries.length > 2 && history.entries[history.entries.length - 2].pathname === '/intro/intro-welcome'
            if (isPrevWelcome) {
                if (shopVideosStates.every(item => item.isPlayed)) {
                    setPlayingVideoIndex(playList.length - 1)
                } else {
                    const reversedPlaylist = shopVideosStates.reverse()
                    const index = reversedPlaylist.findIndex(video => video.isPlayed);
                    setPlayingVideoIndex(index - 1);
                }
            } else {
                if (shopVideosStates.every(item => item.isPlayed)) {
                    history.push('/intro/intro-welcome')
                } else {
                    const index = shopVideosStates.findIndex(video => video.isPlayed === false);
                    setPlayingVideoIndex(index);
                }
            }
            setStateChecked(true);
        }
    }, [playList])

    return (
        <>
            {stateChecked && <div className='intro-video-wrapper'>
                <VideoPlayer
                    playListEnded={onVideoEnds}
                    duration={(data) => onDurationChange(data)}
                />
                <div className='progressbar-container'>
                    {playList.map((video, index) => {
                        return (
                            <Progress
                                key={index}
                                index={index}
                                isProgressVisible={handleAnimation(index)}
                                progressAnimateDuration={progressAnimateDuration}
                            />)
                    })}
                </div>
            </div>}
        </>
    )
}
