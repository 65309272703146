import React from 'react';
import {
	Button,
	Popover,
	ActionList,
	Avatar,
	Stack,
	TextContainer
} from '@shopify/polaris';
import {
	useState,
	useCallback
} from 'react';

export const CustomSelect = ({ availableHosts, selectedHost, onChangeHost, helperText = 'Host is unavailable' }) => {
	const [popoverActive, setPopoverActive] = useState(false);
	const selected = availableHosts.find(host => host.id === selectedHost)
	const togglePopoverActive = useCallback(
		() => setPopoverActive((popoverActive) => !popoverActive), []);

	const activator = (
		<Button
			fullWidth
			textAlign="left"
			disclosure={popoverActive ? 'up' : 'down'}
			onClick={() => togglePopoverActive()}>
			{selected ? <Stack alignment='center'>
				<Avatar size="extraSmall" source={selected.avatar} name={selected.content} initials={selected.initials} />
				<TextContainer>
					{selected.content}
				</TextContainer>
			</Stack> : <TextContainer>
				Select host
			</TextContainer>}
		</Button>
	);

	const handleClick = (id) => {
		onChangeHost(id)
		togglePopoverActive()
	}

	return (
		<Popover
			active={popoverActive}
			activator={activator}
			onClose={togglePopoverActive}
			fixed={true}
		>
			<ActionList
				items={
					availableHosts.map(host =>
					({
						onAction: () => handleClick(host.id),
						active: selectedHost === host.id,
						content: host.content,
						prefix: <Avatar size="extraSmall" source={host.avatar} name={host.content} initials={host.initials} />,
						helpText: host.alert && helperText,
					})
					)}
			/>
		</Popover>
	);
}
