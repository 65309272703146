import React, {useCallback} from 'react';
import {DropZone, Stack, Thumbnail} from '@shopify/polaris';
import {TimelineAttachmentMajor} from '@shopify/polaris-icons';

function UploadFiles({accept = [], type = 'file', files = [], setFiles }) {

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles?.length > 0) {
        setFiles(acceptedFiles);
      }
    },
    [],
  );

  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <div style={{padding: '0'}}>
      <Stack vertical>
        {files.map((file, index) => (
          <Stack alignment="center" key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={TimelineAttachmentMajor}
            />
            <div>
              {file.name}{' '}
              <p>{file.size} bytes</p>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  return (
    <DropZone
      accept={accept}
      type={type}
      allowMultiple={false}
      onDrop={handleDropZoneDrop}>
      {uploadedFiles}
      {fileUpload}
    </DropZone>
  );
}

export default UploadFiles;
