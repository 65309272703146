import React, { useState } from 'react';
import { Banner } from '@shopify/polaris';
import './styles.css';
import { initials } from '../../../../../utils/formatters';

export const HostItemData = ({ data }) => {

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showInitials, setShowInitials] = useState(false);

    let hostDisplayName = data.displayName || data.firstName;
    let hostInitials = initials(hostDisplayName);

    const fallbackToInitials = () => {
        setShowInitials(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }
    
    return (
        <li className='host-data-container' >
            <div className='host-avatar-container'>
                {data.avatarUrl ?
                    showInitials ?
                        <span className='host-avatar initials'>{hostInitials}</span>
                        : <img onError={() => fallbackToInitials()} onLoad={animateImageOnLoad} className={`host-avatar${isImageLoaded ? ' loaded' : ''}`} src={data.avatarUrl} />
                    : <span className='host-avatar initials'>{hostInitials}</span>}

            </div>
            <p className='host-name'>
                {hostDisplayName}
            </p>
            {data.bio
                ? <p className='host-bio'>
                    {data.bio}
                </p>
                : <div className='empty-host-bio-container'>
                    <Banner status='warning'>
                        <p>{data.firstName} has not updated a bio</p>
                    </Banner>
                </div>
            }
        </li>
    )
}