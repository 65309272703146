import React, { useState, useEffect } from 'react';
import { Spinner, TextStyle, Stack } from '@shopify/polaris';
import { useCreateNewAppointmentProvider } from '../../../../../../components/CreateNewAppointmentProvider';
import { checkTodayTomorrow } from '../../../../../../utils/formatters';
import { SelectTimeslotHeader } from '../selectTimeslotHeader';
import { SelectSlotList } from '../selectSlotList';

export const SlotDataTab = () => {
    const {
        selectedSlot,
        availableSlots,
        slotsErrorMessage,
        preselectedData,
        selectedHostId
    } = useCreateNewAppointmentProvider();
    const [selectedTab, setSelectedTab] = useState(0);
    const [slots, setSlots] = useState()
    const [currentSlot, setCurrentSlot] = useState(null);
    const [preselected, setPreselected] = useState(false);
    const [tabs, setTabs] = useState([]);

    const formatDate = (dateObject) =>
      new Intl.DateTimeFormat([], {
          month: 'short',
          day: 'numeric',
      }).format(new Date(dateObject))

    const getSlotDay = (dateObject) =>
      new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());

    const isDayScheduled = (dataObject, data) => {
        let isSlotScheduled = false;
        if (data) {
            isSlotScheduled = dataObject.getTime() === getSlotDay(data).getTime();
        }
        return isSlotScheduled;
    }

    useEffect(() => {
        if (slots && tabs && tabs.length > 0) {
            let index = tabs.findIndex(tab => tab.isPreselected)
            if (index < 0) {
                index = tabs.findIndex(tab => tab.isScheduled)
            }
            if (index >= 0 && !preselected) {
                setPreselected(true)
                setSelectedTab(index);
                setCurrentSlot(slots[index] || [])
            } else {
                setCurrentSlot(slots[selectedTab] || [])
            }
        }
    }, [tabs, slots, selectedTab, preselected])

    useEffect(() => {
        if (slots) {
            const formattedTabs = slots.map((item, index) => ({
                id: index,
                weekday: checkTodayTomorrow(item.date),
                date: formatDate(item.date),
                isScheduled: preselectedData?.id && isDayScheduled(item.date, preselectedData?.slot.start),
                isPreselected: isDayScheduled(item.date, selectedSlot?.timeSlot)
            }))
            setTabs(formattedTabs);
        }
    }, [slots, selectedTab, selectedSlot, preselectedData])

    const isSlotScheduled = (dataObject) => {
        let isSlotScheduled = false;
        if (preselectedData) {
            const scheduledTimeSlot = preselectedData.slot?.start;
            if (scheduledTimeSlot) {
                isSlotScheduled = scheduledTimeSlot.getTime() === dataObject.getTime();
            }
        }
        return isSlotScheduled;
    }

    const isSlotSelectionAvailable = (dataObject) => {
        let isSlotSelectionAvailable = false;
        if (preselectedData) {
            const scheduledTimeSlot = preselectedData.slot?.start;
            let isEventHostsEqualExpression = true;
            if (scheduledTimeSlot) {
                if (selectedHostId) {
                    if (preselectedData.hostId) {
                        isEventHostsEqualExpression = preselectedData.hostId === selectedHostId;
                    } else {
                        isEventHostsEqualExpression = false;
                    }
                } else {
                    if (preselectedData.hostId) {
                        isEventHostsEqualExpression = false;
                    }
                }
            }

            isSlotSelectionAvailable = scheduledTimeSlot.getTime() === dataObject.getTime() && !isEventHostsEqualExpression;
        }
        return isSlotSelectionAvailable;
    }

    useEffect(() => {
        if (availableSlots) {
            let modyfiedSlots;
            if (preselectedData) {
                modyfiedSlots = availableSlots.map(item => ({
                    ...item,
                    slots: item.slots.map((slot => ({
                        ...slot,
                        isScheduled: preselectedData?.id && isSlotScheduled(slot.timeSlot),
                        isSlotSelectionAvailable: preselectedData?.id && isSlotSelectionAvailable(slot.timeSlot)
                    })))
                }))
            } else {
                modyfiedSlots = availableSlots;
            }
            setSlots(modyfiedSlots)
        }
    }, [availableSlots, preselectedData])

    return (
        <>
            <SelectTimeslotHeader
                tabs={tabs}
                selected={selectedTab}
                onSelect={setSelectedTab}
                preselectedData={preselectedData} />
            {currentSlot ?
                !slotsErrorMessage ?
                    <SelectSlotList currentSlot={currentSlot} /> :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Stack vertical alignment="center">
                            <p style={{ textAlign: 'center' }}>
                                <TextStyle variation='strong'>
                                    {slotsErrorMessage}
                                </TextStyle>
                            </p>
                        </Stack>
                    </div>
                :
                <div style={{ display: 'grid', height: '100%' }}>
                    <div style={{ justifySelf: 'center', alignSelf: 'center' }}><Spinner /></div>
                </div>}
        </>
    )
}
