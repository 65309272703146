import React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'moment-timezone';

import { Layout, Card, AccountConnection, Stack, Banner, TextStyle, SkeletonBodyText, Button, MediaCard } from '@shopify/polaris';

import { useAppAuthState } from '../../authState'
import { useShopProvider } from '../../components/ShopProvider';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'

import Hosts from '../../components/Hosts';
import AppWarnings from '../../components/AppWarnings';

import { OnlineStoreConfigMethod } from "../../components/Widget";
import { BusinessHoursSummary } from "../../components/BusinessHours";
import { ProductsSettingsSummary } from "./Products";
import { IntegrationsSummary } from "./IntegrationSettings";
import { ChatSettingsSummary } from "./Chat";
import { getCallPolicy, getCallPolicyDescription ,CallPolicyMode } from "../../components/CallPolicyConfig";
import { CallRoutePolicy } from "../../components/CallRoutingPolicyConfig";
import { isCustomClientTheme } from './ClientSettings/ClientAppThemeSettings'
import { CallRoutingPolicySummary } from '../../components/CallRoutingPolicyConfig';
import useShopServices from '../../hooks/useShopServices'
import { ServicesSummary } from './Services'

function Settings() {

    const { user, userData, signOut } = useAppAuthState();
    const history = useHistory();
    const { shopOrigin,
        subData, subDataLoading,
        onlineStoreConfig, onlineStoreConfigLoading,
        businessHoursConfig, businessHoursLoading,
        schedulingConfig, schedulingConfigLoading,
        callPolicyConfig, callPolicyConfigLoading,
        appStylesConfig, appStylesConfigLoading,
        shopLocales, shopLocalesLoading,
        productsConfig, productsConfigLoading,
        chatConfig, chatConfigLoading,
    } = useShopProvider();

    const { defaultServiceData, defaultServiceLoading } = useShopServices(shopOrigin);

    const subName = subData
        ? `${subData.planData.name}/${subData.planData.plan === 'annual' ? 'Annualy' : 'Monthly'}`
        : 'n/a'

    const subDate = subData
        ? Moment(subData.createdAt.toDate()).format('ll')
        : 'n/a'

    const subStatus = subData
        ? subData.shopify.subStatus
        : 'n/a'

    const callPolicy = getCallPolicy(schedulingConfig);
    const callPolicyDesc = getCallPolicyDescription(callPolicy);
    const customCallRoutePolicy = callPolicyConfig?.callRoutingPolicy?.type === CallRoutePolicy.custom;

    const isWidget = onlineStoreConfig?.method === OnlineStoreConfigMethod.widget.id;
    const clientAppTheme = isCustomClientTheme(appStylesConfig) ? 'Custom' : 'Default';

    const manageSubsriptionAction = { content: 'Manage subscription', onAction: () => history.push('/subscription') };
    const manageOnlineStoreConfig = { content: 'Client settings', onAction: () => history.push('/clientSettings') };
    const manageCallPolicyConfig = { content: 'Call policy settings', onAction: () => history.push('/callPolicyConfig') };

    const terms = (
        <Stack vertical spacing='extraTight'>
            <p>
                Boutiq's{' '}<a href={`${process.env.REACT_APP_WEB_DOMAIN}/terms`} target='_blank' rel="noopener noreferrer">terms and conditions</a>.
            </p>
            <p>
                Boutiq's{' '}<a href={`${process.env.REACT_APP_WEB_DOMAIN}/privacy`} target='_blank' rel="noopener noreferrer">privacy policy</a>.
            </p>
        </Stack>
    )

    // TODO: [CRRC-112] need to add indication of free trial to sub overview and sub details
    return (
        <AppPage title='Settings'>
            <Layout>
                <Layout.Section>
                    <AppWarnings />
                </Layout.Section>
                <Layout.AnnotatedSection
                    title="Hosts"
                    description="Invite new hosts and manage Boutiq app users and roles"
                >
                    <Hosts />
                </Layout.AnnotatedSection>
                <Layout.Section>
                    <div style={{borderTop: '0.1rem solid var(--p-divider'}}/>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <MediaCard
                        title='Online store integration'
                        size='small'

                        primaryAction={{
                            content: 'Go to online store settings',
                            onAction: () => history.push('/clientSettings/onlineStoreSettings'),
                        }}
                        description='Set how Boutiq is added to your online store so clients can start or schedule video calls'
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/online_store_thumbnail.png"
                        />

                    </MediaCard>

                </Layout.Section>
                <Layout.Section oneHalf>

                    <MediaCard
                        title='Client app'
                        size='small'

                        primaryAction={{
                            content: 'Customize client app',
                            onAction: () => history.push('/clientSettings/clientApp'),
                        }}
                        description='Make it your own! Customize the Boutiq client app theme, styling, content and behavior to look on-brand'
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/client_app_thumbnail.png"
                        />

                    </MediaCard>
                </Layout.Section>
                {/*
                <Layout.AnnotatedSection
                    title="Client Experience"
                    description="Set how Boutiq is added to your online store so clients can start video calls. Customize the Boutiq client app"
                >
                    <Card sectioned actions={[manageOnlineStoreConfig]}>
                        {onlineStoreConfigLoading && appStylesConfigLoading && <SkeletonBodyText />}
                        {!onlineStoreConfigLoading && !appStylesConfigLoading &&
                            <Stack vertical spacing='tight'>
                                <p>Boutiq widget is <TextStyle variation='strong'>{isWidget ? 'enabled' : 'disabled'}</TextStyle></p>
                                <p><TextStyle variation='strong'>{clientAppTheme}</TextStyle> client app theme</p>
                            </Stack>
                        }
                    </Card>
                </Layout.AnnotatedSection> */}
                <Layout.AnnotatedSection
                    title="Call policy"
                    description="Set your policy for accepting and scheduling video calls. Set how calls are routed to hosts"
                >
                    <Card sectioned actions={[manageCallPolicyConfig]}>
                        {businessHoursLoading && schedulingConfigLoading && <SkeletonBodyText />}
                        {!schedulingConfigLoading &&
                            <Stack vertical spacing='tight'>
                                <p><TextStyle variation='strong'>{callPolicyDesc}</TextStyle></p>
                                {!schedulingConfig?.disableIncoming &&
                                    <>
                                        {!businessHoursLoading && businessHoursConfig &&
                                            <Stack>
                                                <p>Instant calls available:</p>
                                                {!customCallRoutePolicy 
                                                ? <BusinessHoursSummary config={businessHoursConfig} />
                                                : <span>based on custom host availability</span>
                                                }
                                            </Stack>
                                        }
                                        {!callPolicyConfigLoading &&
                                            <CallRoutingPolicySummary callPolicyConfig={callPolicyConfig} />
                                        }
                                    </>
                                }
                            </Stack>
                        }
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Notifications"
                    description="Manage the notifications Boutiq sends to you and your clients"
                >
                    <Card sectioned>
                        <Stack distribution='trailing'>
                            <Button plain onClick={() => history.push('/notifications')}>Manage Notifications</Button>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>                
                <Layout.AnnotatedSection
                    title="Host Experience"
                    description="Customize the Boutiq host app"
                >
                    <Card sectioned>
                        <Stack distribution='trailing'>
                            <Button plain onClick={() => history.push('/hostSettings')}>Host Settings</Button>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Integrations"
                    description="Enable integrations for event reporting and external data sources"
                >
                    <Card sectioned actions={[{ content: 'Manage integrations', onAction: () => history.push('/integrationSettings') }]}>
                        {!onlineStoreConfigLoading &&
                            <IntegrationsSummary shopId={shopOrigin} />
                        }
                    </Card>
                </Layout.AnnotatedSection>                
                <Layout.AnnotatedSection
                    title="Products"
                    description="Manage how products are displayed in the Boutiq app"
                >
                    <Card sectioned actions={[{ content: 'Product settings', onAction: () => history.push('/productsConfig') }]}>
                        {!productsConfigLoading &&
                            <ProductsSettingsSummary productsConfig={productsConfig} />
                        }
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Chat"
                    description="When enabled clients can use text chat to message your hosts"
                >
                    <Card sectioned actions={[{ content: 'Chat settings', onAction: () => history.push('/chatConfig') }]}>
                        {!chatConfigLoading &&
                            <ChatSettingsSummary chatConfig={chatConfig} />
                        }
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Service terminology"
                    description="Manage how your Boutiq service is presented to clients in the Boutiq client app and in client notifications"
                >
                    <Card sectioned actions={[{ content: 'Manage services', onAction: () => history.push('/services') }]}>
                        {!shopLocalesLoading && !defaultServiceLoading &&
                            <ServicesSummary shopLocales={shopLocales} serviceData={defaultServiceData} />
                        }
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title="Banned list"
                  description="Manage banned clients list"
                >
                    <Card sectioned>
                        <Stack distribution='trailing'>
                            <Button plain onClick={() => history.push('/banned')}>Banned clients</Button>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Subscription and fees"
                    description="Manage your Boutiq subscription plan"
                >
                    <Card sectioned actions={subData ? [manageSubsriptionAction] : null}>
                        {subDataLoading && <SkeletonBodyText />}
                        {!subDataLoading && !subData && <Banner
                            title="No Boutiq Subscription"
                            action={manageSubsriptionAction}
                            status="critical">
                            <p>
                                Boutiq calls will not be connected without an active subscription
                            </p>
                        </Banner>}
                        {!subDataLoading && subData && subData.shopify.subStatus !== 'ACTIVE' && <Banner
                            title="Boutiq subscription is not active"
                            action={manageSubsriptionAction}
                            status='warning'>
                            <p>
                                Boutiq calls will not be connected without an active subscription
                            </p>
                        </Banner>}
                        {!subDataLoading && subData && <Stack distribution='fill'>
                            <Stack vertical spacing='tight'>
                                <TextStyle variation='strong'>Current plan</TextStyle>
                                <p>{subName}</p>
                            </Stack>
                            <Stack vertical spacing='tight'>
                                <TextStyle variation='strong'>Since</TextStyle>
                                <p>{subDate}</p>
                            </Stack>
                            <Stack vertical spacing='tight'>
                                <TextStyle variation='strong'>Status</TextStyle>
                                <p>{subStatus}</p>
                            </Stack>
                        </Stack>}
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Boutiq account"
                    description="Details of your Boutiq account currently in use"
                >
                    <AccountConnection
                        accountName={userData ? `${userData.firstName} ${userData.lastName}` : 'Boutiq Account'}
                        connected={!!user}
                        title='Boutiq Account'
                        details={`Signed in with ${user.email}`}
                        action={{
                            content: 'Sign Out',
                            onAction: () => signOut(),
                        }}
                        avatarUrl={userData && userData.avatarUrl}
                        termsOfService={terms}
                    />
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <Settings />
        </AdminPage>
    )
}
