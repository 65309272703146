import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Stack, EmptyState, Modal, Card, InlineError } from '@shopify/polaris';
import { DateRange } from '../../../pages/Dashboard/useDateRange';
import CombinedSelector from '../../Selectors/CombinedSelector';
import useExportFormData, { EXPORT_STATUS } from '../../../hooks/useExportFormData';
import { useShopProvider } from '../../ShopProvider';

export default function ExportFormDataModal({ open, onClose }) {

    const { shopOrigin } = useShopProvider();
    const [selectedRange, setSelectedRange] = useState(DateRange.last30);
    const { exportError, exportFormData, exportStatus, isExportInProgress } = useExportFormData(
        shopOrigin,
        {
            range: selectedRange,
        }
    )

    const onChangeRange = (range) => {
        setSelectedRange(range)
    }

    useEffect(() => {
        if (exportStatus === EXPORT_STATUS.SUCCEED) {
            onClose();
        }
    }, [exportStatus])


    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Export client scheduling form data'
            primaryAction={{
                content: 'Export',
                onAction: exportFormData,
                disabled: isExportInProgress,
                loading: isExportInProgress,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: onClose,
                    disabled: isExportInProgress,
                },
            ]}
        >
            <Modal.Section>
                <Stack vertical>
                    <Stack spacing='extraLoose' alignment='center'>
                        <CombinedSelector
                            selectedRange={selectedRange}
                            onChangeRange={onChangeRange}
                            distribution={null}
                        />
                        <Stack spacing='extraTight'>
                            <p>From:</p>
                            <p>{selectedRange.start.format('L')}</p>
                        </Stack>
                        <Stack spacing='extraTight'>
                            <p>To:</p>
                            <p>{selectedRange.end.format('L')}</p>
                        </Stack>
                    </Stack>
                    <Stack>
                        {exportError && <InlineError message={exportError.message} />}
                        {exportStatus === EXPORT_STATUS.NO_DATA && <p>No form data found in this range</p>}
                        {exportStatus === EXPORT_STATUS.SUCCEED && <p>Export Complete</p>}
                    </Stack>
                </Stack>
            </Modal.Section>
        </Modal>
    )
}