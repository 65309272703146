import { useEffect, useState } from 'react';
import { VIDEO_STATUS } from '../../../../../constants/story';

const useStoryVideos = (story) => {
  const [videos, setVideos] = useState([])

  useEffect(() => {
    if (story) {
      setVideos(story.videos)
    } else {
      setVideos([])
    }
  }, [story])

  const onAddVideo = (video) => {
    if (videos.some(({id}) => id === video.id)) {
      setVideos(prev => prev.map((storyVideo) => {
        if (storyVideo.id === video.id) {
          return story?.videos?.find(({id}) => video.id === id)
        }
        return storyVideo
      }))
    } else {
      setVideos(prev => [...prev, {
        id: video.id,
        data: {
          ...video.data,
          status: VIDEO_STATUS.UNPUBLISHED
        }
      }])
    }
  }

  const onRemoveVideo = (id) => {
    if (story?.videos?.some(video => video.id === id)) {
      setVideos(prev => prev.map((video) => {
        if (video.id === id) {
          return ({
            id: video.id,
            data: {
              ...video.data,
              status: VIDEO_STATUS.DELETED
            }
          })
        }
        return video
      }))
    } else {
      setVideos(prev => prev.filter(video => video.id !== id))
    }
  }

  const onChangeVideosOrder = (videos) => {
    setVideos(videos)
  }

  const resetVideos = () => {
    setVideos([])
  }

  return ({
    videos,
    onAddVideo,
    onRemoveVideo,
    onChangeVideosOrder,
    resetVideos
  })
}

export default useStoryVideos;
