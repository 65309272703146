import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Stack, Heading, Spinner, TextStyle ,InlineError, TextContainer } from '@shopify/polaris';

import { useShopProvider } from '../../components/ShopProvider';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import PricingPlan from '../../components/PricingPlan'
import PromoCode from '../../components/PromoCode';
import useCaazamREST from '../../hooks/useCaazamREST'

import './index.css';

function SelectPlan() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [promoData, setPromoData] = useState(null);
    const [changeError, setChangeError] = useState(null);
    
    const { subData, pricingPlans, activePlansData, shopData } = useShopProvider();
    const { subPlanChange } = useCaazamREST();

    const currentPlanId = useMemo(()=> subData?.planId, [subData]);
    const isCustomPlan = useMemo(()=> subData?.planData?.custom, [subData]);
    const isLegacyPlan = useMemo(()=> currentPlanId && !activePlansData?.subscriptionsList?.find(sub => sub === currentPlanId),[currentPlanId, activePlansData]);
    const displayPlansList = !isCustomPlan && !isLegacyPlan;

    const promoCode = promoData?.code ?? null;
    const promoFreeTrialDays = promoData?.freeTrialDays ?? 0;
    const freeTrial = currentPlanId ? null : activePlansData.freeTrialDays + promoFreeTrialDays;

    const onSelectPlan = (planId) => {
        setLoading(true);
        setChangeError(null);
        subPlanChange({ planId, redirectUrl: window.location.origin + '/admin/subscription', promoCode })
            .then(confirmUrl => {
                window.location.assign(confirmUrl);
            })
            .catch(error => {
                console.error('subPlanChange failed', error);
                setLoading(false);
                setChangeError(error);
            })
    }

    const onValidPromoCode = (code, data) => {
        setPromoData({ code, ...data});
    }

    const headingMarkup = () => {
        if (isCustomPlan) {
            return (<Heading>Your are currently on a custom subscription plan</Heading>);
        } else if (isLegacyPlan){
            return (<Heading>Your are currently on a legacy subscription plan</Heading>);
        } else  {
            return (<Heading>Pick a plan that's right for your store:</Heading>);
        }
    }

    return (
        <AppPage title='Select plan' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.Section>
                    <Stack vertical>
                        {headingMarkup()}
                        {!currentPlanId && <PromoCode onValidCode={onValidPromoCode}/>}
                        { loading && <Stack vertical alignment='center'>
                            <Heading><TextStyle variation='subdued'>Please wait...</TextStyle> </Heading>
                            <Spinner />
                        </Stack>}
                        {changeError && <InlineError message={changeError.reason || changeError.message} />}
                    </Stack>
                    {displayPlansList 
                    ? <div className='pricing-grid'>
                        {pricingPlans.filter(plan => !plan.isFree).map(plan =>
                            <PricingPlan
                                key={plan.id}
                                planData={{ ...plan, freeTrial }}
                                currentPlan={plan.id === currentPlanId}
                                onSelectPlan={onSelectPlan}
                                loading={loading}
                                CTA='Choose this plan'
                            />)}
                    </div>
                    : <div className='pricing-message'>
                        <p>To change your plan, please <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=${shopData.name} - change ${isCustomPlan ? 'custom' : 'legacy'} subscription plan`} target='_blank' rel="noopener noreferrer">contact us</a>. </p>
                    </div>
                    }
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <SelectPlan/>
        </AdminPage>
    )
}