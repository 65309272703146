import React, { useState } from 'react';
import { Modal, TextContainer, TextStyle } from '@shopify/polaris';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function DeleteEventType({ open, onClose, shopOrigin, eventTypeId, eventTypeName }) {
    const [deleteWorking, setDeleteWorking] = useState(false)

    async function deleteEventType(id) {
        setDeleteWorking(true);
        await Firebase.firestore().collection('shops').doc(shopOrigin).collection('scheduleEventTypes').doc(eventTypeId).delete();
        onClose(true);
        setDeleteWorking(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={`Delete Event Type`}
            primaryAction={{
                content: 'Delete',
                onAction: () => deleteEventType(eventTypeId),
                destructive: true,
                loading: deleteWorking,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: () => onClose(false),
                    disabled: deleteWorking,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer spacing='tight'>
                    <p>Are you sure you want to delete <TextStyle variation='strong'>{eventTypeName}</TextStyle></p>
                    <p>Existing appointments will remain on your store's calendar</p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    )
}
