
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useTemplate(templateId, locale) {

    const ref = templateId && locale ? Firebase.firestore().collection('templates').doc(templateId).collection('locales').doc(locale) : null;
    return useDocumentData(ref);

}
