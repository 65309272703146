import Moment from "moment-timezone";
import React from "react";

const CustomizedDateAxisTick = (props) => {
  const {x, y, payload} = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform={`rotate(-35)`}>
        {Moment(payload.value).format('ll')}
      </text>
    </g>
  );
}

export default CustomizedDateAxisTick;
