import React from 'react';
import {Stack, SkeletonDisplayText, SkeletonThumbnail, Grid} from "@shopify/polaris";

const ProductSkeleton = () => {
  return (
    <div style={{padding: '1rem'}}>
      <Stack vertical={true} spacing={'baseTight'}>
        <Grid columns={{xs: 10, sm: 10, md: 10, lg: 10, xl: 10}}>
          <SkeletonThumbnail size="large" />
          <SkeletonDisplayText size="small" />
        </Grid>
        <Grid columns={{xs: 10, sm: 10, md: 10, lg: 10, xl: 10}}>
          <SkeletonThumbnail size="large" />
          <SkeletonDisplayText size="small" />
        </Grid>
      </Stack>
    </div>
  )
}

export default ProductSkeleton;