import React, { useState, useCallback, useMemo, useEffect } from 'react';

import {
    Stack,
    TextField,
    FormLayout,
    Modal,
    InlineError,
    TextContainer,
} from '@shopify/polaris';

import './styles.css';
import { isEmailValid } from "../../../utils/strings";
import { useBlacklistActions } from '../../../hooks/useBlacklist';
import BannedClientsDescription from "./description";

export default function AddBannedModal({ shopId, open, onClose }) {

    const [clientEmail, setClientEmail] = useState(null);
    const [clientEmailError, setClientEmailError] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [note, addNote] = useState(null);
    const [loading, setLoading] = useState(false);

    const { addToBlacklist } = useBlacklistActions(shopId);

    useEffect(() => {
        setClientEmail(null);
        addNote(null);
    }, [open])

    const onClientEmail = (email) => {
        setClientEmailError(null);
        setApiError(null);
        setClientEmail(email);
    }
    const validateEmail = () => {
        console.log('validateEmail', clientEmail)
        if (!isEmailValid(clientEmail)) {
            setClientEmailError(new Error('Please enter a valid email'));
            return false;
        } else {
            setClientEmailError(null);
            return true;
        }
    }

    const addToBanHandler = async () => {
        if (!validateEmail()) return;

        try {
            setLoading(true);
            await addToBlacklist(clientEmail.toLowerCase().trim(), note);
            onClose();
        } catch (error) {
            setApiError(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Add banned client'
            primaryAction={{ content: 'Ban', onAction: addToBanHandler, loading, disabled: !clientEmail || !!clientEmailError, destructive: true }}
            secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
        >
            <Modal.Section>
                <BannedClientsDescription/>
            </Modal.Section>
            <Modal.Section>
                <FormLayout>
                    <TextField
                        label='Client email'
                        value={clientEmail}
                        onChange={onClientEmail}
                        onBlur={validateEmail}
                        autoComplete="off"
                        type="email"
                        error={clientEmailError?.message}
                    />
                    <TextField
                        label='Note'
                        value={note}
                        onChange={addNote}
                        autoComplete="off"
                        multiline={4}
                    />
                    <InlineError message={apiError?.message} />
                </FormLayout>
            </Modal.Section>
        </Modal>
    )

}
