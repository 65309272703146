import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { Layout, Stack, Card, InlineError, TextField } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage';
import useCaazamREST from '../../hooks/useCaazamREST';

import { useShopProvider } from '../../components/ShopProvider';
import CallLinkPolicyConfig from './customPolicy';
import SelectHost, { SELECT_ALL_HOSTS } from '../../components/SelectHost';



function CreateLink() {

    const history = useHistory();
    const { hosts, schedulingConfig } = useShopProvider();

    const { shopLinkCreate } = useCaazamREST();
    const [formError, setFormError] = useState(null);
    const [options, setOptions] = useState({});
    const [selectedHost, selectedHostSet] = useState(SELECT_ALL_HOSTS.value);
    const [description, setDescription] = useState('');

    const [working, setWorking] = useState(false);

    useEffect(() => {
        if (options?.disableIncoming && options?.disableScheduling) {
            setFormError(new Error('call link must be allowed at least one action'));
        }

    }, [options]);

    const closeForm = () => {
        history.goBack();
    }

    const handleSubmit = async () => {

        setWorking(true);
        setFormError(null);

        const linkOptions = {
            showIntro: true,
            ...options
        }

        try {
            const hostId = selectedHost !== SELECT_ALL_HOSTS.value ? selectedHost : null;
            await shopLinkCreate(hostId, linkOptions, !!description ? description : null);
            Firebase.analytics().logEvent('admin.call_link_created');
            closeForm();
        } catch (error) {
            console.error('call link createfailed', error);
            setFormError(error);
        } finally {
            setWorking(false);
        }
    }

    return (
        <AppPage title='Create Link' breadcrumbs={[{ content: 'Back', onAction: closeForm }]} >
            <Layout>
                <Layout.AnnotatedSection
                    title='Call Links'
                    description='Clients can use a link to start a video call or schedule an appointment'
                >
                    <Card
                        sectioned
                        secondaryFooterActions={[{ content: 'Cancel', onAction: closeForm }]}
                        primaryFooterAction={{ content: 'Create', onAction: handleSubmit, disabled: !!formError, loading: working }}
                    >
                        <Stack vertical>
                        <TextField
                                label='Description'
                                value={description}
                                onChange={setDescription}
                                placeholder='Describe how/where/when is this link to be used'
                            />
                            <SelectHost hosts={hosts} label='Create link for' selecetedHost={selectedHost} onHostChange={selectedHostSet} />
                            <CallLinkPolicyConfig
                                schedulingConfig={schedulingConfig}
                                options={options}
                                setOptions={setOptions}
                            />
                            <div style={{ marginTop: '4px' }}>
                                <InlineError message={formError && formError.message} />
                            </div>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <CreateLink />
        </AdminPage>
    )
}
