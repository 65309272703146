import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";

const useTimezones = () => {
  const ref = firebase.firestore().collection('timezones').orderBy('offset', 'desc')

  const [firebaseTimezones, loadTimezones] = useCollectionDataOnce(ref)

  return ({
    firebaseTimezones,
    loadTimezones
  })
}

export default useTimezones;
