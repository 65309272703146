import React, { useEffect, useState, useCallback } from 'react';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import CopyToClipboard from 'react-copy-to-clipboard';

import useShopCalendarEvent from '../../hooks/useShopCalendarEvent';

import { useShopProvider } from '../../components/ShopProvider';
import { Stack, TextStyle, Button, Modal, Subheading,
    ButtonGroup, Tag, TextField, InlineError, SkeletonBodyText,
    Checkbox, Popover, ActionList,
Collapsible } from '@shopify/polaris';
import { DuplicateMinor,
    ChevronDownMinor,
    ChevronUpMinor, } from '@shopify/polaris-icons';

import useScheduleHistory from '../../hooks/useScheduleHistory';
import useScheduleEmailEvents from '../../hooks/useScheduleEmailEvents';
import { ScheduleStatus } from '../../utils/consts';
import { CustomSelect } from './components/CustomSelect/CustomSelect';
import EventTypeBadge from '../../components/eventTypeBadge';
import moment from 'moment-timezone';
import { instanceDuration } from './utils';
import { NotificationTypes } from '../Settings/Notification';
import './event.scss'

function formatEventDate(date) {
    return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'long',
        timeStyle: 'short',
    }).format(date);
}

function formatShortEventDate(date) {
    return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'medium',
        timeStyle: 'short',
    }).format(date);
}

export default function CaazamEvent({ open, onClose, data, isHostFreeForEvent, rescheduleAppointment }) {

    const { hosts, shopOrigin } = useShopProvider();
    const { cancelScheduledCall, unassignCall, assignCall } = useShopCalendarEvent(shopOrigin);
    const [isReverting, setIsReverting] = useState(false);

    const [showHistory, setShowHistory] = useState(false);
    const { scheduleHistory, scheduleHistoryLoading } = useScheduleHistory(shopOrigin, data?.id);

    const [showEmailEvents, setShowEmailEvents] = useState(false);
    const { scheduleEmailEvents, scheduleEmailEventsLoading } = useScheduleEmailEvents(shopOrigin, data?.id);

    // cancel appointment states
    const [isDeleting, setIsDeleting] = useState(false);
    const [clientNoteOnDelete, setClientNoteOnDelete] = useState(null)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [noShow, setNoShow] = useState(false);
    const [sendCancelEmail, setSendClientEmail] = useState(true);

    // request rescehdule appointment states
    const [isRecheduling, setIsRescheduling] = useState(false);
    const [isReschedulingLoading, setIsReschedulingLoading] = useState(false);
    const [rechedulingNote, setReschedulingNote] = useState('');
    const [reschedulingError, setReschedulingError] = useState(null);

    // assign states
    const [isAssignAction, setAssignAction] = useState(false);
    const [isAssignConfirmed, setIsAssignConfirmed] = useState(false);
    const [selectedHost, setSelectedHost] = useState(null);
    const [selectHostOptionsList, setSelectHostOptionsList] = useState([])

    useEffect(() => {
        if (!open) {
            setIsReverting(false);
            setAssignAction(false);
            setIsDeleting(false);
            setIsRescheduling(false);
            setShowHistory(false);
            setShowEmailEvents(false);
        }
    }, [open]);

    useEffect(() => {
        if (!isRecheduling) {
            setIsReschedulingLoading(false);
            setReschedulingNote('');
            setReschedulingError(null);
        }
    }, [isRecheduling]);

    useEffect(() => {
        if (!isDeleting) {
            setIsDeleteLoading(false);
            setClientNoteOnDelete(null);
            setDeleteError(null);
            setNoShow(false);
            setSendClientEmail(true);

        }
    }, [isDeleting]);

    useEffect(() => {
        if (data) {
            let availableHosts = hosts.filter(host => host.id !== data.hostId).map(host => (
                {
                    content: `${host.firstName} ${host.lastName}`,
                    avatar: host.avatarUrl,
                    initials: `${host.firstName[0]}${host.lastName[0]}`,
                    id: host.id,
                    alert: !isHostFreeForEvent(host.id, data),
                })).sort((a, b) => a.alert === b.alert ? 0 : a.alert ? 1 : -1);
            setSelectHostOptionsList(availableHosts);
        }
        return () => setSelectHostOptionsList[0];
    }, [data]);

    const [reschedulePopoverActive, setReschedulePopoverActive] = useState(false);

    const toggleReschedulePopoverActive = () => setReschedulePopoverActive(!reschedulePopoverActive);

    const rescheduleActivator = (
        <Button onClick={toggleReschedulePopoverActive} plain>
          Reschedule
        </Button>
      )

    if (!data || !open) return null;
    const hostData = data.hostId && hosts.find(host => host.id === data.hostId);
    const hostName = hostData ? hostData.firstName + ' ' + hostData.lastName : data.hostId;
    const hostEmail = hostData && hostData.email;

    const isCompleted = data.status === ScheduleStatus.completed;

    const isCanceled = data.status === ScheduleStatus.deleted;

    const revertAction = async () => {
        setIsReverting(true);
        try {
            await unassignCall(data.id);
        } catch (error) {
            console.error('revertAction', error);
        } finally {
            setIsReverting(false);
            onClose();
        }
    }

    const assignAction = () => {
        setAssignAction(true);
        const targetHost = data.hostId ? data.hostId : selectHostOptionsList[0].id;
        setSelectedHost(targetHost);
    }

    const assignCancelAction = () => {
        setAssignAction(false);
        setSelectedHost(data.hostId);
    }

    const assignConfirmAction = async () => {
        setIsAssignConfirmed(true);
        try {
            if (data.hostId !== selectedHost) {
                await assignCall(data.id, selectedHost)
            }
        } catch (error) {
            console.error('assignAction', error);
        } finally {
            setIsAssignConfirmed(false);
            onClose();
        }
    }

    const deleteAction = async () => {
        setIsDeleting(true);
    }

    const deleteCancelAction = async () => {
        setIsDeleting(false);
    }

    const deleteConfirmAction = async () => {
        setIsDeleteLoading(true);
        setDeleteError(null);
        try {
            await cancelScheduledCall(data.id, { message: clientNoteOnDelete, noShow, disableClientEmail: !sendCancelEmail })
        } catch (error) {
            console.error('deleteConfirmAction', error);
            setDeleteError(error);
        } finally {
            setIsDeleteLoading(false);
            onClose();
        }
    }

    const onHostUpdate = (e) => {
        setSelectedHost(e)
    }

    const requestReschedule = async () => {
        setIsReschedulingLoading(true);
        setReschedulingError(null);
        const request = Firebase.functions().httpsCallable('schedule-sendClientRequestChange');
        try {
            await request({ shopId: shopOrigin, scheduleId: data.id, note: rechedulingNote, admin: true });
            setIsRescheduling(false);
        } catch (error) {
            setReschedulingError(error);
            console.error(`Failed requesting reschedule`, error);
        } finally {
            setIsReschedulingLoading(false);

        }
    }

    const onSubmit = async () => {
        if (isAssignAction) {
            await assignConfirmAction();
        }
        onClose()
        setSelectHostOptionsList([])
    }

    const renderActionButtons = () => {
        if (isDeleting) {
            return (
                <Stack distribution='trailing' alignment='center'>
                    <ButtonGroup>
                        <Button onClick={deleteCancelAction} disabled={isDeleteLoading}>Don't Cancel</Button>
                        <Button destructive onClick={deleteConfirmAction} loading={isDeleteLoading}>Cancel Appointment</Button>
                    </ButtonGroup>
                </Stack>)
        } else if (isRecheduling) {
            return (
                <Stack distribution='trailing' alignment='center'>
                    <ButtonGroup>
                        <Button onClick={() => setIsRescheduling(false)} disabled={isReschedulingLoading}>Cancel</Button>
                        <Button primary onClick={requestReschedule} loading={isReschedulingLoading}>Send</Button>
                    </ButtonGroup>
                </Stack>)
        } else {
            return (
                <Stack>
                    {!isCanceled && !isCompleted && !isAssignAction && <Button onClick={deleteAction} destructive outline>Cancel Appointment</Button>}
                    <Stack.Item fill>
                        <Stack distribution='trailing'>
                            <ButtonGroup>
                                {isAssignAction && <Button onClick={assignCancelAction}>Cancel</Button>}
                                <Button primary onClick={onSubmit} loading={isAssignConfirmed}>OK</Button>
                            </ButtonGroup>
                        </Stack>
                    </Stack.Item>
                </Stack>)
        }
    }

    const formatHistory = (data) => {

        function by() {
            switch (data?.updatedBy?.role) {
                case 'admin': return 'by admin';
                case 'host': return 'by host';
                case 'participant': return 'by client';
                default: return '';
            }
        }

        function duration() {
            if (data?.endTime) {
                return ` (${instanceDuration(moment(data.startTime), moment(data.endTime))} min)`;
            } else {
                return '';
            }

        }

        function event() {
            switch (data?.event) {
                case 'created': return 'Created';
                case 'start_changed': return `Start time changed to ${formatShortEventDate(new Date(data?.startTime))} ${duration()}`;
                case 'state_changed': return `Changed to ${data.state}`;
                case 'host_changed': return `Host changed`;
                case 'request_reschedule': return 'Requested client reschedule';
                case 'cancelled':  return 'Cancelled'; 
                case 'no_show': return 'No show'; 
                case 'completed': return 'Call completed'; 
                default: return ' ';
            }
        }

        return (<p>{event()} <TextStyle variation='subdued'>{by()}</TextStyle></p>)
    }

    const renderHistory = () => {
        return (
            <Stack vertical spacing='extraTight'>
                <Stack>
                    {isCompleted && <p>Completed at <TextStyle variation='subdued'>{formatShortEventDate(data?.completedAt.toDate())}</TextStyle></p>}
                    <Button plain icon={showHistory ? ChevronUpMinor : ChevronDownMinor} onClick={() => setShowHistory(prev => !prev)}>{showHistory ? 'Hide' : 'Show'} history</Button>
                </Stack>
                <Collapsible open={showHistory} id='scheduleHistory'>
                    {!scheduleHistory && <SkeletonBodyText lines={2} />}
                    {scheduleHistory?.docs.map(doc => {
                        let data = doc.data();
                        return (
                            <Stack key={doc.id}>
                                <Stack.Item fill>{formatHistory(data)}</Stack.Item>
                                <p><TextStyle variation='subdued'>{formatShortEventDate(new Date(data.timestamp))}</TextStyle></p>
                            </Stack>
                        )
                    })}
                </Collapsible>
            </Stack>);
    }

    const formatEmailEvent = (data) => {

        function lastEvent() {
            if (!data.events) return null;
            const events = Object.values(data.events)
                .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())
                .filter(e => e.event !== 'processed')
            let event = events[0];
            switch (event.event) {
                case 'dropped':
                    return `${event.event} - ${event.reason}`;
                case 'deferred':
                    return `${event.event} - attempts:${event.attempt}`;
                case 'bounce':
                    {
                        if (event.type === 'bounce')
                            return `bounce - ${event.reason}`;
                        if (event.type === 'blocked')
                            return `blocked - ${event.reason}`;
                    }
                default:
                    return `${event.event}`;
            }
        }

        function engagement() {
            if (!data.engagement) return null;
            let stats = '';
            if (data.engagement.open != null) {
                stats +=`open:${data.engagement.open}`
            }
            if (data.engagement.click != null) {
                stats +=` click:${data.engagement.click}`
            }
            return `(${stats})`
        }

        return (<p>{NotificationTypes[data.templateId].name} <TextStyle variation='subdued'>{lastEvent()} {engagement()}</TextStyle></p>)
    }

    const renderEmailEvents = () => {
        return (
            <Stack vertical spacing='extraTight'>
                <Stack>
                    <Button plain icon={showEmailEvents ? ChevronUpMinor : ChevronDownMinor} onClick={() => setShowEmailEvents(prev => !prev)}>{showEmailEvents ? 'Hide' : 'Show'} email events</Button>
                </Stack>
                <Collapsible open={showEmailEvents} id='showEmailEvents'>
                    {!scheduleEmailEvents && <SkeletonBodyText lines={2} />}
                    {scheduleEmailEvents?.docs
                        .filter(doc => Object.keys(NotificationTypes).includes(doc.data().templateId))
                        .map(doc => {
                            let data = doc.data();
                            return (
                                <Stack key={doc.id} wrap={false}>
                                    <Stack.Item fill>{formatEmailEvent(data)}</Stack.Item>
                                    <p><TextStyle variation='subdued'>{formatShortEventDate(new Date(data.sentAt.toDate()))}</TextStyle></p>
                                </Stack>
                            )
                        })
                    }
                </Collapsible>
            </Stack>
        );
    }

    const renderDetails = () => {

        if (isRecheduling) {
            return <Stack vertical>
                <p>A rescheduling request email will be sent to the client's email address</p>
                <TextField
                    multiline={3}
                    placeholder="Add a personal note"
                    value={rechedulingNote}
                    onChange={setReschedulingNote}
                />
                {reschedulingError
                    ? <InlineError message={reschedulingError.message} />
                    : ' '
                }
            </Stack>
        }

        if (isDeleting) {
            return <Stack vertical spacing='tight'>
                <p><TextStyle variation='strong'>Are you sure you want to cancel this scheduled call?</TextStyle></p>
                <Checkbox
                    label="Mark as no show"
                    checked={noShow}
                    onChange={setNoShow}
                />
                <Checkbox
                    label="Send cancellation email to client"
                    checked={sendCancelEmail}
                    onChange={setSendClientEmail}
                />
                <TextField
                    multiline={3}
                    placeholder="Note to client (optional)"
                    value={clientNoteOnDelete}
                    onChange={setClientNoteOnDelete}
                    disabled={!sendCancelEmail}
                />
                {deleteError
                    ? <InlineError message={deleteError.message} />
                    : ' '
                }
            </Stack>
        }

        return (
            <Stack vertical spacing='tight'>
                <Subheading>Call details</Subheading>
                {data.eventType?.name && <EventTypeBadge name={data.eventType.name} color={data.eventType.color} />}
                <>
                    {!isAssignAction &&
                        <>
                            {data?.hostId
                                ?
                                <Stack alignment='center'>
                                    <Stack>
                                        <p>Host:</p>
                                        <p>{hostName}</p>
                                        <p>{hostEmail}</p>
                                    </Stack>
                                    {data?.status === ScheduleStatus.assigned &&
                                        <Stack>
                                            {hosts.length > 1 && <Button plain onClick={assignAction}>Reassign</Button>}
                                            <Button plain onClick={revertAction} loading={isReverting}>Unassign</Button>
                                        </Stack>}
                                </Stack>
                                : <Stack alignment='center'>
                                    <p>Waiting for host assignment</p>
                                    {data?.status === ScheduleStatus.pending &&
                                        <Button plain onClick={assignAction}>Assign</Button>
                                    }
                                </Stack>
                            }
                        </>
                    }
                    {isAssignAction && <>
                        <Stack alignment='center'>
                            <p><TextStyle>Choose host: </TextStyle></p>
                            <CustomSelect
                                availableHosts={selectHostOptionsList}
                                selectedHost={selectedHost}
                                onChangeHost={onHostUpdate} />
                        </Stack>
                    </>}
                </>
                {!isCompleted && !isCanceled &&
                    <Stack alignment='center'>
                        <p>Client call link:</p>
                        <Stack.Item fill>
                            <TextField
                                value={data?.callLinkUrl}
                                disabled={true}
                                connectedRight={<CopyToClipboard text={data?.callLinkUrl}>
                                    <Button icon={DuplicateMinor} />
                                </CopyToClipboard>}
                            />
                        </Stack.Item>
                    </Stack>
                }
                {renderHistory()}
            </Stack>
        )
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={
                <Stack vertical>
                    <Stack>
                        <p>Call with <TextStyle variation='strong'>{data.customer.name}</TextStyle></p>
                        {isCompleted && <Tag>completed</Tag>}
                        {isCanceled && <div className='canceled-tag'><Tag>canceled</Tag></div>}
                    </Stack>
                </Stack>
            }
        >
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <Subheading>Date and time </Subheading>
                    <Stack alignment='center'>
                        <TextStyle variation='strong'>{formatEventDate(data.startTimestamp.toDate())}</TextStyle>
                        {!isCanceled && !isCompleted && !isRecheduling &&
                            <Popover
                                active={reschedulePopoverActive}
                                activator={rescheduleActivator}
                                autofocusTarget="first-node"
                                onClose={toggleReschedulePopoverActive}
                            >
                                <ActionList
                                    actionRole="menuitem"
                                    items={[
                                        { content: 'Request client', onAction: () => { setIsRescheduling(true); setReschedulePopoverActive(false); } },
                                        {
                                            content: 'Reschedule yourself', onAction: () => { rescheduleAppointment(); setReschedulePopoverActive(false); }
                                        }]}
                                />
                            </Popover>
                        }
                    </Stack>
                    <TextStyle>{instanceDuration(moment(data.startTimestamp.toDate()), moment(data.endTimestamp.toDate()))} minutes</TextStyle>
                </Stack>
            </Modal.Section>
            <Modal.Section>
                <Stack vertical spacing='tight'>
                    <Subheading>Client details</Subheading>
                    <p>{data?.customer?.name}</p>
                    <p>{data?.customer?.email}</p>
                    {data?.customer?.phone && <p>{data.customer.phone}</p>}
                    {renderEmailEvents()}
                </Stack>
            </Modal.Section>
            <Modal.Section>
                {renderDetails()}
            </Modal.Section>
            {<Modal.Section>
                {renderActionButtons()}
            </Modal.Section>}
        </Modal>
    )
}
