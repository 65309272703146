import React from "react";
import { TextContainer } from "@shopify/polaris";

export default function BannedClientsDescription() {
  return (
    <>      
        <TextContainer spacing='tight'>
          <p>
            Banning a client's email will prevent them from scheduling appointments or making calls.
          </p>
          <p>
            Any existing scheduled appointment for this email address will be automatically cancelled.
          </p>
          <p>
            It will also prevent Boutiq from sending messages to their email address.
          </p>
        </TextContainer>
    </>
  )
}
