import { Modal } from '@shopify/polaris';
import React from 'react';

const DeleteStoryModal = ({open, onClose, onDelete, title, subTitle}) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={title}
      primaryAction={{
        content: 'Delete',
        destructive: true,
        onAction: onDelete,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}>
      <Modal.Section>
        <p>{subTitle}</p>
      </Modal.Section>
    </Modal>
  )
}

export default DeleteStoryModal;
