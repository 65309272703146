import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { Layout, Card, Stack, TextStyle, Checkbox, TextField, SkeletonBodyText, Banner, Select } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useShopProvider } from '../../components/ShopProvider';
import useShopDraftOrdersConfig from '../../hooks/useShopDraftOrdersConfig';
import useCaazamREST from '../../hooks/useCaazamREST';
import UpgradeBanner from '../../components/UpgradeBanner';

const DEFAULT_CONFIG = {
    searchOnlyInInventory: true,
}

export function ProductsSettingsSummary({ productsConfig }) {

    const config = Object.assign({}, DEFAULT_CONFIG, productsConfig);
    const searchOnlyInInventory = config.searchOnlyInInventory;
    const searchProductTag = config.searchProductTag;
    const searchIncludeUnpublished = config.searchIncludeUnpublished;

    const renderSearchSummary = () => {
        const productsString = !searchIncludeUnpublished ? 'published products' : 'all products'
        const byTitle = config?.searchByTitle ? <TextStyle variation='strong'>by title only</TextStyle> : null;
        if (!searchOnlyInInventory && !searchProductTag)
            return <p>Search {byTitle} for <TextStyle variation='strong'>{productsString}</TextStyle></p>
        else if (searchOnlyInInventory && !searchProductTag)
            return <p>Search {byTitle} for <TextStyle variation='strong'>{productsString} with inventory</TextStyle> only</p>
        else if (!searchOnlyInInventory && searchProductTag)
            return <p>Search {byTitle} for <TextStyle variation='strong'>{productsString} with tag</TextStyle> "{searchProductTag[0]}"</p>
        else
            return <p>Search {byTitle} for <TextStyle variation='strong'>{productsString} with inventory and tag</TextStyle> "{searchProductTag[0]}"</p>
    }

    const renderTitleSearchSummary = () => {
        if (config?.searchByTitle && config?.searchByTitleExclusions) {
            return <p>Exluding the keywords <TextStyle variation='strong'>{config?.searchByTitleExclusions.join(', ')}</TextStyle></p>;
        }
        return null;
    }

    return <Stack vertical spacing='extraTight'>
        {renderSearchSummary()}
        {renderTitleSearchSummary()}
    </Stack>
}

function ProductsSettings() {
    const history = useHistory();
    const { productsConfig, productsConfigLoading, shopOrigin, shopData } = useShopProvider();

    const [searchOnlyInInventory, setOnlyInInventory] = useState(true);
    const [searchProductTag, setSearchProductTag] = useState(false);
    const [searchIncludeUnpublished, setSearchIncludeUnpublished] = useState(false);
    const [searchByTitle, setSearchByTitle] = useState(false);
    const [searchByTitleExclusions, setSearchByTitleExclusions] = useState(null);
    const { draftOrderConfig, draftOrderConfigLoading } = useShopDraftOrdersConfig(shopOrigin);
    const [inventoryLocations, setInventoryLocations] = useState([]);
    const [inventoryLocationsLoading, setInventoryLocationsLoading] = useState(true);
    const [onlyInInventoryLocation, setOnlyInInventoryLocation] = useState(null);

    const [supportsInvenotry, setSupportsInventory] = useState(false);
    const [supportsLocations, setSupportsLocations] = useState(false);
    const { getLocations } = useCaazamREST();

    const [tag, setTag] = useState(null);

    useEffect(() => {
        setInventoryLocationsLoading(true);
        getLocations()
            .then(res => setInventoryLocations(res.locations.filter(l => l.fulfillsOnlineOrders)))
            .catch(error => console.error('getLocations', error))
            .finally(() => setInventoryLocationsLoading(false));
    }, []);

    useEffect(() => {
        if (shopData) {
            let { productPolicy } = shopData;
            setSupportsInventory(!!productPolicy.supportsInventory);
            setSupportsLocations(!!productPolicy.supportsLocations);
        }
    }, [shopData?.productPolicy])

    useEffect(() => {
        if (productsConfig) {
            const config = Object.assign({}, DEFAULT_CONFIG, productsConfig);
            setOnlyInInventory(config.searchOnlyInInventory);
            setSearchProductTag(!!config.searchProductTag);
            setSearchIncludeUnpublished(config.searchIncludeUnpublished);
            setSearchByTitle(config.searchByTitle);
            setOnlyInInventoryLocation(config.onlyInInventoryLocation);
            if (!!config.searchProductTag) {
                setTag(config.searchProductTag[0]);
            }
            setSearchByTitleExclusions(config.searchByTitleExclusions?.join(',') ?? null);
        }

    }, [productsConfig])

    const onSearchByTitleChange = (newChecked) => {
        setSearchByTitle(newChecked);
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
            .doc('products').set({ searchByTitle: newChecked }, { merge: true })
            .catch(error => console.error('onSearchByTitleChange', error));
    }


    const onInventoryChange = (newChecked) => {
        setOnlyInInventory(newChecked);
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
            .doc('products').set({ searchOnlyInInventory: newChecked }, { merge: true })
            .catch(error => console.error('onInventoryChange', error));
    }

    const onPublishedChange = (newChecked) => {
        setSearchIncludeUnpublished(newChecked);
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
            .doc('products').set({ searchIncludeUnpublished: newChecked }, { merge: true })
            .catch(error => console.error('onPublishedChange', error));
    }

    const onTagChange = (newChecked) => {
        setSearchProductTag(newChecked);
        if (!newChecked) {
            Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
                .doc('products').set({ searchProductTag: Firebase.firestore.FieldValue.delete() }, { merge: true })
                .catch(error => console.error('onTagChange', error));
        }
    }

    const onTagSubmit = () => {
        if (tag) {
            Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
                .doc('products').set({ searchProductTag: [tag] }, { merge: true })
                .catch(error => console.error('onTagSubmit', error));
        }
    }

    const onTitleExclusionsSubmit = () => {
        let val = !!searchByTitleExclusions?.trim()
            ? searchByTitleExclusions.split(',').map(w => w.trim())
            : Firebase.firestore.FieldValue.delete();
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
            .doc('products').set({ searchByTitleExclusions: val }, { merge: true })
            .catch(error => console.error('onTitleExclusionsSubmit', error));

    }

    const onInventoryLocationChange = (value) => {
        let newLocation = value === 'all' ? null : value;
        setOnlyInInventoryLocation(newLocation);
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
            .doc('products').set({ onlyInInventoryLocation: newLocation ?? Firebase.firestore.FieldValue.delete() }, { merge: true })
            .catch(error => console.error('onInventoryLocationChange', error));
    }


    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Product Settings'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned title='Product Search'>
                        <Card.Section>
                            {productsConfigLoading && <SkeletonBodyText />}
                            {!productsConfigLoading && <Stack vertical>
                                <Checkbox
                                    label='Include products with inventory only'
                                    checked={searchOnlyInInventory}
                                    onChange={onInventoryChange}
                                />
                                <Stack alignment='center'>
                                    <Checkbox
                                        label='Include products with tag'
                                        checked={searchProductTag}
                                        onChange={onTagChange}
                                    />
                                    <TextField
                                        disabled={!searchProductTag}
                                        placeholder='tag'
                                        value={tag}
                                        onChange={setTag}
                                        onBlur={onTagSubmit}
                                        error={searchProductTag && !tag && 'tag cannot be empty'}
                                    />

                                </Stack>
                                <Stack alignment='center'>
                                    <Checkbox
                                        label='Include unpublished products'
                                        checked={searchIncludeUnpublished}
                                        onChange={onPublishedChange}
                                    />
                                    {searchIncludeUnpublished && !draftOrderConfigLoading &&
                                        <p><TextStyle variation='subdued'>(Draft orders are {draftOrderConfig?.enabled ? 'enabled' : 'disabled'})</TextStyle></p>
                                    }
                                </Stack>
                                {searchIncludeUnpublished && !draftOrderConfigLoading && !draftOrderConfig?.enabled &&
                                    <Banner
                                        status='warning'
                                        secondaryAction={{ content: 'Go to draft order settings', url: '/hostSettings/draftOrders' }}
                                    >
                                        When unpublished products are allowed, it is recommended draft orders are enabled to allow purchases.
                                    </Banner>
                                }
                            </Stack>
                            }
                        </Card.Section>
                        <Card.Section>
                            {productsConfigLoading && <SkeletonBodyText />}
                            {!productsConfigLoading &&
                                <Stack vertical spacing='tight'>
                                    <Checkbox
                                        label='Search only product title'
                                        checked={searchByTitle}
                                        onChange={onSearchByTitleChange}
                                    />
                                    <TextField
                                        label='Exlcuding these keywords:'
                                        disabled={!searchByTitle}
                                        placeholder='keyword list separated by ","'
                                        value={searchByTitleExclusions}
                                        onChange={setSearchByTitleExclusions}
                                        onBlur={onTitleExclusionsSubmit}
                                    />
                                </Stack>
                            }
                        </Card.Section>
                    </Card>
                    <Card sectioned title='Inventory'>
                        <Card.Section>
                            {productsConfigLoading || inventoryLocationsLoading && <SkeletonBodyText />}
                            {!productsConfigLoading && !inventoryLocationsLoading &&
                                <>
                                    {supportsInvenotry && supportsLocations
                                        ?
                                        <Select
                                            label='Online store inventory location'
                                            disabled={inventoryLocationsLoading || inventoryLocations?.length <= 1}
                                            options={[{ label: 'All online store locations', value: 'all' }].concat(inventoryLocations.map(l => ({ label: l.name, value: l.id })))}
                                            onChange={onInventoryLocationChange}
                                            value={onlyInInventoryLocation}
                                            helpText="Set the location where Boutiq will check for products' inventory levels"
                                        />
                                        :
                                        <Stack vertical>
                                            <p>Set the inventory location where Boutiq will check for products' inventory levels</p>
                                            <UpgradeBanner content='Please upgrade the Boutiq app to add inventory permissions for your store' />
                                        </Stack>
                                    }
                                </>
                            }
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <ProductsSettings />
        </AdminPage>
    )
}
