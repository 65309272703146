import React from 'react';
import {Stack, SkeletonThumbnail} from '@shopify/polaris';
import './styles.css'

const VideoLibrarySkeleton = ({number = 2}) => {
  return (
    <Stack>
      {Array(2).fill(0).map((key) => (
        <div key={key} className={'video-skeleton-container'}>
          <SkeletonThumbnail size={'large'} />
        </div>
      ))}
    </Stack>
  )
}

export default VideoLibrarySkeleton;
