import React from 'react';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { useCollection } from 'react-firebase-hooks/firestore'

export const ScheduleBlockOffReason = {
    HostScheduled: 'host_scheduled',
    Manual: 'manual',
}

export default function useShopScheduleBlocks(shopId, startDate, endDate) {

	const scheduleRef = shopId
		? Firebase.firestore().collection('shops').doc(shopId).collection('shopScheduleBlocked')
			.where('startTimestamp', '>=', startDate)
			.where('startTimestamp', '<', endDate)
		: null;

	const [ scheduleBlocks, scheduleBlocksLoading, scheduleBlocksError ] = useCollection(scheduleRef);

	return {
        scheduleBlocks, scheduleBlocksLoading, scheduleBlocksError,
     };
}
