import React, { useState, useEffect } from 'react';
import { Card, Badge, TextStyle, TextField, Stack, Button, Modal, TextContainer, EmptyState } from '@shopify/polaris';
import Select, { components } from "react-select";
import { useShopProvider } from './ShopProvider';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { useAppAuthState } from '../authState';

const TeamItem = ({ team, openEditModal, openRemoveModal }) => {
    return (
        <div style={{ paddingBottom: '0.8rem' }}>
            <Stack>
                <Stack.Item fill>
                    <Stack distribution='leading' alignment="center">
                        <Stack.Item>
                            <h3>
                                <TextStyle variation="strong">{team.name}</TextStyle>
                            </h3>
                        </Stack.Item>
                        <Stack.Item distribution='leading'>
                            <TextStyle>{`(${team.members ? team.members.length : 0} members)`}</TextStyle>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack>
                    <Button plain destructive onClick={() => openEditModal(team)}>edit</Button>
                    <Button plain destructive onClick={() => openRemoveModal(team)}>delete</Button>
                </Stack>
            </Stack>
        </div>
    )
}

export const Teams = () => {
    const { hosts, shopUserTeams } = useShopProvider();
    const { shopOrigin } = useAppAuthState();
    const [errorBit, setErrorBit] = useState(true);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isInProgress, setIsInProgress] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [isAlreadyExist, setIsAlreadyExist] = useState(false);

    const defineTeamName = (id) => {
        if (!id) return null;
        const team = shopUserTeams.find(item => item.id === id);
        if (team) {
            return team.name;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (hosts) {
            setOptions(hosts.map(host => ({ value: host.id, label: `${host.firstName} ${host.lastName}`, teamName: defineTeamName(host.teamId) })))
        }
    }, [hosts])

    const createTeamAction = { content: 'Create Team', onAction: () => setAddModalOpen(true) }

    useEffect(() => {
        if (teamName) {
            setErrorBit(false);
        } else {
            setErrorBit(true);
        }
    }, [teamName])

    useEffect(() => {
        if (isAlreadyExist) {
            checkIsAlreadyExist();
        }
    }, [isAlreadyExist, teamName])

    const checkIsAlreadyExist = () => {
        let isTeamAlreadyExist;
        if (!selectedTeam) {
            isTeamAlreadyExist = shopUserTeams.find(team => team.name === teamName);
        } else {
            isTeamAlreadyExist = shopUserTeams.find(team => team.name === teamName && team.id !== selectedTeam.id);
        }
        setIsAlreadyExist(isTeamAlreadyExist);
        return isTeamAlreadyExist;
    }

    const removeTeam = async (team) => {
        setIsInProgress(true);
        const ref = Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUserTeams').doc(team.id);
        try {
            if (team.members && team.members.length > 0) {
                await Promise.all(
                    team.members.map(async hostId => await Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(hostId).update({
                        teamId: Firebase.firestore.FieldValue.delete(),
                        updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                    }).catch((error) => {
                        console.error('Failed to updating team', error);
                    })))
            }
            await ref.delete();
            resetStates();
        } catch (deleteError) {
            console.error('Failed to delete team', deleteError);
        }
    }

    const editTeam = async () => {
        const isExisted = checkIsAlreadyExist();
        if (!isExisted) {
            setIsInProgress(true);
            const ref = Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUserTeams').doc(selectedTeam.id);
            try {
                await ref.update({
                    name: teamName,
                    updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                });

                const team = shopUserTeams.find(item => item.id === selectedTeam.id);
                const excludedMembers = team.members.filter(element => !teamMembers.find(item => item.value === element));
                if (excludedMembers.length > 0) {
                    await Promise.all(
                        excludedMembers.map(hostId => Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(hostId).update({
                            teamId: Firebase.firestore.FieldValue.delete(),
                            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                        }).catch((error) => {
                            console.error('Failed updating team', error);
                        }))
                    );
                }
                if (teamMembers && teamMembers.length > 0) {
                    await Promise.all(
                        teamMembers.map(host => Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(host.value).update({
                            teamId: ref.id,
                            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                        }).catch((error) => {
                            console.error('Failed updating team', error);
                        }))
                    );
                }
                resetStates();
            } catch (editError) {
                console.error('Failed to edit team', editError);
            }
        }
    }

    const addTeam = async () => {
        const isExisted = checkIsAlreadyExist();
        if (!isExisted) {
            setIsInProgress(true);
            const ref = Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUserTeams').doc();
            try {
                await ref.set({
                    name: teamName,
                    members: [],
                    createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
                    updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                });
                if (teamMembers && teamMembers.length > 0) {
                    await Promise.all(
                        teamMembers.map(host => Firebase.firestore().collection('shops').doc(shopOrigin).collection('shopUsers').doc(host.value).update({
                            teamId: ref.id,
                            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
                        }).catch((error) => {
                            console.error('Failed updating team', error);
                        }))
                    );
                }
                resetStates();
            } catch (addError) {
                console.error('Failed to create team', addError);
            }
        }
    }

    const customStyles = {
        menuList: (base) => ({
            ...base,
            maxHeight: "150px"
        })
    }

    const openEditModal = (team) => {
        setAddModalOpen(true);
        setSelectedTeam(team);
        setTeamName(team.name);
        const currentTeamMembers = hosts.filter(host => host.teamId === team.id);
        if (currentTeamMembers.length > 0) {
            setTeamMembers(currentTeamMembers.map(host => ({ value: host.id, label: `${host.firstName} ${host.lastName}`, teamName: defineTeamName(host.teamId) })))
        }
    }

    const openRemoveModal = (team) => {
        setRemoveModalOpen(true);
        setSelectedTeam(team);
    }

    const resetStates = () => {
        setAddModalOpen(false);
        setSelectedTeam(null);
        setRemoveModalOpen(false);
        setIsInProgress(false);
        setIsAlreadyExist(false);
        setTeamMembers([]);
        setTeamName('');
    }

    const Option = ({ children, ...props }) => {
        return (
            <components.Option {...props}>
                <Stack>
                    <Stack.Item>
                        {children}
                    </Stack.Item>
                    <Stack.Item>
                        {props.data.teamName && <Badge>{props.data.teamName}</Badge>}
                    </Stack.Item>
                </Stack>
            </components.Option>
        );
    };

    const emptyStateMarkup = (
        <EmptyState
            heading="Create your first team"
            action={createTeamAction}
        />
    );

    const isTeamsCount = shopUserTeams && shopUserTeams.length > 0;

    return (
        <>
            <Card sectioned actions={isTeamsCount ? [createTeamAction] : null}>
                {isTeamsCount > 0
                    ? shopUserTeams.map((item) =>
                        <TeamItem openRemoveModal={openRemoveModal} openEditModal={openEditModal} key={item.id} team={item} />)
                    : emptyStateMarkup
                }
            </Card>
            <Modal
                open={removeModalOpen}
                onClose={() => resetStates()}
                title='Delete Team'
                primaryAction={{
                    content: 'Delete',
                    onAction: () => removeTeam(selectedTeam),
                    destructive: true,
                    loading: isInProgress,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => resetStates(),
                        disabled: isInProgress,
                    }
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>Are you sure you want to delete <TextStyle variation='strong'>{selectedTeam && selectedTeam.name}</TextStyle>? </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
            <Modal
                noScroll={true}
                open={addModalOpen}
                onClose={() => resetStates()}
                title={selectedTeam ? 'Edit Team' : 'Create Team'}
                primaryAction={{
                    content: selectedTeam ? 'Update' : 'Create',
                    onAction: selectedTeam ? editTeam : addTeam,
                    disabled: errorBit || isInProgress || isAlreadyExist,
                    loading: isInProgress,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => resetStates(),
                        disabled: isInProgress,
                    }
                ]}
            >
                <Modal.Section noScroll={true}>
                    <div style={{ height: 300 }}>
                        <Stack vertical>
                            <Stack distribution='fill' vertical  >
                                <Stack vertical spacing='tight'>
                                    <TextStyle>Team name:</TextStyle>
                                    <Stack.Item alignment='leading'>
                                        <TextField
                                            onBlur={() => checkIsAlreadyExist()}
                                            value={teamName}
                                            placeholder='Team name'
                                            onChange={setTeamName} />
                                    </Stack.Item>
                                    {isAlreadyExist && <TextStyle variation='negative'>Team with this name already exists</TextStyle>}
                                </Stack>
                                <Stack vertical spacing='tight'>
                                    <p>Hosts:</p>
                                    <Stack.Item fill>
                                        <Select
                                            value={teamMembers}
                                            onChange={setTeamMembers}
                                            options={options}
                                            styles={customStyles}
                                            components={{ Option }}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            placeholder='Search and select hosts'
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack>
                    </div>
                </Modal.Section>
            </Modal>
        </>
    )
}
