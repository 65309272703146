import AdminPage from '../../../../components/AdminPage';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppPage from '../../../../components/AppPage';
import { ExportMinor } from '@shopify/polaris-icons';
import { Layout } from '@shopify/polaris';
import DashboardFilter from '../../../Dashboard/DashboardFilter';
import useDateRange, { DateRange } from '../../../Dashboard/useDateRange';
import StoriesAnalyticsChart from './components/StoriesAnalyticsChart';
import StoriesAnalyticsTable from './components/StoriesAnalyticsTable';
import { storiesEventDetailAnalytics, storiesPlaytimeExportAnalytics } from '../../../../cubeManager/queries/stories';
import { useShopProvider } from '../../../../components/ShopProvider';
import { useCubeQuery } from '@cubejs-client/react';
import { useCubeProvider } from '../../../../cubeManager';
import Papa from 'papaparse';
import { csvLoader } from '../../../../utils/csvProcessor';
import Moment from 'moment-timezone';

const StoriesAnalytics = () => {
  const history = useHistory();
  const {shopData, shopOrigin} = useShopProvider();
  const {cubeOptions, onCubeError} = useCubeProvider()

  const [range, setRange] = useState(DateRange.last30);
  const [startDate, endDate] = useDateRange(range);

  const [exportQuery, setExportQuery] = useState(null)

  const {resultSet, error, isLoading} = useCubeQuery(exportQuery ?? {}, cubeOptions);

  useEffect(() => {
    if (resultSet?.loadResponse?.results) {
      const data = resultSet?.loadResponse?.results.flatMap(item => item.data).map((item) => {
        if (Object.keys(item).includes('StoryPlaytime.playtime')) {
          return ({
            'Shoppable id': item['StoryPlaytime.storyId'],
            'Published page': item['StoryPlaytime.publishedPage'] ?? 'boutiq-client',
            'Video id': item['StoryPlaytime.videoId'],
            'Product id': item['StoryPlaytime.productId'],
            'Product name': item['StoryPlaytime.productName'],
            'Event': item['StoryPlaytime.eventName'],
            'Event time': item['StoryPlaytime.eventTimestamp'],
            'sessionId': item['StoryPlaytime.sessionId'],
            'Product price': '',
            'Currency code': '',
            'Action': '',
            'Playtime': item['StoryPlaytime.playtime'],
          })
        }
        return ({
          'Shoppable id': item['StoryEvents.storyId'],
          'Published page': item['StoryEvents.publishedPage'] ?? 'boutiq-client',
          'Video id': item['StoryEvents.videoId'],
          'Product id': item['StoryEvents.productId'],
          'Product name': item['StoryEvents.productName'],
          'Product price': item['StoryEvents.eventValue'],
          'Currency code': item['StoryEvents.eventCurrencyCode'],
          'Event': item['StoryEvents.eventName'],
          'Action': item['StoryEvents.eventAction'] ?? '',
          'Event time': item['StoryEvents.eventTimestamp'],
          'sessionId': item['StoryEvents.sessionId'],
          'Playtime': '',
        })
      })
        .sort((a, b) => Moment(b['Event time']).diff(a['Event time'], 'milliseconds'))
      const csv = Papa.unparse(data);
      csvLoader(csv, `${shopOrigin}_stories_`);
      setExportQuery(null)
    }
  }, [resultSet])

  useEffect(() => {
    onCubeError(error)
  }, [error])

  const exportStoriesAnalytics = () => {
    setExportQuery([
      storiesEventDetailAnalytics(shopData.ianaTimezone, startDate, endDate),
      storiesPlaytimeExportAnalytics(shopData.ianaTimezone, startDate, endDate)
    ])
  }

  return (
    <AppPage
      breadcrumbs={[{content: 'Back', onAction: history.goBack}]}
      title='Shoppable video reports'
      secondaryActions={[{content: 'Export', icon: ExportMinor, onAction: exportStoriesAnalytics, loading: isLoading}]}
    >
      <Layout>
        <Layout.Section>
          <DashboardFilter
            onRangeUpdate={setRange}
            currentRange={range}
          />
        </Layout.Section>
        <Layout.Section>
          <StoriesAnalyticsChart
            startDate={startDate}
            endDate={endDate}
          />
        </Layout.Section>
        <Layout.Section>
          <StoriesAnalyticsTable
            startDate={startDate}
            endDate={endDate}
          />
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default () => {
  return (
    <AdminPage>
      <StoriesAnalytics/>
    </AdminPage>
  )
}
