import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useShopProvider } from '../../ShopProvider';

export const IntroPageContext = createContext(null);

export default function IntroPageProvider({ introVideosConfig, children }) {
  const [shopVideosStates, setShopVideosStates] = useState([]);
  const [isShopVideoStatesRefreshed, setIsShopVideoStatesRefreshed] = useState(false)
  const [isMuted, setIsMuted] = useState(false);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(0);
  const { chatConfig } = useShopProvider();
  const [videoElement, setVideoElement] = useState(null);
  const [isUserInteractWithVideoPlayer, setIsUserInteractWithVideoPlayer] = useState(false);
  const [stateChecked, setStateChecked] = useState(false);
  const playingVideoRef = useRef(null);
  const setPlayingVideoRef = (ref) => {
    playingVideoRef.current = ref;
  }
  const setListedVideos = (videos) => {
    setShopVideosStates(videos);
  }
  const [isVideoBuffering, setIsVideoBuffering] = useState(true);
  const [autoplayPermissionsDenied, setAutoplayPermissionsDenied] = useState(false);
  const [playList, setPlayList] = useState([]);

  useEffect(() => {
      if (introVideosConfig) {
        setPlayList(introVideosConfig.map(item => ({
          id: item.id,
          src: item.data.videoUrl,
          storiesConfig: {
            products: item.data.products,
            size: item.data.storyProductsConfig?.size
          }
        })));

        if (shopVideosStates.length === 0) {
          const videoList = introVideosConfig.map(video => ({ id: video.id, isPlayed: false }));
          setListedVideos(videoList);
        } else {
           const newVideoArray = introVideosConfig.map((video, index) => {
            if (index < shopVideosStates.length) {
              if (shopVideosStates[index].id === video.id) {
                return ({ id: video.id, isPlayed: shopVideosStates[index].isPlayed })
              } else {
                return ({ id: video.id, isPlayed: false })
              }
            } else {
              return ({ id: video.id, isPlayed: false })
            }
           });
            setListedVideos(newVideoArray);
          }
          setIsShopVideoStatesRefreshed(true);
      }
  }, [JSON.stringify(introVideosConfig)])

  /** store video states */
  const updateVideoPlayingState = (videoIndex) => {
    if (shopVideosStates.length > 0 && playList.length > 0) {
      const storedVideoStates = shopVideosStates;
      const index = storedVideoStates.findIndex(item => item.id === playList[videoIndex].id);
      if (index > -1) {
        storedVideoStates[index].isPlayed = true;
        setListedVideos(storedVideoStates);
      }
    }
  }

  /** play video after user interaction */
  const playIntroVideo = useCallback(() => {
    setIsUserInteractWithVideoPlayer(true);
    const playPromise = videoElement.current.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => { setAutoplayPermissionsDenied(false); })
    }
  }, [setIsUserInteractWithVideoPlayer, videoElement])

  return <IntroPageContext.Provider value={{
    shopVideosStates,
    setListedVideos,
    isMuted,
    setIsMuted,
    isChatEnabled: chatConfig && chatConfig.enabled,
    playingVideoIndex,
    setPlayingVideoIndex,
    setPlayingVideoRef,
    setVideoElement,
    videoElement,
    playIntroVideo,
    isUserInteractWithVideoPlayer,
    setIsUserInteractWithVideoPlayer,
    setAutoplayPermissionsDenied,
    playList,
    autoplayPermissionsDenied,
    isVideoBuffering,
    setIsVideoBuffering,
    stateChecked,
    setStateChecked,
    updateVideoPlayingState,
    isShopVideoStatesRefreshed,
  }}>
    {children}
  </IntroPageContext.Provider>
}

export function useIntroPage() {
  const context = useContext(IntroPageContext);
  if (!context) {
    throw new Error('useIntroPage must be used within the IntroPageContext Provider');
  }
  return context;
}
