import React, { useState, useCallback } from 'react'
import { Stack, TextField } from '@shopify/polaris'

export const FormTextField = ({
    rows,
    placeholder,
    onPlaceholderChange,
}) => {
    const [value, setValue] = useState(placeholder);
    const handleChange = useCallback((newValue) => setValue(newValue), []);

    return (
        <Stack.Item fill>
            <TextField
                onChange={handleChange}
                onBlur={() => onPlaceholderChange(value)}
                multiline={Number(rows)}
                value={value || ''}
                placeholder='edit placeholder'
                autoComplete='off' />
        </Stack.Item>
    )
}
