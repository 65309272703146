import React from 'react';
import { useHistory } from 'react-router-dom';

import { Layout } from '@shopify/polaris';

import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'

import ClientFormConfigurator from '../../../components/ClientFormConfigurator';

function ClientFormSettings() {
    const history = useHistory();

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Client Form Settings'
        >
            <Layout>
                <Layout.Section>
                    <ClientFormConfigurator/>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <ClientFormSettings />
        </AdminPage>
    )
}
