
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import useTemplate from './useTemplate';

export default function useShopTemplate(shopId, templateId, locale) {

    const [templateData, templateLoading ] = useTemplate(templateId, locale);
    const [ isTemplateLoading, setTemplateLoading ] = useState(true);
    const [ template, setTemplate ] = useState(null);
    
    const templateLocaleRef = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('templates').doc(templateId).collection('locales').doc(locale) : null;
    const [shopTemplateData, shopTemplateLoading, error ] = useDocumentData(templateLocaleRef);

    useEffect(()=> {
        if (!templateLoading && !shopTemplateLoading) {
            let text = Object.assign({},templateData.text, shopTemplateData?.text)
            let subject = shopTemplateData?.subject ?? templateData.subject;
            setTemplate({
                ...shopTemplateData,
                subject,
                text,
            });
            setTemplateLoading(false);
        } else {
            setTemplateLoading(true);
        }

    },[templateLoading, shopTemplateLoading, templateData, shopTemplateData ]);

    return { template, isTemplateLoading }
}
