import { Box, Thumbnail } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import ProductPrice from '../../../../../../components/products/ProductPrice';
import React from 'react';
import useStoriesProductStyle from '../../../../../../hooks/useStoriesProductStyle';
import StoryPreviewProductDescription from '../StoryPreviewProductDescription';
import '../../styles.css'

const CollapseProductPreview = ({product, index, onHover, onHoverOut, hoverProductIndex, products, currentTime}) => {
  const {color, size} = product.storiesConfig;
  const {top, bottom, left, right} = useStoriesProductStyle(size, products, product, currentTime);

  const isLongPrice = product.maxVariantPrice !== product.minVariantPrice ||
    (product.compareAtPrice && product.compareAtPrice !== product.price)

  return (
    <div
      className={`product-preview collapsed ${top < 0 || bottom < 0 ? 'hidden' : ''}`}
      onMouseOver={() => onHover(index)}
      onMouseLeave={onHoverOut}
      style={{
        top: Number.isInteger(top) ? `${top}px` : 'auto',
        bottom: Number.isInteger(bottom) ? `${bottom}px` : 'auto',
        left,
        right,
        width: `calc(100% - ${left}px - ${right}px)`,
        backgroundColor: `${color}99` //60% bg opacity
      }}
    >
      <Thumbnail
        transparent={!!product.image}
        source={product.image ?? ImageMajor}
        size={"large"}
        alt={product.title}
      />
      {index === hoverProductIndex ? (
        <StoryPreviewProductDescription product={product} />
      ) : (
        <Box className={`product-preview-price-container ${isLongPrice ? 'small-text' : ''}`}>
          <ProductPrice product={product} variant={'bodySm'} as={'p'}/>
        </Box>
      )}
    </div>
  )
}

export default CollapseProductPreview;
