import React, { useEffect, useState, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import AppPage from '../../../../components/AppPage';
import AdminPage from '../../../../components/AdminPage';
import { useHistory } from 'react-router-dom';
import { Layout, Card, Stack, Checkbox, TextField, ContextualSaveBar, TextStyle, Subheading, ChoiceList, Banner, Button, Modal, Spinner } from '@shopify/polaris';
import {
    DEFAULT_COLOR,
    DEFAULT_CORNER_STYLE,
    DEFAULT_FONT_FAMILY,
    DEFAULT_HOST_SELECT_SUBTITLE
} from '../../../../utils/consts';
import { Locales } from '../../../../utils/locales'
import { HostSelectionMarkup } from '../../../../components/mockup/HostSelectionMockup';
import { useShopProvider } from '../../../../components/ShopProvider';
import { HostAvatarIcon } from './components/HostAvatar';
import useHostSchedulingConfig from '../../../../hooks/useHostSchedulingConfig';
import { FontsLoader } from '../../../../utils/fonts-loader';
import './styles.css';

const HostScheduleSelection = () => {
    const history = useHistory();
    const [dirtyBitHostSelection, setDirtyBitHostSelection] = useState(false);
    const [dirtyBitVisibilityHosts, setDirtyBitVisibilityHosts] = useState(false);
    const [defaultValuesInitialized, setDefaultValuesInitialized] = useState(false);
    const [hostSelectText, setHostSelectText] = useState({});
    const [isSaving, setSaving] = useState(false);
    const [hostSelectionEnabled, setHostSelectionEnabled] = useState(false);
    const [anyAvailableEnabled, setAnyAvailableEnabled] = useState(true);
    const { shopOrigin, appStylesConfig, hosts, shopLocales, hostSelectionConfig, hostSelectionConfigLoading, setHostSelectionConfig, updateHostHiddenState, updateHostBio } = useShopProvider();
    const primaryColor = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.primaryColor) || DEFAULT_COLOR;
    const cornerStyle = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.cornerStyle) || DEFAULT_CORNER_STYLE;
    const defaultLocale = shopLocales[0];
    const [displayedHosts, setDisplayedHosts] = useState([]);
    const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
    const [selectedHostData, setSelectedHostData] = useState(null);
    const [editableHostBio, setEditableHostBio] = useState('');
    const [isUpdateBioInProgress, setIsUpdateBioInProgress] = useState(false);
    const { getHostConfig ,hostSchedulingConfigLoading } = useHostSchedulingConfig(shopOrigin);

    const handleHosVisibilityStateChange = useCallback(value => setDisplayedHosts(value), []);
    const isValidHost = (hostId) => getHostConfig(hostId) != null;

    const fontFamily = appStylesConfig?.clientAppTheme?.fontFamily ?? DEFAULT_FONT_FAMILY;

    useEffect(() => {
        if (fontFamily) {
            FontsLoader(fontFamily, document.body)
        }
    }, [fontFamily])

    useEffect(() => {
        if (!hostSelectionConfigLoading && hosts) {
            if (hostSelectionConfig) {
                if (hostSelectionConfig.hostSelectionText) {
                    setHostSelectText(hostSelectionConfig.hostSelectionText);
                }
                setHostSelectionEnabled(!!hostSelectionConfig.hostSelectionEnabled)
                setAnyAvailableEnabled(hostSelectionConfig.anyAvailableEnabled != null ? hostSelectionConfig.anyAvailableEnabled : true);
            }
            if (hosts) {
                setDisplayedHosts(hosts.filter(host => !host.isHidden).map(filtered => filtered.id))
            }
            setDefaultValuesInitialized(true);
        }
    }, [hostSelectionConfig, hosts, hostSelectionConfigLoading])

    useEffect(() => {
        if (defaultValuesInitialized) {
            let dirtyBitPreconditionSelectionAvailability;
            let dirtyBitPreconditionOverrideMeassages;
            let dirtyBitPreconditionAnyAvailable;

            if (hostSelectionConfig && hostSelectionConfig.hostSelectionEnabled) {
                dirtyBitPreconditionSelectionAvailability = hostSelectionConfig.hostSelectionEnabled !== hostSelectionEnabled;
            } else {
                dirtyBitPreconditionSelectionAvailability = hostSelectionEnabled;
            }

            if (hostSelectionConfig && hostSelectionConfig.anyAvailableEnabled != null) {
                dirtyBitPreconditionAnyAvailable = hostSelectionConfig.anyAvailableEnabled !== anyAvailableEnabled;
            } else {
                dirtyBitPreconditionAnyAvailable = !anyAvailableEnabled;
            }

            if (hostSelectionConfig && hostSelectionConfig.hostSelectionText) {
                const defaultLocalesValues = Object.entries(hostSelectionConfig.hostSelectionText).map(([key, value]) => ({ locale: key, value }));
                const serviceNounValues = Object.entries(hostSelectText).map(([key, value]) => ({ locale: key, value }));
                if (defaultLocalesValues.length > serviceNounValues.length) {
                    dirtyBitPreconditionOverrideMeassages = compareLocalesOverrides(defaultLocalesValues, serviceNounValues);
                } else {
                    dirtyBitPreconditionOverrideMeassages = compareLocalesOverrides(serviceNounValues, defaultLocalesValues);
                }
            } else {
                dirtyBitPreconditionOverrideMeassages = Object.keys(hostSelectText).length !== 0;
            }
            setDirtyBitHostSelection(
                dirtyBitPreconditionSelectionAvailability
                || dirtyBitPreconditionOverrideMeassages
                || dirtyBitPreconditionAnyAvailable
            )
        }
    }, [hostSelectionEnabled, defaultValuesInitialized, hostSelectionConfig, hostSelectText, anyAvailableEnabled]);

    useEffect(() => {
        if (defaultValuesInitialized) {
            const prev = hosts.filter(host => !host.isHidden).map(host => host.id);
            const isEqual = prev.length === displayedHosts.length && prev.every(hostId => displayedHosts.includes(hostId));
            setDirtyBitVisibilityHosts(!isEqual);
        }
    }, [defaultValuesInitialized, displayedHosts, hosts])

    const compareLocalesOverrides = (compArray, edited) => {
        return !compArray.every(item => {
            const defaultValue = edited.find(value => { if (value.locale === item.locale) return value });
            if (defaultValue) {
                return defaultValue.value === item.value;
            } else {
                return false;
            }
        })
    }

    const handleHostTextChange = (val, locale) => {
        setHostSelectText({ ...hostSelectText, [locale]: val || null });
    }

    const save = async () => {
        setSaving(true);
        if (dirtyBitHostSelection) {
            try {
                let config = { hostSelectionEnabled, anyAvailableEnabled };
                if (Object.keys(hostSelectText).length > 0) {
                    config = { ...config, ...{ hostSelectionText: hostSelectText } }
                }
                await setHostSelectionConfig(config);
                if (hostSelectionEnabled) {
                    firebase.analytics().logEvent('admin.host_select_enabled');
                }
            } catch (error) {
                console.error('Failed saving default service data', error);
            } finally {
                setDirtyBitHostSelection(false);
            }
        }
        if (dirtyBitVisibilityHosts) {
            try {
                await updateHostHiddenState(hosts.map(host => {
                    if (displayedHosts.includes(host.id)) {
                        return ({ ...host, isHidden: false })
                    } else {
                        return ({ ...host, isHidden: true })
                    }
                }));
            } catch (error) {
                console.error('Failed saving default service data', error);
            } finally {
                setDirtyBitVisibilityHosts(false);
            }
        }
        setSaving(false);
    }

    const onDiscard = () => {
        if (hostSelectionConfig) {
            setHostSelectText(hostSelectionConfig.hostSelectionText || {});
            setHostSelectionEnabled(hostSelectionConfig.hostSelectionEnabled);
            setDisplayedHosts(hosts.filter(host => !host.isHidden).map(filtered => filtered.id))
        }
        setDirtyBitHostSelection(false);
    }

    const openEditableDialog = (val) => {
        setSelectedHostData(val);
        setEditableHostBio(val.bio || '')
        setIsModalDialogOpen(true);
    }

    const handleHostBioChange = (val) => {
        setEditableHostBio(val);
    }

    const onClose = () => {
        setIsModalDialogOpen(false);
    }

    const saveEditedHostBio = async () => {
        setIsUpdateBioInProgress(true);
        try {
            await updateHostBio(selectedHostData.id, editableHostBio);
            onClose();
        } catch (error) {
            console.error(error);
        } finally {
            setIsUpdateBioInProgress(false);
        }
    }

    const renderSelectTextForm = () => {
        return (
            <Stack vertical>
                <Subheading>Select host text</Subheading>
                {shopLocales.length > 1
                    ? shopLocales.map(locale => (
                        <TextField
                            key={locale}
                            label={Locales[locale].label}
                            value={hostSelectText[locale]}
                            onChange={(val) => handleHostTextChange(val, locale)}
                            type='text'
                            placeholder={DEFAULT_HOST_SELECT_SUBTITLE[locale]}
                            disabled={!hostSelectionEnabled}
                        />
                    ))
                    : <TextField
                        value={hostSelectText[defaultLocale]}
                        onChange={(val) => handleHostTextChange(val, defaultLocale)}
                        type='text'
                        placeholder={DEFAULT_HOST_SELECT_SUBTITLE[defaultLocale]}
                        disabled={!hostSelectionEnabled} />
                }
            </Stack>
        )
    }

    return (
        <AppPage
            title='Host Selection Experience'
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
        >
            <Layout>
                <Layout.Section>
                    <Card title={<Stack><Subheading>Preview</Subheading></Stack>} sectioned subdued>
                        <Stack spacing='extraLoose' distribution='center'>
                            <HostSelectionMarkup
                                subTitle={hostSelectText[defaultLocale] || DEFAULT_HOST_SELECT_SUBTITLE[defaultLocale]}
                                disabled={!hostSelectionEnabled}
                                hosts={hosts.filter(item => displayedHosts.includes(item.id))}
                                anyAvailableEnabled={anyAvailableEnabled}
                                cornerStyle={cornerStyle}
                                primaryColor={primaryColor}
                            />
                        </Stack>
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card sectioned>
                        <Card.Section>
                            <Stack spacing='tight'>
                                <Checkbox
                                    label="Enable host selection"
                                    checked={hostSelectionEnabled}
                                    onChange={setHostSelectionEnabled}
                                />
                                {hostSelectionEnabled &&
                                    <p><TextStyle variation='subdued'>Hosts should update their profile avatars and bios in the host app</TextStyle></p>}
                            </Stack>
                        </Card.Section>
                        <Card.Section>
                            <Stack vertical>
                                <Subheading>Hosts to display</Subheading>
                                {!hostSchedulingConfigLoading && <ChoiceList
                                    disabled={!hostSelectionEnabled}
                                    choices={
                                        hosts.map(host => ({
                                            label:
                                                <Stack spacing='extraTight' alignment='leading' wrap={false}>
                                                    <HostAvatarIcon className='choice-item' data={host} />
                                                    <Stack vertical spacing='tight'>
                                                        <div className='choice-item'>
                                                            <p>{`${host.firstName && host.firstName} ${host.lastName && host.lastName}`}</p>
                                                            <div className='edit-button-wrapper'>
                                                                <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); openEditableDialog({ bio: host.bio, id: host.id })}}>
                                                                    <TextStyle variation='subdued'> Edit bio</TextStyle>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                </Stack>,
                                            value: host.id,
                                            disabled: !isValidHost(host.id),
                                        }))
                                    }
                                    selected={displayedHosts}
                                    onChange={handleHosVisibilityStateChange}
                                    allowMultiple={true}
                                />}
                                {hostSchedulingConfigLoading && <Spinner size='small' />}
                                {displayedHosts.length === 0 && <Banner status='warning'>
                                    Please select at least one host
                                </Banner>}
                            </Stack>
                        </Card.Section>
                        <Card.Section>
                                <Checkbox
                                    label='Show "Any Available" Option'
                                    checked={anyAvailableEnabled}
                                    onChange={setAnyAvailableEnabled}
                                    disabled={!hostSelectionEnabled}
                                    helpText='Clients can select any available appointment time without selecting a specific host'
                                />
                            </Card.Section>
                        <Card.Section>
                            {renderSelectTextForm()}
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            {(dirtyBitHostSelection || dirtyBitVisibilityHosts) && <ContextualSaveBar
                message="Save host selection settings?"
                saveAction={{
                    onAction: save,
                    loading: isSaving,
                    disabled: displayedHosts.length === 0,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: isSaving,
                }}
            />}
            <Modal
                open={isModalDialogOpen}
                onClose={() => onClose()}
                title={'Edit host bio'}
                primaryAction={{
                    content: 'Save',
                    loading: isUpdateBioInProgress,
                    disabled: !editableHostBio.localeCompare(selectedHostData && selectedHostData.bio || '') || isUpdateBioInProgress,
                    onAction: () => { saveEditedHostBio(); }
                }}
                secondaryActions={{
                    content: 'Cancel',
                    onAction: onClose,
                    disabled: isUpdateBioInProgress
                }}
            >
                <Modal.Section>
                    <Stack vertical>
                        <TextField
                            maxLength={250}
                            multiline={4}
                            label="Host bio"
                            placeholder="Host bio"
                            value={editableHostBio}
                            onChange={handleHostBioChange}
                            autoComplete="off"
                            type="text" />
                        <Stack>
                            <p><span>250</span>&nbsp;/&nbsp;<span>{editableHostBio.length}</span></p>
                        </Stack>
                    </Stack>
                </Modal.Section>
            </Modal>
        </AppPage>
    )
}

export default function () {
    return (
        <AdminPage>
            <HostScheduleSelection />
        </AdminPage>
    )
}
