import React from 'react';
import {useHistory } from 'react-router-dom';
import { Layout, Card, Link, DescriptionList, MediaCard } from '@shopify/polaris';
import {
    AdjustMinor,
  } from '@shopify/polaris-icons';

import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';

function ClientSettings() {

    const history = useHistory();

    return (
        <AppPage 
            title='Client experience' 
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            secondaryActions={[{ content: 'Advanced', icon: AdjustMinor, onAction: () => history.push('/clientSettings/advancedSettings') },]}
            >
            <Layout>
                <Layout.Section fullWidth>
                    {/* empty section for some padding */}
                </Layout.Section>
                <Layout.Section oneThird>
                    <MediaCard
                        title='Online store integration'
                        size='small'

                        primaryAction={{
                            content: 'Go to online store settings',
                            onAction: () => history.push('/clientSettings/onlineStoreSettings'),
                        }}
                        description='Set how Boutiq is added to your online store so clients can start video calls'
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/online_store_thumbnail.png"
                        />

                    </MediaCard>

                </Layout.Section>
                <Layout.Section oneThird>

                    <MediaCard
                        title='Client app'
                        size='small'

                        primaryAction={{
                            content: 'Customize client app',
                            onAction: () => history.push('/clientSettings/clientApp'),
                        }}
                        description='Customize the Boutiq client app theme, content &amp; behavior'
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/client_app_thumbnail.png"
                        />

                    </MediaCard>
                </Layout.Section>
                {/* <Layout.Section oneThird>
                    <MediaCard
                        title='Welcome screen'
                        size='small'
                        portrait
                        primaryAction={{
                            content: 'Welcome Screen',
                            onAction: () => history.push('clientSettings/welcomeSettings'),
                        }}
                        description='Customize the Boutiq client app welcome screen content'
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                        />

                    </MediaCard>
                </Layout.Section> */}
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <ClientSettings />
        </AdminPage>
    )
}
