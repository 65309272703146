import React, { useEffect, useRef, useState } from 'react';
import './FormMessages.css';

export const FormMessages = ({ message }) => {
    const [currentMessage, setCurrentMessage] = useState(message);
    const messageContainer = useRef(null);
    const stepsAppearing = 30;
    const stepsHiding = 30;

    useEffect(() => {
        if(message) {
            setCurrentMessage(message);
            animateMessage(false).then(() => {}).catch((e) => { });
        } else {
            animateMessage(true).then(() => { }).catch((e) => { });
        }
    },[JSON.stringify(message)])

    const messageAnimation = {
        positionBySteps(start, end, step, steps) {
            return Math.max(0, Math.min(end, this.easeOutQuad(start, end, step, steps)));
        },
        easeOutQuad(start, end, t, steps) {
            t /= steps;
            t--;
            return Math.round(-end * (t * t * t * t - 1) + start);
        },
    };

    const animateMessage = (direction) => {
        return new Promise(function (resolve, reject) {
            let step = 0;
            const start = 0;
            const end = 200;
            const currentSteps = direction ? stepsAppearing : stepsHiding;
            let requestID;

            const animate = () => {
                const contentPosition = messageAnimation.positionBySteps(
                    start,
                    end,
                    step,
                    currentSteps);
                if (step <= currentSteps) {
                    step++;
                    if(!messageContainer.current) {
                        cancelAnimationFrame(requestID);
                        reject();
                    } else {
                        messageContainer.current.style.maxHeight = `${direction ? 200 - contentPosition : .7 * contentPosition}px`;
                        requestID = requestAnimationFrame(() => {
                            animate();
                        });
                    }
        
                } else {
                    cancelAnimationFrame(requestID);
                    resolve();
                }
            }
            requestID = requestAnimationFrame(animate);
        });
    }

    return (
        <>
            <div ref={messageContainer} className='popover-message-container-wrapper'>
                <div className='popover-message-container'>
                    {currentMessage && <span className={`message ${currentMessage.type ? currentMessage.type : ''}`}>
                        {currentMessage.icon ? currentMessage.icon : null} {currentMessage.text}
                    </span>}
                </div>
            </div>
        </>
    )
}
