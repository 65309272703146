import React from 'react';
import './index.css'

const WelcomeHostAvatars = ({avatars}) => {

    return (
        <div className='active-host-avatars-container' >
            {avatars.map((avatar, k) =>
                <div key={k} className='avatar-container'>
                    {avatar.avatarUrl ?
                        <img className='avatar' src={avatar.avatarUrl} /> :
                        <span className='avatar'>{avatar.firstName.charAt(0)}{avatar.lastName.charAt(0)}</span>}
                </div>
            )}
        </div>
    );
};

export default WelcomeHostAvatars;
