
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function useShopNotifications(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('notifications') : null;
    const [shopNotifications, shopNotificationsLoading, error] = useDocumentData(ref);

    const toggleDisable = async (type) => {
        const isDisbaled = shopNotifications && shopNotifications.disabled && shopNotifications.disabled[type];
        await ref.set({
            disabled: {
                [type]: !isDisbaled,
            }
        }, { merge: true });
    }

    const setContactEmail = async (clientContactEmail) => {
        await ref.set({
            clientContactEmail: clientContactEmail || Firebase.firestore.FieldValue.delete(),
        },{ merge: true });
    }

    const setDisableHostContact = async (disableContactEmailToHost) => {
        await ref.set({
            disableContactEmailToHost: disableContactEmailToHost || Firebase.firestore.FieldValue.delete(),
        },{ merge: true });
    }

    return { shopNotifications, shopNotificationsLoading, toggleDisable, setContactEmail, setDisableHostContact }
}
