import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics';

import { Card, Stack, Button, Modal, Banner, ButtonGroup } from '@shopify/polaris';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage';
import { useShopProvider } from '../../components/ShopProvider';

import Welcome from './Welcome';
import Hosts from './Hosts';
import OnlineStore from './OnlineStore';
import ConfirmPlan from './ConfirmPlan';
import ThankYou from './ThankYou';

const SUB_VALIDATION_TEXT = 'Without an active subscription Boutiq video calls will not be connected'

const OnboardSteps = {
    welcome: { name: 'welcome', order: 0, next: 'plan', component: () => <Welcome />, cta: 'Next', count: false, },
    plan: { name: 'plan', order: 1, prev: 'welcome', next: 'online', component: () => <ConfirmPlan />, cta: 'Next', count: true, validation: true, validationTxt: SUB_VALIDATION_TEXT },
    online: { name: 'online', order: 2, prev: 'plan', next: 'hosts', component: () => <OnlineStore />, cta: 'Next', count: true },
    hosts: { name: 'hosts', order: 3, prev: 'online', next: 'thankyou', component: () => <Hosts />, cta: 'Next', count: true },
    thankyou: { name: 'thankyou', order: 4, prev: 'hosts', component: () => <ThankYou />, cta: 'Go to Dashboard', count: false },
}

function Onboard() {

    const history = useHistory();
    const { URLStep }  = useParams();
    const maxSteps = Object.values(OnboardSteps).reduce((agg, value) => agg += value.count ? 1 : 0, 0);
    const currentStep = OnboardSteps[OnboardSteps[URLStep] ? URLStep : 'welcome'];
    const { hosts, subData } = useShopProvider();

    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [warningModalContent, setWarningModalContent] = useState(false);

    useEffect(() => {
        firebase.analytics().logEvent(`admin.onboard.${currentStep.name}`);
    }, [URLStep])

    const validation = (step) => {
        switch (step.name) {
/*             case OnboardSteps.hosts.name:
                return hosts.length > 0; */
            case OnboardSteps.plan.name:
                return !!subData;
            default:
                return true;
        }
    }

    const nextStep = () => {
        if (currentStep.next)
            history.push(`/onboard/${currentStep.next}`)
        else (
            history.replace(`/`)
        )
    }
    const prevStep = () => {
        if (currentStep.prev)
            history.replace(`/onboard/${currentStep.prev}`)
    }

    const nextStepWithValidation = () => {
        if (currentStep.validation && !validation(currentStep)) {
            setWarningModalContent(
                <Banner
                    status='warning'>
                    <p>{currentStep.validationTxt}</p>
                </Banner>)
                setWarningModalOpen(true);
            return;
        }
        nextStep();
    }

    return (
        <AppPage>
            <Card >
                {/* <div className='onboard-header'>
                    <Stack alignment='center' spacing='tight'>
                        <img src='/boutiq_light.svg' alt='Boutiq logo' className='caazam-logo' />
                        {currentStep.name === 'thankyou' && <img src='/stars.png' alt='stars'className='caazam-stars'/>}
                    </Stack>
                </div> */}
                <Card.Section>
                    <Stack vertical >
                        {currentStep.count &&
                            <Stack distribution='center'>
                                <p>Step {currentStep.order} of {maxSteps}</p>
                            </Stack>}
                        {currentStep.component()}
                        <Stack distribution={currentStep.order > 0 ? 'equalSpacing' : 'trailing'} alignment='center'>
                            {currentStep.prev &&
                                <Button plain onClick={() => prevStep()}>Previous</Button>
                            }
                            <Button onClick={() => nextStepWithValidation()} disabled={!validation(currentStep)}>{currentStep.cta}</Button>
                        </Stack>
                    </Stack>
                </Card.Section>
            </Card>
            <Modal
                open={warningModalOpen}
                onClose={() => setWarningModalOpen(false)}
            >
                <Modal.Section>
                    <Stack vertical>
                        {warningModalContent}
                        <Stack distribution='trailing'>
                            <ButtonGroup >
                                <Button primary onClick={() => setWarningModalOpen(false)}>Stay</Button>
                                <Button onClick={() => { setWarningModalOpen(false); nextStep() }}>Next</Button>
                            </ButtonGroup>
                        </Stack>
                    </Stack>
                </Modal.Section>
            </Modal>
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage showNavigation={false}>
            <Onboard />
        </AdminPage>
    )
}
