import { useEffect, useState } from 'react';

export default function useInstallData(shopId) {
    const query = new URLSearchParams(window.location.search);

    const [ installKey, setInstallKey] = useState(query.get('installKey') || localStorage.getItem(`${shopId}.installKey`));
    const [ installHmac, setInstallHmac] = useState(query.get('caazamHMAC') || localStorage.getItem(`${shopId}.installHMAC`));

    useEffect(()=>{
        var hmac = query.get('caazamHMAC');
        if (hmac) {
            localStorage.setItem(`${shopId}.installHMAC`, hmac);
            setInstallHmac(hmac);
        } else {
            setInstallHmac(localStorage.getItem(`${shopId}.installHMAC`));
        }
        
        var installKey = query.get('installKey');
        if (installKey) {
            localStorage.setItem(`${shopId}.installKey`, installKey);
            setInstallKey(installKey);
        } else {
            setInstallKey(localStorage.getItem(`${shopId}.installKey`));
        }

    },[shopId]);

    const removeInstallData = () => {
        localStorage.removeItem(`${shopId}.installHMAC`);
        localStorage.removeItem(`${shopId}.installKey`);
    }

    return { installHmac, installKey, removeInstallData };
}
 