import React from 'react';

import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics';

import { Button, Stack, TextStyle, Subheading, DisplayText, Heading } from '@shopify/polaris';
import './PricingPlan.css';

export default function PricingPlan({ planData, currentPlan, onSelectPlan, CTA, loading }) {

    const handlePlanSelected = (planData) => {
        firebase.analytics().logEvent('admin.sub_plan_selected', { plan_id: planData.id, plan_name: planData.name });
        onSelectPlan(planData.id)
    }

    const PlanAction = () => {
        if (!CTA)
            return null;
        else if (currentPlan)
            return (<Button disabled>Your current plan</Button>)
        else
            return (<Button primary onClick={() => handlePlanSelected(planData)} disabled={loading}>{CTA}</Button>)
    }


    const priceCurrencyFormatter = () =>
        new Intl.NumberFormat(window.navigator.language, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });

    const videoPriceCurrencyFormatter = () =>
        new Intl.NumberFormat(window.navigator.language, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

    const percentFormatter = () =>
        new Intl.NumberFormat(window.navigator.language, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });

    return (
        <div className='pricing-plan'>
            {!!planData.freeTrial && <div className='pricing-header'>
                {planData.freeTrial}-DAY FREE TRIAL
            </div>}
            <div className='pricing-content'>
                <Stack vertical alignment='center'>
                    <Subheading element='h2'>{planData.name}</Subheading>
                    <DisplayText size='large'>
                        {priceCurrencyFormatter().format(planData.price.month)}
                        <span style={{ display: 'inline-block', fontSize: '1.5rem' }}>/month</span>
                    </DisplayText>
                    {planData.price.annual &&
                        <p style={{ textAlign: 'center', margin: '0 4rem' }}>
                            <TextStyle variation='subdued'>
                                {`or ${priceCurrencyFormatter().format(planData.price.annual / 12)} billed at ${priceCurrencyFormatter().format(planData.price.annual)} once per year`}
                            </TextStyle>
                        </p>}
                    <hr className='divider' />
                    <div style={{ textAlign: 'center' }}>
                            <Heading> {percentFormatter().format(planData.price.commission / 100)} of sales</Heading>
                            <p> made thru Boutiq </p>
                    </div>
                    {/* <Subheading element='p'><TextStyle variation='subdued'>Features</TextStyle></Subheading> */}
                    <Heading>{planData.maxHosts} Hosts</Heading>
                    <div style={{ textAlign: 'center' }}>
                            <Heading>{planData.videoHours} Monthly video hours</Heading>
                            <p> {videoPriceCurrencyFormatter().format(planData.price.extraHour)} per extra video hour </p>
                    </div>

                    <PlanAction />
                    {/* <p>Video call apps w/ Interactive showroom</p>
                    <p>Analytics dashboard</p>
                    <p>Free support</p> */}
                </Stack>
            </div>
        </div>
    )
}
