import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { useAppAuthState } from '../authState';

export const useAdminShops = () => {
    const { shopsWithPermissions } = useAppAuthState();
    const [adminShops, setAdminShops] = useState(null);
    const [adminShopsLoading, setAdminShopsLoading ] = useState(true);
    const [adminShopsError, setAdminShopsError ] = useState(null);

    useEffect(() => {
        async function fetchAdminShops() {
            setAdminShopsLoading(true);
            setAdminShopsError(null);
            try {
                const res = await Promise.all(shopsWithPermissions.map(async (shopId) => {
                    try {
                        const shopDocument = await firebase.firestore().collection('shops').doc(shopId).get();
                        return ({ [shopId]: shopDocument.data() });
                    } catch (error) {
                        console.error(`could not load shop data for ${shopId}`, error);
                        return ({ [shopId]: null });
                    }
                }))

                const resObj = res.reduce((agg, value) => {
                    if (value) return { ...agg, ...value }
                    else return agg
                }, {});
                setAdminShops(resObj)
            } catch (error) {
                console.error('fetchAdminShops', error);
                setAdminShopsError(error);
            } finally {
                setAdminShopsLoading(false);
            }
        }

        if (shopsWithPermissions?.length > 0) {
            fetchAdminShops()
        }

    }, [shopsWithPermissions])

    return { adminShops, adminShopsLoading, adminShopsError };
};
