import React, { createContext, useContext, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

import useShop from '../hooks/useShop';
import useShopUsers from '../hooks/useShopUsers';
import useShopHostsStatus from '../hooks/useShopHostsStatus';
import useShopInvites from '../hooks/useShopInvites';
import useShopSubscription from '../hooks/useShopSubscription';
import useShopBilling from '../hooks/useShopBilling';
import usePricingPlans from '../hooks/usePricingPlans';
import useShopOnlineStoreConfig from '../hooks/useShopOnlineStoreConfig';
import useShopBusinessHours from '../hooks/useShopBusinessHours';
import useShopSchedulingConfig from '../hooks/useShopSchedulingConfig';
import useShopProductsConfig from '../hooks/useShopProductsConfig';
import useShopChatConfig from '../hooks/useShopChatConfig';
import useShopCallPolicyConfig from '../hooks/useShopCallPolicyConfig';
import useShopLocales from '../hooks/useShopLocales';
import useClientAppConfig from '../hooks/useClientAppConfig';
import useCustomerProfileConfig from '../hooks/useCustomerProfileConfig';
import useShopFeedbackConfig from '../hooks/useShopFeedbackConfig';
import useCampaigns from '../hooks/useCampaigns';
import useShopTeams from '../hooks/useShopTeams';
import useHostSelection from '../hooks/useHostSelection';
import useShopEventTypes from '../hooks/useShopEventTypes';
import useShopHeadlessConfig from '../hooks/useShopHeadlessConfig';
import useShopVideos from '../hooks/useShopVideos';
import useStoriesAppConfig from '../hooks/useStoriesAppConfig';

export const ShopContext = createContext(null);

export default function ShopProvider({shopOrigin, adminRole, children}) {

  // any shop role can read
  const {users, hosts, usersLoading, usersError, updateHostHiddenState, updateHostBio} = useShopUsers(shopOrigin);
  const {shopData, shopDataLoading} = useShop(shopOrigin);
  const {businessHoursConfig, businessHoursLoading} = useShopBusinessHours(shopOrigin);
  const {shopLocales, shopLocalesLoading} = useShopLocales(shopOrigin);
  const {shopUserTeams} = useShopTeams(shopOrigin);
  const {eventTypes, eventTypesLoading} = useShopEventTypes(shopOrigin);
  const {plansData: pricingPlans, plansDataLoading, activePlansData} = usePricingPlans();
  const {chatConfig, chatConfigLoading} = useShopChatConfig(shopOrigin);
  const {customerProfileConfig, customerProfileConfigLoading} = useCustomerProfileConfig(shopOrigin);
  const {feedbackConfig, feedbackConfigLoading} = useShopFeedbackConfig(shopOrigin);
  const {hostSelectionConfig, hostSelectionConfigLoading, setHostSelectionConfig} = useHostSelection(shopOrigin);
  const {shopVideos, shopVideosLoading} = useShopVideos(shopOrigin);

  // only admin
  const {hostsStatus, hostsStatusLoading, hostStatusError} = useShopHostsStatus(shopOrigin, adminRole);
  const {invites, invitesLoading} = useShopInvites(shopOrigin, adminRole);
  const {subData, subDataLoading, subDataError} = useShopSubscription(shopOrigin, adminRole);
  const {currentBillingPeriod, billingLoading} = useShopBilling(shopOrigin, adminRole);
  const {
    onlineStoreConfig,
    onlineStoreConfigLoading,
    saveOnlineStoreConfig
  } = useShopOnlineStoreConfig(shopOrigin, adminRole);
  const {callPolicyConfig, callPolicyConfigLoading} = useShopCallPolicyConfig(shopOrigin, adminRole);
  const {appStylesConfig, appStylesConfigLoading} = useClientAppConfig(shopOrigin, adminRole);
  const {productsConfig, productsConfigLoading} = useShopProductsConfig(shopOrigin, adminRole);
  const {campaigns, campaignsLoading} = useCampaigns(shopOrigin, adminRole);
  const {headlessConfig, headlessConfigLoading} = useShopHeadlessConfig(shopOrigin, adminRole);
  const { storiesAppConfig, storiesAppConfigLoading } = useStoriesAppConfig(shopOrigin, adminRole);

  // admin + calendar_admin
  const {schedulingConfig, schedulingConfigLoading} = useShopSchedulingConfig(shopOrigin, adminRole);

  useEffect(() => {
    if (shopOrigin) {
      firebase.analytics().setUserProperties({shop_id: shopOrigin});
    }

  }, [shopOrigin])

  return <ShopContext.Provider value={{
    shopOrigin, shopData, shopDataLoading,
    users, hosts, usersLoading, usersError, updateHostHiddenState, updateHostBio,
    hostsStatus, hostsStatusLoading, hostStatusError,
    invites, invitesLoading,
    subData, subDataLoading, subDataError,
    currentBillingPeriod, billingLoading,
    onlineStoreConfig, onlineStoreConfigLoading, saveOnlineStoreConfig,
    schedulingConfig, schedulingConfigLoading,
    callPolicyConfig, callPolicyConfigLoading,
    businessHoursConfig, businessHoursLoading,
    pricingPlans, activePlansData, plansDataLoading,
    shopLocales, shopLocalesLoading,
    appStylesConfig, appStylesConfigLoading,
    productsConfig, productsConfigLoading,
    chatConfig, chatConfigLoading,
    customerProfileConfig, customerProfileConfigLoading,
    feedbackConfig, feedbackConfigLoading,
    campaigns, campaignsLoading,
    shopUserTeams,
    hostSelectionConfig, hostSelectionConfigLoading, setHostSelectionConfig,
    shopVideos, shopVideosLoading,
    eventTypes, eventTypesLoading,
    headlessConfig, headlessConfigLoading,
    storiesAppConfig, storiesAppConfigLoading,
  }}>
    {children}
  </ShopContext.Provider>;
}

export function useShopProvider() {
  const context = useContext(ShopContext);
  if (!context) {
    throw new Error('useShopProvider must be used within the ShopContextProvider');
  }
  return context;
}
