import AdminPage from "../../components/AdminPage";
import React from "react";
import AppPage from "../../components/AppPage";
import {
  Layout,
} from '@shopify/polaris';
import {useHistory} from "react-router-dom";
import CallFeedbackList from './CallFeedbackList';

const CallFeedback = () => {
  const history = useHistory();

  return (
    <AppPage
      title='Call Feedback' 
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}     
    >
      <Layout>
        <Layout.Section>
          <CallFeedbackList />
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <CallFeedback />
    </AdminPage>
  )
}
