import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { Layout, Card, Stack, TextStyle, Checkbox, SkeletonBodyText, TextContainer } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { useShopProvider } from '../../components/ShopProvider';

const DEFAULT_CONFIG = {}

export function ChatSettingsSummary({chatConfig}) {

    const config = Object.assign({}, DEFAULT_CONFIG, chatConfig);
    const enabled = config.enabled;

    return <Stack vertical spacing='tight'>
        return <p>Client chat is <TextStyle variation='strong'>{enabled ? 'enabled' : 'disabled'}</TextStyle></p>
    </Stack>
}

function ChatSettings() {
    const history = useHistory();
    const { chatConfig, chatConfigLoading, shopOrigin} = useShopProvider();

    const [enabled, setEnabled] = useState(false);

    useEffect(()=>{
        if (chatConfig) {
            const config = Object.assign({}, DEFAULT_CONFIG, chatConfig);
            setEnabled(config.enabled);
        }

    }, [chatConfig])

    const onEnabledChange = (newChecked) => {
        setEnabled(newChecked);
        Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData')
            .doc('chat').set({ enabled: newChecked }, { merge: true })
            .catch(error => console.error('onEnabledChange', error));
    }


    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Chat Settings'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        {chatConfigLoading && <SkeletonBodyText />}
                        {!chatConfigLoading &&
                            <Stack vertical>
                                <TextContainer spacing='tight'>
                                    <p>Chat allows clients and hosts to share text and image messages before starting or scheduling video calls</p>
                                    <p><TextStyle variation='subdued'>(chat while on a live video call is always available to the call's participants)</TextStyle> </p>
                                </TextContainer>
                                <Checkbox
                                    label='Enable client chat'
                                    checked={enabled}
                                    onChange={onEnabledChange}
                                />
                            </Stack>
                        }
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <ChatSettings />
        </AdminPage>
    )
}
