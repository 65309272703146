
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function useShopServices(shopId) {

    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('services').doc('default') : null;
    const [defaultServiceData, defaultServiceLoading, defaultServiceError] = useDocumentData(ref);

    const setDefaultService = async (data) => {
        await ref.set(data, { merge: true })

    }

    return { defaultServiceData, defaultServiceLoading, defaultServiceError, setDefaultService };
}
