import { DEFAULT_COLOR } from '../../../../utils/consts';

export const DEFAULT_STORIES_WIDGET_SIZE = 'collapsed';
export const DEFAULT_STORIES_WIDGET_COLOR = '#FFFFFF';

export const EXPAND_STORIES_WIDGET_SIZE = 'expanded';

export const TOP_LEFT_POSITION = 'top_left';
export const TOP_RIGHT_POSITION = 'top_right';
export const BOTTOM_LEFT_POSITION = 'bottom_left'
export const BOTTOM_RIGHT_POSITION = 'bottom_right'

const TOP_LEFT_POSITION_LABEL = 'Top Left';
const TOP_RIGHT_POSITION_LABEL = 'Top Right';
const BOTTOM_LEFT_POSITION_LABEL = 'Bottom Left'
const BOTTOM_RIGHT_POSITION_LABEL = 'Bottom Right'

export const TOP_POSITION = 'Top';
export const BOTTOM_POSITION = 'Bottom';

export const SIZE_CONFIG = [
  { label: DEFAULT_STORIES_WIDGET_SIZE, value: DEFAULT_STORIES_WIDGET_SIZE },
  { label: EXPAND_STORIES_WIDGET_SIZE, value: EXPAND_STORIES_WIDGET_SIZE }
]

export const DEFAULT_POSITION_CONFIG = [
  { label: TOP_LEFT_POSITION_LABEL, value: TOP_LEFT_POSITION },
  { label: TOP_RIGHT_POSITION_LABEL, value: TOP_RIGHT_POSITION },
  { label: BOTTOM_LEFT_POSITION_LABEL, value: BOTTOM_LEFT_POSITION },
  { label: BOTTOM_RIGHT_POSITION_LABEL, value: BOTTOM_RIGHT_POSITION },
]

export const ACTION_TYPE_LABELS = {
  NOT_SELECTED: 'none',
  ADD_TO_CART: 'Add to cart',
  SHOW_PRODUCT_PAGE: 'View product',
  GO_TO_PAGE: 'Go to page',
  OPEN_BOUTQ: 'Video call'
}

export const ACTION_TYPES = {
  NOT_SELECTED: 'not_selected',
  ADD_TO_CART: 'add_to_cart',
  SHOW_PRODUCT_PAGE: 'view_product',
  GO_TO_PAGE: 'go_to_page',
  OPEN_BOUTQ: 'open_boutq',
}

export const DEFAULT_PRODUCT_STORIES_CONFIG = {
  color: DEFAULT_STORIES_WIDGET_COLOR,
  position: TOP_LEFT_POSITION,
  size: DEFAULT_STORIES_WIDGET_SIZE,
  showFrom: '0',
  showUntil: '0',
  action: {
    type: ACTION_TYPES.ADD_TO_CART,
    label: ACTION_TYPE_LABELS.ADD_TO_CART,
    color: DEFAULT_COLOR
  }
}

export const DEFAULT_BOUTIQ_PRODUCT_STORIES_CONFIG = {
  color: DEFAULT_STORIES_WIDGET_COLOR,
  position: TOP_LEFT_POSITION,
  size: DEFAULT_STORIES_WIDGET_SIZE,
  showFrom: '0',
}

