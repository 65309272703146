export const getContrast = (hexcolor, alpha = 1, base = null) => {

	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}
	
	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex;
		}).join('');
	}

	if (base) {
		if (base.slice(0, 1) === '#') {
			base = base.slice(1);
		}

		if (base.length === 3) {
			base = base.split('').map(function (hex) {
				return hex + hex;
			}).join('');
		}
	}

	// Convert to RGB value
	let r;
	let g;
	let b;
	if (base) {
		const rgba = hexToRgb(hexcolor, alpha);
		base = hexToRgb(base);
		const mergedColor = blendColors(rgba, base);
		r = mergedColor[0]
		g = mergedColor[1]
		b = mergedColor[2]
	} else {
		const rgba = hexToRgb(hexcolor, alpha);
		r = rgba[0]
		g = rgba[1]
		b = rgba[2]
	}
	// Get YIQ ratio
	let yiq = ((r * 299) + (g * 587) + (b * 114) + (alpha * 256)) / 1000 / alpha;

	// Check contrast
	return (yiq >= 128);

}

const hexToRgb = (hexcolor, alpha = 1) => {
	let r = parseInt(hexcolor.substr(0, 2), 16);
	let g = parseInt(hexcolor.substr(2, 2), 16);
	let b = parseInt(hexcolor.substr(4, 2), 16);
	return ([r, g, b, alpha])
}

const blendColors = (added, base) => {
	var mix = [];
	mix[3] = 1 - (1 - added[3]) * (1 - base[3]); // alpha
	mix[0] = Math.round((added[0] * added[3] / mix[3]) + (base[0] * base[3] * (1 - added[3]) / mix[3])); // red
	mix[1] = Math.round((added[1] * added[3] / mix[3]) + (base[1] * base[3] * (1 - added[3]) / mix[3])); // green
	mix[2] = Math.round((added[2] * added[3] / mix[3]) + (base[2] * base[3] * (1 - added[3]) / mix[3])); // blue
	return mix;
}
