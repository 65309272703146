import React, { useState, useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Layout, Card, Stack ,TextField, InlineError, TextStyle, Button, ContextualSaveBar } from '@shopify/polaris';

import {
    DeleteMinor,
    PlusMinor
  } from '@shopify/polaris-icons';


import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import { UserRole } from '../../../utils/permissions'
import { useShopProvider } from '../../../components/ShopProvider'
import SchedulingSuccess from './SchedulingSuccess';
import { compareObjects } from '../../../utils/objects';
import { SchedulingEventDuration, SchedulingEventPadding } from '../../../components/settings/SchedulingEvent';

import { DEFAULT_LOOKAHEAD_DAYS, DEFAULT_SLOT_DURATION } from '../../../utils/consts';
const MAX_LOOKAHEAD_DAY = 14;
const DEFAULT_CLIENT_ALERT_TIME = 10;
const  DEFAULT_CLIENT_ALERT = { default: DEFAULT_CLIENT_ALERT_TIME };

function Scheduling() {

    const history = useHistory();
    const [ dirtyBit, setDirtyBit ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ slotDuration, setSlotDuration ] = useState(DEFAULT_SLOT_DURATION);
    const [ minNotice, setMinNotice ] = useState(null);
    const [ paddingAfter, setPaddingAfter ] = useState(null);
    const [ paddingAfterError, setPaddingAfterError] = useState(null);
    const [ lookAheadDays, setLookAheadDays] = useState(DEFAULT_LOOKAHEAD_DAYS);
    const [ lookAheadDaysError, setLookAheadDaysError] = useState(null);
    const [alertConfig, setAlertConfig] = useState({...DEFAULT_CLIENT_ALERT});
    const { shopOrigin, schedulingConfig, schedulingConfigLoading } = useShopProvider();

    const buildFromState = () => {
        return {
            slotDuration: slotDuration,
            lookAheadDays: parseInt(lookAheadDays),
            minNotice: isNaN(parseInt(minNotice)) ? null : parseInt(minNotice),
            paddingAfter: !!paddingAfter ? paddingAfter : null,
            alertConfig: Object.entries(alertConfig).map(([id, minsBefore]) => ({ id, minsBefore, type: UserRole.participant })),
        }
    }

    const buildFromConfig = (schedulingConfig) => {
        return {
            slotDuration: schedulingConfig?.slotDuration ?? DEFAULT_SLOT_DURATION,
            lookAheadDays: schedulingConfig?.lookAheadDays ?? DEFAULT_LOOKAHEAD_DAYS,
            minNotice: schedulingConfig?.minNotice ?? null,
            paddingAfter: schedulingConfig?.paddingAfter ?? null,
            alertConfig: schedulingConfig?.alertConfig ?? [{ id: 'default', minsBefore: DEFAULT_CLIENT_ALERT_TIME, type: UserRole.participant }],
        }
    }

    const loadFromConfig = (config) => {
        setSlotDuration(config.slotDuration);
        setMinNotice(config.minNotice ?? '');
        setPaddingAfter(config.paddingAfter ?? '');
        setLookAheadDays(config.lookAheadDays);
        setAlertConfig(config.alertConfig.reduce((agg, a) => {
            agg[a.id] = a.minsBefore;
            return agg;
        }, {}));
    }

    useEffect(() => {
        if (!schedulingConfigLoading) {
            let config = buildFromConfig(schedulingConfig);
            loadFromConfig(config);
        }
    }, [schedulingConfig, schedulingConfigLoading]);

    useEffect(() => {
        const newConfig = buildFromState();
        const currentConfig = buildFromConfig(schedulingConfig);
        setDirtyBit(!compareObjects(newConfig,currentConfig));
    }, [slotDuration,lookAheadDays, minNotice, paddingAfter, alertConfig, schedulingConfig ])


    const onLookaheadChange = (days) => {
        const newVal = parseInt(days);
        setLookAheadDays(newVal);
        if (newVal < 1 || newVal > MAX_LOOKAHEAD_DAY) {
            setLookAheadDaysError(`value should be between 1 day to ${MAX_LOOKAHEAD_DAY} days`);
        } else {
            setLookAheadDaysError(null);
        }
    }

    const onMinNoticeChange = (val) => {
        const newVal = isNaN(parseInt(val)) ? '' : parseInt(val);
        setMinNotice(newVal);
    }

    const onAlertChange = (alertId, val) => {
        setAlertConfig(prev => {
            let update = {...prev}
            update[alertId] = parseInt(val)
            return update;
        });
    }

    const onAlertDelete = (alertId) => {
        setAlertConfig(prev => {
            let update = {...prev}
            delete update[alertId];
            return update;
        });

    }

    const onAlertAdd = () => {
        setAlertConfig(prev => {
            let update = {...prev}
            update[Date.now().toString()] = 10;
            return update;
        });

    }

    const onSave = async () => {

        setSaving(true);
        try {
            const newConfig = buildFromState();
            await Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('scheduling').set({ ...newConfig }, { merge: true });
        } catch (error) {
            console.error('scheduling config', error)
        } finally {
            setSaving(false);
        }
    }

    const onDiscard = () => {
        let config = buildFromConfig(schedulingConfig);
        loadFromConfig(config);
        setLookAheadDaysError(null);
        setPaddingAfterError(null);
    }

    return (
        <AppPage title='Scheduling - Advanced' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.AnnotatedSection
                    title="Scheduling defaults"
                    description='Manage scheduling options presented to clients'>
                    <Card>
                        <Card.Section title='Appointment duration'>
                            <SchedulingEventDuration duration={slotDuration} onChange={setSlotDuration}/>
                        </Card.Section>
                        <Card.Section title='Minimum scheduling notice'>
                            <Stack vertical spacing='tight'>
                                <p> How close to an appointment slot can clients book with you - prevent last minute appointments  </p>
                                <Stack alignment='center'>
                                    <p>Minimum</p>
                                    <div style={{ maxWidth: '200px' }}>
                                        <TextField
                                            type='number'
                                            min={0}
                                            placeholder={`${slotDuration} (default)`}
                                            value={minNotice}
                                            onChange={onMinNoticeChange}
                                            suffix='minutes'
                                        />
                                    </div>
                                    <p>in advance of an appointment</p>
                                </Stack>
                            </Stack>
                        </Card.Section>
                        <Card.Section title='Appointment padding'>
                            <SchedulingEventPadding paddingAfter={paddingAfter} duration={slotDuration} onChange={setPaddingAfter} onError={setPaddingAfterError}/>
                        </Card.Section>
                        <Card.Section title='Scheduling look-ahead days'>
                            <Stack vertical spacing='tight'>
                                <p>
                                    Set how many days into the future clients can select to make appointments
                            </p>
                                <p><TextStyle variation='subdued'>Minimum is 1 day (only for today) and maximum is {MAX_LOOKAHEAD_DAY} days (from today to {MAX_LOOKAHEAD_DAY} days from today)</TextStyle></p>
                                <TextField
                                    label="Days"
                                    type='number'
                                    min={1}
                                    max={MAX_LOOKAHEAD_DAY}
                                    value={lookAheadDays}
                                    onChange={onLookaheadChange}
                                    disabled={schedulingConfig?.lookAheadDays > MAX_LOOKAHEAD_DAY }
                                />
                            { lookAheadDaysError && <InlineError message={lookAheadDaysError}/>}
                            </Stack>
                        </Card.Section>
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                title="Scheduling reminders"
                description='Manage appointment reminders sent to clients'
                >
                    <Card sectioned>
                        <Stack vertical spacing='tight'>
                            {Object.entries(alertConfig).map(([id, alert]) => (
                                <Stack key={id} >
                                    <Stack.Item fill>
                                        <Stack alignment='center'>
                                            <div style={{ maxWidth: '150px' }}>
                                                <TextField
                                                    type='number'
                                                    min={0}
                                                    max={3 * 24 * 60}
                                                    value={alert}
                                                    onChange={(val) => onAlertChange(id, val)}
                                                    suffix='minutes'
                                                />
                                            </div>
                                            <p>before scheduled start time</p>
                                        </Stack>
                                    </Stack.Item>
                                    <Button icon={DeleteMinor} outline destructive onClick={()=> onAlertDelete(id)}/>
                                </Stack>
                            ))}
                            <Button icon={PlusMinor} outline onClick={onAlertAdd}>Add reminder</Button>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>
                <SchedulingSuccess schedulingConfig={schedulingConfig} schedulingConfigLoading={schedulingConfigLoading}/>
            </Layout>
            {dirtyBit && <ContextualSaveBar
                message="Save chnages to advanced scheduling configuration"
                saveAction={{
                    onAction: onSave,
                    loading: saving,
                    disabled: paddingAfterError || lookAheadDaysError,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: saving,
                }}
            />}
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <Scheduling/>
        </AdminPage>
    )
}
