import React, { useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { Stack, List, Layout, Card, Banner, SkeletonBodyText, Toast, Button, Subheading } from '@shopify/polaris';
import CopyToClipboard from 'react-copy-to-clipboard';

import { DuplicateMinor } from '@shopify/polaris-icons';

import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'

import { useShopProvider } from '../../../components/ShopProvider';
import useShopLinks from '../../../hooks/useShopLinks';
import VimeoVideoModal from '../../../components/VimeoVideoModal';


function InivtationsPage({ }) {
    const { shopOrigin } = useShopProvider();
    const { links, linksLoading } = useShopLinks(shopOrigin);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [videoModal, showVideoModal] = useState(false);
    const defaultLinks = links ? links.docs.filter(d => d.data().default && !d.data().hostId) : [];
    const defaultLinkUrl = defaultLinks[0]?.data()?.callLinkUrl;


    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);

    const toastMarkup = showToast ? (
        <Toast content="Call Link copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;


    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Invitations, Emails, &amp; Social Media'
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned title='Learn how to add Boutiq links to invitations, emails, social media posts, and ad campaigns'>
                        <Stack vertical>
                            <p>When a client clicks on a Boutiq call link inside an email or social media post, they will be redirected to your online store to start a new video call and/or schedule a future call.</p>
                            <Banner>
                                <p><Button plain monochrome onClick={() => showVideoModal(true)}>View a short video tutorial</Button> on how to use Boutiq links</p>
                            </Banner>
                            {linksLoading && <SkeletonBodyText />}
                            {!linksLoading && <Stack vertical spacing='loose'>
                                <Subheading>Steps:</Subheading>
                                <List type='number'>
                                    <List.Item>
                                        <Stack vertical spacing='tight'>
                                            <p>Choose a Boutiq call Link:</p>
                                            <p>Your store's default Boutiq call link is:</p>
                                            <Stack alignment='center'>
                                                <span className='code-snippet code-background'>{defaultLinkUrl ?? 'not set'}</span>
                                                {defaultLinkUrl && <CopyToClipboard text={defaultLinkUrl} onCopy={toggleToastActive}>
                                                    <Button icon={DuplicateMinor} />
                                                </CopyToClipboard>}
                                            </Stack>
                                            <p>Or you can create new call links and set their behavior in the <Link to='/shop_links'>Call Link settings</Link> page.</p>
                                        </Stack>
                                    </List.Item>
                                    <List.Item>Copy the call link URL and use it in invitations, emails, and social media posts to invite clients to start or schedule calls</List.Item>
                                </List>
                                <Banner status='warning' title='Social media apps'>
                                    <p>Social media apps (such as Facebook &amp; Instagram) on mobile devices open links in embedded in-app browsers that might not support real-time video calls.
                                        It's recommended to use scheduling-only links for posts on these apps.
                                    </p>
                                </Banner>
                            </Stack>}
                        </Stack>
                    </Card>
                </Layout.Section>
                <VimeoVideoModal
                    open={videoModal}
                    onClose={() => showVideoModal(false)}
                    videoUrl='https://player.vimeo.com/video/740804539#t=10m49s?h=887c30bd41&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                    videoTitle='Integration Options'
                />
            </Layout>
            {toastMarkup}
        </AppPage>

    )
}

export default function () {
    return (
        <AdminPage>
            <InivtationsPage />
        </AdminPage>
    )
}