import React, {useState, useCallback, useRef, useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { TwitterPicker } from 'react-color';
import {CancelSmallMinor} from "@shopify/polaris-icons";
import {Layout, Card, TextField, ContextualSaveBar, Stack, Select, Button, Icon, Checkbox, TextStyle,} from '@shopify/polaris';

import { COLOR_PICKER_DEFAULTS } from '../../utils/consts';
import { isStringNonEmpty } from '../../utils/strings';

import './index.css';
import {translations} from "./translations";

import {
  CTA_ACTION_TYPE,
  CAMPAIGN_TYPES,
  CURRENT_URL_OPTIONS,
  RULES,
  OPEN_SHOP_OPTIONS,
  TIME_PAGE_OPTIONS,
  AUTO_POP_UP_DEFAULTS,
  FIELD_KEYS
} from '../../constants/campaigns';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage';
import ClientPopUpMockup from "../../components/mockup/ClientPopUpMockup";
import UrlRuleBlock from "../../components/urlRuleBlock";
import TimeOnPageBlock from "../../components/campaigns/timeOnPageBlock";
import ShopOpenBlock from "../../components/campaigns/shopOpenBlock";
import WidgetBlock from "../../components/campaigns/widgetBlock";
import {useShopProvider} from "../../components/ShopProvider";
import {ReactComponent as WidgetIcon} from "../../components/Widget/icon/WidgetIcon.svg";
import { useCampaignsActions } from "../../hooks/useCampaigns";
import useCaazamREST from "../../hooks/useCaazamREST";

import { DEFAULT_COLOR, DEFAULT_CORNER_STYLE, WYSIWYG_TEXT_EDITOR_OPTIONS } from '../../utils/consts';
import { WysiwygTextEditor } from '../../components/WysiwygTextEditor';
import QueryParamBlock from "../../components/campaigns/queryParamBlock";
import { formatUploadFilename } from '../../utils/formatters';

function CreateCampaign() {
  let {id: currentCampaignId} = useParams();
  const history = useHistory();
  const fileInput = useRef(null);
  const { uploadCampaignImage } = useCaazamREST();
  const {appStylesConfig, onlineStoreConfig, shopOrigin, shopLocales, campaigns, campaignsLoading } = useShopProvider();
  const { addCampaign, updateCampaign } = useCampaignsActions(shopOrigin);

  const themeCornerStyle = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.cornerStyle) || DEFAULT_CORNER_STYLE;
  const defaultCampaignColor = (onlineStoreConfig?.methodConfig?.color) || DEFAULT_COLOR;

  const buildDefaultRule = (id = new Date().valueOf(), type = RULES[0].value, value = null) => ({id, type, value});
  const buildLocales = useMemo(() => shopLocales.map(l => ({label: l, value: l})), []);

  const initialState = useRef(null);

  const [saving, setSaving] = useState(false);
  const [errorState, setErrorsState] = useState(false);
  const [showApiError, setApiError] = useState(null);
  const [campaignNameError, setCampaignNameError] = useState(null);
  const [buttonNameError, setButtonNameError] = useState(null);
  const [currentUrlError, setCurrentUrlError] = useState(null);
  const [queryParamNameError, setQueryParamNameError] = useState(null);
  const [queryParamValueError, setQueryParamValueError] = useState(null);

  const [autoPopUpConfig, setAutoPopUpConfig] = useState(AUTO_POP_UP_DEFAULTS);
  const [campaignType, setCampaignType] = useState(CAMPAIGN_TYPES[0].value);
  const [campaignLocale, setCampaignLocale] = useState(buildLocales[0].value);
  const [helperText, setHelperText] = useState(onlineStoreConfig?.methodConfig?.helperText || '');
  const [actionType, setActionType] = useState(translations[campaignLocale].button.find(b => b.value === CTA_ACTION_TYPE[0].value).value);
  const [title, setTitle] = useState(translations[campaignLocale].header);
  const [isEnabled, enableCampaign] = useState(true);
  const [isTestingMode,  setTestingMode] = useState(false);
  const [cta, setCTA] = useState(CTA_ACTION_TYPE[0].label);
  const [rules, addRule] = useState([{id: new Date().valueOf(), type: RULES[1].value, value: '30'}]);
  const [image, setImage] = useState(null);
  const [tempImage, setTempImageUrl] = useState(`${process.env.REACT_APP_CONFIG_BUCKET.replace('gs://', 'https://storage.googleapis.com/')}/campaigns/default_hero_image.png`);
  const [time, setTime] = useState('30');
  const [selectedTimeRule, setSelectedTimeRule] = useState(TIME_PAGE_OPTIONS[0].value);
  const [url, setUrl] = useState('');
  const [queryParameterValue, setQueryParameterValue] = useState('');
  const [queryParameterName, setQueryParameterName] = useState('');
  const [selectedUrlRule, setSelectedUrlRule] = useState(CURRENT_URL_OPTIONS[0].value);
  const [isShopOpened, setSelectedOpenShopRule] = useState(OPEN_SHOP_OPTIONS[0].value);
  const [campaignName, setCampaignName] = useState('');
  const [buttonOptions, setButtonOptions] = useState(CTA_ACTION_TYPE);
  const [description, setDescription] = useState(translations[campaignLocale].description);
  const [isCampaignUpdated, setCampaignStatus] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(null);

  const changeRules = useCallback((newValue) => handleCampaignChanges(addRule, FIELD_KEYS.RULES, newValue), []);
  const setName = useCallback((newValue) => handleCampaignChanges(setCampaignName, FIELD_KEYS.NAME, newValue), []);
  const changeTemporaryImage = useCallback((newValue) => handleCampaignChanges(setTempImageUrl, FIELD_KEYS.TEMP_IMAGE, newValue), []);
  const changeHelperText = useCallback((newValue) => handleCampaignChanges(setHelperText, newValue), []);
  const changeCampaignState = useCallback((newChecked) => handleCampaignChanges(enableCampaign, FIELD_KEYS.ENABLED, newChecked), []);
  const changeCampaignMode = useCallback((newChecked) => handleCampaignChanges(setTestingMode, FIELD_KEYS.TEST, newChecked), []);
  const changeTitle = useCallback((newValue) => handleCampaignChanges(setTitle, FIELD_KEYS.TITLE, newValue), []);
  const changeButtonName = useCallback((newValue) => handleCampaignChanges(setCTA, FIELD_KEYS.BUTTON_NAME, newValue), []);
  const changeDescription = useCallback((newValue) => handleCampaignChanges(setDescription, FIELD_KEYS.DESCRIPTION, newValue), []);
  const changeColor = useCallback((newValue) => handleCampaignChanges(onColorChange, FIELD_KEYS.COLOR, newValue), []);
  const handleCampaignTypeChange = useCallback((value) => handleCampaignChanges(setCampaignType, value), []);

  const handleCampaignLocaleChange = useCallback((value) => {
    return handleCampaignChanges(() => {
      setCampaignLocale(value);
      setTitle(translations[value].header);
      setDescription(translations[value].description);
      setCTA(translations[value].button.find(b => b.value === actionType).label);
      setButtonOptions(translations[value].button);
    }, FIELD_KEYS.LANG, value)
  }, [actionType]);
  const handleCTAActionChange = useCallback((value) => {
    return handleCampaignChanges(() => {
      setButtonOptions(translations[campaignLocale].button);
      setActionType(value);
      setCTA(translations[campaignLocale].button.find(b => b.value === value).label);
    }, FIELD_KEYS.BUTTON_ACTION, value)
  }, [campaignLocale]);
  const changeTime = useCallback((newValue) => handleCampaignChanges(setTime, FIELD_KEYS.TIME_ON_PAGE, newValue < 0 ? '0' : `${newValue}`), []);
  const handleSelectUrlChange = useCallback((value) => handleCampaignChanges(setSelectedUrlRule, FIELD_KEYS.CURRENT_URL_SELECTED, value), []);
  const changeUrl = useCallback((newValue) => handleCampaignChanges(setUrl, FIELD_KEYS.CURRENT_URL, newValue), []);
  const setQueryParamName = useCallback((newValue) => handleCampaignChanges(setQueryParameterName, FIELD_KEYS.QUERY_PARAM_NAME, newValue), [queryParameterValue]);
  const setQueryParamValue = useCallback((newValue) => handleCampaignChanges(setQueryParameterValue, FIELD_KEYS.QUERY_PARAM_VALUE, newValue), [queryParameterName]);
  const handleSelectOpenShopChange = useCallback((value) => handleCampaignChanges(setSelectedOpenShopRule, FIELD_KEYS.SHOP_OPEN_SELECTED, value), []);

  const initPopUpParams = (campaign) => {
    setTitle(campaign.header);
    setDescription(campaign.description);
    setCTA(campaign.cta);
    setCampaignLocale(campaign.locale);
    setTempImageUrl(campaign.imageUrl);
    setActionType(CTA_ACTION_TYPE.find(action => action.value === campaign.actionType).value);

    const currentRules = [];
    if (campaign.trigger.currentUrl && campaign.trigger.currentUrl.id) {
      setUrl(campaign.trigger.currentUrl.value);
      setSelectedUrlRule(campaign.trigger.currentUrl.rule);
      currentRules.push({id : campaign.trigger.currentUrl.id, type: RULES[0].value, value: campaign.trigger.currentUrl.value})
    }
    if (campaign.trigger.timeOnPage && campaign.trigger.timeOnPage.id) {
      setTime(campaign.trigger.timeOnPage.value);
      setSelectedTimeRule(campaign.trigger.timeOnPage.rule);
      currentRules.push({id : campaign.trigger.timeOnPage.id, type: RULES[1].value, value: campaign.trigger.timeOnPage.value})
    }
    if (campaign.trigger.shopOpen && campaign.trigger.shopOpen.id) {
      setSelectedOpenShopRule(campaign.trigger.shopOpen.value);
      currentRules.push({id: campaign.trigger.shopOpen.id, type: RULES[2].value, value: campaign.trigger.shopOpen.value})
    }
    if (campaign.trigger.queryParam && campaign.trigger.queryParam.id) {
      setQueryParameterName(campaign.trigger.queryParam.rule);
      setQueryParameterValue(campaign.trigger.queryParam.value);
      currentRules.push({id: campaign.trigger.queryParam.id, type: RULES[3].value, value: campaign.trigger.queryParam.value})
    }
    addRule(currentRules);
  }

  const initParams = (campaign) => {
    setCampaignType(campaign.type);
    setCampaignName(campaign.name);
    setPrimaryColor(campaign.primaryColor || null);
    enableCampaign(campaign.enabled);
    setTestingMode(campaign.testMode || false);
    if (campaign.type === CAMPAIGN_TYPES[0].value) {
      initPopUpParams(campaign);
    } else {
      setAutoPopUpConfig(campaign.trigger)
      setHelperText(campaign.helperText)
    }
  }

  const initUpdates = () => ({
    NAME: false,
    LANG: false,
    ENABLED: false,
    TEST: false,
    COLOR: false,
    TITLE: false,
    BUTTON_ACTION: false,
    BUTTON_NAME: false,
    CURRENT_URL: false,
    CURRENT_URL_SELECTED: false,
    TIME_ON_PAGE: false,
    SHOP_OPEN: false,
    SHOP_OPEN_SELECTED: false,
    TEMP_IMAGE: false,
    DESCRIPTION: false,
  })

  const getClearedTriggers = (triggers) => {
    const clearedTriggers = {...triggers};
    Object.keys(clearedTriggers).forEach(key => {
      if (!clearedTriggers[key]) {
        delete clearedTriggers[key];
      }
    });
    return clearedTriggers;
  }

  useMemo(() => {
    if (currentCampaignId) {
      const index = campaigns.findIndex(c => `${c.id}` === currentCampaignId);
      if (index !== -1) {
        initialState.current = {
          campaign: {
            ...campaigns[index],
            trigger: {
              ...getClearedTriggers(campaigns[index].trigger),
            },
          },
          updates: initUpdates()
        }
        initParams(campaigns[index]);
        return campaigns[index];
      }
    } else {
      initialState.current = {
        campaign: {
          actionType: translations[campaignLocale].button.find(b => b.value === CTA_ACTION_TYPE[0].value).value,
          cta: CTA_ACTION_TYPE[0].label,
          description: translations[campaignLocale].description,
          primaryColor,
          enabled: true,
          header: translations[campaignLocale].header,
          imageUrl: tempImage,
          locale: buildLocales[0].value,
          name: null,
          trigger: {
            timeOnPage: {id: new Date().valueOf(), type: RULES[1].value, value: '30'}
          },
          type: CAMPAIGN_TYPES[0].value,
        },
        updates: initUpdates()
      }
    }
  }, [currentCampaignId, campaigns, campaignsLoading])

  const checkErrors = (key, value) => {
    switch (key) {
      case FIELD_KEYS.NAME: {
        if (isStringNonEmpty(value)){
          setCampaignNameError(null)
          setErrorsState(false);
        } else {
          setCampaignNameError('Campaign name cannot be empty')
          setErrorsState(true);          
        }
        return;
      }
      case FIELD_KEYS.BUTTON_NAME: {
        if (isStringNonEmpty(value)) {
          setButtonNameError(null)
          setErrorsState(false);          
        } else {
          setButtonNameError('Button name cannot be empty')
          setErrorsState(true);          
        }
        return;
      }
      case FIELD_KEYS.CURRENT_URL: {
        if (!isStringNonEmpty(value) && selectedUrlRule) {
          setCurrentUrlError('Url cannot be empty')
          setErrorsState(true);
        } else {
          setCurrentUrlError(null)
          setErrorsState(false);
        }
        return;
      }
      case FIELD_KEYS.QUERY_PARAM_NAME: {
        if (!isStringNonEmpty(value)) {
          setQueryParamNameError('Name cannot be empty')
          setErrorsState(true);
        } else {
          setQueryParamNameError(null)
          if (queryParameterValue) {
            setErrorsState(false);
          }
        }
        return;
      }
      case FIELD_KEYS.QUERY_PARAM_VALUE: {
        if (!isStringNonEmpty(value)) {
          setQueryParamValueError('Value cannot be empty')
          setErrorsState(true);
        } else {
          setQueryParamValueError(null)
          if (queryParameterName) {
            setErrorsState(false);
          }
        }
        return;
      }
    }
  }

  const checkUpdates = (key, value) => {
    switch (key) {
      case FIELD_KEYS.NAME:
        return initialState.current.campaign.name !== value || (initialState.current.campaign.name === null && value !== '');
      case FIELD_KEYS.LANG:
        return initialState.current.campaign.locale !== value;
      case FIELD_KEYS.ENABLED:
        return initialState.current.campaign.enabled !== value;
      case FIELD_KEYS.BUTTON_NAME:
        return initialState.current.campaign.cta !== value;
      case FIELD_KEYS.BUTTON_ACTION:
        return initialState.current.campaign.actionType !== value;
      case FIELD_KEYS.TEMP_IMAGE:
        return initialState.current.campaign.imageUrl !== value;
      case FIELD_KEYS.DESCRIPTION:
        return initialState.current.campaign.description !== value;
      case FIELD_KEYS.TEST:
        return initialState.current.campaign.testMode !== value;
      case FIELD_KEYS.COLOR:
        return initialState.current.campaign.primaryColor !== value;
      case FIELD_KEYS.CURRENT_URL_SELECTED:
        return initialState.current.campaign.trigger.currentUrl && initialState.current.campaign.trigger.currentUrl.rule !== value;
      case FIELD_KEYS.SHOP_OPEN_SELECTED:
        return initialState.current.campaign.trigger.shopOpen && initialState.current.campaign.trigger.shopOpen.rule !== value;
      case FIELD_KEYS.CURRENT_URL:
        return initialState.current.campaign.trigger.currentUrl && initialState.current.campaign.trigger.currentUrl.value !== value;
      case FIELD_KEYS.TIME_ON_PAGE:
        return initialState.current.campaign.trigger.timeOnPage && initialState.current.campaign.trigger.timeOnPage.value !== value;
      case FIELD_KEYS.SHOP_OPEN:
        return initialState.current.campaign.trigger.shopOpen && initialState.current.campaign.trigger.shopOpen.value !== value;
      case FIELD_KEYS.QUERY_PARAM_VALUE:
        return initialState.current.campaign.trigger.queryParam && initialState.current.campaign.trigger.queryParam.value !== value;
      case FIELD_KEYS.QUERY_PARAM_NAME:
        return initialState.current.campaign.trigger.queryParam && initialState.current.campaign.trigger.queryParam.name !== value;
      case FIELD_KEYS.RULES: {
        let isUpdated = false;
        let ruleTypes = Object.keys(initialState.current.campaign.trigger);

        if (ruleTypes.length !== value.length) {
          return true;
        }

        value.forEach(r => {
          if (!ruleTypes.includes(r.type)) {
            isUpdated = true;
          }
        })
        return isUpdated;
      }
    }
  }

  const handleCampaignChanges = (action, key, value = null) => {
    checkErrors(key, value);

    initialState.current = {
      campaign: initialState.current.campaign,
      updates: {
        ...initialState.current.updates,
        [key]: checkUpdates(key, value)
      }};

    const getCampaignStatus = () => {
      let isUpdated = false;
      if (initialState.current && initialState.current.updates) {
        Object.values(initialState.current.updates).forEach(f => {
          if (f) {
            isUpdated = true;
          }
        })
      }
      return isUpdated;
    }
    setCampaignStatus(getCampaignStatus())

    action(value);
  }

  const handleSelectRule = (value) => {
    const id = new Date().valueOf();
    const newRule = buildDefaultRule(id, value, null);
    changeRules([...rules, newRule]);
  };

  const deleteRule = (id) => {
    changeRules(rules.filter(rule => rule.id !== id));
    rules.forEach(rule => {
      if (rule.id === id) {
        if (rule.type === 'currentUrl') {
          setUrl('');
          setSelectedUrlRule(CURRENT_URL_OPTIONS[0].value);
        } else if (rule.type === 'queryParam') {
          setQueryParameterName('');
          setQueryParameterValue('');
        } else if (rule.type === 'timeOnPage') {
          setTime('30');
          setSelectedTimeRule(TIME_PAGE_OPTIONS[0].value);
        } else if (rule.type === 'shopOpen') {
          setSelectedOpenShopRule(OPEN_SHOP_OPTIONS[0].value);
        }
      }
    })
  };

  const closeForm = () => history.goBack();

  const handleUploadButtonClick = () => fileInput.current.click();

  const uploadImage = async () => {
    try {      
      const targetName = formatUploadFilename(image.name);
      const imageUrl = `${process.env.REACT_APP_CONFIG_BUCKET}/${shopOrigin}/online_store/campaigns/${targetName}`;
      const fileName = imageUrl.split('/').slice(-1)[0];
      const { uploadUrl } = await uploadCampaignImage({ fileName, contentType: image.type });
      await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': image.type,
        },
        body: image,
      })
      return uploadUrl && uploadUrl.length > 0 ? uploadUrl[0].split('?')[0] : null;
    } catch (error) {
      console.error('Failed to upload image', error);
    }
  };

  const handleNewLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file)
      changeTemporaryImage(URL.createObjectURL(file))
    }
  }

  const resetCampaign = () => {
    initialState.current = {campaign: initialState.current.campaign};
    initParams(initialState.current.campaign);
  }

  const onColorChange = (primaryColor) => {
    setPrimaryColor(primaryColor.hex);
}

  const saveCampaign = async () => {
    const timeOnPage = rules.find(r => r.type === RULES[1].value);
    const currentUrl = rules.find(r => r.type === RULES[0].value);
    const openedShop = rules.find(r => r.type === RULES[2].value);
    const queryParam = rules.find(r => r.type === RULES[3].value);

    let hasError = false;

    if (!isStringNonEmpty(campaignName)) {
      setCampaignNameError('Campaign name cannot be empty')
      hasError = true;
    }

    if (!isStringNonEmpty(url) && currentUrl) {
      setCurrentUrlError('Url cannot be empty')
      hasError = true;
    }

    if (!isStringNonEmpty(queryParameterName) && queryParam) {
      setQueryParamNameError('Name cannot be empty')
      hasError = true;
    }

    if (!isStringNonEmpty(queryParameterValue) && queryParam) {
      setQueryParamValueError('Value cannot be empty')
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setApiError(null);
    setSaving(true);

    let campaignEntity;

    if (CAMPAIGN_TYPES[0].value === campaignType) {
      let imageUrl = null;
      if (image) {
        imageUrl = await uploadImage();
      } else if (tempImage) {
        imageUrl = tempImage;
      }
      campaignEntity = {
        cta,
        name: campaignName,
        actionType,
        locale: campaignLocale,
        description,
        primaryColor,
        header: title,
        id: currentCampaignId,
        imageUrl,
        trigger: {},
        type: campaignType,
        enabled: isEnabled,
        testMode: isTestingMode,
      }

      if (timeOnPage && timeOnPage.id) {
        campaignEntity.trigger.timeOnPage = {
          id: timeOnPage ? timeOnPage.id : null,
          rule: selectedTimeRule || null,
          value: time,
        }
      } else {
        campaignEntity.trigger.timeOnPage = null;
      }

      if (currentUrl && currentUrl.id) {
        campaignEntity.trigger.currentUrl = {
          id: currentUrl ? currentUrl.id : null,
          rule: selectedUrlRule || null,
          value: selectedUrlRule && url ? url : null,
        }
      } else {
        campaignEntity.trigger.currentUrl = null;
      }

      if (openedShop && openedShop.id) {
        campaignEntity.trigger.shopOpen = {
          id: openedShop ? openedShop.id : null,
          rule: 'shopOpen',
          value: true,
        }
      } else {
        campaignEntity.trigger.shopOpen = null;
      }

      if (queryParam && queryParam.id) {
        campaignEntity.trigger.queryParam = {
          id: queryParam ? queryParam.id : null,
          rule: queryParameterName || null,
          value: queryParameterValue || null,
        }
      } else {
        campaignEntity.trigger.queryParam = null;
      }
    } else {
      campaignEntity = {
        name: campaignName,
        helperText,
        id: currentCampaignId ? currentCampaignId : new Date().valueOf(),
        type: campaignType,
        enabled: isEnabled,
        testMode: isTestingMode,
        trigger: autoPopUpConfig,
      }
    }

    try {
      if (currentCampaignId) {
        await updateCampaign(campaignEntity);
      } else {
        await addCampaign(campaignEntity);
      }
      closeForm();
    } catch (error) {
      setApiError(error);
    }
    finally {
      setSaving(false);
    }
  }

  const removeImage = () => {
    setImage(null);
    changeTemporaryImage(null);
  }

  const renderGeneralOptions = () => (
      <Stack spacing={'tight'} vertical={true}>
        <TextField
            label="Campaign name *"
            placeholder='My campaign'
            onChange={setName}
            maxLength={125}
            value={campaignName}
            error={campaignNameError}
        />
        {/*<Select label='Campaign type'*/} /*TODO: widget type of campaign*/
        {/*        options={CAMPAIGN_TYPES}*/}
        {/*        onChange={handleCampaignTypeChange}*/}
        {/*        value={campaignType}*/}
        {/*/>*/}
        <Select
            label='Campaign locale'
            options={buildLocales}
            onChange={handleCampaignLocaleChange}
            value={campaignLocale}
        />
        <Checkbox
            label="Enabled"
            checked={isEnabled}
            onChange={changeCampaignState}
        />
         <Checkbox
            label="Test mode"
            checked={isTestingMode}
            onChange={changeCampaignMode}
            helpText={isTestingMode && <TextStyle variation='subdued'>Campaign will display everytime audience conditions are met</TextStyle>}
          />
      </Stack>
  )

  const renderRules = () => {
    return rules.map((rule) => {
      if (rule.type === 'currentUrl') {
        return <UrlRuleBlock
            error={currentUrlError}
            ruleId={rule.id}
            key={rule.id}
            deleteRule={deleteRule}
            changeUrl={changeUrl}
            url={url}
            OPTIONS={CURRENT_URL_OPTIONS}
            handleSelectUrlChange={handleSelectUrlChange}
            selectedUrlRule={selectedUrlRule}
        />;
      } else if (rule.type === 'timeOnPage') {
        return <TimeOnPageBlock
            ruleId={rule.id}
            key={rule.id}
            deleteRule={deleteRule}
            time={time}
            changeTime={changeTime}
        />;
      } else if (rule.type === 'shopOpen') {
        return <ShopOpenBlock
            ruleId={rule.id}
            key={rule.id}
            deleteRule={deleteRule}
            OPTIONS={OPEN_SHOP_OPTIONS}
            handleSelectOpenShopChange={handleSelectOpenShopChange}
            isShopOpened={isShopOpened}
        />;
      } else if (rule.type === 'queryParam') {
        return <QueryParamBlock
          ruleId={rule.id}
          key={rule.id}
          deleteRule={deleteRule}
          queryParameterName={queryParameterName}
          setQueryParameterName={setQueryParamName}
          queryParameterValue={queryParameterValue}
          setQueryParameterValue={setQueryParamValue}
          queryParamNameError={queryParamNameError}
          queryParamValueError={queryParamValueError}
        />;
      }
    })
  }

  const renderMockups = () => (
    <Stack spacing='extraLoose' distribution='center'>
      {
        campaignType === CAMPAIGN_TYPES[0].value
            ? <ClientPopUpMockup
                title={title}
                description={description}
                cta={cta}
                image={tempImage}
                primaryColor={primaryColor || defaultCampaignColor}
            />
            : <div className='caazam-campaign-widget' style={{
              backgroundColor: primaryColor,
              width: helperText && helperText.length > 0 ? '160px' : '60px'
            }}>
              <WidgetIcon />
              <p className='caazam-campaign-widget-text' style={{flex: helperText && helperText.length > 0 ? 2 : 0}}>{helperText}</p>
            </div>
      }
    </Stack>
  )

  const renderPopupContent = () => (

    <>
      <Card.Section>
        <Stack vertical>
        <TextField label="Campaign title" onChange={changeTitle} value={title} maxLength={40} />
        <Select label='Campaign button action'
          options={buttonOptions}
          onChange={handleCTAActionChange}
          value={actionType}
        />
        <TextField
          label="Campaign button label"
          onChange={changeButtonName}
          value={cta}
          maxLength={25}
          error={buttonNameError}
        />
        </Stack>
      </Card.Section>
      <Card.Section title='Upload image'>
        <Stack spacing='tight' alignment='center'>
          <Button onClick={handleUploadButtonClick}>Choose File</Button>
          <input ref={fileInput} type="file" accept="image/*" onChange={handleNewLogo}
            style={{ display: 'none' }} />
          {
            tempImage &&
            <Stack>
              <p>image file</p>
              <div className='shop-open-block_close' onClick={removeImage}>
                <Icon source={CancelSmallMinor} color="red" />
              </div>
            </Stack>
          }
        </Stack>
      </Card.Section>
      <Card.Section title='Write your message'>
        <WysiwygTextEditor options={WYSIWYG_TEXT_EDITOR_OPTIONS} htmlContent={description} onChange={changeDescription}/>
      </Card.Section>
      <Card.Section title='Accent color'>
        <Stack distribution='center'>
          <TwitterPicker
            triangle='hide'
            color={primaryColor || defaultCampaignColor}
            onChangeComplete={changeColor}
            colors={COLOR_PICKER_DEFAULTS}
          />
        </Stack>
      </Card.Section>
    </>
  )

  return (
    <AppPage title={`${currentCampaignId ? 'Edit' : 'Create'} campaign`} breadcrumbs={[{ content: 'Back', onAction: closeForm }]} fullWidth>

      <Layout>
        {
          showApiError &&
          <Layout.Section>
            <p>
              <TextStyle variation="negative">Failed to save campaign - {showApiError.message}</TextStyle>
            </p>
          </Layout.Section>
        }
        <Layout.Section oneHalf>
          <Card sectioned title='Campaign'>
            {renderGeneralOptions()}
          </Card>
          <Card sectioned title='Choose your audience'>
            <Stack vertical>
              <Stack vertical={true}>
                {renderRules()}
                {
                  rules.length < RULES.length &&
                  <Select
                    options={RULES.filter(option => !rules.map(r => r.type).includes(option.value))}
                    onChange={handleSelectRule}
                    placeholder={'+ Add condition'}
                  />
                }
              </Stack>
              <p><TextStyle variation='subdued'>Note: Store visitors will experience each campaign no more than once per session (2 hours)</TextStyle></p>
            </Stack>
          </Card>
          <Card title='Content'>
            {campaignType === CAMPAIGN_TYPES[0].value
              ? renderPopupContent()
              : <WidgetBlock
                autoPopUpConfig={autoPopUpConfig}
                setAutoPopUpConfig={setAutoPopUpConfig}
                AUTO_POP_UP_DEFAULTS={AUTO_POP_UP_DEFAULTS}
                helperText={helperText}
                changeHelperText={changeHelperText} />
            }
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card sectioned title='Preview' subdued>
            {renderMockups()}
          </Card>
        </Layout.Section>
      </Layout>
      {isCampaignUpdated && <ContextualSaveBar
        message="Unsaved changes to campaign"
        saveAction={{
          onAction: saveCampaign,
          loading: saving,
          disabled: errorState,
        }}
        discardAction={{
          onAction: closeForm,
          disabled: saving,
        }}
      />}
    </AppPage>
  );
}

export default function () {
  return (
    <AdminPage>
      <CreateCampaign/>
    </AdminPage>
  )
}
