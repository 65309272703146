import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Layout, Stack, Card, InlineError, SkeletonBodyText, TextStyle, Toast, Button, List, TextField } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import CopyToClipboard from 'react-copy-to-clipboard';


import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage';
import useCaazamREST from '../../hooks/useCaazamREST';

import { useShopProvider } from '../../components/ShopProvider';
import useShopLink from '../../hooks/useShopLink';
import { SELECT_ALL_HOSTS } from '../../components/SelectHost';
import CallLinkPolicyConfig from './customPolicy';


function EditLink({ }) {

    const history = useHistory();
    let { id: callLinkId } = useParams();

    const { hosts, shopOrigin, schedulingConfig } = useShopProvider();
    const { linkData, linkLoading } = useShopLink(shopOrigin, callLinkId);

    const hostId = linkData?.hostId;
    const hostData = hostId && hosts.find(host => host.id === hostId);
    const hostName = hostData && `${hostData.firstName} ${hostData.lastName}`;
    const openPreset = `boutiqApp.openPreset('${callLinkId}')`;
    const codeAction = `${openPreset}`
    const codeSnippet = `javascript:${openPreset}`


    const { shopLinkUpdate } = useCaazamREST();
    const [formError, setFormError] = useState(null);
    const [options, setOptions] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [working, setWorking] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (!linkData && !linkLoading) {
            setFormError(new Error('This call link is invalid'));
        } else if (linkData) {

            let linkOptions = {}
            if (linkData.options?.disableIncoming != null) {
                linkOptions.disableIncoming = linkData.options.disableIncoming;
            }
            if (linkData.options?.disableScheduling != null) {
                linkOptions.disableScheduling = linkData.options.disableScheduling;
            }
            setOptions(linkOptions);
            setDescription(linkData?.description ?? '');
        }
    }, [linkData, linkLoading]);

    useEffect(() => {
        if (options?.disableIncoming && options?.disableScheduling) {
            setFormError(new Error('call link must be allowed at least one action'));
        }

    }, [options]);

    const closeForm = () => {
        history.goBack();
    }

    const toggleToastActive = useCallback(() => setShowToast((showToast) => !showToast), []);

    const toastMarkup = showToast ? (
        <Toast content="Call Link copied to clipboard" onDismiss={toggleToastActive} />
    ) : null;

    const handleSubmit = async () => {

        setWorking(true);
        setFormError(null);

        try {
            await shopLinkUpdate(callLinkId, options, !!description ? description : null);
            closeForm();
        } catch (error) {
            console.error('call link update failed', error);
            setFormError(error);
        } finally {
            setWorking(false);
        }
    }

    return (
        <AppPage title='Edit Link' breadcrumbs={[{ content: 'Back', onAction: closeForm }]} >
            <Layout>
                <Layout.Section>
                    {linkLoading && <SkeletonBodyText />}
                    {!linkLoading && <Card
                        title='Configure Link'
                        sectioned
                        secondaryFooterActions={[{ content: 'Cancel', onAction: closeForm }]}
                        primaryFooterAction={{ content: 'Update', onAction: handleSubmit, disabled: !!formError, loading: working }}
                    >
                        <Stack vertical>
                            <div style={{ maxWidth: '80%' }}>
                                <TextField
                                    label='Description'
                                    value={description}
                                    onChange={setDescription}
                                    placeholder='Describe how/where/when is this link to be used'
                                />
                            </div>
                            <List type="bullet">
                                <List.Item>Link is For <TextStyle variation='strong'>{hostName || SELECT_ALL_HOSTS.label}</TextStyle></List.Item>
                                {linkData.default && !linkData.hostId && <List.Item>This link was created automatically by Boutiq during installation</List.Item>}
                                {linkData.default && linkData.hostId && <List.Item>This link was created by the host</List.Item>}

                                {options &&
                                    <List.Item>
                                        <CallLinkPolicyConfig
                                            schedulingConfig={schedulingConfig}
                                            options={options}
                                            setOptions={setOptions}
                                        />
                                    </List.Item>}
                            </List>
                            <div style={{ marginTop: '4px' }}>
                                <InlineError message={formError && formError.message} />
                            </div>
                        </Stack>
                    </Card>}
                    {linkLoading && <SkeletonBodyText />}
                    {!linkLoading && <Card
                        title='Use Link'
                        sectioned
                    >
                        <Card.Section title='Use as plain HTML link'>
                            <Stack vertical>
                                <p><TextStyle variation='subdued'>Best for adding Boutiq links to invitations, emails, social media posts, and ad campaigns</TextStyle></p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{linkData.callLinkUrl}</span>
                                    <CopyToClipboard text={linkData.callLinkUrl} onCopy={toggleToastActive}>
                                        <Button icon={DuplicateMinor} />
                                    </CopyToClipboard>
                                </Stack>
                            </Stack>
                        </Card.Section>
                        <Card.Section title="Use in HTML link elements">
                            <Stack vertical>
                                <p><TextStyle variation='subdued'>To open the Boutiq app from HTML links within your online store use the following as the link target (href):</TextStyle></p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{codeSnippet}</span>
                                    <CopyToClipboard text={codeSnippet} onCopy={toggleToastActive}>
                                        <Button icon={DuplicateMinor} />
                                    </CopyToClipboard>
                                </Stack>
                                <p>Example in HTML link:</p>

                                <div className='code-snippet code-background'>
                                    <p>{`<a href="${codeSnippet}">Video call with our team</a>`}</p>
                                </div>

                            </Stack>
                        </Card.Section>
                        <Card.Section title="Use in HTML buttons">
                            <Stack vertical>
                                <p><TextStyle variation='subdued'>To open the Boutiq app from HTML buttons or other clickable elements within your online store use the following as the element's "onclick" handler </TextStyle></p>
                                <Stack alignment='center'>
                                    <span className='code-snippet code-background'>{codeAction}</span>
                                    <CopyToClipboard text={codeAction} onCopy={toggleToastActive}>
                                        <Button icon={DuplicateMinor} />
                                    </CopyToClipboard>
                                </Stack>
                                <p>Example in HTML button:</p>
                                <div className='code-snippet code-background'>
                                    <p>{`<button onclick="${codeAction}">Video call with our team</button>`}</p>
                                </div>
                            </Stack>
                        </Card.Section>
                    </Card>}
                </Layout.Section>
            </Layout>
            {toastMarkup}
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <EditLink />
        </AdminPage>
    )
}
