import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import { useRef, useState } from 'react';
import { csvLoader } from '../utils/csvProcessor';


export const EXPORT_STATUS = {
  SUCCEED: 'Succeed',
  PENDING: 'Pending',
  NO_DATA: 'No data to export',
  ERROR: 'Something went wrong - '
}

function mapFromDataToCsvTable(shopId, event, fields) {
  const { clientForm, startTimestamp, customer, eventType } = event.data();
  if (clientForm) {
    const formObj = clientForm.data?.reduce((obj, field) => {
      const idIsDate = new Date(field.id);
      const fieldId = isNaN(idIsDate.getTime()) ? `${field.id}` : `${idIsDate.getTime()}`;
      if (Array.isArray(field.value)) {
        obj[fieldId] = `"${field.value?.join(',')}"` ?? null;
      } else if (typeof field.value == 'string') {
        obj[fieldId] = `"${field.value.replace(/"/g, '""')}"`;
      } else {
        obj[fieldId] = field.value;
      }
      if (fields.findIndex(f => f.id === fieldId) < 0) {

        fields.push({
          id: fieldId,
          label: field.label,
        });
      }
      return obj;
    }, {}) ?? {};
    formObj.shopId = shopId;
    formObj.date = startTimestamp.toDate().toISOString();
    formObj.email = customer?.email;
    formObj.schemaVersion = clientForm.schemaVersion;
    formObj.eventType = eventType?.name ?? null;
    return formObj;
  }
}

async function getSchedulingPage(shopId, startDate, endDate, limit = 100, cursor = null) {
  let query = firebase.firestore().collection('shops')
    .doc(shopId)
    .collection('schedule')
    .where('createdAt', '>=', startDate)
    .where('createdAt', '<=', endDate);

  if (cursor) {
    query = query.startAt(cursor);
  }

  let queryRes = await query.limit(limit + 1).get();
  return {
    events: queryRes.size > limit ? queryRes.docs.slice(0, limit) : queryRes.docs,
    next: queryRes.size > limit ? () => getSchedulingPage(shopId, startDate, endDate, limit, queryRes.docs[limit]) : null,
  }
}

const INITIAL_FIELDS = [{ id: 'shopId', label: 'Shop ID' }, { id: 'date', label: 'Date' }, { id: 'email', label: 'Email' }, { id: 'schemaVersion', label: 'Schema Version' }, {id: 'eventType', label: 'Event Type'}];

export default function useExportFormData(shopOrigin, filter) {
  const aggDataRef = useRef([]);
  const fieldsRef = useRef(INITIAL_FIELDS);
  const csvFileRef = useRef('');

  const [exportStatus, setExportStatus] = useState(null);
  const [exportError, setExportError] = useState(null);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  function csvProcessor(events, next) {
    for (const event of events) {
      const formObj = mapFromDataToCsvTable(shopOrigin, event, fieldsRef.current);
      if (formObj)
        aggDataRef.current.push(formObj);
    }

    if (!next) {
      if (!aggDataRef.current.length) {
        setExportStatus(EXPORT_STATUS.NO_DATA);
      } else {
        csvFinalize(aggDataRef.current, fieldsRef.current);
        setExportStatus(EXPORT_STATUS.SUCCEED);
        csvLoader(csvFileRef.current, `${shopOrigin}_form_data`);
      }
      setIsExportInProgress(false);
    } else {
      next().then(({ events, next }) => {
        csvProcessor(events, next);
      }).catch((error) => {
        setExportStatus(EXPORT_STATUS.ERROR);
        setExportError(error);
        setIsExportInProgress(false);
      });
    }
  }

  function csvFinalize(data, fields) {
    csvFileRef.current = fields.map(f => `"${f.id}"`).join(',') + '\n';
    csvFileRef.current += fields.map(f => `"${f.label.replace(/"/g, '""')}"`).join(',') + '\n'
    for (const formObj of data) {
      let row = [];
      fields.forEach(f => {
        row.push(formObj[f.id]);
      })
      csvFileRef.current += row.join(',') + '\n';
    }
  }

  function exportFormData() {
    aggDataRef.current = [];
    fieldsRef.current = [...INITIAL_FIELDS];
    csvFileRef.current = '';

    setExportError(null);
    setExportStatus(EXPORT_STATUS.PENDING);
    setIsExportInProgress(true);

    firebase.analytics().logEvent('admin.export_form_data_history', { range: filter.range?.id });
    getSchedulingPage(shopOrigin, filter.range.start.toDate(), filter.range.end.toDate())
      .then(({ events, next }) => {
        csvProcessor(events, next);
      })
      .catch((error) => {
        setExportStatus(EXPORT_STATUS.ERROR);
        setExportError(error);
        setIsExportInProgress(false);
      });
  }

  return ({
    isExportInProgress,
    exportStatus,
    exportError,
    exportFormData
  })
}

