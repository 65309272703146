import React from "react"
import { AllHostsAvatar } from "./AllHostsAvatar";
import './styles.css';

export const AllHostsItemData = ({ isSelected, setSelectedHost, hosts }) => {
    return (
        <li onClick={() => setSelectedHost(true)}
            className={`host-data-container`} >
            <AllHostsAvatar hosts={hosts} />
            <p className='host-name'>
                Any Available
            </p>
            <p className='host-bio'>
            Select to schedule with any available host
            </p>
        </li>
    )
}
