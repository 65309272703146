import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { TextStyle } from '@shopify/polaris';
import './index.css';

export const WysiwygTextEditor = ({ htmlContent, onChange, maxLength = 300, options, readOnly = false, onLengthChange = () => {} }) => {
  const [editorState, setEditorState] = useState('');
  const [currentLength, setCurrentLength] = useState(null);

  useEffect(() => {
    if (!editorState) {
      const contentBlock = htmlToDraft(htmlContent);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      const textLength = editorState.getCurrentContent().getPlainText().length;
      setCurrentLength(textLength);
      onLengthChange(textLength);
      if (htmlContent)
        setEditorState(editorState);
    } else {
      if (!htmlContent) {
        const onResetState = EditorState.push(editorState, ContentState.createFromText(''));
        setCurrentLength(0);
        onLengthChange(0);
        setEditorState(onResetState)
      }
    }
  }, [htmlContent])

  const onEditorStateChange = (contentBlock) => {
    setEditorState(contentBlock);
    const textLength = contentBlock.getCurrentContent().getPlainText().length;
    setCurrentLength(textLength);
    onLengthChange(textLength);
    onChange(draftToHtml(convertToRaw(contentBlock.getCurrentContent())));
  }

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();
    let length = 0;
    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;
        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }
    return length;
  }

  const handlePastedText = pastedText => {
    if (maxLength) {
      const currentContentLength = editorState
        .getCurrentContent()
        .getPlainText().length;
      const currentSelectedContent = getLengthOfSelectedText();
      const pastedTextLength = pastedText.length;

      let remainingLength = maxLength - currentContentLength + currentSelectedContent;
      let newContent;

      if (pastedText.length + currentContentLength >= maxLength) {
        newContent = Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          pastedText.slice(0, remainingLength)
        );
        onEditorStateChange(
          EditorState.push(
            editorState,
            newContent,
            'insert-characters'
          )
        );
        return true;
      } else {
        newContent = Modifier.insertText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          pastedText.slice(0, pastedTextLength)
        );
        onEditorStateChange(
          EditorState.push(
            editorState,
            newContent,
            'insert-characters'
          )
        );
        return true;
      }
    }
  };

  const handleBeforeInput = () => {
    if (maxLength) {
      const currentSelectedContent = getLengthOfSelectedText();
      const textLength = editorState.getCurrentContent().getPlainText().length;
      if (textLength - currentSelectedContent >= maxLength) {
        return 'handled';
      }
    }
  };

  return (
    <div className={`wysiwyg-text-editor-container ${readOnly ? ' disabled' : ''}`}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        stripPastedStyles={true}
        toolbar={options}
        readOnly={readOnly}
      />
      <Counter maxLength={maxLength} currentLength={currentLength} />
    </div>
  )
}

const Counter = ({ currentLength, maxLength }) => {
  return (
    <TextStyle variation={currentLength === maxLength ? 'negative' : 'subdued'}>{`${currentLength}/${maxLength}`}</TextStyle>
  )
}
