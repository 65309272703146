import { Box, ResourceItem, ResourceList, Text, Thumbnail } from "@shopify/polaris";
import {ImageMajor} from "@shopify/polaris-icons";
import React  from "react";
import ProductPrice from './ProductPrice';
import './styles.css';

const ProductList = ({products, loading, emptyState, onSelectProduct = () => {}, shortcutActions, storyProductSettings}) => {
    return (
        <ResourceList
            emptyState={emptyState}
            items={products}
            renderItem={(product, _, index) => (
              <ResourceItem
                id={product.productId}
                onClick={() => onSelectProduct(product)}
                shortcutActions={shortcutActions?.map((shortcut) => ({...shortcut, onAction: () => shortcut.onAction(product)}))}
              >
                <Box style={{display: 'grid', gridTemplateColumns: '90px 1fr'}}>
                  <Thumbnail
                    transparent={!!product.image}
                    source={product.image ?? ImageMajor}
                    size={"large"}
                    alt={product.title}
                  />
                  <Box>
                    <Text fontWeight={'medium'} as={"h4"} variant={'headingMd'}>{product.title || product.productTitle}</Text>
                    <ProductPrice product={product}/>
                  </Box>
                </Box>
                {storyProductSettings?.(product)}
              </ResourceItem>
            )}
            idForItem={({ id }) => id}
            loading={loading}
        />
    )
}

export default ProductList;
