import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

import {FormLayout, Modal, InlineError, Stack, List} from '@shopify/polaris';

import './styles.css';
import { useBlacklistActions } from '../../../hooks/useBlacklist';
import UploadFiles from "../../../components/UploadFiles";
import BannedClientsDescription from "./description";
import {isEmailValid} from "../../../utils/strings";

export default function UploadBannedModal({ shopId, open, onClose }) {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bannedClientsCsv, setBannedClientsCsv] = useState([]);
    const [bannedList, setBannedList] = useState(null);

    const { addBulkBlacklist } = useBlacklistActions(shopId);

    useEffect(() => {
        setBannedClientsCsv([]);
        setBannedList(null);
        setError(null)
    }, [open])

    useEffect(() => {
        if (bannedClientsCsv.length) {
            const brokenEmails = [];
            const notExistRequiredColumn = [];
            Papa.parse(bannedClientsCsv[0], {
                header: true,
                skipEmptyLines: true,
                transformHeader: (h) => {
                    let header = h.toLowerCase();
                    if (['note','notes'].includes(header)) return 'note';
                    return header;
                },
                complete: function (results) {
                    if (results.data?.length > 0) {
                        if (results.data.length > 500) {
                            setError(new Error(`Cannot import files with more than 500 lines (${results.data?.length})`));
                            return;
                        }
                        results.data.forEach(data => {
                            if (!data.email) {
                                if (!notExistRequiredColumn.includes('email'))
                                    notExistRequiredColumn.push('email');
                            } else {
                                const email = data.email.trim().toLowerCase();
                                const isValid = isEmailValid(email);
                                if (!isValid) {
                                    brokenEmails.push(email);
                                }
                            }
                        })

                        if (notExistRequiredColumn.length > 0) {
                            setError(new Error('File missing required column: ' + notExistRequiredColumn.join()));
                            return;
                        }

                        if (brokenEmails.length > 0) {
                            setError(new Error('File contains invalid emails: ' + brokenEmails.join()));
                        } else {
                            setBannedList(results.data)
                            setError(null);
                        }
                    } else {
                        setError(new Error(`File is empty - no clients to ban`));
                        return;
                    }

                },
            });
        }
    }, [bannedClientsCsv?.[0]?.lastModified])

    const addToBanHandler = async () => {
        setLoading(true);
        try {
            await addBulkBlacklist(bannedList);
            onClose();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Import banned clients'
            primaryAction={{ content:  bannedList?.length && !error ? `Ban ${bannedList.length} clients` : 'Ban' , onAction: addToBanHandler, loading, disabled: !bannedList , destructive: true }}
            secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
        >
            <Modal.Section>
                <BannedClientsDescription />
            </Modal.Section>
            <Modal.Section>
                <Stack vertical>
                    <p>The imported file should be in CSV format and at least contain the columns:</p>
                    <List>
                        <List.Item>email</List.Item>
                        <List.Item>note (optional)</List.Item>
                    </List>
                </Stack>
            </Modal.Section>
            <Modal.Section>
                <FormLayout>
                    <UploadFiles accept={['text/csv']} files={bannedClientsCsv} setFiles={setBannedClientsCsv} />
                    {error && <InlineError message={error?.message} />}
                </FormLayout>
            </Modal.Section>
        </Modal>
    )

}
