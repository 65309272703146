import {useAppAuthState} from "../authState";
import {useEffect, useState} from "react";
import {
    getFirestore,
    doc,
    onSnapshot, writeBatch, serverTimestamp,
} from 'firebase/firestore';
import { DEFAULT_PRODUCT_STORIES_CONFIG } from '../pages/Stories/pages/StoryProductConfig/consts';

const useStoryVideoConfig = (storyId, videoId) => {
    const [isStoryProductsLoading, setIsStoryProductsLoading] = useState(false)
    const [storiesConfig, setStoriesConfig] = useState(null)
    const {shopOrigin} = useAppAuthState();

    const db = getFirestore();
    const storyDoc = doc(db, `shops/${shopOrigin}/videoStories/${storyId}`)
    const videoDoc = doc(storyDoc, `videoStoriesVideos/${videoId}`)

    useEffect(() => {
        if (storyId && videoId) {
            setIsStoryProductsLoading(true)
            const unsub = onSnapshot(videoDoc, (snapshot) => {
                setStoriesConfig(snapshot.data() ?? DEFAULT_PRODUCT_STORIES_CONFIG)
                setIsStoryProductsLoading(false)
            })

            return () => {
                unsub()
                setStoriesConfig(null)
                setIsStoryProductsLoading(false)
            }
        }
    }, [storyId, videoId])

    const onUpdateStoryProducts = async (products) => {
        try {
            setIsStoryProductsLoading(true)
            const batch = writeBatch(db);
            batch.set(videoDoc, {
                products: products.map((product) => {
                    const {
                        compareAtPrice,
                        currencyCode,
                        image,
                        maxVariantPrice,
                        minVariantPrice,
                        price,
                        productId,
                        storiesConfig,
                        title,
                        onlineStoreUrl
                    } = product;
                    return {
                        compareAtPrice,
                        currencyCode,
                        image,
                        maxVariantPrice,
                        minVariantPrice,
                        price,
                        productId,
                        storiesConfig,
                        title,
                        onlineStoreUrl
                    }
                })
            }, {merge: true})
            batch.update(storyDoc, {
                updatedAt: serverTimestamp()
            })
            await batch.commit()
        } catch (e) {
            console.log('onUpdateStoryProducts error: ', e)
        } finally {
            setIsStoryProductsLoading(false)
        }
    }

    return ({
        storiesConfig,
        isStoryProductsLoading,
        onUpdateStoryProducts,
    })

}

export default useStoryVideoConfig;
