import React from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Card, Stack, List, TextContainer } from '@shopify/polaris';
import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import BusinessHours from "../../components/BusinessHours";
import { useShopProvider } from '../../components/ShopProvider';
import { CallPolicyMode, getCallPolicy } from '../../components/CallPolicyConfig';

function BusinessHoursSettings() {
    const history = useHistory();
    const { schedulingConfig } = useShopProvider();

    const Description = ({ policy }) => {
        switch (policy) {
            case CallPolicyMode.scheduling_only:
                return (
                    <TextContainer spacing='tight'>
                        <p>Business hours determine the default availability for scheduled calls:</p>
                        <List>
                            <List.Item>You can override the default and set custom availability hours for each host in the store calendar.</List.Item>
                            <List.Item>Regardless of business hours settings, hosts can invite clients to personal calls at any time.</List.Item>
                        </List>
                    </TextContainer>
                );
            case CallPolicyMode.instant_only:
                return (
                    <TextContainer spacing='tight'>
                        <p>Business hours determine when clients can start a video call:</p>
                        <List>
                            <List.Item>During business hours clients can start instant video calls to your hosts by clicking the Boutiq element (e.g. widget).</List.Item>
                            <List.Item>During non-business hours, the Boutiq element on your store will redirect clients to leave you a message.</List.Item>
                        </List>
                    </TextContainer>
                );
            default:
                return (
                    <TextContainer spacing='tight'>
                        <p>Business hours determine when clients can start a video call:</p>
                        <List>
                            <List.Item>During business hours clients can start instant video calls to your hosts by clicking the Boutiq element (e.g. widget).</List.Item>
                            <List.Item>During non-business hours, the Boutiq element on your store will redirect clients to schedule an appointment for later or leave you a message.</List.Item>
                            <List.Item>Business hours also determine the default availability for scheduled calls. You can set custom availability hours for each host in the store calendar.</List.Item>
                            <List.Item>Regardless of business hours settings, hosts can invite clients to personal calls at any time.</List.Item>
                        </List>
                    </TextContainer>

                )
        }
    }

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Business hours'
            //secondaryActions={[{ content: 'Advanced', onAction: () => history.push('/scheduling')}]}
        >
            <Layout>
                <Layout.Section>                    
                    <Card sectioned title={'Business Hours'}>
                        <Stack vertical spacing='extraLoose'>
                            <Description policy={getCallPolicy(schedulingConfig)}/>
                            <BusinessHours onDiscard={history.goBack} />
                        </Stack>
                    </Card>
                </Layout.Section>
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <BusinessHoursSettings />
        </AdminPage>
    )
}