
import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useEffect, useState } from 'react';

export default function useShopUsers(shopId) {
    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('shopUsers') : null;
    const [query, usersLoading, usersError] = useCollection(ref);
    const [users, setUsers] = useState([]);
    const [hosts, setHosts] = useState([]);

    useEffect(() => {
        if (query) {
            setUsers(query.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        } else {
            setUsers([]);
        }
    }, [query]);

    useEffect(() => {
        if (users) {
            setHosts(users.filter(user => user.roles.includes('host')));
        } else {
            setHosts([]);
        }
    }, [users])

    const updateHostHiddenState = (config) => {
        if (!ref) return;
        const batch = Firebase.firestore().batch();
        config.map(host => batch.update(ref.doc(host.id), {
            isHidden: host.isHidden ? true : Firebase.firestore.FieldValue.delete()
        }));
        return batch.commit();
    }

    const updateHostBio = (id, bio) => {
        if (!ref) return;
        return ref.doc(id).update({ bio });
    }

    return { users, hosts, usersLoading, usersError, updateHostHiddenState, updateHostBio }
}
