import React, { useState, useEffect } from 'react';
import { Card, Stack, Banner, Checkbox, SkeletonBodyText, Button } from '@shopify/polaris';

import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import VimeoVideoModal from '../VimeoVideoModal';
import WidgetConfig from './config';
import { DefaultWidgetConfig } from './consts';


import './styles.css';

const OnlineStoreConfigMethod = {
    widget: { id: 'widget', name: 'Widget', description: 'Boutiq widget appears on pages of your online store' },
    product: { id: 'product', name: 'Automatic: product page', description: 'Boutiq button from any product page on your online store' },
    manual: { id: 'manual', name: 'Manual', description: 'Add Boutiq activation code to an element in your theme' },
}

export { DefaultWidgetConfig, OnlineStoreConfigMethod };

export default function WidgetSettings({ enabled, config, loading, onConfigChange, onEnable, showMoreConfig = false }) {
    const [widgetEnabled, setWidgetEnabled] = useState(true);
    const [videoModal, showVideoModal] = useState(false);

    useEffect(() => {
        if (!loading) {
            setWidgetEnabled(enabled);
        }
    }, [enabled, loading])


    const onWidgetToggle = (val) => {
        setWidgetEnabled(val);
        onEnable(val);
    }

    return (
        <>
            <Card sectioned>
                <Stack>
                    <Stack vertical>
                        <p>Your store visitors will be able to schedule or start an instant call by clicking the Boutiq widget on pages of your online store</p>
                        {loading && <SkeletonBodyText lines={1} />}
                        {!loading && <Checkbox label='Widget enabled' checked={widgetEnabled} onChange={onWidgetToggle}></Checkbox>}
                        <Banner>
                            <p><Button plain monochrome onClick={() => showVideoModal(true)}>View a short video tutorial</Button> on how to customize the widget for your online store</p>
                        </Banner>
                    </Stack>
                </Stack>
            </Card>
            <WidgetConfig
                config={config}
                loading={loading}
                onConfigChange={onConfigChange}
                disabled={!widgetEnabled}
                showMoreConfig={showMoreConfig}
            />
            <VimeoVideoModal
                open={videoModal}
                onClose={() => showVideoModal(false)}
                videoUrl='https://player.vimeo.com/video/740804539#t=01m50s?h=887c30bd41&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                videoTitle='Integration Options'
            />
        </>
    )
}

export async function saveWidgetEnabled(shopId, enabled) {
    let update = {
        method: enabled ? OnlineStoreConfigMethod.widget.id : OnlineStoreConfigMethod.manual.id,
    }
    await Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('onlineStore').set(update, { merge: true });
    Firebase.analytics().logEvent('admin.online_store_int', { method: update.method });
}

export async function saveWidgetConfig(shopId, enabled, methodConfig) {
    let update = {
        method: enabled ? OnlineStoreConfigMethod.widget.id : OnlineStoreConfigMethod.manual.id,
        methodConfig,
    }

    await Firebase.firestore().collection('shops').doc(shopId).collection('installData').doc('onlineStore').set(update, { merge: true });
}
