import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { STORY_DISPLAY_TYPE } from '../pages/Stories/consts';

const useShopVideoStories = (shopId, setStoriesChangeLoading) => {
  const [isStoriesLoading, setIsStoriesLoading] = useState(false)
  const [shopVideoStories, setShopVideoStories] = useState([])
  const storage = getStorage();
  const db = getFirestore();
  const storiesCollection = collection(db, `shops/${shopId}/videoStories`)

  useEffect(() => {
    if (shopId) {
      setIsStoriesLoading(true)
      const unsub = onSnapshot(storiesCollection, async (snapshot) => {
        const promise = Promise.allSettled(snapshot.docs
          .map(doc => ({
            storyId: doc.id,
            ...doc.data()
          }))
          .map(async (story) => {
            const videosRef = collection(storiesCollection, `${story.storyId}/videoStoriesVideos`)
            const querySnapshot = await getDocs(videosRef)
            return ({
              ...story,
              videos: querySnapshot.docs.map(async (doc) => {
                const data = doc.data();
                const videoUrl = await getDownloadURL(ref(storage, data.videoUrl));
                const videoPosterUrl = await getDownloadURL(ref(storage, data.videoPosterUrl));
                return ({
                  data: {
                    ...data,
                    videoUrl,
                    videoPosterUrl,
                    storeVideoPosterUrl: data.videoPosterUrl,
                    storeVideoUrl: data.videoUrl,
                  },
                  id: doc.id
                })
              })
            })
          }))
        const stories = []
        for (const story of (await promise).map(({value}) => value)) {
          const videos = (await Promise.allSettled(story.videos)).map(({value}) => value)
          stories.push({
            ...story,
            videos: videos.filter(video => !!video).sort((a, b) => a.data.index - b.data.index)
          })
        }
        setShopVideoStories(
          stories
            .map((story) => ({
              storyDisplayType: STORY_DISPLAY_TYPE.widget,
              ...story,
            }))
            .sort((a, b) => b.updatedAt?.toDate()?.getTime() - a.updatedAt?.toDate()?.getTime())
        )
        setStoriesChangeLoading(false)
        setIsStoriesLoading(false)
      })
      return () => unsub()
    } else {
      setShopVideoStories([])
    }
  }, [shopId])

  return ({
    videoStoryLoading: isStoriesLoading,
    shopVideoStories,
  })
}

export default useShopVideoStories;
