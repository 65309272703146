import React  from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Card, Link, Stack, Button, DescriptionList} from '@shopify/polaris';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import { NotificationTypes } from './Notification';

function Notifications() {

    const history = useHistory();

    return (
        <AppPage title='Notifications' breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}>
            <Layout>
                <Layout.AnnotatedSection
                    title="Client notifications"
                    description={
                        <Stack vertical>
                            <p>These notifications are automatically sent out to clients. Click on the notification type to customize the content</p>
                            <Button onClick={()=> history.push('/notifications/customTemplate')}>Customize template</Button>
                        </Stack>
                    }>
                    <Card title='Scheduling & Appointments'>
                        <Card.Section>
                            <DescriptionList
                                items={Object.keys(NotificationTypes).map(templateId=> ({
                                        term: <Link url={`/notification/${templateId}`}>{NotificationTypes[templateId].name}</Link>,
                                        description: NotificationTypes[templateId].description,
                                    }
                                ))}
                            />
                        </Card.Section>

                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Store notifications"
                    description={
                        <Stack vertical>
                            <p>These notifications are sent to your store</p>
                        </Stack>
                    }>
                    <Card title='Client Contact'>
                        <Card.Section>
                            <DescriptionList
                                items={[{                                    
                                        term: <Link url={`/notifications/storeNotifications`}>Client contact email</Link>,
                                        description: 'Sent to store when clients choose to send a message or encounter a problem',
                                    }]}
                            />
                        </Card.Section>

                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <Notifications/>
        </AdminPage>
    )
}
