import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

export default function AdminLogin() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const shop = queryParams.get('shop');

    useEffect(() => {
        if (token) {
            signInWithCustomToken(getAuth(),token)
                .catch(() => {})
                .finally(() => history.push('/'));
        } else {
            history.push('/');
        }
    }, [token]);


    return null;

}
