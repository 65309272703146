import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Stack, MediaCard, Badge, Heading, Banner, Card, Button } from '@shopify/polaris';
import {
    AdjustMinor,
} from '@shopify/polaris-icons';

import AppPage from '../../../components/AppPage'
import AdminPage from '../../../components/AdminPage'
import { OnlineStoreConfigMethod } from "../../../components/Widget";
import { useShopProvider } from '../../../components/ShopProvider';
import VimeoVideoModal from '../../../components/VimeoVideoModal';


function OnlineStoreSettings() {
    const history = useHistory();
    const { onlineStoreConfig, headlessConfig } = useShopProvider();
    const isWidget = onlineStoreConfig && onlineStoreConfig.method === OnlineStoreConfigMethod.widget.id;
    const [videoTutorialBanner, showVideoTutorialBanner] = useState(true);
    const [videoModal, showVideoModal] = useState(false);

    return (
        <AppPage
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
            title='Online store integration'
            secondaryActions={[
                { content: 'Advanced', icon: AdjustMinor, onAction: () => history.push('/clientSettings/onlineStoreSettings/headless') },
            ]}
        >
            <Layout>
                <Layout.Section fullWidth>
                    <Card sectioned>
                        <Stack vertical spacing='tight'>
                            <p>You can let clients start or schedule a Boutiq call in various ways - based on your store design and branding as well as your engagement preferences. (hint: you can choose more than one)</p>
                            {videoTutorialBanner && <Banner onDismiss={() => showVideoTutorialBanner(false)}>
                                <p><Button plain monochrome onClick={() => showVideoModal(true)}>View a video tutorial</Button> of all online store integration options. You can also view short tutorials for each option inside their settings page</p>
                            </Banner>}
                        </Stack>
                    </Card>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <MediaCard
                        title={<Stack alignment='center'>
                            <Heading>Widget</Heading>
                            {isWidget && <Badge size='small' status='success'>enabled</Badge>}
                        </Stack>}
                        size='small'

                        primaryAction={{
                            content: 'Widget settings',
                            onAction: () => history.push('/clientSettings/widgetSettings'),
                        }}
                        description='Add the Boutiq widget to your online store'
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/widget_thumbnail.png"
                        />

                    </MediaCard>

                </Layout.Section>
                <Layout.Section oneHalf>
                    <MediaCard
                        title='Buttons &amp; custom code'
                        size='small'

                        primaryAction={{
                            content: 'See how',
                            onAction: () => history.push('/clientSettings/customCodeConfig'),
                        }}
                        description="Add Boutiq actions to buttons and other elements of your online store"
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/manual_thumbnail.png"
                        />

                    </MediaCard>

                </Layout.Section>
                {!headlessConfig?.isHeadless &&
                    <Layout.Section oneHalf>
                        <MediaCard
                            title='Landing page'
                            size='small'

                            primaryAction={{
                                content: 'Landing page settings',
                                onAction: () => history.push('/clientSettings/landingPageSettings'),
                            }}
                            description='Add a personal video shopping landing page to your online store'
                        >
                            <img
                                alt=""
                                width="100%"
                                height="100%"
                                style={{
                                    objectFit: 'cover',
                                }}
                                src="/settings/landing_thumbnail.png"
                            />

                        </MediaCard>

                    </Layout.Section>}
                <Layout.Section oneHalf>
                    <MediaCard
                        title='Campaigns'
                        size='small'

                        primaryAction={{
                            content: 'Campaigns settings',
                            onAction: () => history.push('/campaigns'),
                        }}
                        description="Add pop-up promotions for virtual shopping experiences to your store"
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/campaign_thumbnail.png"
                        />

                    </MediaCard>
                </Layout.Section>

                <Layout.Section oneHalf>
                    <MediaCard
                        title="Invitations, Emails, &amp; Social Media"
                        size='small'

                        primaryAction={{
                            content: 'See how',
                            onAction: () => history.push('/clientSettings/invitations'),
                        }}
                        description="Add Boutiq links to invitations, emails, social media posts, and ad campaigns"
                    >
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            style={{
                                objectFit: 'cover',
                            }}
                            src="/settings/invitations_thumbnail.png"
                        />

                    </MediaCard>
                </Layout.Section>
                <Layout.Section oneHalf></Layout.Section>
                <VimeoVideoModal
                    open={videoModal}
                    onClose={() => showVideoModal(false)}
                    videoUrl='https://player.vimeo.com/video/740804539?h=887c30bd41&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                    videoTitle='Integration Options'
                />
            </Layout>
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <OnlineStoreSettings />
        </AdminPage>
    )
}