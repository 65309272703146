import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ClientSettingsPage from './index';
import OnlineStoreSettingsPage from "./OnlineStoreSettings";
import Widget from './WidgetSettings';
import WidgetAdvanced from './WidgetAdvanced';
import LandingPage from './LandingPage';
import ClientAppSettingsPage from "./ClientAppSettings";
import ClientAppThemeSettings from "./ClientAppThemeSettings";
import ClientAdvancedSettingsPage from "./AdvancedSettings";
import WelcomeSettingsPage from "./WelcomeSettings";
import ClientFormSettings from "./ClientFormSettings";
import CustomCodeSettings from './CustomCodeSettings';
import Invitations from './Invitations';
import HeadlessPage from '../Headless';
import { ClientSettingsProvider } from './ClientSettingsProvider';

export default function ClientSettingsRouter({ }) {
    return (
        <ClientSettingsProvider>
            <Switch>
                <Route path='/clientSettings/onlineStoreSettings/headless'>
                    <HeadlessPage />
                </Route>
                <Route path='/clientSettings/onlineStoreSettings'>
                    <OnlineStoreSettingsPage />
                </Route>
                <Route path='/clientSettings/widgetSettings'>
                    <Widget />
                </Route>
                <Route path='/clientSettings/widgetAdvanced'>
                    <WidgetAdvanced />
                </Route>
                <Route path='/clientSettings/landingPageSettings'>
                    <LandingPage />
                </Route>
                <Route path='/clientSettings/customCodeConfig'>
                    <CustomCodeSettings />
                </Route>
                <Route path='/clientSettings/invitations'>
                    <Invitations />
                </Route>

                <Route path='/clientSettings/clientApp'>
                    <ClientAppSettingsPage />
                </Route>
                <Route path='/clientSettings/clientAppTheme'>
                    <ClientAppThemeSettings />
                </Route>
                <Route path='/clientSettings/advancedSettings'>
                    <ClientAdvancedSettingsPage />
                </Route>
                <Route path='/clientSettings/welcomeSettings'>
                    <WelcomeSettingsPage />
                </Route>
                <Route path='/clientSettings/forms'>
                    <ClientFormSettings />
                </Route>

                <Route path='/clientSettings/'>
                    <ClientSettingsPage />
                </Route>
            </Switch>
        </ClientSettingsProvider>
    )
}
