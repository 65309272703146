import React from 'react';

import AppPage from '../../components/AppPage'
import AdminPage from '../../components/AdminPage'
import CallHistoryTable from './components/CallHistoryTable';

function CallHistory() {

    return (
        <AppPage title='Call History'>
            <CallHistoryTable/>
        </AppPage>
    );
}

export default function() {
    return (
        <AdminPage>
            <CallHistory/>
        </AdminPage>
    )
}
