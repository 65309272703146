import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import {
    Layout,
    Card,
    Stack,
    Button,
    Spinner,
    Subheading,
    TextField,
    RangeSlider,
    Checkbox,
    ContextualSaveBar
} from '@shopify/polaris';
import { useShopProvider } from '../../../components/ShopProvider';
import useShopServices from '../../../hooks/useShopServices'
import {
    DEFAULT_FONT_FAMILY,
    DEFAULT_SERVICE_NAME } from '../../../utils/consts'
import SelectLocale from '../../../components/SelectLocale';
import AppPage from '../../../components/AppPage';
import AdminPage from '../../../components/AdminPage';
import {
    DEFAULT_WELCOME_CONTENT_WELCOME,
    DEFAULT_WELCOME_CONTENT_LINE1,
    DEFAULT_WELCOME_CONTENT_LINE2,
    DEFAULT_COLOR,
    DEFAULT_WELCOME_CONTENT_PRIMARYBUTTON,
    DEFAULT_WELCOME_CONTENT_SECONDARYBUTTON,
    BG_COLOR_PICKER_DEFAULTS,
    DEFAULT_CORNER_STYLE,
} from '../../../utils/consts'
import IntroWelcomeMockup from '../../../components/mockup/IntroWelcomMockup';
import useCaazamREST from '../../../hooks/useCaazamREST';
import { TwitterPicker } from 'react-color';
import { compareObjects } from '../../../utils/objects'
import { formatUploadFilename } from '../../../utils/formatters';
import { FontsLoader } from '../../../utils/fonts-loader';

const DEFAULT_OPACITY = 0.33;

function WelcomeSettings() {

    const history = useHistory();
    const {
        shopOrigin, shopData,
        shopDataLoading, shopLocales,
        shopLocalesLoading, appStylesConfig,
        appStylesConfigLoading, hosts, chatConfig
    } = useShopProvider();
    const [dirtyBit, setDirtyBit] = useState(false);
    const { defaultServiceData } = useShopServices(shopOrigin);
    const [isSaving, setIsSaving] = useState(false);
    const [errorBit, setErrorBit] = useState(false);
    const [customWelcomeData, setCustomWelcomeData] = useState({});
    const [currentLocale, setCurrentLocale] = useState(shopLocales[0]);
    const [logoImageSrc, setLogoImageSrc] = useState(null);
    const [isLogoAvailable, setIsLogoAvailable] = useState(false);

    const backgroundImageFileInput = useRef(null);
    const [backgroundSrc, setBackgroundSrc] = useState(null);
    const [customBackgroundUrl, setCustomBackgroundUrl] = useState(null);
    const [newBackgroundFile, setNewBackgroundFile] = useState(null);
    const [customBackgroundOpacity, setCustomBackgroundOpacity] = useState(null);

    const [isBackgroundColorEnabled, setIsBackgroundColorEnabled] = useState(false);
    const [customBackgroundColor, setCustomBackgroundColor] = useState(null);

    const [showAvatars, setShowAvatars] = useState(!!(appStylesConfig && appStylesConfig.showAvatars));

    const { clientAppBackgroundUploadUrl } = useCaazamREST()

    const currentBackground = appStylesConfig && appStylesConfig.customBackgroundUrl;
    const primaryColor = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.primaryColor) || DEFAULT_COLOR;
    const cornerStyle = (appStylesConfig && appStylesConfig.clientAppTheme && appStylesConfig.clientAppTheme.cornerStyle) || DEFAULT_CORNER_STYLE;
    const fontFamily = appStylesConfig?.clientAppTheme?.fontFamily ?? DEFAULT_FONT_FAMILY;

    useEffect(() => {
        if (fontFamily) {
            FontsLoader(fontFamily, document.body)
        }
    }, [fontFamily])

    const handleRangeOpacitySliderChange = useCallback((value) => {
        setCustomBackgroundOpacity(value);
    }, [appStylesConfig]);

    const uploadBackground = useCallback(async () => {
        try {
            const fileName = customBackgroundUrl.split('/').slice(-1)[0];
            const { uploadUrl } = await clientAppBackgroundUploadUrl({ fileName, contentType: newBackgroundFile.type });
            await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': newBackgroundFile.type,
                },
                body: newBackgroundFile,
            })
        } catch (error) {
            console.error('Failed to upload file', error);
        }
    }, [customBackgroundUrl, newBackgroundFile]);

    const save = async () => {
        setIsSaving(true);
        try {
            if (newBackgroundFile) {
                await uploadBackground();
            }
            let newConfig = {
                customWelcomeData,
                customBackgroundUrl,
                customBackgroundOpacity,
                customBackgroundColor,
                showAvatars
            }
            await Firebase.firestore().collection('shops').doc(shopOrigin).collection('installData').doc('appTheme').set(newConfig, { merge: true });
        } catch (error) {
            console.error(`Failed to save custom welcome ${currentLocale}`, error);
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        setCurrentLocale(shopLocales[0]);
    }, [shopLocalesLoading])

    useEffect(() => {
        if (appStylesConfig) {
            if (appStylesConfig.customLogo) {
                const fileRef = Firebase.storage().refFromURL(appStylesConfig.customLogo);
                fileRef.getDownloadURL()
                    .then(url => setLogoImageSrc(url))
                    .catch(error => {
                        console.error(error.code || error);
                    })
            }
            setIsLogoAvailable(!!appStylesConfig.customLogo);
            setCustomBackgroundUrl(appStylesConfig.customBackgroundUrl || null);
            setCustomBackgroundColor(appStylesConfig.customBackgroundColor || null);
            setCustomBackgroundOpacity(appStylesConfig.customBackgroundOpacity || null);
            setIsBackgroundColorEnabled(!!appStylesConfig.customBackgroundColor);
            setCustomWelcomeData(appStylesConfig.customWelcomeData || {});
        }
    }, [appStylesConfig])

    const isLoading = appStylesConfigLoading || shopDataLoading || shopLocalesLoading;

    const handleWelcomeTextChange = (welcome) => {
        setCustomWelcomeData({ ...customWelcomeData, ...{ [currentLocale]: { ...customWelcomeData[currentLocale], welcome: welcome || null } } });
    }

    const handleLine1Change = (line1) => {
        setCustomWelcomeData({ ...customWelcomeData, ...{ [currentLocale]: { ...customWelcomeData[currentLocale], line1: line1 || null } } });
    }

    const handleLine2Change = (line2) => {
        setCustomWelcomeData({ ...customWelcomeData, ...{ [currentLocale]: { ...customWelcomeData[currentLocale], line2: line2 || null } } });
    }

    const onDiscard = () => {
        setCustomWelcomeData(appStylesConfig && appStylesConfig.customWelcomeData || {})
        setIsLogoAvailable(appStylesConfig && !!appStylesConfig.customLogo || null);
        setCustomBackgroundUrl(appStylesConfig && appStylesConfig.customBackgroundUrl || null);
        setCustomBackgroundColor(appStylesConfig && appStylesConfig.customBackgroundColor || null);
        setCustomBackgroundOpacity(appStylesConfig ? appStylesConfig.customBackgroundOpacity || DEFAULT_OPACITY : null);
        setIsBackgroundColorEnabled(appStylesConfig && !!appStylesConfig.customBackgroundColor);
        setShowAvatars(!!(appStylesConfig && appStylesConfig.showAvatars));
        setNewBackgroundFile(null);
        displayCurrentLogo();
        setErrorBit(false);
    }

    const OnError = (val) => setErrorBit(val);

    const serviceName = (defaultServiceData && defaultServiceData.serviceNoun && defaultServiceData.serviceNoun[currentLocale]) || DEFAULT_SERVICE_NAME[currentLocale];

    const handleUploadButtonClick = () => {
        backgroundImageFileInput.current.click();
    }

    const handleNewBackground = (event) => {
        const file = event.target.files[0];
        if (file) {
            const targetName = formatUploadFilename(file.name);
            const backgroundUrl = `${process.env.REACT_APP_CONFIG_BUCKET}/${shopOrigin}/online_store/custom_background/${targetName}`;
            setCustomBackgroundUrl(backgroundUrl)
            setNewBackgroundFile(file);
            setCustomBackgroundOpacity(DEFAULT_OPACITY);
        }
    }

    const removeBackground = () => {
        setCustomBackgroundOpacity(null);
        setCustomBackgroundUrl(null);
        setNewBackgroundFile(null);
        setBackgroundSrc(null);
    }

    useEffect(() => {
        if (currentBackground) {
            displayCurrentLogo();
        }
    }, [currentBackground])

    const displayCurrentLogo = () => {
        const fileRef = Firebase.storage().refFromURL(currentBackground);
        fileRef.getDownloadURL()
            .then(url => setBackgroundSrc(url))
            .catch(error => {
                console.error(error.code || error);
            })
    }

    useEffect(() => {
        if (newBackgroundFile) {
            let reader = new FileReader();
            reader.readAsDataURL(newBackgroundFile);
            reader.onloadend = (e) => {
                setBackgroundSrc(reader.result);
            }
        } else {
            setBackgroundSrc(null);
        }
    }, [newBackgroundFile])

    const onColorChange = (color) => {
        setCustomBackgroundColor(color);
    }

    const handleChangeBackgroundState = useCallback((newChecked) => {
        setCustomBackgroundColor(newChecked ? customBackgroundColor || BG_COLOR_PICKER_DEFAULTS[0] : null);
        setIsBackgroundColorEnabled(newChecked)
    }, [appStylesConfig]);

    useEffect(() => {
        let prev = {
                customBackgroundColor: appStylesConfig ? appStylesConfig.customBackgroundColor : null,
                customBackgroundOpacity: appStylesConfig ? appStylesConfig.customBackgroundOpacity : null,
                customBackgroundUrl: appStylesConfig ? appStylesConfig.customBackgroundUrl : null,
                customWelcomeData: appStylesConfig ? appStylesConfig.customWelcomeData : {},
                showAvatars: appStylesConfig ? appStylesConfig.showAvatars : false
            }

        let current = {
            customWelcomeData,
            customBackgroundUrl,
            customBackgroundOpacity,
            customBackgroundColor,
            showAvatars
        };

        setDirtyBit(!compareObjects(prev, current));
    }, [customWelcomeData,
        customBackgroundUrl,
        customBackgroundOpacity,
        customBackgroundColor,
        showAvatars]
    );

    const renderConfig = () => {
        return (
            <>
                <Card.Section title='Custom Text'>
                    <Stack vertical>
                        {shopLocales.length > 1 && <SelectLocale currentLocale={currentLocale} options={shopLocales} onChange={setCurrentLocale} />}
                        <TextField label='Welcome' value={customWelcomeData[currentLocale] && customWelcomeData[currentLocale].welcome} onChange={handleWelcomeTextChange} multiline={2} placeholder={DEFAULT_WELCOME_CONTENT_WELCOME[currentLocale]} />
                        <TextField label='1st Line' value={customWelcomeData[currentLocale] && customWelcomeData[currentLocale].line1} onChange={handleLine1Change} multiline={6} placeholder={DEFAULT_WELCOME_CONTENT_LINE1[currentLocale]} />
                        <TextField label='2nd Line' value={customWelcomeData[currentLocale] && customWelcomeData[currentLocale].line2} onChange={handleLine2Change} multiline={3} placeholder={DEFAULT_WELCOME_CONTENT_LINE2[currentLocale]} />
                    </Stack>
                </Card.Section>
                <Card.Section title='Hosts'>
                    <Stack spacing='tight'>
                        <Checkbox
                            label="Show profile pictures"
                            checked={showAvatars}
                            onChange={handleChangeAvatarsVisibilityState}
                        />
                    </Stack>
                </Card.Section>
            </>
        )
    }

    let welcomePreview;
    let line1Preview;
    let line2Preview;
    welcomePreview = (customWelcomeData[currentLocale] && customWelcomeData[currentLocale].welcome) || DEFAULT_WELCOME_CONTENT_WELCOME[currentLocale];
    line1Preview = (customWelcomeData[currentLocale] && customWelcomeData[currentLocale].line1) || DEFAULT_WELCOME_CONTENT_LINE1[currentLocale];
    line2Preview = (customWelcomeData[currentLocale] && customWelcomeData[currentLocale].line2) || DEFAULT_WELCOME_CONTENT_LINE2[currentLocale];

    welcomePreview = welcomePreview.replace(/{shop_name}/g, shopData.name).replace(/{service_name}/g, serviceName);
    line1Preview = line1Preview.replace(/{shop_name}/g, shopData.name).replace(/{service_name}/g, serviceName);
    line2Preview = line2Preview.replace(/{shop_name}/g, shopData.name).replace(/{service_name}/g, serviceName);

    const handleChangeAvatarsVisibilityState = useCallback((newChecked) => {
        setShowAvatars(newChecked)
    }, [appStylesConfig]);

    return (
        <AppPage
            title='Client welcome screen'
            breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
        >
            <Layout>
                <Layout.Section>
                    <Card title={<Stack><Subheading>Preview</Subheading>{isLoading && <Spinner size='small' />}</Stack>} sectioned subdued>
                        {!isLoading &&
                            <Stack spacing='extraLoose' distribution='center'>
                                <IntroWelcomeMockup
                                    isChatEnabled={chatConfig && chatConfig.enabled}
                                    avatars={hosts}
                                    isLogoAvailable={isLogoAvailable}
                                    showAvatars={showAvatars}
                                    logoSrc={logoImageSrc}
                                    cornerStyle={cornerStyle}
                                    primaryColor={primaryColor}
                                    backgroundImage={backgroundSrc}
                                    backgroundColor={customBackgroundColor}
                                    backgroundOpacity={customBackgroundOpacity}
                                    welcome={welcomePreview}
                                    line1={line1Preview}
                                    line2={line2Preview}
                                    primaryButton={DEFAULT_WELCOME_CONTENT_PRIMARYBUTTON[currentLocale]}
                                    secondaryButton={DEFAULT_WELCOME_CONTENT_SECONDARYBUTTON[currentLocale]}
                                />
                            </Stack>
                        }
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card sectioned >
                        {!isLoading && renderConfig()}
                        <Card.Section title='Background'>
                            <Stack vertical spacing='extraLoose'>
                                <Stack vertical spacing='tight'>
                                    <p>Background Image</p>
                                    {backgroundSrc && <img src={backgroundSrc} alt='custom logo' style={{ width: '100%', maxHeight: '60px', objectFit: 'contain' }} />}
                                    <Stack spacing='tight' alignment='center'>
                                        <Button onClick={handleUploadButtonClick}>Choose File</Button>
                                        <input ref={backgroundImageFileInput} type="file" accept="image/jpeg,image/png" onChange={handleNewBackground} style={{ display: 'none' }} />
                                        {backgroundSrc && <Button plain onClick={removeBackground}>Remove</Button>}
                                    </Stack>
                                </Stack>
                                {customBackgroundUrl && <RangeSlider
                                    label='Image opacity'
                                    disabled={!customBackgroundUrl}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={customBackgroundOpacity}
                                    onChange={handleRangeOpacitySliderChange}
                                    output
                                />}
                                <Stack vertical spacing='tight'>
                                    <Checkbox
                                        label="with color overlay"
                                        checked={isBackgroundColorEnabled}
                                        onChange={handleChangeBackgroundState}
                                    />
                                    {isBackgroundColorEnabled &&
                                        <TwitterPicker
                                            triangle='hide'
                                            color={customBackgroundColor}
                                            onChangeComplete={(primaryColor) => onColorChange(primaryColor.hex)}
                                            colors={BG_COLOR_PICKER_DEFAULTS}
                                        />}
                                </Stack>
                            </Stack>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            {dirtyBit && <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                    onAction: save,
                    disabled: errorBit,
                    loading: isSaving,
                }}
                discardAction={{
                    onAction: onDiscard,
                    disabled: isSaving,
                }}
            />}
        </AppPage>
    );
}

export default function () {
    return (
        <AdminPage>
            <WelcomeSettings />
        </AdminPage>
    )
}
