
import React from 'react'
import { useAppAuthState, hostAppAuth } from '../authState';


export default function useHostApp() {

    const { user, shopOrigin } = useAppAuthState();

    const openHostApp = () => {

        var url = process.env.REACT_APP_CAAZAM_HOST_APP + '/adminLogin';
        hostAppAuth(user, shopOrigin)
            .then(token => url = url + `?token=${token.token}`)
            .catch(error => console.error(`Failed to fetch host app token for ${shopOrigin} ${user}`, error))
            .finally(() => window.open(url, 'Caazam Host'));
    }

    return { openHostApp };
}
