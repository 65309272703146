import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export default function usePricingPlans() {

    const activeRef = Firebase.firestore().collection('subscriptions').doc('active');
    const [activePlansData, activePlansDataLoading, activePlansDataError] = useDocumentData(activeRef);
    const [plansData, setplansData] = useState([]);
    const [plansDataLoading, setPlansDataLoading] = useState(true);

    useEffect(() => {

        const getPlanData = (planId) => {
            return Firebase.firestore().collection('subscriptions').doc('active').collection('plans').doc(planId).get()
                .then(planDoc => planDoc.data())
                .catch(planError => console.error(planError));
        }

        const getAllPlansData = async () => {
            var newPlans = [];
            for (const planId of activePlansData.subscriptionsList) {
                const data = await getPlanData(planId);
                newPlans.push({ id: planId, ...data });
            }
            setplansData(newPlans);
            setPlansDataLoading(false);
        }

        if (!activePlansDataLoading) {
            if (activePlansDataError) {
                console.error('Error loading pricing plans', activePlansDataError)
            } else {
                getAllPlansData();
            }
        }

    }, [activePlansData, activePlansDataError, activePlansDataLoading])

    activePlansDataError && console.error(activePlansDataError);

    return { plansData, plansDataLoading, activePlansData };
}
