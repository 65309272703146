import {useAppAuthState} from "../authState";
import Firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import { useMemo, useState } from "react";
import { parseIdString, toPresetProduct } from '../utils/products';

const useShowroomPresetAction = () => {
    const { shopOrigin, user } = useAppAuthState();
    const [loading, setLoading] = useState(false)


    const presetRef = useMemo(() => (
      shopOrigin
        ? Firebase
          .firestore()
          .collection('shops')
          .doc(shopOrigin)
          .collection('showroomPresets')
        : null
    ), [shopOrigin])

    const getPreset = async (id) => {
        try {
            setLoading(true)
            const snapshot = await presetRef
                .doc(id)
                .get()
            return {...snapshot.data(), id}
        } catch (e) {
            console.error('getPreset error', e)
            return {}
        } finally {
            setLoading(false)
        }
    }

    const onUpdatePreset = async (presetId, presetData) => {
        const {products, name, owner} = presetData
        try {
            setLoading(true)
            await presetRef.doc(presetId).update({
                name,
                owner,
                products: JSON.parse(JSON.stringify(products)).map(toPresetProduct),
                updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            })
            // setProductsPresetChanged(false)
        } catch (e) {
            console.log('onUpdatePreset error', e)
        } finally {
            setLoading(false)
        }
    }

    const onSaveNewPreset = async (products, name, owner = 'public') => {
        try {
            setLoading(true)
            const doc = presetRef.doc();
            await doc.set(({
                products: JSON.parse(JSON.stringify(products)).map(toPresetProduct),
                name,
                owner,
                createdBy: user.uid,
                createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            }), {merge: true})
            // setProductsPresetChanged(false)
        } catch (e) {
            console.error('onSaveNewPreset error', e)
        } finally {
            setLoading(false)
        }
    }

    const deletePreset = async (id) => {
        try {
            setLoading(true)
            await presetRef.doc(id).delete()
        } catch (e) {
            console.error('deletePreset error', e)
        } finally {
            setLoading(false)
        }
    }

    return ({
        getPreset,
        onUpdatePreset,
        onSaveNewPreset,
        deletePreset,
        loading
    })
}

export default useShowroomPresetAction;
