export const COMPARE_TYPES = {
  HOSTS: 'hosts',
  EVENT_TYPES: 'event_types',
};

export const CHARTS_LABELS = {
  COMPLETED_CALLS_BY_EVENT_TYPES: 'Event types - completed calls',
  AIR_TIME_SPLIT: 'Air Time Split',
  CALL_DURATION: 'Call Duration',
  NPS_SCORE: 'NPS Score',
  CSAT_SCORE: 'CSAT Score',
  CALL_FEEDBACK: 'Call Feedback',
  AVERAGE_ORDER_VALUE: 'Average Order Value',
  CONVERSATION_RATE: 'Conversion Rate',
  SALES_FOR_THE_PERIOD: 'Sales for the period',
  CALLS_FOR_THE_PERIOD: 'Calls for the period',
  SALES_PER_DAY: 'Sales per Day',
  CALLS_PER_DAY: 'Calls per Day',
  SCHEDULED_EVENTS_PER_DAY: 'Appointment stats',
  SCHEDULED_EVENTS_BY_HOST: 'Appointment stats (by host)',
  SCHEDULED_EVENTS_BY_EVENT_TYPE: 'Appointment stats (by event type)',
  VIDEO_STATISTICS: 'Engagement'
}

export const DEFAULT_HOST_FILTER = {label: 'All hosts', id: 'all', value: 'all'};

export const DEFAULT_EVENT_TYPE_FILTER = {label: 'All event types', id: 'all', value: 'all'};

export const NO_TYPE = {label: '- none -', id: 'default', value: null};
